import classNames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getUser } from "../../../globalAPI/api.js"
import { addPlayersToLeagueTeam } from "../../../pages/league/api/leagueGame.js"
import { removePlayerFromTeam } from "../../../pages/league/api/leagueTeam.js"
import { getLeagueTeamPlayers } from "../../../pages/league/api/myLeague.js"
import { navigateToPlayerPage } from "../../../pages/league/service/service.js"
import { useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"
import { useAssets } from "../../../providers/AssetsProvider.jsx"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import { isBusinessEnvironment } from "../../../utilities/helpers.js"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import Preloader from "../../loaders/preloader/preloader.jsx"
import AskTeammates from "../../popin/invitations/askTeammates.jsx"
import MessagePopin from "../../popin/MessagePopin/MessagePopin.jsx"
import ShareLink from "../../ShareLink/ShareLink.jsx"
import { ThreeDotsToggle } from "../../ThreeDotsToggle/ThreeDotsToggle.jsx"
import { TooltipLight } from "../../tooltip/tooltipLight/tooltipLight.jsx"
import { LeagueModalBase } from "../LeagueModalBase/LeagueModalBase.jsx"
import Table from "../Table/Table.jsx"
import { TeamPicture } from "../TeamPicture/TeamPicture.jsx"

import "./TeamPlayersList.scss"

export const TeamPlayersList = ({ team, extendedPadding }) => {
    const [ players, setPlayers ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ openedLine, setOpenedLine ] = useState(null)
    const navigate = useNavigate()
    const { resetLeagueTeamCache, setSelectedLeagueTeam } = useLeagueStore()
    const userLoaded = useSelector(state => state.userLoaded)
    const { m } = useAssets()
    const isBusiness = isBusinessEnvironment()

    useEffect(() => {
        if (team?.id) {
            setPlayers([])
            getPlayers()
        }
    }, [ team?.id ])

    const getPlayers = () => {
        setIsLoading(true)
        setOpenedLine(null)
        getLeagueTeamPlayers(team?.id).then((data) => {
            const players = data?.map((player) => {
                const values = new Map()

                Object.entries(player).forEach(([ key, value ]) => {
                    values.set(key, value)
                })

                values.set("goalsPerGame", (player.goals / player.playedGames?.toFixed(2)) || 0)
                values.set("name", (
                    `${player.firstname ?? ""} ${player.lastname ? (player.lastname.slice(0, 1) + ".") : ""}`
                ).trim())

                return ({
                    id: player.id,
                    values,

                })
            }) ?? []

            setPlayers(players)
        }).finally(() => setIsLoading(false))
    }

    const renderAfterRowContent = (row) => {
        return <div><ButtonCta text={"supprimer de l'effectif"} onClick={() => onRemovePlayerClick(row)} isCta2Alt/></div>
    }

    const onRemovePlayerClick = /* istanbul ignore next */(row) => {
        const hideModal = () => ModalHandler.hide(RemovePlayerModal)

        ModalHandler.show(RemovePlayerModal, {
            name: row.values?.get("name"),
            onDelete: () => {
                setIsLoading(true)
                hideModal()
                removePlayerFromTeam(team?.id, row.id).then(() => {
                    setPlayers(players.filter((player) => player.id !== row.id))
                }).catch((e) => {
                    ModalHandler.show(MessagePopin, {
                        message: <p>{e.response?.data?.error ?? "Impossible de supprimer le joueur de l'effectif"}</p>,
                        title: "Une erreur est survenue",
                    })
                }).finally(() => {
                    setIsLoading(false)
                })
            },
        })
    }

    /* istanbul ignore next */
    const onPlayerClick = (player) => {
        navigateToPlayerPage(
            navigate,
            player.values?.get("championshipPlayerId"),
            null,
            player.values?.get("championshipTeamId"),
        )
    }

    const openFriendInvitationPopin = () => {
        const hideModal = () => ModalHandler.hide(AskTeammates)
        ModalHandler.show(AskTeammates, {
            alreadySelectedMatesIds: players?.map(player => player.id),
            callback: (e) => {
                setIsLoading(true)
                hideModal()
                addPlayersToLeagueTeam(team?.id, e.join(",")).then(() => {
                    getPlayers()
                }).finally(() => {
                    setIsLoading(false)
                })
            },
            className: "leagueAskTeammates",
        })
    }

    const columns = [
        { align: "center", name: "id", render: (row) =>
            (<TeamPicture
                size={39}
                image={isBusiness ? row.values?.get("businessLeaguePictureUrl") || row.values?.get("pictureUrl") : row.values?.get("pictureUrl")}
                onClick={/* istanbul ignore next */() => onPlayerClick(row)}
                placeHolder="/assets/images/user-placeholder-b.svg"
            />),
        },
        { align: "left", cls: "bold", name: "name", render: (row) => {
            return (
                <span onClick={/* istanbul ignore next */() => onPlayerClick(row)}>
                    {row.values?.get("name")}
                    {(row.values?.get("isCaptain") || row.values?.get("isViceCaptain")) && (
                        <TooltipLight text={row.values?.get("isCaptain") ? "Capitaine" : "Vice-capitaine"} dark>
                            <div className={"teamPlayerList__status"}>
                                <img src={
                                    row.values?.get("isCaptain") ? "/assets/icons/ICON-capitaine.svg" : "/assets/icons/ICON-vice-capitaine.svg"
                                } alt={"capitaine"}/>
                            </div>
                        </TooltipLight>
                    )}
                </span>
            )
        },
        },
        { align: "center", name: "nbGamePlayed" },
        { align: "center", cls: "bold", name: "nbGoals" },
        {
            align: "center", name: "goalsPerGame", render: (row) => {
                return ((row.values?.get("nbGoals") || 0) / (row.values?.get("nbGamePlayed") || 1)).toFixed(2)
            } },
    ]

    if (team?.id && team?.canEdit === true) {
        columns.push({
            align: "right",
            name: "action",
            render : (row) => {
                const isAlreadyOpened = openedLine === row?.id
                return (<ThreeDotsToggle onClick={/* istanbul ignore next */() => {
                    setOpenedLine(isAlreadyOpened ? null : row?.id)
                }} active={isAlreadyOpened}/>)
            },
        })
    }

    const openLeaveModal = () => {
        ModalHandler.show(LeaveTeamModal, {
            name: team?.name,
            onConfirm: () => {
                setIsLoading(true)
                ModalHandler.hide(LeaveTeamModal)

                const showError = () => {
                    ModalHandler.show(MessagePopin, {
                        message: "Impossible de quitter l'équipe",
                        title: "Une erreur est survenue",
                    })
                }

                removePlayerFromTeam(team?.id, userLoaded?.contactId).then((r) => {
                    if (r.data?.message) {
                        showError()
                    } else {
                        setSelectedLeagueTeam({})
                        getUser(true).then(() => {
                            navigate("/", { replace: true })
                            resetLeagueTeamCache()
                        }).finally(() => {
                            setIsLoading(false)
                        })
                    }
                }).catch(() => {
                    showError()
                    setIsLoading(false)
                })
            },
        })
    }

    const canLeave = !!team?.players?.find(player => player.id === userLoaded?.contactId && !player.isCaptain && !player.isViceCaptain)

    return (
        <>
            <div className={classNames("teamPlayerList", {
                "teamPlayerList--extendedPadding": extendedPadding,
            })}>
                {isLoading ? (
                    /* istanbul ignore next */<Preloader fixed/>
                ) : (
                    <Table
                        title="Mon effectif"
                        customClass={"teamPlayerList__table"}
                        animateRows
                        decorator={m("images.league.ornaments.prize-thunder")}
                        // decorator={"/assets/images/league/ornaments/prize-thunder.svg"}
                        columns={columns}
                        dataList={players}
                        columnDisplayNames={columnsDisplayNames}
                        afterRowContent={{
                            render: renderAfterRowContent,
                            rowId: openedLine,
                        }}
                    />
                )}
                {team?.canEdit ? (<div className={"teamPlayerList__actions"}>
                    {!isBusiness && (
                        <ButtonCta
                            text={"Inviter des joueurs"}
                            onClick={/* istanbul ignore next */() => openFriendInvitationPopin()}
                            icon={m("icons.picto-add-user")}
                            hoverChangeIcon={m("icons.picto-add-user--hover")}
                            // icon={"/assets/images/picto-add-user--white.svg"}
                            disabled={isLoading}
                        />
                    )}
                    {team?.shareLink && (
                        <ShareLink
                            isAlt
                            label="Copier le lien d'invitation"
                            shareLink={team?.shareLink}
                        />
                    )}
                </div>) : null}
            </div>
            {canLeave && (
                <ButtonCta text={"Quitter mon équipe"} onClick={openLeaveModal} isCta2Alt className="leaveTeamButton"/>
            )}
        </>
    )
}

const columnsDisplayNames = new Map([
    [ "id", "" ],
    [ "name", "Joueur" ],
    [ "nbGamePlayed", "J" ],
    [ "nbGoals", "B" ],
    [ "goalsPerGame", "B/MJ" ],
    [ "action", "" ],
])
/* istanbul ignore next */
const RemovePlayerModal = ModalHandler.create(({ hide, visible, name, onDelete }) => {
    return (
        <LeagueModalBase onClose={hide} visible={visible} className={"leagueRemovePlayerModal"}>
            <h3>Veux-tu vraiment supprimer {name} de ton effectif ?</h3>
            <div className={"leagueRemovePlayerModal__buttons"}>
                <ButtonCta text={"Annuler"} onClick={hide} isCta2 isAlt />
                <ButtonCta text={"Supprimer"} onClick={onDelete} isCta3/>
            </div>
        </LeagueModalBase>
    )
})

const LeaveTeamModal = ModalHandler.create(({ visible, hide, name, onConfirm }) => {
    return (
        <LeagueModalBase
            className={"leave-team-modal"}
            title={`Êtes-vous sûr de vouloir quitter l'équipe ${name} ?`}
            visible={visible}
            onClose={hide}
        >
            <div className="modal-actions">
                <ButtonCta text={"Annuler"} onClick={hide} isCta2 isAlt/>
                <ButtonCta text={"Confirmer"} onClick={onConfirm} isCta3/>
            </div>
        </LeagueModalBase>
    )
})

TeamPlayersList.propTypes = {
    extendedPadding: PropTypes.bool,
    team: PropTypes.object,
}
