import { getNextLocation } from "../globalAPI/api"
import { nextBooking } from "../store/actions/content"
import store from "../store/store"

export default function updateNextBooking() {
    getNextLocation().then(res => {
        if (res?.filter(booking => booking.amountDue && !booking.subscriptionId)?.length) {store.dispatch(nextBooking(true))}
        else {store.dispatch(nextBooking(false))}
    })
}
