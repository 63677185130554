import { string } from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import StepsManager from "../../../components/stepsManagement/StepManager"
import SelectSuperSub from "../../../components/SuperSub/SelectSuperSub/SelectSuperSub"
import { clearAllForms } from "../../../store/forms/actions"
import OrderSummary from "../../payments/OrderSummary/OrderSummary"

import "./candidatureSupersub.scss"

function CandidatureSupersub() {
    const params = useParams()
    const locationId = params.locationId

    const supersubForm = useSelector(state => state.forms["supersubForm"])

    /* ACTIONS */
    const dispatch = useDispatch()

    useEffect(
        () => {
            dispatch(clearAllForms())
        }, [],
    )

    return (
        <React.Fragment>
            {/*<HeaderSuperSub title={title} helpId="candidatureSupersubHelp" help="test"/>*/}
            <StepsManager hideProgressPar={true}>
                <SelectSuperSub locationId={locationId} />
                <OrderSummary title="Récapitulatif" tunnelType="SUPERSUB" supersubRequest={supersubForm ? supersubForm.supersubRequest : {}}/>
            </StepsManager>
        </React.Fragment>
    )

}

CandidatureSupersub.formName = "candidatureSupersub"
CandidatureSupersub.propTypes = {
    locationId: string,
}

export default CandidatureSupersub
