import { generateLocationPublicInvitDetails } from "../globalAPI/api"

const generateShareLink = (location, theme, haveDetails, sethaveDetails) => {
    let shareLink = import.meta.env.VITE_URL_BASE
            + "/" + (theme === "theme-padel" ? "padel/" : "")
            + "?action=tokenActivation&invitation=TYPE_LOCATION&theme="
            + theme
            + "&id="
            + location.id
            + "&PJT="
            + encodeURIComponent(location.publicJoinToken)
            + "&fromUser="
            + encodeURI(location.organizer.firstname + " " + location.organizer.lastname)

    if (!haveDetails) {
        generateLocationPublicInvitDetails(location.id)
        sethaveDetails(true)
    }
    return encodeURI(shareLink)
}

export default generateShareLink
