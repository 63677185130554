
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import _ConfirmOverlayContentComponent from "../_ConfirmOverlayContentComponent"

class simpleConfirmContent extends _ConfirmOverlayContentComponent
{
    constructor(props) {
        super(props)
    }

    getName() {
        return "simple"
    }

    renderMessages() {
        let rowMessages = []
        this.props.confirmationOverlay.props.simpleMessages.forEach((message) => {
            rowMessages.push(
                <div className="row white">{message}</div>,
            )
        })
        return rowMessages
    }

    preRenderDesktop() {
        return this.preRender()
    }

    renderPicto() {
        return this.props.confirmationOverlay.props.pictoUri ? this.props.confirmationOverlay.props.pictoUri : "Hors-Jeu-O.svg"
    }

    preRender()
    {
        return (
            <div className="align-flex">
                <div className="text-center">
                    <img className="confirmation-content-picto" src={"/assets/images/" + this.renderPicto()} />
                    {this.renderMessages()}
                </div>
            </div>
        )
    }

}

// CONNECT THE STORE (DATA) TO THE COMPONENT
const stateToProps = (state) => {
    return _ConfirmOverlayContentComponent.stateToProps(state)
}

// BIND ACTIONS TO CONTAINER
const dispatchToProps = (dispatch) => {
    let properActions = {}
    let mergedActions = {
        ... properActions,
        ... _ConfirmOverlayContentComponent.dispatchToProps(),
    }
    return bindActionCreators(
        mergedActions
        ,
        dispatch,
    )
}

export default connect(stateToProps, dispatchToProps)(simpleConfirmContent)
