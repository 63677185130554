import classNames from "classnames"
import dayjs from "dayjs"
import PropTypes, { oneOfType } from "prop-types"
import { forwardRef, useEffect } from "react"

import useMediaPath from "../../hooks/useMediaPath"
import UserAgentHandler from "../../services/UserAgentHandler"
import CheckBlock from "../Forms/CheckBlock/CheckBlock"

import "./HoursList.scss"

export const HoursList = forwardRef(
    (
        {
            hours,
            selectedHour,
            timeFilter,
            selectedDateIsToday,
            setSelectedHour,
            defaultScrollToHour,
            hideForbidden = false,
        },
        ref,
    ) => {
        const mediaPath = useMediaPath()
        const today = dayjs().format()
        const todayDate = new Date()

        useEffect(() => {
            if (defaultScrollToHour && hours && ref.current) {
                const index = hours.findIndex(hour => hour.value === defaultScrollToHour)
                if (index !== -1) {
                    ref.current.scrollLeft = (index + (hideForbidden ? 0 : 1)) * (window.innerWidth > 768 ? 70 : 52)
                }
            }
        }, [ hours, ref, defaultScrollToHour ])

        return (
            <div className="hoursList arrowsWrapper">
                <img
                    src={mediaPath([ "/assets/icons/arrow-left-orange.svg", "/assets/icons/arrow-left-black.svg" ])}
                    className={classNames("previousHours", { marginTop: UserAgentHandler.isIOS() || UserAgentHandler.isAndroid()  })}
                    onClick={
                        () => {
                            let hsScrollLeft = ref.current.scrollLeft
                            ref.current.scrollLeft = hsScrollLeft === 140 ?
                                hsScrollLeft - 140
                                :
                                hsScrollLeft - 75
                            
                        }
                    }
                />
                <div className="opacity">
                    <div className="hoursWrapper" ref={ref}>
                        {!hideForbidden && (
                            <CheckBlock
                                callback={
                                    () => {
                                        setSelectedHour("noHourFilter")
                                        ref.current.scrollLeft = 0
                                    }
                                }
                                checked={selectedHour === "noHourFilter"}
                                value={"noHourFilter"}
                            >
                                <img src={selectedHour === "noHourFilter" ?
                                    mediaPath([ "/assets/icons/forbidden-white.svg", "/assets/images/padel/icons/forbidden-black.svg" ])
                                    :
                                    mediaPath([ "/assets/icons/forbidden-grey.svg", "/assets/images/padel/icons/forbidden-grey.svg" ])} />
                            </CheckBlock>
                        )}

                        { hours?.filter(item => {
                            const isNotDisabledTime = (
                                timeFilter
                            && timeFilter.start <= item.value
                            && timeFilter.end >= item.value
                            )
                            const noTimeFilter = !timeFilter
                            const isNotPassedHour = !(
                                selectedDateIsToday
                            && todayDate > new Date(today.split("T")[0] + item.value)
                            )
                            return (isNotDisabledTime || noTimeFilter) && isNotPassedHour
                        })?.map(
                            (hour, i) => (
                                <CheckBlock
                                    callback={
                                        () => {
                                            setSelectedHour(hour)
                                            if (
                                                (
                                                    window.innerWidth
                                                || document.documentElement.clientWidth
                                                || document.body.clientWidth
                                                ) > 768
                                            ) {
                                                ref.current.scrollLeft = (i + (hideForbidden ? 0 : 1)) * 70
                                            }
                                            else {
                                                ref.current.scrollLeft = (i + (hideForbidden ? 0 : 1)) * 52
                                            }
                                        }
                                    }
                                    checked={selectedHour && selectedHour.value === hour.value}
                                    value={hour.value}
                                    key={hour.value}
                                >
                                    {hour.display}
                                </CheckBlock>
                            ),
                        )}
                    </div>
                </div>
                <img
                    src={mediaPath([ "/assets/icons/arrow-right-orange.svg", "/assets/icons/arrow-right-black.svg" ])}
                    className={classNames("nextHours", { marginTop: UserAgentHandler.isIOS() || UserAgentHandler.isAndroid()  })}
                    onClick={
                        () => {
                            let hsScrollLeft = ref.current.scrollLeft
                            ref.current.scrollLeft = hsScrollLeft + 70
                        }
                    }
                />
            </div>
        )
    })

export const hourOptions = [
    { display: "09h", value: 9 },
    { display: "10h", value: 10 },
    { display: "11h", value: 11 },
    { display: "12h", value: 12 },
    { display: "13h", value: 13 },
    { display: "14h", value: 14 },
    { display: "15h", value: 15 },
    { display: "16h", value: 16 },
    { display: "17h", value: 17 },
    { display: "18h", value: 18 },
    { display: "19h", value: 19 },
    { display: "20h", value: 20 },
    { display: "21h", value: 21 },
    { display: "22h", value: 22 },
    { display: "23h", value: 23 },
]

HoursList.displayName = "HoursList"

HoursList.propTypes = {
    defaultScrollToHour: PropTypes.number,
    hideForbidden: PropTypes.bool,
    hours: PropTypes.array,
    onScrollLeft: PropTypes.func,
    onScrollRight: PropTypes.func,
    selectedDateIsToday: PropTypes.bool,
    selectedHour: oneOfType([ PropTypes.object, PropTypes.string ]),
    setSelectedHour: PropTypes.func,
    timeFilter: PropTypes.object,
}
