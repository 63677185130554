import classNames from "classnames"

export default function getFieldClassName(type) {
    const padelType = [ 12, 14, 15 ]
   
    return classNames({
        padel: padelType.includes(type),
        sevenSoccer: type === 13,
    })
}
