import getBaseNameUrl from "./getBaseNameUrl"
import { useTheme } from "../../components/ThemeContext/ThemeContext"

function usePath() {
    const theme = useTheme()
    const prefixLocation = getBaseNameUrl(theme)

    return path => {
        return `${prefixLocation}${path ?? ""}`
    }
}

export default usePath
