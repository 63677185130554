import { bool, func, object, oneOfType, string } from "prop-types"
import { forwardRef, useImperativeHandle, useState } from "react"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import Preloader from "../../../../../components/loaders/preloader/preloader"
import Modal from "../../../../../components/Modal/Modal"
import DateDisplay from "../../../../../components/Time/DateDisplay"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../../../../hooks/useMediaPath"
import usePath from "../../../../../routes/services/usePath"
import { confirmCancel } from "../functions"

const CancelPopin = forwardRef(
    ({ subscription, freeCancelDate, location, isOrganizer, goToCreateSuperSub, cancellationMessage }, ref) => {
        const [ isLoading, setIsLoading ] = useState(false)
        const [ errorMessage, setErrorMessage ] = useState("")
        const navigate = useNavigate()
        const path = usePath()
        const mediaPath = useMediaPath()
        const { tu } = useUrbanLanguage()
        const [ internalModalState, setInternalModalState ] = useState(false)

        useImperativeHandle(ref, () => ({
            hide: () => {
                setInternalModalState(false)
            },
            show: () => {
                setInternalModalState(true)
            },
        }))

        const subscriptionMessage = subscription?.paymentMode === 1 ?
            (<>
                Si tu annules ta réservation, tu seras Hors Délai.<br />
                Après 2 annulations hors délai, ton abonnement est susceptible d'être résilié.
            </>)
            :
            (<>
                Tu es hors délai pour annuler cette session.<br />
                Si tu annules ta session, elle sera perdue.
            </>)

        return (
            <Modal
                className="cancelPopin"
                closeButton={isLoading && <></>}
                closeOnEsc={!isLoading}
                hideOnOverlayClicked={!isLoading}
                isOpen={internalModalState}
                onClose={() => setInternalModalState(false)}
            >
                <header> Annuler ma {subscription ? "session" : "réservation"} </header>
                <div className="cancelPopinContent">
                    <div className="title">Es-tu sûr de vouloir annuler ta {subscription ? "session d'abonnement" : "réservation"} ?</div>

                    {subscription ? (
                        <>
                            {freeCancelDate ? (
                                <div className="freeCancelBlock">
                                    <div>Annulation {subscription ? "possible" : "gratuite"} jusqu’au :</div>
                                    <div className="dateDiv">
                                        <img src={mediaPath([ "/assets/icons/calendar-orange.svg" , "/assets/images/padel/icons/icon-calendar-red.svg" ])} className="calendarIcon" />
                                        {freeCancelDate &&
                                            <div><DateDisplay stringDate={freeCancelDate} /></div>
                                        }
                                    </div>
                                </div>
                            ) : (
                                <div className="paying-cancel">
                                    {subscriptionMessage}
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {cancellationMessage && (
                                <div className="freeCancelBlock">
                                    {cancellationMessage}
                                </div>
                            )}
                            {!cancellationMessage && (
                                <>
                                    {freeCancelDate ? (
                                        <div className="freeCancelBlock">
                                            <div>Ton paiement ne sera pas débité.</div>
                                            <div>Annulation gratuite jusqu’au :</div>
                                            <div className="dateDiv">
                                                <img src={mediaPath([ "/assets/icons/calendar-orange.svg" , "/assets/images/padel/icons/icon-calendar-red.svg" ])} className="calendarIcon" />
                                                {freeCancelDate &&
                                                    <div><DateDisplay stringDate={freeCancelDate} /></div>
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="paying-cancel">
                                            Ton match commence dans moins de 48h, il est trop tard pour annuler gratuitement.<br />
                                            Si tu annules, tu ne seras pas remboursé du montant payé.
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    <div className="actionsDiv">
                        {isLoading ? (
                            <Preloader small cls={"withTopMargin"}/>
                        ) : (
                            <>
                                {!isLoading && errorMessage ? (
                                    <div className="errorMessage">{errorMessage}</div>
                                ) : (
                                    <>
                                        <div className="firstLine">
                                            <ButtonCta
                                                text="Oui"
                                                onClick={() => {
                                                    setIsLoading(true)
                                                    confirmCancel(freeCancelDate, subscription, navigate, location, path).then(() => {
                                                        setIsLoading(false)
                                                    }).catch(() => {
                                                        setIsLoading(false)
                                                        setErrorMessage("Une erreur est survenue lors de l'annulation de la réservation.")
                                                    })
                                                }}
                                                isAlt
                                            />
                                            <ButtonCta
                                                text="Non"
                                                onClick={() => {
                                                    ref.current.hide()
                                                }}
                                                isCta2Alt
                                            />
                                        </div>
                                        {isOrganizer && location.isSupersubRequestAvailable && (
                                            <div className="secondLine">
                                                <ButtonCta
                                                    text={tu("booking.supersub.find")}
                                                    color="orange"
                                                    onClick={() => {
                                                        goToCreateSuperSub()
                                                    }}
                                                    isCta2
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        )
    },
)

CancelPopin.displayName = "CancelPopin"

CancelPopin.propTypes = {
    cancellationMessage: string,
    freeCancelDate: oneOfType([ bool, string ]),
    goToCreateSuperSub: func,
    isOrganizer: bool,
    location: object,
    subscription: oneOfType([ bool, object ]),
}

export default CancelPopin
