import formatTwoDigits from "./formatTwoDigits"

/**
 * 
 * @param {Date} d 
 */
export default function getHourMinutesFromDate(d) {
    return formatTwoDigits(
        d.getHours(),
    ) + "h" +
    formatTwoDigits(
        d.getMinutes(),
    )
}
