import { useEffect , useState } from "react"
import { useSelector } from "react-redux"

import ButtonCta from "../../../../components/Buttons/button/button-cta.jsx"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext"
import { getAbonnements } from "../../../../globalAPI/api"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import EmptySubscription from "../../../../pages/profil/components/EmptySubscription/EmptySubscription"
import SubscriptionCard from "../../../../pages/profil/components/SubscriptionCard/SubscriptionCard"
import { currentDomDetails } from "../../../../store/actions/scaffold/index"
import EmptyContent from "../../componentsContainers/empty_content"
import _ContentComponent from "../_ContentComponent"

import "./abonnementsContent.scss"

function AbonnementsContent() {
    const [
        renderAllAbonnementsLimit,
    ] = useState(10)
    const [ areAbonnementsLoaded, setAreAbonnementsLoaded ] = useState(false)
    const [ abonnements, setAbonnements ] = useState({})
    const [ arrayAbonnements, setArrayAbonnements ] = useState([])
    const [ showAllAbonnements, setShowAllAbonnements ] = useState(false)
    const theme = useTheme()
    const themeHeader = useThemeHeader()

    const ajaxCalling = useSelector(state => state.ajaxCalling)

    useEffect(
        () => {
            getUserAbonnements()
        }, [],
    )

    useEffect(
        () => {
            if (abonnements && Object.keys(abonnements)?.length) {
                let array = []
                for (var index in abonnements) {
                    if (index < renderAllAbonnementsLimit || showAllAbonnements) {array.push(abonnements[index])}
                }
                setArrayAbonnements(array)
            }
        }, [ abonnements, renderAllAbonnementsLimit, showAllAbonnements ],
    )

    function filterAbonnementsToDisplay(Abonnements)
    {
        var filteredAbonnements = {}
        if (Abonnements.length > 0) {
            for (var index in Abonnements) {
                let element = Abonnements[index]
                element.shrinked = true
                if (element.status === 1) {
                    element.statusDisplay = "À régler"
                } else if (element.status === 2) {
                    element.statusDisplay = "Réglée"
                }
                filteredAbonnements[index] = element
            }
        }
        return filteredAbonnements
    }

    function getUserAbonnements() {
        getAbonnements({ activity: themeHeader }).then((Abonnements) => {
            if (Abonnements.hitCount > 0) {
                Abonnements = filterAbonnementsToDisplay(Abonnements.items)
            } else {
                Abonnements = []
            }
            setAbonnements(Abonnements)
            setAreAbonnementsLoaded(true)
            currentDomDetails({
                mainContainerHeight: window.document.getElementById("content").clientHeight,
            })
        })
    }

    function preRenderDesktopContent() {
        return preRenderContent()
    }

    function preRenderContent() {
        return (
            <div className="bookingSubscription">
                {ajaxCalling === true || areAbonnementsLoaded === false ? (
                    <div className="preloader">
                        <div></div>
                    </div>
                ) : (
                    Object.keys(abonnements).length > 0  && arrayAbonnements?.length ? (
                        <div className="row">
                            <div className="c-col c-col--10 c-col--m--10 c-col--sm--12 subWrapper">
                                <div className="width100pct">{arrayAbonnements.map(subscr => <SubscriptionCard abonnement={subscr} key={"subscription-" + subscr?.id}/>)}</div>
                                {!showAllAbonnements && Object.keys(abonnements).length > renderAllAbonnementsLimit && (
                                    <ButtonCta onClick={() => setShowAllAbonnements(true)} className="load-more-button" text="Tout afficher"/>
                                )}
                            </div>
                        </div>
                    ) :  theme === "theme-soccer" ? (
                        <EmptyContent
                            pictoSrc="/assets/images/subscribtion.svg"
                            title="OUPS !"
                            customDescription={
                                (<div className="descriptions">
                                    <div>Tu n'as pas encore d'abonnement chez UrbanSoccer !</div>
                                    <div>Voici ce que tu manques :</div>
                                    <div className="margin-top-line"></div>
                                    <div className="margin-top-line">
                                        <span className="weight-bold orange">- Du confort : </span>
                                        <span>ton terrain réservé chaque semaine au créneau de vote choix</span>
                                    </div>
                                    <div className="margin-top-line">
                                        <span className="weight-bold orange">- De la liberté : </span>
                                        <span>choisir la fréquence de ton abonnement et intégrer des coupures</span>
                                    </div>
                                    <div className="margin-top-line">
                                        <span className="weight-bold orange">- Des économies : </span>
                                        <span>des tarifs avantageux sur ton terrain et nos produits Nike</span>
                                    </div>
                                </div>)}
                        />
                                
                    ) : (
                        <EmptySubscription />
                    )
                )}
            </div>
        )
    }

    return (
        <_ContentComponent
            name={"Abonnements"}
            preRenderContent={preRenderContent}
            preRenderDesktopContent={preRenderDesktopContent}
            title={"ABONNEMENTS"}
            level1Label="MON PROFIL"
        >
        </_ContentComponent>
    )
}

export default AbonnementsContent
