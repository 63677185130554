import ContainersHandler from "../../../entries/main/business/ContainersHandler"
import ContentChanger from "../../../entries/main/business/ContentChanger"
import store from "../../store"

export const closeConfirmationOverlay = () => {
  
    return {
        payload: {
            identifier: "",
            open: false,
            props: {},
            targetContainer: null,
            targetContent: null,
            targetVars: {},
        },
        type: "CONFIRMATION_OVERLAY_UPDATED",
    }
}

export const openConfirmationOverlay = (contentIdentifier, props = {}, lowerLayer) => {
    let content = contentIdentifier ? ContentChanger.load(contentIdentifier, props) : null
    let container = contentIdentifier ? ContainersHandler.load(contentIdentifier) : null

    return {
        payload: {
            displayValidationButton: true,
            identifier: contentIdentifier.replace("Confirm",""),
            lowerLayer: lowerLayer === true,
            open: true,
            props: props,
            targetContainer: container,
            targetContent: content,
            targetVars: {},
        },
        type: "CONFIRMATION_OVERLAY_UPDATED",
    }
}

export const updateConfirmOverlayTargetVar = (varName,varValue,confirmationOverlay) => {
    let newVars = { targetVars: confirmationOverlay.targetVars ? confirmationOverlay.targetVars : {} }
    newVars.targetVars[varName] = varValue
    let newConfirmationOverlay = {
        ...confirmationOverlay,
        ...newVars,
    }

    return {
        payload: newConfirmationOverlay,
        type: "CONFIRMATION_OVERLAY_UPDATED",
    }

}

export const updateConfirmOverlayVar = (varName,varValue,confirmationOverlay) => {

    // Change confirmationOverlay object reference by merging it in order to trigger components rerendering ...
    let newVars = {}
    newVars[varName] = varValue
    let newConfirmationOverlay = {
        ...confirmationOverlay,
        ...newVars,
    }

    if (newConfirmationOverlay.open === true) {
        document.body.classList.add("no-scroll")
    }

    return {
        payload: newConfirmationOverlay,
        type: "CONFIRMATION_OVERLAY_UPDATED",
    }
}

export const cancelConfirmationOverlay = () => {

    return {
        payload: {
            displayValidationButton: true,
            open: false,
            props: {},
            targetContainer: null,
            targetContent: null,
            targetVars: {},
        },
        type: "CONFIRMATION_OVERLAY_UPDATED",
    }
}

export const hasArdoises = (has) => {
    return {
        payload: has,
        type: "HAS_ARDOISES",
    }
}

export const nextBooking = booking => ({
    payload: booking,
    type: "NEXT_BOOKING",
})

export const hasUnpaidBills = (has) => {
    return {
        payload: has,
        type: "HAS_UNPAID_BILLS",
    }
}

export const editProfilPictureUpload = (rawUrl, croppedUrl) => {
    return {
        payload: {
            croppedUrl: croppedUrl,
            rawUrl: rawUrl,
        },
        type: "PROFIL_PICTURE_UPLOAD",
    }
}

export const editPictureUpload = (identifier, rawUrl, croppedUrl) => {
    let payload = {}
    payload[identifier] = {
        croppedUrl: croppedUrl,
        rawUrl: rawUrl,
    }
    return {
    
        payload: payload,
        // type: 'PROFIL_PICTURE_UPLOAD',
        type: "PICTURE_UPLOAD",
    }
}

/**
 * (Un)freeze the body scroll
 * @param {boolean} freeze 
 */
function freezeBodyScroll(freeze) {
    if (typeof(freeze) !== "boolean") {
        // eslint-disable-next-line no-console
        console.error("Index.js - FreezeBodyScroll ERROR : Parameter [freeze] should be of type [boolean]")
    }
    if (freeze) {
        let forcedTop = window.scrollY
        document.documentElement.style.top = "-" + forcedTop + "px"
        document.body.classList.add("no-scroll")
    } else {
        document.documentElement.style.top = "initial"
        document.body.classList.remove("no-scroll")
    }
}

export const toggleLevel3Overlay = (open, contentIdentifier, loadData = {}, withCloseButton, extraClasses, closeAction, notClosePopin) => {
    open = open === true
    let content = contentIdentifier ? ContentChanger.load(contentIdentifier, loadData) : null

    if (open) {
        freezeBodyScroll(true)
    } else {
        freezeBodyScroll(false)
    }

    withCloseButton = withCloseButton !== false
    closeAction = closeAction || null

    return {
        payload: {
            closeAction: closeAction,
            extraClasses: extraClasses,
            identifier: contentIdentifier,
            notClosePopin: notClosePopin,
            open: open,
            targetContent: content,
            withCloseButton: withCloseButton,
        },
        type: "LEVEL3_OVERLAY_TOGGLED",
    }
}

export const toggleAuthenticationOverlay = (open, props) => {

    open = open === true ? true : false

    let payload = {
        open: open,
    }

    if (open === true) {
        freezeBodyScroll(true)
        if (props) {
            payload = {
                ...props,
                ...payload,
            }
        }
    } else {
        freezeBodyScroll(false)
    }

    return {
        payload: payload,
        type: "AUTHENTICATION_OVERLAY_UPDATED",
    }
}

export const updateMainContentVars = (varName,varValue) => {

    let mainContentVars = store.getState().mainContentVars ? store.getState().mainContentVars : {}
    let newVar = {}
    newVar[varName] = varValue
    let newMainContentVars = {
        ...mainContentVars,
        ...newVar,
    }
    return {
        payload: newMainContentVars,
        type: "MAIN_CONTENT_VARS_UPDATED",
    }
}

export const editUserSessionMeta = (metaName, metaValue) => {
    let userSessionMeta = store.getState().userSessionMeta ? store.getState().userSessionMeta : {}
    let newUserSessionMeta = { ...userSessionMeta }
    newUserSessionMeta[metaName] = metaValue
    return {
        payload: newUserSessionMeta,
        type: "USER_SESSION_META_EDITED",
    }
}

export const loadUserFriends = (friends) => {

    if (!friends) {
        friends = []
    }

    return {
        payload: friends,
        type: "USER_FRIENDS_LOAD",
    }
}

export const loadUserInvitations = (invitations) => {

    if (!invitations) {
        invitations = []
    }

    return {
        payload: invitations,
        type: "USER_INVITATIONS_LOAD",
    }
}
