import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../../components/Buttons/button/button-cta.jsx"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage.js"
import useMediaPath from "../../../../../hooks/useMediaPath.js"
import usePath from "../../../../../routes/services/usePath.js"

import "./CantBook.scss"

export default function CantBook() {
    const navigate = useNavigate()
    const mediaPath = useMediaPath()
    const path = usePath()
    const hasArdoises = useSelector(state => state.hasArdoises)
    const { tu } = useUrbanLanguage()

    return (
        <div className="cantBook">
            <img src={mediaPath([ "/assets/images/noMatch.svg", "/assets/images/padel/icons/icons-terrain-barre.svg" ])} alt="" />
            {hasArdoises ? (
                <>
                    <p>
                        Tu n’es pas autorisé à réserver {tu("booking.favoriteSlotField")} car tu as actuellement une ardoise à régler.<br />

                        Pour plus d’informations, merci de te rapprocher de ton centre.
                    </p>
                    <ButtonCta
                        text="Régler mon ardoise"
                        color="orange"
                        onClick={() => { navigate(path("/profil/ardoises")) }}
                        customCls={"ardoise"}
                    />
                </>
            ) : (
                <p>
                    Tu n’es pas autorisé à réserver {tu("booking.favoriteSlotField")}.<br />
                    Pour plus d’informations, n'hésite pas à nous contacter au 0 806 23 00 24.
                </p>
            )}
        </div>
    )
}
