import classNames from "classnames"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

import { getWordpressPosts } from "../../../pages/league/api/myLeague.js"
import Preloader from "../../loaders/preloader/preloader.jsx"

import "./NewsFeed.scss"

export const NewsFeed = () => {
    const [ news, setNews ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        setNews([])
        getWordpressPosts().then((data) => {
            setNews(data?.data)
        }).finally(() => setIsLoading(false))

    }, [])

    if (!isLoading && !news?.length) {return}

    return (
        <div className={classNames("news-feed", {
            loading: isLoading,
        })}>
            {isLoading ? (
            /* istanbul ignore next */
                <Preloader small/>
            ) : (
                <>
                    <h2>La conf' de presse</h2>
                    <div className="news-list">
                        {news?.map((item) => (
                            <a key={item.ID} className="news-item" href={item?.guid} target="_blank" rel="noreferrer">
                                <img src={item.featured_image_url || "/assets/images/league/placeholders/article-placeholder.jpg"} alt={item.post_title}/>
                                <span>{dayjs(item.post_date).format("DD.MM.YYYY")}</span>
                                <h3>{item.post_title}</h3>
                            </a>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}
