import {
    unpickLeagueRegistrationPlayer,
} from "../../league/api/api.js"
import {
    deletePlayerFromCupTeam,
} from "../urbanCup/api"

function useRemovePlayerFromTeam(tunnelType) {
    switch (tunnelType) {
    case "CUP":
        return deletePlayerFromCupTeam

    case "LEAGUE":
        return unpickLeagueRegistrationPlayer
    }
}

export default useRemovePlayerFromTeam
