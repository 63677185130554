function sortCenters(centers) {
    let newSortedCentersOptions = []
    let ignoreCenterIds = [ 1, 8, 17, 18, 21, 23, 35, 36, 38, 40 ]
    
    centers.forEach(
        center => {
            if (!ignoreCenterIds.includes(center.id)) {
                newSortedCentersOptions.push(
                    {
                        0: center.id,
                        1: center.name, 
                        adresse: center.address,
                        allowRentalWithoutPaying: center.allowRentalWithoutPaying,
                        commercialActivities: center.commercialActivities,
                        lat: center.latitude,
                        lng: center.longitude,
                        proximity: center.proximity,
                        types: center.resourceTypes,
                    },
                )
            }
        },
    )

    newSortedCentersOptions = newSortedCentersOptions.sort(
        function (a, b) {
            return (
                a[1] === b[1] ?
                    0
                    :
                    a[1] < b[1] ?
                        -1
                        :
                        1
            )
        },
    )

    return newSortedCentersOptions
}

export default sortCenters
