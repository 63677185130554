import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import Step from "../../../../components/stepsManagement/Step"
import { createUserPlayerProfile, getContactPlayerProfile, modifyContactPlayerProfile, modifyUserPlayerProfile, postContact } from "../../../../globalAPI/api"
import useAddSearchParam from "../../../../hooks/useAddSearchParams"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import ModalHandler from "../../../../providers/Modal/ModalHandler"
import resetScroll from "../../../../services/resetScroll"
import { updateUser } from "../../../../store/actions/scaffold"
import { setFormEntry } from "../../../../store/forms/actions"
import { nextStep } from "../../../../store/stepsManagement/actions"
import PadelLevels from "../../../profil/PadelLevels"
import { postCupRegistration } from "../../urbanCup/api"
import FriendsPopin from "../components/FriendsPopin/FriendsPopin"
import PlayerCard from "../components/PlayerCard/PlayerCard"

import "./PartnerChoice.scss"

export default function PartnerChoice(props) {
    const themeHeader = useThemeHeader()
    const TournamentRegistration = useSelector(state => state.forms.TournamentRegistration)
    const dispatch = useDispatch()
    const addSearchParams = useAddSearchParam()

    const user = useSelector(state => state.userLoaded)
    const currentStep = useSelector(state =>  state.stepManagement.currentStep)
    const userFriends = useSelector(state => state.userFriends)
    const errorRef = useRef()

    const showModal = () => {
        ModalHandler.show(FriendsPopin, {
            callback: friend => {
                setPartner({
                    emailAddress: friend?.emailAddress ?? "",
                    firstname: friend?.firstname  ?? "",
                    lastname: friend?.lastname  ?? "",
                    level: friend?.playerProfile?.level ?? "",
                    licenseNumber: friend?.playerProfile?.licenseNumber  ?? "",
                    phoneNumber: friend?.phoneNumber  ?? "",
                })
                setDisablePartner(true)
            },
        })
    }

    const [ error, setError ] = useState(false)
    const [ organizer, setOrganizer ] = useState({
        emailAddress: user.emailAddress ?? "",
        firstname: user.firstname ?? "",
        lastname: user.lastname ?? "",
        level: user?.playerProfile?.level ?? "",
        licenseNumber: user?.playerProfile?.licenseNumber ?? "",
        phoneNumber: user.phoneNumber ?? "",
    })

    const [ partner, setPartner ] = useState({
        emailAddress: TournamentRegistration?.viceCaptain?.emailAddress ?? "",
        firstname: TournamentRegistration?.viceCaptain?.firstname ?? "",
        lastname: TournamentRegistration?.viceCaptain?.lastname ?? "",
        level: "",
        licenseNumber: "",
        phoneNumber: TournamentRegistration?.viceCaptain?.phoneNumber ?? "",
    })
    const [ viceCaptain, setViceCaptain ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ disablePartner, setDisablePartner ] = useState(false)

    const step = useRef(null)
    const selectedCup = useSelector(state => state.forms?.selectedPadelCup)
    const friends = useSelector(state => state.userFriends)
    const partnerIsComplete = !!(partner.firstname && partner.lastname && partner.emailAddress && partner.phoneNumber && ((selectedCup.category === 2 && partner.licenseNumber) || selectedCup.category !== 2))

    const changeRegistration = (registration) => {
        dispatch(
            setFormEntry(
                "TournamentRegistration",
                registration,
            ),
        )
    }

    const handleOrganizerLicense = () => {
        if (organizer?.licenseNumber || organizer?.level) {
            const api = user.playerProfile?.playerProfileId ? modifyUserPlayerProfile : createUserPlayerProfile
            const profile = {
                level: organizer?.level,
                licenseNumber: organizer.licenseNumber,
                positioning: user.playerProfile?.positioning === 0 || user.playerProfile?.positioning ? user.playerProfile?.positioning : "",
                ranking: user.playerProfile?.ranking ? user.playerProfile?.ranking : "",
            }
            if (profile?.licenseNumber !== user?.playerProfile?.licenseNumber || profile?.level !== user?.playerProfile?.level || !user.playerProfile) {
                api(user.id, profile).then(res => dispatch(updateUser({ ...user, playerProfile: res })))
            }
        }
    }

    const postCupInfo = async () => {
        setIsLoading(true)
        let teamId = "_NEW"
        let viceCaptainId = viceCaptain?.ozmoSystemId || viceCaptain?.contactId

        let error
        if (partnerIsComplete && !viceCaptain) {
            await postContact({ ...partner, centerId: selectedCup?.centerId || TournamentRegistration?.cup?.centerId, title: 0 }, themeHeader).then(async res =>{
                if (res?.Message) {
                    error = res.Message
                    setIsLoading(false)
                    return setError(res.Message)
                } else if (res?.contact?.id && res?.result === 0) {
                    viceCaptainId = res.contact.id
                    await modifyContactPlayerProfile(viceCaptainId, {
                        level: partner.level ?? "",
                        licenseNumber: partner.licenseNumber ?? "",
                        positioning: 2,
                        ranking: "",
                    }).catch(() => {
                        // continue code execution
                    }).finally(() => setIsLoading(false))
                } else {
                    viceCaptainId = res?.contact?.id
                    setIsLoading(false)
                }
            }).catch(e=>  {
                error = e?.response?.data?.data.Message
                setIsLoading(false)
                return setError(error)
            })
        }
        if (error) {return}

        handleOrganizerLicense()
        if (viceCaptainId) {
            if (!error) {postCupRegistration(
                selectedCup?.centerId || TournamentRegistration?.cup?.centerId,
                selectedCup?.id || TournamentRegistration?.cup?.id,
                teamId,
                `${organizer?.firstname}/${partner?.firstname}`,
                null,
                null,
                null,
                null,
                null,
                true,
                user.contactId,
                viceCaptainId,
                themeHeader,
            ).then(
                async (res) => {
                    const registration = res?.data?.data
                    addSearchParams("partnerId", viceCaptainId)
                    await dispatch(
                        setFormEntry(
                            "selectedPadelCup",
                            registration?.cup,
                        ),
                    )
                    await changeRegistration(registration)
                    await dispatch(
                        setFormEntry(
                            "tunnelTournamentRegistration",
                            {
                                isNewRegistration: true,
                            },
                        ),
                    )
                    setIsLoading(false)
                    if (step.current && registration?.viceCaptain) {
                        step.current.setRecap?.(
                            <div>
                                {registration.viceCaptain.firstname}
                                <br/>
                                {registration.viceCaptain.lastname}
                            </div>,
                        )
                    }
                    // if(!nextStep)
                    dispatch(nextStep())
                },
            )}
        } else if (!error) {
            postCupRegistration(
                selectedCup.centerId || TournamentRegistration?.cup?.centerId,
                selectedCup.id || TournamentRegistration?.cup?.id,
                teamId,
                user?.firstname,
                null,
                null,
                null,
                null,
                null,
                true,
                user.contactId,
                null,
                themeHeader,
            ).then(
                async (res) => {
                    const registration = res?.data?.data
                    addSearchParams("partnerId", "none")
                    await changeRegistration(registration)
                    await dispatch(
                        setFormEntry(
                            "tunnelTournamentRegistration",
                            {
                                isNewRegistration: true,
                            },
                        ),
                    )
                    setIsLoading(false)
                    dispatch(nextStep())
                },
            )
        }
    }

    useEffect(() => {
        const matchingFriend = userFriends?.find(friend => friend.lastname === partner.lastname && friend.firstname === partner.firstname && (friend.emailAddress === partner.emailAddress))
        if (matchingFriend) {
            setViceCaptain(matchingFriend)
        } else {
            setViceCaptain(null)
        }
        if (error) {
            setError(false)
        }
    }, [ partner ])

    useEffect(() => {
        if ((partnerIsComplete || disablePartner) && ((organizer?.licenseNumber && selectedCup.category === 2) || selectedCup.category !== 2)) {
            step?.current?.validate()
        } else {
            step?.current?.unvalidate()
        }
    }, [ partner, selectedCup, disablePartner, organizer ])

    useEffect(() => {
        if (error && errorRef.current) {
            step?.current?.unvalidate()
            errorRef.current.scrollIntoView()
        }
    }, [ error, errorRef ])

    useEffect(() => {
        if (currentStep === 0) {
            setPartner({
                emailAddress: "",
                firstname: "",
                lastname: "",
                level: "",
                licenseNumber: "",
                phoneNumber: "",
            })
            setDisablePartner(false)
            dispatch(
                setFormEntry(
                    "TournamentRegistration",
                    {},
                ),
            )
        }
        if (currentStep === 1) {
            resetScroll()
        }
    }, [ currentStep ])

    useEffect(() => {
        if (TournamentRegistration?.viceCaptain?.Id) {
            setIsLoading(true)
            getContactPlayerProfile(TournamentRegistration?.viceCaptain?.Id).then(async res => {
                await modifyContactPlayerProfile(TournamentRegistration?.viceCaptain?.Id, {
                    level: res.level,
                    licenseNumber: res.licenseNumber,
                    positioning: res.positioning ?? 2,
                    ranking: res.ranking,
                }).then(resu => {
                    if (resu?.Message) {
                        setDisablePartner(true)
                        setViceCaptain({ ...TournamentRegistration.viceCaptain, ...res })
                    } else {
                        setDisablePartner(false)
                    }
                })
                setIsLoading(false)
                setPartner({
                    emailAddress: TournamentRegistration.viceCaptain.emailAddress,
                    firstname: TournamentRegistration.viceCaptain.firstname,
                    lastname: TournamentRegistration.viceCaptain.lastname,
                    level: res.level ?? "",
                    licenseNumber: res.licenseNumber ?? "",
                    phoneNumber: TournamentRegistration.viceCaptain.phoneNumber,
                })
            })
        }
        if (TournamentRegistration?.cup) {
            dispatch(
                setFormEntry(
                    "selectedPadelCup",
                    TournamentRegistration?.cup,
                ),
            )
        }

    }, [ TournamentRegistration ])
    const havePartnerInfos = !!(
        partner.lastname ||
        partner.firstname ||
        partner.emailAddress ||
        partner.phoneNumber ||
        partner.licenseNumber ||
        partner.level
    )

    const openPadelLevel = () => ModalHandler.show(PadelLevels)

    return (
        <Step
            {...props}
            title="Mon partenaire"
            help={<PhoneCallHelp tunnelType="CUP" />}
            helpId={"myPartnerHelp"}
            className="noMarginBottom partnerChoice"
            optionnal={(selectedCup) && !selectedCup?.player2Required && !havePartnerInfos}
            checkBeforeNextStep={postCupInfo}
            promise={true}
            ref={step}
            customSkip={postCupInfo}
        >
            {isLoading && <Preloader fixed />}
            <div className="formsHolder noHPadding c-row justify-center layoutSmall">
                <div className="c-col c-col--12">
                    {friends?.length > 0 && (
                        <div className="addFriends">
                            <div className="partnerInscription">
                                <span>Mon partenaire est déjà</span>
                                <span className="spaceBefore">inscrit sur MyUrban ?</span>
                            </div>
                            <ButtonCta
                                className="partnerChoiceButton"
                                isAlt
                                text= "Sélectionner mon partenaire"
                                onClick={showModal}
                            />
                        </div>
                    )}
                    <div className="players">
                        <PlayerCard
                            player={organizer}
                            setPlayer={setOrganizer}
                            onlyLicense
                            openPadelLevel={openPadelLevel}
                            required={selectedCup?.category === 2}
                        />
                        <PlayerCard
                            ref={errorRef}
                            error={error}
                            removePlayer={() => {
                                setDisablePartner(false)
                                setPartner({
                                    emailAddress: "",
                                    firstname: "",
                                    lastname: "",
                                    level: "",
                                    licenseNumber: "",
                                    phoneNumber: "",
                                })
                            }}
                            required={selectedCup?.category === 2 || selectedCup?.player2Required || disablePartner || havePartnerInfos}
                            player={partner}
                            setPlayer={setPartner}
                            licenseAlert={selectedCup?.category === 2 && partner && !partner.licenseNumber}
                            disabled={disablePartner}
                            openPadelLevel={openPadelLevel}
                        />
                    </div>
                </div>
            </div>
        </Step>
    )
}
