import { Controller, useFormContext } from "react-hook-form"

import TextArea from "../../../components/Forms/textArea/TextArea.jsx"
import { Select } from "../../../components/Inputs/NewSelect/Select.jsx"
import { businessLeagueDaysOptions, businessLeagueHoursOptions } from "../service/inputs.js"
import { useBusinessFormNavigation } from "../store/useBusinessFormNavigation.js"

export const Preferences = () => {
    const methods = useFormContext()
    const { nextStep } = useBusinessFormNavigation()
    const onSubmit = methods.handleSubmit(() => nextStep())

    return (
        <form onSubmit={onSubmit}>
            <h2>Vos préférences</h2>
            <p>Cette information nous permet d’adapter le calendrier de la saison mais tous vos matchs ne seront pas
                joués dans ces créneaux.</p>
            <Controller
                render={({ field: { onChange, value } }) => (
                    <Select
                        options={businessLeagueDaysOptions}
                        hideSelectedOptions={false}
                        value={value}
                        onChange={onChange}
                        isMulti
                        menuPlacement={"auto"}
                        placeholder="Sélectionne tes jours"
                        label="Jours de match préférés"
                        error={methods.formState.errors?.preferences?.preferredDays?.message}
                        required
                    />
                )}
                rules={{ required: true }}
                name={"preferences.preferredDays"}
                control={methods.control}
            />
            <Controller
                render={({ field: { onChange, value } }) => (
                    <Select
                        options={businessLeagueHoursOptions}
                        hideSelectedOptions={false}
                        value={value}
                        onChange={onChange}
                        isMulti
                        menuPlacement={"auto"}
                        placeholder="Sélectionne tes créneaux"
                        label="Créneaux horaires préférés"
                        error={methods.formState.errors?.preferences?.preferredHours?.message}
                        required
                    />
                )}
                rules={{ required: true }}
                name={"preferences.preferredHours"}
                control={methods.control}
            />
            <h2>Commentaires</h2>
            <Controller
                render={({ field: { onChange, value } }) => (
                    <TextArea
                        placeholder="Commentaires"
                        labelText="Votre commentaire"
                        value={value}
                        onChange={onChange}
                        error={methods.formState.errors?.preferences?.comment?.message}
                    />
                )}
                rules={{ required: true }}
                name={"preferences.comment"}
                control={methods.control}
            />
        </form>
    )
}
