
const locationHours = [
    {
        display: "09h",
        value: "T08:59:00",
    },
    {
        display: "10h",
        value: "T09:59:00",
    },
    {
        display: "11h",
        value: "T10:59:00",
    },
    {
        display: "12h",
        value: "T11:59:00",
    },
    {
        display: "13h",
        value: "T12:59:00",
    },
    {
        display: "14h",
        value: "T13:59:00",
    },
    {
        display: "15h",
        value: "T14:59:00",
    },
    {
        display: "16h",
        value: "T15:59:00",
    },
    {
        display: "17h",
        value: "T16:59:00",
    },
    {
        display: "18h",
        value: "T17:59:00",
    },
    {
        display: "19h",
        value: "T18:59:00",
    },
    {
        display: "20h",
        value: "T19:59:00",
    },
    {
        display: "21h",
        value: "T20:59:00",
    },
    {
        display: "22h",
        value: "T21:59:00",
    },
    {
        display: "23h",
        value: "T22:59:00",
    },
]

export default locationHours
