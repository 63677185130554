import { useEffect, useState } from "react"

import CreneauDispo from "../../../kids/birthdayReservation/steps/CreneauxDispo"

function useTunnelTypeFormName(tunnelType) {
    const [ formName, setFormName ] = useState("")

    useEffect(
        () => {
            switch (tunnelType) {
            case "BIRTHDAY":
                setFormName("ozmoSubscription")
                break
            case "SCHOOL":
                setFormName(CreneauDispo.formName)
                break
            case "CUP":
                setFormName("TournamentRegistration")
                break
            case "TRAINING":
                setFormName("ozmoSubscription")
                break
            case "LEAGUE":
                setFormName("TournamentRegistration")
                break
            }
        }, [],
    )

    return formName
}

export default useTunnelTypeFormName
