import PropTypes from "prop-types"

import "./TeamScorersSummary.scss"

export const TeamScorersSummary = ({ goals, teamId }) => {
    const filteredGoals = goals.filter(goal => goal.teamId === teamId)

    const goalCounts = filteredGoals.reduce((acc, goal) => {
        acc[goal.playerId] = acc[goal.playerId] || { count: 0, name: goal?.playerFullName ||  goal?.playerName }
        acc[goal.playerId].count += 1
        return acc
    }, {})

    const topScorers = Object.values(goalCounts)
        .sort((a, b) => b.count - a.count)
        .slice(0, 5)

    return (
        <div className="scorersSummary">
            <h3 className={"uppercase tw-bold"}>BUTEURS</h3>
            <ul>
                {topScorers.map((scorer, index) => (
                    <li key={index} className={"tw-regular"}>{scorer.name} <span>({scorer.count})</span></li>
                ))}
            </ul>
        </div>

    )
}

TeamScorersSummary.propTypes = {
    goals: PropTypes.array,
    teamId: PropTypes.number,
}
