export const GO_NEXT_AUTH_STEP = "GO_NEXT_AUTH_STEP"
export const GO_PREV_AUTH_STEP = "GO_PREV_AUTH_STEP"
export const RESET_AUTH_STEPPER = "RESET_AUTH_STEPPER"

export function goNextAuthStep() {
    return {
        type: GO_NEXT_AUTH_STEP,
    }
}

export function goPrevAuthStep() {
    return {
        type: GO_PREV_AUTH_STEP,
    }
}

export function resetAuthStepper() {
    return {
        type: RESET_AUTH_STEPPER,
    }
}
