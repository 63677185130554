import classNames from "classnames"
import { func } from "prop-types"
import { useSelector } from "react-redux"

import { useIsWebview } from "../../../../components/ThemeContext/ThemeContext"
import useCookies from "../../../../hooks/useCookies"
import useThemeFeatures from "../../../../hooks/useThemeFeatures.js"
import { useAssets } from "../../../../providers/AssetsProvider.jsx"
import UserAgentHandler from "../../../../services/UserAgentHandler"
import ProfilCardWrapper from "../ProfilCardWrapper/ProfilCardWrapper"

import "./ContactMe.scss"

export default function ContactMe({ toggleDisplayPhone, setDisplayEmail }) {
    const userLoaded = useSelector(state => state.userLoaded)
    const isWebview = useIsWebview()
    const { isAppV2 } = useCookies()
    const { m } = useAssets()
    const themeFeatures = useThemeFeatures()

    return (
        <div className={classNames("contact-me", {
            "padding-bottom": (UserAgentHandler.isIOS() || UserAgentHandler.isAndroid()) || (isWebview && !isAppV2),
        })}>
            <h2>Me contacter</h2>
            <ProfilCardWrapper>
                <div className="p-myInformation__infos__line">
                    <div className="half">
                        <h3>Téléphone</h3>
                        <span
                            className="editable-line"
                            onClick={toggleDisplayPhone}
                        >
                            <p>
                                {userLoaded.phoneNumber}
                            </p>
                            <img src={m("icons.profil.pencil")} alt="Editer" />
                        </span>
                    </div>
                    <div className="half">
                        <h3>{themeFeatures([ "E-mail", "E-mail", "E-mail Pro" ])}</h3>
                        <span className="editable-line"
                            onClick={
                                () => {
                                    setDisplayEmail(true)
                                }
                            }
                        >
                            <p>
                                {userLoaded.emailAddress}
                            </p>
                            <img src={m("icons.profil.pencil")} alt="Editer" />
                        </span>
                    </div>
                </div>
            </ProfilCardWrapper>
        </div>
    )
}

ContactMe.propTypes = {
    setDisplayEmail: func,
    toggleDisplayPhone: func,
}
