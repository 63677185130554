/* eslint-disable no-irregular-whitespace */
import classNames from "classnames"
import { bool, func, object } from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import CheckBlock from "../../Forms/CheckBlock/CheckBlock.jsx"
import Preloader from "../../loaders/preloader/preloader.jsx"
import DateDisplay from "../../Time/DateDisplay.js"

import "./LeagueCheckblock.scss"

function LeagueCheckblock({
    league,
    selectedLeague,
    onClick,
    isLoading,
}) {

    const alreadySubscribedLeagueIds = useSelector(state => state.forms.alreadySubscribedLeagueIds)
    const remainingPlacesContent = league.remainingPlaces === 0 ?
        <strong className="complet">COMPLET</strong>
        : (
            <>
                {league.playerMatchPrice && (
                    <div
                        className={classNames("playerMatchPrice", {
                            marginBottom: league.remainingPlaces !== null && league.remainingPlaces <= 3,
                        })}
                    >
                        {league.playerMatchPrice}€ / joueur / match
                    </div>
                )}
                {league.remainingPlaces !== null && league.remainingPlaces <= 3 && (
                    <strong className="remainingPlaces">
                        {
                            league.remainingPlaces + " place" +
                    (league.remainingPlaces > 1 ? "s" : "") + " restante" +
                    (league.remainingPlaces > 1 ? "s" : "")
                        }
                    </strong>
                )}
            </>
        )

    return (
        <React.Fragment key={league.id}>
            <CheckBlock
                cls="league"
                callback={onClick}
                checked={selectedLeague ?
                    parseInt(league.id) === parseInt(selectedLeague.id) && 
                    (alreadySubscribedLeagueIds && !alreadySubscribedLeagueIds.includes(league.id))
                    : 
                    false
                }
                name="leagues"
                value={league.id}
                disabled={
                    (alreadySubscribedLeagueIds?.includes(league.id))
                    ||
                    (league.remainingPlaces === 0 && !league.waitingListOpen)
                    || (!league.waitingListOpen && league.isFull)
                }
            >
                <div className="radioList__item--content">
                    <span className="titlePrice">
                        <span className="radioList__item__title">
                            {league.name}
                        </span>
                    </span>

                    <span className="radioList__item__desc">
                        <span className="small-desc">
                            Début : <DateDisplay stringDate={league.start} slashDisplay={true}/>
                        </span>
                        {!!league.maxRegistrations && 
                            (<span className="small-desc">
                                Nombre d'équipes : {league.maxRegistrations}
                            </span>)
                        }
                        
                        {(!league.isFull || (league.waitingListOpen && league.isFull)) && 
                            (<span className="radioList__item__price">
                                <strong className="amount">
                                    {league.amount}€
                                </strong>
                            </span>)
                        }
                        
                        <span className="radioList__item__price">

                            {(alreadySubscribedLeagueIds?.includes(league.id)) ?
                                <strong className="alreadySubscribed">Déjà inscrit</strong>
                                :
                                remainingPlacesContent
                            }
                        </span>

                    </span>
                    
                </div>
                {
                    (isLoading && selectedLeague && (league.id === selectedLeague.id)) &&
                        <Preloader cls="tournament-checkblock-loader"/>
                }
            </CheckBlock>
        </React.Fragment>
    )
}

LeagueCheckblock.propTypes = {
    isLoading: bool,
    league: object,
    onClick: func,
    selectedLeague: object,
}

export default LeagueCheckblock
