export const centersToExclude = [
    1,
    38,
    36,
    35,
    23,
    18,
    17,
    8,
    40,
    21,
]
