import PropTypes from "prop-types"
import { components } from "react-select"

export const CustomMultiValueRemove = props => {
    return (
        <components.MultiValueRemove
            {...props}
            innerProps={{
                ...props.innerProps,
                className: "select-input-multi-value-remove",
            }}
        >
           X
        </components.MultiValueRemove>
    )
}

CustomMultiValueRemove.propTypes = {
    innerProps: PropTypes.object,
}
