import { useEffect, useRef } from "react"

import Form from "../Forms/Form/Form"
import InputDate from "../Forms/InputDate/InputDate"
import { SelectInput } from "../Inputs/Select/SelectInput.jsx"
import { TextInput } from "../Inputs/TextInput/TextInput.jsx"
import { useSchoolTrainingCurrent } from "../SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"

import "./KidForm.scss"

export default function KidForm() {
    const currentSelection = useSchoolTrainingCurrent()
    const { value: { kidForm, selectedKid }, setValue: setCurrentSelection } = currentSelection ||  /* istanbul ignore next */{}
    const { firstname, lastname, birthDate, gender } = kidForm

    const formRef = useRef()

    const genders = [
        {
            label: "Garçon",
            value: 0,
        },
        {
            label: "Fille",
            value: 1,
        },
    ]

    const setInContext = (key, value) => setCurrentSelection(prev => ({ ...prev, kidForm: {
        ...kidForm, [key] : value,
    } }))

    useEffect(() => {
        formRef?.current?.scrollIntoView({ behavior: "smooth" })
    }, [ selectedKid ])

    return (
        <div className="formsHolder c-row justify-center align-start layoutSmall kidForm">
            <div className="c-col c-col--12 c-col--sm--12 no-padding" id="child-form">
                <Form
                    title={
                        (firstname && lastname) ? (
                            <>
                                {firstname} <strong>{lastname}</strong>
                            </>)
                            : 
                            "Ajouter un enfant"
                    }
                    desc={
                        selectedKid?.id ? 
                            "Merci de vérifier et compléter les informations suivantes" 
                            : 
                            "Remplis les informations concernant ton enfant"
                    }
                    ref={formRef}
                >
                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <TextInput
                            label="Prénom"
                            value={firstname}
                            onChange={val => setInContext("firstname", val)}
                            required
                        />
                    </div>

                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <TextInput
                            label="Nom"
                            value={lastname}
                            onChange={val => setInContext("lastname", val)}
                            required
                        />
                    </div>

                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <InputDate
                            label={(
                                <span>Date de naissance 
                                    <span className="label-star">*</span>
                                </span>
                            )}
                            bind={[ birthDate, "birthDate", setInContext ]}
                            max={new Date()} 
                            required
                        />
                    </div>

                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <SelectInput
                            id={"gender"}
                            label={"Civilité"}
                            options={genders}
                            required
                            value={gender}
                            onChange={(event)=>{setInContext("gender", event.target.value)}}
                            placeholderOption="Genre"
                        />
                    </div>
                </Form>
            </div>
        </div>
    )
}
