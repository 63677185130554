import classNames from "classnames"
import { arrayOf, bool, func, node, number, object, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"

import "./FoldableSection.scss"

function FoldableSection({ cls, summary, detailsContent, isSelected, identification, selectSection, disabled }) {
    const [ isFold, setIsFold ] = useState(!disabled)
    const [ gridCols, setGridCols ] = useState(0)

    useEffect(() => {
        const haveOnlyOne = Array.isArray(detailsContent) && detailsContent?.find(item => {
            return item.props.className?.includes("onlyOne")
        })
        const cols = haveOnlyOne ? detailsContent?.length - 1 : detailsContent?.length
        setGridCols(cols)
    }, [ detailsContent ])

    return (
        <section
            className={classNames("foldableSection", { [cls]: cls })}
            data-testid="foldableSection"
            onClick={() => {
                if (disabled) {return}
                if (selectSection) {
                    if (isSelected) {
                        selectSection(null)
                    } else {
                        selectSection(identification)
                    }
                } else {
                    setIsFold(!isFold)
                }
            }}
        >
            <div
                className={classNames({
                    open: (selectSection && isFold) || (!selectSection & !isFold),
                })}
            >
                <summary>
                    {summary}
                </summary>
                <div
                    className={classNames("detailsContent", {
                        hide: (selectSection && !isSelected) || (!selectSection && isFold),
                        [`grid-cols-${gridCols <= 3 ? gridCols : 3}`]: gridCols,
                    })}
                >
                    { detailsContent }
                </div>
            </div>
        </section>
    )
}

FoldableSection.propTypes = {
    cls: string,
    customId: string,
    detailsContent: oneOfType([ string, node, arrayOf(node) ]),
    disabled: bool,
    identification: oneOfType([ number, string, bool, object ]),
    isSelected: bool,
    selectSection:  oneOfType([ func, bool ]),
    summary: oneOfType([ string, node, arrayOf(node) ]),
}

export default FoldableSection
