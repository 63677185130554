export class BusinessLeagueTunnelPaths {
    static basePath = "/enregistrement/"
    static loaderId = "businessLeagueTunnel"

    static stepGeneral = "general"
    static stepCaptain = "capitaine"
    static stepCommunication = "responsable-communication"
    static stepPreferences = "preferences"
    static stepConfirmation = "confirmation"

    static steps = [
        this.stepGeneral,
        this.stepCaptain,
        this.stepCommunication,
        this.stepPreferences,
    ]
}
