import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { getSupersubReservationReponses } from "./supersubRating.api"
import LocationInfoBar from "../../../components/LocationInfoBar/LocationInfoBar"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import Step from "../../../components/stepsManagement/Step"
import StepsManager from "../../../components/stepsManagement/StepManager"
import SupersubRatingCheckBloc from "../../../components/SuperSub/SupersubRatingCheckBloc/SupersubRatingCheckBloc"
import Preloader from "../../../entries/main/../../components/loaders/preloader/preloader"
import { LocationPaths } from "../../../routes/ApplicationPaths/locationPaths.js"
import usePath from "../../../routes/services/usePath"
import { clearAllForms } from "../../../store/forms/actions"

import "./supersubRating.scss"

function SupersubRating() {
    const navigate = useNavigate()
    const path = usePath()
    const params = useParams()
    const locationId = params.locationId
    const [ location, setLocation ] = useState({})
    const [ supersubRequestsResponses, setSupersubRequestsResponses ] = useState(null)
    const [ selectedResponse, setSelectedResponse ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    
    const help = <PhoneCallHelp tunnelType="SUPERSUB"/>

    const loadUserFriends = useSelector(state => state.userFriends)

    const dispatch = useDispatch()

    useEffect(
        () => {
            dispatch(clearAllForms())
            if (locationId) {
                setLoading(true)
                getSupersubReservationReponses(locationId, 1).then( // 1 : status validé
                    (res) => { 
                        setSupersubRequestsResponses(res.items)

                        if (!location.id && res.items && res.items.length > 0) {
                            setLocation(res.items[0].request.reservation)
                        }

                        setLoading(false)
                    },
                )
            }
        }, [],
    )

    return (
        <StepsManager hideProgressPar={true}>
            <Step
                title="SUPERSUB"
                help={ help }
                helpId="selectSuperSubHelp"
                className="chooseCenterAndType selectSuperSub"
                onReturn={
                    () => {
                        dispatch(clearAllForms())
                        navigate(path(LocationPaths.toReservationPlayers(locationId)))
                    }
                }
                returnText="Précedent"
                otherId="supersubRating"
                hideNextBtn={true}
            >
                <div className="formsHolder c-row justify-center align-start 
                    layoutSmall c-col c-col--10 c-col--sm--12">
                    
                    <div className="c-col c-col--10 c-col--sm--12">
                        <LocationInfoBar location={location} displaySelectSuperSubData={false}/>
                    </div>
                    <div className="c-col c-col--10 c-col--sm--12">
                        <div className="radioList sm--checkbox">
                            {(supersubRequestsResponses && supersubRequestsResponses.length > 0) &&
                                supersubRequestsResponses.map(
                                    (r, index) => (
                                        <SupersubRatingCheckBloc 
                                            areFriends={loadUserFriends.some(friend => friend.ozmoSystemId === r.contact.id)}
                                            response={r}
                                            id={r.id}
                                            checked={selectedResponse && selectedResponse.id === r.id}
                                            clickOnCheckblock={(clickedResp) => {
                                                if (selectedResponse && selectedResponse === clickedResp) {
                                                    setSelectedResponse(null)
                                                } else {
                                                    setSelectedResponse(clickedResp)
                                                }
                                        
                                            }}
                                            key={"subrating-" + index}
                                        />
                                    ),
                                )
                            }
                            { loading &&
                                <Preloader/>
                            }
                        </div>
                    </div>
                </div>
            </Step>
        </StepsManager>
    )

}

SupersubRating.formName = "supersubRating"

SupersubRating.propTypes = {
    locationId: PropTypes.number,
}

export default SupersubRating
