import useThemeFeatures from "../../../../hooks/useThemeFeatures"

export default function UsCardsPreview() {
    const themeFeatures = useThemeFeatures()
    return (
        <div className="carte-us-preview">
            <div className="subtitle important-text-black important-text-black-2">
                Régle tes achats avec la carte {themeFeatures([ "us", "up" ])}
            </div>
            <img className="carte-us-picto" src={themeFeatures([ "/assets/images/Carte-US-O.png", "/assets/images/padel/upCardPreview.png" ])}  />
            <div className="description">
                <div>
                    Reçois un bonus en créditant de 100€, 200€ ou 300€, ta carte {themeFeatures([ "UrbanSoccer", "UrbanPadel" ])} !
                </div>
                <div className="orange">
                    N'attends plus et viens retirer ta carte dans ton cente {themeFeatures([ "UrbanSoccer", "UrbanPadel" ])} préféré !
                </div>
            </div>
        </div>
    )
}
