import PropTypes from "prop-types"

import UserAgentHandler from "../../services/UserAgentHandler"

import "./UpdateApp.scss"

export default function UpdateApp({ isMaintenanceMode = false }) {
    return (
        <div className="updateApp">
            <img src="/assets/images/blason-US.svg" />
            <div className="title"><div>TON APP</div><div>URBANSOCCER</div><div>EVOLUE</div></div>
            <div className="desc">
                {isMaintenanceMode ? (
                    "Une maintenance de ton application est en cours. En attendant, tu peux te rendre directement sur le site web"
                ) : (
                    "Pour profiter des nouvelles fonctionnalités, une mise à jour est nécessaire."
                )}
            </div>
            <div className="buttonWrapper">
                {!isMaintenanceMode && (
                    <a
                        href={UserAgentHandler.isIOS() === true ?
                            "https://apps.apple.com/fr/app/id1550182639"
                            :
                            "https://play.google.com/store/apps/details?id=fr.urbansoccer.app"
                        }
                        className="button orangeFull"
                    >
                        <span>METTRE À JOUR</span>
                    </a>
                )}
            </div>
        </div>
    )
}

UpdateApp.propTypes = {
    isMaintenanceMode: PropTypes.bool,
}
