import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import { BookableListing } from "./BookableListing/BookableListing"
import CantBook from "./CantBook/CantBook.jsx"
import { FormErrorContent } from "./FormErrorContent/FormErrorContent"
import { NewBookingFilters } from "./NewBookingFilters/NewBookingFilters"
import NewBookingParams from "./NewBookingParams/NewBookingParams"
import { NoListingContent } from "./NoListingContent/NoListingContent"
import ProximityCenters from "./ProximityCenters/ProximityCenters"
import {
    fetchCentersAndActivities,
    formArgs,
    formatAndUpdateSearchParams,
    getAppropriateHeadingTitle,
} from "./services/function"
import { deserializationStrategies } from "./services/serialization/deserializationStrategies"
import { useBookingStore } from "./services/store"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import Step from "../../../../components/stepsManagement/Step"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext"
import useUrbanLanguage from "../../../../hooks/urbanLanguage/useUrbanLanguage"
import useThemeHeader from "../../../../hooks/useThemeHeader.js"
import { getActivitiesForRessourcesTypes } from "../services/function/functions"

import "./BookingPage.scss"

export const BookingPage = ({ type }) => {
    const navigate = useNavigate()
    const theme = useTheme()
    const userLoaded = useSelector(state => state.userLoaded)
    const hasArdoises = useSelector(state => state.hasArdoises)
    const canBook = userLoaded.canBook
    const dispatch = useDispatch()
    const themeHeader = useThemeHeader()

    const { tu } = useUrbanLanguage()
    const [ searchParams, setSearchParams ] = useSearchParams()
    const {
        isLoading,
        setBookingInfosData,
        bookingInfosData,
        setIsLoading,
        setSavedFilters,
        savedFilters,
        setShouldTriggerSlotNotification,
        summaryData,
        fromOrderSummary,
    } = useBookingStore()

    const bookingForm = useForm({
        defaultValues: getInitialFormState(searchParams, savedFilters),
    })

    const { setValue, watch, getValues } = bookingForm
    const centerWatch = watch(formArgs.CENTER_ID)
    
    useEffect(() => {
        // SYNC FORM FROM URL SEARCH PARAMS
        searchParams.forEach((value, key) => {
            const strategies = deserializationStrategies()
            if (strategies[key]) {
                value = strategies[key](value)
            }
            setValue(key, value)
        })
    }, [ searchParams, setValue ])

    useEffect(() => {
        if (searchParams.get(formArgs.FROM_NOTIFICATION)) {
            setValue(formArgs.FROM_NOTIFICATION, null)
            setShouldTriggerSlotNotification(true)
        } else {
            setShouldTriggerSlotNotification(false)
        }

        setIsLoading(true)
        fetchCentersAndActivities(type, userLoaded, searchParams).then((data) => {
            setIsLoading(false)
            const { selectedCenterData, centerOptions, ardoises } = data

            if (hasArdoises) {
                dispatch(
                    hasArdoises(ardoises),
                )
            }

            let infoData = {
                centerOptions: centerOptions,
            }

            if (fromOrderSummary) {
                infoData.centerActivities = summaryData?.centerActivities
            }

            setBookingInfosData(infoData)

            selectedCenterData?.selectedCenterId && setValue(formArgs.CENTER_ID, getValues(
                formArgs.CENTER_ID) || selectedCenterData?.selectedCenterId,
            )

            if (searchParams.get(formArgs.FROM_NOTIFICATION) && !searchParams.get(formArgs.ACTIVITY_ID)) {
                const activityFromResourceType = selectedCenterData?.selectedCenterTypes.find(type => type.key === parseInt(searchParams.get(formArgs.TYPE_IDS)))?.categories[0]?.id
                setValue(formArgs.ACTIVITY_ID, activityFromResourceType)
            }

            selectedCenterData?.centerActivities && setValue(formArgs.ACTIVITY_ID, getValues(
                formArgs.ACTIVITY_ID) || selectedCenterData?.centerActivities[0]?.id,
            )
        })
    }, [])

    useEffect(() => {
        // UPDATES ACTIVITIES WHEN CENTER CHANGES
        if (centerWatch && bookingInfosData?.centerOptions) {
            const centerToSelect = bookingInfosData?.centerOptions.find(center => center[0] === parseInt(centerWatch))
            const centerToSelectTypes = centerToSelect?.types
            const centerToSelectActivities = getActivitiesForRessourcesTypes(centerToSelectTypes, theme)

            setBookingInfosData({
                ...bookingInfosData,
                centerActivities: centerToSelectActivities,
                proximityCenters: centerToSelect?.proximity ?? [],
            })
        } else {
            setBookingInfosData({
                ...bookingInfosData,
                centerActivities: [],
                proximityCenters: [],
            })
        }
    }, [ centerWatch, bookingInfosData?.centerOptions ])

    useEffect(() => {
        const parsedDate = dayjs(getValues(formArgs.DATE))
        const currentDate = dayjs()

        if (parsedDate.isBefore(currentDate, "day")) {
            setValue(formArgs.DATE, dayjs().format("YYYY-MM-DD"))
        }

    }, [ watch(formArgs.DATE) ])

    useEffect(() => {
        // SYNC URL SEARCH PARAMS FROM FORM
        const subscription = watch(() => formatAndUpdateSearchParams(getValues(), setSearchParams, [
            formArgs.TIME_FILTER,
            formArgs.DISCOUNT_FILTER,
            formArgs.DURATION,
            formArgs.TYPE,
        ]))
        return () => subscription.unsubscribe()
    }, [ watch, getValues, setSearchParams ])

    return (
        <div className="stepManager">
            <div className="progressBar">
                <div className="progressBar__inner" style={{ width: "33%" }}/>
            </div>
            {isLoading && <Preloader fixed />}
            <Step
                title={getAppropriateHeadingTitle(theme, tu("field"))}
                help={<PhoneCallHelp tunnelType="RESERVATION" />}
                helpId={"reservationHelp"}
                className="bookingPage arrows-on-sides noMarginBottom"
                onReturn={() => navigate("/")}
                returnText="Accueil"
                hideNextBtn
                otherId={"locationStep1"}
            >
                {canBook && !hasArdoises ? (
                    <FormProvider {...bookingForm}>
                        <NewBookingParams/>
                        <FormErrorContent/>
                        <div>
                            <div className="c-row formsHolder">
                                <NewBookingFilters/>
                                {!isLoading && <NoListingContent/>}
                                <BookableListing/>
                                <ProximityCenters/>
                            </div>
                        </div>
                    </FormProvider>
                ) : <CantBook/>}
            </Step>
        </div>
    )

    function getInitialFormState(searchParams, savedFilters) {
        const initialFormState = {
            [formArgs.DATE]: searchParams.get(formArgs.DATE) || new Date(),
            [formArgs.CENTER_ID]: searchParams.get(formArgs.CENTER_ID) || null,
            [formArgs.ACTIVITY_ID]: searchParams.get(formArgs.ACTIVITY_ID) || null,
            [formArgs.TIME_FILTER]: searchParams.get(formArgs.TIME_FILTER) || null,
            [formArgs.DISCOUNT_FILTER]: searchParams.get(formArgs.DISCOUNT_FILTER) || null,
            [formArgs.DURATION]: searchParams.get(formArgs.DURATION) || null,
            [formArgs.HOUR]: searchParams.get(formArgs.HOUR) || "noHourFilter",
            [formArgs.TYPE]: searchParams.get(formArgs.TYPE) || null,
            [formArgs.TYPE_IDS]: searchParams.get(formArgs.TYPE_IDS) || null,
            [formArgs.FROM_NOTIFICATION]: searchParams.get(formArgs.FROM_NOTIFICATION) || null,
        }

        if (savedFilters && savedFilters.theme !== themeHeader) {
            setSavedFilters(null)
            return initialFormState
        }

        return savedFilters ? {
            ...initialFormState,
            ...savedFilters,
        } : initialFormState
    }
}

BookingPage.propTypes = {
    // needed to determine the type of booking tunnel, as the useThemeHeader isn't correct at page load
    type: PropTypes.oneOf([ 1, 2 ]),
}
