import classNames from "classnames"
import { arrayOf, bool, func, node, object, oneOfType, shape, string } from "prop-types"

import RadioInput from "./RadioInput"

import "./Radio.scss"

const RadioGroup = ({ options, selectedValue, onRadioChange, className, disabled }) => {
    return (
        <div className={classNames("radioGroup", { [className]: className })}>
            {options.map((option) => (
                <RadioInput
                    disabled={disabled[option.value]}
                    key={`radiogroup-item-${option.value}`}
                    name="radioGroup"
                    value={option.value}
                    label={option.label}
                    checked={selectedValue === option.value}
                    onChange={() => {
                        if (selectedValue !== option.value) {
                            onRadioChange(option.value)
                        }
                    }}
                    onClick={() => {
                        if (selectedValue === option.value) {
                            onRadioChange("")
                        }
                    }}
                />
            ))}
        </div>
    )
}

RadioGroup.propTypes = {
    className: oneOfType([ string, bool ]),
    disabled: oneOfType([ object, bool ]),
    onRadioChange: func.isRequired,
    options: arrayOf(
        shape({
            label: oneOfType([ string.isRequired, node.isRequired ]),
            value: string.isRequired,
        }),
    ).isRequired,
    selectedValue: string.isRequired,
}

export default RadioGroup
