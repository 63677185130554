import { useEffect, useState } from "react"

const useElementOnScreen = ({ ref1, ref2, options, botData, topData, ajaxLoading, isLoading, noCurrent }) => {
    const [ ref1IsVisible, setRef1IsVisible ] = useState(false)
    const [ ref2IsVisible, setRef2IsVisible ] = useState(false)

    const callbackFunction1 = (entries) => {
        const [ entry ] = entries
        setRef1IsVisible(entry.isIntersecting)
    }
    const callbackFunction2 = (entries) => {
        const [ entry ] = entries
        setRef2IsVisible(entry.isIntersecting)
    }

    useEffect(
        () => {
            const observer1 = new IntersectionObserver(callbackFunction1, options)
            const observer2 = new IntersectionObserver(callbackFunction2, options)
            const firstRef = noCurrent ? ref1 : ref1.current
            const secondRef = noCurrent ? ref2 : ref2.current
            if (firstRef) {observer1.observe(firstRef)}
            if (secondRef) {observer2.observe(secondRef)}
            return () => {
                if (firstRef) {observer1.unobserve(firstRef)}
                if (secondRef) {observer2.unobserve(secondRef)}
            }
        }, [ ref1, ref2, options, botData, topData, ajaxLoading, isLoading ],
    )

    return [ ref1IsVisible, ref2IsVisible ]
}

export default useElementOnScreen
