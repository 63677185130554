import { 
    removeBirthdayDiscountCode,
} from "../../pages/kids/birthdayReservation/api"
import {
    deleteSchoolDiscountCode,
} from "../../pages/kids/ecoleDeFoot/api"
import {
    removeStageDiscountCode,
} from "../../pages/kids/stageDeFoot/api"
import {
    deleteLeagueDiscountCode,
} from "../../pages/league/api/api.js"
import { removePrebookDiscountCode } from "../../pages/reservation/tunnelReservation/api"
import {
    removeCupPromoCode,
} from "../../pages/teams/urbanCup/api"

function useRemoveDiscountCode(tunnelType) {
    const doNothing = () => {}
    switch (tunnelType) {
    case "BIRTHDAY":
        return removeBirthdayDiscountCode

    case "SCHOOL":
        return deleteSchoolDiscountCode

    case "CUP":
        return removeCupPromoCode

    case "TRAINING":
        return removeStageDiscountCode

    case "LEAGUE":
        return deleteLeagueDiscountCode

    case "SUPERSUB":
        return doNothing

    case "BOOKING":
        return removePrebookDiscountCode
            
    default:
        // eslint-disable-next-line no-console
        console.error("useRemoveDiscountCode - Not implemented TunnelType : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        break

    }
}

export default useRemoveDiscountCode
