import classNames from "classnames"
import PropTypes from "prop-types"
import { components } from "react-select"

import { useAssets } from "../../../../providers/AssetsProvider.jsx"

export const CustomDropdownIndicator = props => {
    const { isDisabled, isFocused } = props
    const { m } = useAssets()

    return (
        <components.DropdownIndicator
            {...props}
            className={classNames("select-input-dropdown-indicator", {
                disabled: isDisabled,
            })}
        >
            <img src={m("icons.inputs.selectArrow")} alt="Ouvrir liste" className={isFocused ? "focused" : "idle"} />
        </components.DropdownIndicator>
    )
}

CustomDropdownIndicator.propTypes = {
    isDisabled: PropTypes.bool,
    isFocused: PropTypes.bool,
}
