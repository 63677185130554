import { yupResolver } from "@hookform/resolvers/yup"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { BusinessLeagueTunnelBaseLayout } from "./BusinessLeagueTunnelBaseLayout.jsx"
import { postRegistration } from "./service/api.js"
import { formatSubmission } from "./service/service.js"
import { getStepsWithErrors } from "./service/validation.js"
import { useBusinessFormNavigation } from "./store/useBusinessFormNavigation.js"
import ButtonCta from "../../components/Buttons/button/button-cta.jsx"
import Preloader from "../../components/loaders/preloader/preloader.jsx"

import "./BusinessLeagueFormLayout.scss"

const swipeDirection = (currentKey, lastKey) => currentKey > lastKey ? "30%" : "-30%"

const animationVariants = direction => ({
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, transition: { duration: 0 }, x: direction },
    initial: { opacity: 0, x: direction },
})

export const BusinessLeagueTunnelStepLayout = () => {
    const { getCurrentStep, steps, prevStep, nextStep , currentValidationSchema } = useBusinessFormNavigation()
    const location = useLocation()
    const lastTabKey = useRef(0)
    const navigate = useNavigate()
    const [ isLoading, setIsLoading ] = useState(false)

    const methods = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        resolver: currentValidationSchema ? yupResolver(currentValidationSchema) : null,
    })

    const path = location.pathname.split("/").pop()

    useEffect(() => {
        const currentTabKey = steps.findIndex(step => step === path)

        if (currentTabKey !== -1) {
            lastTabKey.current = currentTabKey
        }
    }, [ location.pathname, steps ])

    const handlePrev = () => prevStep()
    const handleNext = methods.handleSubmit(() => {
        if (getCurrentStep() < steps.length - 1) {
            nextStep()
        } else {
            methods.handleSubmit((data) => {
                setIsLoading(true)

                data.general.centerLabel = data.general.center.label
                data.general.center = data.general.center.value

                postRegistration({
                    centerId: methods.watch("general.center"),
                    data: formatSubmission(data),
                    mailDest: data?.captain?.email,
                }).then((res) => {
                    if (res?.code === 204) {
                        setIsLoading(false)
                        navigate("/confirmation", {
                            replace: true,
                            state : {
                                message: "Les informations saisies sur votre équipe ont bien été envoyées.",
                                noThanks: true,
                                success: true,
                                title: "Enregistrement terminé",
                                tunnelType: "BUSINESS_LEAGUE",
                            },
                        })
                    } else {
                        setIsLoading(false)
                    }
                }).finally(() => setIsLoading(false))
            })()
        }
    })

    return (
        <BusinessLeagueTunnelBaseLayout>
            {isLoading && <Preloader fixed/>}
            <FormProvider {...methods}>
                <div className="businessLeagueForm">
                    <div className={"c-row"}>
                        <h1>Vos informations</h1>
                        <div className={"stepper"}>
                            {steps?.map((step, index) => {
                                return (
                                    <div key={index} className={classNames("step", {
                                        active: getCurrentStep() === index,
                                        error: getStepsWithErrors(methods.formState.errors).includes(steps[index]),
                                    })}/>
                                )
                            })}
                        </div>
                        <AnimatePresence mode={"popLayout"}>
                            <motion.div
                                variants={animationVariants(swipeDirection(steps.findIndex(step => step === path), lastTabKey.current))}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                transition={{ duration: 0.6, ease: [ 0.16, 1, 0.3, 1 ] }}
                                className="tab-content"
                            >
                                <Outlet/>
                            </motion.div>
                        </AnimatePresence>
                        <div className="navigation-buttons">
                            {getCurrentStep() > 0 && (
                                <ButtonCta isAlt text="Retour" onClick={handlePrev}/>
                            )}
                            <ButtonCta type="button"
                                text={getCurrentStep() === steps.length - 1 ? "Envoyer" : "Suivant"}
                                onClick={handleNext}
                            />
                        </div>
                    </div>
                </div>
            </FormProvider>
        </BusinessLeagueTunnelBaseLayout>
    )
}
