import { useState } from "react"

import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import { LeagueModalBase } from "../LeagueModalBase/LeagueModalBase.jsx"
import PlayerStatusManagement from "../PlayerStatusManagement/PlayerStatusManagement.jsx"

import "./MVPModal.scss"

export const MVPModal = ModalHandler.create(({ visible, hide, players, onValidate, currentVote }) => {
    const [ selected, setSelected ] = useState(currentVote)

    const isCurrentVote = selected?.championshipPlayerId === currentVote?.championshipPlayerId

    return (
        <LeagueModalBase
            onClose={hide}
            visible={visible}
            className={"mVPModal"}
            icon={"/assets/icons/starStrokeWhite.svg"}
            title={<>VOTE POUR  <br/><strong>— L'HOMME DU MATCH ?</strong></>}
        >
            {!players.length && (
                <>
                    <p>Aucun joueur présent pour ce match.</p>
                    <p>Ajoute tes joueurs à l'effectif et fais la compo pour pouvoir désigner l'homme du match.</p>
                </>
            )}
            {
                players.sort((a, b) => b.currentNbVotes - a.currentNbVotes).map(player => {
                    return (
                        <PlayerStatusManagement
                            player={player}
                            key={`mvpVote-${player.id}`}
                            selected={selected?.id === player.id}
                            isCurrentVote={isCurrentVote}
                            onClick={(player) => {
                                setSelected(player)
                            }}
                            noBottom
                        />
                    )
                })
            }
            <ButtonCta  text={"Valider"} disabled={!selected || isCurrentVote} onClick={() => onValidate(selected)}  />
        </LeagueModalBase>
    )
})
