import classNames from "classnames"

import { useAssets } from "../../../providers/AssetsProvider.jsx"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import { LeagueModalBase } from "../LeagueModalBase/LeagueModalBase.jsx"
import { TeamPicture } from "../TeamPicture/TeamPicture.jsx"

import "./TeamInvitModal.scss"

export const TeamInvitModal = ModalHandler.create(({ visible, hide, team, fromUser, acceptCallback, cls, loggedIn }) => {
    const { m } = useAssets()

    return (
        <LeagueModalBase
            onClose={hide}
            visible={visible}
            className={classNames("teamInvitModal", cls)}
            icon={"/assets/icons/league/teamInvit.svg"}
            title={<>{decodeURIComponent(fromUser)}<br/><strong>— T'INVITE DANS<br />SON ÉQUIPE ?</strong></>}
        >
            <div className="desc">
                <div className="team">
                    <div className="center">
                        <img className="logo" src={m("icons.league.center")} />
                        {team.centerName}
                    </div>
                    <div className="teamName">
                        <TeamPicture image={team.blasonUrl || team.blazon} size={25} />
                        {team.name}
                    </div>
                    <div className="teamName">
                        <img className="logo" src={m("icons.league.cup")} />
                        {team.leagueName}
                    </div>
                </div>
                <div className="action">
                    <div className="label">
                        Souhaites-tu rejoindre<br />
                        cette équipe ?
                    </div>
                    <div className="buttons">
                        <ButtonCta text="Non" isCta2Alt onClick={hide} />
                        <ButtonCta text={loggedIn ? "Oui" : "Oui (se connecter)"} onClick={acceptCallback} />
                    </div>
                </div>
            </div>
        </LeagueModalBase>
    )
})

TeamInvitModal.propTypes = {}
