import classNames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"

import ButtonCta from "../../../../../../components/Buttons/button/button-cta"
import CheckBlock from "../../../../../../components/Forms/CheckBlock/CheckBlock"
import Modal from "../../../../../../components/Modal/Modal"
import { useSchoolTrainingCurrent, useSchoolTrainingFilters, useSchoolTrainingTunnel } from "../../../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import useMediaPath from "../../../../../../hooks/useMediaPath"
import { padelLevels } from "../../../../../../model/data/padelLevels"
import UserAgentHandler from "../../../../../../services/UserAgentHandler"

import "./SlotChoiceFilters.scss"

export const SlotChoiceFilters = ({ isOpen, setIsOpen }) =>  {
    const { value: { availableSlots } } = useSchoolTrainingCurrent()
    const { value: tunnelType } = useSchoolTrainingTunnel()
    const { value: { filterLevel, filterType, filterCenter, availableType, availableCenter }, setValue: setCurrentFilter } = useSchoolTrainingFilters()

    const [ selectedLevel, setSelectedLevel ] = useState(null)
    const [ selectedType, setSelectedType ] = useState(null)
    const [ centersOptions, setCentersOptions ] = useState([])
    const [ selectedCenter, setSelectedCenter ] = useState(null)
    const [ filterChanged, setFilterChanged ] = useState(false)

    const currentSelection = useSchoolTrainingCurrent()
    const { value: { isAdult, selectedAreaId, centers, areas } } = currentSelection
    const mediaPath = useMediaPath()

    const levelScroll = useRef()

    const  typeOption = [
        { label: "Individuel", value: 0 },
        { label: "Collectif", value: 1 },
    ]

    useEffect(() => {
        if (selectedAreaId && centers?.length && areas?.length) {
            const selectedArea = areas.find(area => area. id === selectedAreaId)
            const availableCenter = centers?.filter(center => selectedArea?.centers?.includes(center.id))
            setCentersOptions(availableCenter)
        }
    }, [ selectedAreaId, centers, areas ])

    useEffect(() => {
        if (selectedLevel !== filterLevel || selectedType !== filterType || selectedCenter !== filterCenter) {
            setFilterChanged(true)
        } else {
            setFilterChanged(false)
        }
    }, [ selectedLevel, selectedType, selectedCenter ])

    useEffect(() => {
        setSelectedLevel(filterLevel)
        setSelectedType(filterType)
        setSelectedCenter(filterCenter)
    }, [ filterLevel, filterType, filterCenter ])

    useEffect(() => {
        if (availableSlots?.length) {
            const slotsMinLevels = []
            const slotsMaxLevels = []
            const slotsLevels = []
            const slotsType = []
            const slotsCenter = []
            const target = slot => tunnelType === "TRAINING" ? slot : slot.schoolDetails
            availableSlots.forEach(slot => {
                const targ = target(slot)
                if (targ?.minLevel && !slotsMinLevels.includes(targ.minLevel)) {
                    slotsMinLevels.push(targ.minLevel)
                }
                if (targ?.maxLevel && !slotsMinLevels.includes(targ.maxLevel)) {
                    slotsMaxLevels.push(targ.maxLevel)
                }
                if (targ?.individualCollective >= 0 && !slotsType.includes(targ.individualCollective)) {
                    slotsType.push(targ.individualCollective)
                }
                if (targ?.centerId && !slotsCenter.includes(targ.centerId)) {
                    slotsCenter.push(targ.centerId)
                }
            })
            if (slotsMinLevels.length && slotsMaxLevels.length) {
                slotsMinLevels.sort(function (a, b) {return a - b})
                slotsMaxLevels.sort(function (a, b) {return b - a})
                for (let i = slotsMinLevels[0]; i <= slotsMaxLevels[0]; i++) {
                    slotsLevels.push(i)
                }
            } else if (slotsMinLevels.length) {
                slotsMinLevels.sort(function (a, b) {return a - b})
                for (let i = slotsMinLevels[0]; i <= slotsMinLevels[slotsMinLevels?.length - 1]; i++) {
                    slotsLevels.push(i)
                }
            } else if (slotsMaxLevels.length) {
                slotsMaxLevels.sort(function (a, b) {return a - b})
                for (let i = slotsMaxLevels[0]; i <= slotsMaxLevels[slotsMaxLevels?.length - 1]; i++) {
                    slotsLevels.push(i)
                }
            }

            setCurrentFilter(prev => ({ ...prev,
                availableCenter: slotsCenter,
                availableLevel: slotsLevels,
                availableType: slotsType,
                filterCenter: filterCenter && slotsLevels.includes(filterCenter) ? filterCenter : null,
                filterLevel: filterLevel && slotsLevels.includes(filterLevel) ? filterLevel : null,
                filterType: filterType >= 0 && slotsLevels.includes(filterType) ? filterType : null,
            }))
        } else {
            setCurrentFilter({
                availableCenter: [],
                availableLevel: [],
                availableType: [],
                filterCenter: null,
                filterLevel: null,
                filterType: null,
            })
        }
    }, [ availableSlots ])

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            className="slotChoiceFilters searchFilterPopin"
        >
            <header>TES OPTIONS</header>
            <section>
                {((isAdult && padelLevels?.length < 2) || !isAdult)
                    && typeOption?.filter(type => availableType.includes(type.value)).length < 2
                    && centersOptions?.filter(center => availableCenter.includes(center.id)).length < 2
                    && <div className="noFilter">Aucun filtre disponible pour les résultats en cours.</div>
                }
                {isAdult && padelLevels?.length > 1 && (
                    <>
                        <h2>Sélectionne ton niveau</h2>
                        <div className="arrowsWrapper">
                            <img
                                src={mediaPath([ "/assets/icons/arrow-left-orange.svg", "/assets/icons/arrow-left-black.svg" ])}
                                className={classNames("previousHours", { marginTop: UserAgentHandler.isIOS() || UserAgentHandler.isAndroid()  })}
                                onClick={
                                    () => {
                                        let lvlScrollLeft = levelScroll.current.scrollLeft
                                        levelScroll.current.scrollLeft = lvlScrollLeft === 140 ? 
                                            lvlScrollLeft - (140 * 4)
                                            :
                                            lvlScrollLeft - (75 * 4)
                                        
                                    }
                                }
                            />
                            <div className="opacity">
                                <div className="hoursWrapper" ref={levelScroll}>
                                    { padelLevels.map(
                                        (lvl, i) => (
                                            <CheckBlock
                                                callback={
                                                    () => {
                                                        setSelectedLevel(prev => prev === lvl ? "" : lvl)
                                                        if (
                                                            (
                                                                window.innerWidth
                                                                || document.documentElement.clientWidth
                                                                || document.body.clientWidth
                                                            ) > 768
                                                        ) {
                                                            levelScroll.current.scrollLeft = i * 70
                                                        }
                                                        else {
                                                            levelScroll.current.scrollLeft = i * 52
                                                        }
                                                    }
                                                }
                                                checked={lvl === selectedLevel}
                                                value={lvl}
                                                key={"levelFilter-" + lvl}
                                            >
                                                {lvl}
                                            </CheckBlock>
                                        ),
                                    )}
                                </div>
                            </div>
                            <img
                                src={mediaPath([ "/assets/icons/arrow-right-orange.svg", "/assets/icons/arrow-right-black.svg" ])}
                                className={classNames("nextHours", { marginTop: UserAgentHandler.isIOS() || UserAgentHandler.isAndroid() })}
                                onClick={
                                    () => {
                                        let lvlScrollLeft = levelScroll.current.scrollLeft
                                        levelScroll.current.scrollLeft = lvlScrollLeft + (70 * 4)
                                    }
                                }
                            />
                        </div>
                    </>

                )}

                {typeOption?.filter(type => availableType.includes(type.value)).length > 1 && (
                    <>
                        <h2>Type de {tunnelType === "TRAINING" ? "stage" : "cours"}</h2>
                        <div className={classNames("filterWrapper", {
                            [`gridColumn-${typeOption?.length}`]: typeOption?.length,
                        })}>
                            { typeOption.filter(type => availableType.includes(type.value)).map(
                                type => (
                                    <CheckBlock
                                        checked={selectedType === type.value}
                                        value={type.value}
                                        key={type.label + type.value}
                                        callback={() => setSelectedType(prev => prev === type.value ? null : type.value)}
                                        unselect
                                    >
                                        <div className="ellipsis">{type.label}</div>
                                    </CheckBlock>
                                ),
                            )}
                        </div>
                    </>
                )}
                { centersOptions?.filter(center => availableCenter.includes(center.id)).length > 1 && (
                    <>
                        <h2>Sélectionne ton centre</h2>
                        <div className={classNames("filterWrapper", {
                            [`gridColumn-${centersOptions.length}`]: centersOptions?.length,
                        })}>
                            { centersOptions.map(
                                center => (
                                    <CheckBlock
                                        checked={selectedCenter === center.key}
                                        value={center.key}
                                        key={center.key + center.value}
                                        callback={() => setSelectedCenter(prev => prev === center.key ? null : center.key)}
                                        unselect
                                    >
                                        <div className="ellipsis">{center.value}</div>
                                    </CheckBlock>
                                ),
                            )}
                        </div>
                    </>
                )}
                <ButtonCta
                    disabled={!filterChanged}
                    text="Appliquer"
                    isAlt
                    onClick={
                        () => {
                            if (filterChanged) {setCurrentFilter(prev => ({ ...prev,
                                filterCenter: selectedCenter,
                                filterLevel: selectedLevel,
                                filterType: selectedType,
                            }))}
                            setIsOpen(false)
                        }
                    }
                />
            </section>
        </Modal>
    )
}

SlotChoiceFilters.displayName = "SlotChoiceFilters"

SlotChoiceFilters.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default SlotChoiceFilters
