import PropTypes from "prop-types"
import { createContext, useContext, useEffect, useState } from "react"

import { useTheme } from "../components/ThemeContext/ThemeContext"

const AssetContext = createContext({})

export const AssetProvider = ({ children }) => {
    const [ assets, setAssets ] = useState({})
    const theme = useTheme()

    useEffect(() => {
        let isMounted = true
        async function loadAssets() {
            const fileName = theme.split("-").pop()

            try {
                const loadedAssets = await import(`../medias/${fileName}.json`)
                if (isMounted) {
                    setAssets(loadedAssets.default)
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`Error loading assets for theme ${fileName}:`, error)
                if (isMounted) {
                    setAssets({})
                }
            }
        }
        loadAssets()
        return () => {
            isMounted = false
        }
    }, [ theme ])

    const m = (path) => {
        return path.split(".").reduce((acc, part) => acc && acc[part], assets)
    }

    return (
        <AssetContext.Provider value={{ assets, m }}>
            {children}
        </AssetContext.Provider>
    )
}

// Hook to use the asset context
export const useAssets = () => useContext(AssetContext)

AssetProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
