const genderTranslations = [
    {
        label: "Femme",
        value: "female",
    },
    {
        label: "Homme",
        value: "male",
    },
    {
        label: "Autre",
        value: "other",
    },
]

export default genderTranslations
