import { useContext } from "react"

import { CookieContext } from "../providers/CookieProvider.jsx"

const useCookies = () => {
    const context = useContext(CookieContext)

    if (!context) {
        throw new Error("useCookies must be used within a CookieProvider")
    }

    return context
}

export default useCookies
