import { array, bool, oneOfType } from "prop-types"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import Preloader from "../../../../../../components/loaders/preloader/preloader"
import ReservationSlotInfo from "../../../../../../components/reservation/slotInfoCard/ReservationSlotInfoCard"
import SchoolTrainingSlotsCard from "../../../../../../components/SchoolTrainingSlotsCard/SchoolTrainingSlotsCard"
import { useSchoolTrainingCurrent, useSchoolTrainingTunnel } from "../../../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import useIsMobile from "../../../../../../hooks/useIsMobile"
import schoolTrainingWaitingList from "../../../../functions/schoolTrainingWaitingList"

import "./SlotChoiceSlots.scss"

export default function SlotChoiceSlots({ slots }) {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ , setSearchParams ] = useSearchParams()

    const { value: { prestation, selectedSlot, selectedKid, isAdult } } = useSchoolTrainingCurrent() || {}
    const { value: tunnelType } = useSchoolTrainingTunnel() || {}

    const isMobile = useIsMobile()
    const navigate = useNavigate()
    const user = useSelector(state => state.userLoaded)

    const parentDom = document.getElementById("step-article-3")
    
    useEffect(() => {
        if (slots?.length) {
            if (selectedSlot) {
                setSearchParams(params => {
                    params.set("creneau", tunnelType === "SCHOOL" ? selectedSlot.value : selectedSlot.id)
                    return params
                })
            }
        }
    }, [ selectedSlot, slots ])

    return (
        <div className="slotChoiceSlots formsHolder centerPicker c-row justify-center align-start layoutSmall">
            {slots?.length > 0 && slots[0]?.tunnelType === tunnelType && (
                <>
                    {isLoading && <Preloader fixed />}
                    <div className="c-col c-col--6 c-col--sm--12 flex space-between align-center wrap slotsWrapper">
                        {!isLoading && slots.map(slot => (<SchoolTrainingSlotsCard slot={slot} key={prestation?.type + (slot.label || slot?.name) + (slot.schoolDetails?.id || slot?.id)}/>))}
                    </div>
                    <div className="c-col c-col--3 c-col--sm--12 flex space-between align-center wrap c-col--offset--left--md--1 sticky no-padding">
                        {!isMobile && (
                            <ReservationSlotInfo
                                reservationSlot={tunnelType === "SCHOOL" ? selectedSlot?.schoolDetails : selectedSlot}
                                subscribeToWaitingListCallback={() => schoolTrainingWaitingList(user, selectedKid, tunnelType, setIsLoading, navigate, selectedSlot, isAdult)}
                                slotName={tunnelType === "TRAINING" ? "stage " : "cours "}
                                pleaseSelectText={tunnelType === "TRAINING" ? "ton stage" : "ton cours"}
                                tunnelType={tunnelType}
                                afterClose={()=> parentDom.style.zIndex = "initial"}
                                beforeOpen={() => parentDom.style.zIndex = "11"}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

SlotChoiceSlots.propTypes = {
    slots: oneOfType([ bool, array ]),
}
