import PropTypes from "prop-types"

import { TeamPicture } from "../../../../../components/league/TeamPicture/TeamPicture.jsx"

import "./GameTeamDisplay.scss"

export const GameTeamDisplay = ({ team, bottomEl, size = 240 }) => {
    return (
        <div className="gameTeamDisplay">
            <TeamPicture image={team?.blasonUrl || team?.blazon || "/assets/images/business/communicationPack/teamPlaceholder.png"} size={size} />
            <h1 className={"tw-bold uppercase tw-lh-1"}>{team?.team}</h1>
            <div className="rank tw-bold">
                <h2>{team.rank}</h2>
                <span className="uppercase">{team.rank === 1 ? "er" : "è"}</span>
            </div>
            {bottomEl}
        </div>
    )
}

GameTeamDisplay.propTypes = {
    bottomEl: PropTypes.element,
    size: PropTypes.number,
    team: PropTypes.shape({
        blasonUrl: PropTypes.string,
        blazon: PropTypes.string,
        rank: PropTypes.number,
        team: PropTypes.string,
    }),
}
