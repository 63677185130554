import classNames from "classnames"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useMemo } from "react"

import { TeamPicture } from "../../../../../components/league/TeamPicture/TeamPicture.jsx"
import { mapToObject, sortTableData } from "../../../../../utilities/helpers.js"
import { BaseLayout } from "../Base.jsx"

import "./TeamsRanking.scss"

export const TeamsRanking = (props) => {
    const { data, pagination, ctx } = props

    const sortedTeams = useMemo(() => {
        const teams = data?.league?.teams?.map((team, index) => {
            const values = new Map()
            Object.entries(team).forEach(([ key, value ]) => {
                values.set(key, value)
            })

            return ({
                id: index,
                values,
            })
        }) ?? []

        return Object.keys(teams || {}).length > 0 ? sortTableData(teams, { key: "nbGoals", order: "DESC" }, { key: "firstname", order: "ASC" }) : []
    }, [ props?.data ])

    const { currentPage, limit } = pagination ?? {}

    const paginatedTeams = useMemo(() => {
        const startIndex = (currentPage - 1) * limit
        const endIndex = startIndex + limit
        return sortedTeams.slice(startIndex, endIndex)
    }, [ sortedTeams, currentPage, limit ])

    // const yearMatch = props?.data?.league?.name?.match(/\b\d{4}\b/)
    // const year = yearMatch ? parseInt(yearMatch[0]) : null

    return (
        <BaseLayout decorator="top" cls={"teamsRanking"}>
            <div className={"heading"}>
                <div className="left">
                    {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                    <h3 className={"tw-regular uppercase"}><strong className={"tw-bold"}>SAISON {ctx.leagueSeason} //</strong> {dayjs().format("DD MMMM YYYY")}</h3>
                    <span className={"tw-bold"}>LES CHAMPIONNATS INTER-ENTREPRISE</span>
                </div>
                <div className="right">
                    <h1 className={"tw-bold"}>CLASSEMENT LIGUE</h1>
                    <h2  className={"tw-bold"}>{props.data?.league?.name}</h2>
                </div>
            </div>
            <div className={"table"}>
                <table>
                    <thead>
                        <tr>
                            <th key={"tableHeadKey-" + "0"}
                                className="numbers">{columnsDisplayNames?.get("id")}</th>
                            {columns?.map((column, i) => (
                                <th
                                    key={"tableHeadKey-" + i}
                                    className={classNames({ first: i === 0 })}
                                    style={{ textAlign: column.align || "left" }}
                                >
                                    {columnsDisplayNames?.get(column.name) === "" ? ""
                                        : (columnsDisplayNames?.get(column.name)) || column.name}
                                </th>
                            ))}
                        </tr>
                    </thead>

                    {paginatedTeams?.length > 0 && (
                        <tbody
                        >
                            {paginatedTeams.map((row, i) => {
                                const team = mapToObject(row?.values)
                                return (
                                    <tr
                                        key={"tableBodyKey-" + i}
                                        className={classNames({ highlighted: team.id === ctx.teamId })}
                                    >
                                        <td
                                            className="numbers tw-regular"
                                            key={"tableCell-row" + i + "-" + "0"}
                                        >{i + 1}</td>
                                        {columns?.map((column, j) => (
                                            <td
                                                key={"tableCell-row" + i + "-" + j}
                                                className={classNames("tw-bold", {
                                                    first: j === 0,
                                                    [column.cls]: column.cls,
                                                })}
                                                style={{ textAlign: column.align }}
                                            >
                                                {column.render && column.render(row) || row.values.get(column.name)}
                                            </td>
                                        ))}
                                    </tr>
                                )
                            })}
                        </tbody>
                    )}
                </table>
            </div>

        </BaseLayout>
    )
}

const columnsDisplayNames = new Map([
    [ "id", "Rang" ],
    [ "name", "Équipe" ],
    [ "totalPoints", "PTS" ],
    [ "played", "J" ],
    [ "fairplayPoints", "FP" ],
    [ "goalsDiff", "+/-" ],
    [ "goalsFor", "BP" ],
    [ "goalsAgainst", "BC" ],
    [ "wins", "V" ],
    [ "draws", "N" ],
    [ "losses", "D" ],
])

const columns = [
    {
        align: "left", name: "name", render: (row) => {
            const team = mapToObject(row?.values)

            return (
                <div className={"playerLine"}>
                    <TeamPicture size={58} image={team?.blasonUrl || "/assets/images/business/communicationPack/teamPlaceholder.png"} />
                    <div className={"playerLine__content"}>
                        <div className={"tw-bold"}>
                            {team?.name}
                        </div>
                    </div>

                </div>
            )
        },
    },
    { align: "center", name: "totalPoints" },
    { align: "center", name: "played" },
    { align: "center", name: "fairplayPoints" },
    { align: "center", name: "goalsDiff" },
    { align: "center", name: "goalsFor" },
    { align: "center", name: "goalsAgainst" },
    { align: "center", name: "wins" },
    { align: "center", name: "draws" },
    { align: "center", name: "losses" },
]

TeamsRanking.propTypes = {
    ctx: PropTypes.object,
    data: PropTypes.object,
    pagination: PropTypes.object,
}
