import classNames from "classnames"
import { array, bool, func, oneOfType, string } from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"

import OverlayMenuLvl2 from "./overlayMenuLvl2/overlayMenuLvl2"
import useMediaPath from "../../../../hooks/useMediaPath"
import { selectHamburgerMenu, selectUserProfilMenu } from "../../../../store/actions/scaffold/index"
import { useTheme } from "../../../ThemeContext/ThemeContext.jsx"

import "./overlayMenuItem.scss"

function OverlayMenuItem({
    name,
    customCls,
    hasNotif,
    id,
    appRoute,
    lvl2Items,
    externalLink,
    level1,
    activeMenu,
    setActiveMenu,
}) {

    const menuOpen = useSelector(state => state.menuSelect.open)
    const dispatch = useDispatch()
    const mediaPath = useMediaPath()
    const theme = useTheme()
    useEffect(
        () => {
            if (hasNotif && menuOpen) {
                initializeElements()
            }
        }, [ hasNotif, menuOpen ],
    )

    function initializeElements() {
        if (hasNotif) {
            const nameEl = document.querySelector("#" + id + " .name")
            /* istanbul ignore if */
            if (nameEl?.clientWidth) {
                const notifEl = document.querySelector("#" + id + " .notif")
                if (notifEl) {
                    const deltaMargin = 47
                    const marginCalc = nameEl.clientWidth + deltaMargin
                    notifEl.style.marginLeft = marginCalc + "px"
                }
            }
        }
    }

    initializeElements()

    let cls = classNames("overlayMenuItem", {
        clickedItem: activeMenu,
        [customCls]: customCls,
        overlayMenuItemWithChildren: lvl2Items,
    })

    function isWebViewLink(link) {
        return link.startsWith("urbansoccer://")
    }

    function isAxeptioLink(link) {
        return link.startsWith("javascript:")
    }

    function clickOnMenuItem() {
        setActiveMenu(!activeMenu)
    }

    const lvl1Content = (
        <React.Fragment>
            { hasNotif && (
                <img
                    className="notif"
                    src={mediaPath([ "/assets/icons/icons-graphics-notifications-orange.svg", "/assets/images/padel/icons/icons-notifications.svg" ])}
                    style={{ visibility: activeMenu ? "hidden" : "visible" }}
                />
            )}
            <div className="name">
                {name}
            </div>
            { (lvl2Items || theme === "theme-business") && (
                <img
                    className="arrow"
                    src={
                        activeMenu ?
                            mediaPath([
                                "/assets/icons/arrow-right-white.svg",
                                "/assets/icons/arrow-right-menu.svg",
                                "/assets/icons/business/arrow-orange.svg",
                            ])
                            :
                            mediaPath([
                                "/assets/icons/arrow-right-menu.svg",
                                "/assets/icons/arrow-right-menu.svg",
                                "/assets/icons/business/arrow-blue.svg",
                            ])

                    }
                />
            )}
        </React.Fragment>
    )

    return (
        externalLink ? (
            (isWebViewLink(externalLink) || isAxeptioLink(externalLink)) ? (
                <a
                    data-testid="externalLink"
                    id={id}
                    className={cls}
                    href={isAxeptioLink(externalLink) ? "" : externalLink}
                    onClick={(e) => {
                        if (isAxeptioLink(externalLink)) {
                            e.preventDefault()
                            window.openAxeptioCookies()
                        }
                    }}
                >
                    <span className="name">{name}</span>
                </a>
            ) : (
                <a id={id} className={cls} href={externalLink} target="_blank" rel="noreferrer">
                    <span className="name">{name}</span>
                </a>
            )
        ) :  (
            <React.Fragment>
                {appRoute ? (
                    <NavLink
                        id={id}
                        to={appRoute}
                        className={({ isActive }) => classNames(cls, { selectedItem: isActive })}
                        onClick={
                            () => {
                                dispatch(selectUserProfilMenu(false))
                                dispatch(selectHamburgerMenu(false))
                                setActiveMenu(null)
                            }
                        }
                    >
                        {lvl1Content}
                    </NavLink>
                ) : (
                    <div
                        data-testid="level1"
                        id={id}
                        className={cls}
                        onClick={clickOnMenuItem}
                    >
                        {lvl1Content}
                    </div>
                )}

                { lvl2Items && (
                    <OverlayMenuLvl2
                        parentId={id ?? ""}
                        isOpen={activeMenu}
                        lvl2Items={lvl2Items}
                        level1={level1}
                    />
                )}
            </React.Fragment>
        )
    )
}

OverlayMenuItem.propTypes = {
    activeMenu: oneOfType([ bool, string ]),
    appRoute: string,
    customCls: string,
    externalLink: string,
    hasNotif: bool,
    id: string,
    level1: string,
    lvl2Items: array,
    name: string,
    setActiveMenu: func,
}

export default OverlayMenuItem
