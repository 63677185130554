import { func, object } from "prop-types"
import React from "react"

import { updateConfirmOverlayTargetVar, updateConfirmOverlayVar } from "../../../store/actions/content/index"

class _ConfirmOverlayContentComponent extends React.Component
{
    constructor(props) {
        super(props)
        this.updateConfirmOverlayTargetVar = this.updateConfirmOverlayTargetVar.bind(this)
    }

    _renderPreloader(fixed) {
        return (<div className={(fixed === true ? "fixed-" : "") + "preloader"}>
            <div style={{ backgroundImage: "url('../../../assets/images/loader.svg')" }}></div>
        </div>)
    }
    
    checkIfMobile() {
        return !!(
            (
                window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth
            ) <= 640
        )
    }

    updateConfirmOverlayTargetVar(e) {
        let identifier = e.target.attributes.getNamedItem("data-identifier").value
        this.props.updateConfirmOverlayTargetVar(identifier, e.target.value, this.props.confirmationOverlay)
    }

    static processCallback(callback, content, confirmationOverlay, parent) {
        if (content.props.hasCustomCallback === true) {
            content.type.processCustomCallback(callback,confirmationOverlay.targetVars,content,parent)
        } else if (content.props.customCallBack) {
            content.props.customCallBack(callback)
        }
        else {
            callback()
        }
    }

    render() {
        return (
            <div className="container-confirmOverlay" data-identifier={this.getName().toLowerCase()}>
                {this.checkIfMobile() ?
                    this.preRender()
                    :
                    this.preRenderDesktop()
                }
            </div>
        )
    }

    static stateToProps(state) {
        return {
            appConfig:           state.appConfig,
            confirmationOverlay: state.confirmationOverlay,
            queryParameters:     state.queryParameters,
        }
    }

    // PARENT ACTIONS TO MERGE WITH ...
    static dispatchToProps() {
        return {
            updateConfirmOverlayTargetVar: updateConfirmOverlayTargetVar,
            updateConfirmOverlayVar: updateConfirmOverlayVar,
        }
    }

}

_ConfirmOverlayContentComponent.propTypes = {
    appConfig: object,
    confirmationOverlay: object,
    updateConfirmOverlayTargetVar: func,
}

export default _ConfirmOverlayContentComponent
