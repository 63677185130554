import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock-upgrade"
import classNames from "classnames"
import { string } from "prop-types"
import { useRef, useState } from "react"

import Modal from "../../components/Modal/Modal"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import UserAgentHandler from "../../services/UserAgentHandler"

import "./PadelLevels.scss"

const PadelLevels = ModalHandler.create(({ customClass = "", visible, hide }) => {
    const levelsWrapper = useRef(null)
    const [ zoom, setZoom ] = useState(1)

    return (
        <Modal
            afterOpen={() => disableBodyScroll(levelsWrapper?.current)}
            className={classNames("padelLevel", { [customClass]: customClass })}
            isOpen={visible}
            onClose={() => {
                clearAllBodyScrollLocks()
                hide()
            }}
        >
            <div className="zoomControl">
                <p
                    className={classNames("zoom", { disabled: zoom === 3 })}
                    onClick={zoom < 3 ? () => setZoom(prev => prev + 0.5) : undefined}
                >
                    +
                </p>
                <p
                    className={classNames("dezoom", { disabled: zoom === 1 })}
                    onClick={zoom > 1 ? () => setZoom(prev => prev - 0.5) : undefined}
                >
                    -
                </p>
            </div>
            <a
                download
                href="/assets/pdf/padel-level.pdf"
                className="download"
            >
                <img alt="Télécharger" src="/assets/images/padel/icons/download.svg" />
            </a>
            <div
                className={classNames("wrapper", {
                    haveYOverflow: zoom > 1,
                    noScrollbar: UserAgentHandler.isAndroid() || UserAgentHandler.isIOS(),
                })}
                ref={levelsWrapper}
            >
                <img
                    alt="Niveaux de padel"
                    className="levels"
                    src="/assets/images/padel/padel-level.jpg"
                    style={{ width: `${100 * zoom}%` }}
                />
            </div>
        </Modal>
    )
})

PadelLevels.displayName = "PadelLevels"
PadelLevels.propTypes = {
    customClass: string,
}

export default PadelLevels
