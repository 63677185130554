import classNames from "classnames"
import dayjs from "dayjs"
import { bool, func } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import CheckBlock from "../../../../../components/Forms/CheckBlock/CheckBlock"
import Modal from "../../../../../components/Modal/Modal"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../../../../hooks/useMediaPath"
import locationDurationsForType from "../../../../../model/data/reservation/locationDurationsForType"
import locationHours from "../../../../../model/data/reservation/locationHours"
import UserAgentHandler from "../../../../../services/UserAgentHandler"
import { getTypeName } from "../../function"
import { formArgs } from "../services/function"
import { useBookingStore } from "../services/store"

import "./LocationFilterPopIn.scss"

const LocationFilterPopin = (({ visible, hide }) => {
    const hoursScroll = useRef(null)
    const mediaPath = useMediaPath()
    const { tu } = useUrbanLanguage()
    const today = dayjs().format()
    const todayDate = new Date()
    const { bookingInfosData, setSelectedSlot } = useBookingStore()

    const { watch, setValue } = useFormContext()
    const [ centerId, timeFilter, selectedDate, activityId ] = watch([
        formArgs.CENTER_ID,
        formArgs.TIME_FILTER,
        formArgs.DATE,
        formArgs.ACTIVITY_ID,
    ])

    const oldFilters = {
        [formArgs.DURATION]: watch(formArgs.DURATION),
        [formArgs.HOUR]: watch(formArgs.HOUR),
        [formArgs.TYPE]: watch(formArgs.TYPE),
    }

    const types = bookingInfosData?.centerOptions?.find(center => center[0] === parseInt(centerId))?.types?.filter(type => type?.categories[0]?.id === parseInt(activityId))
    const durations = locationDurationsForType(bookingInfosData?.centerActivies?.find(activity => activity.id === watch(formArgs.ACTIVITY_ID))?.name) ?? []
    const selectedDateIsToday = dayjs(selectedDate).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")
    const hours = locationHours

    const [ selectedDuration, setSelectedDuration ] = useState(oldFilters[formArgs.DURATION])
    const [ selectedType, setSelectedType ] = useState(oldFilters[formArgs.TYPE])
    const [ selectedHour, setSelectedHour ] = useState(oldFilters[formArgs.HOUR])
    const [ sameAsOldFilters, setSameAsOldFilters ] = useState(true)

    const checkFiltersChange = () => {
        const newFilters = {
            [formArgs.DURATION]: selectedDuration?.length > 0 ? selectedDuration : null,
            [formArgs.HOUR]: selectedHour,
            [formArgs.TYPE]: selectedType?.length > 0 ? selectedType : null,
        }

        const oldFilters = {
            [formArgs.DURATION]: watch(formArgs.DURATION)?.length > 0 ? watch(formArgs.DURATION) : null,
            [formArgs.HOUR]: watch(formArgs.HOUR),
            [formArgs.TYPE]: watch(formArgs.TYPE)?.length > 0 ? watch(formArgs.TYPE) : null,
        }

        return JSON.stringify(newFilters) !== JSON.stringify(oldFilters)
    }

    useEffect(
        () => {
            resetFromOldFilters()
        },[ oldFilters.duration, oldFilters.hour, oldFilters.type, visible ],
    )

    useEffect(() => {
        setSameAsOldFilters(!checkFiltersChange())
    }, [ selectedHour, selectedDuration, selectedType ])

    const closeModal = () => {
        hide()
    }

    const applyFilters = () => {
        const params = {
            [formArgs.DURATION]: selectedDuration ?? [],
            [formArgs.HOUR]: selectedHour ?? "noHourFilter",
            [formArgs.TYPE]: selectedType ?? [],
            [formArgs.TYPE_IDS]: selectedType?.map(type => type[0]) ?? [],
        }
        if (params[formArgs.DURATION]?.length === 0) {
            setValue(formArgs.PREBOOK_DURATION, null)
        }
        Object.keys(params).forEach(key => {
            setValue(key, params[key])
        })
        setSelectedSlot(null)
        closeModal()
    }

    function resetFromOldFilters() {
        if (oldFilters) {
            setSelectedDuration(oldFilters[formArgs.DURATION])
            setSelectedHour(oldFilters[formArgs.HOUR])
            setSelectedType(oldFilters[formArgs.TYPE])
        }
    }

    const isTypeSelected = (type) => {
        return selectedType && selectedType.findIndex(item => item[0] === type.key) !== -1
    }

    const isDurationSelected = (duration) => {
        return selectedDuration && selectedDuration.findIndex(item => item.value === duration.value) !== -1
    }

    return (
        <Modal
            beforeOpen={resetFromOldFilters}
            className="searchFilterPopin findAMatch"
            isOpen={visible}
            onClose={closeModal}
            afterClose={() => {
                setSameAsOldFilters(true)
                resetFromOldFilters()
            }}
        >
            <header>Tes options</header>
            <section>
                {(types && types.length > 1) && (
                    <>
                        <h2>Type de {tu("field")}</h2>
                        <div className="typesWrapper">
                            {types.map((type) => (
                                <CheckBlock
                                    checked={isTypeSelected(type)}
                                    callback={() => {
                                        checkFiltersChange(formArgs.TYPE, [ type.key, type.value ])
                                        if (isTypeSelected(type)) {
                                            setSelectedType((prev) =>
                                                prev ? prev.filter((item) => item[0] !== type.key) : null,
                                            )
                                        } else {
                                            setSelectedType((prev) =>
                                                prev ? [ ...prev, [ type.key, type.value ] ] : [ [ type.key, type.value ] ],
                                            )
                                        }
                                    }}
                                    value={type.key}
                                    key={type.key + type.value}
                                    unselect
                                >
                                    {getTypeName(type.key, type.value)}
                                </CheckBlock>
                            ))}
                        </div>
                    </>
                )}
                <h2>Durée</h2>
                <div className="durationsWrapper">
                    {durations.map((duration) => (
                        <CheckBlock
                            checked={isDurationSelected(duration)}
                            callback={() => {
                                checkFiltersChange(formArgs.DURATION, duration)
                                if (isDurationSelected(duration)) {
                                    setSelectedDuration((prev) =>
                                        prev ? prev.filter((item) => item.value !== duration.value) : null,
                                    )
                                } else {
                                    setSelectedDuration((prev) =>
                                        prev ? [ ...prev, duration ] : [ duration ],
                                    )
                                }
                            }}
                            value={duration.display}
                            key={"filterDuration" + duration.display}
                            unselect={true}
                        >
                            {duration.display}
                        </CheckBlock>
                    ))}
                </div>
                <h2>Horaire à partir de</h2>
                <div className="arrowsWrapper">
                    <img
                        src={mediaPath([ "/assets/icons/arrow-left-orange.svg", "/assets/icons/arrow-left-black.svg" ])}
                        className={classNames("previousHours", { marginTop: UserAgentHandler.isIOS() || UserAgentHandler.isAndroid()  })}
                        onClick={
                            () => {
                                let hsScrollLeft = hoursScroll.current.scrollLeft
                                hoursScroll.current.scrollLeft = hsScrollLeft === 140 ?
                                    hsScrollLeft - 140
                                    :
                                    hsScrollLeft - 75
                                    
                            }
                        }
                    />
                    <div className="opacity">
                        <div className="hoursWrapper" ref={hoursScroll}>
                            <CheckBlock
                                callback={
                                    () => {
                                        checkFiltersChange(formArgs.HOUR, "noHourFilter")
                                        setSelectedHour("noHourFilter")
                                        hoursScroll.current.scrollLeft = 0
                                    }
                                }
                                checked={selectedHour === "noHourFilter"}
                                value={"noHourFilter"}
                            >
                                <img src={selectedHour === "noHourFilter" ?
                                    mediaPath([ "/assets/icons/forbidden-white.svg", "/assets/images/padel/icons/forbidden-black.svg" ])
                                    :
                                    mediaPath([ "/assets/icons/forbidden-grey.svg", "/assets/images/padel/icons/forbidden-grey.svg" ])} />
                            </CheckBlock>

                            { hours?.filter(item => {
                                const isNotDisabledTime = (
                                    timeFilter
                                        && timeFilter.start <= item.value
                                        && timeFilter.end >= item.value
                                )
                                const noTimeFilter = !timeFilter
                                const isNotPassedHour = !(
                                    selectedDateIsToday
                                        && todayDate > new Date(today.split("T")[0] + item.value)
                                )
                                return (isNotDisabledTime || noTimeFilter) && isNotPassedHour
                            })?.map(
                                (hour, i) => (
                                    <CheckBlock
                                        callback={
                                            () => {
                                                checkFiltersChange(formArgs.HOUR, hour)
                                                setSelectedHour(hour)
                                                if (
                                                    (
                                                        window.innerWidth
                                                            || document.documentElement.clientWidth
                                                            || document.body.clientWidth
                                                    ) > 768
                                                ) {
                                                    hoursScroll.current.scrollLeft = (i + 1) * 70
                                                }
                                                else {
                                                    hoursScroll.current.scrollLeft = (i + 1) * 52
                                                }
                                            }
                                        }
                                        checked={selectedHour && selectedHour.value === hour.value}
                                        value={hour.value}
                                        key={hour.value}
                                    >
                                        {hour.display}
                                    </CheckBlock>
                                ),
                            )}
                        </div>
                    </div>
                    <img
                        src={mediaPath([ "/assets/icons/arrow-right-orange.svg", "/assets/icons/arrow-right-black.svg" ])}
                        className={classNames("nextHours", { marginTop: UserAgentHandler.isIOS() || UserAgentHandler.isAndroid()  })}
                        onClick={
                            () => {
                                let hsScrollLeft = hoursScroll.current.scrollLeft
                                hoursScroll.current.scrollLeft = hsScrollLeft + 70
                            }
                        }
                    />
                </div>
                <ButtonCta
                    disabled={
                        (selectedHour === "noHourFilter" &&
                                (selectedDuration && selectedDuration.length === 0)
                                && (selectedType && selectedType.length === 0)
                                && sameAsOldFilters
                        ) || sameAsOldFilters

                    }
                    text="Appliquer"
                    isAlt
                    onClick={applyFilters}
                />
            </section>
        </Modal>
    )
})

LocationFilterPopin.displayName = "LocationFilterPopin"

LocationFilterPopin.propTypes = {
    hide: func.isRequired,
    visible: bool.isRequired,
}

export default LocationFilterPopin
