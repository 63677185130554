import { func, number, string } from "prop-types"
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"

import FileInput from "../../../../../components/Forms/FileInput/FileInput"
import Form from "../../../../../components/Forms/Form/Form"
import FormValidator from "../../../../../components/Forms/FormValidator"
import { TextInput } from "../../../../../components/Inputs/TextInput/TextInput.jsx"
import { deleteTeamBlazon, uploadTeamBlazon } from "../../api"

const TeamForm = forwardRef(
    (props, ref) => {
        const { teamId, initialTeamName, initialBlazon, onValidation, onUnvalidation, onFileChangeUpdate } = props
        const [ teamName, setTeamName ] = useState(initialTeamName)
        const [ blazon, setBlazon ] = useState(initialBlazon)

        const formValidator = useRef(null)
        const form = useRef(null)
        const inputUpload = useRef(null)

        const uploadFile = (file, bar) => {
            return new Promise(
                (resolve) => {
                    var fileData = new FormData()
                    fileData.append("picture", file, "picture")
                    if (teamId !== null) {
                        uploadTeamBlazon(teamId, fileData, bar, props.tunnelType.toLowerCase()).then(
                            () => {
                                onFileChangeUpdate()
                                resolve()
                            },
                        )
                    } else {
                        setBlazon(file)
                        onFileChangeUpdate(file)
                        resolve()
                    }
                },
            )
        }

        function deleteAttachment() {
            if (teamId) {
                deleteTeamBlazon(teamId).then(
                    () => {
                        setBlazon(null)
                        onFileChangeUpdate()
                    },
                )
            } else {
                setBlazon(null)
            }

        }

        useEffect(
            () => {
                setBlazon(initialBlazon)
            }, [ initialBlazon ],
        )

        useEffect(
            () => {
                if (teamName) {
                    if (onValidation) {
                        onValidation()
                    }
                }
            }, [ teamName ],
        )

        useImperativeHandle(
            ref, 
            () => ({
                getInfo: () => {
                    return {
                        "blazon": blazon,
                        "teamName": teamName,
                    }
                },
            }),
        )

        return (
            <FormValidator onValidation={onValidation} onUnvalidation={onUnvalidation} ref={formValidator}>
                <Form
                    ref={form}
                    title={
                        (teamName) ? 
                            (<React.Fragment>
                                { teamName }
                            </React.Fragment>)
                            : 
                            "Ajouter une équipe"
                    }
                    desc={
                        teamId ? 
                            "Merci de vérifier et compléter les informations suivantes"
                            :
                            "Remplis les informations concernant ton équipe"
                    }
                >
                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <TextInput
                            label="Nom de l'équipe"
                            disabled={teamId !== null}
                            value={teamName}
                            onChange={setTeamName}
                            required
                        />
                    </div>

                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <FileInput
                            id="blazonUpload"
                            label="Logo de l'équipe (optionnel)"
                            acceptType="image"
                            initButtonUploadText="Choisis un fichier"
                            onFileUploadPromiseCallback={uploadFile}
                            deleteAttachment={deleteAttachment}
                            initFileName={
                                typeof blazon === "string" ?
                                    blazon
                                    :
                                    blazon ?
                                        URL.createObjectURL(blazon)
                                        :
                                        undefined
                            }
                            imageCropWidth={window.innerWidth < 375 ? 200 : 300}
                            imageCropHeight={window.innerWidth < 375 ? 200 : 300}
                            outputWidth={500}
                            outputHeight={500}
                            subLabel="Ton blason doit avoir une dimension de 500px"
                            ref={inputUpload}
                        />
                    </div>
                </Form>
            </FormValidator>
        )
    },
)

// https://github.com/facebook/react/issues/16653#issuecomment-564423981
TeamForm.displayName = "TeamForm"

TeamForm.propTypes = {
    initialBlazon: string,
    initialTeamName: string,
    onFileChangeUpdate: func,
    onUnvalidation: func,
    onValidation: func,
    teamId: number,
    tunnelType: string,
}

export default TeamForm
