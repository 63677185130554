const soccerLanguage = {
    booking: {
        defaultTeam1Name: "Team Urban",
        defaultTeam2Name: "Team Soccer",
        favoriteSlotField: "un terrain",
        filter: {
            noResult: "Aucun créneau ne correspond à ta recherche. Ajoute ce créneau en favori, tu recevras une alerte s'il se libère.",
        },
        listing: {
            noBooking: "Tu n'as pas encore reservé de terrain.",
        },
        noListingField: "Aucun terrain",
        supersub: {
            buttonLabel: "Trouver un Supersub",
            desc: "Ton match arrive à grands pas et il te manque un ou plusieurs joueurs pour le compléter ? Utilise le service SuperSub !",
            find: "Trouver un supersub",
            thinkAbout: "Pense au SuperSub",
        },
        videos: {
            goals: "Les buts",
        },
    },
    cup: {
        cancelPreregistration: "Annuler ma pré-inscription",
    },
    familiarityTrustThanks: "Merci de ta confiance.",
    federation: "FFF",
    field: "terrain",
    formalityTrustThanks: "Merci pour votre confiance.",
    league: {
        definedArticle: "le ",
        name: "Championnat",
        undefinedArticle: "un ",
    },
    longName: "Football",
    mobile: {
        menu: {
            reserver: "RÉSERVER UN TERRAIN",
        },
    },
    name: "UrbanSoccer",
    otherName: "foot",
    payWithUsCard: {
        typeOfCard: "US",
    },
    school: {
        noSchool: "Il n'y a pas d'École de Foot disponible sur le centre de ",
        title: "École de foot",
    },
    shortName: "Foot",
    training: {
        noTraining: "Aucun Stage de Foot n'est disponible sur le centre de ",
        title: "Stages de foot",
    },
    wordpress: "www.urbansoccer.fr",
}

export default soccerLanguage

