
import BirthdayReservation from "../../../pages/kids/birthdayReservation/BirthdayReservation"
import ListingBirthday from "../../../pages/kids/birthdayReservation/listingBithdays"
import ListingSchool from "../../../pages/kids/ecoleDeFoot/listingSchool"
import ListingStage from "../../../pages/kids/stageDeFoot/listingStage"
import StageDeFootTunnel from "../../../pages/kids/stageDeFoot/StageDeFootTunnel.jsx"
import { LeagueTunnel } from "../../../pages/league/LeagueTunnel.jsx"
import { ListingLeague } from "../../../pages/league/ListingLeague.jsx"
import ListingUrbanCup from "../../../pages/teams/urbanCup/ListingUrbanCup.jsx"
import FormValidationErrorsDisplayConfirmContent
    from "../contents/confirmOverlay/formValidationErrorsDisplayConfirmContent.jsx"
import SimpleConfirmContent from "../contents/confirmOverlay/simpleConfirmContent.jsx"
import NotificationsSettings from "../contents/main/notifications/notificationsSettings.jsx"
import PayzenCbPaymentContent from "../contents/overlay/payzenCbPaymentContent.jsx"
import SplitPaymentLinkContent from "../contents/overlay/splitPaymentLinkContent.jsx"

export const allContainers = () => {

    return {
        birthdayReservation:                    <BirthdayReservation/>,
        ecoleDeFoot:                            <ListingSchool/>,
        formValidationErrorsDisplayConfirm:     <FormValidationErrorsDisplayConfirmContent/>,
        inscriptionLeague:                      <ListingLeague/>,
        leagueTunnel:                           <LeagueTunnel/>,
        listingBirthday:                        <ListingBirthday/>,
        notificationsSettings:                  <NotificationsSettings/>,
        payzenCbPayment:                        <PayzenCbPaymentContent />,
        simpleConfirm:                          <SimpleConfirmContent/>,
        splitPaymentLinkContent:                <SplitPaymentLinkContent/>,
        stagesDeFoot:                           <ListingStage/>,
        stagesDeFootTunnel:                     <StageDeFootTunnel/>,
        urbanCup:                               <ListingUrbanCup/>,
    }
}
