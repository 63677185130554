
import { useLocation, useNavigate } from "react-router-dom"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext.jsx"
import useGoToCreateSuperSub from "../../../../hooks/supersub/useGoToCreateSuperSub.js"
import useMediaPath from "../../../../hooks/useMediaPath.js"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import { LocationPaths } from "../../../../routes/ApplicationPaths/locationPaths.js"
import usePath from "../../../../routes/services/usePath.js"
import { useLocationStore } from "../../store/store.js"
import BookingInvitation from "../components/BookingInvitation/BookingInvitation.jsx"

export default function getAppropriateButton(location) {
    const mediaPath = useMediaPath()
    const theme = useTheme()
    const path = usePath()
    const navigate = useNavigate()
    const { actionOpenPaymentModal } = useLocationStore()

    const routerLocation = useLocation()
    const { scoreTeam1, supersubPlayerPrice, scoreTeam2, isOwner, isMVPVoteOpened, isSupersubRequestAvailable, supersubRemainingPlayers, supersubAskedPlayers, invites, resourceType, acceptPrePayment, amountDue, organizer, start, end, supersubConfirmedPlayers, id } = location
    const matchDidNotStartYet = new Date(start) >= new Date()
    const matchEnded = new Date() >= new Date(end)
    const currentTab = routerLocation.pathname.split("/").pop()

    const goToSupersub = useGoToCreateSuperSub(location, amountDue, supersubPlayerPrice)

    const isCurrentStepNot = step => {
        return currentTab !== step
    }

    const GoToMVPVoteButton = (
        <ButtonCta
            icon={mediaPath([ "", "/assets/images/padel/icons/mvp.svg" ])}
            text="Élire le joueur du match"
            onClick={() => navigate(path(LocationPaths.toReservationPlayers(id)))}
        />
    )

    const { show } = ModalHandler.useReactiveModal(BookingInvitation, {
        location: location,
    })

    const checkSupersubPossibility = type => [ 0, 1, 13, 2, 3, 4, 8 ].includes(type)
    const maxSupersubCount = 3

    if (isOwner) {
        if (matchDidNotStartYet) {
            if ((!isSupersubRequestAvailable && isCurrentStepNot(path(LocationPaths.reservationPlayers))) || (invites.length <= 1 && isCurrentStepNot(path(LocationPaths.reservationPlayers)))) {
                return (<ButtonCta
                    icon={mediaPath([ "/assets/images/picto-add-user--white.svg", "/assets/images/padel/icons/icons-player-plus.svg" ])}
                    text="Inviter mes joueurs"
                    onClick={show}
                    hoverChangeIcon={mediaPath([ "/assets/images/picto-add-user--white.svg", "/assets/images/padel/icons/icons-player-plus-green.svg" ])}
                />)
            } else if (
                isSupersubRequestAvailable &&
                supersubRemainingPlayers === 0 &&
                supersubAskedPlayers < maxSupersubCount &&
                checkSupersubPossibility(resourceType) &&
                theme !== "theme-padel"
            ) {
                return (<ButtonCta
                    icon={mediaPath([ "/assets/images/supersub--white.svg", "/assets/images/padel/icons/icon-ultimo.svg" ])}
                    text="Trouver un supersub"
                    onClick={goToSupersub}
                />)
            } else if (supersubAskedPlayers > 0 && theme !== "theme-padel") {
                return (<ButtonCta
                    icon="/assets/images/supersub--white.svg"
                    text="Choisir un supersub"
                    onClick={() => navigate(path(LocationPaths.toCandidatureSuperSub(id)))}
                />)
            }
        } else if (matchEnded) {
            if ((!scoreTeam1 && scoreTeam1 !== 0 || !scoreTeam2 && scoreTeam2 !== 0) && isCurrentStepNot(path(LocationPaths.reservationInfos))) {
                return (<ButtonCta
                    text="Renseigner le&nbsp;score"
                    onClick={() => navigate(path(LocationPaths.toReservationInfos(id)))}
                />)
            } else if (
                theme !== "theme-padel" && supersubConfirmedPlayers && isOwner &&
                invites.some(invite => invite.status === 1 && invite.supersubResponse === null && invite.playerId !== organizer.id)
            ) {
                return (<ButtonCta
                    text="Noter le supersub"
                    onClick={() => navigate(path(`/reservations/locations/${id}/supersubRating`))}
                />)
            } else if (isMVPVoteOpened && isCurrentStepNot(path(LocationPaths.reservationPlayers))) {
                return GoToMVPVoteButton
            }
        }
    } else {
        if (matchDidNotStartYet && amountDue > 0 && isCurrentStepNot(path(LocationPaths.reservationPayments)) && acceptPrePayment) {
            return (<ButtonCta
                text="Payer ma part"
                onClick={actionOpenPaymentModal}
            />)
        } else if (isMVPVoteOpened && isCurrentStepNot(path(LocationPaths.reservationPlayers))) {
            return GoToMVPVoteButton
        }
    }
    return null
}
