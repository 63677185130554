import { useState } from "react"
import { useSelector } from "react-redux"

import OverlayMenuItem from "./overlayMenuItem/overlayMenuItem"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import initializeMenu from "../../../services/menu/initializeMenu"
import initializeMenuBusiness from "../../../services/menu/initializeMenuBusiness.js"
import initializeMenuPadel from "../../../services/menu/initializeMenuPadel"
import { useIsWebview } from "../../ThemeContext/ThemeContext"

import "./overlay_menu_content.scss"

function OverlayMenuContent() {
    const themeFeatures = useThemeFeatures()
    const hasArdoises = useSelector(state => state.hasArdoises)
    const nextBooking = useSelector(state => state.nextBooking)
    const hasUnpaidBills = useSelector(state => state.hasUnpaidBills)
    const user = useSelector(state => state.userLoaded)

    const data = {
        hasArdoises,
        hasUnpaidBills,
        nextBooking,
        userLoaded: user,
    }

    const menusObject = themeFeatures([ initializeMenu(data), initializeMenuPadel(data), initializeMenuBusiness(data) ])
    const [ activeMenu, setActiveMenu ] = useState(null)
    const menuslevel1 = menusObject.menuslevel1
    const menuslevel2 = menusObject.menuslevel2
    const isWebview = useIsWebview()

    return (
        <div id="overlay-menu-content" className="content">
            {(menuslevel1 && menuslevel2) && (
                Object.entries(
                    menuslevel1,
                ).filter(
                    ([ , infos ]) => infos.mobileSection === "menu" && !infos.hidden,
                ).map(
                    ([ lvl1Name, infos ], idx) => {
                        let lvl2Items = menuslevel2[lvl1Name]?.filter(item => item?.isVisible !== false)
                        if (isWebview && lvl1Name === "cookies") {return}
                        return (
                            <OverlayMenuItem
                                id={"overlay-menuItem-" + idx}
                                customCls={infos.customCls}
                                name={infos.label}
                                lvl2Items={lvl2Items}
                                level1={lvl1Name}
                                externalLink={infos.externalLink}
                                appRoute={infos.appRoute}
                                activeMenu={activeMenu === infos.label}
                                setActiveMenu={
                                    () => {
                                        activeMenu === infos.label ?
                                            setActiveMenu(null)
                                            :
                                            setActiveMenu(infos.label)
                                        
                                    }
                                }
                                hasNotif={lvl1Name === "reservations" && !!nextBooking}
                                key={"arrlvl1-" + lvl1Name}
                            />
                        )
                    },
                )
            )}
        </div>
    )
}

export default OverlayMenuContent
