import dayjs from "dayjs"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import Skeleton from "react-loading-skeleton"
import { useSelector } from "react-redux"

import { Base } from "./visuals/Base.jsx"
import ButtonCta from "../../../components/Buttons/button/button-cta.jsx"
import { Select } from "../../../components/Inputs/NewSelect/Select.jsx"
import Preloader from "../../../components/loaders/preloader/preloader.jsx"
import Modal from "../../../components/Modal/Modal.jsx"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import { getLeagueTeamPlayers } from "../../league/api/myLeague.js"
import { useLeagueStore } from "../../league/store/useLeagueStore.js"
import { getVisualData } from "../api/api.js"
import { communicationPackageDynamicContent } from "../config.js"
import { usePdfExporter } from "../services/pdf.js"

const initialPagination = {
    currentPage: 1,
    limit: 1,
    nbPages: 1,
}

export const VisualCard = ({ config, data: { games, league, players } }) => {
    const [ selectedGame, setSelectedGame ] = useState(null)
    const [ selectedPlayer, setSelectedPlayer ] = useState(null)
    const [ selectedOpponent, setSelectedOpponent ] = useState(null)
    const [ opponentTeamPlayers, setOpponentTeamPlayers ] = useState({})
    const [ loadingImg, setLoadingImg ] = useState(true)
    const [ loadingData, setLoadingData ] = useState(false)
    const { selectedLeagueTeam } = useLeagueStore()
    const userLoaded = useSelector((state) => state.userLoaded)
    const [ data, setData ] = useState(null)
    const [ selectedData, setSelectedData ] = useState(null)
    const [ pdfRef, handlePdf ] = usePdfExporter()
    const [ pagination, setPagination ] = useState(initialPagination)

    const ctx = {
        league,
        leagueId: selectedLeagueTeam?.leagueId,
        leagueSeason: dayjs(league?.start).year(),
        players,
        selectedOpponentPlayerPhoto: selectedOpponent?.value,
        selectedTeamPlayerPhoto: selectedPlayer?.value,
        teamId: selectedLeagueTeam?.id,
        userId: userLoaded?.contactId,
    }

    const fetchData = () => {
        setLoadingData(true)
        if (config?.content) {
            ctx.gameId = selectedGame?.value
        }

        getVisualData(ctx, config?.slug).then((res) => {
            const limit = config?.multiPage?.limit
            const nbPages = Math.ceil(config?.multiPage?.getter(res).length / limit)
            setData(res)
            setPagination({
                ...pagination,
                limit,
                nbPages,
            })
            setLoadingData(false)
        })
    }

    useEffect(() => {
        if (selectedData) {
            setLoadingData(true)
            handlePdf(`${config.fileName}-p` + (pagination.currentPage)).then(() => {
                setData(null)
                setSelectedData(null)
                setPagination(initialPagination)
                setLoadingData(false)
            })
        } else {
            setLoadingData(false)
        }
    }, [ selectedData ])

    const handleClick = (index) => {
        if (data) {
            setPagination({
                ...pagination,
                currentPage: index,
            })
            setData(null)
            setSelectedData(data)
        }
    }

    useEffect(() => {
        if (data) {
            if (pagination?.nbPages > 1) {
                ModalHandler.show(MultiPageModal, { callBack: handleClick, data: pagination, totalItems: config?.multiPage?.getter(data).length })
                    .then(() => {
                        setData(null)
                        setPagination(initialPagination)
                    })
            } else {
                setSelectedData(data)
            }
        }
    }, [ pagination, data ])

    useEffect(() => {
        setSelectedGame(null)
        setSelectedData(null)
    }, [ selectedLeagueTeam?.id ])
    
    const opponentTeamId = selectedGame?.data?.localTeam?.teamId === ctx.teamId
        ? selectedGame?.data?.visitorTeam?.id
        : selectedGame?.data?.localTeam?.id

    useEffect(() => {
        if (opponentTeamId && opponentTeamPlayers[opponentTeamId] === undefined) {
            getLeagueTeamPlayers(opponentTeamId)
                .then(opponentPlayers => {
                    setOpponentTeamPlayers(prev => ({ ...prev, [opponentTeamId]: opponentPlayers }))
                }).catch(() => {
                    setOpponentTeamPlayers(prev => ({ ...prev, [opponentTeamId]: [] }))
                })
            
        }
    }, [ opponentTeamId ])

    const playersOptions  = players?.map(player => ({
        label: ((player.firstname ?? "") + " " + (player.lastname ?? "")).trim(),
        value: player.businessLeaguePictureUrl,
    })).filter(p => !!p.value)

    return (
        <>
            {loadingData && <Preloader fixed />}
            <motion.div className={"cp-item"} key={config?.slug} variants={itemVariants}>
                {loadingImg && (<Skeleton style={{ aspectRatio: 16 / 9 }} containerClassName={"skeleton"} />)}
                <img src={`/assets/images/business/communicationPack/samples/${config.slug}.jpg`} alt={config.title} onLoad={() => setLoadingImg(false)}
                    style={loadingImg ? { display: "none" } : null} />
                <h2>{config.title}</h2>
                {config?.description && (
                    <p>{config.description}</p>
                )}

                {config?.selectTeamPlayer && (
                    <Select
                        options={playersOptions}
                        label={"Sélectionne le joueur à afficher"}
                        placeholder={"Joueur"}
                        disabled={!players?.length}
                        onChange={(value) => setSelectedPlayer(value)}
                        value={selectedPlayer}
                    />
                )}

                {config?.content && (
                    <Select
                        options={games}
                        label={communicationPackageDynamicContent[config?.content]?.label}
                        placeholder={communicationPackageDynamicContent[config?.content]?.placeholder}
                        disabled={!games}
                        onChange={(value) => setSelectedGame(value)}
                        value={selectedGame}
                        required
                    />
                )}

                {config?.selectTeamPlayer && selectedGame && (
                    <Select
                        options={
                            opponentTeamPlayers[opponentTeamId]?.players?.map(player => ({
                                label: ((player.firstname ?? "") + " " + (player.lastname ?? "")).trim(),
                                value: player.businessLeaguePictureUrl,
                            }))?.filter(p => !!p.value) ?? []
                        }
                        label={"Sélectionner l'adversaire à afficher"}
                        placeholder={"Joueur"}
                        onChange={(value) => setSelectedOpponent(value)}
                        value={selectedOpponent}
                    />
                )}

                <ButtonCta text="Générer" onClick={fetchData} disabled={config?.content ? !selectedGame : false} />

                {createPortal(
                    (<Base ref={pdfRef}>
                        {config?.component && selectedData && (
                            <config.component data={selectedData} pagination={pagination} ctx={ctx} />
                        )}
                    </Base>), document.body,
                )}

            </motion.div>

        </>
    )
}

export const MultiPageModal = ModalHandler.create(({ data, callBack, visible, hide, totalItems }) => {
    const [ selectedPage, setSelectedPage ] = useState(null)
    const options = Array.from({ length: data.nbPages }, (_, i) => {
        const start = i * data.limit
        const end = Math.min(start + data.limit, totalItems)
        return { label: `Page ${i + 1} - de ${start + 1} à ${end}`, value: i + 1 }
    })

    return (
        <Modal isOpen={visible} onClose={hide} className={"visualPaginationModal"}>
            <p>Le visuel demandé contient plusieurs pages, veuillez sélectionner la page à télécharger</p>
            <Select
                options={options}
                label={"Page"}
                placeholder={"Page"}
                onChange={(value) => setSelectedPage(value)}
                value={selectedPage}
                required
            />
            <ButtonCta text={"Télécharger"} onClick={() => {
                hide()
                return callBack(selectedPage?.value)
            }} disabled={!selectedPage} />
        </Modal>
    )
})

VisualCard.propTypes = {
    config: PropTypes.object,
    data: PropTypes.object,
}

const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
}
