import { bool, func, object } from "prop-types"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import ProfilCardWrapper from "../ProfilCardWrapper/ProfilCardWrapper"

export default function CbCard({ cbCard, cbAliasError, setCbCardPopinOpen, formatCreditCardNumber }) {
    return (
        <div className="cards cb-card">
            <div className="title">Carte bancaire</div>
            <ProfilCardWrapper customCls={Object.keys(cbCard).length === 0 ? "" : "haveCard"}>
                {Object.keys(cbCard).length === 0 ?
                    (<div className="text-center noCbCard">
                        <span className="error important-text-black important-text-black-1">
                            {cbAliasError ?
                                "Suite à une erreur, la carte bancaire enregistrée sur ton compte a été supprimée."
                                :
                                "Tu n'as pas encore enregistré de carte bancaire"
                            }
                        </span>
                        <div className="flex justify-center">
                            <ButtonCta
                                text="Ajouter une carte"
                                isAlt={true}
                                onClick={() => {
                                    setCbCardPopinOpen(true)
                                }}
                            />
                        </div>
                    </div>)
                    :
                    (<div className="cbInfo">
                        <div className="side-left">
                            <img src={"/assets/images/Carte-" + cbCard.tokenDetails.effectiveBrand + "-B.svg"}  />
                            <div className="details">
                                <div>N° : <span className="weight-bold">{formatCreditCardNumber()}</span></div>
                                <div>
                                    Expire fin: <span className="weight-bold">{cbCard.tokenDetails.expiryMonth + "/" + cbCard.tokenDetails.expiryYear}</span>
                                </div>
                            </div>
                        </div>
                        <div className="side-right">
                            <ButtonCta
                                isAlt
                                onClick={() => setCbCardPopinOpen(true)}
                                text="Éditer"
                            />
                        </div>
                    </div>)
                }
            </ProfilCardWrapper>
        </div>
    )
}

CbCard.propTypes = {
    cbAliasError: bool,
    cbCard: object,
    formatCreditCardNumber: func,
    setCbCardPopinOpen: func,
}
