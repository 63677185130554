import classNames from "classnames"
import { bool, func, object, oneOfType, string } from "prop-types"

import "./RoundCaptionnedIconButton.scss"

function RoundCaptionnedIconButton({ icon, text, cls, clickCallback, iconOnly = false }) {
    return (
        <div className={"roundCaptionnedIconButton" + " " + cls }>
            <button onClick={clickCallback} className={classNames({
                iconOnly,
            })}>
                {text && <span className="inner-caption">{text}</span>}
                {icon && <img src={"/assets/icons/" + icon}/>}
            </button>

            <span className="caption">{text}</span>
        </div>
    )
}

RoundCaptionnedIconButton.propTypes = {
    clickCallback: func,
    cls: string,
    icon: string,
    iconOnly: bool,
    text: oneOfType([ string, object ]),
}

export default RoundCaptionnedIconButton
