import {
    getBill, getDebt,
    getKidsSchoolSubscription,
    handleSplitPaymentToken,
} from "../../globalAPI/api.js"
import { getSuperSubsRequest } from "../../globalAPI/supersub/api.js"
import { getBirthdayRegistration } from "../../pages/kids/birthdayReservation/api.js"
import { getKidsStageSubscription } from "../../pages/kids/stageDeFoot/api.js"
import { getLeagueRegistration } from "../../pages/league/api/api.js"
import LyraEmbedPayment from "../../pages/payments/LyraEmbedPayment/LyraEmbedPayment.jsx"
import { getCupRegistrationDetails } from "../../pages/teams/urbanCup/api.js"

const RoutesPayments = [
    {
        element: (
            <LyraEmbedPayment
                title={"PAIEMENT FACTURE"}
                tunnelType={"BILL"}
                getPayment={(params) => getBill(params.billId)}
            />
        ),
        path: "profil/payment/bill/:billId",
    },
    {
        element: (
            <LyraEmbedPayment
                title={"PAIEMENT ARDOISE"}
                tunnelType={"DEBT"}
                getPayment={(params) => getDebt(params.debtId)}
            />
        ),
        path: "profil/payment/debt/:debtId",
    },
    {
        element: (
            <LyraEmbedPayment
                title={"PAIEMENT LEAGUE"}
                tunnelType={"LEAGUE"}
                getPayment={(params) => getLeagueRegistration(params.registrationId)}
            />
        ),
        path: "order/payment/league/:registrationId",
    },
    {
        element: (
            <LyraEmbedPayment
                title={"PAIEMENT ANNIVERSAIRE"}
                tunnelType={"BIRTHDAY"}
                getPayment={params => getBirthdayRegistration(params.registrationId).then(res => {
                    return res?.data?.data
                })}
            />
        ),
        path: "order/payment/birthday/:registrationId",
    },
    {
        element: (
            <LyraEmbedPayment
                title={"PAIEMENT ECOLE"}
                tunnelType={"SCHOOL"}
                getPayment={(params) => getKidsSchoolSubscription(params.subscriptionId)}
            />
        ),
        path: "order/payment/school/:subscriptionId",
    },
    {
        element: (
            <LyraEmbedPayment
                title={"PAIEMENT CUP"}
                tunnelType={"CUP"}
                getPayment={(params) => getCupRegistrationDetails(params.registrationId).then(res => {
                    return res?.data?.data
                })}
            />
        ),
        path: "order/payment/cup/:registrationId",
    },
    {
        element: (
            <LyraEmbedPayment
                title={"PAIEMENT STAGE"}
                tunnelType={"TRAINING"}
                getPayment={(params) => getKidsStageSubscription(params.registrationId).then(res => {
                    return res?.data?.data
                })}
            />
        ),
        path: "order/payment/training/:registrationId",
    },
    {
        element: (
            <LyraEmbedPayment
                title={"PAIEMENT SUPERSUB"}
                tunnelType={"SUPERSUB"}
                getPayment={params => getSuperSubsRequest(params.responseId).then(r => {
                    return r?.data?.data
                })}
            />
        ),
        path: "order/payment/supersub/:responseId",
    },
    {
        element: (
            <LyraEmbedPayment
                title={"PAIEMENT FACTURE"}
                tunnelType={"BILL"}
                getPayment={params => handleSplitPaymentToken(params.token).then(r => {
                    return r?.tokenDetails
                })}
            />
        ),
        path: "profil/payment/splitPayment/:token",
    },
    {
        element: (
            <LyraEmbedPayment
                title={"ENREGISTREMENT CARTE"}
                tunnelType={"REGISTER"}
            />
        ),
        path: "profil/payment/registerCard",
    },
]

export default RoutesPayments 
