import { node } from "prop-types"

import "./ActiveFilters.scss"

export default function ActiveFilters({ children }) {
    return (
        <div className="activeFilters">{children}</div>
    )
}

ActiveFilters.propTypes = {
    children: node,
}
