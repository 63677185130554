import { number, object } from "prop-types"
import { useSelector } from "react-redux"

import LevelStars from "./LevelStars"
import supersubLevels from "../../../model/enums/superSubLevels"
import getHourMinutesFromDate from "../../../services/getHourMinutesFromDate"

function SuperSubRequestCheckboxContent({
    match,
}) {
    const levelText = supersubLevels[match.level]
    const startDate = new Date(match.reservation.start)
    const startHour = getHourMinutesFromDate(startDate)

    const userLoaded = useSelector(state => state.userLoaded)

    function displayPrice() {
        if (match.contactSupersubResponseState === 0 && match.organizer.userId !== userLoaded.id) {
            return  "Demande en cours"
        } else if (match.contactSupersubResponseState === 1 && match.organizer.userId !== userLoaded.id) {
            return  "Demande validée"
        } else if (match.contactSupersubResponseState === -1 && match.organizer.userId !== userLoaded.id) {
            return  "Demande refusée"
        } else if (match.contactInvitationState === 1 && match.organizer.userId !== userLoaded.id) {
            return  "Déjà inscrit"
        }

        if (match.organizer.userId === userLoaded.id) {
            return "Tu es l'organisateur"
        } else if (match.isFree || match.reservation.amountDue === 0) {
            return "Offert pour " + match.reservation.duration
        } else { 
            return match.price + "€ pour " + match.reservation.duration
        }
    }

    return (
        <>
            <div className="radioListe__item__title">
                {startHour}
            </div>
            <div className="radioListe__item__content">
                <div className='center'>
                    {match.reservation.centerName}
                </div>
                <div className="level">
                    <LevelStars
                        level={match.level}
                    />
                    {levelText}
                </div>
                <div className="radioListe__item__price" data-testid="displayText">
                    {displayPrice()}
                </div>
            </div>
        </>
    )
}

SuperSubRequestCheckboxContent.propTypes = {
    alreadyAnsweredStatus: number,
    match: object,
}

export default SuperSubRequestCheckboxContent
