
import { _apiCall } from "../../../../backend/axios"
import { returnAnswerData, returnApiAnswer } from "../../../../backend/helpers"

export function registerLocationScores(locationId, team1Score, team2Score, theme)  {
    return _apiCall(
        "modify/reservation/" + locationId + "/scores",
        "PUT",
        {
            "Content-Type" : "application/json",
            activity: theme,
        },
        theme === 2 ?
            {
                scores: team1Score.map((score, i) => ({
                    "matchSet": i + 1,
                    "scoreTeam1": score,
                    "scoreTeam2": team2Score[i],
                })),
            }
            :
            {
                team1Score: parseInt(team1Score),
                team2Score: parseInt(team2Score),
            },
    ).then(returnApiAnswer)
}

export function cancelLocation(id) {
    return _apiCall(
        "delete/location",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        {
            id: id,
        },
    ).then(returnAnswerData)
}

export function reportLocation(data, theme) {
    return _apiCall(
        "create/locationQuery",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
            activity: theme,
        },
        data,
    ).then(returnAnswerData)
}

export function getCentersMessages(theme) {
    return _apiCall(
        "read/centers/rental-informations",
        "GET",
        theme,
    ).then(returnAnswerData)
}
