import { components } from "react-select"

export const CustomClearIndicator = props => {
    // const styles = tv({
    //     base: "text-gray-400 cursor-pointer transition duration-200 hover:text-error-600",
    // })

    return (
        <components.ClearIndicator
            {...props}
            className="select-input-clear-indicator"
        >
            X
        </components.ClearIndicator>
    )
}
