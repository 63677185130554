import { bool } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import CreneauDispo from "./CreneauxDispo"
import ButtonCta from "../../../../components/Buttons/button/button-cta.jsx"
import ActivationTrigger from "../../../../components/Buttons/Triggers/ActivationTrigger"
import CheckBlock from "../../../../components/Forms/CheckBlock/CheckBlock"
import { SelectInput } from "../../../../components/Inputs/Select/SelectInput.jsx"
import Preloader from "../../../../components/loaders/preloader/preloader"
import Modal from "../../../../components/Modal/Modal.jsx"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import Step from "../../../../components/stepsManagement/Step"
import Tooltip from "../../../../components/tooltip/Tooltip"
import useIsMobile from "../../../../hooks/useIsMobile.js"
import useResetScroll from "../../../../hooks/useResetScroll"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import usePath from "../../../../routes/services/usePath"
import { setFormEntry } from "../../../../store/forms/actions"
import MesEnfants from "../../steps/MesEnfants"
import { addBirthdayOption, editBirthday, removeBirthdayOption } from "../api"

import "./PersonnaliserAnniv.scss"
/**
 * Etape du formulaire BirthdayRegistration permettant de personnaliser son Anniversaire
 */
function PersonnaliserAnniv(props) {
    useResetScroll()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const path = usePath()
    const isMobile = useIsMobile()
    const forms = useSelector(state => state.forms)
    const formEnfant = forms[MesEnfants.formName] || {}
    const formCreneauDispo = forms[CreneauDispo.formName] || {}
    const formPersonnaliserAnnivData = forms[PersonnaliserAnniv.formDataName] || {
        selectedCake: {},
        selectedDrink: {},
        selectedEnfantSupp: {},
        selectedGift: {},
        selectedGiftSize: null,
        selectedOptionWithCategory: [],
        selectedOptions: [],
    }
    const stepManagement = useSelector(state => state.stepManagement)

    const [ dataLoading, setDataLoading ] = useState(false)
    const [ cartDisplay, setCartDisplay ] = useState(false)
    
    const step = useRef(null)

    const handleValidation = () => {
        const giftSize = formPersonnaliserAnnivData.selectedGiftSize || (formPersonnaliserAnnivData.selectedGift.sizes?.[0]?.Key || null)
        const formattedDate = formCreneauDispo.birthdaySubscription.start.replace(/T/g, " ")
        const avoirFormData = forms.avoirFormData || {}

        setDataLoading(true)
        return editBirthday(
            formCreneauDispo.birthdaySubscription.id,
            formEnfant.selectedKid ? formEnfant.selectedKid.id : formCreneauDispo.birthdaySubscription.kid.id,
            formCreneauDispo.birthdaySubscription.birthdayConfig.id,
            formattedDate,
            formPersonnaliserAnnivData.selectedGift.id,
            giftSize,
            formPersonnaliserAnnivData.selectedDrink.id,
            formPersonnaliserAnnivData.selectedCake.id,
            formCreneauDispo.birthdaySubscription.comments,
        ).then((res) => {
            setFormInStore(CreneauDispo.formName, { ...formCreneauDispo, birthdaySubscription: res.data.data })
            setFormInStore("avoirFormData", { ...avoirFormData, recalcAvoir: true })
            setDataLoading(false)
        })
    }

    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
    }

    useEffect(() => {
        const sub = formCreneauDispo.birthdaySubscription
        if (sub && sub.gifts[0] && sub.drinks[0] && sub.cakes[0]) {
            const selectedOptionWithCategory = sub.optionProducts?.filter(el => el.category) || []
            const selectedOptions = sub.optionProducts?.filter(el => !el.category) || []
            setFormInStore(PersonnaliserAnniv.formDataName, {
                ...formPersonnaliserAnnivData,
                selectedCake: sub.cakes[0],
                selectedDrink: sub.drinks[0],
                selectedGift: sub.gifts[0].product,
                selectedGiftSize: sub.gifts[0].sizeValue,
                selectedOptionWithCategory,
                selectedOptions,
            })
        }
    }, [])

    useEffect(() => {
        if (step.current) {
            const { selectedGift, selectedDrink, selectedCake } = formPersonnaliserAnnivData
            const hasValidSelection = selectedGift.id && selectedDrink.id && (!formCreneauDispo.birthdaySubscription.birthdayConfig.cakes.length || selectedCake.id)
            hasValidSelection ? step.current.validate() : step.current.unvalidate()
        }
    }, [ formPersonnaliserAnnivData ])

    const buildPreferences = () => {
        if (!formCreneauDispo?.birthdaySubscription) {return { cakes: [], drinks: [], gifts: [] }}
        const { gifts, drinks, cakes } = formCreneauDispo.birthdaySubscription.birthdayConfig

        const buildGift = (gift) => {
            const formattedSizes = formatSizes(gift)
            const giftSelected = formPersonnaliserAnnivData.selectedGift.id === gift.id
            const descriptionTooltip = buildDescriptionTooltip(gift)

            return (
                <div className="preference-div gift-div" key={gift.id}>
                    <CheckBlock
                        callback={onGiftClick}
                        checked={giftSelected}
                        name="gifts"
                        value={gift.id}
                        disabled={!gift.id}
                    >
                        <div className="checkblock-content radioList__item__subTitle">
                            <div className="pictureUrl">
                                <img src={gift.pictureUrl}/>
                            </div>
                            <div className={gift.sizes && gift.sizes.length > 1 ? "right-part" : ""}>
                                <div className="name">
                                    {gift.name}
                                </div>
                                {gift.sizes && gift.sizes.length > 1 && (
                                    <div className="sizesSelect hide-sm" onClick={e => e.stopPropagation()}>
                                        <SelectInput
                                            id={gift.id + "_sizes"}
                                            label="Taille"
                                            value={formPersonnaliserAnnivData.selectedGiftSize}
                                            options={formattedSizes}
                                            onChange={
                                                (event) => {
                                                    setFormInStore(
                                                        PersonnaliserAnniv.formDataName, {
                                                            ...formPersonnaliserAnnivData,
                                                            selectedGift: event.target.value === "" ? {} : gift,
                                                            selectedGiftSize: event.target.value,
                                                        },
                                                    )
                                                }
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {descriptionTooltip}
                    </CheckBlock>
                </div>
            )
        }

        const buildDrink = (drink) => (
            <div className="preference-div" key={drink.id}>
                <CheckBlock
                    callback={onDrinkClick}
                    checked={formPersonnaliserAnnivData.selectedDrink.id === drink.id}
                    name="drinks"
                    value={drink.id}
                    disabled={!drink.id}
                >
                    <div className="checkblock-content radioList__item__subTitle">
                        <div className="name">{drink.name}</div>
                    </div>
                    {buildDescriptionTooltip(drink)}
                </CheckBlock>
            </div>
        )

        const buildCake = (cake) => (
            <div className="preference-div" key={cake.id}>
                <CheckBlock
                    callback={onCakeClick}
                    checked={formPersonnaliserAnnivData.selectedCake.id === cake.id}
                    name="cakes"
                    value={cake.id}
                    disabled={!cake.id}
                >
                    <div className="checkblock-content radioList__item__subTitle">
                        <div className="name">{cake.name}</div>
                    </div>
                    {buildDescriptionTooltip(cake)}
                </CheckBlock>
            </div>
        )

        return {
            cakes: cakes.map(buildCake),
            drinks: drinks.map(buildDrink),
            gifts: gifts.map(buildGift),
        }
    }

    const buildOptions = () => {
        const { optionProducts = [] } = formCreneauDispo?.birthdaySubscription?.birthdayConfig || {}
        const options = []
        const optionsWithCategory = {}

        optionProducts.forEach((optionProduct, idx) => {
            if (!optionProduct.category && !optionProduct.showAtPayment) {
                options.push(buildOption(optionProduct, idx))
            } else if (!optionProduct.showAtPayment) {
                if (!optionsWithCategory[optionProduct.category]) {optionsWithCategory[optionProduct.category] = []}
                optionsWithCategory[optionProduct.category].push(buildOption(optionProduct, idx, true))
            }
        })

        const optionsWithCategoryDisplay = Object.entries(optionsWithCategory).map(([ category, items ]) => (
            <div className="c-col preferences-div" key={category}>
                <div className="h3">{category}<span className="span-parentheses"> (facultatif)</span></div>
                <div className="radioList--small--half">{items}</div>
            </div>
        ))

        return { options, optionsWithCategoryDisplay }
    }

    const buildOption = (optionProduct, idx, isCategory) => {
        const selector = isCategory ? "selectedOptionWithCategory"  : "selectedOptions"
        const isSelected = formPersonnaliserAnnivData[selector].find((opt) => opt.id === optionProduct.id)
        const handleClick = isCategory ? onOptionWithCategoryClick : onOptionClick
        const checkboxClass = `preference-div${optionProduct.description ? " CheckBlockWithTooltip" : ""}`

        return (
            <div className={checkboxClass} key={optionProduct.id}>
                <CheckBlock
                    callback={handleClick}
                    checked={isSelected}
                    name={`options${isCategory ? "WithCategory" : ""}`}
                    value={optionProduct.id}
                    disabled={optionProduct.isFull}
                >
                    <div className="checkblock-content --column radioList__item__subTitle">
                        <div className="name">{optionProduct.name}</div>
                        {optionProduct.isFull ? <div className="price">Complet</div> : <div className="price">{optionProduct.price} €</div>}
                    </div>
                    {buildDescriptionTooltip(optionProduct)}
                </CheckBlock>
            </div>
        )
    }

    const buildDescriptionTooltip = (item) => (
        item.description && (
            <div className="infoBubble">
                {!isMobile ? (
                    <>
                        <Tooltip id={`infoBubble-option-birthday-${item.id}`}>
                            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </Tooltip>
                        <ActivationTrigger id={`buttonInfoBubble-${item.id}`} target={`#infoBubble-option-birthday-${item.id}`}>
                            <div className="infoBubble__icon">
                                <img src="/assets/icons/info-small.svg" alt="info" />
                            </div>
                        </ActivationTrigger>
                    </>
                ) : (
                    <button className={"activationTrigger"} onClick={(event) => {
                        event.stopPropagation()
                        event.preventDefault()

                        ModalHandler.show(TooltipDescriptionModal, {
                            description: item.description,
                        })
                    }}>
                        <div className="infoBubble__icon">
                            <img src="/assets/icons/info-small.svg" alt="info"/>
                        </div>
                    </button>
                )}
            </div>
        )
    )

    const onGiftClick = (event) => {
        const gift = formCreneauDispo.birthdaySubscription.birthdayConfig.gifts.find((gift) => gift.id === parseInt(event.currentTarget.id))
        let giftSize = formPersonnaliserAnnivData.selectedGiftSize || (gift.sizes?.[0]?.Key || "")
        const isGiftNotSelected = formPersonnaliserAnnivData.selectedGift.id !== gift.id

        if (!gift.sizes.find((s) => s.Key === giftSize)) {
            if (gift.sizes && gift.sizes[0]) {
                giftSize = gift.sizes[0].Key
            }
        }

        if (isMobile && gift.sizes?.length > 1) {
            if (isGiftNotSelected) {
                ModalHandler.show(MobileSizeModal, { callBack: (gift, selectedSize) => {
                    setFormInStore(PersonnaliserAnniv.formDataName, {
                        ...formPersonnaliserAnnivData,
                        selectedGift: gift,
                        selectedGiftSize: selectedSize,
                    })
                    ModalHandler.hide(MobileSizeModal)
                }, gift, giftSize: isGiftNotSelected ? "" : isGiftNotSelected })
            } else {
                setFormInStore(PersonnaliserAnniv.formDataName, {
                    ...formPersonnaliserAnnivData,
                    selectedGift: {},
                    selectedGiftSize: giftSize,
                })
            }
        } else {
            setFormInStore(PersonnaliserAnniv.formDataName, {
                ...formPersonnaliserAnnivData,
                selectedGift: isGiftNotSelected ? gift : {},
                selectedGiftSize: isGiftNotSelected ? giftSize : "",
            })
        }
    }

    const onDrinkClick = (event) => {
        const drink = formCreneauDispo.birthdaySubscription.birthdayConfig.drinks.find((drink) => drink.id === parseInt(event.currentTarget.id))
        setFormInStore(PersonnaliserAnniv.formDataName, {
            ...formPersonnaliserAnnivData,
            selectedDrink: formPersonnaliserAnnivData.selectedDrink.id !== drink.id ? drink : {},
        })
    }

    const onCakeClick = (event) => {
        const cake = formCreneauDispo.birthdaySubscription.birthdayConfig.cakes.find((cake) => cake.id === parseInt(event.currentTarget.id))
        setFormInStore(PersonnaliserAnniv.formDataName, {
            ...formPersonnaliserAnnivData,
            selectedCake: formPersonnaliserAnnivData.selectedCake.id !== cake.id ? cake : {},
        })
    }

    const onOptionClick = (event) => {
        const option = formCreneauDispo.birthdaySubscription.birthdayConfig.optionProducts.find((option) => option.id === parseInt(event.currentTarget.id))
        const isSelected = formPersonnaliserAnnivData.selectedOptions.find((opt) => opt.id === option.id)

        if (isSelected) {
            const updatedOptions = formPersonnaliserAnnivData.selectedOptions.filter((opt) => opt.id !== option.id)
            setFormInStore(PersonnaliserAnniv.formDataName, { ...formPersonnaliserAnnivData, selectedOptions: updatedOptions })
            setDataLoading(true)
            removeBirthdayOption(formCreneauDispo.birthdaySubscription.id, option.id).then(() => setDataLoading(false))
        } else {
            setFormInStore(PersonnaliserAnniv.formDataName, {
                ...formPersonnaliserAnnivData,
                selectedOptions: [ ...formPersonnaliserAnnivData.selectedOptions, option ],
            })
            setDataLoading(true)
            addBirthdayOption(formCreneauDispo.birthdaySubscription.id, option.id).then(() => setDataLoading(false))
        }
    }

    const onOptionWithCategoryClick = (event) => {
        const option = formCreneauDispo.birthdaySubscription.birthdayConfig.optionProducts.find((option) => option.id === parseInt(event.currentTarget.id))
        const isSelected = formPersonnaliserAnnivData.selectedOptionWithCategory.find((opt) => opt.id === option.id)

        if (isSelected) {
            const updatedOptions = formPersonnaliserAnnivData.selectedOptionWithCategory.filter((opt) => opt.id !== option.id)
            setFormInStore(PersonnaliserAnniv.formDataName, { ...formPersonnaliserAnnivData, selectedOptionWithCategory: updatedOptions })
            setDataLoading(true)

            removeBirthdayOption(formCreneauDispo.birthdaySubscription.id, option.id).then(() => setDataLoading(false))
        } else {
            const updatedOptions = formPersonnaliserAnnivData.selectedOptionWithCategory.filter((opt) => opt.category !== option.category)
            updatedOptions.push(option)

            setFormInStore(PersonnaliserAnniv.formDataName, { ...formPersonnaliserAnnivData, selectedOptionWithCategory: updatedOptions })
            setDataLoading(true)

            Promise.all(updatedOptions?.filter(opt => opt.id !== option.id && opt.category === option.category).map(opt => removeBirthdayOption(formCreneauDispo.birthdaySubscription.id, opt.id)))
                .then(() => addBirthdayOption(formCreneauDispo.birthdaySubscription.id, option.id).then(() => setDataLoading(false)))
        }
    }

    const closeToolTipSmall = () => setCartDisplay(false)

    const { gifts, drinks, cakes } = buildPreferences()
    const { options, optionsWithCategoryDisplay } = buildOptions()

    return (
        <Step
            {...props}
            title="Personnalisez votre anniversaire"
            help={<PhoneCallHelp tunnelType="BIRTHDAY" />}
            helpId={"personnaliserAnnivHelp"}
            ref={step}
            onValidation={handleValidation}
            returnText={props.isBirthdayRequestSlotFinalization ? "Voir mes anniversaires" : undefined}
            onReturn={props.isBirthdayRequestSlotFinalization ? () => {
                navigate(path("/kids/anniversaire/listing"))
            } : undefined}
            promise={true}
            customHeaderClass="personnaliserAnnivHeader"
            goToStep={
                formCreneauDispo.birthdaySubscription ?
                    formCreneauDispo.birthdaySubscription.birthdayConfig.catalogProducts.length === 0 ?
                        stepManagement.currentStep + 2
                        :
                        null
                    :
                    null
            }
            cls="arrows-on-sides"
            className="birthdayPersonnalisation"
        >
            <div className="formsHolder c-row justify-center align-start layoutSmall c-col c-col--10 c-col--sm--12 personnaliser-anniv">
                <div className={`c-col preferences-div ${!options.length && !optionsWithCategoryDisplay.length ? "noBorderDiv" : ""}`}>
                    <div className="h3">Sélectionnez vos préférences !<span className="span-parentheses"> (inclus dans votre formule)</span></div>
                    <div className="preference-title">Cadeau d'anniversaire <span className="star">*</span></div>
                    <div className="radioList--small--half">
                        {gifts}
                    </div>
                    <div className="preference-title">Boisson <span className="star">*</span></div>
                    <div className="radioList--small--half">
                        {drinks}
                    </div>
                    <div className="preference-title">Gâteau <span className="star">*</span></div>
                    {cakes.length > 0 && (
                        <div className="radioList--small--half">
                            {cakes}
                        </div>
                    )}
                </div>
                {options.length > 0 && (
                    <div className="c-col preferences-div">
                        <div className="h3">Options<span className="span-parentheses"> (facultatif)</span></div>
                        <div className="radioList--small--half">{options}</div>
                    </div>
                )}
                {optionsWithCategoryDisplay}
                <div className={`productItem__overlay ${cartDisplay ? "is-visible" : ""}`} onClick={closeToolTipSmall}></div>
            </div>
            {dataLoading && <Preloader fixed />}
        </Step>
    )
}

export const MobileSizeModal = ModalHandler.create(({ visible, hide, gift, giftSize, callBack }) => {
    const [ selectedSize, setSelectedSize ] = useState(giftSize)

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value)
    }

    return (
        <Modal isOpen={visible} onClose={hide} className={"birthdaySizeModal"}>
            <div>
                <SelectInput
                    id={gift.id + "_sizes"}
                    label="Taille"
                    value={selectedSize}
                    options={formatSizes(gift)}
                    onChange={handleSizeChange}
                />

                <ButtonCta
                    text={"Ajouter"}
                    disabled={!selectedSize}
                    onClick={() => {
                        callBack?.(gift, selectedSize)
                    }}/>
            </div>
        </Modal>
    )
})

export const TooltipDescriptionModal = ModalHandler.create(({ visible, hide, description }) => {
    return (
        <Modal isOpen={visible} onClose={hide} className={"tooltipDescriptionModal"}>
            <div>
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
        </Modal>
    )
})

export function formatSizes(gift) {
    return gift.sizes?.map(({ Key, Value }) => ({ label: Value, value: Key })) || []
}

PersonnaliserAnniv.formName = "personnaliserAnniv"
PersonnaliserAnniv.formDataName = "personnaliserAnnivData"
PersonnaliserAnniv.propTypes = {
    isBirthdayRequestSlotFinalization: bool,
}

export default PersonnaliserAnniv
