import { bool, func, object, oneOfType, string } from "prop-types"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../../../../hooks/useMediaPath"

export default function RegisterSubscriptionCancel({ freeCancelDate, cancelReservation, goToCreateSuperSub, location }) {
    const { tu } = useUrbanLanguage()
    const mediaPath = useMediaPath()

    return (
        <div className="blocAnnuler">
            <div className="title">ANNULATION</div>
            {
                freeCancelDate ?
                    (<div className="annulationGratuite">
                        <img src={mediaPath([ "/assets/icons/calendar-orange.svg" , "/assets/images/padel/icons/icon-calendar-green.svg" ])} className="calendarIcon" />
                        <div className="freeCancel">
                            <p>Annulation possible jusqu’au :</p>
                            <strong>{freeCancelDate}</strong>
                        </div>
                    </div>)
                    :
                    (<div className="annulationPayante">
                        <img src={mediaPath([ "/assets/icons/calendar-orange.svg" , "/assets/images/padel/icons/icon-calendar-red.svg" ])} className="calendarIcon" />
                        <p>
                            Si tu annules ta réservation, tu seras seras Hors Délai.<br />
                            Après 2 annulations hors délai, ton abonnement est susceptible d'être résilié.
                        </p>
                    </div>)
            }

            <div className="btnsDiv">
                <ButtonCta
                    text="Annuler ma session"
                    color="grey"
                    onClick={cancelReservation}
                    isCta2Alt
                />
                {location.isSupersubRequestAvailable &&
                    (<div className="missingPlayers" onClick={() => {
                        goToCreateSuperSub()
                    }} >
                        Il te manque des joueurs ? <span>{tu("booking.supersub.thinkAbout")}</span>
                    </div>)
                }

            </div>
        </div>
    )
}

RegisterSubscriptionCancel.propTypes = {
    cancelReservation: func,
    freeCancelDate: oneOfType([ bool, string ]),
    goToCreateSuperSub: func,
    location: object,
}
