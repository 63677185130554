import { motion } from "framer-motion"
import { bool, func, string } from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import OverlayLevel3Content from "./overlay_level3_content"
import { toggleLevel3Overlay } from "../../../store/actions/content/index"

const animationVariants = {
    hidden: {
        opacity: 0,
        y: "-100%",
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.12,
        },
        y: 0,
    },
}

function OverlayLevel3() {

    const open = useSelector(state => state.level3Overlay.open)
    const withCloseButton = useSelector(state => state.level3Overlay.withCloseButton)
    const closeAction = useSelector(state => state.level3Overlay.closeAction)
    const extraClasses = useSelector(state => state.level3Overlay.extraClasses)
    const dispatch = useDispatch()

    function defineClassName() {
        let className = open ? " " : "hidden"
        if (!withCloseButton) {
            className += " no-transparency"
        }
        if (extraClasses) {
            className += " " + extraClasses
        }
        return className
    }

    return (
        <motion.div
            id="overlay-level3"
            className={defineClassName()}
            initial="hidden"
            animate={open ? "visible" : "hidden"}
            variants={animationVariants}
        >
            <OverlayLevel3Content />
            {withCloseButton && (
                <img
                    src="/assets/images/Close-W.svg"
                    className="close-button"
                    onClick={() => {
                        if (closeAction) {
                            closeAction()
                        }
                        dispatch(
                            toggleLevel3Overlay(
                                false,
                                null,
                            ),
                        )
                    }}
                />
            )}
        </motion.div>
    )
}

OverlayLevel3.propTypes = {
    closeAction: func,
    extraClasses: string,
    notClosePopin: bool,
    open: bool,
    toggleLevel3Overlay: func,
    withCloseButton: bool,
}

export default OverlayLevel3
