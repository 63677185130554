import classNames from "classnames"
import { bool, func, number, oneOfType, string } from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import VerificationInput from "react-verification-input"

import { confirmPhone } from "./api"
import { getUser } from "../../../globalAPI/api"
import useThemeHeader from "../../../hooks/useThemeHeader"
import SignUpPage from "../../../pages/authentication/signUpPage/signUpPage"
import usePath from "../../../routes/services/usePath"
import { formatTel } from "../../../services/format"
import resetScroll from "../../../services/resetScroll"
import { toggleShowFooter } from "../../../store/actions/scaffold"
import {  goNextAuthStep, resetAuthStepper } from "../../../store/auth/actions/actions"
import { setFormEntry } from "../../../store/forms/actions"
import BlocRetry from "../../blocRetry/blocRetry"
import Preloader from "../../loaders/preloader/preloader"
import { sendConfirmEmail } from "../emailConfirmation/api"
import { signIn } from "../loginComponent/api"
import { sendConfirmPhone } from "../signupForm/api"

import "./smsCodeVerif.scss"

function SmsCodeVerif({ userId, tel, isLoginPhoneUpdating, endAction, isUserPhoneNotConfirm, resetAfterPhoneConfirm, userEmail, goToEmailStep }) {
    const [ code, setCode ] = useState("")
    const [ hasError, setHasError ] = useState(false)
    const [ newTel, setNewTel ] = useState(null)
    const [ isSending, setIsSending ] = useState(false)
    const [ retryComplete, setRetryComplete ] = useState(false)
    const [ isSuccess, setIsSuccess ] = useState(false)
    const [ successOrErrorMsg, setSuccessOrErrorMsg ] = useState(false)
    const [ blockedDuration, setBlockedDuration ] = useState(60000)
    const [ codeInputCompete, setCodeInputCompete ] = useState(false)

    const signUpPageData = useSelector(state => state.forms[SignUpPage.formName])
    const codeDigitCount = 4

    /* ACTIONS */
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = usePath()
    const themeHeader = useThemeHeader()
    const [ , updateState ] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }

    useEffect(
        () => {
            if (codeInputCompete && code.length === codeDigitCount) {
                setCodeInputCompete(false)
                setHasError(false)
                const id = signUpPageData?.id ? signUpPageData.id : userId
                if (id) {
                    setIsSending(true)
                    confirmPhone(id, code).then((res) => {
                        handlePhoneConfirmation(res)
                    })
                }
            }
        }, [ code, codeInputCompete ],
    )

    function handleNotVerifyMail() {
        if (signUpPageData && signUpPageData.accountType === 2) {
            signIn(null, null, null, signUpPageData.tokenAuth).then(() => {
                getUser().then(
                    () => {
                        setIsSending(false)
                        navigate(path("/login"))
                    },
                )
            })
        } else if (signUpPageData && signUpPageData.accountType === 1) {
            signIn(null, null, signUpPageData.tokenAuth, null).then(() => {
                getUser().then(
                    () => {
                        setIsSending(false)
                        navigate(path("/login"))
                    },
                )
            })
        } else {
            setIsSending(false)
        }
    }

    function handlePhoneConfirmation(res) {
        if (resetAfterPhoneConfirm) {
            dispatch(
                resetAuthStepper(),
            )
        }
        if (res?.data?.data?.Message) {
            setHasError(true)
            setIsSending(false)
        } else {
            if (isLoginPhoneUpdating) {
                if (endAction) {
                    endAction(true)
                }
                setIsSending(false)
            } else {
                if (signUpPageData && signUpPageData.verify === 2) {
                    handleNotVerifyMail()
                } else {
                    const id = signUpPageData?.id ? signUpPageData.id : userId
                    const email = signUpPageData?.email || userEmail
                    sendConfirmEmail(
                        id,
                        email,
                        localStorage.getItem("changeCoordinatesToken"),
                        themeHeader,
                    ).then(
                        () => {
                            resetScroll()
                            if (userEmail) {
                                return goToEmailStep?.()
                            }
                            setIsSending(false)
                            dispatch(goNextAuthStep())
                        },
                    )
                }
            }
        }
    }
    const handleEmptyTelNSpace = tel ? tel.replace(/ /g,"") : ""
    const signupTelValue = signUpPageData ? 
        signUpPageData.tel.replace(/ /g,"") 
        :
        handleEmptyTelNSpace

    return (
        <React.Fragment>
            {
                isSending &&
                    <Preloader fixed={true} />
            }
            <div className="smsCodeVerif">
                <div className="introText introTextBold">Saisis le code que nous venons de t'envoyer. </div>

                <VerificationInput
                    validChars="0-9"
                    autoFocus
                    placeholder=""
                    onComplete={
                        (e) => {
                            setCode(e)
                            setCodeInputCompete(true)
                        }
                    }
                    onBlur={() => {
                        dispatch(toggleShowFooter(true))
                    }}
                    onFocus={() => {
                        dispatch(toggleShowFooter(false))
                    }}
                    length={codeDigitCount}
                    classNames={{
                        character: "character",
                        characterInactive: "inactive",
                        characterSelected: "selected",
                        container: classNames("smsAutoCodeVerif", { hasError }),
                    }}
                />

                { hasError && (
                    <div className="errorLabel">
                        <img className="iconError" src="/assets/icons/errorNotif.svg" />
                        <div>
                            Le code saisi est invalide
                        </div>
                    </div>
                )}

                <BlocRetry
                    onFocus={(e) => {
                        if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                            dispatch(toggleShowFooter(false))
                        }
                    }}
                    onBlur={(e) => {
                        if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                            dispatch(toggleShowFooter(true))
                        }
                    }}
                    type="phoneNumber"
                    value={
                        isLoginPhoneUpdating ?
                            tel.replace(/ /g,"")
                            :
                            signupTelValue
                    }
                    blockedDuration={blockedDuration}
                    retryActionComplete={retryComplete}
                    isSuccess={isSuccess}
                    successOrErrorMsg={successOrErrorMsg}
                    onChangeAction={(val) => {
                        setNewTel(val)
                    }}
                    notValid={
                        isLoginPhoneUpdating ?
                            !tel && newTel === ""
                            :
                            !signupTelValue 
                    }
                    retryAction={() => {
                        setIsSending(true)

                        if (isLoginPhoneUpdating) {
                            if (userId && tel) {
                                sendConfirmPhone(
                                    userId,
                                    newTel !== null ? "+" + formatTel(newTel) : "+" + formatTel(tel),
                                    isUserPhoneNotConfirm ? localStorage.getItem("changeCoordinatesToken") : null,
                                    themeHeader,
                                ).then((resPhone) => {
                                    
                                    if (resPhone?.data?.data?.Message) {
                                        setSuccessOrErrorMsg(resPhone.data.data.Message)
                                        if (resPhone.data.data.nbMinutes) {
                                            setBlockedDuration(resPhone.data.data.nbMinutes * 60000)
                                            setIsSuccess(true)
                                        } else {
                                            setIsSuccess(false)
                                            setBlockedDuration(60000)
                                        }
                                    } else if (resPhone?.data?.data) {
                                        setIsSuccess(true)
                                        setBlockedDuration(60000)
                                        setSuccessOrErrorMsg("Un nouveau SMS a été envoyé")
                                    } else {
                                        setBlockedDuration(60000)
                                        setIsSuccess(false)
                                    }
                                    setIsSending(false)
                                    setRetryComplete(true)
                                    setFormInStore(
                                        SignUpPage.formName, {
                                            ...signUpPageData,
                                            id: resPhone.data.data.id,
                                            tel: newTel !== null ? newTel.replace(/ /g,"") : tel.replace(/ /g,""),
                                        },
                                    )
                                }).catch(() => {
                                    setBlockedDuration(60000)
                                    setIsSuccess(false)
                                    setSuccessOrErrorMsg("Impossible d'envoyer le SMS au numéro indiqué")
                                })
                            }
                        } else {
                            /* UPDATE USER DATA WITH NEW TEL*/

                            const phoneNum = newTel !== null ? "+" + formatTel(newTel) : "+" + formatTel(signUpPageData.tel)

                            sendConfirmPhone(signUpPageData.id, phoneNum, localStorage.getItem("changeCoordinatesToken"), themeHeader).then((res) => {
                                if (res?.data?.data?.Message) {
                                    if (res.data.data.nbMinutes) {
                                        setBlockedDuration(res.data.data.nbMinutes * 60 * 1000)
                                        setIsSuccess(true)
                                    }
                                    else {
                                        setIsSuccess(false)
                                        setBlockedDuration(60000)
                                    }
                                    setRetryComplete(true)
                                    setSuccessOrErrorMsg(res.data.data.Message)
                                    setIsSending(false)
                                } else {
                                    if (res?.data?.data?.id) {
                                        setRetryComplete(true)
                                        setFormInStore(
                                            SignUpPage.formName, {
                                                ...signUpPageData,
                                                id: res.data.data.id,
                                                tel: phoneNum,
                                            },
                                        )
                                        setBlockedDuration(60000)
                                        setIsSuccess(true)
                                        setSuccessOrErrorMsg("Un nouveau SMS a été envoyé")
                                        setIsSending(false)
                                    }
                                }
                            })
                        }
                    }}
                />
            </div>
        </React.Fragment>
    )
}

SmsCodeVerif.propTypes = {
    endAction: func,
    goToEmailStep: func,
    isLoginPhoneUpdating: bool,
    isUserPhoneNotConfirm: bool,
    resetAfterPhoneConfirm: bool,
    tel: string,
    userEmail: oneOfType([ string, bool ]),
    userId: number,
}

export default SmsCodeVerif
