import dayjs from "dayjs"
import { string } from "prop-types"
import { useMemo } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getAllKidsSchoolsSubscriptions } from "./api.js"
import SchoolListingElement from "./components/SchoolListingElement/SchoolListingElement.jsx"
import Listing from "../../../components/listing/Listing.jsx"
import { useTheme } from "../../../components/ThemeContext/ThemeContext"
import { usePaginatedListing } from "../../../hooks/usePaginatedListing.js"
import useThemeFeatures from "../../../hooks/useThemeFeatures.js"
import { clearAllForms, setFormEntry } from "../../../store/forms/actions.js"

function ListingSchool({ onglet }) {
    const periodStart = dayjs().subtract(3, "year").format("YYYY-MM-DD")

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const themeFeatures = useThemeFeatures()
    const theme = useTheme()

    const {
        disableActions,
        displayToIndex,
        getItems,
        isLoading,
        listItems,
        loadMore,
        totalElements,
    } = usePaginatedListing(
        getAllKidsSchoolsSubscriptions,
        {
            orderByAsc: false,
            periodStart: periodStart,
            status: "[2,1,3]",
        },
        (res) => {
            dispatch(
                setFormEntry(
                    "RegistrationCount",
                    res.count || res.length,
                ),
            )
            if (res.count === 0) {
                goToTunnel(true)
            } else {
                if (onglet === "new") {
                    goToTunnel(false)
                }
            }
        },
    )

    const goToTunnel = (noReturn = false) => {
        dispatch(
            clearAllForms(),
        )
        navigate(
            themeFeatures([ "/kids/ecoleDeFoot/new", "/padel/coursStages/inscription" ]),
            {
                state: {
                    denyReturn: noReturn,
                },
            },
        )
    }

    const content = useMemo(() => {
        return listItems?.map((item, index) => {
            if (index > displayToIndex) { return }

            return (
                <SchoolListingElement
                    callback={getItems}
                    schoolRegistration={item}
                    key={index}
                />
            )
        })
    }, [ listItems, displayToIndex ])

    return (
        <Listing
            title={theme === "theme-soccer" ? "Mes écoles de foot" : "Mes cours"}
            newButtonText="Nouvelle inscription"
            type='SCHOOL'
            loadMore={loadMore}
            itemsData={content}
            isLoading={isLoading}
            goToTunnel={() => goToTunnel()}
            disableLoadMore={disableActions}
            canShowMore={displayToIndex + 1 < totalElements}
        />
    )
}

ListingSchool.propTypes = {
    onglet: string,
}
export default ListingSchool
