import { loadUser } from "../../../store/actions/scaffold"
import store from "../../../store/store"
import { modifyUserPicture } from "../api"

export function uploadFile(file) {
    return new Promise(
        (resolve) => {
            var fileData = new FormData()
            fileData.append("picture", file)
            modifyUserPicture(fileData).then(
                (res) => {
                    store.dispatch(loadUser(res.data))
                    resolve()
                },
            )
        },
    )
}

export function myInformationStepperTitle(currentAuthStepId) {
    switch (currentAuthStepId) {
    case 1:
        return "Renseigner un Email"
    case 2:
        return "Gestion du compte"
    default:
        return "Unknown step"
    }
}
