import { useState } from "react"
import { useDispatch } from "react-redux"

import ButtonCta from "../../../../../../components/Buttons/button/button-cta"
import InputDate from "../../../../../../components/Forms/InputDate/InputDate"
import { SelectInput } from "../../../../../../components/Inputs/Select/SelectInput.jsx"
import {
    useSchoolTrainingCurrent,
    useSchoolTrainingTunnel,
} from "../../../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import { goToStep } from "../../../../../../store/stepsManagement/actions"
import PreForm from "../../../../../kids/components/preForm/PreForm"
import { SlotChoiceFilters } from "../SlotChoiceFilters/SlotChoiceFilters.jsx"

import "./SlotChoicePreform.scss"

export default function SlotChoicePreform() {
    const currentSelection = useSchoolTrainingCurrent()
    const { setValue: setTunnelType } = useSchoolTrainingTunnel()
    const { value: { selectedAreaId, areas, periodStart }, setValue: setCurrentSelection } = currentSelection
    const [ modalOpen, setModalOpen ] = useState(false)
    const dispatch = useDispatch()

    const setPeriodStart = (key, value) => setCurrentSelection(prev => ({ ...prev, [key]: value }))

    return (
        <PreForm customCls="slotChoicePreform">
            <div className="flexVerticalOnMobile">
                <label className="h2">
                    <span>Ville :</span>
                    <SelectInput
                        disabled={!areas}
                        value={selectedAreaId}
                        options={ areas?.length > 0 ?
                            areas.map(x => {
                                return { label: x.name, value: x.id }
                            })
                            :
                            undefined
                        }
                        label={"Sélectionne une ville"}
                        onChange={e => {
                            dispatch(goToStep(1))
                            setTunnelType(undefined)
                            setCurrentSelection(prev => ({ ...prev, prestation: undefined, selectedAreaCenters: areas?.find(area => area.id === parseInt(e?.target?.value))?.centers, selectedAreaId: parseInt(e?.target?.value) }))
                        }}
                        placeholderOption="Sélectionne une ville"
                        required
                    />
                </label>
                <label className="h2">
                    <span>Date :</span>
                    <InputDate
                        bind={[ periodStart, "periodStart" ,setPeriodStart ]} 
                        min={new Date()}
                        useDatePicker
                        customClass={
                            "customDayPicker"
                        }
                        position="left"
                        checkValue
                        label={"Sélectionne une date"}
                    />
                </label>
            </div>
            <div className="moreOptions">
                <ButtonCta
                    isAlt
                    text= "+ plus d'options"
                    onClick={() => setModalOpen(true)}
                />
                <SlotChoiceFilters isOpen={modalOpen} setIsOpen={() => setModalOpen(false)} />
            </div>
        </PreForm>
    )
}
