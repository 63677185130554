import { _apiCall } from "../../backend/axios"

export function getDashboardAds(theme) {
    let type
    switch (theme) {
    case "theme-soccer":
        type = 0
        break
    case "theme-padel":
        type = 1
        break
    default:
        type = 0
        break
    }

    return _apiCall(
        `read/dashboard/items?type=${type}`,
        "GET",
    ).then((res) => {
        return res.data.data
    })
}

