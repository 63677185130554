import { _apiCall } from "../../../backend/axios"

export function createSupersubRequest(
    reservationId,
    askedPlayers,
    level,
    description,
    isFree,
) {
    return _apiCall(
        "create/supersubs/requests",
        "POST",
        {
            "Content-Type": "application/json",
        },
        {
            "askedPlayers": askedPlayers,
            "description": description,
            "isFree": isFree,
            "level": level,
            "reservationId": reservationId,
        },
    )
}

export function modifySupersubRequest(
    id,
    askedPlayers,
    level,
    description,
    isFree,
) {
    const data = {
        "askedPlayers": askedPlayers,
        "description": description,
        "level": level,
    }

    if (isFree !== null) {
        data.isFree = isFree
    }

    return _apiCall(
        "modify/supersubs/requests/" + id,
        "PUT",
        {
            "Content-Type": "application/json",
        },
        data,
    )
}
