import AbonnementsContent from "../../entries/main/contents/main/abonnementsContent"
import Bill from "../../pages/profil/Bill"
import Debt from "../../pages/profil/Debt"
import EditPersonnalInformations from "../../pages/profil/EditPersonnalInformations"
import { FavoriteSlotsPage } from "../../pages/profil/FavoriteSlots/FavoriteSlotsPage"
import FriendsInvitation from "../../pages/profil/FriendsInvitation"
import MoyensDePaiementContent from "../../pages/profil/moyensDePaiementContent"
import MyInformation from "../../pages/profil/MyInformation"

const RoutesProfile = [
    {
        element: (<FriendsInvitation />),
        path: "/profil/coequipiers/*",
    },
    {
        element: (<FavoriteSlotsPage/>),
        path: "/profil/creneauxFavoris/*",
    },
    {
        element: (<AbonnementsContent />),
        path: "/profil/abonnements",
    },
    {
        element: (<Bill />),
        path: "/profil/factures",
    },
    {
        element: (<Debt />),
        path: "/profil/ardoises",
    },
    {
        element: (<MoyensDePaiementContent />),
        path: "/profil/moyensDePaiement",
    },
    {
        element: (<MoyensDePaiementContent />),
        path: "/profil/moyensDePaiement/:onglet",
    },
    {
        element: (<MyInformation />),
        path: "/profil/coordonnees",
    },
    {
        element: (<EditPersonnalInformations />),
        path: "/profil/coordonnees/edit",
    },
]

export default RoutesProfile
