import { arrayOf, func, node, oneOfType, string } from "prop-types"
import React, { Component } from "react"

class FormValidator extends Component {
    constructor(props) {
        super(props)

        this.state = {
            valid: false,
        }

        this.validate = this.validate.bind(this)

        this.form = React.createRef()
    }

    componentDidMount() {
        this.validate()
    }

    componentDidUpdate() {
        this.validate()
    }

    validate() {
        if (this.form.current && this.props.onValidation && this.props.onUnvalidation) {
            let radioOK = this.inputRadioAreValid()
            let selectOK = this.selectAreValid()
            let textOK = this.inputTextAreValid()
            let dateOK = this.inputDateAreValid()
            // let emailOK = this.inputTextAreValid();

            if (radioOK === null && selectOK === null && textOK === null && dateOK === null) {
                //Le formulaire n'as pas encore de contenu
                return
            } else {
                if (radioOK === null) {radioOK = true}
                if (selectOK === null) {selectOK = true}
                if (textOK === null) {textOK = true}
                if (dateOK === null) {dateOK = true}
                if (this.state.valid === false) {
                    if (radioOK && selectOK && textOK && dateOK) {
                        this.props.onValidation()
                        this.setState({
                            valid: true,
                        })
                    }
                } else if (this.state.valid === true) {
                    if (!radioOK || !selectOK || !textOK || !dateOK) {
                        this.props.onUnvalidation()
                        this.setState({
                            valid: false,
                        })
                    }
                } else {
                    // eslint-disable-next-line no-console
                    console.error("valid state must be boolean")
                    // eslint-disable-next-line no-debugger
                    debugger
                }
            }
        }
        return this.state.valid
    }

    areInputsValid(NodeList) {
        let res = true
        if (NodeList.length === 0) {
            res = null
        } else {
            for (let i = 0; i < NodeList.length; i++) {
                if (NodeList[i].validity.valid === false || NodeList[i].value.length < NodeList[i].minLength) {
                    res = false
                }
            }
        }
        return res
    }

    inputRadioAreValid() {
        let NodeList = this.form.current.querySelectorAll("input[type=\"radio\"]")
        if (NodeList.length === 0) {
            return null
        } else {
            let NodeArray = []

            for (let i = 0; i < NodeList.length; i++) {
                NodeArray.push(NodeList[i])
            }

            let nodeByName = {}
            NodeArray.forEach(
                (input) => {
                    if (nodeByName[input.name] === undefined) {
                        nodeByName[input.name] = {
                            nodes : [],
                            valid : false,
                        }
                    }
                    nodeByName[input.name].nodes.push(input)
                    if (input.checked) {
                        nodeByName[input.name].valid = true
                    }
                },
            )

            let valid = true
            Object.keys(nodeByName).forEach(
                (name) => {
                    if (nodeByName[name].valid === false) {
                        valid = false
                    }
                },
            )
            return valid
        }
    }

    selectAreValid() {
        let NodeList = this.form.current.querySelectorAll("select[required]")
        return this.areInputsValid(NodeList)
    }

    inputTextAreValid() {
        let NodeList = this.form.current.querySelectorAll("input[required]")
        return this.areInputsValid(NodeList)
    }

    inputDateAreValid() {
        let NodeList = this.form.current.querySelectorAll("input[type=\"date\"][required]")
        return this.areInputsValid(NodeList)
    }

    render() {
        return (
            <section
                className={this.props.className}
                style={{
                    width: "100%",
                }}
                ref={this.form}
            >
                { this.props.children }
            </section>
        )
    }
}

FormValidator.propTypes = {
    children: oneOfType([ string, node, arrayOf(node) ]),
    className: string,
    onUnvalidation: func,
    onValidation: func,
}

export default FormValidator
