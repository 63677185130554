import { _apiCall } from "../../../backend/axios"

export function forgottenPassword(email, captchaToken, theme) {
    const testingApiKey = import.meta.env.VITE_TESTING_API_KEY
    const headers = {
        "Content-Type" : "application/json",
        "activity": theme,
    }

    if (testingApiKey) {
        headers["x-ozmo-testing-key"] = testingApiKey
    }

    return _apiCall(
        "replace/send-forgotpassword",
        "PUT",
        headers,
        {
            captchaToken: captchaToken,
            login: email,
        },
    )
}
