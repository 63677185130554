import { bool, func, number } from "prop-types"

import ButtonCta from "../../Buttons/button/button-cta"
import Stepper from "../Stepper"

export default function Step1padel({ step, onNextStep, disableActions }) {
    return (
        <div className={"step1"}>
            <header><img className="" src="/assets/images/padel/logo/logo-green.svg" /></header>
            <div className={"padel-pictures"}>
                <img className="friese-green" src="/assets/images/padel/backgrounds/first-connection-friese-green.svg" />
                <img className="padel-bg" src="/assets/images/padel/backgrounds/first-connection-bg.png" />
            </div>
            <h4 className={"welcome"}>
                <span>
                    BIENVENIDO
                </span>
            </h4>
            <h4 className={"intro"}>{"Chez urban padel"}</h4>
            <ul>
                <li>Réserve une piste</li>
                <li>Invite tes potes</li>
                <li>Participe à un tournoi</li>
                <li>Inscris-toi à un cours</li>
            </ul>
            <div className="change-step">
                <ButtonCta
                    onClick={() => {
                        onNextStep()
                    }}
                    disabled={disableActions}
                    text={
                        (
                            <>
                                <div className={"button-first-connection"}>
                                    <span>{"vamos"}</span>
                                </div>
                            </>
                        )
                    }
                />
            </div>
            <div className={"first-connection-stepper"}>
                <Stepper activeStep={step}/>
            </div>
            <footer><img className="" src={"/assets/images/padel/backgrounds/first-connection-footer.svg"} /></footer>
        </div>
    )
    
}

Step1padel.propTypes = {
    disableActions: bool,
    onNextStep: func,
    step: number,
}

