import { bool, func, number, oneOfType, string } from "prop-types"
import React, { useState } from "react"
import PhoneInput from "react-phone-input-2"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import AuthStep from "../../../components/authentification/authStep/authStep"
import AuthStepper from "../../../components/authentification/authStepper/authStepper"
import { sendConfirmPhone } from "../../../components/authentification/signupForm/api"
import SmsCodeVerif from "../../../components/authentification/smsCodeVerif/smsCodeVerif"
import ButtonCta from "../../../components/Buttons/button/button-cta"
import Preloader from "../../../components/loaders/preloader/preloader"
import { TooltipLight } from "../../../components/tooltip/tooltipLight/tooltipLight"
import useMediaPath from "../../../hooks/useMediaPath"
import useThemeHeader from "../../../hooks/useThemeHeader"
import phoneCountries from "../../../model/enums/phoneCountries"
import { formatTel } from "../../../services/format"
import { goNextAuthStep } from "../../../store/auth/actions/actions"
import { clearAllForms } from "../../../store/forms/actions"

import "react-phone-input-2/lib/style.css"
import "./phoneConfirm.scss"

function PhoneConfirm({ prevAction, initialPhoneNumber, step1Title, userEmail, goToEmailStep,
    step2Title, step3Title, forceChangePhone = false, userId, isUserPhoneNotConfirm }) {
    const mediaPath = useMediaPath()
    const themeHeader = useThemeHeader()
    const userLoaded = useSelector(state => state.userLoaded)

    // const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [ phoneNumber, setPhoneNumber ] = useState(initialPhoneNumber ? initialPhoneNumber : "")

    const currentAuthStepId = useSelector(state => state.auth.currentAuthStepId)

    const [ ajaxLoading, setAjaxLoading ] = useState(false)

    const [ clickOnValidate, setClickOnValidate ] = useState(false)
    const [ isSuccess, setIsSuccess ] = useState(false)
    const [ successOrErrorMsg, setSuccessOrErrorMsg ] = useState(false)

    /* ACTIONS */
    const dispatch = useDispatch()
    const location = useLocation()
    const isLoginPhoneUpdating = location.pathname === "/profil/coordonnees" || location.pathname === "/padel/profil/coordonnees"

    function stepperTitle() {
        switch (currentAuthStepId) {
        case 1:
            return step1Title ? step1Title : "Renseigner un téléphone"
        case 2:
            return step2Title ? step2Title : "Confirme ton téléphone"
        case 3:
            return step3Title ? step3Title : "Création de compte"
        default:
            return "Default step title"
        }
    }

    function confirmTel() {
        setAjaxLoading(true)
        // dispatch(goNextAuthStep())

        function handleRes(res) {
            setAjaxLoading(false)
            setClickOnValidate(true)
            
            if (res.data && res.data.data && res.data.data.Message) {
                setIsSuccess(false)
                setSuccessOrErrorMsg(res.data.data.Message)
            } else if (res.data && res.data.data) {
                setIsSuccess(true)
                setClickOnValidate(false)
                dispatch(goNextAuthStep())
            } else {
                setIsSuccess(false)
            }
        }

        if (userId && phoneNumber) {
            let isPhoneChange = initialPhoneNumber !== phoneNumber
            const phoneToSend = isPhoneChange ? "+" + formatTel(phoneNumber) : "+" + formatTel(initialPhoneNumber)
            sendConfirmPhone(
                userId,
                phoneToSend,
                isUserPhoneNotConfirm ? localStorage.getItem("changeCoordinatesToken") : null,
                themeHeader,
                !!userLoaded?.id,
            ).then(handleRes)
        }
    }

    return (
        <React.Fragment>
            {ajaxLoading && 
                (<Preloader
                    fixed={true}
                />)
            }
            <AuthStepper
                title={stepperTitle()}
                prevAction={() => {
                    dispatch(clearAllForms())
                    prevAction()
                }}
                rootClasses="women-bkg"
                help={<p>Tu rencontres un problème pour te connecter ou créer un compte ? Contacte-nous au 0 806 23 00 24</p>}
            >
                <AuthStep>
                    <div className="introText">Renseigne ton numéro de téléphone mobile.</div>
                    <div className="phoneInput">
                        <PhoneInput
                            country={"fr"}
                            placeholder="Téléphone mobile"
                            inputProps={{
                                required: true,
                            }}
                            onlyCountries={phoneCountries}
                            enableTerritories
                            value={phoneNumber}
                            onChange={
                                (phone, data) => {
                                    setPhoneNumber(phone)
                                    const phoneHasDial = phone.includes(data.dialCode)
                                    if (phoneHasDial) {
                                        setPhoneNumber(phone)
                                    } else {
                                        if (phone.length <= data.countryCode.length) {
                                            setPhoneNumber(phone.replace(/\D|^0+/g, ""))
                                        } else {
                                            setPhoneNumber(data.dialCode + phone.replace(/\D|^0+/g, ""))
                                        }
                                    }
                                }
                            }
                                
                            specialLabel="Téléphone mobile"
                        />
                        <TooltipLight text="Ce numéro de téléphone sera utilisé pour valider ton identité et recevoir des informations transactionnelles par SMS.">
                            <img alt="" src={mediaPath([ "/assets/icons/questionMark.svg", "/assets/images/padel/icons/icons-notifications.svg" ])} className="inputPhoneQuestionMark"/>
                        </TooltipLight>
                    </div>
                    <div className="button-holder-center">
                        <ButtonCta
                            isAlt={true}
                            text="Suivant"
                            disabled={
                                (forceChangePhone && phoneNumber === initialPhoneNumber)
                            }
                            onClick={
                                () => {
                                    confirmTel()
                                }
                            }
                        />
                    </div>

                    {
                        clickOnValidate && 
                            (<div className="errorSuccessLabel">
                                {
                                    isSuccess ? 
                                        <img alt="" className="iconError" src="/assets/icons/notifDarkGreen.svg" />
                                        :
                                        <img alt="" className="iconError" src="/assets/icons/errorNotif.svg" />
                                }
                                <div className={isSuccess ? "isSuccess" : ""}>
                                    {successOrErrorMsg}
                                </div>
                            </div>)
                
                    }
                </AuthStep>

                <AuthStep>
                    <SmsCodeVerif 
                        phoneChange={initialPhoneNumber !== phoneNumber}
                        userId={userId}
                        tel={phoneNumber}
                        isLoginPhoneUpdating={isLoginPhoneUpdating}
                        endAction={prevAction}
                        isUserPhoneNotConfirm={isUserPhoneNotConfirm}
                        resetAfterPhoneConfirm
                        userEmail={userEmail}
                        goToEmailStep={goToEmailStep}
                    />
                </AuthStep>

            </AuthStepper>
        </React.Fragment>
    )

}

PhoneConfirm.formName = "phoneConfirm"
PhoneConfirm.propTypes = {
    forceChangePhone: bool,
    goToEmailStep: func,
    initialPhoneNumber: string, 
    isUserPhoneNotConfirm: bool,
    prevAction: func,
    step1Title: string,
    step2Title: string,
    step3Title: string,
    userEmail: oneOfType([ string, bool ]),
    userId: number,
}

export default PhoneConfirm
