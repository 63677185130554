import { arrayOf, bool, func, node, number, oneOfType, string } from "prop-types"
import { useEffect , useState } from "react"

import "./ActivationTrigger.scss"

function ActivationTrigger({
    id,
    target,
    cls,
    callback,
    children,
}) {
    const [ active, setActive ] = useState(false)
    
    if (target === undefined || typeof target !== "string") {
        // eslint-disable-next-line no-console
        console.error("ActivationTrigger must have a target prop of string type")
        // eslint-disable-next-line no-debugger
        debugger
    } else if (target[0] !== "#") {
        // eslint-disable-next-line no-console
        console.error("ActivationTrigger target must be an identifier css selector")
        // eslint-disable-next-line no-debugger
        debugger
    }

    useEffect(
        () => {
            handleClickOutsideTooltip()
        }, [],
    )

    function handleClickOutsideTooltip() {
        document.addEventListener(
            "click",
            (evt) => {
                const tooltipHelp = document.getElementById(id)
                const buttonTooltipHelp = document.getElementById("buttonTooltip-" + id)
                if (buttonTooltipHelp && buttonTooltipHelp.classList.contains("active")) {
                    let targetElement = evt.target // clicked element
                    do {
                        if (targetElement === tooltipHelp || targetElement === buttonTooltipHelp) {
                            // This is a click inside. Do nothing, just return.
                            return
                        }
                        // Go up the DOM
                        targetElement = targetElement.parentNode
                    } while (targetElement)
                    // This is a click outside.
                    buttonTooltipHelp.click()
                }
            },
        )
    }

    function listenerCleanTooltip(evt) {
        let targetNode = document.querySelector(target)
        let clickedElement = evt.target

        if (target !== null) {
            if (
                targetNode && clickedElement && clickedElement.parentNode && clickedElement.parentNode.parentNode && clickedElement.id !== targetNode.id
                && clickedElement.parentNode.id !== targetNode.id
                && clickedElement.parentNode.parentNode.id !== targetNode.id
            ) {
                targetNode.classList.remove("active")
                document.removeEventListener("click", listenerCleanTooltip)
                setActive(false)
                callback?.(false)
            }
        }
    }

    function toggleTargetVisibility(event) {
        event.stopPropagation()
        event.preventDefault()
 
        const toCloseList = document.querySelectorAll(".tooltip.active")
        toCloseList?.forEach(elem => {
            if (!document.querySelector(target).contains(elem)) {elem?.classList.remove("active")}
        })
        let targetNode = document.querySelector(target)
        if (targetNode) {
            if (targetNode.classList.contains("tooltip")) {
                let oldTooltip = document.querySelector(".tooltip.active")
                if (oldTooltip !== null && oldTooltip.id === target.substr(1)) {
                    setActive(false)
                    callback?.(false)
                    return oldTooltip.classList.remove("active")
                }
            }
        }

        targetNode.classList.add("active")
        let thatElement = document.querySelector("#" + id)
        let arrow = targetNode.querySelector(".arrow")
        if (thatElement.classList.contains("trackarrow")) {
            arrow.setAttribute("style", "left: " + thatElement.offsetLeft + "px;")
        }

        document.addEventListener(
            "click",
            listenerCleanTooltip,
        )
        if (active === false) {
            callback?.(true)
            setActive(true)
        }
    }

    return (
        <button
            id={id}
            className={"activationTrigger" + (active ? " active" : "") + (cls ? " " + cls : "")}
            onClick={toggleTargetVisibility}
        >
            { children }
        </button>
    )
}

ActivationTrigger.propTypes = {
    callback: oneOfType([ bool, func ]),
    children: oneOfType([ string, node, arrayOf(node) ]),
    cls: string,
    id: oneOfType([ number, string ]),
    target: string,
}

export default ActivationTrigger
