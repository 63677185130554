import classNames from "classnames"
import { array, func, object } from "prop-types"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import CheckBlock from "../../../../../components/Forms/CheckBlock/CheckBlock"
import Modal from "../../../../../components/Modal/Modal"

import "./CupFilterPopin.scss"

const CupFilterPopin = forwardRef(({ currentFilter, setCurrentFilter, centersOptions }, ref) => {
    const [ selectedCenter, setSelectedCenter ] = useState(null)
    const [ selectedGender, setSelectedGender ] = useState(null)
    const [ selectedType, setSelectedType ] = useState(null)
    const [ filterChanged, setFilterChanged ] = useState(false)
    const [ internalModalState, setInternalModalState ] = useState(false)

    useImperativeHandle(ref, () => ({
        hide: () => {
            setInternalModalState(false)
        },
        show: () => {
            setInternalModalState(true)
        },
    }))

    const genderOption = [
        { label: "Homme", value: "1" },
        { label: "Femme", value: "2" },
        { label: "Mixte", value: "3" },
    ]

    const typeOption = [
        { label: "Homologué", value: "2" },
        { label: "Loisir", value: "1" },
    ]

    useEffect(() => {
        if (selectedCenter !== currentFilter?.selectedCenter
            || selectedGender !== currentFilter?.selectedGender
            || selectedType !== currentFilter?.selectedType
        ) {return setFilterChanged(true)}

        setFilterChanged(false)
    }, [ selectedCenter, selectedGender, selectedType ])

    useEffect(() => {
        setSelectedCenter(currentFilter?.selectedCenter ?? null)
        setSelectedGender(currentFilter?.selectedGender ?? null)
        setSelectedType(currentFilter?.selectedType ?? null)
        setFilterChanged(false)
    }, [ currentFilter ])

    return (
        <Modal
            className="cupFilterPopin searchFilterPopin"
            isOpen={internalModalState}
            onClose={() => setInternalModalState(false)}
        >
            <header>TES OPTIONS</header>
            <section>
                { centersOptions?.length > 0 && (
                    <>
                        <h2>Centre</h2>
                        <div className={classNames("filterWrapper", {
                            [`gridColumn-${centersOptions.length}`]: centersOptions?.length,
                        })}>
                            { centersOptions.map(
                                center => (
                                    <CheckBlock
                                        checked={selectedCenter === center.key}
                                        value={center.key}
                                        key={center.key + center.value}
                                        callback={() => setSelectedCenter(prev => prev === center.key ? null : center.key)}
                                        unselect
                                    >
                                        <div className="ellipsis">{center.value}</div>
                                    </CheckBlock>
                                ),
                            )}
                        </div>
                    </>
                )}
                <h2>Genre</h2>
                <div className={classNames("filterWrapper", {
                    [`gridColumn-${genderOption?.length}`]: genderOption?.length,
                })}>
                    { genderOption.map(
                        gender => (
                            <CheckBlock
                                checked={selectedGender === gender.value}
                                value={gender.value}
                                key={gender.label + gender.value}
                                callback={() => setSelectedGender(prev => prev === gender.value ? null : gender.value)}
                                unselect
                            >
                                <div className="ellipsis">{gender.label}</div>
                            </CheckBlock>
                        ),
                    )}
                </div>
                <h2>Type</h2>
                <div className={classNames("filterWrapper", {
                    [`gridColumn-${typeOption?.length}`]: typeOption?.length,
                })}>
                    { typeOption.map(
                        type => (
                            <CheckBlock
                                checked={selectedType === type.value}
                                value={type.value}
                                key={type.label + type.value}
                                callback={() => setSelectedType(prev => prev === type.value ? null : type.value)}
                                unselect
                            >
                                <div className="ellipsis">{type.label}</div>
                            </CheckBlock>
                        ),
                    )}
                </div>
                <ButtonCta
                    disabled={!filterChanged}
                    text="Appliquer"
                    isAlt
                    onClick={
                        () => {
                            if (filterChanged) {setCurrentFilter({
                                selectedCenter: selectedCenter,
                                selectedGender: selectedGender,
                                selectedType: selectedType,
                            })}
                            ref?.current?.hide()
                        }
                    }
                />
            </section>
        </Modal>
    )
})

CupFilterPopin.displayName = "CupFilterPopin"

CupFilterPopin.propTypes = {
    centersOptions: array,
    currentFilter: object,
    setCurrentFilter: func,
}

export default CupFilterPopin
