import BirthdayReservation from "../../pages/kids/birthdayReservation/BirthdayReservation"
import ListingBirthday from "../../pages/kids/birthdayReservation/listingBithdays"
import EcoleDeFoot from "../../pages/kids/ecoleDeFoot/EcoleDeFoot"
import ListingSchool from "../../pages/kids/ecoleDeFoot/listingSchool"
import ListingStage from "../../pages/kids/stageDeFoot/listingStage"
import StageDeFootTunnel from "../../pages/kids/stageDeFoot/StageDeFootTunnel"

const RoutesKids = [
    {
        element: (<ListingSchool />),
        path:"/kids/ecoleDeFoot",
    },
    {
        element: (<ListingSchool />),
        path:"/kids/ecoleDeFoot/*",
    },
    {
        children: [
            ...EcoleDeFoot.stepsArray.map(step => {
                return {
                    element: (<EcoleDeFoot />),
                    path: step,
                }
            }),
            {
                element: (<EcoleDeFoot />),
                path: ":id",
            },
        ],
        element: (<EcoleDeFoot />),
        path: "/kids/ecoleDeFoot/new",
    },
    {
        element: (<ListingBirthday/>),
        path: "/kids/anniversaire",
    },
    {
        element: (<ListingBirthday/>),
        path: "/kids/anniversaire/listing",
    },
    {
        children: [
            {
                element: (<BirthdayReservation />),
                path: ":id",
            },
            {
                element: (<BirthdayReservation />),
                path: ":initialCenterId/:initialTypeId/:daywp/:monthwp/:yearwp",
            },
        ],
        element: (<BirthdayReservation />),
        path: "/kids/anniversaire/new",
    },
    {
        element: (<BirthdayReservation />),
        path: "/kids/anniversaire/new/:initialCenterId/:initialTypeId/:daywp/:monthwp/:yearwp",
    },
    {
        element: (<ListingStage />),
        path: "/kids/stagesDeFoot",
    },
    {
        element: (<ListingStage />),
        path: "/kids/stagesDeFoot/listing",
    },
    {
        children: [
            {
                element: (<StageDeFootTunnel />),
                path: ":id",
            },
        ],
        element: (<StageDeFootTunnel />),
        path: "/kids/stagesDeFoot/new",
    },
]

export default RoutesKids
