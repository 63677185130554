import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock-upgrade"
import classNames from "classnames"
import PropTypes from "prop-types"

import Modal from "../../Modal/Modal.jsx"

import "./LeagueModalBase.scss"

export const LeagueModalBase = ({ visible, onClose, children, className, title, icon, afterOpen, identifier, beforeClose }) => {
    const isTitleString = typeof title === "string"

    const handleBodyScroll = (visible) => {
        const el = document.querySelectorAll(".modal .modal-content .content")[0]
        if (visible) {
            disableBodyScroll(el)
        } else {
            enableBodyScroll(el)
        }
    }

    return (
        <Modal
            className={classNames("league-modal-base league-layout", className)}
            isOpen={visible}
            onClose={() => {
                handleBodyScroll(false)
                onClose()
            }}
            identifier={identifier}
            beforeClose={
                () => {
                    handleBodyScroll(false)
                    beforeClose && beforeClose()
                }
            }
            afterOpen={() => {
                handleBodyScroll(true)
                afterOpen && afterOpen()
            }}
        >
            <div className={"wrapper"}>
                {(icon || title) && (
                    <div className={"heading"}>
                        {icon && (
                            <div className={"heading-icon"}>
                                <img src={icon} alt={isTitleString ? title.toLowerCase() : "icon"}/>
                            </div>
                        )}
                        {title && (
                            <h2>{title}</h2>
                        )}
                    </div>
                )}
                <div className={"content"}>
                    {children}
                </div>
            </div>
        </Modal>
    )
}

LeagueModalBase.propTypes = {
    afterOpen: PropTypes.func,
    beforeClose: PropTypes.func,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    icon: PropTypes.string,
    identifier: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    visible: PropTypes.bool.isRequired,
}
