/* # Connecteurs endpoints API League */
import { _apiCall } from "../../../backend/axios"
import { externalApiCall } from "../../../backend/helpers"

/*
    Permet de rechercher les villes par code postaux
    Avantage: Utilise une API gouvernementale
    Inconvénient: Si je recherche '632', ca va rien me ressortir,
    on est obligé de renseigner à la main les codes postaux complet tel que 63200, 63210, etc
*/
   
export function searchCitiesByCodePostalGouv(codePostal) {
    return externalApiCall(
        "https://geo.api.gouv.fr/communes?codePostal=" + codePostal + "&fields=nom,codesPostaux&format=json&geometry=centre",
        "GET",
    )
}

export function searchCitiesByCityNameGouv(cityName) {
    return externalApiCall(
        "https://geo.api.gouv.fr/communes?nom=" + cityName + "&fields=nom,codesPostaux&format=json&geometry=centre",
        "GET",
    ).then(
        (res) => {
            const severalCPArr = []
            res.data.forEach(
                (r, idx) => {
                    const severalCP = r.codesPostaux.length > 1
                    if (severalCP) {
                        r.codesPostaux.forEach(
                            (cp, idx2) => {
                                if (idx2 > 0) {
                                    severalCPArr.push({
                                        cp: cp,
                                        idx: idx,
                                        nom: r.nom,
                                    })
                                }
                            },
                        )
                    }
                },
            )
            severalCPArr.forEach(
                (scp) => {
                    res.data.splice(
                        scp.idx,
                        0,
                        { codesPostaux: [ scp.cp ], nom: scp.nom },
                    )
                },
            )
            return res
        },
    )
}

/*
    Permet de ressortir toutes les villes d'un département
    Avantage: Utilise une API gouvernementale, à partir d'un code departement retourne toutes les villes
    Par exemple si je recherche 63, ca va me ressortir toutes les communes du dépatement 63
    API ultra légère au vu de toutes les villes qu'elle renvoie
    Inconvénient: Certaines villes ont un code postal qui ne correspond pas au département,
    par exemple la ville de Laveyrune son département est le 07, et son code postal est 48250
    https://fr.wikipedia.org/wiki/Liste_des_communes_de_France_dont_le_code_postal_ne_correspond_pas_au_d%C3%A9partement
*/
export function searchCitiesByDepartmentGouv(dep) {
    return externalApiCall(
        "https://geo.api.gouv.fr/departements/" + dep + "/communes?fields=nom,codesPostaux&format=json&geometry=centre",
        "GET",
    ).then((res) => {
        const severalCPArr = []
        res.data.forEach((r, idx) => {
            const severalCP = r.codesPostaux.length > 1
            if (severalCP) {
                r.codesPostaux.forEach((cp, idx2) => {
                    if (idx2 > 0) {
                        severalCPArr.push({ cp: cp, idx: idx, nom: r.nom })
                    }
                })
            }
        })
        severalCPArr.forEach((scp) => {
            res.data.splice(scp.idx, 0, { codesPostaux: [ scp.cp ], nom: scp.nom })
        })
        return res
    })
}

export function signUp(data, theme) {
    const testingApiKey = import.meta.env.VITE_TESTING_API_KEY

    const headers = {
        "Content-Type" : "application/json",
        activity: theme,
    }

    if (testingApiKey) {
        headers["x-ozmo-testing-key"] = testingApiKey
    }

    return  _apiCall(
        "create/register",
        "POST",
        headers,
        data,
    ).then(
        (res) => {
            return res
        },
    ).catch((err) => {
        return err
    })
}

export function sendConfirmPhone(id, value, changeCoordinatesToken, theme, authenticated = false) {
    let data = {
        value: value,
    }

    if (changeCoordinatesToken) {
        data.changeCoordinatesToken = changeCoordinatesToken
    }

    return  _apiCall(
        "check/" + id + "/send-confirm-phone",
        "PUT",
        {   
            "Content-Type" : "application/json",
            activity: theme,
        },
        data,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        !authenticated,
    ).then(
        (res) => {
            return res
        },
    ).catch((err) => {
        return err
    })
}
