import { useNavigate } from "react-router-dom"

import { getCookie } from "../services/cookies"

function useAppRedirect() {
    const isWebview = !!getCookie("webview")
    const navigate = useNavigate()
    return url => {
        if (isWebview) {return window.location.href = url}
        else {return navigate(url)}
    }
}

export default useAppRedirect
