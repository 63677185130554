import dayjs from "dayjs"
import { object } from "prop-types"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { deleteSuperSubRequest } from "./api"
import { delayNumber } from "./functions"
import Preloader from "../../../entries/main/../../components/loaders/preloader/preloader"
import { getLocation } from "../../../globalAPI/api"
import useMediaPath from "../../../hooks/useMediaPath"
import { useLocationStore } from "../../../pages/reservation/store/store"
import { modifySupersubRequest } from "../../../pages/supersub/searchSuperSub/api"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import usePath from "../../../routes/services/usePath"
import ButtonCta from "../../Buttons/button/button-cta"
import Modal from "../../Modal/Modal"
import { completeSuperSubRequest } from "../SelectSuperSub/api"

import "./SuperSubLocationRecap.scss"

function SuperSubLocationRecap({ location }) {
    const path = usePath()

    const [
        reservation,
        // setReservation
    ] = useState(location)
    const { currentLocation, setCurrentLocation } = useLocationStore()
    const [ ajaxLoading, setAjaxLoading ] = useState(false)

    const maxSupersubCount = 3

    const {
        id,
        isSupersubRequestAvailable,
        start,
        type,
        isOwner,
        supersubRequests,
        supersubAskedPlayers,
        supersubConfirmedPlayers,
        supersubRemainingPlayers,
    } = reservation

    const msPerDay = 24 * 60 * 60 * 1000
    const diffDaysNowTilStart = (new Date(start) - new Date()) / msPerDay
    const startDay = dayjs(dayjs(start).format("YYYY-MM-DD"))
    const today = dayjs(dayjs(new Date()).format("YYYY-MM-DD"))
    const mediaPath = useMediaPath()

    const daysTillStart = startDay.diff(today,"hour") / 24
    const pluralDayWording = delayNumber(diffDaysNowTilStart, daysTillStart) > 1 ? "s" : ""
    const delayString = delayNumber(diffDaysNowTilStart, daysTillStart) + (Math.trunc(diffDaysNowTilStart) === 0 ? "" : (" jour" + pluralDayWording))

    const navigate = useNavigate()

    function isTypeAllowed(type) {
        return [ 0, 1, 13, 2, 3, 4, 8 ].indexOf(type) !== -1
    }

    const noSearchYet = supersubAskedPlayers <= 0

    const goToCreateSuperSub = () => navigate(path("/reservations/locations/" + id + "/searchsupersub"))

    function modifySuperSub() {
        goToCreateSuperSub()
    }

    const showModal = () => ModalHandler.show(SuperSubLocationRecapModal, {
        callback: cancelConfirm,
    })
    /* istanbul ignore next */
    const cancelConfirm = () => {
        setAjaxLoading(true)
        const lastRequest = supersubRequests[supersubRequests.length - 1]
        if (lastRequest?.confirmedPlayers === 0) {
            deleteSuperSubRequest(lastRequest.id).then(res => {
                const data = res.data.data
                setCurrentLocation({ ...currentLocation, ...data.reservation, supersubRequests: [ ...currentLocation.supersubRequests, data ] })
            })
        } else if (lastRequest && lastRequest.confirmedPlayers > 0) {
            modifySupersubRequest(
                lastRequest.id,
                lastRequest.confirmedPlayers,
                lastRequest.level,
                lastRequest.description,
                lastRequest.isFree,
            ).then(
                (res) => {
                    const data = res?.data?.data
                    if (data.askedPlayers === data.confirmedPlayers) {
                        completeSuperSubRequest(data.id).then(res => {
                            const request = res.data.data
                            setCurrentLocation({ ...currentLocation, ...request.reservation, supersubRequests: [ ...currentLocation.supersubRequests, request ] })
                        })
                    }
                },
            )
        }

        getLocation(id).then(
            (res) => {
                setAjaxLoading(false)
                navigate(path("/confirmcommand"), {
                    state: {
                        confirmedSubscription: res,
                        isCanceled: true,
                        message: "closeSupersubRequest",
                        subtitle: "Nous te confirmons la fermeture de ta recherche de SuperSub.",
                        success: true,
                        title: "Recherche fermée",
                        tunnelType: "SUPERSUB",
                    },
                })
            },
        )
    }

    const findSuperSubButton = (
        <ButtonCta
            isAlt
            text="Trouver un supersub"
            onClick={goToCreateSuperSub}
            icon={mediaPath([ "/assets/images/supersub--orange.svg", "/assets/images/padel/icons/icon-ultimo.svg" ])}
            hoverChangeIcon={mediaPath([ "/assets/images/supersub--white.svg", "/assets/images/padel/icons/icon-ultimo.svg" ])}
            className="findSupersubBtn"
        />
    )

    const spellingS = supersubConfirmedPlayers > 1 ? "s" : ""
    const sSpelling = (maxSupersubCount - supersubAskedPlayers) > 1 ? "s" : ""
    const filledSubSearchs = (
        <div className="o-supersub--full">
            <img src={mediaPath([ "/assets/images/supersub--orange.svg", "/assets/images/padel/icons/icon-ultimo.svg" ])}/>
            <div className="o-supersub__content">
                <div className="o-supersub__content__title">
                    {supersubConfirmedPlayers} supersub{spellingS} confirmé{spellingS}
                </div>
                {isSupersubRequestAvailable && supersubRemainingPlayers === 0 && (
                    <div className="o-supersub__content__text">
                        {supersubAskedPlayers === maxSupersubCount ?
                            "Ton équipe est à présent complète"
                            :
                            "Tu peux encore chercher jusqu'à " + (maxSupersubCount - supersubAskedPlayers) + " supersub" + sSpelling + "."
                        }
                    </div>
                )}
            </div>
        </div>
    )

    const editableSubSearch = (
        <div className="o-supersub--editable">
            <div className="o-supersub__content">
                <img src={mediaPath([ "/assets/images/supersub--orange.svg", "/assets/images/padel/icons/icon-ultimo.svg" ])}/>
                <div>
                    <div className="o-supersub__content__title">
                        Recherche de {supersubRequests.length !== 0 && supersubRequests[supersubRequests.length - 1].askedPlayers} supersub{supersubRequests.length !== 0 && supersubRequests[supersubRequests.length - 1].askedPlayers > 1 ? "s" : ""} en cours
                    </div>
                    <div className="o-supersub__content__text">
                        Tu disposes d'un délai de {
                            delayString
                        }&nbsp;pour trouver un SuperSub
                    </div>
                </div>
            </div>
            {(
                isSupersubRequestAvailable
                && supersubRequests.length !== 0
            ) && (
                <div className="o-supersub__edition">
                    { supersubRequests[supersubRequests.length - 1].status === 0 && (
                        <div
                            className={
                                "o-supersub__edition__cta"
                            }
                            data-testid="modifySub"
                            onClick={modifySuperSub}
                        >
                            <img src="/assets/icons/pencil--white.svg"/>
                            <span>Modifier</span>
                        </div>
                    )}
                    {
                        <div
                            className="o-supersub__edition__cta"
                            onClick={showModal}
                            data-testid="cancelSub"
                        >
                            <img src="/assets/icons/cross-white.svg" className="rotate45"/>
                            <span>Fermer</span>
                        </div>
                    }
                </div>
            )}
        </div>
    )

    return (
        <React.Fragment>
            {(
                isOwner && (
                    supersubAskedPlayers > 0
                    || (
                        isSupersubRequestAvailable
                            && isTypeAllowed(type)
                            && diffDaysNowTilStart <= 5
                    )
                )
            ) &&
                (
                    <section
                        className={
                            "o-section--supersub" +
                            (noSearchYet ?
                                ""
                                :
                                " u-space-top-border"
                            )
                        }
                    >
                        { ajaxLoading &&
                            /* istanbul ignore next */
                            (<Preloader
                                fixed
                            />)
                        }
                        {(location.supersubRequests && location.supersubRequests.length > 0 && location.supersubRequests[location.supersubRequests.length - 1].status === 0) && (
                            <ButtonCta
                                className="supersubSelection"
                                isAlt
                                icon={mediaPath([ "/assets/images/supersub--orange.svg", "/assets/images/padel/icons/icon-ultimo.svg" ])}
                                hoverChangeIcon="/assets/images/supersub--white.svg"
                                text="Choisir un supersub"
                                onClick={
                                    () => {
                                        navigate(path("/reservations/locations/" + id + "/candidaturesupersub"))
                                    }
                                }
                            />
                        )}
                        {supersubConfirmedPlayers > 0 &&
                            filledSubSearchs
                        }
                        {(
                            supersubAskedPlayers > supersubConfirmedPlayers
                        ) &&
                            editableSubSearch
                        }
                        {(
                            isSupersubRequestAvailable &&
                            parseInt(supersubRemainingPlayers) === 0 &&
                            supersubAskedPlayers < maxSupersubCount &&
                            isTypeAllowed(location.resourceType)
                        ) && (
                            findSuperSubButton
                        )}
                    </section>
                )
            }
        </React.Fragment>
    )
}
/* istanbul ignore next */
const SuperSubLocationRecapModal = ModalHandler.create(({ callback, visible, hide }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
        >
            <header>Fermer la demande en cours</header>
            <div className="confirm-cancel">
                <div className="o-superSub__content__text">
                    Les SuperSubs déjà validés seront conservés.
                </div>
                <div className="confirm-buttons">
                    <ButtonCta
                        className="supersubClose"
                        text="Oui"
                        isCta2Alt={true}
                        onClick={() => {
                            callback()
                            hide()
                        }}
                    />
                    <ButtonCta
                        className="supersubClose"
                        text="Non"
                        isCta2Alt={true}
                        onClick={hide}
                    />
                </div>
            </div>
        </Modal>
    )
})

SuperSubLocationRecap.propTypes = {
    location: object,
}

export default SuperSubLocationRecap
