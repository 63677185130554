import { createUserPlayerProfile, modifyContactPlayerProfile, modifyUserPlayerProfile } from "../../../../globalAPI/api"
import { updateUser } from "../../../../store/actions/scaffold"

export function modifyLicenses(user, partner, licence1, licence2, ref, dispatch) {
    const api = user.playerProfile?.playerProfileId ? modifyUserPlayerProfile : createUserPlayerProfile
    const profile = {
        level: user.playerProfile?.level ? user.playerProfile?.level : "",
        licenseNumber: licence1,
        positioning: user.playerProfile?.positioning === 0 || user.playerProfile?.positioning ? user.playerProfile?.positioning : "",
        ranking: user.playerProfile?.ranking ? user.playerProfile?.ranking : "",
    }
    if (licence1 !== user?.playerProfile?.licenseNumber || !user.playerProfile) {
        api(user.id, profile).then(res => dispatch(updateUser({ ...user, playerProfile: res })))
    }
    if (partner?.licenseNumber !== licence2) {
        modifyContactPlayerProfile(partner.contactId, {
            level: partner.positioning ?? "",
            licenseNumber: partner.licenseNumber ?? "",
            positioning: partner.positioning ?? 2,
            ranking: partner.positioning ?? "",
        })
    }
    ref?.current?.hide()
}

export function handlePartnerModify(partner, setLicence2, setCanModifyPartner) {
    if (partner?.contactId) {
        setLicence2(partner?.licenseNumber)
        modifyContactPlayerProfile(partner.contactId, {
            level: partner.positioning ?? "",
            licenseNumber: partner.licenseNumber ?? "",
            positioning: partner.positioning ?? "",
            ranking: partner.positioning ?? "",
        }).then(res => {
            if (res?.playerProfile?.contactId) {
                setCanModifyPartner(true)
            }
        })
    }
}
