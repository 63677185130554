import PropTypes from "prop-types"

export const VisualPlayerPicture = ({
    width,
    forcedHeight,
    picture,
    zIndex,
    coordinates: { top, left , bottom, right },
    mirror,
}) => {
    const centered = (left === 0 && right === 0) || (top === 0 && bottom === 0)

    return (
        <div
            className={"visualPlayerPicture"}
            style={{
                bottom: centered ? "unset" : bottom,
                height: forcedHeight,
                left: centered ? "50%" : left,
                maxWidth: width,
                position: "absolute",
                right: centered ? "unset" : right,
                top: centered ? "50%" : top,
                transform: `${mirror ? "scaleX(-1)" : ""} ${centered ? "translate(-50%, -50%)" : ""}`.trim(),
                width: "100%",
                zIndex: zIndex,
            }}
        >
            <img src={picture} alt="player" style={{
                width: "100%",
            }} />
        </div>
    )
}

VisualPlayerPicture.propTypes = {
    coordinates: PropTypes.shape({
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
        top: PropTypes.number,
    }),
    forcedHeight: PropTypes.number,
    mirror: PropTypes.bool,
    picture: PropTypes.string,
    width: PropTypes.number,
    zIndex: PropTypes.number,
}
