import { add, parse } from "date-fns"
import ProgressBar from "progressbar.js"
import { bool, number, string } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import CreneauxDispo from "./CreneauxDispo"
import FormValidator from "../../../../components/Forms/FormValidator"
import TextArea from "../../../../components/Forms/textArea/TextArea"
import { TextInput } from "../../../../components/Inputs/TextInput/TextInput.jsx"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import Step from "../../../../components/stepsManagement/Step"
import { editSchoolSubscription } from "../../../../globalAPI/api"
import useUrbanLanguage from "../../../../hooks/urbanLanguage/useUrbanLanguage"
import useAddSearchParam from "../../../../hooks/useAddSearchParams"
import useMediaPath from "../../../../hooks/useMediaPath"
import useThemeFeatures from "../../../../hooks/useThemeFeatures"
import genderValues from "../../../../model/enums/genderValues"
import resetScroll from "../../../../services/resetScroll"
import { setFormEntry } from "../../../../store/forms/actions"
import { skipStep } from "../../../../store/stepsManagement/actions"
import { addStageMedicalCertificate, editStageSubscription, removeStageMedicalCertificate } from "../../stageDeFoot/api"
import MesEnfants from "../../steps/MesEnfants"
import { addSchoolMedicalCertificate, editSchoolSecondSubscription, removeSchoolMedicalCertificate } from "../api"

import "./EmergencyContact.scss"

export default function EmergencyContact(props) {
    const { tunnelType, numero, isAdult, skipPrestaSup } = props

    const forms = useSelector(state => state.forms)
    const firstSubscription = forms?.ozmoSubscription?.firstSubscription

    const [ contact1Firstname, setContact1Firstname ] = useState("")
    const [ contact1Lastname, setContact1Lastname ] = useState("")
    const [ contact1Phone, setContact1Phone ] = useState("")
    const [ contact1Email, setContact1Email ] = useState("")
    const [ contact2Firstname, setContact2Firstname ] = useState("")
    const [ contact2Lastname, setContact2Lastname ] = useState("")
    const [ contact2Phone, setContact2Phone ] = useState("")
    const [ contact2Email, setContact2Email ] = useState("")
    const [ uploadCertif, setUploadCertif ] = useState(undefined)
    const [ isUploading, setIsUploading ] = useState(false)
    const [ buttonUploadText, setButtonUploadText ] = useState("Choisissez un fichier")
    const [ isValidContact1Form, setIsValidContact1Form ] = useState(false)
    const [ isValidContact2Form, setIsValidContact2Form ] = useState(false)
    const [ isAuthoriseLeaveAlone, setIsAuthoriseLeaveAlone ] = useState(!!firstSubscription.childAllowedToLeaveAlone)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ haveCheckedStatus, setHaveCheckedStatus ] = useState(false)
    const [ bar, setBar ] = useState(null)

    const informationsRef = useRef()
    const inputUpload = useRef()
    const step = useRef()
    const progressBarRef = useRef()
    const firstCheckbox = useRef()
    const secondCheckbox = useRef()

    const userLoaded = useSelector(state => state.userLoaded)
    const checkSkipStepEDF = useSelector(state => state.checkSkipStepEDF)
    const stepManagement = useSelector(state => state.stepManagement)

    const dispatch = useDispatch()
    const themeFeatures = useThemeFeatures()
    const media = useMediaPath()
    const addSearchParams = useAddSearchParam()
    const { tu } = useUrbanLanguage()

    const medicalCertif = (firstSubscription?.attachments?.length) ?
        firstSubscription.attachments[0].fileName
        :
        undefined
    
    let child = firstSubscription ? firstSubscription.kid : {}

    let childName = child?.firstName ?? "enfant"

    const firstDate = new Date([
        forms?.ozmoSubscription?.firstSubscription?.school?.start ?? null,
        forms?.ozmoSubscription?.firstSubscription?.training?.start ?? null,
        forms?.ozmoSubscription?.secondSubscription?.school?.start ?? null,
        forms?.ozmoSubscription?.secondSubscription?.training?.start ?? null,
    ].filter(v => !!v).sort()?.[0] ?? "now")

    const kidhasMoreThan6 = firstSubscription?.kid?.birthDate
        && (
            add(parse(firstSubscription?.kid?.birthDate, "dd/MM/yyyy", new Date()), { years: 6 })
            <= firstDate
        )

    const help = <PhoneCallHelp tunnelType={tunnelType}/>

    const informations = firstSubscription?.informations

    const setFormInStore = (name, form) => dispatch(setFormEntry(name, form))

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/
        return re.test(email)
    }

    const isContact2PhoneValid = () => {
        const phonePattern = /^[0-9+]+$/

        return phonePattern.test(contact2Phone) && contact2Phone.length >= 10 && contact2Phone.length <= 16
    }

    const validateContact2Form = () => {
        // if none of fields are filled, form is valid
        if (!contact2Firstname && !contact2Lastname && !contact2Phone && !contact2Email) {
            return true
        }

        return !!(contact2Firstname && contact2Lastname && isContact2PhoneValid() && contact2Email && validateEmail(contact2Email))
    }

    const handleOptionChange = (changeEvent) => {
        const val = (changeEvent.target.value === "true")
        setUploadCertif(val)
        setHaveCheckedStatus(true)
    }

    const onFileUpload = (event) => {
        const subscription = forms.ozmoSubscription.firstSubscription
        const secondSubscription = forms.ozmoSubscription.secondSubscription
        const file = event.target.files[0]
        const fileName = file.name
        setIsUploading(true)

        setButtonUploadText(fileName)

        const handleUploadResult = (res) => {
            setIsUploading(false)
            setFormInStore("ozmoSubscription", {
                ...forms["ozmoSubscription"],
                firstSubscription: res,
            })
        }

        switch (tunnelType) {
        case "SCHOOL":
            if (secondSubscription) {
                addSchoolMedicalCertificate(subscription.id, file, bar).then(
                    (res) => {
                        setFormInStore("ozmoSubscription", {
                            ...forms["ozmoSubscription"],
                            firstSubscription: res,
                        })
                        addSchoolMedicalCertificate(secondSubscription.id, file, bar).then(
                            (res2) => {
                                setIsUploading(false)
                                setFormInStore("ozmoSubscription", {
                                    ...forms["ozmoSubscription"],
                                    secondSubscription: res2.data.subscription,
                                })
                            },
                        )
                    },
                )
            } else {
                addSchoolMedicalCertificate(subscription.id, file, bar).then(handleUploadResult)
            }
            break

        case "TRAINING":
            addStageMedicalCertificate(subscription.id, file, bar).then(handleUploadResult)
            break
        }

    }
    
    const deleteAttachment = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const firstSubscription = forms.ozmoSubscription.firstSubscription
        const secondSubscription = forms.ozmoSubscription.secondSubscription
        inputUpload.current.value = ""
        setButtonUploadText("Choisissez un fichier")

        let removeMedicalCertificate
        switch (tunnelType) {
        case "SCHOOL":
            removeMedicalCertificate = removeSchoolMedicalCertificate
            break
        case "TRAINING":
            removeMedicalCertificate = removeStageMedicalCertificate
            break
        }
        if (firstSubscription.attachments.length > 0) {
            if (secondSubscription) {
                removeMedicalCertificate(firstSubscription.id, firstSubscription.attachments[0].id).then(
                    (res) => {
                        setFormInStore("ozmoSubscription", {
                            ...forms["ozmoSubscription"],
                            firstSubscription: res,
                        })
                        removeMedicalCertificate(firstSubscription.id, firstSubscription.attachments[0].id).then(
                            (res2) => {
                                setFormInStore("ozmoSubscription", {
                                    ...forms["ozmoSubscription"],
                                    secondSubscription: res2,
                                })
                                bar?.set?.(0)
                            },
                        )
                    },
                )
            } else {
                removeMedicalCertificate(firstSubscription.id, firstSubscription.attachments[0].id).then(
                    (res) => {
                        setFormInStore("ozmoSubscription", {
                            ...forms["ozmoSubscription"],
                            firstSubscription: res,
                        })
                        bar?.set?.(0)
                    },
                )
            }
        } else {
            bar?.set?.(0)
        }
    }

    const updateOzmoRegistration = () => {
        return new Promise(
            (resolve) => {
                const firstSubscription = forms.ozmoSubscription.firstSubscription
                const secondSubscription = forms.ozmoSubscription.secondSubscription
                
                let slot
                let slotIdBySub = (sub) => {
                    switch (tunnelType) {
                    case "SCHOOL":
                        return sub.school.id
                    case "TRAINING":
                        return sub.training.id
                    }
                }
                if (forms[CreneauxDispo.formName]) {
                    forms[CreneauxDispo.formName].selectedTimeslot ? 
                        slot = forms[CreneauxDispo.formName].selectedTimeslot.value 
                        :
                        slot = slotIdBySub(firstSubscription)
                } else {
                    slot = slotIdBySub(firstSubscription)
                }

                const selectedKid = firstSubscription.kid

                setAjaxLoading(true)

                let data1 = {
                    child: selectedKid.id,
                    childAllowedToLeaveAlone: isAuthoriseLeaveAlone,
                    childBirthDate: selectedKid.birthDate.split("T")[0].split("-").reverse().join("/"),
                    childFirstname: selectedKid.firstName,
                    childGender: genderValues[selectedKid.gender],
                    childLastname: selectedKid.lastName,
                    childOutfitSize: firstSubscription.outfitSizeValue ?? undefined,
                    contact1Email: contact1Email,
                    contact1Firstname: contact1Firstname,
                    contact1Lastname: contact1Lastname,
                    contact1Phone: contact1Phone.replace("+33","0").replace(/ /g, ""),
                    healthSurveyStatus: uploadCertif ? -1 : 1,
                    subscriptionId: firstSubscription.id,
                }

                if (informationsRef.current) {
                    data1.informations = informationsRef.current.value
                }

                if (contact2Firstname !== undefined) {
                    data1.contact2Firstname = contact2Firstname
                }

                if (contact2Lastname !== undefined) {
                    data1.contact2Lastname = contact2Lastname
                }

                if (contact2Email !== undefined) {
                    data1.contact2Email = contact2Email
                }

                if (contact2Phone !== undefined) {
                    data1.contact2Phone = contact2Phone !== undefined ? contact2Phone.replace("+33","0").replace(/ /g, "") : null
                }

                if (secondSubscription !== undefined) {
                    data1.correlationId = secondSubscription.id
                }

                switch (tunnelType) {
                case "SCHOOL":
                    data1.dailySlot = slot
                    editSchoolSubscription(data1).then(
                        (response) => {
                            setFormInStore("ozmoSubscription", {
                                ...forms["ozmoSubscription"],
                                firstSubscription: response.data,
                            })
                                
                            if (secondSubscription !== undefined) {
                                let dataSub2 = {
                                    child: secondSubscription.kid.id,
                                    childAllowedToLeaveAlone: isAuthoriseLeaveAlone,
                                    dailySlot: secondSubscription.school.id,
                                    firstSubscriptionId: firstSubscription.id,
                                    healthSurveyStatus: uploadCertif ? -1 : 1,
                                    subscriptionId: secondSubscription.id,
                                }
                                    
                                editSchoolSecondSubscription(dataSub2).then(
                                    (response) => {
                                        setFormInStore("ozmoSubscription", {
                                            ...forms["ozmoSubscription"],
                                            firstSubscription: response.metadata.firstSchoolRegistration,
                                            secondSubscription: response.data,
                                        })
                                        resolve()
                                    },
                                )
                            }
                            else {
                                resolve()
                            }
                        },
                    )
                    break

                case "TRAINING":{
                    data1.stageSession = slot
                    data1.childPosition = forms[MesEnfants.formName] && forms[MesEnfants.formName].selectedKid?.position
                    let options = []
                    if (firstSubscription.optionProducts.length > 0) {
                        firstSubscription.optionProducts.forEach(
                            option => {
                                options.push(option.id)
                            },
                        )
                    }
                    data1["options-Spécifique"] = options

                    const optionProductsOfSubs = firstSubscription ? 
                        firstSubscription.optionProducts : null
                    const equipmentsOfSubs = firstSubscription ?
                        firstSubscription.catalogArticles : null

                    editStageSubscription(data1).then(
                        (response) => {
                            const subs = response.data.data
                            if (optionProductsOfSubs) {
                                subs.optionProducts = optionProductsOfSubs
                            }
                            if (equipmentsOfSubs) {
                                subs.catalogArticles = equipmentsOfSubs
                            }

                            setFormInStore("ozmoSubscription", {
                                ...forms["ozmoSubscription"],
                                firstSubscription: subs,
                            })
                            resolve()
                        },
                    )
                    break
                }
                }
                addSearchParams("contact", "done")
            },
        )
    }

    useEffect(() => {
        resetScroll()
        
        if (isAdult) {
            dispatch(skipStep())
        }
        const ozmoSubscriptions = forms.ozmoSubscription
        const firstSubscription = ozmoSubscriptions.firstSubscription
        const urgencyContact1 = firstSubscription.urgencyContact1
        const urgencyContact2 = firstSubscription?.urgencyContact2

        if (firstSubscription.healthSurveyStatusValue === -1) {
            setUploadCertif(true)
        }
        setContact1Firstname(urgencyContact1.firstName !== null ? urgencyContact1.firstName : userLoaded.firstname)
        setContact1Lastname(urgencyContact1.lastName !== null ? urgencyContact1.lastName : userLoaded.lastname)
        setContact1Phone(urgencyContact1.phone !== null ? urgencyContact1.phone : userLoaded.phoneNumber)
        setContact1Email(urgencyContact1.email !== null ? urgencyContact1.email : userLoaded.emailAddress)
        setContact2Firstname(urgencyContact2.firstName !== null ? urgencyContact2.firstName : "")
        setContact2Lastname(urgencyContact2.lastName !== null ? urgencyContact2.lastName : "")
        setContact2Phone(urgencyContact2.phone !== null ? urgencyContact2.phone.replace(/(.{2})/g, "$1 ").trim().replace("+33","0") : "")
        setContact2Email(urgencyContact2.email !== null ? urgencyContact2.email : "")
        setIsAuthoriseLeaveAlone(!!firstSubscription?.childAllowedToLeaveAlone)

        if (firstSubscription !== undefined) {
            if (
                (firstSubscription.healthSurveyStatusValue === -1 || firstSubscription.healthSurveyStatusValue === 1) || 
                (isValidContact1Form && validateContact2Form())
            ) {
                step?.current?.validate()
            } else {
                step.current?.unvalidate()
            }
        }
    }, [])

    useEffect(() => {
        setIsValidContact2Form(validateContact2Form())
    }, [ contact2Phone, contact2Firstname, contact2Lastname, contact2Email ])

    useEffect(() => {
        if (bar === null && document.querySelector("#progressBarCertificat")) {
            setBar(new ProgressBar.Line("#progressBarCertificat", { color: themeFeatures([ "#ff7832", "#B4FFAA" ]), easing: "easeInOut" }))
        }
    },[ progressBarRef,  uploadCertif ])

    useEffect(() => {
        if (isValidContact1Form && isValidContact2Form && (haveCheckedStatus || firstSubscription.healthSurveyStatusValue === -1 || firstSubscription.healthSurveyStatusValue === 1)) {
            step.current.validate()
        } else {
            step.current.unvalidate()
        }
    }, [ isValidContact1Form, isValidContact2Form, haveCheckedStatus, firstSubscription ])

    return (
        <Step
            {...props}
            title="Contacts d'urgence"
            help={ help }
            helpId={"EmergencyContactHelp"}
            ref={step}
            cls="arrows-on-sides noMarginBottom emergencyContact"
            onValidation={updateOzmoRegistration}
            promise
            goToStep={skipPrestaSup ? stepManagement.currentStep + 2 : undefined}
            numero={numero}
            otherId="6"
            goToPreviousStep={
                skipPrestaSup ? stepManagement.currentStep - 2 :
                    checkSkipStepEDF ? 
                        checkSkipStepEDF.skipEquipment ?
                            checkSkipStepEDF.skipPrestaSupp ?
                                stepManagement.currentStep - 3
                                :
                                stepManagement.currentStep - 2
                            : null
                        :
                        null
            }
        >
            {ajaxLoading && 
                <Preloader fixed />
            }
            {!isAdult && (
                <>
                    <div className="formsHolder c-row layoutSmall declaration-div">
                        <div className="c-col c-col--12 wysiwyg">
                            <p>Je déclare sur l’honneur que {childName} voit un médecin régulièrement et que j'ai pris connaissance du  <a href="https://cerfa.vos-demarches.com/particuliers/cerfa-15699.pdf" target="_blank" rel="noreferrer">questionnaire de santé « QS-Sport », Cerfa N°15699*01</a> en répondant :</p>
                        </div>

                        <div className="c-col c-col--12">
                            <div className="c-inputHolder--radioSquare">
                                <input
                                    type="radio" id="schoolsRegistrations--01"
                                    name="schoolsRegistrations" value="false"
                                    defaultChecked={firstSubscription?.healthSurveyStatusValue === 1}
                                    onChange={handleOptionChange}
                                    ref={firstCheckbox}
                                />
                                <label htmlFor="schoolsRegistrations--01">« NON » à toutes les questions</label>
                            </div>

                            <div className="c-inputHolder--radioSquare">
                                <input
                                    type="radio" id="schoolsRegistrations--02"
                                    name="schoolsRegistrations" value="true"
                                    defaultChecked={firstSubscription?.healthSurveyStatusValue === -1}
                                    onChange={handleOptionChange}
                                    ref={secondCheckbox}
                                />
                                <label htmlFor="schoolsRegistrations--02">« OUI » à au moins 1 question</label>
                            </div>
                        </div>

                        <div className="c-col c-col--12 child-leave-alone">
                            {kidhasMoreThan6 && (
                                <div className="c-inputHolder--radioSquare">
                                    <input
                                        type="checkbox" id="schoolsRegistrations--03"
                                        name="schoolsRegistrations2"
                                        checked={isAuthoriseLeaveAlone}
                                        onChange={event => setIsAuthoriseLeaveAlone(
                                            event.currentTarget.checked,
                                        )}
                                    />

                                    <label htmlFor="schoolsRegistrations--03">
                                        J'autorise {firstSubscription.kid.firstName ?? "MON ENFANT"} à quitter seul le
                                        centre Urban
                                    </label>
                                </div>
                            )}

                            {!kidhasMoreThan6 && (
                                <div>
                                    {firstSubscription.kid.firstName ?? "MON ENFANT"} ayant moins de 6 ans,
                                    seules les personnes renseignées dans les contacts
                                    ci-dessous seront autorisés à le récupérer. Une pièce d'identité vous sera demandée.
                                </div>
                            )}

                        </div>

                        {uploadCertif && (
                            <>
                                <div className="c-col c-col--12 wysiwyg">
                                    <p>Je m’engage à effectuer un rendez-vous chez un médecin afin de fournir un
                                        certificat médical datant de moins d'un an attestant que {childName} est apte à
                                        la pratique du {tu("longName")}, avant la première séance.</p>
                                </div>

                                <div className="c-col c-col--4 c-col--m--12">
                                    <div className="input-file">
                                        <div className="top">
                                            <div className="left-part">
                                                <label htmlFor="schoolsRegistrations--03">Certificat médical</label>
                                                <button disabled={isUploading} className="btn">
                                                    {medicalCertif ? medicalCertif : buttonUploadText}
                                                </button>
                                            </div>
                                            
                                            <div className="right-part">
                                                { firstSubscription?.attachments?.length > 0 ?
                                                    <img src={media([ "/assets/icons/cross-orange.svg", "/assets/images/padel/icons/cross.svg" ])} />
                                                    :
                                                    <img className="defaultCursor" src={media([ "/assets/images/upload.svg", "/assets/images/padel/icons/upload-padel.svg" ])} />
                                                }
                                            </div>
                                            
                                            <input 
                                                type="file" 
                                                id="schoolsRegistrations--03" 
                                                placeholder="" 
                                                onClick={firstSubscription?.attachments?.length > 0 ? deleteAttachment : undefined}
                                                onChange={ onFileUpload }
                                                ref={inputUpload}
                                            />
                                        </div>
                                        <div className="bottom" id="progressBarCertificat" ref={progressBarRef}> </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="formsHolder c-row layoutSmall contacts-div">
                        <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1 contacts-sub-div">
                            <div className="formBox">
                                <FormValidator
                                    onValidation={() => setIsValidContact1Form(true)}
                                    onUnvalidation={() => setIsValidContact1Form(false)}
                                    className="formBox__content"
                                >
                                    <div className="formBox__title">Contact principal</div>

                                    <div className="c-mrg--btm--1">
                                        <TextInput
                                            label="Nom"
                                            value={contact1Lastname}
                                            onChange={setContact1Lastname}
                                            required
                                        />
                                    </div>

                                    <div className="c-mrg--btm--1">
                                        <TextInput
                                            label="Prénom"
                                            value={contact1Firstname}
                                            onChange={setContact1Firstname}
                                            required
                                        />
                                    </div>

                                    <div className="c-mrg--btm--1">
                                        <TextInput
                                            label="Adresse email"
                                            value={contact1Email}
                                            onChange={setContact1Email}
                                            required
                                            type={"email"}
                                            inputMode={"email"}
                                        />
                                    </div>

                                    <div className="c-mrg--btm--1">
                                        <TextInput
                                            label="Téléphone"
                                            value={contact1Phone}
                                            onChange={setContact1Phone}
                                            required
                                            type={"tel"}
                                            inputMode={"numeric"}
                                            minLength={10}
                                            maxLength={16}
                                        />
                                    </div>
                                </FormValidator>
                            </div>
                        </div>

                        <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1 contacts-sub-div">
                            <div className="formBox">
                                <div className="formBox__content">
                                    <div className="formBox__title">Contact secondaire (facultatif)</div>

                                    <div className="c-mrg--btm--1">
                                        <TextInput
                                            label="Nom"
                                            value={contact2Lastname}
                                            onChange={setContact2Lastname}
                                        />
                                    </div>

                                    <div className="c-mrg--btm--1">
                                        <TextInput
                                            label="Prénom"
                                            value={contact2Firstname}
                                            onChange={setContact2Firstname}
                                        />
                                    </div>

                                    <div className="c-mrg--btm--1">
                                        <TextInput
                                            label="Adresse email"
                                            value={contact2Email}
                                            onChange={setContact2Email}
                                            type={"email"}
                                            inputMode={"email"}
                                        />
                                    </div>

                                    <div className="c-mrg--btm--1">
                                        <TextInput
                                            label="Téléphone"
                                            value={contact2Phone}
                                            onChange={setContact2Phone}
                                            type={"tel"}
                                            inputMode={"numeric"}
                                            minLength={10}
                                            maxLength={16}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="formsHolder c-row layoutSmall c-mrg--btm--4 c-mrg--btm--sm--1 allergie-div">
                        <div className="c-col c-col--3 c-col--sm--12 allergie-sub-div">
                            <div className="h3 text-center-sm allergie-title">
                                { childName } est allergique?
                                <br/>
                                Des informations importantes à nous soumettre ? Dis-nous tout.
                            </div>
                        </div>
                        <div className="c-col c-col--9 c-col--sm--12 allergie-sub-div">
                            <TextArea
                                defaultValue={informations}
                                ref={informationsRef}
                                placeholder={
                                    tunnelType === "SCHOOL" ? 
                                        "Exemple : " + childName + " est asthmatique. " + childName + " ne doit pas partir seul" + (child?.gender === "Fille" ? "e" : "") + " après l'entrainement."
                                        : tunnelType === "TRAINING" ? 
                                            "Exemple : " + childName + " est asthmatique. " + childName + " est allergique à l'arachide..."
                                            :
                                            childName + " suit un régime alimentaire particulier ? Des informations importantes à nous soumettre ? Dites nous tout."
                                }
                            />
                        </div>
                    </div>
                </>
            )}
        </Step>
    )
}

EmergencyContact.propTypes = {
    isAdult: bool,
    numero: number,
    skipPrestaSup: bool,
    tunnelType: string,
}
