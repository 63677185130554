import { arrayOf, node, oneOfType } from "prop-types"

import "./dashboard-block.scss"

function DashboardBlock(props) {
    return (
        <div className="dashboardBlock">
            {props.children}
        </div>
    )
}

DashboardBlock.propTypes = {
    children: oneOfType([ arrayOf(node), node ]),
}

export default DashboardBlock
