import PropTypes, { arrayOf, bool, func, node, object, oneOfType, string } from "prop-types"
import { useEffect, useMemo, useRef, useState } from "react"

import { TextInput } from "../../Inputs/TextInput/TextInput.jsx"

import "./Autocomplete.scss"

export const Autocomplete = ({
    label,
    initialValue,
    initFunction,
    onChange,
    apiCall,
    getOptionLabel,
    displayFunction,
    debounce,
    error,
    onlyNumbers,
    required,
    maxLength,
    localFilter,
}) => {
    const [ open, setOpen ] = useState(false)
    const [ options, setOptions ] = useState([])
    const [ loading, setLoading ] = useState(open && options && options.length === 0)
    const [ inputValue, setInputValue ] = useState(getOptionLabel(initialValue))

    const wrapperRef = useRef(null)
    const debounceTimeoutRef = useRef(null) // Ref for storing the debounce timeout
    
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ wrapperRef ])

    const handleBlur = () => {
        setTimeout(() => {
            setOpen(false)
        }, 200)
    }

    useEffect(() => {
        if (!open) {
            setOptions([])
        }
        if (initialValue && options.length === 0) {
            if (initFunction) {
                setLoading(true)
                initFunction && initFunction?.()?.then(res => {
                    if (res?.data) {
                        // Apply localFilter here
                        const filteredOptions = localFilter ? res.data.filter(option => localFilter({ option, val: inputValue })) : res.data
                        setOptions(filteredOptions)
                    }
                    setLoading(false)
                })
            } else {
                setLoading(false)
            }
        }
    }, [ open, initialValue, inputValue ])

    useEffect(() => {
        setInputValue(getOptionLabel(initialValue))
    }, [ initialValue ])

    function handleApiCall(val) {
        if (apiCall) {
            setLoading(true)
            apiCall?.(val).then(res => {
                if (res?.data) {
                    // Apply localFilter here
                    const filteredOptions = localFilter ? res.data.filter(option => localFilter({
                        option,
                        val,
                    })) : res.data
                    setOptions(filteredOptions)
                }
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }
    }

    function handleOptionClick(option) {
        setInputValue(getOptionLabel(option))
        onChange(option)
        setOpen(false)
    }

    function onTextFieldChange(val) {
        setInputValue(val)

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current) // Clear existing timeout
        }

        if (val && val.length > 0) {
            debounceTimeoutRef.current = setTimeout(() => { // Set new timeout
                handleApiCall(val)
            }, debounce ? 300 : 0)
        } else {
            setOptions([])
        }
    }

    const filteredOptions = useMemo(() => {
        return localFilter ? options.filter(option => localFilter({ option, val: inputValue })) : options
    }, [ options, inputValue, localFilter ])

    return (
        <div className={"autocomplete-container"} ref={wrapperRef}>
            <TextInput
                value={inputValue}
                label={label}
                type={onlyNumbers ? "number" : "text"}
                defaultValue={getOptionLabel(initialValue)}
                onChange={(val) => {
                    if (maxLength) {
                        val = val.slice(0, maxLength)
                    }
                    onTextFieldChange(val)
                }}
                onBlur={handleBlur}
                onFocus={() => setOpen(true)}
                error={error}
                fullWidth
                loading={loading}
                required={required}
            />
            {open && filteredOptions.length > 0 && (
                <div className={"autocomplete-options-container"}>
                    {filteredOptions.map((option, index) => (
                        <div
                            key={index}
                            className={"autocomplete-option"}
                            onClick={() => handleOptionClick(option)}
                        >
                            {displayFunction ? displayFunction(option) : getOptionLabel(option)}
                        </div>
                    ),
                    )}
                </div>
            )}
        </div>
    )
}

Autocomplete.propTypes = {
    apiCall: func,
    debounce: bool,
    displayFunction: func,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    getOptionLabel: func,
    initFunction: func,
    initialValue: oneOfType([ string, object ]),
    label: oneOfType([ string, node, arrayOf(node) ]),
    localFilter: func,
    maxLength: PropTypes.number,
    onChange: func,
    onlyNumbers: bool,
    required: bool,
}
