import { _apiCall } from "../../../backend/axios"
import { buildQueryString } from "../../../backend/helpers.js"

export function getCenterBirthdaysTypes(centerId) {
    return _apiCall(
        "read/birthdays/configs/" + centerId,
        "GET",
    ).then(
        (result) => {
            return result.data.data.items
        },
    )
}

/**
 * data : {withMultiPayments, withAlias, subscriptionId, returnUrl}
 */
export function  generatePayzenBirthdayPaymentReqs(data, activity) {
    return _apiCall(
        "create/profil/payment/card/kids/birthday/payment/reqs",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
            activity,
        },
        data,
    ).then(
        (response) => {
            if (response?.data?.data) {
                return response.data.data
            }
            return []
        },
    )
}

export function getBirthdayRegistrations(params) {
    const query = buildQueryString(params)
    return _apiCall(
        "read/birthdays/registrations" + query,
        "GET",
    ).then((response) => {
        return {
            count: response.data.data.hitCount ?? response.data.data.length ?? 0,
            items: response.data.data.items ?? response.data.data,
        }
    })
}

export function getBirthdayRegistration(id) {
    return _apiCall(
        "read/birthdays/registrations/" + id,
        "GET",
    ).then(
        (result) => {
            return result
        },
    )
}

export function getBirthdaySlotDetails(id) {
    return _apiCall(
        "read/birthdays/slots/" + id,
        "GET",
    )
}

export function getBirthdayCatalogDiscount(id) {
    return _apiCall(
        `read/birthdays/configs/${id}/catalogdiscount`,
        "GET",
    ).then(
        (result) => {
            return result
        },
    )
}

export function getBirthdaySlotsForConfig(periodStart, periodEnd, configId, orderByAsc, currentBirthdayRegistrationId) {
    periodStart = periodStart.toISOString()
    periodEnd = periodEnd.toISOString()

    let url = `read/birthdays/slots/${periodStart}/${periodEnd}/${configId}/${orderByAsc}`

    if (currentBirthdayRegistrationId) {
        url += `/${currentBirthdayRegistrationId}`
    }

    return _apiCall(
        url,
        "GET",
    ).then(
        (result) => {
            return result
        },
    )
}

export function addBirthday(data) {
    return _apiCall(
        "modify/birthdays/add",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        data,
    ).then(
        (result) => {
            return result
        },
    )
}

export function editBirthday(id, kidId, birthdayConfigId, date, giftId, 
    giftSize, drinkId, cakeId, comments, slotId, putCGV) {

    let data = {
        birthdayConfigId: birthdayConfigId,
        date: date,
        kidId: kidId,
    }

    if (giftId) { 
        data.giftId = giftId

        data.giftSize = giftSize

    }

    if (drinkId) { // can't use ternary condition because we don't want to set attribute if it doesnt exist
        data.drinkId = drinkId
    }
    if (cakeId) {
        data.cakeId = cakeId
    }

    if (comments) {
        data.comments = comments
    }

    if (slotId) {
        data.slotId = slotId
    }

    if (putCGV) {
        let formattedDate = new Date().toLocaleDateString().replace(/\//g, "-")
        formattedDate = formattedDate + " " + new Date().toLocaleTimeString()
        data.cgvAcceptationDate = formattedDate
        data.cgvVersion = "v1"
    }

    return _apiCall(
        "modify/birthdays/" + id + "/edit",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        data,
    ).then(
        (result) => {
            return result
        },
    )
}

export function addBirthdayOption(id, optionId) {
    return _apiCall(
        "modify/birthdays/" + id + "/options/" + optionId,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
    ).then(
        (result) => {
            return result
        },
    )
}

export function removeBirthdayOption(id, optionId) {
    return _apiCall(
        "modify/birthdays/registrations/" + id + "/options/" + optionId,
        "POST",
    )
}

export function createBillBirthday(id) {
    return _apiCall(
        "read/birthdays/registrations/" + id + "/createBill",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
    ).then(
        (result) => {
            return result
        },
    )
}

export function addBirthdayCatalogArticle(id, data) {
    return _apiCall(
        "modify/birthdays/registrations/" + id + "/catalogArticles",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        data,
    ).then(
        (result) => {
            return result
        },
    )
}

export function editBirthdayCatalogArticle(id, catalogArticleId, data) {
    return _apiCall(
        "modify/birthdays/registrations/" + id + "/catalogArticles/" + catalogArticleId ,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        data,
    ).then(
        (result) => {
            return result
        },
    )
}

export function removeBirthdayCatalogArticle(id, catalogArticleId) {
    return _apiCall(
        "delete/birthdays/registrations/" + id + "/catalogArticles/" + catalogArticleId,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
    ).then(
        (result) => {
            return result
        },
    )
}

export function addBirthdayDiscountCode(id, discountcode) {
    return _apiCall(
        "modify/birthdays/registrations/" + id + "/discountCodes/" + discountcode,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
    ).then(
        (result) => {
            return result
        }, (err) => {
            return err
        },
    )
}

export function removeBirthdayDiscountCode(id, discountcode) {
    return _apiCall(
        "delete/birthdays/registrations/" + id + "/discountCodes/" + discountcode,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
    ).then(
        (result) => {
            return result
        }, (err) => {
            return err
        },
    )
}

export function preregisterBirthday(id) {
    return _apiCall(
        "modify/birthdays/registrations/" + id + "/preregister",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
    ).then(
        (result) => {
            return result
        },
    )
}

export function cancelBirthday(id) {
    return _apiCall(
        "modify/birthdays/" + id + "/delete",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
    ).then(
        (result) => {
            return result
        },
    )
}
