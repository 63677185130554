import * as actions from "./actions"

const initialState = {
    discountCodes: [],
    equipments: {},
    options: {},
    schoolSubscription: {},
    secondSchoolSubscription: {},
}

function cart(state = initialState, action) {
    switch (action.type) {
    case actions.SET_SCHOOL_SUBSCRIPTION:
        return Object.assign({}, state, {
            schoolSubscription: {
                amount: action.payload.amount,
                id: action.payload.id,
            },
        })

    case actions.REMOVE_SCHOOL_SUBSCRIPTION:
        delete state.schoolSubscription
        return Object.assign({}, state)

    case actions.SET_SECOND_SCHOOL_SUBSCRIPTION:
        return Object.assign({}, state, {
            secondSchoolSubscription: {
                amount: action.payload.amount,
                id: action.payload.id,
            },
        })

    case actions.REMOVE_SECOND_SCHOOL_SUBSCRIPTION:
        delete state.secondSchoolSubscription
        return Object.assign({}, state)

    case actions.ADD_SCHOOL_OPTION:{
        let options = state.options !== undefined ? state.options : {}
        if (options[action.payload.category] === undefined && action.payload.category === "Autres") {
            options[action.payload.category] = []
        }
        if (action.payload.category === "Autres") {
            if (!options[action.payload.category].includes(action.payload.id)) {
                options[action.payload.category].push(action.payload.id)
            }
        }
        else {
            options[action.payload.category] = action.payload.id
        }
        return Object.assign({}, state, {
            options,
        })
    }

    case actions.REMOVE_SCHOOL_OPTION: {
        let schoolOptions = state.options !== undefined ? state.options : {}
        if (action.payload.category === "Autres") {
            delete schoolOptions[action.payload.category][action.payload.id]
            schoolOptions[action.payload.category].splice(schoolOptions[action.payload.category].indexOf(action.payload.id), 1)
        }
        else {
            delete schoolOptions[action.payload.category]
        }
        return Object.assign({}, state, {
            schoolOptions,
        })
    }

    case actions.CLEAR_SCHOOL_OPTIONS:
        return Object.assign({}, state, {
            options: {},
        })

    case actions.ADD_PRODUCT_TO_CART:{
        const initialQuantity = state.equipments[action.payload.id] !== undefined ? 
            state.equipments[action.payload.id][action.payload.size] !== undefined ?
                state.equipments[action.payload.id][action.payload.size].quantity 
                : 
                0
            : 
            0
            
        const product = {
            id: action.payload.id,
            price: action.payload.price,
            quantity: parseInt(action.payload.quantity) + initialQuantity,
            size: action.payload.size,
        }
        return Object.assign({}, state, {
            equipments: {
                ...state.equipments,
                [action.payload.id]: {
                    ...state.equipments[action.payload.id],
                    [action.payload.size]: product,
                },
            },
        })
    }
            
    case actions.REMOVE_PRODUCT_FROM_CART:
        if (Object.keys(state.equipments).length > 0) {
            if (state.equipments[action.payload.id] && state.equipments[action.payload.id][action.payload.size]) {
                delete state.equipments[action.payload.id][action.payload.size]
            }
        }
        return Object.assign({}, state)

    case actions.ADD_DISCOUNT_CODE:{
        let discountCodes = state.discountCodes
        discountCodes.push(action.payload.name)
        return Object.assign({}, state, {
            discountCodes,
        })
    }

    case actions.REMOVE_DISCOUNT_CODE:
        delete state.discountCode
        return state

    default:
        return state
    }
}

export default cart
