import dayjs from "dayjs"
import PropTypes from "prop-types"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import Modal from "../../../../components/Modal/Modal"
import ModalHandler from "../../../../providers/Modal/ModalHandler"
import ProfilCardWrapper from "../ProfilCardWrapper/ProfilCardWrapper"

import "./AvailableCredit.scss"

export default function AvailableCredit({ total, items }) {
    return (
        <div className="cards cb-card available-credit">
            <div className="title">Mes avoirs</div>
            <ProfilCardWrapper >
                <div className={"available-credit-wrapper"}>
                    <div className="subtitle important-text-black important-text-black-2">
                        Montant des avoirs disponibles
                    </div>
                    <h2 className={"total-credit-amount"}>{total}€</h2>
                    <h3 className={"subtitle-listing"}>échéances des avoirs</h3>
                    <div className={"credit-listing"}>
                        {items.slice(0, 3).map((item, index) => (
                            <div key={index} className={"credit-item"}>
                                <p>{item.amount}€ expire le {dayjs(item.expirationDate).format("DD/MM/YYYY")}</p>
                            </div>
                        ))}
                    </div>
                    <ButtonCta onClick={() => ModalHandler.show(CreditModal, { items: items })} text={"Voir tous les avoirs"} isAlt={true} />
                </div>
            </ProfilCardWrapper>
        </div>
    )
}

export const CreditModal = ModalHandler.create(({ items, visible, hide }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className="credit-listing-modal"
        >
            <header>tes échéances d'avoirs</header>
            <div className="wrapper">
                {items.map((item, index) => (
                    <div key={index}>
                        <p>{item.amount}€ expire le {dayjs(item.expirationDate).format("DD/MM/YYYY")}</p>
                    </div>
                ))}
            </div>
        </Modal>
    )
})

AvailableCredit.propTypes = {
    items: PropTypes.array,
    total: PropTypes.number,
}
