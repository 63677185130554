import classNames from "classnames"
import dayjs from "dayjs"
import { array, bool, func, number, object, oneOfType, string } from "prop-types"
import { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"

import useElementOnScreen from "../../../hooks/useElementOnScreen"
import useMediaPath from "../../../hooks/useMediaPath"
import DayDisplay from "../DayDisplay/DayDisplay"

const DayPicker = forwardRef(
    (
        {
            dayChoosen,
            dayCount = 5,
            initialDate = new Date(),
            paginable = false,
            selectedDate,
            onDayClick,
            isDayDisabled,
            appendDaysWith,
            altDays,
            customArrows,
            beforeDays,
            afterDays,
        },
        ref,
    ) => {
        const today = new Date()
        const [ isPreviousClickable, setIsPreviousClickable ] = useState(false)
        const [ isNextClickable, setIsNextClickable ] = useState(true)
        const [ daysToDisplay, setDaysToDisplay ] = useState([])
        const [ blockScroll, setBlockScroll ] = useState(false)
        const mediaPath = useMediaPath()
        function addDays(date, days) {
            let result = new Date(date)
            result.setDate(result.getDate() + days)
            return result
        }
        const daysWrapper = useRef()
        const daysRef = useRef([])
        const options = {
            threshold: .4,
        }
        const [ ref1IsVisible, ref2IsVisible ] = useElementOnScreen(
            {
                noCurrent: daysRef?.current?.[0]?.current !== null,
                options: options,
                ref1: daysRef?.current?.[0],
                ref2: daysRef?.current?.[daysRef?.current?.length - 1],
            },
        )

        const currentWindowWidth =  window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth

        const selectedDateIndex =  selectedDate && daysToDisplay?.findIndex(day => dayjs(day).format("YYYY-MM-DD") === dayjs(selectedDate).format("YYYY-MM-DD"))

        const smallScrollDistance = currentWindowWidth > 640 ?
            430
            :
            310
        
        const scrollDistance = currentWindowWidth > 1024 ?
            602
            :
            smallScrollDistance    

        const getNextDays = () => {
            if (beforeDays && afterDays && !isNextClickable) {return}
            else if (afterDays) {
                const currentScroll = daysWrapper.current.scrollLeft
                daysWrapper.current.scrollLeft = currentScroll + scrollDistance
            } else {
                const startDay = addDays(daysToDisplay[dayCount - 1], 1)
    
                let fiveDays = [
                    startDay,
                ]
                for (let i = 1; i < dayCount; i++) {
                    fiveDays.push(addDays(startDay, i))
                }
                setDaysToDisplay(fiveDays)
            }
        }

        const getPreviousDays = () => {
            if (beforeDays) {
                const currentScroll = daysWrapper.current.scrollLeft
                daysWrapper.current.scrollLeft = currentScroll - scrollDistance
            } else {
                let startDay = addDays(daysToDisplay[0], -dayCount)
                /* istanbul ignore else */
                if (today > startDay) {
                    startDay = today
                }
    
                let fiveDays = [
                    startDay,
                ]
                for (let i = 1; i < dayCount; i++) {
                    fiveDays.push(addDays(startDay, i))
                }
                setDaysToDisplay(fiveDays)
            }
        }

        const checkPreviousClickable = () => {
            const now = dayjs().format("DD-MM-YYYY")
            const firstDisplayedDate = dayjs(daysToDisplay[0]).format("DD-MM-YYYY")
            return now !== firstDisplayedDate
        }

        useEffect(() => {
            !beforeDays && !afterDays && setIsPreviousClickable(checkPreviousClickable())
        }, [ daysToDisplay ])

        const constructDays = date => {
            if (beforeDays && afterDays) {
                let startDate = new Date(date)
                const days = []
                const selectedDay = dayjs(date).format("YYYY-MM-DD")
                const today = dayjs(new Date()).format("YYYY-MM-DD")
                const daysDifference = dayjs(selectedDay).diff(dayjs(today), "day")
                for (let i = daysDifference < 20 ? daysDifference : 20; i > 0; i--) {
                    days.push(addDays(startDate, -i))
                }
                days.push(startDate)
                for (let i = 1; i < 50; i++) {
                    days.push(addDays(startDate, i))
                }
                daysRef.current = daysRef.current.slice(0, days.length)
                setDaysToDisplay(days)
            } else {
                let startDate = new Date(date)
                const previousDay = addDays(startDate, -1)
                if (previousDay > today || dayjs(previousDay).format("MM-DD-YYYY") === dayjs(today).format("MM-DD-YYYY")) {
                    startDate = previousDay
                }
    
                let days = [
                    startDate,
                ]
            
                for (let i = 1; i < dayCount; i++) {
                    days.push(addDays(startDate, i))
                }
                setDaysToDisplay(days)
            }
        }

        useEffect(
            () => {
                /* istanbul ignore next */
                if (selectedDate && !daysToDisplay?.find(day => dayjs(day).format("YYYY-MM-DD") === dayjs(selectedDate).format("YYYY-MM-DD"))) {
                    constructDays(selectedDate)
                }

            }, [ selectedDate ],
        )
        useEffect(
            () => {
                /* istanbul ignore next */
                if (beforeDays && afterDays && selectedDateIndex && selectedDateIndex !== -1) {
                    if (blockScroll) {
                        setBlockScroll(false)
                    } else if (dayjs(daysToDisplay[selectedDateIndex]).format("YYYY-MM-DD") !== dayjs(today).format("YYYY-MM-DD")) {
                        daysRef?.current?.[selectedDateIndex - 1]?.scrollIntoView?.({ inline: "start" })
                    } else {
                        daysRef?.current?.[selectedDateIndex]?.scrollIntoView?.({ inline: "start" })
                    }
                }

            }, [ selectedDateIndex ],
        )
        useEffect(
            () => {
                constructDays(initialDate)
            }, [],
        )

        useImperativeHandle(
            ref,
            () => ({
                getDays: () => daysToDisplay,
            }),
        )
        useEffect(
            () => {
                if (daysRef.current.length !== daysToDisplay.length) {
                    // add or remove refs
                    daysRef.current = Array(daysToDisplay.length)
                        .fill()
                        .map((_, i) => daysRef.current[i] || createRef())
                }
            }, [ daysToDisplay ],
        )
        useEffect(
            () => {
                if (beforeDays && afterDays && ref1IsVisible) {
                    setIsPreviousClickable(false)
                } else if (beforeDays && afterDays && !ref1IsVisible) {
                    setIsPreviousClickable(true)
                }
            }, [ ref1IsVisible ],
        )
        useEffect(
            () => {
                if (beforeDays && afterDays && ref2IsVisible) {
                    setIsNextClickable(false)
                } else if (beforeDays && afterDays && !ref2IsVisible) {
                    setIsNextClickable(true)
                }
            }, [ ref2IsVisible ],
        )

        return (
            <section
                className={classNames("o-section--days", {
                    customDaysHandler: beforeDays && afterDays,
                })}
            >
                { paginable && (
                    <img
                        data-testid="previousDays"
                        src={customArrows ? customArrows[0] : mediaPath([ "/assets/icons/arrow-left-orange.svg", "/assets/images/padel/icons/arrow-left-orange.svg" ])}
                        className={classNames("previousDays", { disabled: !isPreviousClickable })}
                        onClick={
                            () => {
                                /* istanbul ignore else */
                                if (isPreviousClickable) {
                                    getPreviousDays()
                                }
                            }
                        }
                    />
                )}
                {
                    !beforeDays && !afterDays && daysToDisplay.map(
                        (day) => {
                            let inOrange = null
                            if (dayChoosen !== undefined) {
                                inOrange = dayChoosen === dayjs(day).format("MM-DD-YYYY")
                            }
                            return (
                                <DayDisplay
                                    inOrange={inOrange}
                                    disabled={isDayDisabled?.(day)}
                                    day={day}
                                    selectedDate={selectedDate}
                                    onClick={() => {
                                        onDayClick(day)
                                    }}
                                    key={"locday--" + day}
                                    altDays={altDays}
                                >
                                    {appendDaysWith?.(day)}
                                </DayDisplay>
                            )
                        },
                    )
                }

                {
                    beforeDays && afterDays && (
                        <div className="daysWrapper" ref={daysWrapper}>
                            {
                                daysToDisplay.map(
                                    (day, i) => {
                                        let inOrange = null
                                        if (dayChoosen !== undefined) {
                                            inOrange = dayChoosen === dayjs(day).format("MM-DD-YYYY")
                                        }

                                        return (
                                            <DayDisplay
                                                inOrange={inOrange}
                                                disabled={isDayDisabled?.(day)}
                                                day={day}
                                                selectedDate={selectedDate}
                                                onClick={() => {
                                                    if (inOrange) {return}
                                                    onDayClick(day)
                                                    setBlockScroll(true)
                                                }}
                                                key={"locday--" + day}
                                                altDays={altDays}
                                                ref={el => daysRef.current[i] = el} 
                                            >
                                                {appendDaysWith?.(day)}
                                            </DayDisplay>
                                        )
                                    },
                                )
                            }
                        </div>
                    )
                }

                {paginable && (
                    <img
                        data-testid="nextDays"
                        src={customArrows ? customArrows[1] : mediaPath([ "/assets/icons/arrow-right-orange.svg", "/assets/images/padel/icons/arrow-right-orange.svg" ])}
                        className={classNames("nextDays", { disabled: beforeDays && afterDays && !isNextClickable })}
                        onClick={getNextDays}
                    />
                )}
            </section>
        )
    },
)

DayPicker.propTypes = {
    afterDays: oneOfType([ bool, number ]),
    altDays: bool,
    appendDaysWith: func,
    beforeDays: oneOfType([ bool, number ]),
    customArrows: oneOfType([ bool, array ]),
    dayChoosen: oneOfType([ object, string ]),
    dayCount: number,
    firstDate: oneOfType([ object, string ]),
    futureOnly: bool,
    initialDate: oneOfType([ object, string ]),
    isDayDisabled: func,
    onDayClick: func,
    paginable: bool,
    pastOnly: bool,
    selectedDate: oneOfType([ object, string ]),
}

DayPicker.displayName = "DayPicker"

export default DayPicker
