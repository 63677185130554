import FormValidationErrorsDisplayConfirm from "./confirmOverlay/formValidationErrorsDisplayConfirmContent"
import SimpleConfirm from "./confirmOverlay/simpleConfirmContent"
import AuthenticationExceptionContent from "./overlay/authenticationExceptionContent"
import PayzenCbPaymentContent from "./overlay/payzenCbPaymentContent"
import SplitPaymentLinkContent from "./overlay/splitPaymentLinkContent"
import BirthdayReservation from "../../../pages/kids/birthdayReservation/BirthdayReservation"
import EcoleDeFoot from "../../../pages/kids/ecoleDeFoot/EcoleDeFoot"
import StageDeFootTunnel from "../../../pages/kids/stageDeFoot/StageDeFootTunnel"
import LeagueTunnel from "../../../pages/league/LeagueTunnel"
import FindAMatch from "../../../pages/supersub/findAMatch/FindAMatch"
import UrbanCup from "../../../pages/teams/urbanCup/UrbanCup"

export const allContents = () => {
    return {
        authenticationException:                <AuthenticationExceptionContent />,
        birthdayReservation:                    <BirthdayReservation/>,
        ecoleDeFootTunnel:                      <EcoleDeFoot/>,
        findMatch:                              <FindAMatch/>,
        formValidationErrorsDisplayConfirm:     <FormValidationErrorsDisplayConfirm />,
        leagueTunnel:                           <LeagueTunnel/>,
        payzenCbPayment:                        <PayzenCbPaymentContent />,
        simpleConfirm:                          <SimpleConfirm />,
        splitPaymentLinkContent:                <SplitPaymentLinkContent />,
        stagesDeFootTunnel:                     <StageDeFootTunnel/>,
        urbanCupTunnel:                         <UrbanCup/>,
    }
}
