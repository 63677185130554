import PropTypes from "prop-types"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { IntercomProvider as Intercom, useIntercom } from "react-use-intercom"

import { useTheme } from "../components/ThemeContext/ThemeContext.jsx"

export const IntercomIntegration = ({ children }) => {
    const userLoaded = useSelector(state => state.userLoaded)
    const { boot, shutdown } = useIntercom()

    useEffect(() => {
        if (Object.keys(userLoaded).length > 0) {
            boot({
                email: userLoaded.emailAddress,
                name: userLoaded.firstname + " " + userLoaded.lastname,
                userId: userLoaded.contactId,
            })
        } else {
            shutdown()
        }
    }, [ userLoaded, boot, shutdown ])

    return <>{children}</>
}

export const IntercomProvider = ({ children }) => {
    const intercomAppId = import.meta.env.VITE_INTERCOM_APP_ID
    const theme = useTheme()

    if (!intercomAppId || theme !== "theme-business") {
        return <>{children}</>
    }
    
    return (
        <Intercom appId={intercomAppId}>
            <IntercomIntegration>
                {children}
            </IntercomIntegration>
        </Intercom>
    )
}

IntercomProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

IntercomIntegration.propTypes = {
    children: PropTypes.node.isRequired,
}
