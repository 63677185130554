import Modal from "../../../../../components/Modal/Modal"
import useMediaPath from "../../../../../hooks/useMediaPath"
import ModalHandler from "../../../../../providers/Modal/ModalHandler"

import "./GymlibLine.scss"

const GymlibLine = () => {
    const mediaPath = useMediaPath()
    return (
        <div className="gymlibLine">
            J'ai un code Gymlib
            <img
                className="cancelInsurance"
                src={mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                onMouseOver={e => e.currentTarget.src = mediaPath([ "/assets/icons/info-small-orange-hover.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                onMouseOut={e => e.currentTarget.src = mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                onClick={
                    (e) => {
                        e.preventDefault()
                        ModalHandler.show(GymlibHelp)
                    }
                }
            />
        </div>
    )
}

export const GymlibHelp = ModalHandler.create(({ visible, hide }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className="gymlibModal"
        >
            <header>Payer avec Gymlib</header>
            <section>
                <div className="title">
                Depuis l'app Gymlib, sélectionne un centre et une durée de réservation pour récupérer ton code.
                </div>
                <div className="desc">
                Attention, pour que le code soit valide, les informations ci-dessous doivent obligatoirement être les mêmes entre Gymlib et MyUrban :
                </div>
                <ul>
                    <li>Email</li>
                    <li>Centre</li>
                    <li>Durée de réservation</li>
                </ul>
                <div className="helpDesc">
                En cas de besoin n'hésite pas à nous contacter au 0&nbsp;806&nbsp;23&nbsp;00&nbsp;24 (appel non surtaxé)
                </div>
            </section>
        </Modal>
    )
})

export default GymlibLine
