
import { func, string } from "prop-types"

import ButtonCta from "../Buttons/button/button-cta"

import "./TextButton.scss"

export default function TextButton({ text, buttonLabel, action }) {
    return (
        <div className="textButton">
            {text && <div className="text">{text}</div>}
            <ButtonCta text={buttonLabel} isAlt onClick={action} />
        </div>
    )
}

TextButton.propTypes = {
    action: func,
    buttonLabel: string,
    text: string,
}
