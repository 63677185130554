import { arrayOf, bool, func, node, number, oneOfType, string } from "prop-types"
import { useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import PhoneInput from "react-phone-input-2"

import APP_CONFIG from "../../business/config"
import phoneCountries from "../../model/enums/phoneCountries"
import { useAssets } from "../../providers/AssetsProvider.jsx"
import ButtonCta from "../Buttons/button/button-cta"
import Timer from "../Time/timer/timer"

import "./blocRetry.scss"

function BlocRetry({ type, value, onChangeAction, retryAction, retryActionComplete, isSuccess, successOrErrorMsg, notValid, blockedDuration, onFocus, onBlur }) {
    const [ isEditable, setIsEditable ] = useState(false)
    const [ textData, setTextData ] = useState(value ? value : "")
    const [ timeoutBeforeSending, setTimeoutBeforeSending ] = useState(false)
    const { m } = useAssets()
    const inputEditable = useRef(null)
    const recaptchaRef = useRef(null)

    const phoneWording = type === "phoneNumber" ?
        "Tu n'as pas reçu de SMS, est-ce bien ton numéro ?"
        :
        "UNDEFINED TYPE PROVIDED"

    const typeWording = type === "phoneNumber" ?
        "code"
        :
        "Unknown type"

    const editableContent = isEditable ? (
        <input
            onFocus={onFocus}
            onBlur={onBlur}
            type="text"
            value={textData}
            className="blocRetryInput" onChange={
                (e) => {
                    setTextData(e.target.value)
                    if (onChangeAction) {
                        onChangeAction(e.target.value)
                    }
                }
            }
            ref={inputEditable} 
        />
    ) : (
        <p>
            {textData}
        </p>
    )

    return (
        <div className="blocRetry">
            <div className="retryLabel">
                { type === "emailAddress" ? "Est-ce bien ton e-mail ?" : phoneWording }
            </div>

            <div className="retrySendBloc">
                <div className={type === "phoneNumber" ? "phoneNumber half" : "half"}>
                    <span
                        className={isEditable ? "editable-line couldEdit" : "editable-line"}
                    >
                        { type === "phoneNumber" ?  (
                            <PhoneInput
                                onFocus={onFocus}
                                onBlur={onBlur}
                                country={"fr"}
                                placeholder="Téléphone mobile"
                                inputProps={{
                                    required: true,
                                }}
                                onlyCountries={phoneCountries}
                                enableTerritories
                                value={textData}
                                onChange={
                                    (phone, data) => {
                                        setTextData(phone)
                                        const phoneHasDial = phone.includes(data.dialCode)
                                        if (phoneHasDial) {
                                            setTextData(phone)
                                            if (onChangeAction) {
                                                onChangeAction(phone)
                                            }
                                        } else {
                                            if (phone.length <= data.countryCode.length) {
                                                setTextData(phone.replace(/\D|^0+/g, ""))
                                                if (onChangeAction) {
                                                    onChangeAction(phone.replace(/\D|^0+/g, ""))
                                                }
                                            } else {
                                                setTextData(data.dialCode + phone.replace(/\D|^0+/g, ""))
                                                if (onChangeAction) {
                                                    onChangeAction(data.dialCode + phone.replace(/\D|^0+/g, ""))
                                                }
                                            }
                                        }
                                    }
                                }
                                specialLabel="Téléphone mobile"
                            />
                        ) : editableContent}
                      
                        <img
                            src={m("icons.profil.pencil")}
                            className={type === "phoneNumber" ? "pencilIcon absolute" : "pencilIcon"}
                            alt="Editer"
                            onClick={
                                type === "phoneNumber" ?
                                    () => document.querySelector(".form-control").focus()
                                    :
                                    () => {
                                        setIsEditable(!isEditable)
                                        if (!isEditable) {
                                            setTimeout(
                                                () => {
                                                    if (inputEditable?.current) {
                                                        inputEditable.current.focus()
                                                    }
                                                }, 100,
                                            )
                                        }
                                    }
                            }
                        />
                    </span>
                </div>
                <ButtonCta
                    text={
                        "Renvoyer un " + 
                        (type === "emailAddress" ?
                            "e-mail"
                            : typeWording
                        )
                        + " de confirmation"
                    }
                    isAlt
                    disabled={notValid || ((type === "emailAddress" || type === "phoneNumber") && (timeoutBeforeSending && isSuccess))}
                    onClick={
                        retryAction ? 
                            async () => {
                                if (type === "emailAddress") {
                                    const captchaToken = await recaptchaRef.current.executeAsync()
                                    recaptchaRef.current.reset()
                                    setTimeoutBeforeSending(true)
                                    retryAction(textData, captchaToken)
                                } else {
                                    setTimeoutBeforeSending(true)
                                    retryAction(textData)
                                }
                            }
                            :
                            undefined
                    }
                />
            </div>

            { retryActionComplete && (
                <div className="errorSuccessLabel">
                    { isSuccess && (!blockedDuration || (blockedDuration && blockedDuration === 60000)) ? 
                        <img className="iconError" src="/assets/icons/notifDarkGreen.svg" />
                        :
                        <img className="iconError" src="/assets/icons/errorNotif.svg" />
                    }
                    <div className={isSuccess ? "isSuccess" : ""}>
                        {successOrErrorMsg}
                    </div>
                </div>
            )}

            {(type === "emailAddress" || type === "phoneNumber") && timeoutBeforeSending && isSuccess && (
                <div className="timerText">
                    <span>Nouvel envoi possible dans&nbsp;</span>
                    <Timer
                        duration={blockedDuration ?? 60000}
                        expirationCallBack={
                            () => { setTimeoutBeforeSending(false) }
                        }
                    />
                </div>
            )}
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={APP_CONFIG.recapchaSiteKey}
                size="invisible"
            />
        </div>
    )
}

BlocRetry.propTypes = {
    blockedDuration: oneOfType([ bool, number ]),
    isSuccess: bool,
    notValid: bool,
    onBlur: oneOfType([ bool, func ]),
    onChangeAction: func,
    onFocus: oneOfType([ bool, func ]),
    retryAction: func,
    retryActionComplete: bool,
    successOrErrorMsg: oneOfType([ string, node, arrayOf(node) ]),
    type: string,
    value: string,
}

export default BlocRetry
