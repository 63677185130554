import dayjs from "dayjs"

const dateToYear = (dateStr) => {
    const isDateInFormat = (dateStr) => {
        const regex = /^\d{4}-\d{2}-\d{2}$/
        return regex.test(dateStr)
    }
    if (isDateInFormat(dateStr)) {
        return dayjs(dateStr).format("YYYY")
    } else {
        return dayjs(dateStr, "DD/MM/YYYY").format("YYYY")
    }
}

export default dateToYear
