import classNames from "classnames"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ActiveFilters from "./ActiveFilters"
import DiscountFilters from "./DiscountFilters"
import LocationFilterPopin from "./LocationFilterPopIn"
import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import CheckBlock from "../../../../../components/Forms/CheckBlock/CheckBlock"
import { hourOptions } from "../../../../../components/HoursList/HoursList"
import DayPicker from "../../../../../components/Time/DayPicker/DayPicker"
import { getUser } from "../../../../../globalAPI/api"
import useDeepCompareEffect from "../../../../../hooks/useDeepCompareEffect.js"
import useMediaPath from "../../../../../hooks/useMediaPath"
import useThemeHeader from "../../../../../hooks/useThemeHeader"
import locationHours from "../../../../../model/data/reservation/locationHours"
import usePath from "../../../../../routes/services/usePath"
import {
    correspondingActivities,
    filterSlotsByActivity,
    getCategoryIdFromTypesIds,
    handleNextSlotDate,
    hydrateSlots,
} from "../../../../profil/FavoriteSlots/functions"
import { compareArrays, formArgs, handleSlotModal, timeFilters } from "../services/function"
import { useBookingStore } from "../services/store"

import "./NewBookingFilters.scss"

export const NewBookingFilters = () => {
    const [ slots, setSlots ] = useState([])
    const [ isFilterModalOpen, setIsFilterModalOpen ] = useState(false)
    const { control, setValue, watch, register } = useFormContext()
    const mediaPath = useMediaPath()
    const activity = useThemeHeader()

    const {
        availableDiscounts,
        setIsLoading,
        bookingInfosData,
        fromOrderSummary,
        setSelectedSlot,
        selectedSlot,
        savedFilters,
    } = useBookingStore()

    const userLoaded = useSelector(state => state.userLoaded) ?? []
    const navigate = useNavigate()
    const path = usePath()

    const todayDate = dayjs()
    const [ timeFilter, selectedDate, hourFilter, typeFilter, centerId, activityId, typeIds, creneauID ] = watch([
        formArgs.TIME_FILTER,
        formArgs.DATE,
        formArgs.HOUR,
        formArgs.TYPE,
        formArgs.CENTER_ID,
        formArgs.ACTIVITY_ID,
        formArgs.TYPE_IDS,
        formArgs.SLOT_ID,
    ])

    const types = bookingInfosData?.centerOptions?.find(center => center[0] === parseInt(centerId))?.types?.filter(type => type?.categories[0]?.id === parseInt(activityId))
    const selectedDateIsToday = dayjs(selectedDate).format("DD-MM-YYYY") === todayDate.format("DD-MM-YYYY")
    const haveDiscountCodes = availableDiscounts?.length > 0
    const filterApplied = selectedDate || timeFilter || (hourFilter && hourFilter !== "noHourFilter") || typeFilter || typeIds?.length > 0

    useEffect(() => {
        register(formArgs.TIME_FILTER)
        register(formArgs.HOUR)
        register(formArgs.DURATION)
        register(formArgs.TYPE)
    }, [])

    useEffect(() => {
        setSlots(filterSlotsByActivity(hydrateSlots(userLoaded?.preferredSlots ?? [], bookingInfosData?.centerOptions ?? []), activity))
    }, [ userLoaded, bookingInfosData?.centerOptions ])

    useEffect(() => {
        if (slots && creneauID) {
            setSelectedSlot(slots.find(slot => slot.id === parseInt(creneauID)))
        }
    }, [ creneauID, slots ])

    useEffect(() => {
        const validDate = dayjs(selectedDate).isValid()
        if (selectedDate && validDate) {
            const isNotWeekEnd = ![ 0, 6 ].includes(
                dayjs(selectedDate).day(),
            )
            const noHourFilterApplied = !hourFilter || hourFilter === "noHourFilter"
            const isToday = dayjs(selectedDate).format("DD-MM-YYYY") === todayDate.format("DD-MM-YYYY")

            if (!isToday && isNotWeekEnd && !fromOrderSummary && !selectedSlot && noHourFilterApplied) {
                const newTime = {
                    end: "T23:59:00",
                    name: "night",
                    start: "T17:59:00",
                }
                setTimeFilter(newTime, true)
            } else {
                setTimeFilter(savedFilters?.[formArgs.TIME_FILTER] ?? null)
            }
        }
    }, [ selectedDate ])

    useDeepCompareEffect(() => {
        if (types && types.length > 0 && typeIds && typeIds.length > 0 && Array.isArray(typeIds)) {
            const newTypes = types.filter(type => typeIds.includes(type.key)).map(type => [ type.key, type.value ]) ?? []
            const newTypeIds = typeIds.filter(typeId => newTypes.find(type => type[0] === typeId))

            if (!compareArrays(typeIds, newTypeIds)) {
                setValue(formArgs.TYPE_IDS, newTypeIds)
            }

            setValue(formArgs.TYPE, newTypes)
        }
    }, [ typeIds, types ?? [] ])

    const setTimeFilter = (time, fromDateChange = null) => {
        if (!time || (!fromDateChange || !time) && !fromOrderSummary) {
            setValue(formArgs.TIME_FILTER, null)
        } else {
            setValue(formArgs.TIME_FILTER, {
                end: time?.end,
                name: time?.name,
                start: time?.start,
            })
        }
    }
    const changeTimeFilter = (time) => {
        if (timeFilter?.name === time.name) {
            setValue(formArgs.TIME_FILTER, null)
        } else {
            setValue(formArgs.TIME_FILTER, {
                end: time?.end,
                name: time?.name,
                start: time?.start,
            })
        }
    }

    const dateChange = (day, fromStep1) => {
        if ((dayjs(selectedDate).isSame(day, "day")) && !fromStep1) {
            return
        }
        setValue(formArgs.DATE, dayjs(day).format("YYYY-MM-DD"))
    }

    const openFilterModal = () => {
        setIsFilterModalOpen(true)
    }

    const handleSlotSelection = (slot) => {
        const activity = getCategoryIdFromTypesIds(slot.center, slot.resourceTypes) ?? {}
        const newTypes = correspondingActivities
            .filter(activity => slot.resourceTypes.includes(activity.id))
            .map(activity => {
                let name = types?.find(type => type.key === activity.id)?.value ?? activity.name
                return [ activity.id, name ]
            })
        
        const newHourFilters = locationHours.find(
            option => option.display === hourOptions.find(
                option => option.value === slot.start,
            ).display,
        ) ?? "noHourFilter"

        slot.fromUserAction = true

        setValue(formArgs.DATE, dayjs(handleNextSlotDate(slot)).format("YYYY-MM-DD"))
        setValue(formArgs.TYPE, newTypes)
        setValue(formArgs.TYPE_IDS, newTypes.map(type => type[0]) ?? [])
        setValue(formArgs.HOUR, newHourFilters)
        setValue(formArgs.CENTER_ID, parseInt(slot.centerId))
        setValue(formArgs.ACTIVITY_ID, parseInt(activity.id))
        setValue(formArgs.SLOT_ID, slot.id)
        setValue(formArgs.TIME_FILTER, null)
        setSelectedSlot(slot)
    }

    const handleSlotButtonClick = () => {
        handleSlotModal({
            activityId,
            bookingInfosData,
            centerId,
            hourFilter,
            onSlotSelected: handleSlotSelection,
            refreshUser,
            selectedDate,
            selectedSlot,
            slots,
            timeFilter,
            typeFilter,
            userLoaded,
        })
    }

    const refreshUser = () => {
        setIsLoading(true)
        getUser(true).finally(() => {
            setIsLoading(false)
            navigate(path("/profil/creneauxFavoris"))
        })
    }

    const filterModal = useMemo(() => {
        return (
            <LocationFilterPopin hide={() => setIsFilterModalOpen(false)} visible={isFilterModalOpen}/>
        )
    }, [ isFilterModalOpen ])

    if (!centerId || !activityId || !selectedDate) {return null}   

    return (
        <>
            <div className={"daysContainer c-col c-col--12 flex justify-center"}>
                <ButtonCta
                    className={"favoriteSlotButton desktop"}
                    hoverChangeIcon={"/assets/icons/favoriteSlot/add-favorite-slot.svg"}
                    isAlt
                    disabled={!filterApplied && slots.length === 0}
                    text={slots.length > 0 ? "Mes créneaux" : <>Ajouter un <br/>créneau favori</>}
                    icon={"/assets/icons/favoriteSlot/add-favorite-slot-orange.svg"}
                    onClick={() => handleSlotButtonClick()}
                />
                <Controller
                    control={control}
                    render={
                        ({ field: { value } }) => (
                            <div className="dayPickerWrapper">
                                <DayPicker
                                    initialDate={new Date()}
                                    paginable
                                    altDays
                                    onDayClick={(day) => dateChange(day, null, null)}
                                    selectedDate={value}
                                    dayCount={(
                                        window.innerWidth
                                            || document.documentElement.clientWidth
                                            || document.body.clientWidth
                                    ) <= 1024 ? 5 : 7}
                                    beforeDays={20}
                                    afterDays={49}
                                    customArrows={
                                        [
                                            mediaPath(
                                                [
                                                    "/assets/icons/arrow-left-black.svg",
                                                    "/assets/images/padel/icons/arrow-left-green.svg",
                                                ],
                                            ),
                                            mediaPath(
                                                [
                                                    "/assets/icons/arrow-right-black.svg",
                                                    "/assets/images/padel/icons/arrow-right-green.svg",
                                                ],
                                            ),
                                        ]
                                    }
                                />
                            </div>
                        )
                    }
                    name="date"
                />
            </div>
            <div
                className="filtersWrapper c-col c-col--12 flex justify-center"
            >
                <div className={classNames("creneaux", { divider: haveDiscountCodes })}>
                    {timeFilters.map(filter => (
                        <CheckBlock
                            key={filter.name}
                            checked={timeFilter?.name === filter.name}
                            name="creneauItem"
                            value={filter.name}
                            callback={() => changeTimeFilter(filter)}
                            disabled={selectedDateIsToday && todayDate > new Date(todayDate.format().split("T")[0] + filter.end)}
                        >
                            <span>{filter.displayStart}</span>
                            <span>{filter.displayEnd}</span>
                        </CheckBlock>
                    ))}
                </div>
                { haveDiscountCodes && (
                    <Controller
                        control={control}
                        render={
                            ({ field: { value, onChange } }) => (
                                <DiscountFilters selectedDiscount={value} setSelectedDiscount={onChange} availableDiscounts={availableDiscounts} />
                            )
                        }
                        name={formArgs.DISCOUNT_FILTER}
                    />
                )}
                <div className="buttonWrapper">
                    <ButtonCta
                        text={(<>
                            <span>
                                                Options de match
                            </span>
                            <img alt={""} src={mediaPath([ "/assets/icons/options.svg", "/assets/icons/options-black.svg" ])} />
                        </>)}
                        onClick={openFilterModal}
                        className="filtersOptions"
                        isCta2Alt
                        noShadow
                    />
                    <ButtonCta
                        className={"favoriteSlotButton inline"}
                        hoverChangeIcon={"/assets/icons/favoriteSlot/add-favorite-slot.svg"}
                        isAlt
                        disabled={!filterApplied && slots.length === 0}
                        text={slots.length > 0 ? "Mes créneaux" : <>Ajouter un <br/>créneau favori</>}
                        icon={"/assets/icons/favoriteSlot/add-favorite-slot-orange.svg"}
                        onClick={() => handleSlotButtonClick()}
                    />
                </div>
            </div>
            <ActiveFilters/>
            {filterModal}
        </>
    )
}
