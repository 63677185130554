import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import DateDisplay from "../../../../components/Time/DateDisplay"
import { getUsCenters } from "../../../../globalAPI/api"
import usePath from "../../../../routes/services/usePath"
import { isBusinessEnvironment } from "../../../../utilities/helpers.js"
import ProfilCardWrapper from "../ProfilCardWrapper/ProfilCardWrapper"

import "./MyProfil.scss"

export default function MyProfil() {
    const [ favoriteCenter, setFavoriteCenter ] = useState(null)
    const [ initialized, setInitialized ] = useState(false)
    const userLoaded = useSelector(state => state.userLoaded)
    const navigate = useNavigate()
    const path = usePath()

    useEffect(() => {
        const centersArr = []
        getUsCenters().then((res) => {
            if (res) {
                Array.from(res.data).forEach(
                    (center) => {
                        centersArr.push({ id: center.id, name: center.name })
                    },
                )
                setFavoriteCenter(centersArr.find(
                    (e) => parseInt(userLoaded.centerId) === parseInt(e.id),
                )?.name)
                setInitialized(true)
            }
        })
    }, [])

    return (
        <div className="my-profil">
            <h2>Mon profil</h2>
            <ProfilCardWrapper>
                <p className="name">{userLoaded.firstname} {userLoaded.lastname}</p>
                <div className="p-myInformation__infos__line">
                    <div>
                        <h3>Centre favori</h3>
                        <p>
                            {initialized && (
                                <>
                                    {favoriteCenter ?
                                        favoriteCenter
                                        :
                                        "Pas de centre favori : 0 806 23 00 24"
                                    }
                                </>
                            )}
                        </p>
                    </div>
                    {isBusinessEnvironment() && (
                        <div className="min210--medium">
                            <h3>Fonction</h3>
                            <p>
                                {userLoaded.jobTitle || "Non renseigné"}
                            </p>
                        </div>
                    )}
                </div>
                <div className="p-myInformation__infos__line">
                    <div>
                        <h3>Mon adresse de facturation</h3>
                        <p>{userLoaded.street} {userLoaded.zip} {userLoaded.city}</p>
                    </div>

                    <div className="min210--medium">
                        <h3>Date de naissance</h3>
                        <p>
                            {userLoaded.birthdate && (
                                <DateDisplay
                                    stringDate={userLoaded.birthdate}
                                    slashDisplay
                                />
                            )}

                        </p>
                    </div>
                </div>
                <div className="p-myInformation__infos__line">
                    <div className="newsLettersSub">
                        <h2>Profite de cadeaux, codes promos et remises en recevant les meilleures offres et exclusivités :</h2>
                        <div className="wrapper">
                            <p>
                                UrbanPadel :
                                <span>{userLoaded.padelNewsletter ? " Oui" : " Non"}</span>
                            </p>
                            <p>
                                UrbanSoccer :
                                <span>{userLoaded.newsletter ? " Oui" : " Non"}</span>
                            </p>
                            <p>
                                Partenaires :
                                <span>{userLoaded.partnerNewsletter ? " Oui" : " Non"}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </ProfilCardWrapper>
            <ButtonCta
                text="Modifier mon profil"
                onClick={
                    () => {
                        navigate(path("/profil/coordonnees/edit"))
                    }
                }
            />
        </div>
    )
}
