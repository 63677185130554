import { useFormContext } from "react-hook-form"

import { formArgs } from "../services/function"

import "./FormErrorContent.scss"

export const FormErrorContent = () => {
    const { watch } = useFormContext()

    const [ selectedDate, centerId, activityId ] = watch([
        formArgs.DATE,
        formArgs.CENTER_ID,
        formArgs.ACTIVITY_ID,
    ])

    return (
        <div className={"formErrorContent"}>
            {(!centerId || !(activityId || activityId?.id === 0) || !selectedDate) && (
                <div className="noParams">
                    {!centerId && "Sélectionne un centre"}
                    {centerId && !(activityId || activityId?.id === 0) && "Sélectionne une activité"}
                    {!selectedDate && "Sélectionne une date"}
                </div>
            )}
        </div>
    )
}
