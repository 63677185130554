import { string } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import Step from "../../../../components/stepsManagement/Step"
import useAddSearchParam from "../../../../hooks/useAddSearchParams"
import useResetScroll from "../../../../hooks/useResetScroll"
import { setFormEntry } from "../../../../store/forms/actions"
import { nextStep } from "../../../../store/stepsManagement/actions"
import EffectifDisplay from "../../components/EffectifDisplay/EffectifDisplay"
import useUpdateRegistration from "../../hooks/useUpdateRegistration"

import "./ChoixEffectif.scss"

function ChoixEffectif(props) {
    useResetScroll()
    const { tunnelType } = props
    const step = useRef(null)

    const dispatch = useDispatch()
    const addSearchParams = useAddSearchParam()

    const [ ajaxLoading, setAjaxLoading ] = useState(false)

    const registration = useSelector(state => state.forms.TournamentRegistration)
    const tunnelTournamentRegistration = useSelector(state =>  state.forms.tunnelTournamentRegistration)
    const avoirFormData = useSelector(state => state.forms.avoirFormData)
    const [ mailPlayers, setMailPlayers ] = useState(false)
    const help = <PhoneCallHelp tunnelType={tunnelType}/>

    const updateRegistration = useUpdateRegistration(tunnelType)

    useEffect(
        () => {
            step.current.validate()
            if (registration) {
                setMailPlayers(registration.sendEmailToTeamMembers)
            }
        }, [ registration ],
    )

    function changeMailTeamValue() {
        setAjaxLoading(true)
        updateRegistration(
            registration.centerId,
            registration.cup ? registration.cup : registration.league.id,
            registration.teamId,
            registration.cupTeam,
            null,
            registration.id,
            [],
            registration.comments,
            false,
            !mailPlayers,
            registration.captainId,
            registration.viceCaptainId,
        ).then(handleCupResult)
    }

    function handleCupResult(res) {
        if (res) {
            dispatch(
                setFormEntry(
                    "TournamentRegistration",
                    res.data.data,
                ),
            )
            setAjaxLoading(false)
        }
    }

    return (
        <Step
            {...props}
            title="Mon effectif"
            className="noMarginBottom rosterChoice"
            help={ help }
            helpId={"effectifHelp"}
            checkBeforeNextStep={() => {
                addSearchParams("teamComposition", "done")
                dispatch(nextStep())
            }}
            onValidation={() => {	
                dispatch(	
                    setFormEntry("avoirFormData", {
                        ...avoirFormData,
                        recalcAvoir: true,
                    }))
                dispatch(	
                    setFormEntry(	
                        "tunnelTournamentRegistration",
                        {	
                            ...tunnelTournamentRegistration,
                            isNewRegistration: false,
                        },	
                    ),	
                )	
            }}
            ref={step}
        >
            { ajaxLoading && 
                <Preloader fixed={true}/>
            }
            <div className="centerPicker c-row justify-center align-start layoutSmall">
                <div className="c-col c-col--8 c-col--sm--12 flex justify-center align-center wrap formsHolder">
                    <EffectifDisplay
                        tunnelType={tunnelType}
                    />
                </div>
                <div className="c-col c-col--8 c-col--sm--12 flex">
                    <div className="c-inputHolder--radioSquare verticalSpacing">
                        <input
                            type="checkbox"
                            id="sendPlayerEmailSubscription--01"
                            name="sendPlayerEmailSubscription"
                            checked={mailPlayers}
                            readOnly
                            onClick={changeMailTeamValue}
                        />
                        <label htmlFor="sendPlayerEmailSubscription--01">
                            Prévenir mes joueurs par email une fois l’inscription finalisée
                        </label>
                    </div>
                </div>
            </div>
        </Step>
    )
}

ChoixEffectif.propTypes = {
    tunnelType: string,
}

export default ChoixEffectif
