import { bool, func, node, object, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import ButtonCta from "../../../components/Buttons/button/button-cta"
import { cancelConfirmationOverlay, updateConfirmOverlayTargetVar, updateConfirmOverlayVar } from "../../../store/actions/content/index"

const OverlayConfirmationContent = ({
    confirmationOverlay,
    unconfirmAction,
    cancelConfirmationOverlay,
    headerLabel,
    message,
    unconfirmMessage,
    confirmMessage,
    content,
    ajaxCalling,
    container,
}) => {
    const [ localMessage, setLocalMessage ] = useState(null)

    useEffect(() => {
        if (localMessage) {
            const timer = setTimeout(() => {
                setLocalMessage(null)
            }, 4000)
            return () => clearTimeout(timer)
        }
    }, [ localMessage ])

    const confirmationMessage = () => {
        if (localMessage) {
            return <div className="white confirm-overlay-message">{localMessage}</div>
        }
        return ""
    }

    const checkIfMobile = () => {
        return (
            (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 640
        )
    }

    const handleUnconfirmAction = () => {
        if (unconfirmAction !== null) {
            unconfirmAction()
            cancelConfirmationOverlay()
        } else {
            cancelConfirmationOverlay()
        }
    }

    const displayHeaderLabel = () => {
        if (headerLabel) {
            if (checkIfMobile()) {
                return (
                    <div className="header">
                        <div className="white overlay-main-title">{headerLabel}</div>
                    </div>
                )
            } else {
                return <div className="underlined-title header">{headerLabel}</div>
            }
        }
        return ""
    }

    let actionsButtonsClass = ajaxCalling !== true ? "overlay-confirm-action-container" : "overlay-confirm-action-container hidden"
    let displayValidationButton = confirmationOverlay.displayValidationButton ? "" : "disabled"
    let confirmationMsg = confirmationMessage()
    let headerLbl = displayHeaderLabel()

    return (
        <div id="overlay-confirmation-content" data-identifier={confirmationOverlay.identifier} className="content">
            <div id="overlay-content-top">
                {headerLbl}
                <div className={"content" + (headerLbl !== "" ? " hasHeaderTitle" : "")}>
                    {content}
                </div>
            </div>
            {confirmationMsg}
            {message && <div className="white confirm-overlay-message">{message}</div>}
            {ajaxCalling === true && (
                <div className="row">
                    <div className='fixed-preloader'>
                        <div></div>
                    </div>
                </div>
            )}
            <div id="overlay-content-bottom" className="align-flex">
                <div className={actionsButtonsClass}>
                    {confirmMessage && (
                        <ButtonCta
                            disabled={displayValidationButton}
                            text={confirmMessage}
                            className="button-orange overlay-confirm-action"
                            onClick={() => {
                                if (confirmationOverlay.displayValidationButton) {
                                    container?.processCallback ?
                                        container.processCallback(
                                            cancelConfirmationOverlay,
                                            content,
                                            confirmationOverlay,
                                            this) :
                                        cancelConfirmationOverlay()
                                }
                            }}
                        />
                    )}
                    {unconfirmMessage && (
                        <button id="overlay-unconfirm-action"
                            className="button-orange overlay-confirm-action"
                            onClick={handleUnconfirmAction}
                        >{confirmationOverlay.unconfirmMessage ? confirmationOverlay.unconfirmMessage : unconfirmMessage}</button>
                    )}
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    var newPropsObject = {}
    if (null !== state.confirmationOverlay) {
        newPropsObject.confirmationOverlay = state.confirmationOverlay
        newPropsObject.content = state.confirmationOverlay.targetContent
        newPropsObject.message = state.confirmationOverlay.message
        newPropsObject.headerLabel = state.confirmationOverlay.props && state.confirmationOverlay.props.headerLabel ? state.confirmationOverlay.props.headerLabel : ""
        newPropsObject.container = state.confirmationOverlay.targetContainer
        newPropsObject.ajaxCalling = state.ajaxCalling
        newPropsObject.appConfig = state.appConfig
        newPropsObject.unconfirmAction = state.confirmationOverlay.props && state.confirmationOverlay.props.unconfirmAction
            ? state.confirmationOverlay.props.unconfirmAction : null
    }
    return newPropsObject
}

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            cancelConfirmationOverlay: cancelConfirmationOverlay,
            updateConfirmOverlayTargetVar: updateConfirmOverlayTargetVar,
            updateConfirmOverlayVar: updateConfirmOverlayVar,
        },
        dispatch,
    )
}

OverlayConfirmationContent.propTypes = {
    ajaxCalling: oneOfType([ bool, object ]),
    appConfig: object,
    cancelConfirmationOverlay: func,
    confirmMessage: oneOfType([ string, node ]),
    confirmationOverlay: object,
    container: object,
    content: node,
    headerLabel: oneOfType([ string, node ]),
    message: oneOfType([ string, node ]),
    unconfirmAction: func,
    unconfirmMessage: oneOfType([ string, node ]),
}

export default connect(mapStateToProps, dispatchToProps)(OverlayConfirmationContent)
