export const delayNumber = (diffDaysNowTilStart, daysTillStart) => {
    const pluralMinuteWording = Math.trunc(diffDaysNowTilStart * 24 * 60) > 1 ? "s" : ""
    const pluralHourWording = Math.trunc(diffDaysNowTilStart * 24) > 1 ? "s" : ""
    
    const oneHourLess = Math.trunc(diffDaysNowTilStart * 24) === 0 ? // Si moins d'une heure
        (Math.trunc(diffDaysNowTilStart * 24 * 60) + " minute" + pluralMinuteWording)
        :
        (Math.trunc(diffDaysNowTilStart * 24) + " heure" + pluralHourWording)
    
    return Math.trunc(diffDaysNowTilStart) === 0 ? // Si moins d'un jour
        oneHourLess
        :
        daysTillStart.toFixed(0)
} 
