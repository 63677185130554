const getMedicalCertificateButtonText = (subscription) => {
    const status = subscription.validationStatusValue

    switch (status) {
    case 2:
        return "CERTIFICAT MÉDICAL VALIDÉ"
    case 1:
        return "CERTIFICAT MÉDICAL ENVOYÉ"
    case 0:
        return "ENVOYER CERTIFICAT MÉDICAL"
    case -1:
        return "CERTIFICAT MÉDICAL REFUSÉ"
    default:
        return ""
    }
}

const getMedicalCertificateStatus = (subscription) => {
    const status = subscription.validationStatusValue

    switch (status) {
    case 2:
        return "Validé"
    case 1:
        return "En attente de validation"
    default:
        return "En attente de réception"
    }
}

export { getMedicalCertificateButtonText, getMedicalCertificateStatus }
