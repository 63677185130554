import classNames from "classnames"
import PropTypes from "prop-types"
import { default as SelectLib } from "react-select"

import { CustomClearIndicator } from "./InnerComponents/CustomClearIndicator.jsx"
import { CustomControl } from "./InnerComponents/CustomControl.jsx"
import { CustomDropdownIndicator } from "./InnerComponents/CustomDropdownIndicator.jsx"
import { CustomIndicatorsContainer } from "./InnerComponents/CustomIndicatorsContainer.jsx"
import { CustomInput } from "./InnerComponents/CustomInput"
import { CustomMenu } from "./InnerComponents/CustomMenu.jsx"
import { CustomMenuPortal } from "./InnerComponents/CustomMenuPortal.jsx"
import { CustomMultiValue } from "./InnerComponents/CustomMultiValue.jsx"
import { CustomOption } from "./InnerComponents/CustomOption.jsx"
import { CustomPlaceholder } from "./InnerComponents/CustomPlaceholder"
import { CustomSingleValue } from "./InnerComponents/CustomSingleValue.jsx"
import { CustomValueContainer } from "./InnerComponents/CustomValueContainer.jsx"

import "./Select.scss"

export const Select = (props) => {
    return (
        <div className={classNames("select-input-container-root", {
            disabled: props.disabled,
            "fit-content": props.fitContent,
        })}>
            {props.label && (
                <label className="select-label">
                    {props.label}{props.required && (<span className="select-label-required">*</span>)}
                < /label>
            )}
            <SelectLib
                unstyled
                placeholder={props.placeholder}
                isDisabled={props.disabled || props.isLoading}
                noOptionsMessage={() => "Aucune option disponible"}
                closeMenuOnSelect={props.isMulti !== undefined ? !props.isMulti : props.closeMenuOnSelect}
                hasError={!!props.error}
                menuPortalTarget={document.getElementsByClassName("theme")[0]}
                className={"select-input-container"}
                required={props.required}
                components={{
                    ClearIndicator: CustomClearIndicator,
                    Control: CustomControl,
                    DropdownIndicator: CustomDropdownIndicator,
                    IndicatorsContainer: CustomIndicatorsContainer,
                    Input: CustomInput,
                    Menu: CustomMenu,
                    MenuPortal: CustomMenuPortal,
                    MultiValue: CustomMultiValue,
                    Option: CustomOption,
                    Placeholder: CustomPlaceholder,
                    SingleValue: CustomSingleValue,
                    ValueContainer: CustomValueContainer,
                }}
                {...props}
            />
            {props.hintText && !props.error && (
                <small className="select-input-hint-text">{props.hintText}</small>
            )}
            {props.error && (
                <small className="select-input-error">{props.error}</small>
            )}
        </div>
    )
}

Select.propTypes = {
    closeMenuOnSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fitContent: PropTypes.bool,
    hintText: PropTypes.string,
    isLoading: PropTypes.bool,
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
}
