import { func, string } from "prop-types"
import { useDispatch } from "react-redux"

import { useSchoolTrainingCurrent, useSchoolTrainingTunnel } from "../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import { goToStep } from "../../../../store/stepsManagement/actions.js"
import NikeEquipment from "../../../kids/steps/NikeEquipment"

export default function SchoolTrainingEquipement({ theme, updateSearchParams }) {
    const { value: tunnelType } = useSchoolTrainingTunnel()
    const { value: { isAdult, selectedSlot } } = useSchoolTrainingCurrent()
    const dispatch = useDispatch()

    const target = slot => tunnelType === "TRAINING" ? slot : slot?.schoolDetails
    const haveOption = () => {
        return target(selectedSlot)?.optionProducts?.filter(option => !option.showAtPayment)?.length > 0
    }
    let stepNumber = 6
    if (!haveOption()) {
        stepNumber -= 1
    }
    if (isAdult) {
        stepNumber -= 1
    }
    if (!target(selectedSlot)?.catalogProducts?.length) {
        stepNumber = undefined
    } else {
        stepNumber += "/"
    }
    
    if (!tunnelType) {
        dispatch(goToStep(0))
        return null
    }

    return (
        <NikeEquipment
            tunnelType={tunnelType}
            theme={theme}
            updateSearchParams={updateSearchParams}
            numero={stepNumber}
        />
    )
}

SchoolTrainingEquipement.propTypes = {
    theme: string,
    updateSearchParams: func,
}
