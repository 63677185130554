import classNames from "classnames"
import { bool, oneOfType, string } from "prop-types"
import { useState } from "react"

import useMediaPath from "../../hooks/useMediaPath"
import ButtonCta from "../Buttons/button/button-cta"

import "./ShareLink.scss"

export default function ShareLink({ icon, label, shareLink, isAlt, isCta2, customCls }) {
    const [ linkHasBeenCopied, setLinkHasBeenCopied ] = useState(false)
    const mediaPath = useMediaPath()

    const buttonLabel = label ?? "Copier le lien"

    return (
        <ButtonCta
            isAlt={isAlt}
            isCta2={isCta2}
            icon={icon ?? mediaPath([
                "/assets/images/copy-to-clipboard.svg",
                "/assets/images/padel/icons/icon-copy.svg",
                "/assets/icons/business/copy-link-icon.svg",
            ])}
            hoverChangeIcon={mediaPath([
                "/assets/images/copy-to-clipboard-white.svg",
                isCta2 ? "/assets/images/padel/icons/icon-copy.svg" : "/assets/images/padel/icons/icon-copy-green.svg",
                "/assets/icons/business/copy-link-icon.svg",
            ])}
            onClick={ linkHasBeenCopied ? undefined : () => {
                navigator.clipboard.writeText(shareLink)
                setLinkHasBeenCopied(true)
                setTimeout(() => {
                    setLinkHasBeenCopied(false)
                }, 5000)
            }}
            disabled={!shareLink}
            text={linkHasBeenCopied ? "Lien copié ! :)" : buttonLabel}
            className={classNames("shareLink", { blocked: linkHasBeenCopied, [customCls]: customCls })}
        />
    )
}

ShareLink.propTypes = {
    customCls: oneOfType([ bool, string ]),
    icon: oneOfType([ bool, string ]),
    isAlt: bool,
    isCta2: bool,
    label: string,
    shareLink: string,
}
