import { bool, number } from "prop-types"

import Step from "./Step"

export default function Stepper({ activeStep, enableStepFour }) {
    return (
        <div className="stepper">
            <Step isActive={activeStep === 0} isCompleted={activeStep > 0} />
            <Step isActive={activeStep === 1} isCompleted={activeStep > 1} />
            <Step isActive={activeStep === 2} />
            {(activeStep === 3 || enableStepFour) && <Step isActive={activeStep === 3} />}
        </div>
    )
}

Stepper.propTypes = {
    activeStep: number,
    enableStepFour: bool,
}
