import classNames from "classnames"
import { arrayOf, bool, func, node, oneOfType, string } from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import useMediaPath from "../../../hooks/useMediaPath"
import usePath from "../../../routes/services/usePath"
import { toggleShowFooter } from "../../../store/actions/scaffold"
import { resetAuthStepper } from "../../../store/auth/actions/actions"
import BlocRetry from "../../blocRetry/blocRetry"

import "./emailConfirmation.scss"

function EmailConfirmation({
    introText,
    introTextIsBold,
    retryValue,
    onChangeAction,
    retryAction,
    retryComplete,
    isSuccess,
    successOrErrorMsg,
    displaySignup,
    orangeSubText = "Clique sur le lien de validation pour activer ton compte",
}) {
    const mediaPath = useMediaPath()
    const path = usePath()
    const dispatch = useDispatch()

    return (
        <React.Fragment>
            <div className="flex-column align-center">
                { introText &&
                    <div className={introTextIsBold ? "introText introTextBold" : "introText"}>{introText}</div>
                }
                <img className="icon--big" src={mediaPath([ "/assets/icons/e-mail.svg", "/assets/images/padel/icons/e-mail.svg", "/assets/icons/business/e-mail.svg" ])}/>
                <div className="introText introTextBold">Un e-mail de confirmation t'a été envoyé !</div>
                <div className={classNames("importantText", { noMarginBottom: displaySignup })}>{orangeSubText}</div>
                {displaySignup && (
                    <div className="importantText">
                        Si tu n'as reçu aucun e-mail, tu peux créer un compte <Link
                            to={path("/signup")}
                            onClick={() => dispatch(resetAuthStepper())}
                        >ici</Link>.
                    </div>
                )}
            </div>
            
            <BlocRetry
                type="emailAddress"
                value={retryValue}
                onChangeAction={onChangeAction}
                retryAction={retryAction}
                retryActionComplete={retryComplete}
                isSuccess={isSuccess}
                successOrErrorMsg={successOrErrorMsg}
                onFocus={() => dispatch(toggleShowFooter(false))}
                onBlur={() => dispatch(toggleShowFooter(true))}
            />
        </React.Fragment>
    )

}

EmailConfirmation.propTypes = {
    displaySignup: bool,
    introText: oneOfType([ string, node, arrayOf(node) ]),
    introTextIsBold: bool,
    isSuccess: bool,
    onChangeAction: func,
    orangeSubText: oneOfType([ string, node, arrayOf(node) ]),
    retryAction: func,
    retryComplete: bool,
    retryValue: string,
    successOrErrorMsg: oneOfType([ string, node, arrayOf(node) ]),
}

export default EmailConfirmation
