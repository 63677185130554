import classNames from "classnames"
import dayjs from "dayjs"
import { object } from "prop-types"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import APP_CONFIG from "../../business/config"
import useIsMobile from "../../hooks/useIsMobile"
import days from "../../model/enums/dayValues"
import schoolTrainingWaitingList from "../../pages/SchoolTrainingSubscription/functions/schoolTrainingWaitingList"
import CurrencyDisplay from "../currencies/CurrencyDisplay"
import Preloader from "../loaders/preloader/preloader"
import ReservationSlotInfo from "../reservation/slotInfoCard/ReservationSlotInfoCard"
import { useSchoolTrainingCurrent, useSchoolTrainingTunnel } from "../SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import HourDisplay from "../Time/HourDisplay"

import "./SchoolTrainingSlotsCard.scss"

export default function SchoolTrainingSlotsCard({ slot }) {
    const { value: tunnelType } = useSchoolTrainingTunnel()
    const { value: { selectedSlot, stageSubscriptions, schoolSubscriptions, isAdult, selectedKid }, setValue: setCurrentSelection } = useSchoolTrainingCurrent()
    const user = useSelector(state => state.userLoaded)
    const [ alreadySubscribed, setAlreadySubscribed ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(false)
    const isMobile = useIsMobile()
    const navigate = useNavigate()
    const isSessionSchoolType = tunnelType === "SCHOOL" && APP_CONFIG.schoolTypes.session.includes(slot.schoolDetails.typeId)
    const isSubscriptionSchoolType = tunnelType === "SCHOOL" && APP_CONFIG.schoolTypes.subscription.includes(slot.schoolDetails.typeId)

    const handleAlreadySubscribed = () => {
        if ((isAdult
            && ((tunnelType === "SCHOOL" && schoolSubscriptions?.some(sub => sub.school.id === slot.value))
                || (tunnelType === "TRAINING" && stageSubscriptions?.some(sub => sub?.training?.id === slot.id)))
        ) || (!isAdult
            && ((tunnelType === "SCHOOL" && schoolSubscriptions?.some(sub => sub.school.id === slot.value && sub.kid?.id === selectedKid?.id))
                || (tunnelType === "TRAINING" && stageSubscriptions?.some(sub => sub?.training?.id === slot.id && sub.kid?.id === selectedKid?.id)))
        )) {
            setAlreadySubscribed(true)
        } else {
            setAlreadySubscribed(false)
        }
    }

    const handleClick = () => {
        if ((tunnelType === "SCHOOL" && selectedSlot?.value === slot.value) || (tunnelType === "TRAINING" && selectedSlot?.id === slot.id)) {
            setCurrentSelection(prev => ({ ...prev, selectedSlot: undefined }))
        } else {
            setCurrentSelection(prev => ({ ...prev, selectedSlot: slot }))
        }
    }

    useEffect(() => {
        handleAlreadySubscribed()
    }, [ slot, schoolSubscriptions, stageSubscriptions, isAdult, selectedKid, tunnelType ])

    return (
        <>
            {isLoading && <Preloader fixed />}
            {tunnelType && (
                <div
                    className={classNames("schoolTrainingSlotsCard", {
                        disabled: alreadySubscribed || (slot.schoolDetails?.full && !slot.schoolDetails?.waitingListOpened) || (slot?.full && !slot?.waitingListOpened),
                        selected: (tunnelType === "SCHOOL" && selectedSlot?.value === slot.value) || (tunnelType === "TRAINING" && selectedSlot?.id === slot.id),
                    })}
                    data-testid="schoolTrainingCard"
                    onClick={alreadySubscribed || (slot.schoolDetails?.full && !slot.schoolDetails?.waitingListOpened) || (slot?.full && !slot?.waitingListOpened) ? undefined : handleClick}
                >
                    {tunnelType === "SCHOOL" && (
                        <>
                            {isSubscriptionSchoolType ? (
                                <div className="label">{days[slot?.schoolDetails?.day]} <HourDisplay minutes={slot?.schoolDetails?.hour} /> - <HourDisplay minutes={slot?.schoolDetails?.hour + slot?.schoolDetails?.rentalDuration} /></div>
                            ) : (
                                <div className="label">{days[slot?.schoolDetails?.day]} {dayjs(slot.schoolDetails?.start).format("DD/MM/YYYY")}</div>
                            )}
                            {isSessionSchoolType && (
                                <div className="center"> <HourDisplay minutes={slot?.schoolDetails?.hour} /> - <HourDisplay minutes={slot?.schoolDetails?.hour + slot?.schoolDetails?.rentalDuration} /></div>
                            )}
                            <div className="center">{slot?.schoolDetails?.centerName}</div>
                            {isSubscriptionSchoolType && (
                                <div className="center">
                                    {slot?.schoolDetails?.start && slot?.schoolDetails?.end && (
                                        <>
                                            Du {dayjs(slot.schoolDetails.start).format("DD/MM/YYYY")} au {dayjs(slot.schoolDetails.end).format("DD/MM/YYYY")}
                                        </>
                                    )}
                                </div>
                            )}
                            {slot.schoolDetails?.full && !alreadySubscribed && <div>COMPLET</div>}
                            {slot.schoolDetails?.individualCollective !== 0 && !slot.schoolDetails?.full && slot.schoolDetails?.remainingPlaces && slot.schoolDetails?.remainingPlaces < 4 && <div>{slot.schoolDetails.remainingPlaces} {slot.schoolDetails?.remainingPlaces > 1 ? "places restantes" : "place restante"}</div>}
                            {alreadySubscribed && <div>Déjà inscrit</div>}
                            <div className="price">
                                {slot.schoolDetails?.amountTotal > 0 &&  (
                                    <CurrencyDisplay price={slot.schoolDetails?.amountTotal} />
                                )}
                            </div>
                            <div className={classNames("type", { solo: slot.schoolDetails?.individualCollective === 0 })}>{
                                slot.schoolDetails?.individualCollective === 0 ? "individuel" : "collectif"}
                            </div>
                        </>
                    )}
                    {tunnelType === "TRAINING" && (
                        <>
                            <div className="label">{slot.name}</div>
                            <div className="center">{slot?.centerName}</div>
                            <div className="center">
                                {slot?.start && slot?.end && (
                                    <>
                                        Du {dayjs(slot.start).format("DD/MM/YYYY")} au {dayjs(slot.end).format("DD/MM/YYYY")}
                                    </>
                                )}
                            </div>
                            {slot.full && !alreadySubscribed && <div>COMPLET</div>}
                            {slot.individualCollective !== 0 && !slot.full && slot?.remainingPlaces && slot?.remainingPlaces < 4 && <div>{slot.remainingPlaces} {slot?.remainingPlaces > 1 ? "places restantes" : "place restante"}</div>}
                            {alreadySubscribed && <div>Déjà inscrit</div>}
                            <div className="price">
                                {slot.amount > 0 &&  (
                                    <CurrencyDisplay price={slot.amount} />
                                )}
                            </div>
                            <div className={classNames("type", { solo: slot.individualCollective === 0 })}>{
                                slot.individualCollective === 0 ? "individuel" : "collectif"}
                            </div>
                        </>
                    )}
                </div>
            )}
            {isMobile && ((tunnelType === "TRAINING" && selectedSlot?.id === slot.id) || (tunnelType === "SCHOOL" && selectedSlot?.value === slot.value)) && (
                <div
                    className="c-col c-col--3 c-col--sm--12 tooltip-description active"
                    id={"description-card-" + (slot?.id || slot?.value)}
                    key={"description-card-" + (slot?.id || slot?.value)}
                >
                    <ReservationSlotInfo
                        reservationSlot={
                            tunnelType === "TRAINING" ?
                                selectedSlot
                                :
                                slot?.schoolDetails
                        }
                        tunnelType={tunnelType}
                        subscribeToWaitingListCallback={() => schoolTrainingWaitingList(user, selectedKid, tunnelType, setIsLoading, navigate, selectedSlot, isAdult)}
                        slotName={tunnelType === "TRAINING" ? "stage " : "cours "}
                        pleaseSelectText={
                            tunnelType === "TRAINING" ?
                                "ton stage"
                                :
                                "ton cours"
                        }
                    />
                </div>
            )}
        </>
    )
}

SchoolTrainingSlotsCard.propTypes = {
    slot: object,
}
