import {
    cancelPreregisterLeagueSubscription,
    cancelStageRegistration,
} from "../../../../globalAPI/api"
import { cancelSchoolRegistration } from "../../../kids/ecoleDeFoot/api.js"
import { cancelCupPreregistration } from "../../../teams/urbanCup/api"

function cancelPreRegistration(
    tunnelType,
    setAjaxLoading,
    subscription,
    navigate,
    path,
    secondSubscription,
) {
    setAjaxLoading(true)
    const lastUniverse = localStorage.getItem("lastUniverse")
    switch (tunnelType) {
    case "SCHOOL":
        Promise.all([
            cancelSchoolRegistration(subscription.id),
            ...(secondSubscription ? [ cancelSchoolRegistration(secondSubscription.id) ] : []),
        ]).then(
            () => {
                navigate(path("/confirmcommand"), {
                    state: {
                        confirmedSubscription: subscription,
                        message: lastUniverse === "theme-padel" ? "Nous te confirmons avoir annulé ta pré-inscription." : "Nous vous confirmons avoir annulé votre pré-inscription.",
                        success: true,
                        title: "Pré-inscription annulée",
                        tunnelType: "SCHOOL",
                    },
                })
            },
        )
        break

    case "CUP":
        cancelCupPreregistration(subscription.id).then(
            () => {
                navigate(path("/confirmcommand"), {
                    state: {
                        confirmedSubscription: subscription,
                        isCanceled: true,
                        message: "Nous te confirmons avoir annulé ta pré-inscription.",
                        subtitle: "",
                        success: true,
                        title: "Pré-inscription annulée",
                        tunnelType: "CUP",
                    },
                })
            },
        )
        break

    case "TRAINING":
        cancelStageRegistration(subscription.id).then(
            () => {
                navigate(path("/confirmcommand"), {
                    state: {
                        confirmedSubscription: subscription,
                        isCanceled: true,
                        message: lastUniverse === "theme-padel" ? "Nous te confirmons avoir annulé ta pré-inscription." : "Nous vous confirmons avoir annulé votre pré-inscription.",
                        subtitle: "",
                        success: true,
                        title: "Pré-inscription annulée",
                        tunnelType: "TRAINING",
                    },
                })
            },
        )
        break

    case "LEAGUE":
        cancelPreregisterLeagueSubscription(subscription.id).then(
            () => {
                navigate(path("/confirmcommand"), {
                    state: {
                        confirmedSubscription: subscription,
                        isCanceled: true,
                        message: "Nous te confirmons avoir annulé ta pré-inscription.",
                        subtitle: "",
                        success: true,
                        title: "Pré-inscription annulée",
                        tunnelType: "LEAGUE",
                    },
                })
            },
        )
        break
        
    case "BOOKING":
        // Not concerned
        break

    default:
        // eslint-disable-next-line no-console
        console.error("cancelPreRegistration - Not implemented tunnelType : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        break
    }
}

export default cancelPreRegistration
