import { _apiCall } from "../../../backend/axios"

export function getSuperSubsRequestResponses(requestId, offset = 0, pagesize = 3) {
    let baseUrl = "read/supersubs/requests/" + requestId + "/responses" + "?offset=" + offset + "&pagesize=" + pagesize

    return  _apiCall(
        baseUrl,
        "GET",
    ).then(
        (res) => {
            return res?.data?.data ?
                res.data.data
                :
                null
            
        },
    ).catch((err) => {
        return err
    })
}

export function offerSubersubPlace(id, returnUrl, withAlias) {
    return  _apiCall(
        "read/supersubs/" + id + "/offer-place",
        "POST",
        {
            "Content-Type" : "application/json",
        },
        {
            returnUrl: returnUrl,
            withAlias,
        },
    ).then(
        (res) => {
            return res?.data?.data ? res.data.data : null
        },
    ).catch((err) => {
        return err
    })
}

export function acceptSupersubReponse(id) {
    return  _apiCall(
        "modify/supersubs/responses/" + id + "/accept",
        "PUT",
    ).then(
        (res) => {
            return res?.data?.data ? res.data.data : null
        },
    ).catch((err) => {
        return err
    })
}

export function refuseSupersubReponse(id) {
    return  _apiCall(
        "modify/supersubs/responses/" + id + "/refuse",
        "PUT",
    ).then(
        (res) => {
            return res?.data?.data ? res.data.data : null
        },
    ).catch((err) => {
        return err
    })
}

export function completeSuperSubRequest(id) {
    return _apiCall(
        `modify/supersubs/requests/${id}/complete`,
        "PUT",
    )
}
