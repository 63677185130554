import PropTypes from "prop-types"

import "./ThreeDotsToggle.scss"

export const ThreeDotsToggle = ({ active, onClick }) => {
    return (
        <div
            onClick={(e) => {
                onClick?.(e)
            }}
            className={
                "three-dots" + (active ?
                    " active"
                    :
                    ""
                )
            }
        >
            <div className="dot"></div>
        </div>
    )
}

ThreeDotsToggle.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
}
