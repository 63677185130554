import classNames from "classnames"
import { bool, func, object, oneOfType, string } from "prop-types"
import { forwardRef } from "react"
import PhoneInput from "react-phone-input-2"
import { useSelector } from "react-redux"

import { SelectInput } from "../../../../../components/Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../../../../components/Inputs/TextInput/TextInput.jsx"
import phoneCountries from "../../../../../model/enums/phoneCountries"
import { PadelLevelArray } from "../../../../../model/enums/User/PadelLevel"

import "./PlayerCard.scss"

const PlayerCard = forwardRef(({ error, removePlayer, player, setPlayer, onlyLicense, licenseAlert, disabled, required, openPadelLevel }, ref) => {
    const selectedCup = useSelector(state => state.forms.selectedPadelCup)

    return (
        <div className={classNames("playerCard", { onlyLicense: onlyLicense })}>
            {disabled && removePlayer &&  (
                <img
                    src="/assets/images/padel/icons/cross-close.svg"
                    className="remove"
                    onClick={removePlayer}
                />
            )}
            {error && <div className="error" ref={ref}>{error}</div>}
            <h2>{`${!player?.firstname && !player?.lastname ? "Mon partenaire" : ""}${player?.firstname} ${player?.lastname ? player.lastname.charAt(0) + "." : ""}`}</h2>
            <TextInput
                label="Nom"
                value={player.lastname}
                onChange={(val) => setPlayer(prev => ({ ...prev, lastname: val }))}
                disabled={onlyLicense || disabled}
                required={required}
            />
            <TextInput
                label="Prénom"
                value={player.firstname}
                onChange={(val) => setPlayer(prev => ({ ...prev, firstname: val }))}
                disabled={onlyLicense || disabled}
                required={required}
            />
            <TextInput
                label="Adresse email"
                value={player.emailAddress}
                onChange={(val) => {
                    setPlayer(prev => ({ ...prev, emailAddress: val.replace(/\s+/g, "") }))
                }}
                disabled={onlyLicense || disabled}
                required={required}
                error={error === "Email blacklisté"}
            />
            <PhoneInput
                containerClass={classNames({
                    inputError: error === "Téléphone manquant ou incorrect",
                    onlyLicense: onlyLicense || disabled,
                    required: required || onlyLicense,
                })}
                placeholder="Téléphone mobile"
                country={"fr"}
                disabled={onlyLicense || disabled}
                inputProps={{
                    required: required,
                }}
                onlyCountries={phoneCountries}
                enableTerritories
                value={player.phoneNumber}
                onChange={
                    (phone, data) => {
                        const phoneHasDial = phone.includes(data.dialCode)
                        if (phoneHasDial) {
                            setPlayer(prev => ({ ...prev, phoneNumber: phone }))
                        } else {
                            if (phone.length <= data.countryCode.length) {
                                setPlayer(prev => ({ ...prev, phoneNumber: phone.replace(/\D|^0+/g, "") }))
                            } else {
                                setPlayer(prev => ({ ...prev, phoneNumber: data.dialCode + phone.replace(/\D|^0+/g, "") }))
                            }
                        }
                    }
                }
                specialLabel={<>Téléphone<span className="required"> *</span></>}
            />
            {selectedCup && selectedCup.category === 2 && (
                <TextInput
                    label="Numéro de licence"
                    required={onlyLicense ? required : (player ? true : required) }
                    value={player.licenseNumber ?? ""}
                    onChange={(val) => setPlayer(prev => ({ ...prev, licenseNumber: val ? val.replace(/[^A-Za-z0-9]/g, "") : "" }))}
                    disabled={disabled}
                />
            )}
            {selectedCup && selectedCup.category === 1 && (
                <>
                    <SelectInput
                        id={"level"}
                        options={PadelLevelArray}
                        value={player.level ?? ""}
                        onChange={(e) => setPlayer(prev => ({
                            ...prev, level: e?.target?.value,
                        }))}
                        label="Niveau"
                        disabled={disabled}
                    />
                    {!disabled && (
                        <div
                            className="showLevel"
                            onClick={openPadelLevel}
                        >
                            Voir les niveaux
                        </div>
                    )}
                </>
            )}
            {licenseAlert && (
                <div className="licenseAlert">
                    <img src="/assets/images/padel/icons/icons-info.svg" />
                    <span>Ton coéquipier n'a pas renseigné son numéro de licence, il est obligatoire pour participer au tournoi. Il doit le renseigner sans attendre sur son profil MyUrban.</span>
                </div>
            )}
        </div>
    )
})

PlayerCard.displayName = "PlayerCard"
PlayerCard.propTypes = {
    disabled: bool,
    error: oneOfType([ string, bool ]),
    licenseAlert: bool,
    onlyLicense: bool,
    openPadelLevel: func,
    player: object,
    removePlayer: func,
    required: bool,
    setPlayer: func,
}

export default PlayerCard
