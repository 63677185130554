export default function getBaseNameUrl(theme) {
    switch (theme) {
    case "theme-soccer":
    default:
        return ("")

    case "theme-padel":
        return ("/padel")
    }
}
