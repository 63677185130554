import ListingSchool from "../../pages/kids/ecoleDeFoot/listingSchool"
import ListingStage from "../../pages/kids/stageDeFoot/listingStage"
import SchoolTrainingSubscription from "../../pages/SchoolTrainingSubscription/SchoolTrainingSubscription"

const RoutesSchoolTraining = [
    {
        element: (<ListingSchool />),
        path: "/padel/cours",
    },
    {
        element: (<ListingSchool />),
        path: "/padel/cours/*",
    },
    {
        element: (<SchoolTrainingSubscription />),
        path: "/padel/coursStages/inscription",
    },
    {
        element: (<ListingStage />),
        path: "/padel/stages",
    },
    {
        element: (<ListingStage />),
        path: "/padel/stages/*",
    },
]

export default RoutesSchoolTraining
