import { Navigate } from "react-router-dom"

import ConfirmationCmd from "../../entries/main/contents/main/helpers/ConfirmationCmd.jsx"
import { getUsCenters } from "../../globalAPI/api.js"
import { BusinessLeagueTunnelBaseLayout } from "../../pages/businessLeagueTunnel/BusinessLeagueTunnelBaseLayout.jsx"
import { BusinessLeagueTunnelStepLayout } from "../../pages/businessLeagueTunnel/BusinessLeagueTunnelStepLayout.jsx"
import { Captain } from "../../pages/businessLeagueTunnel/steps/Captain.jsx"
import { Communication } from "../../pages/businessLeagueTunnel/steps/Communication.jsx"
import { General } from "../../pages/businessLeagueTunnel/steps/General.jsx"
import { Preferences } from "../../pages/businessLeagueTunnel/steps/Preferences.jsx"
import { BusinessLeagueTunnelPaths } from "../ApplicationPaths/BusinessLeagueTunnelPaths.js"

export const RoutesBusinessLeagueTunnel = [
    {
        children: [
            {
                element: <General/>,
                path: BusinessLeagueTunnelPaths.stepGeneral,
            },
            {
                element: <Captain/>,
                path: BusinessLeagueTunnelPaths.stepCaptain,
            },
            {
                element: <Communication/>,
                path: BusinessLeagueTunnelPaths.stepCommunication,
            },
            {
                element: <Preferences/>,
                path: BusinessLeagueTunnelPaths.stepPreferences,
            },
            {
                element: <Navigate to={BusinessLeagueTunnelPaths.stepGeneral} replace />,
                path: "",
            },
        ],
        element: <BusinessLeagueTunnelStepLayout/>,
        id: "businessLeagueTunnel",
        loader: async () => {
            const { data : centersData } = await getUsCenters(3)

            return centersData?.map(center => ({
                label: center.name,
                value: center.id,
            }))
        },
        path: BusinessLeagueTunnelPaths.basePath,
    },
    {
        element: (<BusinessLeagueTunnelBaseLayout><ConfirmationCmd /></BusinessLeagueTunnelBaseLayout>),
        path: BusinessLeagueTunnelPaths.stepConfirmation,
    },
]
