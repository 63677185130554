import React, { useEffect, useState } from "react"

import { getCookie } from "../services/cookies.js"

export const CookieContext = React.createContext(null)

// eslint-disable-next-line react/prop-types
const CookieProvider = ({ children }) => {
    const [ isWebview, setIsWebview ] = useState(false)
    const [ isAppV2, setIsAppV2 ] = useState(false)

    useEffect(() => {
        setIsWebview(!!getCookie("webview"))
        setIsAppV2(!!getCookie("appV2"))
    }, [])

    return (
        <CookieContext.Provider value={{ isAppV2, isWebview }}>
            {children}
        </CookieContext.Provider>
    )
}

export default CookieProvider
