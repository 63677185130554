import classNames from "classnames"
import { bool, number, object, oneOfType, string } from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useStore } from "react-redux"

import CurrencyDisplay from "../../../../../components/currencies/CurrencyDisplay"
import Preloader from "../../../../../components/loaders/preloader/preloader"
import { getAvoirAmount } from "../../../../../globalAPI/api"
import { setFormEntry } from "../../../../../store/forms/actions"

import "./AvoirCheckbox.scss"

function AvoirCheckbox({ totalAmount, tva, formName, isPreregistration, customActionMessage = null, disabled = false, targetDate }) {
    /* VARIABLES */
    const dispatch = useDispatch()
    /* STATE */
    const [ isLoading, setIsLoading ] = useState(false)
    const [ checkAvoir, setCheckAvoir ] = useState(false)
    const [ useAllAvoir, setUseAllAvoir ] = useState(false)
    const [ avoirAmount, setAvoirAmount ] = useState(null)
    const [ avoirAmountDisplay, setAvoirAmountDisplay ] = useState(null)
    const [ rapportTva, setRapportTva ] = useState(null)
    const [ , updateState ] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    /* STORE */
    const store = useStore()
    const formStored = store.getState().forms[formName] !== undefined ?
        store.getState().forms[formName]
        : {}

    /* ACTIONS */
    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }

    /* EFFECTS */
    useEffect(
        () => {
            if (store.getState().forms[formName] === undefined) {
                setFormInStore(formName, {
                })
            }

            // get check avoir value from store
            if (formStored?.checkAvoir !== undefined) {
                setCheckAvoir(formStored.checkAvoir)
            }

            if (totalAmount && tva && totalAmount !== 0 && tva !== 0) {
                setRapportTva(totalAmount / tva)
            }

            setIsLoading(true)
            
            getAvoirAmount(targetDate).then((avoirAmountRes) => {
                setAvoirAmount(avoirAmountRes)
                reCalculateAvoir(avoirAmountRes)
                setIsLoading(false)
            })
        }, [])

    useEffect(() => {
        setCheckAvoir(formStored?.checkAvoir)
    }, [ formStored?.checkAvoir ])

    useEffect(
        () => {
            if (totalAmount && tva && totalAmount !== 0 && tva !== 0) {
                if (rapportTva === null || rapportTva === undefined || isNaN(rapportTva)) {
                    setRapportTva(totalAmount / tva)
                }
            }
        }, [ totalAmount, tva ],
    )

    /* FUNCTIONS */
    function reCalculateAvoir(avoirAm, isReturnVal) {     
        let calc
        let useAll = false

        // check avoir amount
        if (avoirAm <= totalAmount) {
            calc = avoirAm
            useAll = true

        } else {
            if (!checkAvoir) {
                calc = avoirAm
            } else {
                calc = totalAmount > 0 ? totalAmount : (avoirAm - totalAmount)
            }
        }

        if (formStored?.recalcAvoir) {
            if (checkAvoir && avoirAm !== undefined && avoirAm !== null) { // some test to avoid Infinity errors
                let newTotal = avoirAm >= totalAmount ? 0 : totalAmount - avoirAm
                newTotal = newTotal < 0 ? 0 : newTotal
                const newTva = newTotal > 0 ? newTotal / rapportTva : 0
        
                setFormInStore(formName, {
                    ...formStored,
                    avoirAmount: avoirAmountDisplay,
                    checkAvoir: checkAvoir,
                    recalcAvoir: false,
                    totalWithAvoir: newTotal,
                    tvaWithAvoir: newTva,
                })
            } 
        }

        if (isReturnVal) {
            if (calc !== avoirAmountDisplay) {
                setUseAllAvoir(useAll)
                setAvoirAmountDisplay(calc)
            }
            if (useAll !== useAllAvoir) {
                setUseAllAvoir(useAll)
            }
            return calc
        } else {
            setUseAllAvoir(useAll)
            setAvoirAmountDisplay(calc)
        }
    }

    function handleAvoir() {
        if (store.getState().forms[formName] !== undefined) {
            if (!checkAvoir) { // so it will be changed to true -> so apply avoir   

                let calc = null
                // check avoir amount
                if (avoirAmount <= totalAmount) {
                    calc = avoirAmount
                } else {
                    calc = totalAmount
                }
                
                let newTotal = totalAmount > 0 ? totalAmount - avoirAmountDisplay : 0
                newTotal = newTotal < 0 ? 0 : newTotal
                const newTva = newTotal > 0 ? newTotal / rapportTva : 0
        
                setFormInStore(formName, {
                    ...formStored,
                    avoirAmount: calc,
                    checkAvoir: !checkAvoir,
                    totalWithAvoir: newTotal,
                    tvaWithAvoir: newTva,
                })
            } else if (checkAvoir && formStored.totalWithAvoir !== undefined) {
                setFormInStore(formName, {
                    ...formStored,
                    //put this field to undefined in order to take OrderSummary tva value
                    avoirAmount: undefined, 
                    
                    //put this field to undefined in order to not apply avoir amount in OrderSummary,
                    checkAvoir: !checkAvoir, 
                    
                    totalWithAvoir: undefined, 
                    //put this field to undefined in order to take OrderSummary total value
                    tvaWithAvoir: undefined,
                })
            }
        }
        setCheckAvoir(!checkAvoir)
    }

    const remainingAmount = avoirAmount - (reCalculateAvoir(avoirAmount, true))

    return (
        <React.Fragment>
            {   
                isLoading && 
                    <Preloader fixed={true}/>
            }
            {
                !isLoading && avoirAmount > 0 &&
                    (<div className={
                        isPreregistration ? "c-inputHolder--checkbox avoir-checkbox checkBoxNoBorder"
                            : "c-inputHolder--checkbox avoir-checkbox"
                    }>
                        <input type="checkbox" id="cartAvoir" checked={!!checkAvoir} onChange={() => handleAvoir()} disabled={disabled}/>
                        <label className="avoir-label">
                            <span className="text-side">
                                <span className="title">Mon avoir</span>
                                {
                                    !checkAvoir &&
                                        <span className="subtitle">Montant disponible sur mon avoir</span>
                                }
                                {
                                    checkAvoir &&
                                        (<React.Fragment>
                                            {
                                                customActionMessage ?
                                                    <span className="subtitle">{customActionMessage}</span>
                                                    : (useAllAvoir ?
                                                        <span className="subtitle">J’utilise la totalité de mon avoir</span>
                                                        :
                                                        <span className="subtitle">Je n'utilise qu'une partie de mon avoir</span>)
                                            }
                                        </React.Fragment>)
                                }
                            
                            </span>
                            <span className={classNames("price-side", {
                                disabled,
                            })}>
                                {
                                    useAllAvoir ?
                                        <CurrencyDisplay price={reCalculateAvoir(avoirAmount, true)}/>
                                        :
                                        (<span className="priceWithSubPrice">                    
                                            <span className="price"><CurrencyDisplay price={avoirAmount}/></span>
                                            { checkAvoir && 
                                                (<span className="montantRestant">
                                                    <CurrencyDisplay price={remainingAmount}/>
                                                    {
                                                        remainingAmount > 1 ?
                                                            <span>&nbsp;restants</span>
                                                            :
                                                            <span>&nbsp;restant</span>
                                                    }
                                                </span>)
                                            }
                                        </span>)
                                }
                            </span>
                        </label>
                    </div>)
            }
        </React.Fragment>
    )
}

AvoirCheckbox.propTypes = {
    customActionMessage: string,
    disabled: oneOfType([ bool, object ]),
    formName: string,
    isPreregistration: oneOfType([ bool, object ]),
    targetDate: string,
    totalAmount: number,
    tva: oneOfType([ number, string ]),
}

export default AvoirCheckbox
