import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import DebtBillDisplay from "./components/DebtBillDisplay"
import ButtonCta from "../../components/Buttons/button/button-cta"
import PageHeader from "../../components/layout/pageHeader/PageHeader"
import Preloader from "../../components/loaders/preloader/preloader"
import PayFreeAmount from "../../components/reservation/PayFreeAmount/PayFreeAmount"
import { useTheme } from "../../components/ThemeContext/ThemeContext"
import { getCommands, getFactures, getUsCenters } from "../../globalAPI/api"
import useProceedCheckout from "../../hooks/reservation/useProceedCheckout"
import useMediaPath from "../../hooks/useMediaPath"
import useResetScroll from "../../hooks/useResetScroll"
import usePath from "../../routes/services/usePath.js"

import "./Bill.scss"

const Bill = () => {
    useResetScroll()

    const [ isPaymentPopinOpen, setIsPaymentPopinOpen ] = useState(false)
    const [ centers, setCenters ] = useState([])
    const [ bills, setBills ] = useState([])
    const [ paidBills, setPaidBills ] = useState([])
    const [ billsToPay, setBillsToPay ] = useState([])
    const [ isLoading, setIsLoading ] = useState(true)
    const [ billsToDisplay, setBillsToDisplay ] = useState(9)
    const [ paidBillsCount, setPaidBillsCount ] = useState(0)
    const [ billToPay, setBilltoPay ] = useState({})
    const [ freeAmount, setFreeAmount ] = useState("")
    const [ notPaidBillsCount, setNotPaidBillsCount ] = useState(0)
    const mediaPath = useMediaPath()
    const path = usePath()
    const theme = useTheme()
    const amount = freeAmount === "" ?
        billToPay.amountTTC - billToPay.paidAmount
        :
        freeAmount

    const {
        proceedCheckout,
    } = useProceedCheckout(
        {
            customData: {
                amount,
                resourceId: billToPay.id,
                serviceType: "BILL",
                vads_order_info: "Facture #" + billToPay.id,
                vads_return_url: import.meta.env.VITE_URL_BASE
                    + "/?action=payzenPayment&type=BILL&theme=" + theme,
            },
            lyraPaymentUrl: path(`/profil/payment/bill/${billToPay.id}`),
            redirectToListing: false,
            tunnelType: "BILL",
        },
    )
    const user = useSelector(state => state.userLoaded)

    const getMinAmount = () => {
        const minAmount = billToPay.amountTTC / 10
        const amountDue = billToPay.amountTTC - billToPay.paidAmount
        const compareMinAmountAmountDue = minAmount < amountDue
        if (billToPay.serviceType === 3 || billToPay.serviceType === 1 || billToPay.serviceType === 6 || billToPay.serviceType === 50 || billToPay.splitable) {
            if (compareMinAmountAmountDue && minAmount) {
                return minAmount?.toFixed(2)
            } else {
                return amountDue
            }
        }
    }

    const dateSort = (a, b) => {
        const startDateA = a.startDate.split("/").reverse().join("-")
        const startDateB = b.startDate.split("/").reverse().join("-")
        return startDateA === startDateB ?
            0
            :
            startDateA < startDateB ?
                1
                :
                -1
    }

    const groupBillsByYears = billsArray => {
        const billsYears = []
        const yearSortedBills = []
        billsArray.forEach(bill => {
            const year = bill.startDate.split("/")[2]
            const findYearIndex = billsYears.findIndex(y => y === year)
            if (findYearIndex === -1) {
                billsYears.push(year)
                yearSortedBills.push([ bill ])
            } else {
                const tempBills = yearSortedBills[findYearIndex]
                yearSortedBills[findYearIndex] = [ ...tempBills, bill ]
            }
        })
        return billsYears.map((year, index) => [ year, yearSortedBills[index] ])
    }

    const paidBillsToDisplay = () => {
        let billsCount = 0
        return paidBills.map(
            year => {
                if (billsCount <= billsToDisplay) {
                    return (
                        <div
                            className="year"
                            key={year[0]}
                        >
                            <h3>{year[0]}</h3>
                            {year[1].map((bill) => {
                                if (billsCount <= billsToDisplay) {
                                    billsCount += 1
                                    let centerName
                                    if (bill.centerId !== 1 && bill.centerId !== 40 && centers && centers.length > 0 && centers.find(center => center.id === bill.centerId)) {
                                        centerName = centers.find(center => center.id === bill.centerId).name
                                    } else {
                                        if (bill.centerId === 1) {
                                            centerName = "Autres Sites"
                                        }
                                        if (bill.centerId === 40) {
                                            centerName = "UrbanStore"
                                        }
                                    }

                                    return (
                                        <DebtBillDisplay
                                            key={bill.id + bill.startDate + bill.centerId}
                                            item={bill}
                                            centerName={centerName}
                                            tunnelType="BILL"
                                        />
                                    )
                                }
                            })}
                        </div>
                    )
                }
            },
        )
    }
    
    useEffect(
        () => {
            getUsCenters(true).then(
                (res) => {
                    if (res && res.data) {
                        setCenters(res.data)
                    }
                },
            )
            //get ardoise
            getFactures().then(
                res => {
                    getCommands().then(resCmd => {
                        const commands = resCmd ? resCmd.map(command => ({
                            amountTTC: command.amountTTC,
                            centerId: command.center.id,
                            code: command.code,
                            contactId: user.contactId,
                            id: command.id,
                            isCommand: true,
                            paidAmount: command.paidAmount,
                            serviceType: 1,
                            serviceTypeDisplay: `Location${command?.activity ? command.activity === 2 ? " Padel" : " Soccer" : "" }`,
                            startDate: dayjs(command.date).format("DD/MM/YYYY"),
                            status: command.amountTTC === command.paidAmount ? 20 : 0,
                            statusDisplay: command.amountTTC === command.paidAmount ? "Réglée" : "A régler",
                            type: 1,
                            typeDisplay: "Commande",
                        })) : []
                        const bills = res ?? []
                        const allItems = commands.concat(bills)
                        
                        const typesToExclude = [ 19, 20, 24, 28, 29, 30, 34, 36, 37, 39, 41, 43, 99 ]
                        const filteredBill = allItems.filter(bill => !typesToExclude.includes(bill.serviceType))
                            .sort(dateSort)
                        const paid = filteredBill.filter(bill => bill.status === 20)
                        const notPaid = filteredBill.filter(bill => bill.status === 0)
                        setPaidBillsCount(paid.length)
                        setNotPaidBillsCount(notPaid.length)
                        setBills(filteredBill)
                        setPaidBills(groupBillsByYears(paid))
                        setBillsToPay(groupBillsByYears(notPaid))
                        setIsLoading(false)
                    })
                },
            )
        }, [],
    )

    return (
        <div className="bill">
            <PageHeader
                title={"Factures"}
                goBack={false}
            />
            <div className="c-row">
                <div className="c-col c-col--10 c-col--sm--12 no-padding ">
                    {isLoading && <Preloader />}
                    {!isLoading && bills.length > 0 && centers && centers.length > 0 && (
                        <div className="billsListing">
                            {billsToPay.length > 0 && (
                                <div className="notPaid">
                                    <h2>À RÉGLER ({notPaidBillsCount})</h2>
                                    {billsToPay.map(
                                        year => (
                                            <div key={"billToPaid" + year[0]} className="year">
                                                <h3>{year[0]}</h3>
                                                {year[1].map(bill => (
                                                    <DebtBillDisplay
                                                        key={bill.id + bill.startDate + bill.centerId}
                                                        item={bill}
                                                        centerName={
                                                            bill.centerId === 1 ?
                                                                "Autres sites"
                                                                :
                                                                bill.centerId === 40 ?
                                                                    "UrbanStore"
                                                                    :
                                                                    centers && centers.length > 0 && centers.find(center => center.id === bill.centerId) ?
                                                                        centers.find(center => center.id === bill.centerId).name
                                                                        :
                                                                        "Autres sites"
                                                        }
                                                        tunnelType="BILL"
                                                        setBilltoPay={bill => setBilltoPay(bill)}
                                                        isPaymentPopInOpen={isPaymentPopinOpen}
                                                        setPaymentPopInOpen={setIsPaymentPopinOpen}
                                                        freeAmount={freeAmount}
                                                    />
                                                ))}
                                            </div>
                                        ),
                                    )}
                                </div>
                            )}
                            {paidBills.length > 0 && (
                                <div className="paidBills">
                                    <h2>RÉGLÉE{paidBills.length > 1 ? "S" : ""}</h2>
                                    {paidBillsToDisplay()}
                                    {paidBillsCount > billsToDisplay + 1 && (
                                        <ButtonCta
                                            isAlt={true}
                                            text="Afficher les factures précédentes"
                                            className="displayOlderBills"
                                            onClick={() => setBillsToDisplay(billsToDisplay + 10)}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    {!isLoading && bills.length === 0 && (
                        <div className="noBill">
                            <img alt="" src={mediaPath([ "/assets/images/noBill.svg", "/assets/images/padel/icons/icons-not-bill.svg" ])} />
                            <p>Tu n'as aucune facture.</p>
                        </div>
                    )}
                </div>
            </div>
            {billsToPay && (
                <PayFreeAmount
                    registration={
                        {
                            amountTotal: billToPay.amountTTC,
                            centerName: billToPay.centerName,
                            dueAmount: billToPay.amountTTC - billToPay.paidAmount,
                            id: billToPay.id,
                        }
                    }
                    billMinAmount={getMinAmount()}
                    setAmountToPay={setFreeAmount}
                    paiementCallback={proceedCheckout}
                    setIsOpen={setIsPaymentPopinOpen}
                    isOpen={isPaymentPopinOpen}
                    tunnelType="BILL"
                />
            )}
        </div>
    )
}

export default Bill
