function locationDurationsForType(type) {
    if (type === "Squash") {
        return [
            {
                display: "0h45",
                value: 45,
            }, {
                display: "1h30",
                value: 90,
            }, {
                display: "2h00",
                value: 120,
            },
        ]
    } else {
        return [
            {
                display: "1h00",
                value: 60,
            }, {
                display: "1h30",
                value: 90,
            }, {
                display: "2h00",
                value: 120,
            },
        ]
    }
        
}

export default locationDurationsForType
