import formatTwoDigits from "../../services/formatTwoDigits"

function HourDisplay({ minutes, date, displayZero = false }) {
    function displayMinutes(minutes) {
        return (formatTwoDigits(minutes) === "00" && !displayZero) ? //Partie des minutes
            "" 
            : 
            formatTwoDigits(minutes)
    }
    return (
        minutes !== undefined ?
            Math.floor(minutes / 60) + "h" + // Partie des heures 
            displayMinutes(minutes % 60)
            :
            date ? 
                (
                    displayZero ?
                        formatTwoDigits(date.getHours()) // Heures
                        :
                        date.getHours()
                ) + "h" +  displayMinutes(date.getMinutes()) // Minutes
                :
                "Erreur paramètres incorrects"
    )
}

export default HourDisplay
