import classNames from "classnames"
import { bool, func, number, object, oneOfType, string } from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"

import CurrencyDisplay from "../../../components/currencies/CurrencyDisplay"
import Preloader from "../../../components/loaders/preloader/preloader"
import Modal from "../../../components/Modal/Modal"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import ShopCartEquipmentLine from "../../../components/reservation/Shop/ShopCartEquipmentLine/ShopCartEquimentLine"
import ShopProduct from "../../../components/reservation/Shop/ShopProduct/ShopProduct"
import Step from "../../../components/stepsManagement/Step"
import { getCookie } from "../../../services/cookies"
import UserAgentHandler from "../../../services/UserAgentHandler"
import { addProductToCart } from "../../../store/cart/actions"
import { setFormEntry } from "../../../store/forms/actions"
import { skipStep } from "../../../store/stepsManagement/actions"
import { getBirthdayCatalogDiscount } from "../birthdayReservation/api"
import CreneauDispo from "../birthdayReservation/steps/CreneauxDispo"
import { getKidsSchoolDetail } from "../ecoleDeFoot/api"
import CreneauxDispo from "../ecoleDeFoot/steps/CreneauxDispo"
import { getKidsStageDetail } from "../stageDeFoot/api"

import "./NikeEquipment.scss"

class NikeEquipment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            SubTotal: 0,
            cartDisplay: false,
            catalogDiscounts: undefined,
            isLoading: false,
            missingInfoOpen: false,
            missingLabel: "",
            oldZIndex: null,
            productZoomOpen: false,
            zoomContent: null,
        }
        this.openCartSmall = this.openCartSmall.bind(this)
        this.closeCartSmall = this.closeCartSmall.bind(this)
    }

    componentDidMount() {
        document.querySelector("#content").scrollTo(0, 0)
        document.querySelector("html").scrollTo(0, 0)
        window.scrollTo(0, 0)
        const { forms, setFormInStore, addProductToCart, cart, tunnelType } = this.props
        const formatedAvailableEquipment = {}

        switch (tunnelType) {
        case "BIRTHDAY": {
            const formCreneauDispo = this.props.forms[CreneauDispo.formName] !== undefined ? 
                this.props.forms[CreneauDispo.formName]
                : {}

            if (formCreneauDispo?.birthdaySubscription?.id) {

                this.setState({
                    isLoading : true,
                })

                getBirthdayCatalogDiscount(formCreneauDispo.birthdaySubscription.birthdayConfig.id).then((res) => {
                    if (res.data.data.length >= 1) {
                        this.setState({
                            catalogDiscounts : res.data.data[0],
                        })
                    }

                    formCreneauDispo.birthdaySubscription.birthdayConfig.catalogProducts.forEach(
                        element => {
                            formatedAvailableEquipment[element.id] = element
                        },
                    )
        
                    setFormInStore(NikeEquipment.formDataName, {
                        ...this.props.forms[NikeEquipment.formDataName],
                        availableEquipment: formCreneauDispo.birthdaySubscription.catalogArticles,
                    })
        
                    formCreneauDispo.birthdaySubscription.catalogArticles.forEach(
                        (element) => {
                            if (cart.equipments[element.product.id] === undefined) {
                                const equipment = formatedAvailableEquipment[element.product.id]
                                let sizes = equipment.sizes
                                let formattedSizes = {}
                                sizes.forEach(element => {
                                    formattedSizes[element.Value] = element.Key
                                })
                                addProductToCart(element.product.id, formattedSizes[element.size], element.quantity, element.product.price)
                            }
                        },
                    )

                    this.setState({
                        isLoading : false,
                    })

                })

            } else {
                setFormInStore(NikeEquipment.formDataName, {
                    ...this.props.forms[NikeEquipment.formDataName],
                    availableEquipment: {},
                })
            }
            break
        }
            
        case "SCHOOL": {

            const firstSubscription = forms.ozmoSubscription.firstSubscription
            if (forms?.choixCreneau?.selectedTimeslot?.schoolDetails?.catalogProducts?.length === 0) {
                this.props.skipStep()
            }
            const setInfo = () => {
                setFormInStore(NikeEquipment.formDataName, {
                    ...this.props.forms[NikeEquipment.formDataName],
                    availableEquipment: formatedAvailableEquipment,
                })
        
                firstSubscription.catalogArticles.forEach(
                    (element) => {
                        if (cart.equipments[element.product.id] === undefined) {
                            const equipment = formatedAvailableEquipment[element.product.id]
                            let sizes = equipment.sizes
                            let formattedSizes = {}
                            sizes.forEach(element => {
                                formattedSizes[element.Value] = element.Key
                            })
                            addProductToCart(element.product.id, formattedSizes[element.size], element.quantity, element.product.price)
                        }
                    },
                )
            }

            this.setState({
                isLoading : true,
            })
            getKidsSchoolDetail(firstSubscription.school.id).then(
                (res) => {
                    if (res) {
                        let newState
                        if (res.metadata.catalogDiscounts.length >= 1) {
                            newState = {
                                advertising : res.data.advertising,
                                catalogDiscounts : res.metadata.catalogDiscounts[0],
                            }
                        } else {
                            newState = {
                                advertising : res.data.advertising,
                            }   
                        }
                        this.setState(newState)
                        this.setState({
                            isLoading : false,
                        })
                    }
                },
            )
            if (forms[CreneauxDispo.formName].selectedTimeslot === undefined) {
                this.setState({
                    isLoading : true,
                })
                getKidsSchoolDetail(firstSubscription.school.id).then(
                    (res)=>{
                        res.data.catalogProducts.forEach(
                            element => {
                                formatedAvailableEquipment[element.id] = element
                            },
                        )
                        setInfo()
                        this.setState({
                            isLoading : false,
                        })
                    },
                )
            } else {
                forms[CreneauxDispo.formName].selectedTimeslot.schoolDetails.catalogProducts.forEach(
                    element => {
                        formatedAvailableEquipment[element.id] = element
                    },
                )
                setInfo()
            }
            break
        }
            
        case "CUP": {
            const cupSubscription = forms.TournamentRegistration
            if (cupSubscription) {
                if (cupSubscription.cup.catalogProducts.length === 0) {
                    this.props.skipStep()
                }
                setFormInStore(NikeEquipment.formDataName, {
                    ...this.props.forms[NikeEquipment.formDataName],
                    availableEquipment: cupSubscription ? 
                        cupSubscription.cup.catalogProducts
                        :
                        [],
                })
                this.setState({
                    advertising : cupSubscription ? 
                        cupSubscription.cup.advertising
                        :
                        undefined,
                    catalogDiscounts : cupSubscription ? 
                        cupSubscription.catalogDiscounts
                        :
                        {},
                })
            }
            break
        }
            
        case "TRAINING": {

            const stageSubscription = forms.ozmoSubscription.firstSubscription
            const hasCatalogProducts = forms.hasCatalogProducts
            if (hasCatalogProducts === false) {
                this.props.skipStep()
            }
            const setInfoStage = () => {
                setFormInStore(NikeEquipment.formDataName, {
                    ...this.props.forms[NikeEquipment.formDataName],
                    availableEquipment: formatedAvailableEquipment,
                })
        
                stageSubscription.catalogArticles.forEach(
                    (element) => {
                        if (cart.equipments[element.product.id] === undefined) {
                            const equipment = formatedAvailableEquipment[element.product.id]
                            let sizes = equipment.sizes
                            let formattedSizes = {}
                            sizes.forEach(element => {
                                formattedSizes[element.Value] = element.Key
                            })
                            addProductToCart(element.product.id, formattedSizes[element.size], element.quantity, element.product.price)
                        }
                    },
                )
            }
            if (forms[CreneauxDispo.formName] && forms[CreneauxDispo.formName].selectedTimeslot === undefined) {
                this.setState({
                    isLoading : true,
                })
                getKidsStageDetail(stageSubscription.training.id).then(
                    (res) => {
                        res.data.catalogProducts.forEach(
                            element => {
                                formatedAvailableEquipment[element.id] = element
                            },
                        )
                        this.setState({
                            advertising : res.data.data.advertising,
                            catalogDiscounts : res.data.metadata.catalogDiscounts[0],
                            isLoading: false,
                        })
                        setInfoStage()
                    },
                )
            } else {
                this.setState({
                    isLoading : true,
                })
                getKidsStageDetail(stageSubscription.training.id).then(
                    (res) => {
                        if (res) {
                            this.setState({
                                advertising : res.data.data.advertising,
                                catalogDiscounts : res.data.metadata.catalogDiscounts[0],
                                isLoading: false,
                            })
                        }
                    },
                )
                if (forms[CreneauxDispo.formName]) {
                    if (forms[CreneauxDispo.formName].selectedTimeslot.catalogProducts) {
                        forms[CreneauxDispo.formName].selectedTimeslot.catalogProducts.forEach(
                            element => {
                                formatedAvailableEquipment[element.id] = element
                            },
                        )
                        setInfoStage()
                    } else {
                        getKidsStageDetail(forms[CreneauxDispo.formName].selectedTimeslot.id).then(
                            res => {
                                res.data.data.catalogProducts.forEach(
                                    element => formatedAvailableEquipment[element.id] = element,
                                )
                                setInfoStage()
                            },
                        )
                    }
                }
            }
            break
        }
                
        case "LEAGUE": {

            const leagueSub = forms.TournamentRegistration
            if (leagueSub) {
                if (leagueSub.league.catalogProducts.length === 0) {
                    this.props.skipStep()
                }
                setFormInStore(NikeEquipment.formDataName, {
                    ...this.props.forms[NikeEquipment.formDataName],
                    availableEquipment: leagueSub ? 
                        leagueSub.league.catalogProducts
                        :
                        [],
                })
                this.setState({
                    advertising : leagueSub ? 
                        leagueSub.league.advertising
                        :
                        undefined,
                    catalogDiscounts : leagueSub ? 
                        leagueSub.catalogDiscounts
                        :
                        {},
                })
            }
            break
        }

        default:
            // eslint-disable-next-line no-console
            console.error("NOT IMPLEMENTED TUNNEL TYPE or forgot break : %o", tunnelType)
            // eslint-disable-next-line no-debugger
            debugger
            break
        }
        const isAppV2 = !!getCookie("appV2")
        if (!isAppV2) {
            const oldZIndex = document.getElementById("footer")?.style?.zIndex
            this.setState({ oldZIndex : oldZIndex })
            document.getElementById("footer").style.zIndex = "999"
        }

        const isMobile = (window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth) <= 640

        this.handleheaderHeight(isMobile)

        window.addEventListener("resize", ()=> {
            const isMobile = (window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth) <= 640

            this.handleheaderHeight(isMobile)
        })
    }

    handleheaderHeight(isMobile) {
        const btn = document.getElementById("previous-step-btn")
        if (btn) {
            if (isMobile) {
                const stepHeader = document.getElementsByClassName("stepHeader")[0]
                btn.style.height = stepHeader.offsetHeight + "px"
            }
        }
    }

    componentWillUnmount() {
        const isAppV2 = !!getCookie("appV2")
        if (!isAppV2) {
            document.getElementById("footer").style.zIndex = this.state.oldZIndex
        }
    }

    // method
    // open dialog cart
    openCartSmall() {
        this.setState({ cartDisplay: true })
    }

    // close dialog cart
    closeCartSmall() {
        this.setState({ cartDisplay: false })
    }

    createCategories(equipment, categoryEquipment) {
        if (categoryEquipment[equipment.category] === undefined) {
            categoryEquipment[equipment.category] = []
        }
        categoryEquipment[equipment.category].push(equipment)
    }

    sortCategoryEquipment(categoryEquipment, sortedCategoryEquipment) {
        Object.keys(categoryEquipment).sort().forEach(
            function (key) {
                sortedCategoryEquipment[key] = categoryEquipment[key]
            },
        )
        
        Object.keys(sortedCategoryEquipment).forEach(
            (key) => {
                sortedCategoryEquipment[key].sort(
                    (a, b) => {
                        let index = 0
                        while (a.name[index] === b.name[index] && index < a.name.length && index < b.name.length) {
                            index++
                        }
                        const compareAB =  a.name[index] < b.name[index] ? -1 : 1
                        return a.name[index] === b.name[index] ? 
                            0
                            :
                            compareAB
                        
                    },
                )
            },
        )
    }

    createEquipmentDom(sortedCategoryEquipment, equipmentDOM) {
        if (Object.values(sortedCategoryEquipment).length > 0) {
            Object.values(sortedCategoryEquipment).forEach(
                el => {
                    el.forEach(
                        (equipment) => {
                            equipmentDOM.push(
                                <div className="c-col c-col--4 c-col--sm--6 product" key={`shopProduct--${equipment.id}`}>
                                    <ShopProduct 
                                        {...equipment}
                                        tunnelType={this.props.tunnelType}
                                        openMissingInfo={(label) => {
                                            this.setState({ missingLabel: label })
                                            this.setState({ missingInfoOpen: true })
                                        }}
                                        openProductZoom={node => {
                                            this.setState({ zoomContent: node })
                                            this.setState({ productZoomOpen: true })
                                        }}
                                    />
                                </div>,
                            )
                        },
                    )
                },
            )
        }
    }

    addSubscriptionEquipmentToCart(equipments, cartLines) {
        let subTotal = 0
        equipments.forEach(
            (equipment) => {
                subTotal += Number.parseInt(equipment.quantity) * Number.parseFloat(equipment.product.price)
                cartLines.push(
                    <li key={`product--${equipment.id}`}>
                        <ShopCartEquipmentLine
                            productOrder={equipment}
                            productInfos={equipment.product}
                            tunnelType={this.props.tunnelType}
                        />
                    </li>,
                )
            },
        )
        return subTotal
    }

    render() {
        // school data
        const { forms, stepManagement, checkSkipStepEDF, tunnelType, theme, updateSearchParams, customPrevious } = this.props
        const selectedTimeslot = forms[CreneauxDispo.formName] !== undefined ? forms[CreneauxDispo.formName].selectedTimeslot : undefined
        let catalogDiscounts
        const firstSubscription = forms?.ozmoSubscription ? forms.ozmoSubscription.firstSubscription : null
        const { availableEquipment } = forms[NikeEquipment.formDataName] !== undefined ? 
            forms[NikeEquipment.formDataName] 
            : {
                availableEquipment: {},
            }

        // birthday data
        const formCreneauDispo = this.props.forms[CreneauDispo.formName] !== undefined ? 
            this.props.forms[CreneauDispo.formName]
            : {}

        // commons data
        const help = <PhoneCallHelp tunnelType={tunnelType}/>
        
        let sortedCategoryEquipment = {}
        let advertising
        let DiscountOnSubTotal = 0
        let equipmentDOM = []
        let equipments = []
        let cartLines  = []
        let categoryEquipment = {}
        let SubTotal = 0

        switch (tunnelType) {
        case "BIRTHDAY":
            if (formCreneauDispo?.birthdaySubscription?.id) {
                    
                advertising = formCreneauDispo.birthdaySubscription.birthdayConfig.advertising
    
                equipments = formCreneauDispo.birthdaySubscription.catalogArticles
                    
                formCreneauDispo.birthdaySubscription.birthdayConfig.catalogProducts.forEach(
                    (el) => {
                        this.createCategories(el, categoryEquipment)
                    },
                )
            
                DiscountOnSubTotal = formCreneauDispo.birthdaySubscription.catalogDiscountAmount
            }
            break

        case "SCHOOL":
            if (selectedTimeslot) {
                advertising = selectedTimeslot.schoolDetails.advertising
            } else {
                advertising = this.state.advertising
            } 
            equipments = firstSubscription?.catalogArticles

            DiscountOnSubTotal = firstSubscription?.catalogDiscountAmount
            break

        case "CUP": {
            const cupSubscription = forms.TournamentRegistration
            advertising = this.state.advertising
            equipments = cupSubscription ? 
                cupSubscription.catalogArticles
                :
                []
                
            DiscountOnSubTotal = cupSubscription ? 
                cupSubscription.catalogDiscountAmount
                :
                []
                
            break
        }

        case "TRAINING": {
            const stageSubscription = forms.ozmoSubscription.firstSubscription
            advertising = this.state.advertising
            equipments = stageSubscription.catalogArticles
            DiscountOnSubTotal = stageSubscription.catalogDiscountAmount
            break
        }
            
        case "LEAGUE": {
            const leagueSubscription = forms.TournamentRegistration
            advertising = this.state.advertising
            equipments = leagueSubscription ? 
                leagueSubscription.catalogArticles
                :
                []
                
            DiscountOnSubTotal = leagueSubscription ? 
                leagueSubscription.catalogDiscountAmount
                :
                []
                
            break
        }

        default:
            // eslint-disable-next-line no-console
            console.error("UNKNOWN OR NOT IMPLEMENTED TUNNEL TYPE : %o", tunnelType)
            // eslint-disable-next-line no-debugger
            debugger
            break
        }
        
        catalogDiscounts = this.state.catalogDiscounts
        if (tunnelType !== "BIRTHDAY") {
            Object.values(availableEquipment).forEach(
                (el) => {
                    this.createCategories(el, categoryEquipment)
                },
            )
        }

        SubTotal = this.addSubscriptionEquipmentToCart(equipments, cartLines)

        this.sortCategoryEquipment(
            categoryEquipment,
            sortedCategoryEquipment,
        )
        this.createEquipmentDom(sortedCategoryEquipment, equipmentDOM)

        const equipmentOrLoader = equipmentDOM.length > 0 ?
            equipmentDOM
            :
            <Preloader/>
        
        const havePrestaSupPrevious = checkSkipStepEDF.skipPrestaSupp ?
            stepManagement.currentStep - 2
            :
            null
        
        const previousStep = checkSkipStepEDF ? havePrestaSupPrevious : null
        const titleWording = theme === "theme-padel" ? "Équipement" : "Équipement Nike"
        const catalogMaxAmount = catalogDiscounts?.maxAmount === null ? (
            <div className="cartBox__info" key={"discount-" + catalogDiscounts?.id}>
                <p>{catalogDiscounts?.level}€ euros achetés = {catalogDiscounts?.amountPerLevel}€ offerts</p>
            </div>
        ) : (
            <div className="cartBox__info" key={"discount-" + catalogDiscounts?.id}>
                <p>{catalogDiscounts?.level}€ euros achetés = {catalogDiscounts?.amountPerLevel}€ offerts dans la limite de {catalogDiscounts?.maxAmount}€ offerts</p>
            </div>
        )

        const equipmentsTotalQuantity = Object.values(equipments).reduce(
            (acc, el) => acc + el.quantity,
            0,
        )

        return (
            <Step
                {...this.props}
                title={tunnelType === "BIRTHDAY" ? "Cadeaux Supplémentaires" : titleWording}
                help={ help }
                helpId={"NikeEquipmentHelp"}
                ref={this.step}
                optionnal={true}
                cls={classNames("nikeEquipment arrows-on-sides noMarginBottom", {
                    isMobile: UserAgentHandler.isAndroid() || UserAgentHandler.isIOS(),
                })}
                otherId='5'
                checkBeforeNextStep={updateSearchParams ? () => updateSearchParams(forms?.TournamentRegistration?.catalogArticles) : undefined}
                goToPreviousStep={
                    customPrevious ?? 
                    (tunnelType === "SCHOOL" ||  tunnelType === "BIRTHDAY" ? 
                        previousStep
                        :
                        null)
                }
            >
                { this.state.isLoading &&
                    <Preloader fixed={true} />
                }

                <div className="formsHolder c-row c-mrg--btm--4 c-mrg--btm--sm--1 layoutSmall">

                    {
                        tunnelType === "BIRTHDAY" &&
                            <div className="h3">Faites plaisir à votre enfant et ses copains avec encore plus de cadeaux !</div>
                    }

                    <div className="c-col c-col--content c-col--sm--12 product-container">
                        <div className="c-row gridProduct">
                            {Object.values(sortedCategoryEquipment).length > 0 ? 
                                equipmentOrLoader
                                :
                                !this.state.isLoading && availableEquipment.length === 0 &&
                                    <div>Aucun équipement disponible</div>
                            }
                        </div>
                    </div>

                    <div className="c-col c-col--sidebar c-col--sm--12">
                        <div className={`cartBox smallOverlay ${this.state.cartDisplay ? "is-visible" : ""}`}>
                            <a className="cartBox__close show-sm" onClick={ this.closeCartSmall } title="Fermer">
                                <img src={theme === "theme-soccer" ? "/assets/icons/cross-orange.svg" : "/assets/icons/cross-white-diag.svg"} alt="close"/>
                            </a>

                            <div className="cartBox__title">Panier</div>
                            {catalogDiscounts?.amountPerLevel && catalogDiscounts?.level ? (
                                catalogMaxAmount
                            ) : catalogDiscounts?.minPurchase !== null && catalogDiscounts?.level === null && (
                                <div className="cartBox__info" key={"discount-" + catalogDiscounts.id}>
                                    <p>{catalogDiscounts.level}€ euros offerts à partir de {catalogDiscounts.minPurchase}€ d'achats</p>
                                </div>
                            )}

                            { advertising && 
                                <div className="cartBox__wysiwyg" dangerouslySetInnerHTML={{ __html: advertising }}></div>
                            }

                            { Object.values(equipments).length > 0 ?
                                (<React.Fragment>
                                    <div className="cardBox__products">
                                        {cartLines.length > 0 && cartLines}
                                    </div>

                                    <div className="cardBox__price">
                                        { DiscountOnSubTotal < 0 ? (
                                            <React.Fragment>
                                                <div className="cardBox__price__subtotal">
                                                    <span className="text">Total Équipements</span>
                                                    <span className="price">
                                                        <CurrencyDisplay price={SubTotal}/>
                                                    </span>
                                                </div>

                                                <div className="cardBox__price__discount">
                                                    <span className="text">Remise : </span>
                                                    <span className="price"><CurrencyDisplay price={DiscountOnSubTotal}/></span>
                                                </div>

                                                <div className="cardBox__price__total">
                                                    <span className="text">Total après remise</span>
                                                    <span className="price">
                                                        <CurrencyDisplay price={SubTotal + DiscountOnSubTotal}/>
                                                    </span>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <div className="cardBox__price__subtotal">
                                                <span className="text">Total Équipements</span>
                                                <span className="price">
                                                    <CurrencyDisplay price={SubTotal}/>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>)
                                : (
                                    <div className="cartBox__empty">{ tunnelType === "SCHOOL" || tunnelType === "TRAINING" || tunnelType === "BIRTHDAY" ?
                                        "Vous n’avez aucun équipement dans votre panier."
                                        :
                                        "Tu n'as aucun équipement dans ton panier."
                                    }</div>
                                )
                            }
                        </div>
                    </div>

                    <button className="ctaStickyCart" onClick={ this.openCartSmall }>
                        <img className="cart" src={theme === "theme-soccer" ? "/assets/icons/icon--cart--orange.svg" : "/assets/images/padel/icons/icon--cart--padel-green.svg"} />
                        <div className="content">
                            <div className="articleCount">{equipmentsTotalQuantity} article{equipmentsTotalQuantity?.length > 1 ? "s" : ""}</div>
                            <div className="price">
                                <CurrencyDisplay price={SubTotal + DiscountOnSubTotal}/>
                            </div>
                        </div>
                        <img className="arrow" src="/assets/icons/arrow-up--white.svg" />
                    </button>
                </div>
                <Modal
                    className="missingInfo"
                    isOpen={this.state.missingInfoOpen}
                    onClose={() => this.setState({ missingInfoOpen: false })}
                >
                    <header>{this.state.missingLabel} manquante</header>
                    <div>Renseigne une {this.state.missingLabel}</div>
                </Modal>
                <Modal
                    className="zoomContent"
                    isOpen={this.state.productZoomOpen}
                    onClose={() => this.setState({ productZoomOpen: false })}
                >
                    {this.state.zoomContent ? this.state.zoomContent : <></>}
                </Modal>
            </Step>
        )
    }
}

NikeEquipment.formName = "equipmentForm"
NikeEquipment.formDataName = "equipmentData"

const mapStateToProps = state => {
    return {
        cart: state.cart,
        checkSkipStepEDF: state.checkSkipStepEDF,
        forms: state.forms,
        stepManagement: state.stepManagement,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addProductToCart: (id, size, quantity, price) => dispatch(addProductToCart(id, size, quantity, price)),
        setFormInStore: (name, form) => dispatch(setFormEntry(name, form)),
        skipStep: () => dispatch(skipStep()),
    }
}

NikeEquipment.propTypes = {
    addProductToCart: func,
    cart: object,
    checkSkipStepEDF: object,
    customPrevious: oneOfType([ bool, number ]),
    forms: object,
    setFormInStore: func,
    skipStep: func,
    stepManagement: object,
    theme: string,
    tunnelType: string,
    updateSearchParams: func,
}

export default connect(mapStateToProps, mapDispatchToProps)(NikeEquipment)
