import { bool, func, string } from "prop-types"
import { forwardRef, useImperativeHandle, useState } from "react"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import Preloader from "../../../../components/loaders/preloader/preloader"
import Modal from "../../../../components/Modal/Modal"

import "./QuitWaitingListPopin.scss"

const QuitWaitingListPopin = forwardRef(({ confirmCancel, title, isLoading }, ref) => {
    const [ internalModalState, setInternalModalState ] = useState(false)

    useImperativeHandle(ref, () => ({
        hide: () => {
            setInternalModalState(false)
        },
        show: () => {
            setInternalModalState(true)
        },
    }))

    return (
        <Modal
            isOpen={internalModalState}
            onClose={() => setInternalModalState(false)}
            className="quitWaitingListPopin"
        >
            <div className="modal-close-button showPadel" onClick={() => setInternalModalState(false)}/>
            <header>{title}</header>
            <section className="confirmButtons">
                <ButtonCta
                    text="Oui"
                    color="grey"
                    onClick={() => confirmCancel()}
                    isCta2Alt
                />
                <ButtonCta
                    text="Non"
                    color="grey"
                    onClick={() => {
                        setInternalModalState(false)
                    }}
                    isCta2Alt
                />
            </section>
            {isLoading && <Preloader fixed />}
        </Modal>
    )
})

QuitWaitingListPopin.displayName = "QuitWaitingListPopin"

QuitWaitingListPopin.propTypes = {
    confirmCancel: func,
    isLoading: bool,
    title: string,
}

export default QuitWaitingListPopin
