import { arrayOf, bool, node, oneOfType, string } from "prop-types"

import Preloader from "../../entries/main/../../components/loaders/preloader/preloader"

import "./ListingRegistrationHolder.scss"

function ListingRegistrationHolder({ isLoading, logo, status, name, details, actions }) {
    return (
        <div className="listing__registration">
            {
                isLoading && 
                    <Preloader fixed/>
            }
            <div className="listing__registration__infos">
                <div className="logo">
                    <img src={logo}/>
                </div>
                <div className={"info-list"}>
                    <div className="info-list__important">
                        <div className="status">
                            { status }
                        </div>
                        <div className="type">
                            { name }
                        </div>
                    </div>
                    <div className="info-list__details">
                        { details }
                    </div>
                </div>
            </div>
            <div className="actions">
                { actions }
            </div>
        </div>
    )
}

ListingRegistrationHolder.propTypes = {
    actions: oneOfType([ string, node, arrayOf(node) ]),
    details: oneOfType([ string, node, arrayOf(node) ]),
    isLoading: bool,
    logo: string,
    name: oneOfType([ string, node, arrayOf(node) ]),
    status: oneOfType([ string, node, arrayOf(node) ]),
}

export default ListingRegistrationHolder
