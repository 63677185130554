import { _apiCall } from "../../backend/axios"

/* Read bloc promo of menu items */
export function readMenuItemsBlocPromo(theme) {
    let type
    switch (theme) {
    case "theme-soccer":
    default:
        type = 0
        break
    case "theme-padel":
        type = 1
        break
    }
    return _apiCall(
        `read/menu/items?type=${type}`,
        "GET",
    ).then((res) => { return res.data.data })
}
