import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import CreneauDispo from "./CreneauxDispo"
import PersonnaliserAnniv from "./PersonnaliserAnniv"
import TextArea from "../../../../components/Forms/textArea/TextArea"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import Step from "../../../../components/stepsManagement/Step"
import useResetScroll from "../../../../hooks/useResetScroll"
import { setFormEntry } from "../../../../store/forms/actions"
import MesEnfants from "../../steps/MesEnfants"
import { editBirthday } from "../api"

import "./Commentaires.scss"

/**
 * Etape du formulaire BirthdayRegistration permettant de choisir un créneaux
 */
function Commentaires(props) {
    useResetScroll()
    /* VARIABLES */
    const dispatch = useDispatch()

    /* STATE */
    const [ dataLoading, setDataLoading ] = useState(false)
    const [ , updateState ] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    /* REFS */
    const step = useRef(null)
    const commentaires = useRef(null)

    /* STORE */
    let formEnfant = useSelector(state => state.forms[MesEnfants.formName])
    formEnfant = formEnfant ?? {}
    const stepManagement = useSelector(state => state.stepManagement)

    /* ACTIONS */
    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }

    /* FORMULAIRES */
    let formCreneauDispo = useSelector(state => state.forms[CreneauDispo.formName])
    formCreneauDispo = formCreneauDispo ?? {}
    const formPersonnaliserAnnivData = useSelector(state => state.forms[PersonnaliserAnniv.formDataName])

    /* DOM HELPERS */
    const help = <PhoneCallHelp tunnelType="BIRTHDAY"/>

    let childName = formEnfant && formEnfant.selectedKid ? formEnfant.selectedKid.firstName : ""
    let childGender = formEnfant && formEnfant.selectedKid ? formEnfant.selectedKid.gender : ""

    if (!childName && !childGender) {
        if (formCreneauDispo.birthdaySubscription && formCreneauDispo.birthdaySubscription.kid) {
            childName = formCreneauDispo.birthdaySubscription.kid.firstName
            childGender = formCreneauDispo.birthdaySubscription.kid.gender
        }
    }

    useEffect(
        () => {

            if (step.current) {
                step.current.validate()
            }

        }, [ step ],
    )

    function postBirthday() {
        setDataLoading(true)
        return new Promise(
            (resolve) => {
                let formattedDate = formCreneauDispo.birthdaySubscription.start.replace(/T/g, " ")

                if (formCreneauDispo.birthdaySubscription && formCreneauDispo.birthdaySubscription.id) {

                    editBirthday(formCreneauDispo.birthdaySubscription.id,
                        formEnfant.selectedKid ? formEnfant.selectedKid.id : formCreneauDispo.birthdaySubscription.kid.id,
                        formCreneauDispo.birthdaySubscription.birthdayConfig.id,
                        formattedDate,
                        formPersonnaliserAnnivData.selectedGift ? formPersonnaliserAnnivData.selectedGift.id :
                            formCreneauDispo.birthdaySubscription.gifts[0].product.id,
                        formCreneauDispo.birthdaySubscription.gifts[0].sizeValue,
                        formPersonnaliserAnnivData.selectedDrink ? formPersonnaliserAnnivData.selectedDrink.id :
                            formCreneauDispo.birthdaySubscription.drinks[0].id,
                        formPersonnaliserAnnivData.selectedCake ? 
                            formPersonnaliserAnnivData.selectedCake.id 
                            :
                            formCreneauDispo.birthdaySubscription.cakes && formCreneauDispo.birthdaySubscription.cakes[0] ? 
                                formCreneauDispo.birthdaySubscription.cakes[0].id 
                                : null,
                        commentaires.current ? commentaires.current.value : null).then((res) => {

                        setFormInStore(CreneauDispo.formName, {
                            ...formCreneauDispo,
                            birthdaySubscription: res.data.data,
                        })
                        setDataLoading(false)
                        resolve()
                    })

                }
            })
    }

    return (
        <Step
            {...props}
            title="Commentaires"
            help={ help }
            helpId={"birthDayCreneauHelp"}
            ref={step}
            cls="arrows-on-sides"
            onValidation={postBirthday}
            promise={true}
            goToPreviousStep={
                (
                    formCreneauDispo
                    && formCreneauDispo.birthdaySubscription
                    && formCreneauDispo.birthdaySubscription.birthdayConfig
                    && formCreneauDispo.birthdaySubscription.birthdayConfig.catalogProducts
                    && formCreneauDispo.birthdaySubscription.birthdayConfig.catalogProducts.length === 0
                ) ? 
                    stepManagement.currentStep === 5 ?
                        stepManagement.currentStep - 2
                        :
                        stepManagement.currentStep - 3
                    :
                    null
            }
        >

            {
                dataLoading &&
                    <Preloader fixed={true} />
            }

            <div className="formsHolder c-row formsHolder justify-center align-start 
            c-mrg--btm--1 c-mrg--btm--sm--1 layoutSmall commentaires-birthday c-col c-col--10">
                <div className="align-flex c-col c-col--12 base-div">
                    <div className="c-col c-col--3 c-col--sm--12 left-part allergie-sub-div">
                        <div className="h3 text-center-sm">
                            {childName} est allergique?
                            <br />
                            Des informations importantes à nous soumettre ? Dites-nous tout.
                        </div>
                    </div>
                    <div className="c-col c-col--9 c-col--sm--12 allergie-sub-div">
                        <TextArea
                            ref={commentaires}
                            defaultValue={formCreneauDispo && formCreneauDispo.birthdaySubscription && formCreneauDispo.birthdaySubscription.comments}
                            placeholder={"Exemple : " + childName + " est asthmatique. "}
                        />
                    </div>

                </div>

            </div>

        </Step>
    )
}

export default Commentaires
