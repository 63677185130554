import { bool, func, number, object, oneOfType, string } from "prop-types"
import { useRef } from "react"

import "./button.scss"

function Button({
    id,
    text,
    className = "",
    icon,
    color = "",
    rounded,
    disabled,
    onClick,
    isBtnV2,
    onlyIcon,
    link,
    data,
    customCls,
    noHover,
    rect,
    hoverChangeIcon,
    target,
}) {
    const TagName = link ? "a" : "button"
    const iconRef = useRef(null)
    return (
        <TagName 
            {...data}
            id={id}
            className={
                "button " + color + " " + className + 
                (rounded ? " rounded" : "") + 
                (disabled ? " disabled" : "") + 
                (isBtnV2 ? " btnV2" :  "") +
                (customCls ? " " + customCls : "") +
                (noHover ? " no-hover" : "") +
                (onlyIcon ? " onlyIcon" : "") +
                (rect ? " rect" : "")

            }
            target={target}
            href={link}
            onClick={onClick}
            disabled={disabled}
            tabIndex="0"
            onMouseEnter={() => {
                if (icon && hoverChangeIcon) {
                    iconRef.current.src = hoverChangeIcon
                }
            }}
            onMouseLeave={() => {
                if (icon && hoverChangeIcon) {
                    iconRef.current.src = icon
                }
            }}
        >
            {icon && 
                <img ref={iconRef} src={icon}/>
            }
            { !onlyIcon &&
                <span>{text}</span>
            }
        </TagName>
    )
}

Button.propTypes = {
    className: string,
    color: string,
    customCls: string,
    data: object,
    disabled: bool,
    hoverChangeIcon: oneOfType([ bool, string ]),
    icon: string,
    id: oneOfType([ number, string ]),
    isBtnV2: bool,
    link: string,
    noHover: bool,
    onClick: func,
    onlyIcon: bool,
    rect:bool,
    rounded: bool,
    target: oneOfType([ bool, string ]),
    text: oneOfType([ string, object ]),
}

export default Button
