import { func, number, object, oneOfType, string } from "prop-types"
import { useDispatch } from "react-redux"

import useIsMobile from "../../hooks/useIsMobile"
import useMediaPath from "../../hooks/useMediaPath"
import { goToStep, previousStep } from "../../store/stepsManagement/actions"
import RoundCaptionnedIconButton from "../Buttons/roundCaptionnedIconButton/RoundCaptionnedIconButton"

/**
 * Bouton pour retourner à l'étape précédente à l'aide d'une action envoyée au store
 *
 * Peut être désactivé ou caché
 */
function PreviousStepButton({ text, callback, goToStepProps, height }) {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const mediaPath = useMediaPath()

    function onClick() {
        document.querySelector("#content")?.scrollTo({
            behavior: "smooth",
            left: 0,
            top: 0,
        })
        document.querySelector("#content")?.scrollTo({
            behavior: "smooth",
            left: 0,
            top: 0,
        })
        window?.scrollTo({
            behavior: "smooth",
            left: 0,
            top: 0,
        })
        if (callback !== undefined) {
            callback()
        }
        if (goToStepProps || goToStepProps === 0) {
            dispatch(goToStep(goToStepProps))
        } else {
            dispatch(previousStep())
        }
    }

    return (
        <div
            id="previous-step-btn"
            className="stepButtonHolder stepButtonHolder--prev"
            style={
                (isMobile && height) ?
                    {
                        height: height,
                    }
                    :
                    undefined
            }
        >
            <RoundCaptionnedIconButton
                icon={mediaPath([ "arrow-left.svg", "arrow-left-black-alt.svg" ])}
                text={ text ? text : "Précédent" }
                clickCallback={onClick}
            />
        </div>
    )
}

PreviousStepButton.propTypes = {
    callback: func,
    goToStepProps: oneOfType([ number, func ]),
    height: number,
    text: oneOfType([ string, object ]),
}

export default PreviousStepButton
