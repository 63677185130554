import classNames from "classnames"
import { node, string } from "prop-types"
import { createContext, useContext, useEffect, useState } from "react"

import useCookies from "../../hooks/useCookies.js"
import useFavicon from "../../hooks/useFavicon"
import { isBusinessEnvironment } from "../../utilities/helpers.js"

const ThemeContext = createContext()

export function useTheme() {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error("useTheme must be used within a ThemeProvider")
    }
    return context.theme
}

export function useThemeUpdate() {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error("useThemeUpdate must be used within a ThemeProvider")
    }
    return context.updateTheme
}

export function useIsWebview() {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error("useIsWebview must be used within a ThemeProvider")
    }
    return context.isWebview
}

export function useThemeInitialized() {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error("useThemeInitialized must be used within a ThemeProvider")
    }
    return [ context.themeInitialized, context.setThemeInitialized ]
}

export function ThemeProvider({ children, theme: externalTheme }) {
    const isBusinessLeague =  isBusinessEnvironment()

    const defaultTheme = isBusinessLeague ? "theme-business" : "theme-soccer"
    const { isWebview, isAppV2 } = useCookies()
    const favicon = useFavicon()

    const [ theme, setTheme ] = useState(externalTheme || defaultTheme)
    const [ themeInitialized, setThemeInitialized ] = useState(false)

    function updateTheme(newTheme) {
        setTheme(newTheme)
        localStorage.setItem("lastUniverse", newTheme)
        setThemeInitialized(true)
    }

    useEffect(() => {
        favicon(theme)
    }, [ theme ])

    useEffect(() => {
        if (externalTheme) {
            updateTheme(externalTheme)
            setThemeInitialized(true)
        }
    }, [ externalTheme ])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!themeInitialized) {
                setTheme(isBusinessLeague ? "theme-business" : "theme-soccer")
                setThemeInitialized(true)
            }
        }, 10000)

        return () => clearTimeout(timer)
    }, [ themeInitialized, isBusinessLeague ])

    const value = {
        isWebview,
        setThemeInitialized,
        theme,
        themeInitialized,
        updateTheme,
    }

    useEffect(() => {
        if (theme !== defaultTheme) {
            setThemeInitialized(true)
        }
        setTheme(isBusinessLeague ? "theme-business" : theme ? theme : defaultTheme)
    }, [ isBusinessLeague, theme ])

    return (
        <ThemeContext.Provider value={value}>
            <div className={classNames("theme", theme, { isAppV2 })}>
                {children}
            </div>
        </ThemeContext.Provider>
    )
}

ThemeProvider.propTypes = {
    children: node,
    theme: string,
}
