import { array, bool, func, object, oneOfType, string } from "prop-types"

import PlayerStatusManagement from "../PlayerStatusManagement/PlayerStatusManagement"

import "./PlayersStatusList.scss"

export default function PlayersStatusList({ players, title, selected, setSelected, changePlayerStatus, allowEdit = false, disableMvp = false, currentUserId }) {
    return (
        <div className="playersStatusList">
            <h2 className="title">
                <img alt="decorator" src="/assets/icons/threeThunders.svg" />
                {title}
            </h2>
            <div className="list">
                {
                    players?.length > 0 && players?.map(player => (
                        <PlayerStatusManagement
                            mvpVotesDisplay={!disableMvp}
                            player={player}
                            key={player.gamePlayerId}
                            selected={selected?.gamePlayerId === player.gamePlayerId}
                            onClick={setSelected}
                            changePlayerStatus={changePlayerStatus}
                            allowEdit={allowEdit || currentUserId === player.id}
                        />
                    ))
                }
            </div>
        </div>
    )
}

PlayersStatusList.propTypes = {
    allowEdit: bool,
    changePlayerStatus: func,
    currentUserId: string,
    disableMvp: bool,
    players: array,
    selected: oneOfType([ bool, object ]),
    setSelected: oneOfType([ bool, func ]),
    title: string,
}
