import classNames from "classnames"
import { useFormContext } from "react-hook-form"

import CheckBlock from "../../../../../components/Forms/CheckBlock/CheckBlock"
import { getTypeName } from "../../function"
import { formArgs } from "../services/function"

import "./ActiveFilters.scss"

export default function ActiveFilters() {
    const { watch, setValue } = useFormContext()
    const [ typeFilter, durationFilter, hourFilter ] = watch([
        formArgs.TYPE,
        formArgs.DURATION,
        formArgs.HOUR,
    ])

    const noActiveFilters = (!typeFilter || typeFilter?.length === 0) &&
        (!durationFilter || durationFilter?.length === 0) &&
        (!hourFilter || hourFilter === "noHourFilter")

    if (noActiveFilters) {
        return null
    }

    return (
        <div className={classNames("bookingActiveFilters", {
            noMarginTop:  typeFilter?.length === 0 && durationFilter?.length === 0 && hourFilter === "noHourFilter",
        })}>
            {typeFilter?.length >= 0 && typeFilter?.map(type => (
                <CheckBlock
                    key={"activefilter-" + type[0] + type[1]}
                    checked={true}
                    unselect={true}
                    action={() => {
                        const newTypeFilter = typeFilter.filter(filter => filter[0] !== type[0] || filter[1] !== type[1])
                        setValue(formArgs.TYPE_IDS, newTypeFilter.map(type => type[0]) ?? [])
                        setValue(formArgs.TYPE, newTypeFilter)
                    }}
                >
                    {getTypeName(type[0], type[1])}
                </CheckBlock>
            ))}
            {durationFilter?.length > 0 && durationFilter?.map(duration => (
                <CheckBlock
                    key={duration.display}
                    checked={true}
                    unselect={true}
                    action={() => {
                        const newDurationFilter = durationFilter.filter(filter => filter.value !== duration.value)
                        setValue(formArgs.DURATION, newDurationFilter)
                        if (newDurationFilter.length === 0) {
                            setValue(formArgs.PREBOOK_DURATION, null)
                        }
                    }}
                >
                    {duration.display}
                </CheckBlock>
            ))}
            {hourFilter !== "noHourFilter" && (
                <CheckBlock
                    unselect
                    checked
                    action={() => {
                        setValue(formArgs.HOUR, "noHourFilter")
                    }}
                >
                    Après {hourFilter.display}
                </CheckBlock>
            )}
        </div>
    )
}
