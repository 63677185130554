export default function resetScroll(smooth = false, customElement) {
    const scrollOptions = {
        behavior: smooth ? "smooth" : "auto",
        left: 0,
        top: 0,
    }

    if (customElement) {
        document?.querySelector(customElement).scrollTo(scrollOptions)
        return
    }
    document?.querySelector("#content")?.scrollTo(scrollOptions)
    document?.querySelector("html")?.scrollTo(scrollOptions)
    window?.scrollTo(scrollOptions)
}
