import { bool, func, number, string } from "prop-types"

import Toggle from "../../../../../../components/Buttons/toggle/Toggle"
import ActivationTrigger from "../../../../../../components/Buttons/Triggers/ActivationTrigger"
import Tooltip from "../../../../../../components/tooltip/Tooltip"
import useThemeFeatures from "../../../../../../hooks/useThemeFeatures"

import "./toggleSetting.scss"

function ToggleSetting({ id, label, description, enabled, callback }) {
    const themeFeatures = useThemeFeatures()
    return (
        <div id={id} className="toggleSetting">
            <div className="line">
                <div className="infos">
                    <label>{ label }</label>
                    <ActivationTrigger id={ "notif-help-id" + id } cls='mini trackarrow' target={"#tooltip-" + id}>
                        {themeFeatures([ <div className="questionMark" key={"toggle-soccer"}>i</div>, <img alt="" key="toggle-padel" src="/assets/images/padel/icons/icons-notifications.svg" /> ])}
                    </ActivationTrigger>
                </div>
                
                <Toggle 
                    enabled={ enabled }
                    callback={callback}
                />
            </div>
            <Tooltip
                id={"tooltip-" + id}
                cls="bottomLeft"
            >
                { description }
            </Tooltip>
        </div>
            
    )
}
ToggleSetting.propTypes = {
    callback: func,
    description: string,
    enabled: bool,
    id: number,
    label: string,
}

export default ToggleSetting
