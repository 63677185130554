export default [
    "gp",
    "gf",
    "mq",
    "pf",
    "fr",
    "de",
    "be",
    "hr",
    "hu",
    "is",
    "re",
    "lu",
    "nl",
    "at",
    "fi",
    "gr",
    "mc",
    "sk",
    "ie",
    "it",
    "sm",
    "bs",
    "es",
    "je",
    "no",
    "ro",
    "gb",
    "sv",
    "sc",
    "se",
    "dk",
    "ca",
    "us",
    "pt",
    "cz",
    "ch",
    "pl",
]
