import classNames from "classnames"
import PropTypes from "prop-types"
import { components } from "react-select"

export const CustomControl = props => {
    const { children, isDisabled, isFocused, selectProps: { hasError } } = props

    return (
        <components.Control
            {...props}
            className={classNames("select-input-control", {
                "hasError": hasError,
                "isDisabled": isDisabled,
                "isFocused": isFocused,
            })}
        >
            {children}
        </components.Control>
    )
}

CustomControl.propTypes = {
    children: PropTypes.node,
    isDisabled: PropTypes.bool,
    isFocused: PropTypes.bool,
    selectProps: PropTypes.shape({
        hasError: PropTypes.bool,
    }),
}

