import APP_CONFIG from "../../../business/config"

const padelLanguage = {
    booking: {
        defaultTeam1Name: APP_CONFIG.padelDefaultTeamsNames.team1,
        defaultTeam2Name: APP_CONFIG.padelDefaultTeamsNames.team2,
        favoriteSlotField: "une piste",
        filter: {
            noResult: "Aucun créneau ne correspond à ta recherche. Ajoute ce créneau en favori, tu recevras une alerte s'il se libère.",
        },
        listing: {
            noBooking: "Tu n'as pas encore reservé de piste.",
        },
        noListingField: "Aucune piste",
        supersub: {
            buttonLabel: "Publier mon match",
            desc: "Ton match arrive à grand pas… Publie ton match pour le compléter",
            find: "Match\npublic",
            thinkAbout: "Pense au ultimo",
        },
        videos: {
            goals: "Les meilleurs points",
        },
    },
    cup: {
        cancelPreregistration: "Annuler",
    },
    familiarityTrustThanks: "Gracias",
    federation: "FFT",
    field: "piste",
    formalityTrustThanks: "Gracias",
    league: {
        definedArticle: "le ",
        name: "Championnat",
        undefinedArticle: "un ",
    },
    longName: "Padel",
    mobile: {
        menu: {
            reserver: "RÉSERVER UNE PISTE",
        },
    },
    name: "UrbanPadel",
    otherName: "padel",
    payWithUsCard: {
        typeOfCard: "UP",
    },
    school: {
        noSchool: "Il n'y a pas de Cours de Padel disponible sur le centre de ",
        title: "Cours de Padel",
    },
    shortName: "Padel",
    training: {
        noTraining: "Aucun Stage de Padel n'est disponible sur le centre de ",
        title: "Stage de Padel",
    },
    wordpress: "www.urbanpadel.fr",
}

export default padelLanguage
