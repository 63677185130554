import initializeMenu from "../../services/menu/initializeMenu.js"
import initializeMenuBusiness from "../../services/menu/initializeMenuBusiness.js"
import initializeMenuBusinessVisitor from "../../services/menu/initializeMenuBusinessVisitor.js"
import initializeMenuPadel from "../../services/menu/initializeMenuPadel.js"

export const menuFunctions = {
    "theme-business": initializeMenuBusiness,
    "theme-business-visitor": initializeMenuBusinessVisitor,
    "theme-padel": initializeMenuPadel,
    "theme-soccer": initializeMenu,
}
