import { bool, func, number, string } from "prop-types"
import { useState } from "react"
import { useSelector } from "react-redux"

import { createUserPlayerProfile, modifyUserPlayerProfile } from "../../../globalAPI/api"
import useMediaPath from "../../../hooks/useMediaPath"
import { PadelLevelArray } from "../../../model/enums/User/PadelLevel"
import { PadelPlacementArray } from "../../../model/enums/User/PadelPlacement"
import ButtonCta from "../../Buttons/button/button-cta"
import { SelectInput } from "../../Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../Inputs/TextInput/TextInput.jsx"
import Stepper from "../Stepper"

export default function Step4Padel({ step, theme, closeAndSave, disableActions }) {
    const currentUser = useSelector(state => state.userLoaded)
    const playerProfile = currentUser?.playerProfile
    const mediaPath = useMediaPath()
    const [ ranking, setRanking ] = useState("")
    const [ licenceNumber, setLicenceNumber ] = useState("")
    const [ level, setLevel ] = useState("")
    const [ placement, setPlacement ] = useState("")

    function sendPadelInformation() {
        const api = playerProfile?.playerProfileId ? modifyUserPlayerProfile : createUserPlayerProfile
        api(currentUser.id, {
            level: level,
            licenseNumber: licenceNumber,
            positioning: placement,
            ranking: ranking,
        })
    }

    return (
        <div className={"step4"}>
            <header><img className="" src={mediaPath([ "/assets/images/logo-white.svg", "/assets/images/padel/logo/logo-green.svg" ])} /></header>
            <h4 className={"step4-intro"}>à propos de ton niveau</h4>
            <div className={"step4-intro2"}>As de la raquette ou joueur du dimanche dis-nous en plus sur ton niveau</div>
            <div className={"step4-content"}>

                <div className={"step4-input"}>
                    <TextInput
                        customRootCls={"inverted-bg"}
                        label={"N° de licence"}
                        value={licenceNumber}
                        onChange={val => setLicenceNumber(val ? val.replace(/[^A-Za-z0-9]/g, "") : "")}
                    />
                </div>

                <div className={"step4-input"} title="Nombre">
                    <TextInput
                        customRootCls={"inverted-bg"}
                        label={"Classement fft"}
                        value={ranking}
                        inputMode={"numeric"}
                        type={"number"}
                        onChange={val => setRanking(val ? val.replace(/[^0-9 ]/g, "") : "")}
                    />
                </div>

                <div className={"step4-input"}>
                    <SelectInput
                        id={"level"}
                        label={"Niveau"}
                        options={PadelLevelArray}
                        value={level}
                        onChange={(event)=>{setLevel(event.target.value)}}
                        placeholderOption={"Niveau"}
                    />
                </div>

                <div className={"step4-input"}>
                    <SelectInput
                        id={"placement"}
                        label={"Placement"}
                        options={PadelPlacementArray}
                        value={placement}
                        onChange={(e) => setPlacement(e?.target?.value)}
                        placeholderOption={"Placement"}
                    />
                </div>

            </div>
            <div className={"change-step"}>
                <ButtonCta
                    onClick={() => {
                        sendPadelInformation()
                        closeAndSave()
                    }}
                    disabled={disableActions}
                    text={
                        (
                            <div className={"button-first-connection"}>
                                <span>je suis prêt(e)</span>
                                {theme === "theme-soccer" ? (
                                    <img src={mediaPath([ "/assets/icons/chevronsoccer.svg", "" ])} />) : null}
                            </div>
                        )
                    }
                />
            </div>
            <div className={"first-connection-stepper"}>
                <Stepper activeStep={step}/>
            </div>
            <footer><img className="" src={mediaPath([ "/assets/images/bordersoccer.svg", "/assets/images/padel/backgrounds/first-connection-footer.svg" ])} /></footer>
        </div>
    )
    
}

Step4Padel.propTypes = {
    closeAndSave: func,
    disableActions: bool,
    level: number,
    licenceNumber: string,
    placement: number,
    ranking: number,
    sendPadelInformation: func,
    setLevel: func,
    setLicenceNumber: func,
    setPlacement: func,
    setRanking: func,
    step: number,
    theme: string,
}
