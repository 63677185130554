import { _apiCall } from "../../../backend/axios.js"
import { returnAnswerData } from "../../../backend/helpers.js"

export async function acceptTeamInvitation(token) {
    return await _apiCall(
        "create/league/team/publicJoin",
        "POST",
        null,
        {
            token: decodeURIComponent(token),
        },
    ).then(returnAnswerData)
}

export async function removePlayerFromTeam(teamId, playerId) {
    return await _apiCall(
        `modify/league/team/${teamId}/player/${playerId}/remove`,
        "POST",
    ).then(returnAnswerData)
}

export async function modifyTeamPicture(file, teamId) {
    const fileData = new FormData()
    fileData.append("picture", file)

    return await _apiCall(
        `modify/league/team/${teamId}/blazon`,
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        fileData,
    ).then(returnAnswerData)
}

export async function getTeamPJT(teamId) {
    return await _apiCall(
        `read/league/teams/${teamId}/publicToken`,
        "GET",
    ).then(returnAnswerData)
}
