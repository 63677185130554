import { bool } from "prop-types"
import { useSelector } from "react-redux"

import { menuFunctions } from "./functions.js"
import Menu from "./menu"
import { useTheme } from "../ThemeContext/ThemeContext"

function MenuBuilder({ forceVisitorHeader = false }) {
    const theme = useTheme()
    const hasArdoises = useSelector(state => state.hasArdoises)
    const nextBooking = useSelector(state => state.nextBooking)
    const hasUnpaidBills = useSelector(state => state.hasUnpaidBills)
    const user = useSelector(state => state.userLoaded)

    const data = {
        hasArdoises,
        hasUnpaidBills,
        nextBooking,
        userLoaded: user,
    }

    const finalTheme = forceVisitorHeader ? theme + "-visitor" : theme

    const { menuslevel1, menuslevel2 } = menuFunctions[finalTheme](data)

    return <Menu menuLevel1={menuslevel1} menuLevel2={menuslevel2} />
}

MenuBuilder.propTypes = {
    forceVisitorHeader: bool,
}

export default MenuBuilder
