import classNames from "classnames"
import dayjs from "dayjs"
import { number, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../components/Buttons/button/button-cta.jsx"
import FormValidator from "../../../../components/Forms/FormValidator"
import { SelectInput } from "../../../../components/Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../../../components/Inputs/TextInput/TextInput.jsx"
import LeagueCheckblock from "../../../../components/league/LeagueCheckblock/LeagueCheckblock.jsx"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import MessagePopin from "../../../../components/popin/MessagePopin/MessagePopin.jsx"
import ReservationSlotInfoCard from "../../../../components/reservation/slotInfoCard/ReservationSlotInfoCard"
import Step from "../../../../components/stepsManagement/Step"
import slugify from "../../../../entries/main/contents/main/helpers/removeAccent"
import useUrbanLanguage from "../../../../hooks/urbanLanguage/useUrbanLanguage.js"
import useAddSearchParam from "../../../../hooks/useAddSearchParams"
import useIsMobile from "../../../../hooks/useIsMobile"
import useMediaPath from "../../../../hooks/useMediaPath"
import useResetScroll from "../../../../hooks/useResetScroll"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import usePath from "../../../../routes/services/usePath"
import { clearAllForms, removeFormEntry, setFormEntry } from "../../../../store/forms/actions"
import { goToStep } from "../../../../store/stepsManagement/actions"
import PreForm from "../../../kids/components/preForm/PreForm"
import {
    getLeagueCenters,
    getUserLeagueRegistrations,
    listCenterLeagues,
    listLeagueDetail,
} from "../../../league/api/api.js"
import useCancelPreregistration from "../../hooks/useCancelPreregistration"
import useGetPrivateTournament from "../../hooks/useGetPrivateTournament"
import useTeamSubscriptionLoaded from "../../hooks/useTeamSubscriptionLoaded"
import useUpdateRegistration from "../../hooks/useUpdateRegistration"
import {
    getCenterCups,
    getCupCenters,
    getCupDetails,
    getCupList,
} from "../api"
import CupCheckblock from "../components/CupCheckblock/CupCheckblock"
import NoCup from "../components/NoCup/NoCup"

import "./ChoixTournois.scss"

function ChoixTournoi(props) {
    useResetScroll()
    const { tunnelType } = props
    const step   = useRef(null)
    const select = useRef(null)
    const form   = useRef(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = usePath()
    const isMobile = useIsMobile()
    const mediaPath = useMediaPath()
    const addSearchParams = useAddSearchParam()
    const { tu } = useUrbanLanguage()

    const editSubscription = useUpdateRegistration(tunnelType)
    const getPrivateTournament = useGetPrivateTournament(tunnelType)
    const cancelPreregistration = useCancelPreregistration(tunnelType)

    const [ userCenterId,        setUserCenterId ]        = useState({})
    const [ selectedCenterId,    setSelectedCenterId ]    = useState(null)
    const [ selectedCenterName,  setSelectedCenterName ]  = useState(null)
    const [ centers,             setCenters ]             = useState([])
    const [ loadingCups,         setLoadingCups ]         = useState(false)
    const [ cups,                setCups ]                = useState([])
    const [ selectedCup,         setSelectedCup ]         = useState(null)
    const [ selectedCupDetails,  setSelectedCupDetails ]  = useState(null)
    const [ ajaxLoading,         setAjaxLoading ]         = useState(false)
    const [ errorMsg,            setErrorMsg ]            = useState(null)
    const [ loadingCupDetails,   setLoadingCupsDetails ]  = useState(false)
    const [ userRegisteredCups,  setUserRegisteredCups ]  = useState(false)
    const [ buttonDisabled, setButtonDisabled ] = useState(true)
    const [ tournamentCodeCenterId,  setTournamentCodeCenterId ]  = useState(null)
    const [ tournamentLoading, setTournamentLoading ] = useState(null)
    const [ requestWip, setRequestWip ] = useState(false)

    const registration = useSelector(state => state.forms.TournamentRegistration)
    const selectedTeam = useSelector(
        state => state.forms.ChoixEquipe ?
            state.forms.ChoixEquipe.selectedTeam
            :
            undefined,
    )
    const userLoaded = useSelector(state => state.userLoaded)
    const tournamentCode = useSelector(state => state.forms.tournamentCode)
    const currentStep = useSelector(state =>  state.stepManagement.currentStep)
    const tunnelTournamentRegistration = useSelector(state =>  state.forms.tunnelTournamentRegistration)
    const {
        alreadySubscribedCupIds,
        alreadySubscribedLeagueIds,
        loadingTeamSubsIds,
    } = useTeamSubscriptionLoaded()
    const help = <PhoneCallHelp tunnelType={tunnelType}/>

    function handleTournamentResult(res) {
        setLoadingCups(false)
        if (res?.data?.data) {
            let filteredCups = res.data.data
            filteredCups = filteredCups.sort((a, b) => {
                a = a.start.split("/").reverse().join("")
                b = b.start.split("/").reverse().join("")
                return a.localeCompare(b)
            })
            if (filteredCups.length > 0 && registration && selectedCenterId === registration.centerId) {
                let allIds = filteredCups.map(x => x.id)
                let chosenId
                switch (tunnelType) {
                case "CUP":
                    chosenId = registration.cup.id
                    break
                case "LEAGUE":
                    chosenId = registration.championshipTeam.leagueId
                    break
                }
                if (
                    !allIds.includes(
                        chosenId,
                    ) && tournamentCodeCenterId
                ) {
                    let newTournament
                    switch (tunnelType) {
                    case "CUP":
                        newTournament = registration.cup
                        break
                    case "LEAGUE":
                        newTournament = registration.league
                        break
                    }
                    let newcup = []
                    newcup.push(newTournament)
                    setTournamentCode("CODESECRET")
                    setTournamentCodeCenterId(registration.centerId)

                    setCups(newcup)
                    selectCup(newTournament)
                } else {
                    setCups(filteredCups)

                }
            } else {
                setCups(filteredCups)
            }
            changeSelectedCenter()
        }
    }

    /** Initialisation de l'étape */
    useEffect(
        () => {
            setUserCenterId(userLoaded?.centerId)
            let gatherRegistrations
            const periodStart = dayjs(new Date()).subtract(3, "year").format("YYYY-MM-DD")
            switch (tunnelType) {
            case "CUP":
                gatherRegistrations = () => getCupList({
                    offset: 0,
                    orderByAsc: false,
                    periodStart: periodStart,
                    status: "[2,1,3]",
                })
                break

            case "LEAGUE":
                gatherRegistrations = () => getUserLeagueRegistrations({
                    offset: 0,
                    orderByAsc: false,
                    periodStart: periodStart,
                    status: "[2,1,3]",
                })
                break
            }

            gatherRegistrations().then(
                (res) => {
                    if (res) {
                        setUserRegisteredCups(res.items)
                    }
                },
            )

            let gatherCenters
            switch (tunnelType) {
            case "CUP":
                gatherCenters = getCupCenters
                break

            case "LEAGUE":
                gatherCenters = getLeagueCenters
                break
            }
            gatherCenters().then(
                (res) => {
                    const centers = res.data.data ? Object.values(res.data.data) : []
                    const filteredCenters = centers.filter((c) => c.id !== 40)
                    setCenters(filteredCenters)
                },
            )

            if (tournamentCode) {
                tryGetTournamentFromCode()
            }
        }, [],
    )

    /** Reload center cups */
    useEffect(
        () => {
            if (tournamentCodeCenterId === null && !tournamentCode && !requestWip) {
                reloadCentersCup()
            }
        }, [
            currentStep,
            selectedCenterId,
            selectedTeam,
            registration,
            userRegisteredCups,
            tournamentCodeCenterId,
        ])

    useEffect(
        () => {
            if (registration === undefined) {
                setSelectedCup(null)
                setSelectedCupDetails(null)
                unvalidateStep()
            }
        }, [ selectedTeam, registration ],
    )

    useEffect(
        () => {
            if (!selectedCup) {
                setSelectedCupDetails(null)
                unvalidateStep()
            }
        }, [ selectedCup ],
    )

    useEffect(
        () => {
            if (selectedCupDetails) {
                if (!selectedCup) {
                    setSelectedCupDetails(null)
                    unvalidateStep()
                }
                if (selectedCupDetails.remainingPlaces === 0) {unvalidateStep()}
            }
        }, [ selectedCupDetails ],
    )

    /** Remove error message when tournamentCode is deleted by user */
    useEffect(
        () => {
            if (!!tournamentCode && tournamentCode.length === 0) {
                setErrorMsg(null)
            }
        },[ tournamentCode ],
    )

    /** Check étape */

    useEffect(
        () => {
            if (currentStep > props.numero - 1) {
                // Si l'utilisateur n'a pas encore selectionné de tournois, on ne passe pas à la suite
                if (selectedCup === null && registration === undefined) {
                    dispatch(
                        goToStep(props.numero - 1),
                    )
                }
                // Sinon s'il n'y à pas d'inscription ou que le tournois ne corresponds pas à cette inscription, on la met à jour
                else if (
                    !registration ||
                    (
                        (!!registration && !!selectedCup) &&
                        (
                            (
                                registration.teamName !== selectedTeam.name &&
                                registration.teamName !== selectedTeam.teamName
                            ) ||
                            (
                                (registration.cup && registration.cup.id !== selectedCup.id) ||
                                (registration.championshipTeam && registration.championshipTeam.leagueId !== selectedCup.id)
                            )
                        )
                    )
                ) {
                    dispatch(
                        goToStep(props.numero - 1),
                    )
                    storeCupInfo().then(
                        () => {
                            dispatch(
                                goToStep(props.numero),
                            )
                        },
                    )
                }
            }
        }, [ props.cls, currentStep, selectedTeam ],
    )

    function getFilter() {
        switch (tunnelType) {
        case "CUP":
            return x => x.id === registration.cup.id

        case "LEAGUE":
            return x => x.id === registration.league.id
        }
    }

    /** Mise à jour auto du tournois selectionné / du récap */
    useEffect(
        () => {
            if (registration) {
                if (cups.length > 1) {
                    let registrationCup = cups.filter(getFilter())[0]
                    if (registrationCup) {
                        selectCup(registrationCup)
                    }
                }
                if (!selectedCenterId) {
                    if (selectedCenterId !== registration.centerId) {
                        setSelectedCenterId(registration.centerId)
                    }
                }
                if (step.current) {
                    step.current.setRecap(
                        <div>
                            {registration.cup &&
                                registration.cup.name
                            }
                            {registration.league &&
                                registration.league.name
                            }
                            <br/>
                            {registration.centerName}
                        </div>,
                    )
                }
            }

        }, [ registration, cups ],
    )

    /** Update centers */
    useEffect(
        () => {
            changeSelectedCenter()
        }, [ centers ],
    )

    function reloadCentersCup() {
        if ((selectedTeam && selectedCenterId) || registration) {
            setRequestWip(true)
            setButtonDisabled(true)
            setLoadingCups(true)
            setCups([])
            const cupDetailsRestore = selectedCupDetails && selectedCupDetails.id ?
                { ...selectedCupDetails } : null
            if (cupDetailsRestore) {
                setSelectedCupDetails("Loading")
            } else {
                setSelectedCupDetails(null)
            }

            let gatherMethod
            switch (tunnelType) {
            case "CUP":
                gatherMethod = getCenterCups
                break
            case "LEAGUE":
                gatherMethod = listCenterLeagues
                break
            }
            if (selectedTeam && selectedCenterId && currentStep === 1) {
                gatherMethod(
                    selectedCenterId,
                    selectedTeam.id,
                ).then((res) => {
                    setButtonDisabled(false)
                    setRequestWip(false)
                    handleTournamentResult(res)
                    if (cupDetailsRestore) {
                        setSelectedCupDetails(cupDetailsRestore)
                    }
                })
            } else if (registration && currentStep === 1) {
                gatherMethod(
                    registration.centerId,
                    registration.teamId,
                ).then((res) => {
                    setButtonDisabled(false)
                    setRequestWip(false)
                    handleTournamentResult(res)
                    if (cupDetailsRestore) {
                        setSelectedCupDetails(cupDetailsRestore)
                    }
                })
            } else {
                setRequestWip(false)
            }
        }
    }

    function changeSelectedCenter() {
        const newValue = select?.current?.value ? select?.current?.value : userLoaded?.centerId

        if (newValue && centers?.find(center => center.id === parseInt(newValue))) {
            if (newValue !== selectedCenterId && selectedCenterId !== null) {
                clearTournamentCode()
                setTournamentCodeCenterId(null)
                setSelectedCup(null)
                setSelectedCupDetails(null)
                unvalidateStep()
            }

            setSelectedCenterId(newValue)
            const currentCenter = centers.filter(x => x.id === parseInt(newValue))

            if (currentCenter.length > 0) {
                setSelectedCenterName(
                    currentCenter[0].name,
                )
            }
        }
    }

    function clearTournamentCode() {
        dispatch(
            setFormEntry(
                "tournamentCode",
                "",
            ),
        )
    }

    function setTournamentCode(code) {
        dispatch(
            setFormEntry(
                "tournamentCode",
                code,
            ),
        )
    }

    function onCupClick(event) {
        event.stopPropagation()
        event.preventDefault()
        const clickedCup = cups?.filter(x => x.id === parseInt(event.currentTarget.id))[0]
        if (selectedCup && selectedCup.id === clickedCup.id) {
            addSearchParams(tunnelType === "CUP" ? "cupId" : "leagueId", "none")
            setSelectedCup(null)
            setSelectedCupDetails(null)
            unvalidateStep()
        } else {
            addSearchParams(tunnelType === "CUP" ? "cupId" : "leagueId", clickedCup?.id)
            selectCup(clickedCup)
        }
    }

    function selectCup(cup) {
        setLoadingCupsDetails(true);
        (
            cup.remainingPlaces === 0
            || (!alreadySubscribedLeagueIds && !alreadySubscribedCupIds)
            || (alreadySubscribedLeagueIds && alreadySubscribedLeagueIds.includes(cup.id))
            || (alreadySubscribedCupIds && alreadySubscribedCupIds.includes(cup.id))
            || ((!cup.full || !cup.isFull) && !cup.canRegister && !!cup.reservedForVIPUntil)
            || (tunnelType === "CUP" && cup.isFull)
        ) ?
            unvalidateStep()
            :
            validateStep()

        if (alreadySubscribedCupIds || alreadySubscribedLeagueIds) {
            if (
                (alreadySubscribedCupIds && alreadySubscribedCupIds.includes(cup.id)) ||
                (alreadySubscribedLeagueIds && alreadySubscribedLeagueIds.includes(cup.id))
            ) {
                addSearchParams(tunnelType === "CUP" ? "cupId" : "leagueId", "none")
                setSelectedCup(null)
                setLoadingCupsDetails(false)
                setSelectedCupDetails(null)
                unvalidateStep()
                return
            }
        }

        setSelectedCup(cup)
        setSelectedCupDetails("Loading")

        let getDetailsMethod
        switch (tunnelType) {
        case "CUP":
            getDetailsMethod = getCupDetails
            break
        case "LEAGUE":
            getDetailsMethod = listLeagueDetail
            break
        }

        if (!loadingCupDetails) {
            getDetailsMethod(cup.id).then(
                (r) => {
                    setLoadingCupsDetails(false)
                    setSelectedCupDetails(r.data.data)
                },
            )
        }
    }

    function validateStep() {
        if (step.current) {
            if (!loadingCupDetails) {step.current.validate()}
        }
    }

    function unvalidateStep() {
        if (step.current) {
            step.current.unvalidate()
        }
    }

    function changeRegistration(registration) {
        dispatch(
            setFormEntry(
                "TournamentRegistration",
                registration,
            ),
        )
    }

    function storeCupInfo() {
        let teamId = selectedTeam.id ?
            selectedTeam.id
            :
            "_NEW"

        return new Promise(
            (mainResolve) => {
                let promise
                if (registration) {
                    if (
                        (registration.cup && registration.cup.id !== selectedCup.id)
                        || (registration.championshipTeam && registration.championshipTeam.leagueId !== selectedCup.id)
                    ) {
                        promise = new Promise(
                            (resolve) => {
                                cancelPreregistration(registration.id).then(
                                    () => {
                                        editSubscription(
                                            selectedCenterId,
                                            selectedCup.id,
                                            teamId,
                                            selectedTeam.name ?
                                                selectedTeam.name
                                                :
                                                selectedTeam.teamName,
                                            selectedTeam.blazon,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            selectedTeam?.captainId,
                                            selectedTeam?.viceCaptainId,
                                        ).then((res) => {
                                            if (res) {
                                                changeRegistration(res.data.data)
                                            }
                                            dispatch(
                                                setFormEntry(
                                                    "tunnelTournamentRegistration",
                                                    {
                                                        ...tunnelTournamentRegistration,
                                                        isNewRegistration: true,
                                                        teamDataChange: true,
                                                    },
                                                ),
                                            )
                                            resolve()
                                        }).catch((e) => {
                                            if (e?.response?.data?.data?.Message) {
                                                ModalHandler.show(MessagePopin, {
                                                    message: e?.response?.data?.data?.DetailedMessage || e?.response?.data?.data?.Message,
                                                    title: "Erreur",
                                                })
                                            }
                                        }).finally(() => setAjaxLoading(false))
                                    },
                                )
                            },
                        )
                    }
                } else {
                    promise = editSubscription(
                        selectedCenterId,
                        selectedCup.id,
                        teamId,
                        selectedTeam.name ?
                            selectedTeam.name
                            :
                            selectedTeam.teamName,
                        selectedTeam.blazon,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        selectedTeam?.captainId,
                        selectedTeam?.viceCaptainId,
                    ).then((res) => {
                        changeRegistration(res.data.data)
                        dispatch(
                            setFormEntry(
                                "tunnelTournamentRegistration",
                                {
                                    ...tunnelTournamentRegistration,
                                    isNewRegistration: true,
                                },
                            ),
                        )
                        setAjaxLoading(false)
                    }).catch((e) => {
                        if (e?.response?.data?.data?.Message) {
                            dispatch(
                                goToStep(0),
                            )
                            ModalHandler.show(MessagePopin, {
                                message: e?.response?.data?.data?.DetailedMessage || e?.response?.data?.data?.Message,
                                title: "Erreur",
                            })
                        }
                    })
                }

                if (promise) {
                    setAjaxLoading(true)
                    promise.then(
                        () => {
                            dispatch(
                                removeFormEntry("avoirFormData"),
                            )
                            mainResolve()
                            setAjaxLoading(false)
                        },
                    ).catch(() => {
                        setAjaxLoading(false)
                    })
                } else {
                    dispatch(
                        removeFormEntry("avoirFormData"),
                    )
                    mainResolve()
                }

                if (step.current) {
                    step.current.setRecap(
                        <div>
                            {selectedCup.name}
                            <br/>
                            {selectedCenterName}
                        </div>,
                    )
                }
            },
        )
    }

    function subscribeToWaitingList() {
        let teamId = selectedTeam.id ?
            selectedTeam.id
            :
            "_NEW"

        setAjaxLoading(true)
        editSubscription(
            selectedCenterId,
            selectedCup.id,
            teamId,
            selectedTeam.name ?
                selectedTeam.name
                :
                selectedTeam.teamName,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
            undefined,
            selectedTeam?.captainId,
            selectedTeam?.viceCaptainId,
        ).then(
            (res) => {
                setAjaxLoading(false)

                clearAllForms()
                goToStep(0)
                navigate(
                    path("/confirmcommand"), {
                        state: {
                            confirmedSubscription: res.data.data,
                            message: "Un email te sera envoyé en cas de places disponibles.",
                            subtitle: "Nous te confirmons l'inscription de ton équipe en liste d’attente.",
                            success: true,
                            title: "Inscription à la liste d’attente",
                            tunnelType: tunnelType,
                        },
                    },
                )
            },
        )
    }

    function tryGetTournamentFromCode() {
        const codeFormatted = slugify(tournamentCode)
        if (codeFormatted && codeFormatted !== "") {
            setTournamentLoading(true)
            getPrivateTournament(codeFormatted).then(
                (res) => {
                    if (res) {
                        if (!res.data.data.Message) {
                            let sub = res.data.data
                            let newCups = []

                            if (tunnelType === "CUP") {
                                newCups.push(sub)
                                setTournamentCodeCenterId(sub.centerId)
                                setCups(newCups)
                                selectCup(sub)
                                setErrorMsg(null)
                            } else {
                                listLeagueDetail(sub.id).then(
                                    (res) => {
                                        if (!res?.data?.data?.id) {
                                            setErrorMsg("Code incorrect")
                                        } else {
                                            newCups.push(sub)
                                            setTournamentCodeCenterId(sub.centerId)
                                            setCups(newCups)
                                            selectCup(sub)
                                            setErrorMsg(null)
                                        }
                                    },
                                )

                            }
                        } else {
                            setErrorMsg("Code incorrect")
                        }
                    } else {
                        setErrorMsg("Code incorrect")
                    }
                    setTournamentLoading(false)
                },
            )
        }

    }

    function clearTournament() {
        clearTournamentCode()
        setTournamentCodeCenterId(null)
        setSelectedCup(null)
    }

    function getTitle() {
        switch (tunnelType) {
        case "CUP":
            return "Tournois"
        case "LEAGUE":
            return tu("league.name")
        default:
            return `NOT IMPLEMENTED TunnelType : ${tunnelType}`
        }
    }

    function getPromoCodeLabel() {
        switch (tunnelType) {
        case "CUP":
            return "Rejoins un tournoi existant :"
        case "LEAGUE":
            return `Rejoins ${tu("league.undefinedArticle")} ${tu("league.name")} privée :`
        }
    }

    return (
        <Step
            {...props}
            title={getTitle()}
            help={ help }
            helpId={"monTournoiHelp"}
            onValidation={storeCupInfo}
            promise={true}
            className={classNames(tunnelType, "noMarginBottom", "tournamentChoice")}
            ref={step}
        >
            {ajaxLoading &&
                <Preloader fixed={true}/>
            }
            <PreForm>
                <div className="flexVerticalOnMobile">
                    <label className="h2">
                        Sélectionne ton centre :
                        <SelectInput
                            ref={select}
                            label={"Centre"}
                            id={"schoolPicker"}
                            options={centers.length > 0 ?
                                centers.map(x => ({ label: x.name, value: x.id }))
                                : undefined }
                            disabled={buttonDisabled}
                            value={ tournamentCodeCenterId ?
                                tournamentCodeCenterId
                                :
                                selectedCenterId ?
                                    selectedCenterId
                                    :
                                    userCenterId
                            }
                            onChange={changeSelectedCenter}
                            placeholderOption="Séléctionne un centre"
                            required
                        />
                    </label>
                </div>

                <TextInput
                    labelOnTop
                    label={getPromoCodeLabel()}
                    customRootCls={classNames("codeInput", { tournamentCodeCenterId: tournamentCodeCenterId })}
                    value={tournamentCode}
                    onChange={setTournamentCode}
                    placeholder='Entre ton code'
                    error={errorMsg}
                    actions={[ {
                        onClick: tournamentLoading ?
                            () => {}
                            :
                            tournamentCodeCenterId ?
                                clearTournament
                                :
                                tryGetTournamentFromCode,
                        render: <ButtonCta
                            text={tournamentLoading ?
                                <Preloader/>
                                :
                                tournamentCodeCenterId ?
                                    (
                                        <img
                                            className="closeCross"
                                            src={mediaPath([ "/assets/icons/cross-white.svg", "/assets/icons/cross-black.svg" ])}
                                        />
                                    )
                                    :
                                    "OK"
                            }
                            noSkew
                            noShadow
                        />,
                    } ]}
                />
            </PreForm>

            <div className="formsHolder centerPicker c-row justify-center align-start layoutSmall">
                { (loadingCups === false && cups.length === 0 && centers.length > 0 && selectedCenterId !== null) ?
                    <NoCup  tunnelType={tunnelType} selectedCenterName={selectedCenterName} />
                    :
                    (<React.Fragment>
                        <div className="c-col c-col--6 c-col--sm--12 flex space-between align-center wrap ">
                            <FormValidator
                                onValidation={validateStep}
                                onUnvalidation={unvalidateStep}
                                ref={form}
                            >
                                <div className={tunnelType === "CUP" ? "radioList sm--checkbox" : "radioList--small--half sm--checkbox"}>
                                    {(loadingCups || loadingTeamSubsIds) ?
                                        <Preloader />
                                        :
                                        cups.map(
                                            (cup) => (
                                                tunnelType === "CUP" ? (
                                                    <CupCheckblock
                                                        cup={cup}
                                                        selectedCup={selectedCup}
                                                        onClick={onCupClick}
                                                        isMobile={isMobile}
                                                        selectedCupDetails={selectedCupDetails}
                                                        subscribeToWaitingList={subscribeToWaitingList}
                                                        key={"cup-" + cup.id}
                                                        isLoading={loadingCupDetails}
                                                    />
                                                ) : tunnelType === "LEAGUE" ? (
                                                    <React.Fragment key={"league-" + cup.id}>
                                                        <LeagueCheckblock
                                                            league={cup}
                                                            selectedLeague={selectedCup}
                                                            onClick={onCupClick}
                                                            isMobile={isMobile}
                                                            selectedLeagueDetails={selectedCupDetails}
                                                            subscribeToWaitingList={subscribeToWaitingList}
                                                            isLoading={loadingCupDetails}
                                                        />
                                                        {isMobile && (
                                                            <React.Fragment>
                                                                {selectedCupDetails && selectedCupDetails.id === cup.id && (
                                                                    <div
                                                                        className={
                                                                            "c-col c-col--3 c-col--sm--12" +
                                                                            " tooltip-description tournoi-tooltip-description left active"
                                                                        }
                                                                        id={"description-card-" + (cup.id)}
                                                                        key={"description-card-" + (cup.id)}
                                                                    >
                                                                        <ReservationSlotInfoCard
                                                                            reservationSlot={selectedCupDetails}
                                                                            subscribeToWaitingListCallback={subscribeToWaitingList}
                                                                            slotName={tu("league.name")}
                                                                            pleaseSelectText={tu("league.undefinedArticle") + tu("league.name")}
                                                                            tunnelType="LEAGUE"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {tunnelType} not implemented
                                                    </React.Fragment>
                                                )
                                            ),
                                        )
                                    }
                                </div>
                            </FormValidator>
                        </div>
                        <div
                            className="c-col c-col--3 c-col--sm--12 flex space-between align-center wrap c-col--offset--left--md--1 sticky">
                            {!isMobile && (
                                <ReservationSlotInfoCard
                                    reservationSlot={selectedCupDetails}
                                    subscribeToWaitingListCallback={subscribeToWaitingList}
                                    slotName={tunnelType === "CUP" ? "tournoi" : tu("league.name")}
                                    pleaseSelectText={tunnelType === "CUP" ? "ton tournoi" : `${tu("league.undefinedArticle")} ${tu("league.name")}`}
                                    tunnelType={tunnelType}
                                />
                            )}
                        </div>
                    </React.Fragment>)
                }
            </div>
        </Step>
    )
}

ChoixTournoi.propTypes = {
    cls: string,
    numero: number,
    tunnelType: string,
}

export default ChoixTournoi
