import classNames from "classnames"
import { bool, func, number, object, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"
import { IMaskInput } from "react-imask"
import { useDispatch, useSelector } from "react-redux"

import AvoirCheckbox from "../../../entries/main/contents/main/helpers/AvoirCheckbox"
import { deleteCreditCard, getCreditCard } from "../../../globalAPI/api.js"
import useUrbanLanguage from "../../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../../hooks/useMediaPath"
import { checkGymlibCodeValidity } from "../../../pages/payments/OrderSummary/api.js"
import GymlibLine from "../../../pages/payments/OrderSummary/components/GymlibLine/GymlibLine"
import getPartsSelect from "../../../pages/payments/OrderSummary/services/getPartsSelect"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import formatTwoDigits from "../../../services/formatTwoDigits"
import { setFormEntry } from "../../../store/forms/actions"
import ButtonCta from "../../Buttons/button/button-cta"
import { SelectInput } from "../../Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../Inputs/TextInput/TextInput"
import Preloader from "../../loaders/preloader/preloader.jsx"
import Modal from "../../Modal/Modal"
import CbAliasError from "../../popin/CbAliasError/CbAliasError.jsx"
import playersNumber from "../LocationBlock/playersNumber"

import "./PayFreeAmount.scss"

const PayFreeAmount = ({
    registration,
    paiementCallback,
    withMultiPayments = false,
    withGymlibPayments,
    tunnelType,
    setAmountToPay,
    haveDiscount,
    billMinAmount,
    canPayWithUsCard,
    setCanPayWithUsCardModalOpen,
    withPartPayment,
    pricePerPart,
    withAvoir,
    isOpen,
    setIsOpen,
    onUseAlias,
    canUseAlias,
}) => {
    const [ partOptions, setPartOptions ] = useState([])
    const [ part, setPart ] = useState(1)
    const [ isGymlib, setIsGymlib ] = useState("")
    const [ gymlibCode, setGymlibCode ] = useState("")
    const [ validGymlibCode, setValidGymlibCode ] = useState()
    const [ codeError, setCodeError ] = useState(false)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ cbCard, setCbCard ] = useState(null)
    const [ saveCard, setSaveCard ] = useState(false)

    const dispatch = useDispatch()
    const avoirFormData = useSelector(state => state.forms.avoirFormData)
    if (!registration) {
        registration = {}
    }

    const mediaPath = useMediaPath()
    const { tu } = useUrbanLanguage()

    let { centerName, amountTotal, dueAmount, league } = registration
    let type

    if (tunnelType === "BOOKING") {
        if (registration.resourceTypeDisplay) {type = playersNumber.find(type => type.types.includes(registration.resourceType))?.players}
        dueAmount = dueAmount ?? registration.amountDue
        if (registration.discountedPrice) {
            amountTotal = registration.discountedPrice
        }

    }

    if (!league) {
        league = registration
    }

    let { name, start } = league

    if (start?.includes("T")) {
        let dateStart = new Date(start)
        start = formatTwoDigits(dateStart.getDate()) + "/" + formatTwoDigits(dateStart.getMonth() + 1) + "/" + dateStart.getFullYear()
    }

    const minAmountCalculation = tunnelType === "BOOKING"
        ?
        !withPartPayment
            ?
            !type
                ?
                (amountTotal / 10)?.toFixed(2)
                :
                (amountTotal / type)?.toFixed(2)
            : pricePerPart && (dueAmount >  pricePerPart &&  dueAmount < 2 * pricePerPart) ? dueAmount?.toFixed(2) : pricePerPart?.toFixed(2)
        :
        avoirFormData && avoirFormData.avoirAmount !== undefined && avoirFormData.checkAvoir
            ?
            (avoirFormData.avoirAmount >= (registration.leaguePricing / 10))
                ?
                0
                :
                displayDecimals((registration.leaguePricing / 10) - avoirFormData.avoirAmount)
            :
            amountTotal < (registration.leaguePricing / 10)
                ?
                displayDecimals(amountTotal)
                :
                dueAmount < (registration.leaguePricing / 10)
                    ?
                    displayDecimals(dueAmount)
                    :
                    displayDecimals(registration.leaguePricing / 10)

    const pricePercentage = withPartPayment ? 100 / registration.playersNumber : type && tunnelType === "BOOKING" ?
        100 / type
        : undefined

    const [ minAmount, setMinAmount ] = useState(minAmountCalculation)
    const isDueAmountLowerThanMinAmount = (dueAmount < (minAmount || minAmountCalculation))

    const [ amountTotalDisplay, setAmountTotalDisplay ] = useState(amountTotal)
    const [ freeAmount, setFreeAmount ] = useState(
        withPartPayment ? pricePerPart : isDueAmountLowerThanMinAmount ?
            dueAmount.toString()
            :
            minAmount ?
                minAmount.toString()
                :
                "",
    )

    const [ errorMessage, setErrorMessage ] = useState(undefined)

    if (dueAmount === null || dueAmount === undefined) {
        dueAmount = amountTotalDisplay
    }

    useEffect(
        () => {
            if (tunnelType !== "BOOKING")  {
                handleAvoir()
            }
        }, [ avoirFormData, amountTotal ],
    )

    useEffect(
        () => {
            setFreeAmount(
                withPartPayment ? pricePerPart : isDueAmountLowerThanMinAmount ?
                    dueAmount.toString()
                    : minAmount ?
                        minAmount.toString()
                        :
                        "",
            )
        }, [ isDueAmountLowerThanMinAmount, pricePerPart ],
    )

    useEffect(
        () => {
            setAmountTotalDisplay(amountTotal)
            if (amountTotal && haveDiscount) {
                if (tunnelType === "BOOKING") {
                    withPartPayment ?
                        setFreeAmount(pricePerPart?.toFixed(2))
                        :
                        setFreeAmount((amountTotal / type)?.toFixed(2))
                } else {
                    setFreeAmount((amountTotal / 10)?.toFixed(2))
                }
            }
        },[ amountTotal, pricePerPart ],
    )

    useEffect(
        () => {
            if (tunnelType && tunnelType === "BILL" && !!billMinAmount) {
                setMinAmount(parseFloat(billMinAmount).toFixed(2))
            }
        }, [ billMinAmount, minAmount ],
    )

    const partPriceToPay = (freeAmount > dueAmount ? dueAmount : freeAmount)

    useEffect(() => {
        if (registration && withPartPayment && partPriceToPay > 0) {
            setPartOptions(getPartsSelect(registration, Math.floor(registration.dueAmount / pricePerPart), null, undefined, part))

            const gymlibPayout = validGymlibCode?.payout ?? 0

            setFreeAmount(partPriceToPay - gymlibPayout)
        }
    }, [ registration, withPartPayment, partPriceToPay, pricePerPart, validGymlibCode ])
    useEffect(() => {
        dispatch(setFormEntry("avoirFormData", {}))
    }, [ part ])

    useEffect(() => {
        if (validGymlibCode) {
            dispatch(
                setFormEntry("avoirFormData", {
                    ...avoirFormData,
                    avoirAmount: undefined,
                    checkAvoir: false,
                    totalWithAvoir: undefined,
                    tvaWithAvoir: undefined,
                }),
            )
        }
    }, [ validGymlibCode ])

    function handleAvoir() {
        let notUsingDueAmount = false
        if (dueAmount === 0 || dueAmount === null || dueAmount === undefined) {
            notUsingDueAmount = true
        }

        // handle free amount with avoir
        if (avoirFormData?.avoirAmount !== undefined && avoirFormData.checkAvoir) {
            setAmountTotalDisplay(avoirFormData.totalWithAvoir)
            if (avoirFormData.avoirAmount >= (registration.leaguePricing / 10)) {
                setFreeAmount(0)
                setMinAmount(0)
            } else {
                setFreeAmount((registration.leaguePricing / 10) - avoirFormData.avoirAmount)
                setMinAmount(displayDecimals((registration.leaguePricing / 10) - avoirFormData.avoirAmount))
            }
        } else {
            if (amountTotal < (registration.leaguePricing / 10)) {
                setFreeAmount(amountTotal)
                setMinAmount(displayDecimals(amountTotal))
            } else if ((dueAmount < (registration.leaguePricing / 10)) && !notUsingDueAmount) {
                setFreeAmount(dueAmount)
                setMinAmount(displayDecimals(dueAmount))
            } else {
                setFreeAmount(registration.leaguePricing / 10)
                setMinAmount(displayDecimals(registration.leaguePricing / 10))
            }
            setAmountTotalDisplay(amountTotal)
        }
    }

    function displayDecimals(number) {
        return number ?
            !(Math.floor(+number) === +number) ?
                Number.parseFloat(number)?.toFixed(2)
                :
                parseFloat(number)?.toFixed(2)
            :
            ""

    }

    const handlePartChange = (selectedPart) => {
        if (withPartPayment && pricePerPart) {
            setPart(selectedPart)
            const isPayingHigherLastPart = dueAmount - (pricePerPart * selectedPart) >= pricePerPart
            const priceToPay = isPayingHigherLastPart ? pricePerPart * selectedPart : dueAmount
            setFreeAmount(priceToPay?.toFixed(2))
        }
    }

    const checkGymlibCode = () => {
        setAjaxLoading(true)
        checkGymlibCodeValidity(gymlibCode, registration.id, null, "reservation").then(
            (res) => {
                if (res?.status === 200 && res?.data?.data?.data?.payout !== undefined) {
                    dispatch(
                        setFormEntry("avoirFormData", {
                            ...avoirFormData,
                            avoirAmount: undefined,
                            checkAvoir: false,
                            totalWithAvoir: undefined,
                            tvaWithAvoir: undefined,
                        }),
                    )
                    setValidGymlibCode({
                        code: gymlibCode,
                        payout: res?.data?.data?.data?.payout,
                    })
                } else {
                    setValidGymlibCode(null)
                    setCodeError(true)
                }
            }).finally(() => setAjaxLoading(false))
    }

    const deleteGymlibCode = () => {
        setGymlibCode("")
        setIsGymlib("notGymlib")
        setValidGymlibCode(false)
    }

    useEffect(() => {
        if (registration.id && tunnelType === "BOOKING") {
            setMinAmount(displayDecimals(minAmountCalculation))
            handlePartChange(1)
        }
    }, [ registration.id, withPartPayment ])

    useEffect(() => {
        if (cbCard === null) {
            setAjaxLoading(true)
            getCreditCard().then(
                (result) => {
                    setAjaxLoading(false)
                    if (result?.data?.data?.tokenDetails) {
                        setCbCard(result.data.data)
                    }
                    if (result?.data?.data?.errorCode) {
                        deleteCreditCard()
                        ModalHandler.show(CbAliasError)
                    }
                },
            )
        }
    }, [ ])

    return (
        <div className="medium payFreeAmount">
            {ajaxLoading && (
                <div className="z-top">
                    <Preloader fixed/>
                </div>
            )}
            <Modal
                closeOnEsc={false}
                isOpen={isOpen}
                onClose={() => {
                    handlePartChange(1)
                    dispatch(setFormEntry("avoirFormData", {}))
                    setIsOpen(false)
                }}
            >
                <header>{ tunnelType === "BOOKING" ? " Payer " : " Payer ma part " }</header>
                <section className="note-holder">
                    {tunnelType !== "BOOKING" && tunnelType !== "BILL" && (
                        <div className="global-infos">
                            <div>
                                <div className="info-div">
                                        Centre : <strong>{centerName}</strong><br/>
                                </div>
                                <div className="info-div">
                                    {tu("league.name")} : <strong>{name}</strong>
                                </div>
                            </div>
                            <div>
                                <div className="info-div-date">
                                        Début : <strong>{start}</strong><br/>
                                </div>
                                <div className="info-div">
                                        Prix : <strong>{displayDecimals(amountTotalDisplay)}&nbsp;EUROS</strong>
                                </div>
                            </div>
                        </div>
                    )}
                    <article className="articlePayFreeAmount">
                        <div className="price-line">
                            <span>Montant Total :</span>
                            <span>{displayDecimals(amountTotalDisplay)}&nbsp;€</span>
                        </div>

                        {dueAmount !== amountTotalDisplay && (
                            <div className="price-line">
                                <span>Montant restant dû :</span>
                                <span>
                                    { displayDecimals(dueAmount) }&nbsp;€
                                </span>
                            </div>
                        )}
                        { dueAmount >= (parseFloat(minAmount) || !!parseFloat(minAmountCalculation)) && (
                            <div className="price-line">
                                <span>
                                        Montant {haveDiscount ? "" : "minimum"}
                                    <span className="show-sm"><br/></span>
                                    <span className="hide-sm">&nbsp;</span>
                                        à régler
                                    {!!pricePercentage &&
                                            " (" + (pricePercentage % 1 === 0 ? pricePercentage : pricePercentage?.toFixed(2)) + "%)"
                                    }
                                </span>
                                <span>
                                    {(minAmount !== "NaN" && minAmount) || (minAmountCalculation !== "NaN" && minAmountCalculation)}
                                        &nbsp;€
                                </span>
                            </div>
                        )}

                        {!!pricePerPart && pricePerPart > 0 && withAvoir && (
                            <div className={classNames("payment-line-avoir", {
                                havePadding: withGymlibPayments,
                            })}>
                                <AvoirCheckbox
                                    disabled={!!validGymlibCode || !!isGymlib}
                                    totalAmount={parseFloat(freeAmount)}
                                    formName="avoirFormData"
                                    tva={freeAmount ? (freeAmount / 6).toFixed(2) : null}
                                    customActionMessage={"Utiliser mon avoir pour payer"}
                                    targetDate={registration?.start}
                                />
                            </div>
                        )}
                        {(withGymlibPayments && avoirFormData.totalWithAvoir === undefined) && (
                            <div className={classNames("gymlibPayment", {
                                haveBorder: !!pricePerPart && pricePerPart > 0 && withAvoir,
                            })}>
                                <div className="isGymlib">
                                    <GymlibLine />
                                    <div className="box" onClick={() => {
                                        setCodeError(false)
                                        setIsGymlib(prev => prev ? "" : "isGymlib")
                                        handlePartChange(1)
                                    }}>
                                        <div className={classNames("checkedElement", { hidden: isGymlib !== "isGymlib" })} />
                                    </div>
                                </div>
                                {isGymlib && (
                                    <TextInput
                                        label={"Code Gymlib"}
                                        value={gymlibCode}
                                        customRootCls="inputCupCode"
                                        onChange={setGymlibCode}
                                        disabled={!!validGymlibCode}
                                        actions={[ {
                                            onClick:
                                                validGymlibCode ?
                                                    deleteGymlibCode
                                                    :
                                                    checkGymlibCode,

                                            render: validGymlibCode ? (
                                                <img src={mediaPath([ "/assets/icons/cross-orange.svg", "/assets/images/padel/icons/cross-close.svg" ])}/>
                                            ) : (
                                                <ButtonCta
                                                    text="OK"
                                                    noSkew
                                                    noShadow
                                                />),
                                        } ]}
                                    />
                                )}
                                {(codeError || validGymlibCode) && (
                                    <div className="rowPromo">
                                        <div className="info">{validGymlibCode ? "Code activé" : "Code incorrect"}</div>
                                    </div>
                                )}
                            </div>
                        )}

                        {canUseAlias && (
                            <>
                                {!(cbCard && Object.keys(cbCard).length > 0) && (<div className="c-inputHolder--checkbox cb-div">
                                    <input
                                        type="checkbox"
                                        id="saveCard"
                                        checked={saveCard}
                                        value=""
                                        onChange={() => setSaveCard(!saveCard)}
                                    />
                                    <label>
                                        J'enregistre ma carte
                                    </label>
                                </div>)}

                                {cbCard && Object.keys(cbCard).length > 0 && (
                                    <div className="c-inputHolder--checkbox cb-div">
                                        <input
                                            type="checkbox"
                                            id="cb"
                                            onChange={(e) => onUseAlias(e.target.checked)}
                                        />
                                        <label>
                                            <span>J'utilise ma carte enregistrée </span>
                                            <span>({cbCard.tokenDetails.pan})</span>
                                        </label>
                                    </div>
                                )}
                            </>
                        )}

                        <div className={classNames("payment-line",
                            { amountError: errorMessage, partsLine: withPartPayment },
                        )}>
                            <span className="priceToPay">Montant {haveDiscount ? "" : "libre "}à régler :</span>
                            <div className={"priceAndPartContainer"}>
                                {withPartPayment ? (
                                    <SelectInput
                                        id={"partsNumber"}
                                        options={partOptions?.map(partOpt => {
                                            return { label: partOpt[1], value: partOpt[0] }
                                        }) ?? []}
                                        value={part}
                                        required
                                        disabled={partOptions?.length === 1 || (tunnelType === "BOOKING" && registration?.discount?.type === 0) || isGymlib === "isGymlib"}
                                        onChange={(event) => handlePartChange(event.target.value)}
                                        customArrow={[ "url('/assets/icons/arrow-down-orange.svg')", "url('/assets/images/padel/icons/icon-arrow-down-red.svg')" ]}
                                    />
                                ) : <div />}
                                <div className='input-price-holder'>
                                    <IMaskInput
                                        inputMode="decimal"
                                        mask={Number}
                                        scale={2}
                                        value={
                                            avoirFormData?.totalWithAvoir !== undefined
                                                ?
                                                tunnelType === "LEAGUE"
                                                    ?
                                                    avoirFormData.avoirAmount >= (registration.leaguePricing / 10)
                                                        ? "0"
                                                        : ((registration.leaguePricing / 10) - avoirFormData.avoirAmount).toString()
                                                    : avoirFormData.totalWithAvoir.toString()
                                                :
                                                withPartPayment
                                                    ?
                                                    parseFloat(freeAmount > dueAmount ? dueAmount : freeAmount)?.toFixed(2)?.toString()
                                                    :
                                                    haveDiscount
                                                        ?
                                                        parseFloat(minAmountCalculation)?.toFixed(2)?.toString()
                                                        :
                                                        freeAmount && freeAmount !== ""
                                                            ?
                                                            freeAmount.toString()
                                                            :
                                                            ""
                                        }
                                        onKeyDown={
                                            (e) => {
                                                const charactersToExclude = [ "E", "e", "Dead", "+", "-" ]
                                                if (charactersToExclude.includes(e.key)) {
                                                    e.preventDefault()
                                                }
                                            }
                                        }
                                        onChange={
                                            (e) => {
                                                const value = e.target.value
                                                const havePoint = value.split(".")
                                                const floatValue = havePoint[1] && havePoint[1].length === 1 ? value : parseFloat(e.target.value)
                                                if (parseFloat(floatValue) < (minAmount || minAmountCalculation)) {
                                                    setErrorMessage("Erreur - saisis un montant égal ou supérieur au montant minimum à régler.")
                                                } else if (parseFloat(floatValue) > (dueAmount ?? amountTotal)) {
                                                    setErrorMessage("Erreur - saisis un montant égal ou inférieur au montant total à régler.")
                                                } else {
                                                    setErrorMessage(undefined)
                                                }
                                                value === "" ?
                                                    setFreeAmount("")
                                                    :
                                                    setFreeAmount(floatValue)

                                                if (tunnelType === "BILL" && setAmountToPay) {
                                                    value === "" ?
                                                        setAmountToPay("")
                                                        :
                                                        setAmountToPay(floatValue)

                                                }
                                            }
                                        }
                                        onBlur={
                                            () => {
                                                setFreeAmount(parseFloat(freeAmount)?.toFixed(2))
                                            }
                                        }
                                        disabled={withPartPayment}
                                        placeholder={((minAmount !== "NaN" && minAmount) || (minAmountCalculation !== "NaN" && minAmountCalculation)) ? (minAmount !== "NaN" && minAmount) || (minAmountCalculation !== "NaN" && minAmountCalculation) : undefined}
                                        normalizeZeros={false}
                                        radix={"."}
                                        mapToRadix={[ "," ]}
                                        type="number"
                                        pattern="\\d*"
                                    />
                                </div>
                            </div>
                        </div>
                        { errorMessage && (
                            <div className="errorMessage">
                                <img alt="" src={mediaPath([ "/assets/icons/errorNotif.svg", "/assets/images/padel/icons/errorNotif.svg" ])}/>
                                <p>{errorMessage}</p>
                            </div>
                        )}
                        {tunnelType === "LEAGUE" && (
                            <div className='leagueRemainder'>
                                <p>Pour valider l’inscription, tu dois payer au moins 10% maintenant.</p>
                                <p>Tes joueurs ont jusqu’au 1er match de la saison pour régler leur part en ligne.</p>
                                <p>Le montant restant dû le jour du 1er match sera prélevé automatiquement sur le compte bancaire du capitaine.</p>
                            </div>
                        )}
                        <div className="button-holder">
                            <ButtonCta
                                isAlt
                                text={avoirFormData && avoirFormData.totalWithAvoir === 0 ? "Finaliser" : "Régler"}
                                onClick = {() => {
                                    setIsOpen(false)
                                    const notPayzen = (parseFloat(freeAmount) === 0) && !gymlibCode
                                    let amountToPay = +freeAmount
                                    paiementCallback(withMultiPayments, amountToPay, notPayzen, null, canUseAlias ? saveCard : null, validGymlibCode ? gymlibCode : null)
                                }}
                                disabled = {!gymlibCode && (
                                    (tunnelType !== "LEAGUE" && !(avoirFormData?.avoirAmount >= (registration.leaguePricing / 10))) && (!freeAmount // Si le montant n'est pas défini
                                        || (parseInt(freeAmount) === 0)
                                        || (
                                            // Si le montant du est supérieur au montant minimum
                                            // et que le montant libre est inférieur au montant minimum
                                            parseFloat(dueAmount) >= (parseFloat(minAmount) || parseFloat(minAmountCalculation))
                                            && parseFloat(freeAmount) < (parseFloat(minAmount) || /* istanbul ignore next */ parseFloat(minAmountCalculation))
                                        )
                                        || (
                                            // Si le montant du est inférieur au montant minimum
                                            // et que le montant libre est différant du montant minimum
                                            parseFloat(dueAmount) < (parseFloat(minAmount) || parseFloat(minAmountCalculation))
                                            && parseFloat(freeAmount) !== parseFloat(dueAmount)
                                        )
                                        || (parseFloat(freeAmount) > parseFloat(dueAmount)))
                                    || errorMessage
                                )}
                            />
                        </div>
                        {tunnelType === "BOOKING" && canPayWithUsCard && (
                            <div
                                data-testid="canPayWithUsCardRef"
                                className="payWithUsCardLink"
                                onClick={() => {
                                    setIsOpen(false)
                                    setCanPayWithUsCardModalOpen(true)
                                }}
                            >
                                    Tu souhaites régler en carte ou chèques {tu("name")} ?
                            </div>
                        )}
                    </article>
                </section>
            </Modal>
        </div>
    )
}

PayFreeAmount.propTypes = {
    billMinAmount: oneOfType([ bool, number, string ]),
    canPayWithUsCard: bool,
    canUseAlias: bool,
    haveDiscount: bool,
    isOpen: bool,
    onUseAlias: func,
    paiementCallback: func,
    pricePerPart: number,
    registration: object,
    setAmountToPay: oneOfType([ func, bool ]),
    setCanPayWithUsCardModalOpen: func,
    setIsOpen: func,
    tunnelType: string,
    withAvoir: bool,
    withGymlibPayments: bool,
    withMultiPayments: bool,
    withPartPayment: bool,
}

PayFreeAmount.displayName = "PayFreeAmount"

export default PayFreeAmount
