export default function () {
    return [
        {
            age: 37,
            email: "toto37@gmail.com",
            firstName: "To",
            id: 1,
            lastName: "To",
        },
        {
            age: 38,
            email: "toto38@gmail.com",
            firstName: "To",
            id: 2,
            lastName: "To2",
        },
    ]
}
