import appConfig from "../config.json"
import { handleOzmoReloadingAction } from "../store/actions/scaffold"

function handleRedirections(store, history) {
    switch (history.location.pathname) {

    case appConfig.ozmoAccountVerifyUri:

        store.dispatch(
            handleOzmoReloadingAction("accountVerify"),
        )

        break

    case appConfig.ozmoAccountVerifyUriPadel:

        store.dispatch(
            handleOzmoReloadingAction("accountVerifyPadel"),
        )

        break

    case appConfig.ozmoLocationPrivateInvitUri: // /reload

        store.dispatch(
            handleOzmoReloadingAction("locationPrivateInvit"),
        )

        break
    }
}

export default handleRedirections
