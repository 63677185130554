function useGetSubTeam(registration, tunnelType) {
    switch (tunnelType) {
    case "CUP":
        return registration.cupTeam
    case "LEAGUE":
        return registration.championshipTeam
    default:
        // eslint-disable-next-line no-console
        console.error("useGetSubTeam - Tunnel not implemented : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        break
    }
}

export default useGetSubTeam
