import axios from "axios"

import disconnectUser from "../services/disconnectUser"
import { openConfirmationOverlay, toggleAuthenticationOverlay, toggleLevel3Overlay } from "../store/actions/content"
import store from "../store/store"

export function handleBadRequest(errorMessage) {
    const errors = errorMessage?.errors || { "Erreur interne": "Action impossible" }
    store.dispatch(
        openConfirmationOverlay(
            "formValidationErrorsDisplayConfirm",
            {
                confirmMessage: "Fermer",
                formErrors: Object.values(errors),
                hasCustomCallback: false,
                unconfirmMessage: null,
            },
        ),
    )
}

export function handleUnauthorized(errorMessage) {
    if ([ "No user can be authenticated with given login/password", "No user can be authenticated with given token" ].includes(errorMessage)) {
        disconnectUser()
        store.dispatch(
            toggleAuthenticationOverlay(true, { failedAuthenticationMessage: "Identifiant/Mot de passe incorrects !" }),
        )
    }
}

export function handlePreconditionFailed(errorMessage) {
    if (errorMessage === "Afin de te connecter, valide le lien qui t'a été envoyé par email.") {
        store.dispatch(toggleLevel3Overlay(
            true,
            "authenticationException",
            {
                displayBlockGetNewConfirmationMail: true,
                message: errorMessage,
                // userEmail: extraHeaders["auth-login"] can be included if needed
            },
            false,
        ))
    }
}

export function handleNotFound(errorMessage) {
    return errorMessage
}

export function returnApiAnswer(res) {
    return res
}

export function returnAnswerData(res) {
    if (res?.data?.data) {
        return res.data.data
    } else {
        return res
    }
}

export const externalApiCall = async (path, verb) => {
    let instance = axios.create()

    if (verb === "GET") {
        return instance.get(path)
    } else if (verb === "POST") {
        return instance.post(path)
    }

}

export function downloadFile({ file, fileName }) {
    const url = window.URL.createObjectURL(new Blob([ file ]))
    const a = document.createElement("a")
    a.href = url
    a.setAttribute("download", fileName ? fileName : "download.pdf")
    a.setAttribute("target", "_blank")
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
}

export function buildQueryString(params) {
    const queryString = Object.keys(params)
        .filter(key => params[key] !== undefined)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join("&")
    return queryString ? `?${queryString}` : ""
}
