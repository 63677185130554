// import TeamSteps from "../../pages/teams/steps/TeamSteps";
import PadelCup from "../../pages/teams/PadelCup/PadelCup"
import ListingUrbanCup from "../../pages/teams/urbanCup/ListingUrbanCup"

const RoutesCup = [
    {
        element: (<ListingUrbanCup/>),
        path: "/padel/tournois",
    },
    {
        children: [
            {
                element: (<PadelCup />),
                path: ":id",
            },
        ],
        element: (<PadelCup />),
        path: "/padel/tournois/new",
    },
    {
        element: (<ListingUrbanCup/>),
        path: "/padel/tournois/listing",
    },
]

export default RoutesCup
