import { bool, func, object } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useStore } from "react-redux"
import { useNavigate } from "react-router-dom"

import { SchoolListingElementPopIn } from "./SchoolListingElementPopIn.jsx"
import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import ListingRegistrationHolder from "../../../../../components/listing/ListingRegistrationHolder"
import StatusDisplay from "../../../../../components/listing/StatusDisplay"
import Preloader from "../../../../../components/loaders/preloader/preloader"
import MessagePopin from "../../../../../components/popin/MessagePopin/MessagePopin"
import { getMedicalCertificateButtonText } from "../../../../../components/reservation/medicalCertificateText"
import { useTheme } from "../../../../../components/ThemeContext/ThemeContext"
import { displayBill } from "../../../../../globalAPI/api"
import { useCanDownload } from "../../../../../hooks/useCanDownload.jsx"
import ModalHandler from "../../../../../providers/Modal/ModalHandler"
import usePath from "../../../../../routes/services/usePath"
import { openPopin } from "../../../../../services/routingManagment"
import { setFormEntry } from "../../../../../store/forms/actions"
import QuitWaitingListPopin from "../../../../teams/components/QuitWaitingListPopin/QuitWaitingListPopin"
import { addSchoolMedicalCertificate, cancelSchoolRegistration, getKidsSchoolSubscription } from "../../api"

import "./SchoolListingElement.scss"

export const SchoolListingElement = ({ schoolRegistration, displayDetail, callback }) => {
    const { id, status, statusValue, preregistrationLimitDate } = schoolRegistration

    const listAttachment = useRef(null)
    const quitWaitingListPopin = useRef(null)
    /* STATE */
    const [ isLoading, setIsLoading ] = useState(false)
    const [ uploadingCertificate, setUploadingCertificate ] = useState(false)
    const { check, message } = useCanDownload()

    /* STORE */
    const store = useStore()
    const theme = useTheme()

    /* VARIABLES */
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = usePath()

    const openDetailModal = () => {
        ModalHandler.show(SchoolListingElementPopIn, {
            schoolRegistration: schoolRegistration,
        })
    }

    /* ACTIONS */

    const formOzmo = store.getState().forms["ozmoSubscription"] !== undefined ?
        store.getState().forms["ozmoSubscription"]
        : {}

    useEffect(
        () => {
            if (displayDetail === true) {
                setTimeout(() => {
                    openDetailModal()
                }, 200)
            } else if (displayDetail === false) {
                ModalHandler.hide(SchoolListingElementPopIn)
            }
        }, [ schoolRegistration ],
    )

    function quitterFileAttente() {
        setIsLoading(true)
        cancelSchoolRegistration(schoolRegistration.id).then(
            (res) => {
                callback(res)
            },
        ).catch((e) => {
            if (e?.response?.data?.data?.Message) {
                quitWaitingListPopin?.current?.hide()
                ModalHandler.show(MessagePopin, {
                    message: e?.response?.data?.data?.Message,
                    title: "Erreur",
                })
            }
        }).finally(() => setIsLoading(false))
    }

    function finaliser() {
        setIsLoading(true)

        getKidsSchoolSubscription(schoolRegistration.id).then(
            (subscription) => {
                
                if (subscription.correlationId) {
                    getKidsSchoolSubscription(subscription.correlationId).then(
                        (secondSub) => {
                            let firstSubIsSecond = subscription.discountCodes.map(
                                code => code.secondRegistrationDiscount,
                            ).includes(true)
                            let secondSubIsFirst = secondSub.catalogArticles.length > 0 || secondSub.optionProducts.length > 0
                            
                            if (firstSubIsSecond || secondSubIsFirst) {
                                dispatch(
                                    setFormEntry(
                                        "ozmoSubscription", {
                                            ...formOzmo,
                                            firstSubscription: secondSub,
                                            secondSubscription: subscription,
                                        },
                                    ),
                                )
                            } else {
                                dispatch(
                                    setFormEntry(
                                        "ozmoSubscription", {
                                            ...formOzmo,
                                            firstSubscription: subscription,
                                            secondSubscription: secondSub,
                                        },
                                    ),
                                )
                            }
                            setIsLoading(false)
                            navigate(path("/schoolordersummary"), {
                                state: {
                                    isPreregistration: true,
                                    title: "Ma préinscription",
                                },
                            })
                        },
                    )
                } else {
                    dispatch(
                        setFormEntry(
                            "ozmoSubscription", {
                                ...formOzmo,
                                firstSubscription: subscription,
                            },
                        ),
                    )

                    setIsLoading(false)

                    navigate(path("/schoolordersummary"), {
                        state: {
                            isPreregistration: true,
                            title: "Ma préinscription",
                        },
                    })
                }
            },
        )
    }

    function annuler() {
        finaliser()
    }

    function disableCertificateButton(subscription) {
        return subscription.validationStatusValue === 2 || subscription.validationStatusValue === 1
    }

    function uploadMedicalCertif() {
        listAttachment.current.click()
    }

    return (
        <ListingRegistrationHolder
            isLoading={isLoading}
            logo={schoolRegistration.school.pictureUrl}
            status={
                (<StatusDisplay
                    id={id}
                    status={status}
                    statusValue={statusValue}
                    preRegistrationLimitDate={preregistrationLimitDate}
                    isFamiliarity={theme === "theme-padel"}
                />)
            }
            name={schoolRegistration.school.type}
            details={
                (<React.Fragment>
                    <input
                        // capture="user"
                        type="file"
                        accept="application/pdf, image/jpeg, image/png"
                        className="hidden-file-input"
                        ref={listAttachment} 
                        value={""}
                        onChange={(e) => {
                            let file = e.target.files[0]
                            setUploadingCertificate(true)
                            addSchoolMedicalCertificate(schoolRegistration.id, file).then(
                                (resultSubscription) => {
                                    if (resultSubscription) {
                                        callback(() => {
                                            ModalHandler.show(MessagePopin, {
                                                message: (
                                                    <div>
                                                        <p>Nous avons bien reçu votre certificat médical.</p>
                                                        <p>Un délai est nécessaire pour le valider.</p>
                                                    </div>
                                                ),
                                                title: "Envoi confirmé",
                                            })
                                        })
                                        setUploadingCertificate(false)
                                        
                                        // refresh subscriptions list
                                    } else {
                                        ModalHandler.show(MessagePopin, {
                                            message: (
                                                <div>
                                                    <p>Un problème technique est survenu lors de l'envoi.</p>
                                                    <p>Veuillez réessayer ultérieurement.</p>
                                                </div>
                                            ),
                                            title: "Echec envoi",
                                        })
                                    }
                                },
                            ).catch(() => setUploadingCertificate(false))
                        }}
                    />
                    <div>{schoolRegistration.school.centerName}</div>
                    <div>
                        <span>{schoolRegistration.humanDay} </span> 
                        <span>{schoolRegistration.school.startDate}</span> 
                        <span> de </span> <span>{schoolRegistration.startHour} à {schoolRegistration.endHour}</span>
                    </div>
                    {schoolRegistration?.kid && (
                        <div>
                            {schoolRegistration.kid.firstName + " " + schoolRegistration.kid.lastName}
                        </div>
                    )}
                    <div 
                        className="detail-btn"
                        onClick={() => {
                            openDetailModal()
                            openPopin("listing", "details", schoolRegistration.id)
                        }}
                    >
                        Voir le détail
                    </div>
                </React.Fragment>)
            }
            actions={
                (<React.Fragment>
                    {
                        (schoolRegistration.billId > 0 && schoolRegistration.statusValue === 3 && schoolRegistration.kid &&
                            schoolRegistration.healthSurveyStatusValue !== 1) &&
                                (<ButtonCta
                                    isCta2Alt
                                    disabled={disableCertificateButton(schoolRegistration)}
                                    className="buttonCta--mb"
                                    onClick={uploadMedicalCertif}
                                    text={
                                        !uploadingCertificate ? 
                                            getMedicalCertificateButtonText(schoolRegistration)
                                            :
                                            (<div>
                                                <Preloader alt={theme === "theme-soccer"}/>
                                            </div>)
                                    }
                                />)

                    }

                    {
                        (schoolRegistration.billId > 0 
                            && schoolRegistration.statusValue === 3
                        ) &&
                            (<>
                                <ButtonCta
                                    isCta2Alt
                                    text="Télécharger la facture"
                                    onClick={() => {
                                        check(() => displayBill(schoolRegistration.billId, setIsLoading).then((res) => {
                                            if (res) {
                                                ModalHandler.show(MessagePopin, {
                                                    message: res,
                                                    title: "Erreur",
                                                })
                                            }
                                        }))
                                    }}
                                />
                            </>)
                    }

                    {
                        (schoolRegistration.statusValue === 1) &&
                            (<ButtonCta 
                                isCta2Alt
                                text="Quitter la file d'attente"
                                onClick={() => quitWaitingListPopin?.current?.show()}
                            />)
                    }

                    {
                        (schoolRegistration.statusValue === 2) &&
                            (<ButtonCta 
                                className="buttonCta--mb"
                                isAlt
                                text="Finaliser ma pré-inscription"
                                onClick={() => {
                                    finaliser()
                                }}
                            />)
                    }       

                    {
                        (schoolRegistration.statusValue === 2) &&
                            (<ButtonCta 
                                isAlt
                                text="Annuler ma pré-inscription"
                                onClick={() => {
                                    annuler()
                                }}
                            />)
                    }
                    {message}
                    {schoolRegistration.statusValue === 1 && <QuitWaitingListPopin ref={quitWaitingListPopin} confirmCancel={quitterFileAttente} title="Quitter la file d'attente" isLoading={isLoading} />}
                </React.Fragment>)
            }
        />
    )
}

SchoolListingElement.propTypes = {
    callback: func,
    displayDetail: bool,
    schoolRegistration: object,
}

export default SchoolListingElement
