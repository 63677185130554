import dayjs from "dayjs"
import { useState } from "react"

import ButtonCta from "../../../../../components/Buttons/button/button-cta.jsx"
import InputDate from "../../../../../components/Forms/InputDate/InputDate.jsx"
import Modal from "../../../../../components/Modal/Modal.jsx"
import ModalHandler from "../../../../../providers/Modal/ModalHandler.jsx"

import "./AgeCheckModal.scss"

export const AgeCheckModal = ModalHandler.create(({ visible, hide, discount, confirmCallback, closeCallback }) => {
    const [ date, setDate ] = useState(undefined)

    const close = () => {
        setDate(undefined)
        hide()
        closeCallback()
    }

    return (
        <Modal isOpen={visible} onClose={() => {
            close()
        }} className={"age-check-modal"}>
            <header>{discount?.label}</header>
            <div className={"age-check-content"}>
                <p>{discount?.terms}</p>

                <p>Renseigne ta date de naissance pour finaliser ta réservation</p>

                <InputDate
                    label="Date de naissance"
                    bind={[ date, setDate ]}
                    max={new Date()}
                    useDatePicker
                    customClass="customDayPicker"
                    placeholder="JJ/MM/AAAA"
                    required

                />

                {dayjs().diff(dayjs(date), "year") <= 26 ? (
                    <ButtonCta
                        text="Suivant"
                        disabled={!date}
                        onClick={() => {
                            confirmCallback(date)
                        }}
                    />
                ) : (
                    <>
                        <p className={"error"}>Tu n'es pas éligible à l'offre tarifaire car tu as plus de 26 ans.
                            Souhaites-tu quand même continuer et payer le complément le jour de la réservation dans le
                            centre ?
                        </p>

                        <div className={"acceptation"}>
                            <ButtonCta text="non" onClick={close} isCta2/>
                            <ButtonCta text="oui" onClick={() => {
                                hide()
                                confirmCallback(date)
                            }}/>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
})
