import classNames from "classnames"
import { bool, func, oneOfType, string } from "prop-types"

function InvitationOption({ text, icon, invitationCallback, customCls }) {
    return (
        <div
            className={classNames("invitationOption", { [customCls]: customCls })}
            onClick={ invitationCallback }
        >
            <div className="icon">
                <img src={icon}/>
            </div>
            <div className="text">
                {text}
            </div>
        </div>
    )
}

InvitationOption.propTypes = {
    customCls: oneOfType([ string, bool ]),
    icon: string,
    invitationCallback: func,
    text: string,
}

export default InvitationOption
