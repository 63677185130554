import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useStepStore } from "./useStepStore.js"
import { BusinessLeagueTunnelPaths } from "../../../routes/ApplicationPaths/BusinessLeagueTunnelPaths.js"
import { businessLeagueTunnelValidation } from "../service/validation.js"

export const useBusinessFormNavigation = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { step, setStep } = useStepStore()
    const { steps, basePath } = BusinessLeagueTunnelPaths

    useEffect(() => {
        const path = location.pathname
        const currentStepIndex = steps.findIndex(stepPath => path.includes(stepPath))

        if (currentStepIndex !== -1 && currentStepIndex + 1 !== step) {
            setStep(currentStepIndex + 1)
        }
    }, [ location, steps, setStep, step ])

    const nextStep = () => {
        if (step < steps.length) {
            const nextStep = step + 1
            setStep(nextStep)
            navigate(`${basePath}${steps[nextStep - 1]}`)
        }
    }

    const prevStep = () => {
        if (step > 1) {
            const prevStep = step - 1
            setStep(prevStep)
            navigate(`${basePath}${steps[prevStep - 1]}`)
        }
    }

    const currentValidationSchema = businessLeagueTunnelValidation[steps[step - 1]]

    const getCurrentStep = () => step - 1

    return { currentValidationSchema, getCurrentStep, nextStep, prevStep, steps }
}
