import { bool, func, string } from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector, useStore } from "react-redux"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import CheckBlock from "../../../../components/Forms/CheckBlock/CheckBlock"
import Preloader from "../../../../components/loaders/preloader/preloader"
import Modal from "../../../../components/Modal/Modal"
import EffectifPopIn from "../../../../components/popin/invitations/EffectifPopIn"
import MainInvitationsPopIn from "../../../../components/popin/invitations/MainInvitationsPopIn"
import ProfilePicture from "../../../../components/user/ProfilePicture"
import {
    createFriendshipInvitationFromEmail,
    generateFriendshipRawInvitationTokenUrl,
} from "../../../../globalAPI/api"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import ModalHandler from "../../../../providers/Modal/ModalHandler"
import alphabeticalPlayerSort from "../../../../services/Sorting/alphabeticalPlayerSort"
import { setFormEntry } from "../../../../store/forms/actions"
import { getLeagueRegistration } from "../../../league/api/api.js"
import useAddPlayerToTeam from "../../hooks/useAddPlayerToTeam"
import useGetSubTeam from "../../hooks/useGetSubTeam"
import useGetSubTournament from "../../hooks/useGetSubTournament"
import useRemovePlayerFromTeam from "../../hooks/useRemovePlayerFromTeam"
import useUpdateRegistration from "../../hooks/useUpdateRegistration"
import { getCupRegistrationDetails, getCupTeamPlayersPick, sendEmailInvitation } from "../../urbanCup/api"

import "./EffectifDisplay.scss"

// eslint-disable-next-line react/display-name
const EffectifDisplay =
    ({ tunnelType, callback, hasParent }) => {
        const appConfig = useSelector(state => state.appConfig)
        const userLoaded = useSelector(state => state.userLoaded)

        let registration = useSelector(state => state.forms.TournamentRegistration)
        const tunnelTournamentRegistration = useSelector(state => state.forms.tunnelTournamentRegistration)

        if (registration === undefined) {
            registration = {}
        }
        const {
            captain,
            captainId,
            captainName,
            viceCaptain,
            viceCaptainName,
            viceCaptainId,
        } = registration

        const mailPlayers = registration.sendEmailToTeamMembers
        const [ updatingViceCaptain, setUpdatingViceCaptain ] = useState(false)
        const [ mateToPromote, setMateToPromote ] = useState(null)
        const [ ajaxLoading, setAjaxLoading ] = useState(false)
        const [ addingPlayer, setAddingPlayer ] = useState(false)
        const [ isAddVicePopInOpen, setIsAddVicePopInOpen ] = useState(false)
        const [ , updateState ] = React.useState()
        const forceUpdate = React.useCallback(() => updateState({}), [])

        const store = useStore()
        const dispatch = useDispatch()
        const themeHeader = useThemeHeader()

        const addPlayerToTeam = useAddPlayerToTeam(tunnelType)

        const updateRegistration = useUpdateRegistration(tunnelType)
        const SubTournament = useGetSubTournament(registration, tunnelType)
        const SubTeam = useGetSubTeam(registration, tunnelType)
        const removePlayerFromTeam = useRemovePlayerFromTeam(tunnelType)

        const [ players, setPlayers ] = useState(SubTeam ? SubTeam.players : undefined)

        useEffect(
            () => {
                let fbAppId = encodeURIComponent(appConfig.fbAppId)
                window.fbAsyncInit = function () {
                    window.FB.init({
                    // appId      : '184264925511637',
                        appId      : fbAppId,
                        cookie     : true,
                        status     : true,
                        version    : "v12.0",
                        xfbml      : true,
                    })
                };

                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0]
                    if (d.getElementById(id)) {return}
                    js = d.createElement(s); js.id = id
                    js.src = "//connect.facebook.net/en_US/sdk.js"
                    fjs.parentNode.insertBefore(js, fjs)
                }(document, "script", "facebook-jssdk"))
            }, [],
        )

        useEffect(
            () => {
                if (captainId && tunnelTournamentRegistration) {
                    if (tunnelTournamentRegistration.teamDataChange
                        && tunnelTournamentRegistration.isNewRegistration && SubTeam && SubTeam.teamId) {
                        dispatch(
                            setFormEntry(
                                "tunnelTournamentRegistration",
                                {
                                    ...tunnelTournamentRegistration,
                                    isNewRegistration: false,
                                    teamDataChange: false,
                                },
                            ),
                        )
                        setAjaxLoading(true)

                        getCupTeamPlayersPick(SubTeam.teamId).then((res) => {
                            addTeamMateToCup(res.data.data)
                        })
                    }
                }
            }, [ tunnelTournamentRegistration ],
        )

        useEffect(
            () => {
                const teamOfRegistration = useGetSubTeam(registration, tunnelType)
                if (teamOfRegistration) {
                    setPlayers(teamOfRegistration.players)
                }
            }, [ registration ],
        )

        function openViceCaptainPopIn() {
            setIsAddVicePopInOpen(true)
        }

        function promoteSelectedMate() {
            setUpdatingViceCaptain(true)
            updateRegistration(
                registration.centerId,
                SubTournament.id,
                registration.teamId,
                SubTeam.name,
                null,
                registration.id,
                [],
                registration.comments,
                false,
                mailPlayers,
                registration.captainId,
                mateToPromote,
            ).then(handleCupResult)
        }

        function deletePlayer(playerId, callback) {
            removePlayerFromTeam(
                registration.id,
                playerId,
            ).then(
                callback ?
                    callback
                    :
                    handleCupResult,
            )
        }

        function deleteViceCaptain() {
            updateRegistration(
                registration.centerId,
                SubTournament.id,
                registration.teamId,
                SubTeam.name,
                null,
                registration.id,
                [], // registration.players,
                registration.comments,
                false,
                mailPlayers,
                registration.captainId,
                null,
            ).then(handleCupResult)
        }

        function selectMateToPromote(event) {
            let idToPromote = event.currentTarget.id.replace("vice-", "")
            setMateToPromote(
                mateToPromote && idToPromote === mateToPromote ?
                    null
                    :
                    idToPromote,
            )
        }

        //
        function addTeamMateToCup(teammates) {
            let sub = store.getState().forms.TournamentRegistration
            let promises = []
            if (sub) {
                teammates.forEach(
                    (mate) => {
                        const mateId = mate.ozmoSystemId ? mate.ozmoSystemId : mate.id
                        const mateFinded = SubTeam.players.find((p) => p.id === mateId)
                        if (!mateFinded) {
                            promises.push(
                                addPlayerToTeam(
                                    sub.id,
                                    mate.ozmoSystemId ? mate.ozmoSystemId : mate.id,
                                ),
                            )
                        }
                    },
                )
            }
            if (promises.length > 0) {
                setAddingPlayer(true)
                Promise.all(promises).then(
                    () => {
                        if (tunnelType === "CUP") {
                            getCupRegistrationDetails(sub.id).then((res) => {
                                handleCupResult(res)
                            })
                        } else if (tunnelType === "LEAGUE") {
                            // TO DO
                            // CALL getLeagueRegistrationDetail(sub.id)
                            // THEN handleCupResult
                            // AND REMOVE CODE BELOW
                            getLeagueRegistration(sub.id).then((res) => {
                                handleCupResult(res)
                            })
                        }
                    },
                )
            } else {
                setAddingPlayer(false)
                setAjaxLoading(false)
                handleCupResult()
            }

        }

        const addTeammates = (teammatesIds) => {
            let promises = []
            teammatesIds.forEach(
                (mateId) => {
                    promises.push(
                        addPlayerToTeam(
                            registration.id,
                            mateId,
                        ),
                    )
                },
            )
            setAddingPlayer(true)
            Promise.all(promises).then(
                (res) => {
                    let max = {
                        data: {
                            data: {
                                players:[],
                            },
                        },
                    }
                    res.forEach(
                        (result) => {
                            if (result.data.data.players.length >= max.data.data.players.length) {
                                max = result
                            }
                        },
                    )
                    handleCupResult(max)
                },
            )
        }

        const generateInvitMessage = async (link) => {
            let res = await generateFriendshipRawInvitationTokenUrl(
                import.meta.env.VITE_URL_BASE + "/", // add / for mobile app purpose
                tunnelType === "LEAGUE" ? registration.id : registration.teamId,
                tunnelType === "CUP" ? registration.cup.id : undefined,
                tunnelType === "LEAGUE" ? registration.teamId : undefined,
            )

            if (res.data.data) {
                let url = res.data.data[0]

                const slotName = tunnelType === "LEAGUE" ? "championnat" : "tournois"

                return link ?
                    url
                    :
                    SubTournament ?
                        encodeURIComponent(
                            `${userLoaded.firstname} ${userLoaded.lastname} t'invite au ${slotName} ${SubTournament.name}, clique sur le lien suivant pour rejoindre son équipe ! ${url}`,
                        )
                        :
                        "error : undefined"
                
            }
        }

        const sendEmail = (email) =>{
            switch (tunnelType) {
            case "CUP":
                sendEmailInvitation(
                    email,
                    import.meta.env.VITE_URL_BASE + "/", // add / for mobile app purpose
                    registration.teamId,
                    registration.id,
                )
                break

            case "LEAGUE":
                createFriendshipInvitationFromEmail(
                    email,
                    import.meta.env.VITE_URL_BASE + "/", // add / for mobile app purpose
                    registration.teamId,
                    themeHeader,
                )
                break
            }
        }

        const getAlreadySelectedMatesIds = () => {
            let everySelectedMatesIds = []
            let team = []
            switch (tunnelType) {
            case "CUP":
                team = registration.cupTeam ? registration.cupTeam.players : []
                break
            case "LEAGUE":
                team = registration.championshipTeam ? registration.championshipTeam.players : []
                break
            default:
                // eslint-disable-next-line no-console
                console.error("getAlreadySelectedMatesIds - Not implemented tunnel : %o", tunnelType)
                // eslint-disable-next-line no-debugger
                debugger
            }
            everySelectedMatesIds = team.map(
                player => player.id,
            )
            if (registration.viceCaptainId) {
                everySelectedMatesIds.push(registration.viceCaptainId)
            }
            if (registration.captainId) {
                everySelectedMatesIds.push(registration.captainId)
            }
            return everySelectedMatesIds
        }

        function handleCupResult(res) {
            if (res) {
                dispatch(
                    setFormEntry(
                        "TournamentRegistration",
                        {
                            catalogDiscounts: res.data.catalogDiscounts ?
                                res.data.catalogDiscounts
                                :
                                registration.catalogDiscounts,
                            proforma: res.data.proforma ?
                                res.data.proforma
                                :
                                registration.proforma,
                            ...res.data.data,
                        },
                    ),
                )
                if (res.data.data) {
                    switch (tunnelType) {
                    case "CUP":
                        setPlayers(res.data.data.cupTeam.players)
                        break
                    case "LEAGUE":
                        setPlayers(res.data.data.championshipTeam.players)
                        break
                    }
                    forceUpdate()
                }

                setAjaxLoading(false)
                setAddingPlayer(false)
                setUpdatingViceCaptain(false)
                setMateToPromote(null)

                setIsAddVicePopInOpen(false)

                if (callback) {
                    callback()
                }
            }
        }

        function playerFilter(x) {
            return x.id !== viceCaptainId && x.id !== captainId
        }

        const showAddPlayerModal = () => {
            ModalHandler.show(MainInvitationsPopIn, {
                closeCallback: () => {
                    hasParent && ModalHandler.show(EffectifPopIn, {}, { keepPreviousProps: true })
                },
                linkToSend: async () => {
                    return await generateInvitMessage(true).then((res) => res)
                },
                mailCallback: sendEmail,
                mateCallback: addTeammates,
                messageToSend: generateInvitMessage,
                selectedMates: getAlreadySelectedMatesIds(),
            }, { keepPreviousProps: true })
        }

        useEffect(() => {
            ModalHandler.update(MainInvitationsPopIn, {
                selectedMates: getAlreadySelectedMatesIds(),
            })
        }, [ players ])

        return (
            <React.Fragment>
                { ajaxLoading &&
                    <Preloader fixed />
                }
                <div className="radioList--small bottomSeparator">
                    {captain ?
                        (<React.Fragment>
                            <CheckBlock
                                cls="user"
                                value={captain.id}
                                name="captain"
                                key={captain.id}
                            >
                                <div className='tag'>
                                    <img src="/assets/icons/ICON-capitaine.svg"/>
                                </div>
                                <div className="radioList__item--logo">
                                    <ProfilePicture src={captain.pictureUrl}/>
                                </div>
                                <div className="radioList__item__subTitle">
                                    { captainName ?
                                        captainName
                                        :
                                        captain.firstName + " " + captain.lastName
                                    }
                                </div>

                                <div className="radioList__item__info">
                                        Capitaine
                                </div>
                            </CheckBlock>
                            { viceCaptain ?
                                (<CheckBlock
                                    cls="user"
                                    value={viceCaptain.id}
                                    name="captain"
                                    onDelete={
                                        registration.statusValue === 0 ?
                                            deleteViceCaptain
                                            :
                                            undefined
                                    }
                                    key={viceCaptain.id}
                                >
                                    <div className='tag'>
                                        <img src="/assets/icons/ICON-vice-capitaine.svg"/>
                                    </div>
                                    <div className="radioList__item--logo">
                                        <ProfilePicture src={viceCaptain.pictureUrl}/>
                                    </div>
                                    <div className="radioList__item__subTitle">
                                        { viceCaptainName ?
                                            viceCaptainName
                                            :
                                            viceCaptain.firstName + " " + viceCaptain.lastName
                                        }
                                    </div>

                                    <div className="radioList__item__info">
                                                Vice-Capitaine
                                    </div>
                                </CheckBlock>)
                                :
                                (
                                    registration.statusValue !== 3 &&
                                    registration.statusValue !== 2 &&
                                    registration.statusValue !== 1
                                ) &&
                                            (<div className="radioList__item action">
                                                <ButtonCta
                                                    className= "buttonCta--iconMore"
                                                    text= "Ajouter mon vice capitaine"
                                                    noSkew
                                                    noShadow
                                                    heightFull
                                                    onClick= {openViceCaptainPopIn}
                                                    disabled= {
                                                        players && (players.filter(playerFilter).length === 0 || addingPlayer)
                                                    }
                                                />
                                            </div>)
                            }

                        </React.Fragment>)
                        :
                        <Preloader/>
                    }

                </div>

                <span className="separatorTitle formsHolder">Mes coéquipiers : </span>
                <div className="radioList--small">
                    { players &&
                        players.filter(playerFilter).sort(alphabeticalPlayerSort).map(
                            (player) => (
                                <CheckBlock
                                    cls="user"
                                    value={player.id}
                                    name="players"
                                    onDelete={
                                        player.id !== userLoaded.id ?
                                            deletePlayer
                                            :
                                            undefined
                                    }
                                    key={player.id}
                                >
                                    <div className="radioList__item--logo">
                                        <ProfilePicture src={player.pictureUrl}/>
                                    </div>
                                    <div className="radioList__item__subTitle">
                                        {player.firstname + " " + player.lastname}
                                    </div>

                                    <div className="radioList__item__info">
                                        Coéquipier
                                    </div>
                                </CheckBlock>
                            ),
                        )
                    }
                    { addingPlayer &&
                        (<CheckBlock cls="user">
                            <Preloader/>
                        </CheckBlock>)
                    }
                    <div className="radioList__item action">
                        <ButtonCta
                            text = "Ajouter un joueur"
                            disabled = {!captain}
                            className = "buttonCta--iconMore"
                            noSkew
                            noShadow
                            heightFull
                            onClick = {() => {
                                showAddPlayerModal()
                                hasParent && ModalHandler.hide(EffectifPopIn)
                            }}
                        />
                    </div>
                </div>
                <div className="effectifPopIns">
                    <div className="big">
                        <Modal
                            isOpen={isAddVicePopInOpen}
                            onClose={() => setIsAddVicePopInOpen(false)}
                        >
                            <header> Ajout vice-capitaine </header>
                            <section className="invitationSection noHPadMobile">
                                <div className="centerPicker c-row justify-center align-start layoutSmall">
                                    <div className="c-col c-col--10 c-col--sm--12 flex justify-center align-center wrap ">
                                        <div className="radioList--small">
                                            { updatingViceCaptain ?
                                                <Preloader/>
                                                :
                                                players && players.filter(playerFilter).map(
                                                    (player) => (
                                                        <CheckBlock
                                                            callback={selectMateToPromote}
                                                            checked={parseInt(player.id) === parseInt(mateToPromote)}
                                                            value={"vice-" + player.id}
                                                            name="teamates"
                                                            key={"vice-" + player.id}
                                                        >
                                                            <div className="radioList__item--logo">
                                                                <ProfilePicture src={player.pictureUrl}/>
                                                            </div>
                                                            <div className="radioList__item__subTitle">
                                                                {player.firstname + " " + player.lastname}
                                                            </div>
                                                        </CheckBlock>
                                                    ),
                                                )
                                            }
                                        </div>
                                        <ButtonCta
                                            text="Ajouter mon vice capitaine"
                                            disabled={updatingViceCaptain || mateToPromote === null}
                                            onClick={promoteSelectedMate}
                                        />
                                    </div>
                                </div>
                            </section>
                        </Modal>
                    </div>
                </div>
            </React.Fragment>
        )
    }

EffectifDisplay.propTypes = {
    callback: func,
    hasParent: bool,
    tunnelType: string,
}

export default EffectifDisplay
