import { _apiCall } from "../../../backend/axios"

export function listLocations({ pagesize, offset, periodStart = null, periodEnd = null, orderbyasc = false, theme }) {
    return _apiCall(
        `read/locations?pagesize=${pagesize}&offset=${offset}&periodStart=${periodStart}&periodEnd=${periodEnd}&orderByAsc=${orderbyasc}&invitationStatuses[]=1&statuses[]=0`,
        "GET",
        theme,
    )
}

export function listConvocations(theme) {
    return _apiCall(
        "read/convocations",
        "GET" ,
        { activity: theme },
    )
}

export function rentalInformations() {
 
    return _apiCall(
        "read/rentalInformations",
        "GET",
    ).then((response) => {
        if (response && response.data && response.data.data) {
            return response.data.data ? response.data.data : []
        }
        return []
    })
}
