import * as actions from "./actions"

const initialState = {
    alreadySubscribedCupIds: [],
    alreadySubscribedLeagueIds: [],
    avoirFormData: {},
    choixCreneau: undefined,
    choixEcole: {
        centerId: undefined,
        selectedCenterId: undefined,
        typeId: undefined,
    },
    choixEnfant: undefined,
    creneauDispoAnniv: undefined,
    creneauxData: undefined,
    ecoleData: { 
        centersOptions: [],
        typesOptions: [],
        typesRecommendation: [],
    },
    enfantsData: undefined,
    equipmentData: undefined,
    ozmoSubscription: undefined,
    suppSchoolData: undefined,
}

function sharedForm(state = initialState, action) {
    switch (action.type) {
    case actions.CLEAR_ALL:
        return initialState
    case actions.SET_FORM_ENTRY:
        return Object.assign({}, state, {
            [action.payload.name]: action.payload.data,
        })

    case actions.UPDATE_BIRTHDAY_FORM_ENTRY:
        return {
            ...state,
            choixEcole: action.payload.data.ecole,
            choixEnfant: action.payload.data.enfant,
            creneauDispoAnniv: action.payload.data.creneau,
        }

    case actions.REMOVE_FORM_ENTRY:
        delete state[action.payload]
        return Object.assign({}, state)

    default:
        return state
    }
}

export default sharedForm
