import { createBrowserHistory } from "history"

function popUrl() {
    let history = createBrowserHistory()
    const urlParam = history.location.pathname.split("/")
    let url = "/" + urlParam[1]
    if (urlParam[2]) {
        url +=  "/" + urlParam[2]
    }
    if (urlParam.length === 6) { // il y a egalement un onglet
        url +=  "/" + urlParam[3]
    }
    if (urlParam.length === 7) { // il y a un onglet et un filtre
        url +=  "/" + urlParam[3] + "/" + urlParam[4]
    }

    history.push(url)
}

function openPopin(defaultOnglet, popin, dataId) {
    let history = createBrowserHistory()
    const urlParam = history.location.pathname.split("/")
    if (urlParam.length >= 3) {
        let url = "/" + urlParam[1] + "/" + urlParam[2]
        if (urlParam[3]) { // onglet
            url += "/" + urlParam[3]
            if (urlParam.length === 7 || urlParam.length === 5) { // il y a egalement un filtre
                url += "/" + urlParam[4]
            }
        } else {
            if (defaultOnglet) {
                url += "/" + defaultOnglet
            }
        }
        url += "/" + popin + "/" + dataId   
        history.push(url)
    }
}

function removeAccent(str) {
    var accent = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g, // C, c
    ]
    var noaccent = [ "A","a","E","e","I","i","O","o","U","u","N","n","C","c" ]
     
    for (var i = 0; i < accent.length; i++) {
        str = str.replace(accent[i], noaccent[i])
    }
     
    return str
}

export { openPopin, removeAccent, popUrl }
