import { useEffect, useState } from "react"

export default function useIsMobile() {
    const [ isMobile, setIsMobile ] = useState(checkIfMobile())

    function checkIfMobile() {
        return (
            (
                window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth
            ) <= 640
        )
    }

    function handleMobileView() {
        setIsMobile(
            checkIfMobile(),
        )
            
        window.addEventListener(
            "resize",
            () => {
                setIsMobile(
                    checkIfMobile(),
                )
            },
        )
    }

    useEffect(
        () => {
            handleMobileView()
        }, [],
    )

    return isMobile
}
