import * as ReactTooltip from "@radix-ui/react-tooltip"
import classNames from "classnames"
import { motion } from "framer-motion"
import { arrayOf, bool, node, oneOf, oneOfType, string } from "prop-types"
import { useState } from "react"

import { useTheme } from "../../ThemeContext/ThemeContext"

import "./tooltipLight.scss"

export const TooltipLight = (props) => {
    const theme = useTheme()
    const [ open, setOpen ] = useState(false)

    const tooltipClass = classNames(
        "tooltipLight",
        theme,
        props.position,
        {
            centered: props.centered,
            dark: props.dark,
        },
    )

    if (!props.text) {return props.children}

    return (
        <ReactTooltip.Root
            open={open}
            onOpenChange={setOpen}
        >
            <ReactTooltip.Trigger
                onClick={() => setOpen((prevOpen) => !prevOpen)}
                asChild
                onFocus={() => setTimeout(() => setOpen(true), 0)} // timeout needed to run this after onOpenChange to prevent bug on mobile
                onBlur={() => setOpen(false)}
            >
                {props.children}
            </ReactTooltip.Trigger>
            <ReactTooltip.Portal>
                <ReactTooltip.Content
                    asChild
                    side={props.position}
                    sideOffset={5}
                    avoidCollisions
                    align={"center"}
                    className={tooltipClass}
                >
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                    >
                        {props.text}
                        <ReactTooltip.Arrow className={"tooltipLightArrow"} width={16} height={8}/>
                    </motion.div>
                </ReactTooltip.Content>
            </ReactTooltip.Portal>
        </ReactTooltip.Root>
    )
}

TooltipLight.propTypes = {
    centered: bool,
    children: oneOfType([ string, arrayOf(node), node ]),
    dark: bool,
    position: oneOf([ "top", "right", "bottom", "left" ]),
    text: string,
}

TooltipLight.defaultProps = {
    position: "top",
}
