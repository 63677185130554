import { bool } from "prop-types"

export default function Step({ isActive, isCompleted }) {
    let className = "stepDot"
    if (isActive) {
        className += " active"
    } else if (isCompleted) {
        className += " completed"
    } else {
        className += " inactive"
    }

    return <div className={className}></div>
}

Step.propTypes = {
    isActive: bool,
    isCompleted: bool,
}
