import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ProfilCardWrapper from "./components/ProfilCardWrapper/ProfilCardWrapper"
import PadelLevels from "./PadelLevels"
import ButtonCta from "../../components/Buttons/button/button-cta"
import { SelectInput } from "../../components/Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../components/Inputs/TextInput/TextInput.jsx"
import PageHeader from "../../components/layout/pageHeader/PageHeader"
import Preloader from "../../components/loaders/preloader/preloader"
import {  createUserPlayerProfile, getUser, getUserPlayerProfile , modifyUserPlayerProfile } from "../../globalAPI/api"
import { PadelLevelArray } from "../../model/enums/User/PadelLevel"
import { PadelPlacementArray } from "../../model/enums/User/PadelPlacement"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import usePath from "../../routes/services/usePath"

import "./EditPadelInfos.scss"

export default function EditPadelInfos() {
    const path = usePath()
    const navigate = useNavigate()
    const user = useSelector(state => state.userLoaded)

    const [ playerProfile, setPlayerProfile ] = useState(user?.playerProfile)

    const [ isLoading, setIsLoading ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ form, setForm ] = useState({
        level: user.playerProfile?.level ? user.playerProfile?.level : "",
        licenseNumber: user.playerProfile?.licenseNumber ? user.playerProfile?.licenseNumber : "",
        positioning: user.playerProfile?.positioning === 0 || user.playerProfile?.positioning ? user.playerProfile?.positioning : "",
        ranking: user.playerProfile?.ranking ? user.playerProfile?.ranking : "",
    })
    const api = playerProfile?.playerProfileId ? modifyUserPlayerProfile : createUserPlayerProfile

    const sendPlayerProfile = () => {
        setError(false)
        setIsLoading(true)
        api(user.id, form).then(res => {
            if (res.Message) {
                setError(res.Message)
                setIsLoading(false)
            }
            else if (res?.contactId) {getUser().then(() => {
                setIsLoading(false)
                navigate("/padel/profil/coordonnees")
            })}
        })
    }

    useEffect(() => {
        if (user?.playerProfile) {
            setPlayerProfile(user.playerProfile)
            setForm({
                level: user.playerProfile?.level ? user.playerProfile?.level : "",
                licenseNumber: user.playerProfile?.licenseNumber ? user.playerProfile?.licenseNumber : "",
                positioning: user.playerProfile?.positioning || user.playerProfile?.positioning === 0 ? user.playerProfile?.positioning.toString() : "",
                ranking: user.playerProfile?.ranking ? user.playerProfile?.ranking : "",
            })
        }
        else if (user?.id) {getUserPlayerProfile(user.id).then(res => {
            setPlayerProfile(res)
            setForm({
                level: res.level,
                licenseNumber: res.licenseNumber,
                positioning: res.positioning,
                ranking: res.ranking,
            })
        })}
    }, [ user ])

    return (
        <div className="editPadelInfos">
            <PageHeader
                title="Éditer mes infos sportives"
                goBack={path("/profil/coordonnees")}
            />
            {isLoading && <Preloader fixed />}
            <ProfilCardWrapper>
                <div className="grid">
                    <div className="grid-item">
                        <SelectInput
                            id={"placement"}
                            options={PadelPlacementArray}
                            onChange={(e) => setForm(prev => ({
                                ...prev, positioning: e?.target?.value,
                            }))}
                            value={form?.positioning}
                            label="Placement"
                        />
                    </div>
                    <div className="grid-item">
                        <TextInput
                            label={"N° de licence"}
                            value={form.licenseNumber}
                            onChange={val => setForm(prev => ({ ...prev, licenseNumber: val ? val.replace(/[^A-Za-z0-9]/g, "") : "" }))}
                        />
                    </div>
                    <div className="grid-item">
                        <TextInput
                            label={"Classement fft"}
                            value={form.ranking}
                            inputMode={"numeric"}
                            type={"number"}
                            onChange={val => setForm(prev => ({ ...prev, ranking: val ? val.replace(/[^0-9 ]/g, "") : "" }))}
                        />
                    </div>
                    <div className="grid-item">
                        <SelectInput
                            id={"level"}
                            options={PadelLevelArray}
                            onChange={(e) => setForm(prev => ({
                                ...prev, level: e?.target?.value,
                            }))}
                            value={form?.level}
                            label="Niveau"
                        />
                    </div>
                </div>
                <div className="grid">
                    <div />
                    <div
                        className="grid-item level"
                        onClick={() => ModalHandler.show(PadelLevels)}
                    >
                        Voir les niveaux
                    </div>
                </div>
            </ProfilCardWrapper>
            { error && <div className="error">{error}</div>}
            <ButtonCta
                text="Enregistrer"
                onClick={
                    () => {
                        sendPlayerProfile()
                    }
                }
            />
        </div>
    )
}
