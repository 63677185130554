import { useLocation } from "react-router-dom"

function useInitialStep(component) {
    const location = useLocation()

    const locationPathArray = location.pathname.split("/")
    const lastPath = locationPathArray[locationPathArray.length - 1]

    return component.stepsArray.findIndex(s => s === lastPath)
}

export default useInitialStep
