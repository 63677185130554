import { bool } from "prop-types"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import resetScroll from "../../services/resetScroll"
import AdultForm from "../AdultForm/AdultForm"
import ButtonCta from "../Buttons/button/button-cta"
import CheckBlock from "../Forms/CheckBlock/CheckBlock"
import KidForm from "../KidForm/KidForm"
import { useSchoolTrainingCurrent } from "../SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"

import "./KidAdultForm.scss"

export default function KidAdultForm({ isLoadingKids }) {
    const [ initialized, setInitialized ] = useState(false)
    const [ showKidsForm, setShowKidsForm ] = useState(false)
    const [ searchParams, setSearchParams ] = useSearchParams()

    const currentStep = useSelector(state => state.stepManagement.currentStep)

    const currentSelection = useSchoolTrainingCurrent()
    const { value: { isAdult, kids, selectedKid }, setValue: setCurrentSelection } = currentSelection || {}

    const resetKidForm = () => {
        setCurrentSelection(prev => ({ ...prev, kidForm: {
            birthDate: "",
            firstname: "",
            gender: "",
            lastname: "",
        }, selectedKid: undefined }))
    }

    const handleKidSelection = kid => {
        if (selectedKid?.id === kid.id) {
            setCurrentSelection(prev => ({ ...prev, selectedKid: undefined }))
            resetKidForm()
            setShowKidsForm(false)
        } else {
            setShowKidsForm(true)
            const birthDateFormatter = date => {
                if (date) {
                    if (date.includes("/")) {
                        return date.split("/").reverse()?.join("-")
                    } else {
                        return date
                    }
                } else {
                    return ""
                }
            }
            setCurrentSelection(prev => ({ ...prev, kidForm: {
                birthDate: birthDateFormatter(kid.birthDate),
                firstname: kid.firstName,
                gender: kid.genderValue.toString(),
                lastname: kid.lastName,
            }, selectedKid: kid }))
        }
    }

    useEffect(() => {
        resetScroll()
    }, [])

    useEffect(() => {
        if (!kids?.length && !isLoadingKids) {
            setShowKidsForm(true)
        }
        if (kids?.length && !initialized && currentStep === 0) {
            const searchParamsKid = searchParams.get("enfant")
            if (searchParamsKid) {
                const matchingKid = kids.find(kid => kid.id === parseInt(searchParamsKid))
                if (matchingKid) {
                    handleKidSelection(matchingKid)
                    setInitialized(true)
                } else {
                    searchParams.delete("enfant")
                    setSearchParams(searchParams)
                    setInitialized(true)
                }
            } else {
                setInitialized(true)
            }
        }
    }, [ isLoadingKids, kids ])

    useEffect(() => {
        if (initialized) {
            setSearchParams(params => {
                params.set("adulte", isAdult ? "oui" : "non")
                selectedKid?.id ? params.set("enfant", selectedKid.id) : params.delete("enfant") 
                return params
            })
        }
    }, [ isAdult, selectedKid, initialized ])

    return (
        <div className="kidSelection">
            {!isAdult && (
                <>
                    <div className="userKids radioList--small">
                        {kids?.length > 0 && (
                            <>
                                {kids.map(kid => (
                                    <CheckBlock
                                        callback={() => handleKidSelection(kid)}
                                        checked={selectedKid?.id === kid.id}
                                        value={kid.id}
                                        name="kids"
                                        key={kid.id}
                                    >
                                        <div className="radioList__item__subTitle">
                                            {kid.firstName}
                                            <strong>{kid.lastName.toUpperCase()}</strong>
                                        </div>

                                        <div className="radioList__item__info">
                                            Né{kid.gender === "Fille" ? "e" : ""} le {kid.birthDate}
                                        </div>
                                    </CheckBlock>
                                ))}
                                <div className="radioList__item action">
                                    <ButtonCta
                                        noSkew
                                        noShadow
                                        heightFull
                                        className="buttonCta--iconMore addKids"
                                        text="Ajouter un nouvel enfant"
                                        onClick={() => {
                                            resetKidForm()
                                            setShowKidsForm(true)
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    {showKidsForm && (
                        <KidForm />
                    )}
                </>
            )}
            {isAdult && <AdultForm />}
        </div>
    )
}

KidAdultForm.propTypes = {
    isLoadingKids: bool,
}
