/**
 *
 * @param searchString - ex. ?param1=x&param2=xx...
 * @returns {{type: string, payload: *}}
 */
export const loadUrlQueryParameters = (searchString) => {
    var parameters = {}
    if (searchString) {
        searchString = searchString.substr(1)
        let rawSplitArray = searchString.split("&")
        if (rawSplitArray.length > 0) {
            rawSplitArray.forEach((row) => {
                let paramArray = row.split("=")
                if (paramArray.length >= 2) {
                    let value = ""
                    paramArray.forEach((val,idx)=>{
                        if (idx === 0) {
                            return
                        }
                        value += val
                        if (idx > 1) {
                            value += ("=" + val)
                        }
                    })
                    parameters[paramArray[0]] = decodeURI(value)
                }
            })
        }
    } else if (searchString === null) {
        parameters = {}
    }

    // Check and handle the parameters for a well form tokenActivation
    if (parameters.action === "tokenActivation") {
        if (
            parameters.invitation !== "TYPE_FRIENDSHIP" &&
            parameters.invitation !== "TYPE_FRIENDSHIP_AND_LEAGUE" &&
            parameters.invitation !== "TYPE_LOCATION" &&
            parameters.invitation !== "TYPE_FRIENDSHIP_AND_CUP"
        ) {
            parameters.action = ""
        }
    }

    return {
        payload: parameters,
        type: "QUERY_PARAMETERS_LOAD",
    }
}
