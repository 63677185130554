import { _apiCall } from "../../backend/axios"

export function getSuperSubsRequest(id) {
    return _apiCall(
        `read/supersubs/requests/${id}`,
        "GET",
    )
}

export function createSupersubMatchResponse(id, data, theme) {
    return _apiCall(
        `create/supersubs/requests/${id}/responses`,
        "POST",
        {
            "Content-Type": "application/json",
            activity: theme,
        },
        data,
    )
}
