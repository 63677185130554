import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import Toggle from "../../../../../components/Buttons/toggle/Toggle"
import { SelectInput } from "../../../../../components/Inputs/Select/SelectInput.jsx"
import Preloader from "../../../../../components/loaders/preloader/preloader"
import { useSchoolTrainingCurrent } from "../../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import { getUsCentersArea, getUsCentersBookable } from "../../../../../globalAPI/api"
import useThemeHeader from "../../../../../hooks/useThemeHeader.js"
import { centersToExclude } from "../../../../../model/data/centersToExclude"
import { nextStep } from "../../../../../store/stepsManagement/actions"
import PreForm from "../../../../kids/components/preForm/PreForm"

import "./PlayerSelectionPreform.scss"

export default function PlayerSelectionPreform() {
    const themeHeader = useThemeHeader()
    const currentSelection = useSchoolTrainingCurrent()
    const { value: { selectedAreaId, isAdult, centers, areas }, setValue: setCurrentSelection } = currentSelection || {}
    const [ searchParams,setSearchParams ] = useSearchParams()
    const dispatch = useDispatch()

    const user = useSelector(state => state.userLoaded)

    useEffect(() => {
        if (!centers) {
            getUsCentersBookable(themeHeader).then(
                (res) => {
                    const centersRes = res.data ? Object.values(res.data) : []
                    const filteredCenters = centersRes.filter((c) => !centersToExclude?.includes(c.id))
                    setCurrentSelection(prev => ({ ...prev, centers: filteredCenters }))
                    getUsCentersArea(user.centerId).then(resu => {
                        const area = resu.data?.filter(
                            area => area.name !== "Centre fermé" && filteredCenters.find(center => area.centers.includes(center.key)),
                        )
                        if (searchParams.get("areaId") && user?.centerId) {
                            const centerArea = area?.find(area => area.id === parseInt(searchParams.get("areaId")))
                            setCurrentSelection(prev => ({ ...prev, selectedAreaCenters: centerArea?.centers, selectedAreaId: parseInt(searchParams.get("areaId")) }))
                        } else if (!selectedAreaId && user?.centerId) {
                            const centerArea = area?.find(area => area.centers?.includes(user.centerId))
                            setCurrentSelection(prev => ({ ...prev, selectedAreaCenters: centerArea?.centers, selectedAreaId: centerArea?.id }))
                        }
                        setCurrentSelection(prev => ({ ...prev, areas: area }))
                        if (searchParams.get("adulte") === "oui" && searchParams.get("areaId")) {
                            dispatch(nextStep())
                        }
                    })
                },
            )
        }
    }, [])

    return (
        <PreForm customCls="playerSelectionPreform">
            {!areas && <Preloader fixed />}
            <div className="flexVerticalOnMobile">
                <label className="h2">
                    <span>Ville :</span>
                    <SelectInput
                        disabled={!areas}
                        value={selectedAreaId}
                        options={ areas?.length > 0 ?
                            areas.map(x => {
                                return { label: x.name, value: x.id }
                            })
                            :
                            undefined
                        }
                        label={"Sélectionne une ville"}
                        onChange={e => {
                            setSearchParams(params => {
                                params.set("areaId", e?.target?.value)
                                return params
                            })
                            setCurrentSelection(prev => ({
                                ...prev,
                                selectedAreaCenters: areas?.find(area => area.id === parseInt(e?.target?.value))?.centers,
                                selectedAreaId: parseInt(e?.target?.value),
                            }))
                        }}
                        placeholderOption="Sélectionne une ville"
                        required
                    />
                </label>
            </div>
            <div className="rightPart">
                <div className="toggle-wrapper">
                    <div className="label adult">Adulte</div>
                    <Toggle
                        enabled={!isAdult}
                        callback={checked => setCurrentSelection(prev => ({
                            ...prev,
                            adultForm: {
                                birthdate: user?.birthdate ? new Date(user.birthdate) : "",
                                level: user?.playerProfile?.level ?? "",
                                ranking: user?.playerProfile?.ranking ?? "",
                                title: user?.title ?? "",
                            },
                            isAdult: !checked,
                            kidForm: {
                                birthDate: "",
                                firstname: "",
                                gender: "",
                                lastname: "",
                            },
                            selectedKid: undefined,
                        }))}
                    />
                    <div className="label child">Enfant</div>
                </div>
            </div>
        </PreForm>
    )
}
