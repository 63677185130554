import { useEffect, useState } from "react"

export const usePaginatedListing = (apiFunction, params, callBack, initialCall = true, pageSize = 10, initialState = []) => {
    const [ listItems, setListItems ] = useState(initialState)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ offset, setOffset ] = useState(0)
    const [ totalElements, setTotalElements ] = useState(0)
    const [ disableActions, setDisableActions ] = useState(false)
    const [ displayToIndex, setDisplayToIndex ] = useState(pageSize - 1)

    const getItems = (forceReset = false) => {
        let innerOffset = offset
        if (forceReset) {
            setDisplayToIndex(pageSize - 1)
            innerOffset = 0
            setListItems([])
        }

        setIsLoading(true)
        apiFunction({
            ...params,
            offset: innerOffset,
            pageSize: pageSize,
        })
            .then((res) => {
                const items = res.items || res
                if (items.length === pageSize && res.count > pageSize) {
                    apiFunction({
                        ...params,
                        offset: innerOffset + pageSize,
                        pageSize: pageSize,
                    })
                        .then((res) => {
                            setOffset(innerOffset + pageSize)
                            if (res.length || res.items?.length) {
                                const newItems = res.items || res
                                setListItems(items.concat(newItems))
                            }
                        })
                        .catch(() => setIsLoading(false))
                        .finally(() => setIsLoading(false))
                } else {
                    setIsLoading(false)
                    setListItems(items)
                }
                callBack?.(res)
                setTotalElements(res.count || res.length)
            })
            .catch(() => setIsLoading(false))
    }

    useEffect(() => {
        if (initialCall) {
            getItems()
        }
    }, [])

    const loadMore = () => {
        setDisplayToIndex((prev) => prev + pageSize)
        if (listItems.length < totalElements) {
            setDisableActions(true)
            apiFunction({
                ...params,
                offset: offset + pageSize,
                pageSize: pageSize,
            })
                .then((res) => {
                    setOffset((prevOffset) => prevOffset + pageSize)
                    if (res.length || res.items?.length) {
                        const newItems = res.items || res
                        setListItems((prev) => prev.concat(newItems))
                    }
                })
                .catch(() => setDisableActions(false))
                .finally(() => setDisableActions(false))
        } else if (listItems.length === totalElements) {
            setIsLoading(false)
        }
    }

    return {
        disableActions,
        displayToIndex,
        getItems,
        isLoading,
        listItems,
        loadMore,
        totalElements,
    }
}
