import classNames from "classnames"
import { object } from "prop-types"

import usePlural from "../../../hooks/usePlural"
import { useAssets } from "../../../providers/AssetsProvider.jsx"

import "./LeagueMVP.scss"

export default function LeagueMVP({ player }) {
    const { m } = useAssets()
    return (
        <div className="leagueMVP">
            <div className="mvpVotes">
                <img src={m("icons.league.star")}/>
                <span className="votes">{player?.currentNbVotes} {usePlural(player?.currentNbVotes, "VOTE", "VOTES")}</span>
            </div>
            <div className="member"> 
                <div className={classNames("avatar", {
                    "placeholder": !player?.pictureUrl,
                })}>
                    <img src="/assets/icons/star--orange.svg" className="star"/>
                    <img src={player?.pictureUrl ?? "/assets/images/user-placeholder-b.svg"} className="picture"/>
                </div>
                <div className="desc">
                    <div className="name">{(player?.firstname ?? "") + (player?.lastname ? " " +  player.lastname.charAt(0) + "." : "")}</div>
                    <div>Homme du match</div>
                </div>
            </div>
        </div>
    )
}

LeagueMVP.propTypes = {
    player: object,
}
