
export default function useFavicon() {
    const changeFavicon = (path) => {
        let link = document.querySelector("#favicon")
        /* istanbul ignore else */
        if (!link) {
            link = document.createElement("link")
            link.rel = "icon"
            document.head.appendChild(link)
        }
        link.href = path
    }

    const favicon = (theme) => {
        switch (theme) {
        case "theme-soccer":
        default:
            return "/faviconSoccer.ico"

        case "theme-padel":
            return "/faviconPadel.ico"

        case "theme-business":
            return "/faviconBusiness.ico"
        }
    }

    return theme => changeFavicon(favicon(theme))
}
