function useFavoriteUniverse() {
    // in OMZO
    // favoriteUniverse = 0 -> soccer
    // favoriteUniverse = 1 -> padel

    return universe => {
        switch (universe) {
        case 0:
        default:
            return "theme-soccer"

        case 1:
            return "theme-padel"
        }
    }
}

export default useFavoriteUniverse
