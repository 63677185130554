// Data to sort is located in src/data/model/reservation/locationHours.js
const durationFilterSort = (a, b) => {
    if (a.display === b.display) {
        return 0
    } else if (a.display > b.display) {
        return 1
    } else {
        return -1
    }
}

export default durationFilterSort
