import {
    getPrivateLeague,
} from "../../league/api/api.js"
import {
    getPrivateTournament,
} from "../urbanCup/api"

export default function useGetPrivateTournament(tunnelType) {
    switch (tunnelType) {
    case "CUP":
        return getPrivateTournament

    case "LEAGUE":
        return getPrivateLeague

    default:
        // eslint-disable-next-line no-console
        console.error("useGetPrivateTournament - Not implemented tunneltype : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        return () => {}
    }
}
