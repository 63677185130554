export default function (state = {}, action) {

    switch (action.type) {
    case  "CONFIRMATION_OVERLAY_UPDATED":
        if (action.payload.open === true) {
            document.body.classList.add("hack-moz-scroll")
        } else {
            document.body.classList.remove("hack-moz-scroll")
        }
        return action.payload
    }

    return state
}
