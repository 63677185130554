import { bool, func, string } from "prop-types"
import { useEffect, useState } from "react"

import useUrbanLanguage from "../../../hooks/urbanLanguage/useUrbanLanguage"
import useThemeHeader from "../../../hooks/useThemeHeader"
import { registerLocationTeamsNames } from "../../../pages/reservation/infoReservation/LocationCompositionManagement/api"
import { useLocationStore } from "../../../pages/reservation/store/store.js"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import ButtonCta from "../../Buttons/button/button-cta"
import { TextInput } from "../../Inputs/TextInput/TextInput.jsx"
import Modal from "../../Modal/Modal"

import "./RenameTeamsPopin.scss"

const RenameTeamsPopin = ModalHandler.create(({ team1Name, setTeam1Name, team2Name, setTeam2Name, visible, hide }) => {
    const { currentLocation,setCurrentLocation } = useLocationStore()

    const [ popinTeam1Name, setPopinTeam1Name ] = useState(currentLocation.team1CustomName ? currentLocation.team1CustomName : currentLocation.team1Name)
    const [ popinTeam2Name, setPopinTeam2Name ] = useState(currentLocation.team2CustomName ? currentLocation.team2CustomName : currentLocation.team2Name)
    const [ teamNameError, setTeamNameError ] = useState(false)

    const [ emptyInputError, setEmptyInputError ] = useState(false)

    const { tu } = useUrbanLanguage()
    const themeHeader = useThemeHeader()

    const saveTeamsNames = (name1, name2) => {
        setTeamNameError(false)  // Reset team name error
        setEmptyInputError(false)  // Reset empty input error

        if (!name1 || !name2) {
            return setEmptyInputError(true)  // Set empty input error if either name is empty
        }
        if (name1 === "Team Urban" || name1 === "Team Soccer" || name2 === "Team Urban" || name2 === "Team Soccer") {
            return setTeamNameError(true)
        }

        registerLocationTeamsNames(currentLocation.id, name1, name2, themeHeader, true).then(res => {
            const data = res.data.data
            return setCurrentLocation({
                ...currentLocation,
                team1CustomName: data.team1CustomName,
                team1Name: data.team1Name,
                team2CustomName: data.team2CustomName,
                team2Name: data.team2Name,
                teamNamesCustomized: data.teamNamesCustomized,
            })
        })
        setTeam1Name(name1)
        setTeam2Name(name2)
        hide()
    }

    useEffect(
        () => {
            setEmptyInputError(false)
            setTeamNameError(false)
        }, [ team1Name, team2Name, popinTeam1Name, popinTeam2Name ],
    )

    return (
        <Modal
            onClose={hide}
            className="renameTeamsPopin"
            isOpen={visible}
        >
            <header> Renommer les équipes </header>
            <section>
                <TextInput
                    label={tu("booking.defaultTeam1Name")}
                    value={popinTeam1Name}
                    onChange={setPopinTeam1Name}
                    placeholder={tu("booking.defaultTeam1Name")}
                    maxLength={20}
                    fullWidth
                    actions={[
                        {
                            onClick: () => setPopinTeam1Name(""),
                            render: (
                                <img
                                    alt="action-icon"
                                    src={"/assets/icons/cross-black.svg"}
                                />
                            ),
                        },
                    ]}
                    iconSize={"small"}
                />
                <TextInput
                    label={tu("booking.defaultTeam2Name")}
                    value={popinTeam2Name}
                    onChange={setPopinTeam2Name}
                    placeholder={tu("booking.defaultTeam2Name")}
                    maxLength={20}
                    fullWidth
                    actions={[
                        {
                            onClick: () => setPopinTeam2Name(""),
                            render: (
                                <img
                                    alt="action-icon"
                                    src={"/assets/icons/cross-black.svg"}
                                />
                            ),
                        },
                    ]}
                    iconSize={"small"}
                />
                {teamNameError && <div className="teamNameError">Les noms d'équipes doivent être différent de "Team Soccer" et "Team Urban"</div>}
                {emptyInputError && <div className="teamNameError">Les noms d'équipes ne peuvent pas être vides</div>}
                <ButtonCta
                    isAlt
                    text="Valider"
                    disabled={emptyInputError || teamNameError}
                    onClick={() => saveTeamsNames(popinTeam1Name, popinTeam2Name)}
                />
            </section>
        </Modal>
    )
})

RenameTeamsPopin.propTypes = {
    isModalOpen: bool,
    setIsModalOpen: func,
    setTeam1Name: func,
    setTeam2Name: func,
    team1Name: string,
    team2Name: string,
}

RenameTeamsPopin.displayName = "RenameTeamsPopin"
export default RenameTeamsPopin
