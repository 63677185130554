import { useFormContext } from "react-hook-form"

import CheckBlock from "../../../../../components/Forms/CheckBlock/CheckBlock"
import useMediaPath from "../../../../../hooks/useMediaPath"
import PreForm from "../../../../kids/components/preForm/PreForm"
import { formArgs } from "../services/function"
import { useBookingStore } from "../services/store"

import "./ProximityCenters.scss"

export default function ProximityCenters() {
    const { watch, setValue } = useFormContext()
    const [ selectedActivity ] = watch([ formArgs.ACTIVITY_ID ])
    const proximityCenters = useBookingStore(state => state.bookingInfosData?.proximityCenters) || []
    const error = useBookingStore(state => state.error)
    const isLoading = useBookingStore(state => state.isLoading)
    const mediaPath = useMediaPath()

    const centersToDisplay = (proximityCenters &&
        proximityCenters.filter(proxi => proxi.resourceTypes?.find(
            resource => resource.categories[0]?.id === parseInt(selectedActivity),
        ))) ?? []

    const handleCenterChange = (centerId) => {
        setValue(formArgs.CENTER_ID, centerId)
    }

    return (
        <>
            {selectedActivity && centersToDisplay && centersToDisplay.length > 0 && !error && !isLoading &&  (
                <div className="c-col c-col--12 flex justify-center proximityCenters">
                    <PreForm fullsize>
                        <div className="sentence">
                            <img src={mediaPath([ "/assets/icons/center-orange.svg", "/assets/images/padel/icons/centre-red.svg" ])}/>
                            <span>{(proximityCenters.length === 1 ? "Autre centre" : "Autres centres") + " à proximité :"}</span>
                        </div>
                        <div className="proximity">
                            {
                                centersToDisplay.map(
                                    (center, index) => {
                                        if (index < 3) {
                                            return (
                                                <CheckBlock
                                                    callback={() => {
                                                        handleCenterChange(center.id)
                                                        const scrollElement = document.querySelector(".bookablesListing")
                                                        scrollElement?.scrollIntoView()
                                                    }}
                                                    value={center.id}
                                                    key={center.id + center.name}
                                                >
                                                    <p>{center.name}</p>
                                                </CheckBlock>
                                            )
                                        }
                                    },
                                )
                            }
                        </div>
                    </PreForm>
                </div>
            )}
        </>
    )
}
