import classNames from "classnames"
import { arrayOf, bool, func, node, number, oneOfType, string } from "prop-types"
import { forwardRef, useState } from "react"
import { useDispatch, useSelector, useStore } from "react-redux"

import Help from "../../../entries/main/../../components/tooltip/Help/Help"
import { nextStep } from "../../../store/stepsManagement/actions"

import "./StepHeader.scss"

/**
 * Composant purement visuel permettant d'afficher l'en tête d'une étape
 */
const StepHeader = forwardRef(
    ({ numero, title, onHeaderClick, optionnal, help, helpId, customClass, customHelp, customSkip }, ref) => {
        const colClassName = numero !== undefined ? "c-col--10 c-col--sm--12" : "c-col--12"
        const dispatch = useDispatch()
        const store = useStore()
        const [ helpIsOpen, setHelpIsOpen ] = useState(false)
        function skip(e) {
            e.stopPropagation()
            dispatch(
                nextStep(),
            )
        }
        const stepCount = useSelector(state => state.stepManagement?.stepCount)
        let isActiveStep = store.getState().stepManagement.currentStep === (numero - 1) ? true : false 

        return (
            <div className={classNames("stepHeader", { "z-10": helpIsOpen })} ref={ref}>
                <div className={customClass ? "c-row justify-center align-start layoutSmall " + customClass :
                    "c-row justify-center align-start layoutSmall"}>
                    <div className={`c-col ${colClassName}`}>
                        <header onClick={onHeaderClick}>
                            <span className="title">
                                { numero &&
                                    (<span className="numero">{ numero }
                                        { isActiveStep && <span className="hide-sm">/</span>}
                                        <span className="show-sm">/{stepCount}</span></span>)
                                }

                                <h1 className="text">
                                    { title }

                                    { optionnal &&
                                        <small>(Facultatif)</small>
                                    }
                                </h1>

                                { optionnal &&
                                    <div className="skipLink show-sm" data-testid="optionnal" onClick={customSkip ?? skip}>Passer</div>
                                }
                            </span>

                            { help !== undefined  && !customHelp &&
                                (<Help id={helpId} callback={setHelpIsOpen}>
                                    { help }
                                </Help>
                                )
                            }
                            { customHelp }
                        </header>
                    </div>
                </div>
            </div>
        )
    },
)

StepHeader.propTypes = {
    customClass: string,
    customHelp: oneOfType([ node, bool ]),
    customSkip: oneOfType([ bool, func ]),
    help: oneOfType([ string, node, arrayOf(node) ]),
    helpId: string,
    numero: oneOfType([ number, string ]),
    onHeaderClick: func,
    optionnal: bool,
    title: oneOfType([ string, node, arrayOf(node) ]),
}

StepHeader.displayName = "StepHeader"

export default StepHeader
