import { bool, func, number } from "prop-types"

import useMediaPath from "../../../hooks/useMediaPath"
import ButtonCta from "../../Buttons/button/button-cta"
import Stepper from "../Stepper"

export default function Step1({ step, onNextStep, disableActions }) {

    const mediaPath = useMediaPath()
    
    return (
        <div className={"step1"}>
            <header>
                <img src="/assets/images/logo-white.svg" />
            </header>
            <img src="/assets/images/step1firstConnection.png" />
            <h4 className="welcome">Bienvenue</h4>
            <h4 className="intro">Dans la team urbanSoccer</h4>
            <ul>
                <li>Réserve un terrain</li>
                <li>Invite tes potes</li>
                <li>Trouve un Supersub</li>
                <li>Participe à une League</li>
                <li>Inscris ton enfant à notre Academy</li>
            </ul>
            <div className="change-step">
                <ButtonCta
                    onClick={() => {
                        onNextStep()
                    }}
                    disabled={disableActions}
                    text={(
                        <div className={"button-first-connection"}>
                            <span>{"je me lance"}</span>
                            <img src={"/assets/icons/chevronsoccer.svg"} />
                        </div>
                    )}
                />
            </div>
            <div className={"first-connection-stepper"}>
                <Stepper activeStep={step}/>
            </div>
            <footer>
                <img src={mediaPath([ "/assets/images/bordersoccer.svg", "" ])} />
            </footer>
        </div>
    )
    
}

Step1.propTypes = {
    disableActions: bool,
    onNextStep: func,
    step: number,
}

