import { bool, func, node, oneOfType, string } from "prop-types"
import { useDispatch } from "react-redux"

import useMediaPath from "../../hooks/useMediaPath"
import { goToStep, nextStep } from "../../store/stepsManagement/actions"
import RoundCaptionnedIconButton from "../Buttons/roundCaptionnedIconButton/RoundCaptionnedIconButton"

/**
 * Bouton pour passer à l'étape suivante à l'aide d'une action envoyée au store
 *
 * Peut être désactivé ou caché
 */
function NextStepButton({ callback, promise, goToStepProps, checkBeforeNextStep, text }) {
    const dispatch = useDispatch()
    const mediaPath = useMediaPath()
    function onClick() {
        document.querySelector("#content")?.scrollTo({
            behavior: "smooth",
            left: 0,
            top: 0,
        })
        document.querySelector("#content")?.scrollTo({
            behavior: "smooth",
            left: 0,
            top: 0,
        })
        window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: 0,
        })

        if (checkBeforeNextStep) {
            return checkBeforeNextStep()
        }

        if (callback !== undefined) {
            if (promise) {
                callback().then(
                    () => {
                        if (goToStepProps) {
                            dispatch(goToStep(goToStepProps))
                        } else {
                            dispatch(nextStep())
                        }
                    },
                )
            } else {
                callback()
                if (goToStepProps) {
                    dispatch(goToStep(goToStepProps))
                } else {
                    dispatch(nextStep())
                }
            }
        }
        else {
            if (goToStepProps) {
                dispatch(goToStep(goToStepProps))
            } else {
                dispatch(nextStep())
            }
        }
    }

    return (
        <div className="stepButtonHolder stepButtonHolder--next">
            <RoundCaptionnedIconButton
                icon={mediaPath([ "arrow-right.svg", "arrow-right-black-alt.svg" ])}
                text={text ? text : "Suivant"}
                clickCallback={onClick}
            />
        </div>
    )
}

NextStepButton.propTypes = {
    callback: func,
    checkBeforeNextStep: oneOfType([ func, bool ]),
    goToStepProps: func,
    promise: bool,
    text: oneOfType([ string, bool, node ]),
}

export default NextStepButton
