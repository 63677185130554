
import classNames from "classnames"
import { bool, func, object, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import CreneauDispo from "./CreneauxDispo"
import ButtonCta from "../../../../components/Buttons/button/button-cta"
import CurrencyDisplay from "../../../../components/currencies/CurrencyDisplay"
import { TextInput } from "../../../../components/Inputs/TextInput/TextInput.jsx"
import Preloader from "../../../../components/loaders/preloader/preloader"
import Modal from "../../../../components/Modal/Modal"
import CbAliasError from "../../../../components/popin/CbAliasError/CbAliasError"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import ShopCartEquipmentLine from "../../../../components/reservation/Shop/ShopCartEquipmentLine/ShopCartEquimentLine"
import Step from "../../../../components/stepsManagement/Step"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext"
import HourDisplay from "../../../../components/Time/HourDisplay"
import AvoirCheckbox from "../../../../entries/main/contents/main/helpers/AvoirCheckbox"
import {
    deleteCreditCard,
    getCreditCard,
} from "../../../../globalAPI/api"
import useMediaPath from "../../../../hooks/useMediaPath"
import useResetScroll from "../../../../hooks/useResetScroll"
import useThemeFeatures from "../../../../hooks/useThemeFeatures"
import days from "../../../../model/enums/dayValues"
import ModalHandler from "../../../../providers/Modal/ModalHandler"
import usePath from "../../../../routes/services/usePath"
import { editUserSessionMeta } from "../../../../store/actions/content"
import { clearAllForms, setFormEntry } from "../../../../store/forms/actions"
import { goToStep } from "../../../../store/stepsManagement/actions"
import MesEnfants from "../../steps/MesEnfants"
import {
    addBirthdayDiscountCode,
    addBirthdayOption,
    cancelBirthday,
    editBirthday,
    generatePayzenBirthdayPaymentReqs,
    preregisterBirthday,
    removeBirthdayDiscountCode,
    removeBirthdayOption,
} from "../api"

import "./OrderSummary.scss"

function OrderSummary({ isPreregistration, title, isFinalisation }) {
    useResetScroll()
    const themeFeatures = useThemeFeatures()
    const cgvUrl = themeFeatures([ "https://www.urbansoccer.fr/cgv-urbansoccer/","https://www.urbanpadel.fr/mentions-legales/cgv-urbanpadel/ " ])
    const path = usePath()
    const mediaPath = useMediaPath()
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()

    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ codeError, setCodeError ] = useState(false)
    const [ promoCode, setPromoCode ] = useState("")
    const [ , setAvailableDiscountCodes ] = useState([])
    const [ cbCard, setCbCard ] = useState({})
    const [ isUsingUserCB, setIsUsingCB ] = useState(false)
    const [ optionsProduct, setOptionsProduct ] = useState(null)
    const [ paymentEnabled, setPaymentEnabled ] = useState(null)
    const [ showAcceptCGV, setShowAcceptCGV ] = useState(false)

    const dispatch = useDispatch()
    const forms = useSelector(state => state.forms)

    /* FORMULAIRES */
    const formCreneauDispo = forms[CreneauDispo.formName] !== undefined ?
        forms[CreneauDispo.formName]
        :
        {}

    const avoirFormData = forms.avoirFormData !== undefined ?
        forms.avoirFormData
        :
        {}

    const formEnfant = forms?.[MesEnfants.formName] !== undefined ?
        forms[MesEnfants.formName]
        :
        {}

    const promoCodeInput = useRef(null)
    const step = useRef(null)

    useEffect(
        () => {
            const formCreneauDispo = forms?.[CreneauDispo.formName] !== undefined ?
                forms[CreneauDispo.formName]
                :
                {}
            
            setAvailableDiscountCodes(forms?.[CreneauDispo.formName] !== undefined ?
                forms[CreneauDispo.formName].birthdaySubscription ?
                    forms[CreneauDispo.formName].birthdaySubscription.birthdayConfig.discountCodes
                    :
                    []
                :
                [],
            )

            if (formCreneauDispo?.birthdaySubscription?.discountCodes) {
                formCreneauDispo.birthdaySubscription.discountCodes.forEach(
                    code => {
                        setPromoCode(code.code)
                    },
                )
            }

            if (formCreneauDispo.birthdaySubscription) {
                setOptionsProduct(formCreneauDispo.birthdaySubscription.birthdayConfig.optionProducts)
                setAvailableDiscountCodes(formCreneauDispo.birthdaySubscription.birthdayConfig.discountCodes)
            }

            if (isPreregistration) {
                setPaymentEnabled(true)
            }
            setAjaxLoading(true)

            getCreditCard().then((result) => {
                if (result.data.data.tokenDetails) {
                    setCbCard(result.data.data)
                    setAjaxLoading(false)
                } else {
                    setAjaxLoading(false)
                }
                if (result?.data?.data?.errorCode) {
                    deleteCreditCard()
                    ModalHandler.show(CbAliasError)
                }

            })
        },[],
    )

    function sendPayzenForm(lyraPaymentUrl, data, returnUrl, amount) {
        if (data) {
            if (data.ipn) {
                returnUrl += "&ipn=" + encodeURIComponent("/api/" + data.ipn.split("/US-API/myurban/")[1])
            }

            let dest = lyraPaymentUrl

            if (amount) {
                dest = lyraPaymentUrl.includes("?") ? `${lyraPaymentUrl}&amount=${amount}` : `${lyraPaymentUrl}?amount=${amount}`
            }

            navigate(dest, { state: { lyraEmbedParams: { ...data, returnUrl }, prevLocation:location } })
        }
    }

    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
    }

    function addPromoCode() {
        setAjaxLoading(true)

        /* FORMULAIRES */
        const formCreneauDispo = forms[CreneauDispo.formName] !== undefined ?
            forms[CreneauDispo.formName]
            : {}

        const avoirFormData = forms.avoirFormData !== undefined ?
            forms.avoirFormData
            : {}

        const addPromocode = () => {
            addBirthdayDiscountCode(
                formCreneauDispo.birthdaySubscription.id, promoCode,
            ).then(
                (res) => {
                    if (res) {
                        if (res?.data?.data && !res.data.data.Message) {
                            setFormInStore(CreneauDispo.formName, {
                                ...formCreneauDispo,
                                birthdaySubscription: res.data.data,
                            })

                            setFormInStore("avoirFormData", {
                                ...avoirFormData,
                                recalcAvoir: true,
                            })
                            setCodeError(false)
                        } else {
                            setCodeError(true)
                        }
                    } else {
                        setCodeError(true)
                    }
                    setAjaxLoading(false)
                }, () => {
                    setCodeError(true)
                    setAjaxLoading(false)
                },
            )
        }

        let firstSubHasDiscountCode = false
        if (formCreneauDispo?.birthdaySubscription?.discountCodes
            && formCreneauDispo.birthdaySubscription.discountCodes.length > 0) {
            firstSubHasDiscountCode = true
        }

        if (firstSubHasDiscountCode) {
            deleteDiscountCode()
            return
        }

        /* check if there is already a discount code and if true, delete it before adding the new one */
        if (formCreneauDispo.birthdaySubscription && formCreneauDispo.birthdaySubscription.discountCodes.length > 0) {
            const oldCodes = formCreneauDispo.birthdaySubscription.discountCodes
            let hasNoOldCode = true
            oldCodes.forEach(
                code => {
                    hasNoOldCode = false

                    removeBirthdayDiscountCode(formCreneauDispo.birthdaySubscription.id, code.code).then((res) => {
                        setFormInStore(CreneauDispo.formName, {
                            ...formCreneauDispo,
                            birthdaySubscription: res.data.data,
                        })
                        setFormInStore("avoirFormData", {
                            ...avoirFormData,
                            recalcAvoir: true,
                        })
                        addPromocode()
                    })
                },
            )
            if (hasNoOldCode) {
                addPromocode()
            }
        } else {
            addPromocode()
        }
    }

    function deleteDiscountCode() {

        setAjaxLoading(true)

        removeBirthdayDiscountCode(formCreneauDispo.birthdaySubscription.id, promoCode).then((res) => {
            setFormInStore(CreneauDispo.formName, {
                ...formCreneauDispo,
                birthdaySubscription: res.data.data,
            })

            setFormInStore("avoirFormData", {
                ...avoirFormData,
                recalcAvoir: true,
            })
            setPromoCode("")
            setAjaxLoading(false)
        })
    }

    function handleCGV(e) {
        setPaymentEnabled(e.target.checked)
    }

    function proceedCheckout(withMultiPayments) {
        let returnUrl = import.meta.env.VITE_URL_BASE + `/?action=payzenPayment&type=birthday&theme=${theme}`
        if (formCreneauDispo.birthdaySubscription.id !== undefined) {
            returnUrl += "&firstSub=" + formCreneauDispo.birthdaySubscription.id
        }

        if (isPreregistration) {
            returnUrl += "&preregistration=true"
        }

        if (isFinalisation) {
            returnUrl += "&isFinalisation=true"
        }

        let data = {
            askRegisterPay: isUsingUserCB,
            returnUrl: returnUrl,
            subscriptionId: formCreneauDispo.birthdaySubscription.id,
        }
        if (withMultiPayments) {
            data.withMultiPayments = withMultiPayments
        }

        if (isUsingUserCB) {
            data.withAlias = true
        }

        if (avoirFormData.checkAvoir) {
            data.allocateCredits = true
        }
        setAjaxLoading(true)

        let formattedDate = formCreneauDispo.birthdaySubscription.start.replace(/T/g, " ")

        const sendReqs = () => generatePayzenBirthdayPaymentReqs(data).then(
            (res) => {
                if (avoirFormData.checkAvoir &&
                    avoirFormData.avoirAmount >= formCreneauDispo.birthdaySubscription.amountTotal) {
                    clearAllForms()
                    goToStep(0)
                    navigate(path("/confirmcommand"), {
                        state: {
                            confirmedSubscription: formCreneauDispo.birthdaySubscription,
                            message: "Un email de confirmation vous a été envoyé",
                            subtitle: "Nous vous confirmons votre réservation d'anniversaire.",
                            success: true,
                            title: "Réservation réussie !",
                            tunnelType: "BIRTHDAY",
                        },
                    })

                } else {
                    editUserSessionMeta("beforePayzenForm", true)

                    sendPayzenForm(
                        `/order/payment/birthday/${data.subscriptionId}`,
                        res,
                        returnUrl,
                        null,
                    )
                }
            },
        )

        if (!isPreregistration) {
            editBirthday(formCreneauDispo.birthdaySubscription.id,
                formCreneauDispo.birthdaySubscription.kid.id,
                formCreneauDispo.birthdaySubscription.birthdayConfig.id,
                formattedDate,
                formCreneauDispo.birthdaySubscription.gifts[0].product.id,
                formCreneauDispo.birthdaySubscription.gifts[0].sizeValue,
                formCreneauDispo.birthdaySubscription.drinks[0].id,
                formCreneauDispo?.birthdaySubscription?.cakes?.[0] ?
                    formCreneauDispo.birthdaySubscription.cakes[0].id
                    :
                    null
                ,
                formCreneauDispo.birthdaySubscription.comments,
                null,
                true,
            ).then(sendReqs)
        } else {
            sendReqs()
        }
    }

    function addSchoolOption(event) {
        setAjaxLoading(true)

        /* FORMULAIRES */
        const formCreneauDispo = forms[CreneauDispo.formName] !== undefined ?
            forms[CreneauDispo.formName]
            :
            {}

        const avoirFormData = forms.avoirFormData !== undefined ?
            forms.avoirFormData
            :
            {}

        const input = event.target
        const optionId = input.id.split("-")[1]

        if (input.checked) {
            addBirthdayOption(formCreneauDispo.birthdaySubscription.id, optionId).then((res) => {
                setFormInStore(CreneauDispo.formName, {
                    ...formCreneauDispo,
                    birthdaySubscription: res.data.data,
                })

                setFormInStore("avoirFormData", {
                    ...avoirFormData,
                    recalcAvoir: true,
                })
                setAjaxLoading(false)
            })
        } else {
            removeBirthdayOption(formCreneauDispo.birthdaySubscription.id, optionId).then((res) => {
                setFormInStore(CreneauDispo.formName, {
                    ...formCreneauDispo,
                    birthdaySubscription: res.data.data,
                })

                setFormInStore("avoirFormData", {
                    ...avoirFormData,
                    recalcAvoir: true,
                })
                setAjaxLoading(false)
            })
        }
    }

    function preregister() {

        /* FORMULAIRES */
        const formCreneauDispo = forms[CreneauDispo.formName] !== undefined ?
            forms[CreneauDispo.formName]
            :
            {}
        
        setAjaxLoading(true)

        let formattedDate = formCreneauDispo.birthdaySubscription.start.replace(/T/g, " ")

        editBirthday(formCreneauDispo.birthdaySubscription.id,
            formCreneauDispo.birthdaySubscription.kid.id,
            formCreneauDispo.birthdaySubscription.birthdayConfig.id,
            formattedDate,
            formCreneauDispo.birthdaySubscription.gifts[0].product.id,
            formCreneauDispo.birthdaySubscription.gifts[0].sizeValue,
            formCreneauDispo.birthdaySubscription.drinks[0].id,
            formCreneauDispo?.birthdaySubscription?.cakes?.[0] ?
                formCreneauDispo.birthdaySubscription.cakes[0].id : null,
            formCreneauDispo.birthdaySubscription.comments,
            null,
            true,
        ).then(() => {

            preregisterBirthday(formCreneauDispo.birthdaySubscription.id).then((res) => {
                clearAllForms()
                goToStep(0)
                navigate(path("/confirmcommand"), {
                    state: {
                        confirmedSubscription: res.data.data,
                        limitDate: res.data.data.preRegistrationLimitDate,
                        message: "preregisterConfirm",
                        subtitle: "",
                        success: true,
                        title: "Vous y êtes presque&nbsp;!",
                        tunnelType: "BIRTHDAY",
                    },
                })
            })

        })

    }

    function cancelRegistration() {

        /* FORMULAIRES */
        const formCreneauDispo = forms[CreneauDispo.formName] !== undefined ?
            forms[CreneauDispo.formName]
            : {}
        setAjaxLoading(true)

        cancelBirthday(formCreneauDispo.birthdaySubscription.id).then(() => {
            navigate(path("/confirmcommand"), {
                state: {
                    confirmedSubscription: formCreneauDispo.birthdaySubscription,
                    isCanceled: true,
                    message: "Nous vous confirmons avoir annulé votre réservation d'anniversaire. ",
                    success: true,
                    title: "Réservation d'anniversaire annulée",
                    tunnelType: "BIRTHDAY",
                },
            })
        })

    }

    function useUserCB(e) {
        setIsUsingCB(e.target.checked)
    }

    const giftSize = formCreneauDispo.birthdaySubscription ?
        formCreneauDispo?.birthdaySubscription?.gifts?.[0]?.product?.name?.toLowerCase().includes("maillot") ?
            formCreneauDispo.birthdaySubscription.gifts[0].size
            :
            null
        : null

    const date = formCreneauDispo.birthdaySubscription ?
        new Date(formCreneauDispo.birthdaySubscription.start)
        :
        ""

    let amountTotal = formCreneauDispo.birthdaySubscription !== undefined ?
        formCreneauDispo.birthdaySubscription.amountTotal
        :
        0

    // handle total amount with avoir
    if (avoirFormData?.totalWithAvoir !== undefined) {
        amountTotal = avoirFormData.totalWithAvoir
    }

    const child = formEnfant ? formEnfant.selectedKid : undefined
    let childName
    if (child !== undefined) {
        childName = child.firstName + " " + child.lastName
    } else {
        if (formCreneauDispo?.birthdaySubscription?.kid) {
            childName = formCreneauDispo.birthdaySubscription.kid.firstName + " " + formCreneauDispo.birthdaySubscription.kid.lastName
        }
    }

    let totalTva
    if (formCreneauDispo?.birthdaySubscription?.proforma) {
        totalTva = formCreneauDispo.birthdaySubscription.proforma.amountTTC -
        formCreneauDispo.birthdaySubscription.proforma.amountHT
    }

    const cartOptions = formCreneauDispo.birthdaySubscription ? formCreneauDispo.birthdaySubscription.optionProducts : []

    // handle tva amount with avoir
    if (avoirFormData?.tvaWithAvoir !== undefined) {
        totalTva = avoirFormData.tvaWithAvoir
    }

    // handle avoir amount
    // let avoirAmount = 0;
    // if (avoirFormData && avoirFormData.avoirAmount !== undefined) {
    //     avoirAmount = avoirFormData.avoirAmount;
    // }

    let optionsDOM = []
    if (cartOptions) {
        cartOptions.forEach(
            (option) => {
                optionsDOM.push(
                    <li>
                        <div className="info">{option.name}</div>
                        <div className="price">
                            <CurrencyDisplay price={option.price}/>
                        </div>
                    </li>,
                )
            },
        )
    }

    let optionsMendatoryDOM = []
    if (formCreneauDispo?.birthdaySubscription?.gifts?.[0]) {
        optionsMendatoryDOM.push(
            <li>
                <div className="info">
                    <span className="options-mendatory-label">Cadeau d'anniversaire : </span>
                    {formCreneauDispo.birthdaySubscription.gifts[0].product.name}
                    {
                        giftSize &&
                            <span> {giftSize}</span>
                    }
                </div>
                <div className="offert-label">
                    <div>Offert</div>
                </div>
            </li>,
        )
    }
    if (formCreneauDispo?.birthdaySubscription?.drinks?.[0]) {
        optionsMendatoryDOM.push(
            <li>
                <div className="info">
                    <span className="options-mendatory-label">Boisson : </span>
                    {formCreneauDispo.birthdaySubscription.drinks[0].name}
                </div>
                <div className="offert-label">
                    <div>Offert</div>
                </div>
            </li>,
        )
    }
    if (formCreneauDispo?.birthdaySubscription?.cakes?.[0]) {
        optionsMendatoryDOM.push(
            <li>
                <div className="info">
                    <span className="options-mendatory-label">Gâteau : </span>
                    {formCreneauDispo.birthdaySubscription.cakes[0].name}
                </div>
                <div className="offert-label">
                    <div>Offert</div>
                </div>
            </li>,
        )
    }

    const equipments = formCreneauDispo.birthdaySubscription ?
        formCreneauDispo.birthdaySubscription.catalogArticles
        :
        []
    
    let cartLines = []
    let equipmentSubTotal = 0
    if (equipments) {
        equipments.forEach(
            (equipment) => {
                equipmentSubTotal += Number.parseInt(equipment.quantity) * Number.parseFloat(equipment.product.price)
                cartLines.push(
                    <li key={`product--${equipment.id}`}>
                        <ShopCartEquipmentLine
                            productOrder={equipment}
                            productInfos={equipment.product}
                            disableDeletion={isPreregistration}
                            tunnelType={"BIRTHDAY"}
                        />
                    </li>,
                )
            },
        )
    }

    let equipmentDiscountOnSubTotal = formCreneauDispo.birthdaySubscription ?
        formCreneauDispo.birthdaySubscription.catalogDiscountAmount
        :
        []

    const help = <PhoneCallHelp tunnelType="BIRTHDAY"/>

    const optionsAtPayment = []
    let optionsProducts = optionsProduct

    if (optionsProducts) {
        if (optionsProducts.length > 0) {
            optionsProducts.forEach(option => {
                if (option.showAtPayment) {
                    optionsAtPayment.push(option)
                }
            })
        }
    }

    let promoCodeDiscount = 0
    if (formCreneauDispo.birthdaySubscription !== undefined) {
        if (formCreneauDispo.birthdaySubscription.discountCodes) {
            formCreneauDispo.birthdaySubscription.discountCodes.forEach(
                code => {
                    promoCodeDiscount += code.amount
                },
            )
        }
    }

    let firstSubOptionsIds = []
    if (formCreneauDispo?.birthdaySubscription?.optionProducts) {
        formCreneauDispo.birthdaySubscription.optionProducts.forEach(
            (el) => {
                firstSubOptionsIds.push(el.id)
            },
        )
    }

    let firstSubHasDiscountCode = false
    let firstSubDiscount = 0
    if (formCreneauDispo?.birthdaySubscription?.discountCodes) {
        formCreneauDispo.birthdaySubscription.discountCodes.forEach(
            (code) => {
                firstSubDiscount = firstSubDiscount + code.amount
                firstSubHasDiscountCode = true
            },
        )
    }

    const openOptionInfoModal = (optionInfo) => {
        ModalHandler.show(OptionInfoModal, {
            optionInfo: optionInfo,
        })
    }

    const buttonsCount = () => {
        let count = 1
        if (isPreregistration) {count += 1}
        if (!isPreregistration && formCreneauDispo.birthdaySubscription.birthdayConfig.preregistrationEnabled &&
            !formCreneauDispo.birthdaySubscription.preRegistrationLimitDate) {count += 1}
        return count
    }

    useEffect(() => {
        setShowAcceptCGV(false)
    }, [ paymentEnabled ])

    return (
        <Step
            title={title ? title : "Ma commande"}
            help={ help }
            helpId={"birthDayCreneauHelp"}
            ref={step}
            className="ordersummary-anniversaire"
            cls={"arrows-on-sides noMarginBottom " +
                (isPreregistration ? "preregistration" : "")
            }
            returnText={ isPreregistration ? "Voir mes anniversaires" : undefined }
            onReturn={ isPreregistration ?
                () => {
                    navigate(path("/kids/anniversaire/listing"))
                }
                :
                undefined
            }
            otherId='last'
        >
            {ajaxLoading &&
                <Preloader fixed={true}/>
            }
            {
                formCreneauDispo.birthdaySubscription &&
                    (<div className="formsHolder c-row c-mrg--btm--4 c-mrg--btm--sm--1 orderSummary
                layoutSmall order-summary-birthday c-col c-col--10">
                        <div className="c-col c-col--content c-col--xm--12">
                            <div className="cartPriceDetails">
                                <div className="cartPriceDetails__heading">
                                    <div className="logo">
                                        <img src={formCreneauDispo.birthdaySubscription.birthdayConfig.type.pictureUrl} />
                                    </div>

                                    <div className="content">
                                        <div className="name">{childName}</div>
                                        <div className="center">{formCreneauDispo.birthdaySubscription.birthdayConfig.type.name} - {formCreneauDispo.birthdaySubscription.birthdayConfig.centerName}</div>
                                        <div className="time hide-sm">
                                            {days[date.getDay()]} {date.toLocaleDateString()} :&nbsp;
                                            <HourDisplay
                                                date={new Date(formCreneauDispo.birthdaySubscription.start)}/>
                                            <span> - </span>
                                            <HourDisplay
                                                date={new Date(new Date(formCreneauDispo.birthdaySubscription.start).getTime() + (formCreneauDispo.birthdaySubscription.birthdayConfig.birthdayDuration * 60000))}/>
                                        </div>
                                        <div className="time show-sm">
                                            {days[date.getDay()]} {date.toLocaleDateString()} :&nbsp;
                                            <div>
                                                <HourDisplay
                                                    date={new Date(formCreneauDispo.birthdaySubscription.start)}/>
                                                <span> - </span>
                                                <HourDisplay
                                                    date={new Date(new Date(formCreneauDispo.birthdaySubscription.start).getTime() + (formCreneauDispo.birthdaySubscription.birthdayConfig.birthdayDuration * 60000))}/>
                                            </div>
                                        </div>

                                        <div className="price show-sm">
                                            <CurrencyDisplay price={formCreneauDispo.birthdaySubscription.birthdayPricing} />
                                        </div>
                                    </div>

                                    <div className="price hide-sm">
                                        <CurrencyDisplay price={formCreneauDispo.birthdaySubscription.birthdayPricing}/>
                                    </div>
                                </div>

                                <ul className="cartPriceDetails__content mendatory-option">
                                    {optionsMendatoryDOM.length > 0 &&
                                optionsMendatoryDOM
                                    }
                                </ul>

                                <ul className="cartPriceDetails__content">
                                    {optionsDOM.length > 0 &&
                                        (<div>
                                            <div className="options-label">Mes options : </div>
                                            {optionsDOM}
                                        </div>)
                                    }
                                </ul>

                            </div>

                            {cartLines.length > 0 &&
                                (<div className="cartOrderDetails c-mrg--btm--sm--1">
                                    <div className="h3">Mes cadeaux supplémentaires</div>
                                    <ul className="cartOrderDetails__list">
                                        { cartLines }
                                    </ul>

                                    <ul className="cartOrderDetails__price">
                                        { equipmentDiscountOnSubTotal < 0 &&
                                            (<li className="discount">
                                                <div className="info">Remise équipement :</div>
                                                <div className="price"><CurrencyDisplay price={equipmentDiscountOnSubTotal}/></div>
                                            </li>)
                                        }
                                        <li className="subtotal">
                                            <div className="info">Total cadeaux supplémentaires :</div>
                                            <div className="price">
                                                <CurrencyDisplay price={(equipmentSubTotal + equipmentDiscountOnSubTotal)}/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>)
                            }
                        </div>

                        <div className="c-col c-col--sidebar c-col--xm--12">
                            <div className={isPreregistration ? "cartBox cartBoxPreRegistration" : "cartBox"}>
                                {!isPreregistration && optionsAtPayment.length > 0 &&
                                optionsAtPayment.map(
                                    (el) => (
                                        <div className="cartBox__content bordered" key={"optAtPayment-" + el.id}>
                                            <div className="c-inputHolder--checkbox">
                                                <input
                                                    type="checkbox"
                                                    value=""
                                                    disabled={isPreregistration}
                                                    onChange={addSchoolOption}
                                                    defaultChecked={firstSubOptionsIds.includes(el.id)}
                                                    id={"optAtPayment-" + el.id}
                                                />
                                                <label htmlFor={"optAtPayment-" + el.id}>
                                                    {el.name === "Assur Annul EdF" ?
                                                        "Assurance annulation"
                                                        :
                                                        el.name
                                                    }
                                                </label>
                                                {el.description && (
                                                    <img
                                                        alt=""
                                                        className="cancelInsurance"
                                                        src={mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                        onMouseOver={e => e.currentTarget.src = mediaPath([ "/assets/icons/info-small-orange-hover.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                        onMouseOut={e => e.currentTarget.src = mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
                                                        onClick={
                                                            () => {
                                                                openOptionInfoModal({
                                                                    description: el.description,
                                                                    title: el.name,
                                                                })
                                                            }
                                                        }
                                                    />
                                                )}
                                            </div>

                                            <div className="h2-600 price-option">
                                                <CurrencyDisplay price={el.price}/>
                                            </div>
                                        </div>
                                    ),
                                )
                                }

                                {
                                    !isPreregistration &&
                                        (<div className="cartBox__content bordered">
                                            <div className="h4">Sous total</div>
                                            <div className="h2-600">
                                                <CurrencyDisplay formatZero={true} price={(formCreneauDispo.birthdaySubscription.amountTotal + promoCodeDiscount)}/>
                                            </div>
                                        </div>)
                                }

                                {
                                    !isPreregistration &&
                                        (<div className="cartBox__content column" ref={promoCodeInput}>
                                            <div className={"discount-div" + (isPreregistration ? " disabled" : "")}>
                                                <TextInput
                                                    label="Code promo"
                                                    value={promoCode}
                                                    onChange={setPromoCode}
                                                    disabled={firstSubHasDiscountCode || isPreregistration}
                                                    actions={[ {
                                                        onClick: addPromoCode,
                                                        render: firstSubHasDiscountCode ? (
                                                            <img alt="" src={mediaPath([ "/assets/icons/cross-orange.svg", "/assets/images/padel/icons/cross-close.svg" ])}/>
                                                        ) : (
                                                            <ButtonCta
                                                                text="OK"
                                                                noSkew
                                                                noShadow
                                                            />),
                                                    } ]}
                                                />
                                            </div>

                                            {codeError &&
                                                (<div className="rowPromo">
                                                    <div className="info">Code incorrect</div>
                                                </div>)
                                            }
                                            {promoCodeDiscount > 0 &&
                                                (<div className="rowPromo">
                                                    <div className="info">Remise :</div>
                                                    <div className="price">- <CurrencyDisplay price={promoCodeDiscount} /></div>
                                                </div>)
                                            }
                                        </div>)
                                }

                                <AvoirCheckbox
                                    totalAmount={formCreneauDispo.birthdaySubscription ?
                                        formCreneauDispo.birthdaySubscription.amountTotal
                                        :
                                        null
                                    }
                                    tva={formCreneauDispo.birthdaySubscription ?
                                        formCreneauDispo.birthdaySubscription.proforma.amountTTC - formCreneauDispo.birthdaySubscription.proforma.amountHT
                                        :
                                        null
                                    }
                                    formName="avoirFormData"
                                    isPreregistration={isPreregistration}
                                >
                                </AvoirCheckbox>

                                <div className="cartBox__info column biggerPadding noMargin">
                                    <div className="rowPrice big">
                                        <div className="info">Total</div>
                                        <div className="price">
                                            <CurrencyDisplay formatZero={true} price={(amountTotal)}/>
                                        </div>
                                    </div>

                                    <div className="rowPrice">
                                        <div className="info">Dont TVA :</div>
                                        <div className="price">
                                            <CurrencyDisplay formatZero={true} price={totalTva}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="cartBox__cta">
                                    <div className="c-inputHolder--checkbox">
                                        {
                                            isPreregistration &&
                                                (<input type="checkbox" id="cartCGV" disabled="true"
                                                    checked="true" onChange={handleCGV}/>)
                                        }
                                        {
                                            !isPreregistration &&
                                                <input type="checkbox" id="cartCGV" onChange={handleCGV}/>
                                        }
                                        <label>
                                            J’accepte les <a style={{ cursor: "pointer" }} onClick={() => {
                                                window.open(cgvUrl, "_blank")
                                            }}>Conditions Générales de Vente</a>
                                        </label>
                                    </div>

                                    {
                                        Object.keys(cbCard).length > 0  &&
                                            (<div className="c-inputHolder--checkbox cb-div-preregistration">
                                                <input type="checkbox" id="cb"
                                                    onChange={useUserCB}/>
                                                <label>
                                                    <span>J'utilise ma carte enregistrée</span> <span>({cbCard.tokenDetails.pan})</span>
                                                </label>
                                            </div>)
                                    }
                                    {showAcceptCGV && !paymentEnabled && (
                                        <div className="acceptCGV">
                                            <img
                                                src={mediaPath([ "/assets/icons/notifications.svg", "/assets/images/padel/icons/icons-notifications.svg" ])}
                                                className="alert"
                                            />
                                            Tu n'as pas accepté les CGV
                                        </div>
                                    )}   

                                    <div className={classNames("orderSummaryButtonsHolder", {
                                        even: buttonsCount() % 2 === 0,
                                        odd: buttonsCount() % 2 !== 0,
                                    })}>
                                        {isPreregistration ?
                                            (<React.Fragment>
                                                <ButtonCta
                                                    className={classNames("buttonCta--big", {
                                                        disabled: !paymentEnabled,
                                                    })}
                                                    text={
                                                        amountTotal !== 0 ?
                                                            "Payer"
                                                            :
                                                            "Finaliser"
                                                    }
                                                    onClick={() => !paymentEnabled ? setShowAcceptCGV(true) : proceedCheckout(false)}
                                                />
                                                <ButtonCta
                                                    className={classNames("buttonCta--big", {
                                                        disabled: !paymentEnabled,
                                                    })}
                                                    isCta2
                                                    text="Annuler"
                                                    onClick={!paymentEnabled ? () => setShowAcceptCGV(true) : cancelRegistration}
                                                />
                                            </React.Fragment>)
                                            :
                                            (<React.Fragment>
                                                <ButtonCta
                                                    className={classNames("buttonCta--big wrap", {
                                                        disabled: !paymentEnabled,
                                                    })}
                                                    onClick={() => !paymentEnabled ? setShowAcceptCGV(true) : proceedCheckout(false)}
                                                    text = {
                                                        amountTotal !== 0 ?
                                                            <span>Payer</span>
                                                            :
                                                            <span>Finaliser</span>
                                                    }
                                                />
                                                { formCreneauDispo.birthdaySubscription.birthdayConfig.preregistrationEnabled &&
                                                        !formCreneauDispo.birthdaySubscription.preRegistrationLimitDate && (
                                                    <ButtonCta
                                                        className={classNames("doubleText", {
                                                            "buttonCta--big": theme === "theme-soccer",
                                                            disabled: !paymentEnabled,
                                                        })}     
                                                        isCta2
                                                        text="Paiement différé"
                                                        subText={"à régler sous " + formCreneauDispo.birthdaySubscription.birthdayConfig.preRegisterDuration + " jours"}
                                                        onClick={!paymentEnabled ? () => setShowAcceptCGV(true) : preregister}
                                                    />
                                                )}
                                            </React.Fragment>
                                            )
                                        }
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>)
            }
        </Step>
    )
}

export const OptionInfoModal = ModalHandler.create(({ optionInfo, visible, hide }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className="insuranceInfoPopin"
        >
            {optionInfo && (
                <>
                    <header>
                        <span>
                            {optionInfo.title}
                        </span>
                    </header>
                    <section>
                        <div
                            dangerouslySetInnerHTML={{ __html: optionInfo.description }}
                            className="dangerousHtml"
                        >
                        </div>
                    </section>
                </>
            )}
        </Modal>
    )
})

OrderSummary.propTypes = {
    clearAllForms: func,
    editUserSessionMeta: func,
    forms: object,
    goToStep: func,
    isFinalisation: bool,
    isPreregistration: bool,
    setFormInStore: func,
    title: string,
}

export default OrderSummary
