import classNames from "classnames"
import PropTypes, { bool, oneOfType, string } from "prop-types"
import { useLocation, useNavigate } from "react-router-dom"

import "./LeagueHeader.scss"

export const LeagueHeader = ({ children, previous, title, cls }) => {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <div className={classNames("league-header-wrapper", {
            [cls]: cls,
        })}>
            <div className={"league-header c-row justify-center"}>
                <div
                    className={"header-title-wrapper"}
                >
                    {(location?.state?.hasPreviousHistory || previous) && (
                        <div
                            data-testid="backButton"
                            className={"back-button"}
                            onClick={() => {
                                navigate(previous ?? -1)
                            }}
                        >
                            <img alt="boutton retour" src={"/assets/icons/arrow-left-black-alt.svg"}
                            />
                        </div>
                    )}
                    <h1>
                        {title}
                    </h1>
                </div>
                {children}
            </div>
        </div>
    )
}

LeagueHeader.propTypes = {
    children: PropTypes.node,
    cls: PropTypes.string,
    previous: oneOfType([ string, bool ]),
    title: PropTypes.string,
}
