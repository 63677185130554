import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

import { navigateToPlayerPage } from "../../../pages/league/service/service.js"
import { useAssets } from "../../../providers/AssetsProvider.jsx"

import "./MatchEventCard.scss"

export default function MatchEventCard({ cards, goals, teamName }) {
    const navigate = useNavigate()
    const { m } = useAssets()

    const renderEvent = (event, iconPath, key) => (
        <motion.li
            className="event"
            key={key}
            variants={itemVariants}
            onClick={() => navigateToPlayerPage(navigate, event.playerId, null, event.teamId)}
        >
            <div className="image">
                {event.count && <span>{event.count}</span>}
                <img src={iconPath} alt="" />
            </div>
            <div className="name">{event?.playerName}</div>
        </motion.li>
    )

    const noEvent = !goals?.length && !cards?.["2"]?.length && !cards?.["1"]?.length

    return (
        <div className="matchEventCard">
            <div className="teamName">{teamName}</div>
            <AnimatePresence>
                <motion.ul
                    className={classNames("events", {
                        noEvents: noEvent,
                    })}
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                    {goals?.map((event, index) => renderEvent(event, m("icons.league.goal"), `goal-${index}`))}
                    {cards?.["2"]?.map((event, index) => renderEvent(event, m("icons.league.redCard"), `red-${index}`))}
                    {cards?.["1"]?.map((event, index) => renderEvent(event, m("icons.league.yellowCard"), `yellow-${index}`))}
                    {/*{goals?.map((event, index) => renderEvent(event, "goal.svg", `goal-${index}`))}*/}
                    {/*{cards?.["2"]?.map((event, index) => renderEvent(event, "redCard.svg", `red-${index}`))}*/}
                    {/*{cards?.["1"]?.map((event, index) => renderEvent(event, "yellowCard.svg", `yellow-${index}`))}*/}
                    {noEvent && (
                        <p className="noEvent">Aucun événement marquant</p>
                    )}
                </motion.ul>
            </AnimatePresence>
        </div>
    )
}

const containerVariants = {
    hidden: { height: 0, opacity: 0 },
    visible: {
        height: "auto",
        opacity: 1,
        transition: {
            staggerChildren: 0.07,
        },
    },
}

const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
}

MatchEventCard.propTypes = {
    cards: PropTypes.shape({
        "1": PropTypes.arrayOf(PropTypes.object),
        "2": PropTypes.arrayOf(PropTypes.object),
    }),
    goals: PropTypes.arrayOf(PropTypes.object),
    teamName: PropTypes.string,
}
