import { getNotificationSettings, getOzmoNotificationToken, postNotificationTokenToOzmo, updateNotificationSettings } from "../globalAPI/api"

export function sendNotifTokenToOzmo(userid) {
    return new Promise(
        (resolve) => {
            const  search  = window.location.search
            let queryParameters = search.replace("?", "").split("&")
            let updatedQueryParameters = {}
            queryParameters.forEach(
                (el) => {
                    var keyValue = el.split("=")
                    var key = keyValue[0]
                    var value = keyValue[1]
                    updatedQueryParameters[key] = value
                },
            )
            const { pushActu, notifToken } = updatedQueryParameters
            if (pushActu) {
                getOzmoNotificationToken(userid).then(
                    (res) => {
                        if (res.data.data.value === null) {
                            if (notifToken) {
                                postNotificationTokenToOzmo(userid, notifToken).then(
                                    () => {
                                        getNotificationSettings(userid).then(
                                            (res) => {
                                                updateNotificationSettings(
                                                    userid,
                                                    res.data.data.map(
                                                        (notifSetting) => notifSetting.id,
                                                    ),
                                                ).then(
                                                    () => {
                                                        resolve()
                                                    },
                                                )
                                            },
                                        )
                                    },
                                )
                            }
                            resolve()
                        } else if (notifToken) {
                            postNotificationTokenToOzmo(userid, notifToken).then(
                                () => {
                                    resolve()
                                },
                            )
                        }
                        resolve()
                    },
                )
            } else if (notifToken) {
                postNotificationTokenToOzmo(userid, notifToken).then(
                    () => {
                        resolve()
                    },
                )
            } else {
                resolve()
            }
        },
    )
}
