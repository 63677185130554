
import { func, number } from "prop-types"
import Countdown, { zeroPad } from "react-countdown"

import "./timer.scss"

// duration : milliseconds
function Timer({ duration, expirationCallBack }) {

    return (
        <span className="custom-timer">
            <Countdown
                date={Date.now() + duration}
                zeroPadTime={true}
                onComplete={expirationCallBack}
                renderer={({ minutes, seconds }) => <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>}
            />
        </span>
    )
}

Timer.propTypes = {
    duration: number,
    expirationCallBack: func,
}

export default Timer
