import { _apiCall } from "../../../backend/axios"
import { buildQueryString, returnAnswerData, returnApiAnswer } from "../../../backend/helpers"

export function getAllKidsStageSubscriptions(params) {
    const query = buildQueryString(params)
    return _apiCall(
        "read/kids/stage/subscriptions/all" + query,
        "GET",
    ).then((response) => {
        return {
            count: response.data.data.hitCount ?? response.data.data.length ?? 0,
            items: response.data.data.items ?? response.data.data,
        }
    })
}

export function addStageMedicalCertificate(id, file, progressBarEl) {
    return _apiCall(
        `modify/kids/stage/${id}/add/certificate`,
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            attachment: file,
        },
        null,
        null,
        null,
        true,
        progressBarEl,
    ).then(x => x.data.data.subscription)
}

export function removeStageMedicalCertificate(subid, fileid) {
    return _apiCall(
        "modify/kids/stage/" + subid + "/attachments/delete/" + fileid,
        "POST", {
            "Content-Type": "multipart/form-data",
        },
    ).then(response => response.data.data)
}

export function generatePayzenStagePaymentReqs(data) {
    return _apiCall(
        "create/profil/payment/card/kids/training/payment/reqs",
        "POST", {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then(returnApiAnswer)
}

export function cancelStageRegistration(id) {
    return _apiCall(
        `modify/kids/stage/${id}/cancel`,
        "POST",
    ).then(returnApiAnswer)
}

export function getKidsStages({
    centerId,
    stageType,
}) {
    let uri = `read/kids/stages?centersId[0]=${centerId}`
    if (stageType) {
        uri += `&stageType=${stageType}`
    }
    //&selecter=centerId
    return _apiCall(
        uri,
        "GET",
    ).then(returnApiAnswer)
}

export function getKidsStagesFromCenters(centerIds, ageCategory) {
    const centerIdsParams = centerIds.map((id) => `centersId[]=${id}`).join("&")

    return _apiCall(
        `read/kids/stages/types?${centerIdsParams}&ageCategory=${ageCategory}`,
        "GET",
    ).then(returnAnswerData)
}

/**
     * stageSession,
     * childFirstname,
     * childLastname,
     * childBirthDate,
     * childGender,
     * childPosition
     * childOutfitSize
     * foodRestrictions
     * otherFoodRestrictions
     * contact1Firstname
     * contact1Lastname
     * contact1Email
     * contact1Phone
     * contact2Firstname
     * contact2Lastname
     * contact2Email
     * contact2Phone
     * cgvAccepted
     * informations
     */
export function editStageSubscription(data) {
    return _apiCall(
        "modify/kids/stage/edit",
        "POST", {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then(returnApiAnswer)
}

export function addStageProduct(id, productId, size, quantity) {
    return _apiCall(
        "modify/kids/stage/" + id + "/product/add",
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            ["product-" + productId]: size,
            ["quantity-product-" + productId]: quantity,
        },
    ).then(returnApiAnswer)
}

export function addStageDiscountCode(id, code) {
    return _apiCall(
        "modify/kids/stage/" + id + "/discountCode/add",
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            code: code,
        },
    ).then(returnApiAnswer)
}

export function removeStageDiscountCode(id, code) {
    return _apiCall(
        `modify/kids/stage/${id}/discountCode/delete`,
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            code: code,
        },
    ).then(returnApiAnswer)
}

export function addStageOption(id, optionId) {
    return _apiCall(
        "modify/kids/stage/" + id + "/option/add",
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            option: optionId,
        },
    ).then(returnApiAnswer)
}

export function getKidsStageDetail(id) {
    return _apiCall(
        "read/kids/stage/" + id,
        "GET",
    ).then(returnApiAnswer)
}

export function getKidsStagesTypes() {
    return _apiCall(
        "read/kids/stages/types",
        "GET",
    ).then((response) => {
        return response.data.data
    })
}

export function getKidsStagesCenterTypes({
    centerId,
}) {
    return _apiCall(
        `read/kids/centers/${centerId}/stages/types?includeTypesForArea=true`,
        "GET",
    ).then(returnApiAnswer)
}

export function getKidsStageSubscription(id) {
    return _apiCall(
        "read/kids/stage/subscription/" + id,
        "GET",
    ).then(returnApiAnswer)
}

export function deleteStageOption(id, optionId) {
    return _apiCall(
        "modify/kids/stage/" + id + "/option/delete",
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            option: optionId,
        },
    ).then(returnApiAnswer)
}

export function deleteStageProduct(id, productId, size) {
    return _apiCall(
        "modify/kids/stage/" + id + "/product/delete/" + productId + "/" + size,
        "POST", {
            "Content-Type": "multipart/form-data",
        },
    ).then(returnAnswerData)
}

export function preregisterStageSubscription(id) {
    return _apiCall(
        "modify/kids/stage/subscription/preregister",
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            subscriptionId: id,
        },
    ).then(returnApiAnswer)
}
