import { useSearchParams } from "react-router-dom"

function useQueryParametersObject() {
    const [ searchParams ] = useSearchParams()

    const search = searchParams.toString()

    let queryParameters = search.replace("?", "").split("&")
    let updatedQueryParameters = {}
    queryParameters.forEach(
        (el) => {
            var keyValue = el.split("=")
            var key = keyValue[0]
            var value = keyValue[1]
            if (key && value) {
                if (updatedQueryParameters[key]) {
                    const newValue = [ updatedQueryParameters[key] ]
                    updatedQueryParameters[key] = newValue.concat(value)
                } else {
                    updatedQueryParameters[key] = value
                }
            }
        },
    )

    return updatedQueryParameters
}

export default useQueryParametersObject
