import { _apiCall } from "../../../../backend/axios"

export function getLocationPaymentScreenAdvertising(theme) {
    let type
    switch (theme) {
    case "theme-soccer":
    default:
        type = 0
        break
    case "theme-padel":
        type = 1
        break
    }
    return _apiCall(
        `read/pub/items?type=${type}`,
        "GET",
    ).then(res => res.data.data[0])
}
