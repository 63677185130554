
export default function NoMatchOnCurrentDay() {
    return (
        <div className="c-col c-col--8 c-col--m--10 c-col--sm--12 flex-column align-center">
            <p className="h2 text-center">Aucun match disponible.</p>
            <p className="text-center noSupersub">
                Aucun match disponible pour le moment. Restes attentif, une équipe peut avoir besoin de toi très prochainement.
            </p>
        </div>
    )
}
