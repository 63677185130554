import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import AuthStep from "../../../components/authentification/authStep/authStep"
import AuthStepper from "../../../components/authentification/authStepper/authStepper"
import { sendConfirmEmail } from "../../../components/authentification/emailConfirmation/api"
import EmailConfirmation from "../../../components/authentification/emailConfirmation/emailConfirmation"
import SignupForm from "../../../components/authentification/signupForm/signupForm"
import SmsCodeVerif from "../../../components/authentification/smsCodeVerif/smsCodeVerif"
import Preloader from "../../../components/loaders/preloader/preloader"
import useAppRedirect from "../../../hooks/useAppRedirect"
import useThemeHeader from "../../../hooks/useThemeHeader"
import usePath from "../../../routes/services/usePath"
import { clearAllForms, setFormEntry } from "../../../store/forms/actions"

import "./signUpPage.scss"

function SignUpPage() {

    const [ clickOnNext, setClickOnNext ] = useState(false)

    const [ initComponent, setInitComponent ] = useState(false)

    const [ newEmail, setNewEmail ] = useState(null)
    const [ isSending, setIsSending ] = useState(false)

    const signUpPageData = useSelector(state => state.forms[SignUpPage.formName])

    const [ retryComplete, setRetryComplete ] = useState(false)
    const [ isSuccess, setIsSuccess ] = useState(false)
    const [ successOrErrorMsg, setSuccessOrErrorMsg ] = useState(false)

    /* ACTIONS */
    const appRedirect = useAppRedirect()
    const dispatch = useDispatch()
    const path = usePath()
    const themeHeader = useThemeHeader()
    const [ , updateState ] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }
    
    useEffect(
        () => {
            if (!initComponent) {
                setInitComponent(true)
                if (signUpPageData?.email) {
                    if (!clickOnNext) {
                        setClickOnNext(true)
                       
                    }
                }
            }
        }, [ signUpPageData ],
    )

    function stepperTitle() {
        return "Création de compte"
    }

    return (
        <React.Fragment>
            <AuthStepper
                title={stepperTitle()}
                prevAction={() => {
                    dispatch(clearAllForms())
                    appRedirect(path("/login"))
                }}
                rootClasses="women-bkg"
                contentCls="signUpPageStepper"
                help={<p>Tu rencontres un problème pour te connecter ou créer un compte ? Contacte-nous au 0 806 23 00 24</p>}
                hideGoBackStepIdArray={[ 3 ]}
            >
                <AuthStep>
                    <SignupForm />
                </AuthStep>

                <AuthStep>
                    <SmsCodeVerif/>
                </AuthStep>

                <AuthStep>
                    { isSending &&
                        <Preloader fixed={true} />
                    }
                    <EmailConfirmation 
                        introText = "Félicitations ton téléphone a bien été validé"
                        introTextIsBold={true}
                        retryValue={signUpPageData ? signUpPageData.email : ""}
                        retryAction={() => {
                            setIsSending(true)

                            const emailAddr = newEmail ? newEmail : signUpPageData.email
                         
                            sendConfirmEmail(signUpPageData.id, emailAddr, localStorage.getItem("changeCoordinatesToken"), themeHeader).then((resEmail) => {
                                setIsSending(false)
                                if (resEmail?.data?.data?.Message) {
                                    setRetryComplete(true)
                                    setIsSuccess(false)
                                    setSuccessOrErrorMsg(resEmail.data.data.Message)
                                } else {
                                    if (resEmail?.data?.data?.id) {
                                        setRetryComplete(true)
                                        setFormInStore(
                                            SignUpPage.formName, {
                                                ...signUpPageData,
                                                email: emailAddr,
                                                id: resEmail.data.data.id,
                                            },
                                        )
                                        setIsSuccess(true)
                                        setSuccessOrErrorMsg("Un nouvel email a été envoyé")
                                    }
                                }
                                   
                            })

                        }}
                        onChangeAction={(val) => {
                            setNewEmail(val)
                        }}
                        retryComplete={retryComplete}
                        isSuccess={isSuccess}
                        successOrErrorMsg={successOrErrorMsg}
                    />
                </AuthStep>
                  
            </AuthStepper>
        </React.Fragment>
    )

}

SignUpPage.formName = "signUpPage"
SignUpPage.propTypes = {
    prevAction: PropTypes.func,
}

export default SignUpPage
