import { _apiCall } from "../../../backend/axios"

export function cancelSuperSubResponse(id) {
    return _apiCall(
        `/modify/supersubs/responses/${id}/cancel`,
        "PUT",
    )
}

export function rateOrganizer(id, rating, ratingComment) {
    return _apiCall(
        `modify/supersubs/responses/${id}/rateOrganizer`,
        "PUT",
        {
            "Content-Type": "application/json",
        },
        {
            rating: rating,
            ratingComment: ratingComment,
        },
    )
}
