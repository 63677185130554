import PropTypes from "prop-types"

import ButtonCta from "../../../components/Buttons/button/button-cta.jsx"
import CustomProgressBar from "../../../components/CustomProgressBar/CustomProgressBar.jsx"
import ListingRegistrationHolder from "../../../components/listing/ListingRegistrationHolder.jsx"
import MessagePopin from "../../../components/popin/MessagePopin/MessagePopin.jsx"
import ProgressItem from "../../../components/ProgressItem/ProgressItem.jsx"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import { validateLeague } from "../service/service.js"

import "./BusinessLeagueListingElement.scss"

export function BusinessLeagueListingElement({ leagueRegistration }) {
    const items = validateLeague(leagueRegistration)

    const registrationDetails = (
        <div>
            <div className="info-list__details__item">
                <span>Centre :</span>
                <span>{leagueRegistration.centerName}</span>
            </div>
            <div className="info-list__details__item">
                <span>Début :</span>
                <span>{leagueRegistration?.league?.start}</span>
            </div>
            <div className="info-list__details__item">
                <span>Équipe :</span>
                <span>{leagueRegistration.teamName}</span>
            </div>
            <div className="info-list__details__item">
                <span>Capitaine :</span>
                <span>{leagueRegistration.captainName}</span>
            </div>
        </div>
    )

    const openDetailsModal = () => {
        ModalHandler.show(MessagePopin, {
            cls: "xl completionPopIn",
            message: (
                <>
                    <div className="completionPopIn__content">
                        {items?.length > 0 && items.map(item => (
                            <ProgressItem
                                simple
                                label={item.label}
                                disabled={item.disabled}
                                key={item.key || item.label}
                            />
                        ))}
                    </div>
                    <CustomProgressBar items={items} tooltip={false}/>
                </>
            ),
            title: "Mes étapes d’inscription",
        })
    }

    return (
        <ListingRegistrationHolder
            logo={leagueRegistration.league.pictureUrl}
            name={leagueRegistration.league.name}
            status={items?.every(x => !x.disabled) ? "Confirmée" : "En cours"}
            details={registrationDetails}
            actions={(
                <ButtonCta text={"suivre mes étapes d’inscription"} onClick={openDetailsModal} />
            )}
        />
    )
}

BusinessLeagueListingElement.propTypes = {
    leagueRegistration: PropTypes.object,
}

