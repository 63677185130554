import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock-upgrade"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"

import { useTheme } from "../../ThemeContext/ThemeContext"
import { menuFunctions } from "../functions.js"
import ProfileMenuLvl2 from "../menuItem/profileMenuLvl2/profileMenuLvl2"

import "./overlayProfileMenu.scss"

function OverlayProfileMenu() {
    const theme = useTheme()
    const hasArdoises = useSelector(state => state.hasArdoises)
    const nextBooking = useSelector(state => state.nextBooking)
    const hasUnpaidBills = useSelector(state => state.hasUnpaidBills)
    const user = useSelector(state => state.userLoaded)

    const data = {
        hasArdoises,
        hasUnpaidBills,
        nextBooking,
        userLoaded: user,
    }

    const { menuslevel2 } = menuFunctions[theme](data)

    const userOpen = useSelector(state => state.userSelect.open)
    const menuRef = useRef()

    useEffect(() => {
        if (menuRef.current) {
            if (userOpen) {
                disableBodyScroll(menuRef.current)
            } else {
                clearAllBodyScrollLocks()
            }
        }

        return clearAllBodyScrollLocks()
    }, [ userOpen ])

    return (
        <AnimatePresence>
            {menuslevel2 && userOpen && (
                <motion.div className="profileMenuOverlay" {...overlayAnimationsSettings} ref={menuRef}>
                    <ProfileMenuLvl2
                        parentId="profile-lvl2-overlay"
                        level1={"profil"}
                        childrenList={menuslevel2.profil}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    )
}

const overlayAnimationsSettings = {
    animate: { opacity: 1, y: "0px" },
    exit: { opacity: 0, y: "calc(100dvh - var(--footer-height))" },
    initial: { opacity: 0, y: "calc(100dvh - var(--footer-height))" },
    transition: { duration: 0.25, ease: [ 0.25,0.1,0.25, 1 ] },
}

export default OverlayProfileMenu
