import { useSearchParams } from "react-router-dom"

const useAddSearchParam = () => {
    const [ , setSearchParams ] = useSearchParams()

    return (field, value) => {
        setSearchParams(searchParams => {
            searchParams.set(field, value)
            return searchParams
        })
    }
}

export default useAddSearchParam
