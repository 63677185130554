import { _apiCall } from "../../backend/axios"
import { loadUser } from "../../store/actions/scaffold"
import store from "../../store/store"

export function  modifyUserPicture(file) {
    return _apiCall(
        "modify/profil-picture",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        file,
    )
}

export function editUser(user) {
    return _apiCall(
        "modify/user",
        "POST",
        {
            "Content-Type": "application/json",
        },
        user,
    )
}

export function modifyPassword(oldPassword, password, theme) {
    return _apiCall(
        "replace/change-password",
        "PUT",
        {
            "Content-Type" : "application/json",
            activity: theme,
        },
        {
            oldPassword: oldPassword,
            password: password,
        },
    )
}

export function deleteAccount() {
    return _apiCall(
        "delete/user",
        "DELETE",
    )
}

export function modifyFavoriteUniverse(user, universe) {
    // in OMZO
    // favoriteUniverse = 0 -> soccer
    // favoriteUniverse = 1 -> padel

    let favoriteUniverse
    switch (universe) {
    case "theme-soccer":
    case "theme-business":
        favoriteUniverse = 0
        break
    case "theme-padel":
        favoriteUniverse = 1
        break
    default:
        favoriteUniverse = universe
    }

    return _apiCall(
        "modify/user",
        "POST",
        {
            "Content-Type": "application/json",
        },
        {
            ...user,
            favoriteUniverse,
        },
    ).then(
        (response) => {
            if (response?.data?.data.id) {
                store.dispatch(
                    loadUser(response.data),
                )
            }

            switch (response.data.data.favoriteUniverse) {
            case 0:
            default:
                return "theme-soccer"
            case 1:
                return "theme-padel"
            }
        },
    ).catch((err) => {
        return err
    })
}

export function resetFavoriteUniverse(user) {
    return _apiCall(
        "modify/user",
        "POST",
        {
            "Content-Type": "application/json",
        },
        {
            ...user,
            favoriteUniverse: null,
        },
    ).then(res => {
        return res
    }).catch(err => {
        return err
    })
}

export async function getFavoriteSlots() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                {
                    center: "Clermont-Ferrand",
                    end: "2021-05-10T14:00:00",
                    field: "Filmé Indoor",
                    id: 1,
                    notification: {
                        app: false,
                        email: false,
                        sms: true,
                    },
                    start: "2021-05-10T13:00:00",
                },
                {
                    center: "Puteaux",
                    end: "2021-05-10T15:00:00",
                    field: null,
                    id: 2,
                    notification: {
                        app: true,
                        email: true,
                        sms: true,
                    },
                    start: "2021-05-12T14:00:00",
                },
                // {
                //     id: 3,
                //     start: "2021-05-14T15:00:00",
                //     end: "2021-05-10T16:00:00",
                //     center: "Clermont-Ferrand",
                //     field: "Filmé Indoor",
                //     notification: {
                //         sms: false,
                //         email: false,
                //         app: false,
                //     },
                // },
            ])
        }, 1500)
    })
}
