import MessagePopin from "../../../components/popin/MessagePopin/MessagePopin.jsx"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import { editSchoolSubscription } from "../../kids/ecoleDeFoot/api"
import { editStageSubscription } from "../../kids/stageDeFoot/api"

export default async function schoolTrainingRegistration(userLoaded, selectedKid, dailySlot, tunnelType, setFormInStore, removeFormInStore, setIsLoading, nextStep, creneauxData, schoolSubscriptions, isAdult) {
    return new Promise(
        (resolve) => {
            const ozmoCall = () => {
                setIsLoading(true)

                let data = {
                    child: selectedKid?.id,
                    childBirthDate: selectedKid?.birthDate.split("-").reverse().join("/"),
                    childFirstname: selectedKid?.firstName,
                    childGender: selectedKid?.gender,
                    childLastname: selectedKid?.lastName,
                    childOutfitSize: selectedKid?.equipment,
                    contact1Email: userLoaded.emailAddress,
                    contact1Firstname: userLoaded.firstname,
                    contact1Lastname: userLoaded.lastname,
                    contact1Phone: userLoaded.phoneNumber ? userLoaded.phoneNumber.replace("+33", "0") : "",
                    isAdult,
                }
                switch (tunnelType) {
                case "SCHOOL":
                    data.dailySlot = dailySlot.value

                    editSchoolSubscription(data).then(
                        (response) => {
                            const id = response.data.id
                            setFormInStore("choixCreneau", {
                                schoolSubscriptionId: id,
                            })

                            setFormInStore("ozmoSubscription", {
                                firstSubscription: response.data,
                            })

                            setFormInStore("choixCreneau", {
                                selectedTimeslot: dailySlot,
                                selectedTimeslotValue: dailySlot?.value,
                            })
                            const convertToObject = array => {
                                const resultObject = {}
                                for (const item of array) {
                                    resultObject[item.value] = item
                                }
                                return resultObject
                            }
                            setFormInStore("creneauxData", {
                                actualKidSubscriptionsIds: schoolSubscriptions?.map(school => school.value),
                                timeslots: convertToObject(creneauxData),
                            })
                            setFormInStore("choixEnfant", {
                                selectedKid,
                            })
                            removeFormInStore("avoirFormData")

                            nextStep()
                            resolve()
                        },
                    ).catch((e) => {
                        if (e?.response?.data?.data?.Message) {
                            ModalHandler.show(MessagePopin, {
                                message: e?.response?.data?.data?.Message,
                                title: "Erreur",
                            })
                        }
                    }).finally(() => setIsLoading(false))
                    break
                case "TRAINING": {
                    data.stageSession = dailySlot.id,
                    data.childPosition = selectedKid?.position

                    const optionProductsOfSubs = dailySlot.optionProducts
                    const equipmentsOfSubs = dailySlot.catalogProducts
                    setFormInStore(
                        "hasOption",
                        optionProductsOfSubs?.length !== 0,
                    )
                    setFormInStore("choixEnfant", {
                        selectedKid,
                    })
                    setFormInStore(
                        "optionsProducts",
                        optionProductsOfSubs,
                    )
                    setFormInStore(
                        "hasCatalogProducts",
                        equipmentsOfSubs?.length !== 0,
                    )
                    setFormInStore(
                        "equipmentData",
                        {
                            availableEquipment: equipmentsOfSubs,
                        },
                    )

                    editStageSubscription(data).then(
                        (response) => {
                            const id = response.data.data.id
                            setFormInStore(
                                "choixCreneau", {
                                    schoolSubscriptionId: id,
                                    selectedTimeslot: dailySlot,
                                    selectedTimeslotValue: dailySlot?.id,
                                },
                            )

                            const subs = response.data.data

                            setFormInStore(
                                "ozmoSubscription",
                                {
                                    firstSubscription: subs,
                                },
                            )

                            removeFormInStore("avoirFormData")

                            setIsLoading(false)
                            nextStep()
                            resolve()
                        },
                    )
                    break
                }

                }
            }
            ozmoCall()
        },
    )
}
