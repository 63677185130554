/**
 * Permet d'obtenir les classes d'une step en fonction de sa position
 *
 * Retourne first si c'est la première step, last si c'est la dernière, rien sinon
 * @param {*} index L'index de l'étape que l'on veut tester
 * @param {*} stepCount Le nombre totale d'étapes
 */
function getStepPositionClass(index, stepCount) {
    return (
        index === 0 ?
            "first"
            : (index === stepCount - 1) ?
                "last"
                :
                ""
    )
}

export default getStepPositionClass
