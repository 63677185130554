import { func, object } from "prop-types"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import useMediaPath from "../../../../hooks/useMediaPath"

export default function UsCards({ elem, setUsCard }) {
    const mediaPath = useMediaPath()
    return (
        <div className="usInfo">
            <div className="side-left">
                <img src={mediaPath([ "/assets/images/Carte-US-O.png", "/assets/images/padel/CarteUP.png" ])}  />
                <div className="details">
                    <div>{elem.code}</div>
                    <div>
                        Expire le : <span className="weight-bold">{elem.endDate}</span>
                    </div>
                    {elem.status === 2 ?
                        (<div>
                            Solde restant : <span className="weight-bold orange">{elem?.leftAmount?.toFixed(2).toString().replace(".00", "")}€</span>
                        </div>)
                        :
                        (<div className="weight-bold">
                            EXPIRÉE
                        </div>)
                    }
                </div>
            </div>
            <div className="side-right">
                <ButtonCta
                    isAlt={true}
                    disabled={elem.status === 3}
                    onClick={() => {
                        setUsCard(elem)
                    }}
                    text="Détails"
                />
            </div>
        </div>
    )
}

UsCards.propTypes = {
    elem: object,
    setOpenUsCardPopin: func,
    setUsCard: func,
}
