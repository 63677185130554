import classNames from "classnames"
import { number } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useStore } from "react-redux"
import { useNavigate } from "react-router-dom"

import PersonnaliserAnniv from "./PersonnaliserAnniv"
import AlmaBadge from "../../../../components/AlmaBadge/AlmaBadge.jsx"
import ButtonCta from "../../../../components/Buttons/button/button-cta"
import CurrencyDisplay from "../../../../components/currencies/CurrencyDisplay"
import CheckBlock from "../../../../components/Forms/CheckBlock/CheckBlock"
import FormValidator from "../../../../components/Forms/FormValidator"
import InputDate from "../../../../components/Forms/InputDate/InputDate"
import Preloader from "../../../../components/loaders/preloader/preloader"
import Modal from "../../../../components/Modal/Modal"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import Step from "../../../../components/stepsManagement/Step"
import HourDisplay from "../../../../components/Time/HourDisplay"
import CommonHandler from "../../../../entries/main/business/CommonHandler"
import useIsMobile from "../../../../hooks/useIsMobile"
import dayValues from "../../../../model/enums/dayValues"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import usePath from "../../../../routes/services/usePath"
import { removeFormEntry, setFormEntry } from "../../../../store/forms/actions"
import PreForm from "../../components/preForm/PreForm"
import ChooseCenterAndType from "../../steps/ChooseCenterAndType"
import MesEnfants from "../../steps/MesEnfants"
import { addBirthday, editBirthday, getBirthdayRegistrations, getBirthdaySlotDetails, getBirthdaySlotsForConfig } from "../api"
import SlotDemandRequestForm from "../components/SlotDemandRequestForm/SlotDemandRequestForm"

/**
 * Etape du formulaire BirthdayRegistration permettant de choisir un créneaux
 */
function CreneauDispo(props) {
    const path = usePath()

    /* VARIABLES */
    const navigate = useNavigate()
    const { daywp, monthwp, yearwp } = props
    const dispatch = useDispatch()
    const [ timeSlotsSameDate, setTimeSlotsSameDate ] = useState([])
    const [ timeSlotsOtherDate, setTimeSlotsOtherDate ] = useState([])
    //let isMobile;

    /* STATE */
    const [ date, setDate ] = useState(null)
    const [ displayForm, setDisplayForm ] = useState(false)
    const [ dataLoading, setDataLoading ] = useState(false)
    const isMobile = useIsMobile()
    // const [selectedTimeslot, setSelectedTimeslot] = useState({});
    const [ , updateState ] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    /* REFS */
    const step = useRef(null)
    const slotRequestForm = useRef(null)

    /* STORE */
    // const currentStep = useSelector(state =>  state.stepManagement.currentStep);
    const store = useStore()
    let formConfigPick = store.getState().forms[ChooseCenterAndType.formName]
    formConfigPick = formConfigPick ? formConfigPick : {}
    let formEnfant = store.getState().forms[MesEnfants.formName]
    formEnfant = formEnfant ? formEnfant : {}

    store.subscribe(() => {
        formConfigPick = store.getState().forms[ChooseCenterAndType.formName] ?
            store.getState().forms[ChooseCenterAndType.formName] : {}
    })

    /* ACTIONS */
    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }

    /* FORMULAIRES */
    const formCreneauDispo = store.getState().forms[CreneauDispo.formName] !== undefined ? 
        store.getState().forms[CreneauDispo.formName] 
        : {
            selectedTimeSlot: {},
            slots: undefined,
        }
    const formCreneauDispoData = store.getState().forms[CreneauDispo.formDataName] !== undefined ? 
        store.getState().forms[CreneauDispo.formDataName] 
        : {}
    const formPersonnaliserAnnivData = store.getState().forms[PersonnaliserAnniv.formDataName] !== undefined ? 
        store.getState().forms[PersonnaliserAnniv.formDataName] 
        : {
            selectedCake : {},
            selectedDrink : {},
            selectedEnfantSupp : {},
            selectedGift : {},
            selectedGiftSize : null,
            selectedOptions : [],
        }   

    if (!date && formConfigPick.configId) {

        if (formCreneauDispo.birthdaySubscription && formCreneauDispo.birthdaySubscription.id) {
            //setDate(new Date(formCreneauDispo.birthdaySubscription.start))
            if (formCreneauDispo.birthdaySubscription.requestedDate) {
                const dateFromSubs = new Date(formCreneauDispo.birthdaySubscription.requestedDate)
                dateFromSubs.setHours(0,0,0)
                setDate(dateFromSubs)
            }
        } 
    }

    if (step.current) {
        if (formCreneauDispo.selectedTimeSlot && formCreneauDispo.selectedTimeSlot.id 
            && formEnfant && formEnfant.selectedKid) {
            step.current.validate()
        } else {
            if (formCreneauDispoData && formCreneauDispoData.data &&
                 formCreneauDispo.birthdaySubscription) { // already request birthday
                step.current.validate()
            } else {
                step.current.unvalidate()
            }
        }
    }

    useEffect(
        () => {
            if (formConfigPick.configId) {
                if (!dataLoading && date instanceof Date && !isNaN(date)) {
                    date.setHours(0,0,0)

                    let periodEnd = new Date(date.getTime())
                    //max 30 days
                    if (date.getDate() > 1) {
                        periodEnd.setMonth(periodEnd.getMonth() + 1)
                        periodEnd.setDate(periodEnd.getDate() - 1)
                    } else {
                        periodEnd.setDate(date.getDate() + 29)
                    }
    
                    setDataLoading(true)
    
                    let existingBirthdayRegistrations
                    getBirthdayRegistrations({
                        offset: 0,
                        orderByAsc: false,
                        periodEnd: periodEnd,
                        periodStart: date,
                        status: "[2,1,3]",
                    }).then(
                        (res) => {
                            existingBirthdayRegistrations = res?.items ?? []
                            const currentBirthdaySubsId = formCreneauDispo.birthdaySubscription ?
                                formCreneauDispo.birthdaySubscription.id
                                :
                                null

                            getBirthdaySlotsForConfig(date, periodEnd, formConfigPick.configId, true, currentBirthdaySubsId).then(
                                (res) => {
                                    const items = res?.data?.data?.items
                                    const obsSlots = []

                                    if (items) {

                                        if (items.length === 0) {
                                            setFormInStore(
                                                CreneauDispo.formName, {
                                                    ...formCreneauDispo,
                                                    selectedTimeSlot: {},
                                                    slots: [],
                                                },
                                            )
                                            setDataLoading(false)
                                        }

                                        items.forEach(
                                            (item) => {
                                                obsSlots.push(getBirthdaySlotDetails(item.id))
                                            },
                                        )

                                        Promise.all(obsSlots).then(
                                            (res) => {
                                                let slotsRes = []
                    
                                                res.forEach(
                                                    (r) => {
                                                        if (
                                                            r && r.data.data && r.data.data.birthdayConfigs
                                                            && r.data.data.birthdayConfigs[0]
                                                            && getConfigIdAssociatedToBirthdayConfig(r.data.data)
                                                        ) {
                                                            slotsRes.push(r.data.data)
                                                        }
                                                    },
                                                )
                                                
                                                let slot = {}
                                                if (formCreneauDispo.birthdaySubscription) {
                                                    slot = slotsRes.find(
                                                        (s) => s.start === formCreneauDispo.birthdaySubscription.start,
                                                    )
                                                    if (slot === undefined) {slot = {}}
                                                }
                                                setFormInStore(
                                                    CreneauDispo.formName, {
                                                        ...formCreneauDispo,
                                                        existingBirthdayRegistrations: existingBirthdayRegistrations,
                                                        selectedTimeSlot: slot,
                                                        slots: slotsRes,
                                                    },
                                                )
                    
                                                setDataLoading(false)

                                            },
                                        )
                                    }
                                },
                            )
                        },
                    )

                }
            }

        }, [ date, formConfigPick.configId ],
    )

    useEffect(
        () => {
            buildTimeSlots()
        }, [ formCreneauDispo, date ],
    )

    /* DOM HELPERS */
    const help = <PhoneCallHelp tunnelType="BIRTHDAY"/>

    function buildTooltipMobile(domArray, objectArray, slot, idx) {
        const isLastOrOdd = idx === objectArray.length - 1 || (!isEven(idx) && idx !== 0)

        if (isLastOrOdd) {
            if (idx >= 1 && !isEven(idx)) {
                const previousEl = objectArray[idx - 1]

                domArray.push(
                    <div className="c-col c-col--3 c-col--sm--12 tooltip-description left"
                        id={"description-card-" + previousEl.id} key={"description-card-" + previousEl.id}>
                        {renderDescriptionCard(previousEl)}
                    </div>,
                )
            }

            const classToApply = `c-col c-col--3 c-col--sm--12 tooltip-description${(idx === 0 || isEven(idx)) ? " left" : ""}`
            domArray.push(
                <div className={classToApply}
                    id={"description-card-" + slot.id} key={"description-card-" + slot.id}>
                    {renderDescriptionCard(slot)}
                </div>,
            )
        }
    }

    function checkIfExistingSubscription(slot) {
        let isEqual = false

        const birthdayConfigOfSlot = getConfigIdAssociatedToBirthdayConfig(slot)
        if (formCreneauDispo.existingBirthdayRegistrations) {
            formCreneauDispo.existingBirthdayRegistrations.forEach((subs) => {
                const slotDate = new Date(slot.start)
                const subsDate = new Date(subs.start)
                if (subs.statusValue === 2 || subs.statusValue === 3) {
                    if (slot.birthdayConfigs && birthdayConfigOfSlot &&
                        subs.birthdayConfig.id === birthdayConfigOfSlot.id && slotDate.getTime() === subsDate.getTime()) {
                        isEqual = true
                    }
                }
            })
        }
        return isEqual
    }

    function buildTimeSlots() {

        let nbOtherSlot = 0

        let newTimeSlotsSameDate = []
        let newTimeSlotsOtherDate = []

        let timeSlotSameDateObj = []
        let timeSlotOtherDateObj = []
        
        if (formCreneauDispo && formCreneauDispo.slots && date) {

            if (formCreneauDispo.slots.length > 0) {
                formCreneauDispo.slots.forEach((slot) => {
                    const slotDate = new Date(slot.start)
    
                    if (slotDate.getDate() === date.getDate() && 
                    slotDate.getMonth() === date.getMonth() && 
                    slotDate.getFullYear() === date.getFullYear()) {
                        timeSlotSameDateObj.push(slot)
                        if (!isMobile) {
                            newTimeSlotsSameDate.push(buildCheckBlockSlot(slot))
                        }
                    } else {
                        if (nbOtherSlot < 6) {
                            timeSlotOtherDateObj.push(slot)
                            if (!isMobile) {
                                newTimeSlotsOtherDate.push(buildCheckBlockSlot(slot))
                            }
                            nbOtherSlot++
                        }
                    }
                })
                
                if (isMobile) {
                    timeSlotSameDateObj.forEach(
                        (slot, idx) => {
                            newTimeSlotsSameDate.push(buildCheckBlockSlot(slot))
                            buildTooltipMobile(newTimeSlotsSameDate, timeSlotSameDateObj, slot, idx)
                        },
                    )
    
                    timeSlotOtherDateObj.forEach(
                        (slot, idx) => {
                            newTimeSlotsOtherDate.push(buildCheckBlockSlot(slot))
                            buildTooltipMobile(newTimeSlotsOtherDate, timeSlotOtherDateObj, slot, idx)
                        },
                    )
    
                }
            } 
            
            setTimeSlotsSameDate(newTimeSlotsSameDate)
            setTimeSlotsOtherDate(newTimeSlotsOtherDate)
        }
    }

    function getConfigIdAssociatedToBirthdayConfig(slot) {
        if (slot.birthdayConfigs) {
            return slot.birthdayConfigs.find(
                (b) => b.id.toString() === formConfigPick.configId + "",
            )
        }
    }

    function buildCheckBlockSlot(slot) {
        const slotDate = new Date(slot.start)
        const slotEndDate = new Date(slot.end)

        const birthdayConfigOfSlot = getConfigIdAssociatedToBirthdayConfig(slot)

        return (
            <div className="slot-bithday-div" key={slot.id}>
                <CheckBlock
                    callback={onTimeslotClick}
                    checked={parseInt(slot.id) === parseInt(formCreneauDispo.selectedTimeSlot.id)}
                    name={"timeslots-" + slot.id}
                    value={slot.id}
                    disabled={checkIfExistingSubscription(slot)}
                    key={slot.id}
                >

                    <div className="radioList__item__subTitle">
                        {dayValues[slotDate.getDay()]} {slotDate.toLocaleDateString()} 
                    </div>
                    <div className="radioList__item__price">
                        <HourDisplay date={slotDate} /><span>&ensp;-&ensp;</span>
                        { (birthdayConfigOfSlot && slotDate) &&
                            <HourDisplay date={slotEndDate} />
                        }
                        <br/>
                        <span>{slot?.resourceDisplay}</span>
                        <br />
                        <strong>
                            { birthdayConfigOfSlot && birthdayConfigOfSlot.amount && (
                                <CurrencyDisplay
                                    price={birthdayConfigOfSlot.amount}
                                />)
                            }
                        </strong>
                    </div>

                </CheckBlock>
            </div>
        )
    }

    function isEven(idx) {
        return idx % 2 === 0
    }

    function closeAllTooltipDescription() {
        const tooltips = document.querySelectorAll(".tooltip-description")
        tooltips.forEach(t => {
            t.classList.remove("active")
        })
    }

    function onTimeslotClick(event) {
        const newSelectedTimeslotId = event.currentTarget.id

        if (displayForm) {
            setFormInStore(CreneauDispo.formDataName, {
                ...formCreneauDispoData,
                data: null,
            })
            if (slotRequestForm.current !== undefined) {
                slotRequestForm.current.clear()
            }

            setDisplayForm(!displayForm)
        }

        let slot = formCreneauDispo.slots.find((s) => s.id === parseInt(newSelectedTimeslotId))
        const oldSelectedTimeslot = formCreneauDispo.selectedTimeSlot

        if (isMobile) {
            closeAllTooltipDescription()
        
            const timeslot = document.querySelector("#description-card-" + slot.id)
            if (oldSelectedTimeslot === slot) {
                timeslot.classList.remove("active")
            } else {
                timeslot.classList.add("active")
            }
        }

        if (oldSelectedTimeslot === slot) {
            slot = {}
        }

        if (slot) {
            // si il y a deja une inscription avec demande de creneau
            if (
                formCreneauDispo.birthdaySubscription &&
                formCreneauDispo.birthdaySubscription.status === "A pourvoir"
            ) {
                setFormInStore(
                    CreneauDispo.formName, {
                        ...formCreneauDispo,
                        birthdaySubscription: null,
                        selectedTimeSlot: slot,
                    },
                )
            } else {
                setFormInStore(
                    CreneauDispo.formName, {
                        ...formCreneauDispo,
                        selectedTimeSlot: slot,
                    },
                )
            }
        }
    }

    /* handle scroll auto to form */
    function scrollToForm() {
        let scrollElement = document.getElementById("slotRequestForm")
        const rectChildForm = scrollElement.getBoundingClientRect()

        // mobile
        if (window.innerWidth <= 640) {
            const y = (rectChildForm.top - 60) + window.scrollY
            const _document = document.scrollingElement || document.documentElement
            CommonHandler.scrollTo(_document, y, 400)
        } else {
            let baseElement = document.getElementById("step-article-3")
            const y = scrollElement.offsetTop - 15
            CommonHandler.scrollTo(baseElement, y, 400)
        }

    }

    function renderDescriptionCard(timeSlotParam) {
        let timeSlotToRender = timeSlotParam ? timeSlotParam : formCreneauDispo.selectedTimeSlot
        const birthdayConfigOfSlot = getConfigIdAssociatedToBirthdayConfig(timeSlotToRender)

        if (timeSlotToRender?.id !== formCreneauDispo.selectedTimeSlot?.id) {
            return null
        }

        return (
            <div>
                <div className="cardInfo_arrow"></div>
                <div className="cardInfo">
                    {timeSlotToRender && timeSlotToRender.birthdayConfigs && birthdayConfigOfSlot ?
                        (<div className="cardInfo__content creneaux-dispo-div">
                            <div className="h1 text-uppercase">Informations</div>
                            <div className="avatarRow">
                                <img className="avatarRow__img" src={birthdayConfigOfSlot.type.pictureUrl} />
                                <div className="avatarRow__label">
                                    {birthdayConfigOfSlot.type.name }
                                    {timeSlotToRender.centerName &&
                                            (<React.Fragment>
                                                <br/>
                                                { timeSlotToRender.centerName }
                                            </React.Fragment>)}

                                </div>
                            </div>

                            <div className="cardInfo__text">
                                <div className="cardInfo__description" dangerouslySetInnerHTML={{ __html: birthdayConfigOfSlot.description }}></div>
                            </div>

                            <div className="cardInfo__price h1">Tarif : <CurrencyDisplay price={birthdayConfigOfSlot.amount} /></div>

                            {birthdayConfigOfSlot?.allowPaymentSpread && (
                                <AlmaBadge amount={birthdayConfigOfSlot?.amount} customId={"alma-widget-" + timeSlotToRender.id} />
                            )}

                            <ButtonCta
                                onClick = {() => {
                                    openDescriptionModal(birthdayConfigOfSlot.detailedDescription)
                                }}
                                text = "Plus d’informations"
                            />
                        </div>)
                        :
                        <div className="text-center cardInfo__empty">Sélectionnez le jour et le créneau auxquels vous souhaitez fêter l'anniversaire de <span className="child-name">{formEnfant && formEnfant.selectedKid ? formEnfant.selectedKid.firstName : ""}</span></div>
                    }
                </div>
            </div>
        )

    }

    function openDescriptionModal(description) {
        ModalHandler.show(ModalHandler.create(({ visible, hide, description }) => {
            return  (
                <Modal
                    isOpen={visible}
                    onClose={hide}
                    className="birthdayDescripton"
                    withPortal
                >
                    <div className="description-popup" dangerouslySetInnerHTML={{ __html: description }}>
                    </div>
                </Modal>
            )
        }), {
            description: description,
        })
    }

    function postBirthday() {

        const birthdayConfigOfSlot = getConfigIdAssociatedToBirthdayConfig(formCreneauDispo.selectedTimeSlot)

        setDataLoading(true)
        return new Promise (
            (resolve) => {
                let formattedDate = formCreneauDispo.selectedTimeSlot.start.replace(/T/g, " ")
        
                if (formCreneauDispo.selectedTimeSlot && formEnfant && formEnfant.selectedKid) {
        
                    if (displayForm) { // is requesting form
                        resolve()
                    } else {
                        if (formCreneauDispo.birthdaySubscription && formCreneauDispo.birthdaySubscription.id &&
                            formCreneauDispo.birthdaySubscription.birthdayConfig.id === formConfigPick.configId) {

                            const slotId = formCreneauDispo.selectedTimeSlot.id ? 
                                formCreneauDispo.selectedTimeSlot.id : null

                            editBirthday(formCreneauDispo.birthdaySubscription.id,
                                formEnfant.selectedKid.id, formCreneauDispo.birthdaySubscription.birthdayConfig.id,
                                formattedDate, formPersonnaliserAnnivData.selectedGift.id, formPersonnaliserAnnivData.selectedGiftSize,
                                formPersonnaliserAnnivData.selectedDrink.id, formPersonnaliserAnnivData.selectedCake.id,
                                formCreneauDispo.birthdaySubscription.comments,
                                slotId,
                            ).then((res) => {
                                setFormInStore(CreneauDispo.formName, {
                                    ...formCreneauDispo,
                                    birthdaySubscription: res.data.data,
                                })
                                dispatch(removeFormEntry("avoirFormData"))
                                setDataLoading(false)
                                resolve()
                            })

                        } else {
                            let data = {
                                birthdayConfigId: formCreneauDispo.selectedTimeSlot.birthdayConfigs &&
                                birthdayConfigOfSlot ? 
                                    birthdayConfigOfSlot.id : "",
                                date: formattedDate,
                                kidId: formEnfant.selectedKid.id,
                                slotId: formCreneauDispo.selectedTimeSlot.id,
                            }
            
                            addBirthday(data).then(
                                (res) => {
                                    const birthdayRegistration = res.data.data
                                    setFormInStore(CreneauDispo.formName, {
                                        ...formCreneauDispo,
                                        birthdaySubscription: birthdayRegistration,
                                    })
                                    setDataLoading(false)
                                    dispatch(removeFormEntry("avoirFormData"))
                                    resolve()
                                },
                            )
                        }
                    }
                } 
            })
    }

    function requestSlot(params) {
        if (params) {
            setDataLoading(true)
            let horaireSplits = params.horaire.split("H")
            const hour = +horaireSplits[0] <= 9 && +horaireSplits[0] > 0 ? ("0" + horaireSplits[0]) : horaireSplits[0]
            const min = +horaireSplits[1] <= 9  && +horaireSplits[1] > 0 ? ("0" + horaireSplits[1]) : horaireSplits[1]

            const realMonth = params.date.getMonth() + 1
            const month = realMonth <= 9 ? ("0" + realMonth) : realMonth
            const date = params.date.getDate() <= 9 ? ("0" + params.date.getDate()) : (params.date.getDate())

            let formattedDate = params.date.getFullYear() + "-" + month + "-" + date
            formattedDate = formattedDate + " " + hour + ":" + min + ":00"

            let data = {
                birthdayConfigId: formConfigPick.configId,
                comments: params.commentaires,
                date: formattedDate,
                kidId: formEnfant.selectedKid.id,
            }

            addBirthday(data).then(
                (res) => {
                    setDataLoading(false)
                    if (res.status === 200) {
                        setDisplayForm(false)
                        navigate(path("/confirmcommand"), {
                            state: {
                                confirmedSubscription: res.data.data,
                                message: "Votre centre UrbanSoccer vous recontactera très prochainement.",
                                success: true,
                                title: "Demande de créneau transmise ",
                                tunnelType: "BIRTHDAY",
                            },
                        })
                    }
                },
            )
        }
    }

    return (
        <Step
            {...props}
            title="Créneaux disponibles"
            help={ help }
            helpId={"birthDayCreneauHelp"}
            ref={step}
            onValidation={postBirthday}
            promise={true}
            className="birthdaySlotChoice"
        >
            <PreForm>
                <div className="h2">
                    Selectionnez une date :
                    <InputDate
                        bind={[ date, setDate ]} 
                        min={new Date()}
                        useDatePicker={true}
                        customClass="customDayPicker"
                        daywp={daywp}
                        monthwp={monthwp}
                        yearwp={yearwp}
                    />
                </div>
            </PreForm>
            {
                dataLoading &&
                    <Preloader fixed={true} />
            }
            {!dataLoading && (
                <>
                    <div className="formsHolder c-row formsHolder justify-center align-start 
                    c-mrg--btm--1 c-mrg--btm--sm--1 layoutSmall timeslots-birthday">
                        <div className="c-col c-col--5 c-col--sm--12">
                            <FormValidator>
                                {
                                    timeSlotsSameDate && timeSlotsSameDate.length > 0 &&
                                        (<div className="radioList--small--half timeSlotsSameDate">
                                            {timeSlotsSameDate}
                                        </div>)
                                }

                                { date && (!dataLoading && (!timeSlotsSameDate || (timeSlotsSameDate && timeSlotsSameDate.length === 0))) && (
                                    <div className="radioList--small--half timeSlotsSameDate">
                                        <h2 className="slotH2Custom">Désolé, aucun créneau dédié aux anniversaires n'est disponible à cette date.</h2>
                                    </div>
                                )}

                                {
                                    date && timeSlotsOtherDate && timeSlotsOtherDate.length > 0 &&
                                        (<div className="radioList--small--half">
                                            <h2 className="slotH2Custom">Nous vous proposons d'autres dates :</h2>
                                            {timeSlotsOtherDate}
                                        </div>)
                                }

                                { date && (!dataLoading && (!timeSlotsSameDate || (timeSlotsSameDate && timeSlotsSameDate.length === 0))) && (
                                    <div className={classNames("demander-creneau-btn-div ", { "padding-bottom": !displayForm })}>
                                        <h2 className="slotH2Custom">
                                            Aucun créneau proposé ne vous convient ? Faites une demande de créneau, votre centre vous recontactera au plus vite.
                                        </h2>
                                        <ButtonCta
                                            text = "Demander un créneau"
                                            isCta2Alt = {true}
                                            icon = "/assets/icons/icon--calendar--white.svg"
                                            onClick = {
                                                () => {
                                                    if (formCreneauDispo.selectedTimeSlot) {
                                                        closeAllTooltipDescription()
                                                        setFormInStore(CreneauDispo.formName, {
                                                            ...formCreneauDispo,
                                                            selectedTimeSlot: {},
                                                        })
                                                    }
                                                    if (displayForm) {
                                                        setFormInStore(CreneauDispo.formDataName, {
                                                            ...formCreneauDispoData,
                                                            data: null,
                                                        })

                                                        // si il y a deja une inscription avec demande de creneau
                                                        if (formCreneauDispo.birthdaySubscription &&
                                                            formCreneauDispo.birthdaySubscription.status === "A pourvoir") {
                                                            setFormInStore(CreneauDispo.formName, {
                                                                ...formCreneauDispo,
                                                                birthdaySubscription: null,
                                                            })
                                                        }

                                                        if (slotRequestForm.current !== undefined) {
                                                            slotRequestForm.current.clear()
                                                        }
                                                    }
                                                    setDisplayForm(!displayForm)

                                                    if (!displayForm) {
                                                        const checkDOMExistence = (dom) => {
                                                            const documentElement = document.getElementById("slotRequestForm")
                                                            if (documentElement) {
                                                                scrollToForm()
                                                            } else {
                                                                setTimeout(() => {
                                                                    checkDOMExistence(dom)
                                                                }, 200)
                                                            }
                                                        }
                                                        checkDOMExistence("slotRequestForm")
                                                    }
                                                }
                                            }
                                        />   
                                    </div>
                                )}
                                
                            </FormValidator>

                        </div>

                        {
                            !date &&
                                (<div className="c-col c-col--9 c-col--sm--12">
                                    <div className="c-mrg--btm--2">
                                        <div className="text-center cardInfo__empty">Sélectionnez le jour et le créneau auxquels vous souhaitez fêter l'anniversaire de <span className="child-name">{formEnfant && formEnfant.selectedKid ? formEnfant.selectedKid.firstName : ""}</span></div>
                                    </div>
                                </div>)
                        }

                        { date && !isMobile && (
                            <div className={classNames("c-col c-col--3 c-col--sm--12 sticky")}>
                                {renderDescriptionCard()}
                            </div>
                        )}

                    </div>

                    {date && !(!dataLoading && (!timeSlotsSameDate || (timeSlotsSameDate && timeSlotsSameDate.length === 0))) && (
                        <div className="c-row justify-center align-start  c-mrg--btm--2 c-mrg--btm--sm--1 layoutSmall button-holder birthday-request-div">
                            <div className="c-col c-col--5 c-col--sm--12 demander-creneau-btn-div">
                                <h2 className="slotH2Custom">
                                    Aucun créneau proposé ne vous convient ? Faites une demande de créneau, votre centre vous recontactera au plus vite.
                                </h2>
                                <ButtonCta
                                    icon="/assets/icons/icon--calendar--white.svg"
                                    text="Demander un créneau"
                                    isCta2Alt
                                    onClick={
                                        () => {
                                            if (formCreneauDispo.selectedTimeSlot) {
                                                closeAllTooltipDescription()
                                                setFormInStore(CreneauDispo.formName, {
                                                    ...formCreneauDispo,
                                                    selectedTimeSlot: {},
                                                })
                                            }
                                            if (displayForm) {
                                                setFormInStore(CreneauDispo.formDataName, {
                                                    ...formCreneauDispoData,
                                                    data: null,
                                                })

                                                // si il y a deja une inscription avec demande de creneau
                                                if (formCreneauDispo.birthdaySubscription &&
                                                    formCreneauDispo.birthdaySubscription.status === "A pourvoir") {
                                                    setFormInStore(CreneauDispo.formName, {
                                                        ...formCreneauDispo,
                                                        birthdaySubscription: null,
                                                    })
                                                }

                                                if (slotRequestForm.current !== undefined) {
                                                    slotRequestForm.current.clear()
                                                }
                                            }
                                            setDisplayForm(!displayForm)

                                            if (!displayForm) {
                                                setTimeout(() => {
                                                    scrollToForm()
                                                }, 200)
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="c-col c-col--3 c-col--sm--12">
                            </div>

                        </div>
                    )}

                    { displayForm && 
                        (<div className="c-row justify-center align-start  c-mrg--btm--2 c-mrg--btm--sm--1 layoutSmall">
                            <div className="c-col c-col--8 c-col--sm--12 slotRequestForm" id="slotRequestForm">
                                <SlotDemandRequestForm
                                    ref={slotRequestForm}
                                    data={formCreneauDispoData.data}
                                    onValidationFunct={requestSlot}
                                    customCls="askCreneau"
                                />
                            </div>
                        </div>)
                    }
                </>
            )}
        </Step>
    )
}

CreneauDispo.formName = "creneauDispoAnniv"
CreneauDispo.formDataName = "creneauDispoAnnivData"
CreneauDispo.propTypes = {
    daywp: number,
    monthwp: number,
    yearwp: number,
}

export default CreneauDispo
