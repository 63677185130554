const PadelLevel = {
    1: "Initiation (1)",
    10: "Élite (10)",
    2: "Débutant (2)",
    3: "Débutant + (3)",
    4: "Intermédiaire (4)",
    5: "Intermédiaire + (5)",
    6: "Avancé (6)",
    7: "Avancé + (7)",
    8: "Expert (8)",
    9: "Expert + (9)",
}

export const PadelLevelArray = Object.entries(PadelLevel).map(([ value, label ]) => ({ label, value: parseInt(value) }))
export default PadelLevel
