import dayjs from "dayjs"
import PropTypes from "prop-types"

import { GameTeamDisplay } from "../../parts/GameTeamDisplay/GameTeamDisplay.jsx"
import { TeamGameSeries } from "../../parts/GameTeamDisplay/TeamGameSeries/TeamGameSeries.jsx"
import { Title } from "../../parts/Title/Title.jsx"
import { VisualPlayerPicture } from "../../parts/VisualPlayerPicture/VisualPlayerPicture.jsx"
import { BaseLayout } from "../Base.jsx"

import "./NextGame.scss"

export const NextGame = (props) => {
    const { gameDetails, league } = props.data

    return (
        <BaseLayout decorator="bottom" withGradient>
            <Title size={[ 66 ]} title={
                <span className="primary tw-regular"><strong className="tw-bold">{league?.name}</strong> Saison {props.ctx.leagueSeason}</span>
            } cls={"uppercase tw-lh-1"} margin={[ 0,0,190,0 ]} />
            <VisualPlayerPicture
                picture={
                    props.ctx?.selectedTeamPlayerPhoto
                    ?? gameDetails?.localTeam?.players?.find(player => player.isCaptain)?.businessLeaguePictureUrl
                    ?? "/assets/images/business/communicationPack/mock/personLeft.png"
                }
                width={650}
                zIndex={0}
                coordinates={{
                    bottom: 0,
                    left: -136,
                }}
            />
            <VisualPlayerPicture
                picture={
                    props.ctx?.selectedOpponentPlayerPhoto
                    ?? gameDetails?.visitorTeam?.players?.find(player => player.isCaptain)?.businessLeaguePictureUrl
                    ?? "/assets/images/business/communicationPack/mock/personLeft.png"
                }
                width={650}
                zIndex={0}
                mirror
                coordinates={{
                    bottom: 0,
                    right: -136,
                }}
            />
            <div className={"nextGame"}>
                <GameTeamDisplay team={gameDetails?.localTeam} bottomEl={<TeamGameSeries team={gameDetails?.localTeam}/>} size={180}/>
                <div className={"versusContent"}>
                    <img src={"/assets/images/business/communicationPack/vs.png"}/>
                    <Title size={[ 48,30 ]} title={dayjs(gameDetails?.date).format("dddd DD MMMM | HH[h]mm")} subtitle={"URBANSOCCER " + league?.centerName} cls={"uppercase tw-bold"}/>
                </div>
                <GameTeamDisplay team={gameDetails?.visitorTeam} bottomEl={<TeamGameSeries team={gameDetails?.visitorTeam}/>} size={180}/>
            </div>
        </BaseLayout>
    )
}

NextGame.propTypes = {
    ctx: PropTypes.object,
    data: PropTypes.object,
}
