import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getMySupersubsMatchs } from "./api"
import InfiniteScroller from "../../../components/InfiniteScroller/InfiniteScroller"
import MatchBlock from "../../../components/SuperSub/MatchBlock/MatchBlock"
import useShowBanScreen from "../../../hooks/supersub/showBanScreen"
import usePath from "../../../routes/services/usePath"

import "./MySupersubsRequests.scss"

function MySupersubsRequests() {
    const userLoaded = useSelector(state => state.userLoaded)
    const loadUserFriends = useSelector(state => state.userFriends)

    const [ selectedMatch, setSelectedMatch ] = useState(null)
    const navigate = useNavigate()
    const path = usePath()
    const showBanScreen = useShowBanScreen()

    function goToFindMatch() {
        navigate(path("/supersub/findMatch"))
    }

    useEffect(
        () => {
            if (userLoaded.supersubBanned) {
                showBanScreen("supersub")
            }
        }, [],
    )

    return (
        <div className="step matchs mySupersubRequest">
            <InfiniteScroller
                gatherTopData={
                    (PAGE_SIZE, offset) => getMySupersubsMatchs(
                        userLoaded.id,
                        PAGE_SIZE,
                        offset,
                        [ 1 ],
                        undefined,
                        new Date().toISOString(),
                        false,
                    )
                }
                gatherBotData={
                    (PAGE_SIZE, offset) => getMySupersubsMatchs(
                        userLoaded.id,
                        PAGE_SIZE,
                        offset,
                        [ 0, 1 ],
                        new Date().toISOString(),
                        undefined,
                    )
                }
                topDisplayMapper={
                    (match) => (
                        <MatchBlock
                            isSelected={match.id === selectedMatch}
                            selectMatch={setSelectedMatch}
                            match={match}
                            alreadyPlayed={true}
                            key={"playedmatch-" + match.id}
                        />
                    )
                }
                botDisplayMapper={
                    (match, index) => (
                        <MatchBlock
                            areFriends={loadUserFriends.some(friend => friend.ozmoSystemId === match.request.organizer.id)}
                            match={match}
                            key={"mtchblck-" + index}
                        />
                    )
                }

                getItemBlockById={
                    (id) => document.getElementById("matchblock-" + id)
                }
                emptyDataText="Tu n'as postulé à aucun match."
                emptyDataButtonText="Trouver un match"
                emptyDataButtonCallback={goToFindMatch}
                topTitle="Joués"
                botTitle="À venir"
            />
        </div>
    )
    
}

export default MySupersubsRequests
