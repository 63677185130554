import { func } from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"

import { forgottenPassword } from "./api"
import AuthStep from "../../../components/authentification/authStep/authStep"
import AuthStepper from "../../../components/authentification/authStepper/authStepper"
import EmailConfirmation from "../../../components/authentification/emailConfirmation/emailConfirmation"
import GatherEmail from "../../../components/Forms/gatherEmail/gatherEmail"
import Preloader from "../../../components/loaders/preloader/preloader"
import { useThemeUpdate } from "../../../components/ThemeContext/ThemeContext"
import useAppRedirect from "../../../hooks/useAppRedirect"
import useThemeHeader from "../../../hooks/useThemeHeader"
import usePath from "../../../routes/services/usePath"
import { toggleShowFooter } from "../../../store/actions/scaffold"
import { goNextAuthStep } from "../../../store/auth/actions/actions"
import { clearAllForms, setFormEntry } from "../../../store/forms/actions"

import "./passwordForgotten.scss"

function PasswordForgotten() {

    const [ clickOnNext, setClickOnNext ] = useState(false)
    const [ email, setEmail ] = useState("")
    const [ errorMessage, setErrorMessage ] = useState(null)
    const [ initComponent, setInitComponent ] = useState(false)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)

    const passwordForgottenData = useSelector(state => state.forms[PasswordForgotten.formName])
    const currentAuthStepId = useSelector(state => state.auth.currentAuthStepId)

    const [ retryComplete, setRetryComplete ] = useState(false)
    const [ isSuccess, setIsSuccess ] = useState(false)
    const [ successOrErrorMsg, setSuccessOrErrorMsg ] = useState(false)

    /* ACTIONS */
    const path = usePath()
    const appRedirect = useAppRedirect()
    const dispatch = useDispatch()
    const location = useLocation()
    const setTheme = useThemeUpdate()
    const themeHeader = useThemeHeader()
    const [ , updateState ] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])
    if (location.pathname === "/padel/forgotPassword") {setTheme("theme-padel")}
    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }
    
    useEffect(
        () => {
            if (!initComponent) {
                setInitComponent(true)
                if (passwordForgottenData?.email) {
                    if (!clickOnNext) {
                        setClickOnNext(true)
                    }
                }
            }
            return () => dispatch(toggleShowFooter(true))
        }, [ passwordForgottenData ],
    )

    function stepperTitle() {
        switch (currentAuthStepId) {
        case 1:
            return "Mot de passe oublié ?"
        case 2:
            return "Récupération du lien par e-mail"
        default:
            return "Mot de passe oublié ?"
        }
    }

    return (
        <React.Fragment>
            {ajaxLoading && 
                <Preloader fixed={true}/>
            }
            <AuthStepper
                title={stepperTitle()}
                prevAction={() => {
                    dispatch(clearAllForms())
                    appRedirect(path("/login"))
                }}
                rootClasses="women-bkg forgotPassword"
                contentCls={
                    currentAuthStepId === 2 ?
                        "passwordComplexityParent"
                        :
                        null
                }
                help={<p>Tu rencontres un problème pour te connecter ou créer un compte ? Contacte-nous au 0 806 23 00 24</p>}
            >
                <AuthStep cls="forgottenPassword">
                    <h2>
                        Renseigne ton adresse e-mail ci-dessous.
                    </h2>
                    <p>
                        Tu vas recevoir un lien pour réinitialiser ton mot de passe.
                    </p>
                        
                    <GatherEmail
                        initialValue={passwordForgottenData?.email ?
                            passwordForgottenData.email : ""}
                        onSubmit={
                            (newEmail, captchaToken) => {
                                setFormInStore(PasswordForgotten.formName, {
                                    ...passwordForgottenData,
                                    email: newEmail,
                                })

                                setEmail(newEmail)

                                setAjaxLoading(true)
                                forgottenPassword(newEmail, captchaToken, themeHeader).then(
                                    (res) => {
                                        setAjaxLoading(false)
                                        let apiMsg
                                        if (res.data?.data) {
                                            apiMsg = res.data?.data?.Message
                                        }
                                        if (apiMsg === "L'utilisateur n'a pas été retrouvé dans la base.") {
                                            setErrorMessage(
                                                <p className="text-input-error">
                                                    Une erreur est survenue. Tu peux créer un compte <Link to={path("/signup")}>ici</Link>.
                                                </p>,
                                            )
                                        } else if (apiMsg === "Pas de mot de passe défini pour ce compte.") {
                                            setErrorMessage("Contacte le service client, en utilisant l’info-bulle en haut à droite de l’écran.")
                                        } else if (apiMsg) {
                                            setErrorMessage(apiMsg)
                                        }
                                        if (!res || res?.data?.code === 204) {
                                            setErrorMessage(null)
                                            dispatch(goNextAuthStep())
                                        }
                                    },
                                )
                            }
                        }
                        canDisplayError={!!errorMessage}
                        errorMessage={errorMessage}
                        changeErrorMessage={setErrorMessage}
                    />
                </AuthStep>
                <AuthStep>
                    <EmailConfirmation
                        introText="Ta demande de changement de mot de passe à bien été prise en compte"
                        retryValue={email}
                        retryAction={
                            (mail, captchaToken) => {
                                setAjaxLoading(true)
                                setEmail(mail)
                                forgottenPassword(mail, captchaToken, themeHeader).then(
                                    (res) => {
                                        setAjaxLoading(false)
                                        setRetryComplete(true)
                                        if (res?.data?.data?.Message) {
                                            setIsSuccess(false)
                                            setSuccessOrErrorMsg(res.data.data.Message)
                                        } else {
                                            setIsSuccess(true)
                                            setSuccessOrErrorMsg("Un nouvel email a été envoyé")
                                        }
                                    },
                                )
                            }
                        }
                        orangeSubText="Clique sur le lien pour réinitialiser ton mot de passe"
                        retryComplete={retryComplete}
                        isSuccess={isSuccess}
                        successOrErrorMsg={successOrErrorMsg}
                        displaySignup
                    />
                </AuthStep>
            </AuthStepper>
        </React.Fragment>
    )

}

PasswordForgotten.formName = "passwordForgotten"

PasswordForgotten.propTypes = {
    prevAction: func,
}

export default PasswordForgotten
