import dayjs from "dayjs"
import { bool, object } from "prop-types"
import { useLocation, useNavigate } from "react-router-dom"

import useIsMobile from "../../../hooks/useIsMobile.js"
import useMediaPath from "../../../hooks/useMediaPath.js"
import {
    isGameForfeit,
    isMvpVoteOpen,
} from "../../../pages/league/service/service.js"
import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import { useTheme } from "../../ThemeContext/ThemeContext.jsx"
import VersusDisplay from "../VersusDisplay/VersusDisplay.jsx"

import "./LastMatch.scss"

export default function LastMatch({ match, noCalendar, team }) {
    const isMobile = useIsMobile()
    const navigate = useNavigate()
    const location = useLocation()
    const mediaPath = useMediaPath()
    const theme = useTheme()

    if (noCalendar) {
        return (
            <div className="last-match no-match">
                <h2 className="title">Dernier match</h2>
                <p className="no-match">Le calendrier sera bientôt disponible.</p>
            </div>
        )
    }

    const voteRequirements = isMvpVoteOpen(match)
        && !match?.mvpInfos?.selectedPlayer
        && match?.currentUserStatus === 1
        && match?.players?.filter(player => player?.status === 1).length > 1

    return (
        <div className={"last-match"}>
            <h2 className="title">Dernier match</h2>

            <div className="date-wrapper">
                <img alt="icone date" src={mediaPath([
                    "/assets/icons/calendar-orange.svg",
                    "/assets/icons/calendar-orange.svg",
                    "/assets/icons/business/calendar-orange.svg",
                ])} className="date"/>
                <span>{dayjs(match?.date).format("dddDD/MM/YYYY")}</span>
                <img alt="icone heure" src={mediaPath([
                    "/assets/icons/heure-orange.svg",
                    "/assets/icons/heure-orange.svg",
                    "/assets/icons/business/heure-orange.svg",
                ])} className="hour"/>
                <span>{dayjs(match?.date).format("HH[H]mm")}</span>
            </div>
            
            <VersusDisplay
                big={!isMobile}
                team1={match?.localTeam}
                team2={match?.visitorTeam}
                isMatchPlayed
                isForfeit={isGameForfeit(match)}
            />

            {voteRequirements ? (
                <ButtonCta
                    text="désigne l'homme du match"
                    isAlt={theme !== "theme-business"}
                    onClick={() => navigate(LeaguePath.toLeagueMatchSummary(team?.id, match.id), {
                        state: {
                            from: location.pathname,
                            openMvpVote: true,
                        },
                    })}
                />
            ) : (
                <ButtonCta
                    text="Récapitulatif du match"
                    isAlt={theme !== "theme-business"}
                    onClick={() => navigate(LeaguePath.toLeagueMatchSummary(team?.id, match.id), {
                        state: {
                            from: location.pathname,
                        },
                    })}
                />
            )}
        </div>
    )
}

LastMatch.propTypes = {
    match: object,
    noCalendar: bool,
    team: object,
}
