/* # Connecteurs endpoints API League */
import dayjs from "dayjs"

import { _apiCall } from "../../../backend/axios.js"
import { buildQueryString, returnAnswerData, returnApiAnswer } from "../../../backend/helpers.js"

/* LISTING */
export function getUserLeagueRegistrations(params) {
    const query = buildQueryString(params)

    return _apiCall(
        "read/league/registrationsleague" + query,
        "GET",
    ).then((response) => {
        return {
            count: response.data.data.hitCount ?? response.data.data.length ?? 0,
            items: response.data.data.items ?? response.data.data,
        }
    })
}
    
/* Choix Equipe */
export function getUserRegistrationLeagueTeams() {
    return _apiCall(
        "read/league/registration/teams",
        "GET",
    ).then(returnApiAnswer)
}

export function getLeagueCenters(countryCode = 250) {
    return _apiCall(
        `read/league/centers/${countryCode}`,
        "GET",
    ).then(returnApiAnswer)
}

/* Choix League */
export function listCenterLeagues(centerId, teamId) {
    return _apiCall(
        `read/league/center/${centerId}/team/${teamId}/list`,
        "GET",
    ).then(returnApiAnswer)
}

export function listLeagueDetail(leagueId) {
    return _apiCall(
        `read/league/${leagueId}`,
        "GET",
    ).then(returnApiAnswer)
}

export function getPrivateLeague(privateCode) {
    return _apiCall(
        `read/league/private/${privateCode.toUpperCase()}`,
        "GET",
    ).then(returnApiAnswer)
}

/* Gestion Effectif */
export function addPlayerToLeagueTeam(registrationId, contactId) {
    return _apiCall(
        "create/league/registration/players/pick",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {
            contactId: contactId,
            registrationId: registrationId,
        },
    ).then(returnApiAnswer)
}

/* Prestation Supplémentaires */
export function addLeagueOption(id, optionId) {
    return _apiCall(
        `modify/league/${id}/option/add`,
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            optionId: optionId,
        },
    ).then(returnApiAnswer)
}

export function deleteLeagueOption(id, optionId) {
    return _apiCall(
        `modify/league/${id}/option/delete`,
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            optionId: optionId,
        },
    ).then(returnApiAnswer)
}

/* Nike Equipment */
export function addLeagueProduct(leagueId, productId, size, quantity, theme) {
    return _apiCall(
        "modify/league/registration/" + leagueId + "/product/add",
        "POST", {
            "Content-Type": "multipart/form-data",
            activity: theme,
        },
        {
            ["product-" + productId] : size,
            ["quantity-product-" + productId] : quantity,
        },
    ).then(returnApiAnswer)
}

export function deleteLeagueProduct(id, productId, size, theme) {
    return _apiCall(
        "modify/league/registration/" + id + "/product/delete/" + productId + "/" + size,
        "POST", {
            "Content-Type": "multipart/form-data",
            activity: theme,
        },
    ).then(returnApiAnswer)
}

/* Order Summary */
export function generatePayzenLeaguePaymentReqs(data) {
    return _apiCall(
        "create/profil/payment/card/league/registration/payment/reqs",
        "POST", {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then((response) => {
        if (response) {
            return response
        }
        return []
    })
}

export function addLeagueDiscountCode(id, code) {
    return _apiCall(
        "modify/league/registration/" + id + "/discountCode/add",
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            code: code,
        },
    ).then(returnApiAnswer)
}

export function deleteLeagueDiscountCode(id, code) {
    return _apiCall(
        `modify/league/registration/${id}/discountCode/delete`,
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            code: code,
        },
    ).then(returnApiAnswer)
}

/* Retours Payzen */
export function getLeagueRegistration(id, theme) {
    return _apiCall(
        `read/league/registration/${id}/detail`,
        "GET",
        { activity: theme },
    ).then(returnApiAnswer)
}

/* MULTI STEPS */
export function editLeagueSubscription(
    centerId,
    leagueId,
    selectedTeamId = "_NEW",
    newTeamName,
    picture,
    registrationId,
    selectedPlayers,
    comments,
    waitingForAvailability,
    sendEmailToTeamMembers,
    captainId,
    //options-"name"
    viceCaptainId,
) {
    return _apiCall(
        "modify/league/registration/edit",
        "POST", {
            "Content-Type": "multipart/form-data",
        },
        registrationId ? 
            {
                captainId: captainId,
                centerId: centerId,
                comments: comments ? comments : "",
                leagueId: leagueId,
                newTeamName: newTeamName,
                registrationId: registrationId,
                selectedPlayers: selectedPlayers,
                selectedTeamId: selectedTeamId,
                sendEmailToTeamMembers: sendEmailToTeamMembers,
                //options-"name": //options-"name",
                viceCaptainId: viceCaptainId,
                waitingForAvailability: waitingForAvailability,
            }
            :
            {
                centerId: centerId,
                leagueId: leagueId,
                newTeamName: newTeamName,
                picture: picture,
                selectedTeamId: selectedTeamId ? selectedTeamId : "_NEW",
                waitingForAvailability: waitingForAvailability,
            },
    ).then(returnApiAnswer)
}

export function preregisterLeagueSubscription(data) {
    return _apiCall(
        "modify/league/team/registration/preregister",
        "POST", {
            "Content-Type": "multipart/form-data",
        },
        {
            registrationId: data,
        },
    ).then(returnApiAnswer)
}

// modify/league/team/registration/preregister/cancel
export function cancelPreregisterLeagueSubscription(id) {
    return _apiCall(
        "modify/league/team/registration/preregister/cancel",
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            registrationId: id,
        },
    ).then(returnAnswerData)
}

// removeRegistrationTeamPlayer
export function unpickLeagueRegistrationPlayer(subscriptionId, playerId) {
    return _apiCall(
        "modify/league/registration/unpick/player",
        "POST", {
            "Content-Type": "multipart/form-data",
        }, {
            contactId: playerId,
            registrationId: subscriptionId,
        },
    ).then(returnApiAnswer)
}

export function getLeaguesByPeriodAndCenterId(centerId, periodStart) {
    let end = dayjs(periodStart).endOf("year")

    if (end.isAfter(dayjs())) {
        end = dayjs()
    }

    return _apiCall(
        `read/league/center/list?centerId=${centerId}&periodStart=${periodStart}&periodEnd=${end.format("YYYY-MM-DDTHH:mm:ss")}`,
        "GET",
    ).then(returnAnswerData ?? [])
}

export function getCenter(id) {
    return _apiCall(
        `read/centers/${id}`,
        "GET",
    ).then(returnAnswerData)
}
