import classNames from "classnames"
import { bool, func } from "prop-types"
import { useState } from "react"

import "./Toggle.scss"

function Toggle({ enabled, callback }) {
    const [ isEnabled, setIsEnabled ] = useState(enabled)
    return (
        <div
            className={classNames("toggle", {
                disabled: !callback,
                enabled: isEnabled,
            })} 
            onClick={ 
                () => {
                    if (callback) {
                        callback(!isEnabled)
                        setIsEnabled(!isEnabled)
                    }
                }
            }
        >
            <div className="toggle__bubble"></div>
        </div>
    )
}

Toggle.propTypes = {
    callback: func,
    enabled: bool,
}

export default Toggle
