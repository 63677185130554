export default {
    1: "janvier",
    10: "octobre",
    11: "novembre",
    12: "décembre",
    2: "février",
    3: "mars",
    4: "avril",
    5: "mai",
    6: "juin",
    7: "juillet",
    8: "août",
    9: "septembre",
}
