import { useEffect, useRef } from "react"

export function useClickOutside(callback) {
    const ref = useRef()

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            callback()
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return ref
}
