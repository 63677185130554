import { _apiCall } from "../../../backend/axios"

export function signIn(login, password, captchaToken, fbToken, googleToken) {
    const data = {}
    const testingApiKey = import.meta.env.VITE_TESTING_API_KEY
    const headers = {
        "Content-Type" : "application/json",
    }

    if (testingApiKey) {
        headers["x-ozmo-testing-key"] = testingApiKey
    }

    if (login) {
        data.login = login
    }
    
    if (password) {
        data.password = password
    }

    if (captchaToken) {
        data.captchaToken = captchaToken
    } 

    if (fbToken) {
        data.facebookToken = fbToken
    } else if (googleToken) {
        data.googleToken = googleToken
    }
    return _apiCall(
        "check/login",
        "POST",
        headers,
        data,
    )
}
