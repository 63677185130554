import classNames from "classnames"
import dayjs from "dayjs"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import { useState } from "react"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import Preloader from "../../../../components/loaders/preloader/preloader.jsx"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext.jsx"
import { TooltipLight } from "../../../../components/tooltip/tooltipLight/tooltipLight.jsx"
import { getUser } from "../../../../globalAPI/api.js"
import useMediaPath from "../../../../hooks/useMediaPath"
import { editFavoriteSlot } from "../api.js"

import "./FavoriteSlotItem.scss"

export const FavoriteSlotItem = (props) => {
    const [ isLoading, setIsLoading ] = useState(false)

    const { center, start, fieldName, notificationSource, status } = props.slot
    const day = dayjs().day(props.slot.day).format("dddd")
    const startHour = `${start}h`
    const endHour = `${(start + 2) < 24 ? (start + 2) + "h" : "00h"}`
    const media = useMediaPath()
    const theme = useTheme()

    const notificationList = notificationSource.map((source, index) => {
        return (
            <img alt={`notification-${source}`} key={index} src={getNotificationIcon(source)}/>
        )
    })

    const handleToggleNotification = () => {
        if (isLoading || !notificationSource.length) {return}

        const slot = props.slot
        setIsLoading(true)
        const newStatus = slot.status === 0 ? 1 : 0
        editFavoriteSlot(slot.id, {
            ...slot,
            notificationSource: JSON.stringify(slot.notificationSource),
            resourceTypes: JSON.stringify(slot.resourceTypes),
            status: newStatus,
        }).then(() => {
            getUser(true).finally(() => {
                setIsLoading(false)
            })
        })
    }

    const image = status === 0 && notificationList.length  ? "/assets/icons/favoriteSlot/bell-check.svg" :
        media([ "/assets/icons/favoriteSlot/bell-cancel.svg",
            "/assets/icons/favoriteSlot/padel/bell-cancel.svg" ])

    return (
        <motion.div layout variants={props.variants} className={
            classNames("favorite-item-wrapper", {
                "searchable": props.searchable,
            })
        } key={props.itemKey}>
            <div className={"item"}>
                <span>{day}</span>
                <span>{`${startHour} - ${endHour}`}</span>
            </div>
            <div className={"item"}>
                <span>Centre</span>
                <p>{center[1]}</p>
            </div>
            <div className={"item"}>
                <span>Terrain</span>
                <p>{fieldName ?? "Pas de préférence"}</p>
            </div>
            {!props.searchable && (
                <div className={"item"}>
                    <span>Notifications</span>
                    <div className={"notifications-container"}>
                        {status === 1 ? "Désactivées" : notificationList.length > 0 ? notificationList : "Aucune notification"}
                    </div>
                </div>
            )}
            <div className={"item v-align slot-notifications"}>
                <div className={"actions-container"}>
                    {!props.searchable ? (
                        <>
                            <TooltipLight text={props.slot.notificationSource.length ? (status === 0 ? "Désactiver les" +
                                " notifications" : "Activer les notifications") : null} position={"top"} dark centered>
                                <div>
                                    <ButtonCta
                                        onlyIcon
                                        className={classNames("notification-toggle", {
                                            disabled: !notificationSource.length,
                                            loading: isLoading,
                                        })}
                                        isAlt={status === 1}
                                        noHover
                                        subText={isLoading ? <Preloader small alt={theme === "theme-soccer"}/> : null}
                                        icon={!isLoading ? image : null}
                                        onClick={handleToggleNotification}
                                    />
                                </div>
                            </TooltipLight>
                            <ButtonCta
                                onlyIcon
                                icon={"/assets/icons/pencil--white.svg"}
                                onClick={props.onEdit}
                            />
                            <ButtonCta
                                onlyIcon
                                icon={"/assets/icons/favoriteSlot/bin.svg"}
                                isCta2Alt
                                onClick={props.onDelete}
                            />
                        </>
                    ) : (
                        <ButtonCta
                            onlyIcon
                            icon={"/assets/icons/favoriteSlot/search.svg"}
                            onClick={props.onSearch}
                        />
                    )}
                </div>
            </div>
        </motion.div>
    )

    function getNotificationIcon(source) {
        switch (source) {
        case 0:
            return media([ "/assets/icons/favoriteSlot/notification-push.svg", "/assets/icons/favoriteSlot/padel/notification-push.svg" ])
        case 1:
            return media([ "/assets/icons/favoriteSlot/notification-mail.svg", "/assets/icons/favoriteSlot/padel/notification-mail.svg" ])
        case 2:
            return media([ "/assets/icons/favoriteSlot/notification-sms.svg", "/assets/icons/favoriteSlot/padel/notification-sms.svg" ])
        default:
            return ""
        }
    }
}

FavoriteSlotItem.propTypes = {
    itemKey: PropTypes.string,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onSearch: PropTypes.func,
    searchable: PropTypes.bool,
    slot: PropTypes.object.isRequired,
    variants: PropTypes.object,
}
