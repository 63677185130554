import { bool, number, object, oneOfType, string } from "prop-types"

import CurrencyDisplay from "../../../components/currencies/CurrencyDisplay"
import useMediaPath from "../../../hooks/useMediaPath"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import truncateToTwoDecimals from "../../../services/truncateToTwoDecimals"

import "./BookingInfo.scss"

export default function BookingInfo({ activity, centerName, timeDisplay, duration, discountPrice, discount, price, pricePerPart, error, freeCancelDate, freeCancelDatePassed }) {
    const mediaPath = useMediaPath()
    const isExpiredPrebook = error && error === "Préréservation introuvable."
    const themeFeatures = useThemeFeatures()
    return (
        <div className="bookingInfo">
            <div className="wrapper">
                <div className="details">
                    <div className="info">
                        <img src={mediaPath([ "/assets/icons/terrain-orange.svg", "/assets/images/padel/icons/terrain-red.svg" ])} />
                        <span>{activity}</span>
                    </div>
                    <div className="info center">
                        <img src={mediaPath([ "/assets/icons/center-orange.svg", "/assets/images/padel/icons/center-red.svg" ])} />
                        <span>{centerName}</span>
                    </div>
                    <div className="info">
                        <img src={mediaPath([ "/assets/icons/calendar-orange.svg", "/assets/images/padel/icons/calendar-red.svg" ])} />
                        <span>{timeDisplay}</span>
                    </div>
                    <div className="info">
                        <img src={mediaPath([ "/assets/icons/chrono-orange.svg", "/assets/images/padel/icons/chrono-red.svg" ])} />
                        <span>{duration}</span>
                    </div>
                </div>
                <div className="price">
                    {discountPrice && (
                        <>
                            <span className="discount">
                                {price + "€"}
                            </span>
                            <span> </span>
                        </>
                    )}
                    <CurrencyDisplay price={discountPrice || price} customCls="priceToPay"/>
                    {pricePerPart && price && (
                        <div className="pricePlayer">
                            soit <CurrencyDisplay price={truncateToTwoDecimals(pricePerPart)} /> / personne
                        </div>
                    )}
                </div>
            </div>
            {discountPrice && discount && (
                <div className="discountDetails">
                    <div className="discountLabel">{discount?.label}</div>
                    {discount?.terms && (<div
                        className="discountDescription">{discount.terms}</div>)}
                </div>
            )}
            <div className="bookingFreeCancel">
                {!error && freeCancelDate && (
                    <>
                        <img src={mediaPath([ "/assets/icons/notifications.svg", "/assets/images/padel/icons/icons-info.svg" ])} className="alert"/>
                        {!freeCancelDatePassed ?
                            `En cas d'annulation avant le ${freeCancelDate}, ${themeFeatures([ "10", "25" ])}% 
                            de la réservation sera remboursée sous forme d'avoir. Passé cette date, l'annulation sans 
                            frais n'est plus possible.`
                            :
                            "L'annulation sans frais n'est plus possible."
                        }
                    </>
                )}
                {error && (
                    isExpiredPrebook ?
                        "Session expirée, actualise la page..."
                        : (
                            <>
                                {error}<br />
                            Retourne à l'étape précédente.
                            </>
                        )
                )}
            </div>
        </div>
    )
}

BookingInfo.propTypes = {
    activity: oneOfType([ string, bool ]),
    centerName: oneOfType([ string, bool ]),
    discount: oneOfType([ object, bool ]),
    discountPrice: oneOfType([ number, bool ]),
    duration: oneOfType([ string, bool ]),
    error: oneOfType([ string, bool ]),
    freeCancelDate: oneOfType([ string, bool ]),
    freeCancelDatePassed: bool,
    price: oneOfType([ number, bool ]),
    pricePerPart: number,
    timeDisplay: oneOfType([ string, bool ]),
}
