import { getLeagueRegistration } from "../../league/api/api.js"
import { getCupRegistrationDetails } from "../urbanCup/api"

function useGetTeamRegistration(tunnelType) {
    switch (tunnelType) {
    case "CUP":
        return getCupRegistrationDetails
    case "LEAGUE":
        return getLeagueRegistration
    default: 
        // eslint-disable-next-line no-console
        console.error("NOT IMPLEMENTED CASE")
        // eslint-disable-next-line no-debugger
        debugger
        return () => {}
    }
}

export default useGetTeamRegistration
