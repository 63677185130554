
const lastUniverse = localStorage.getItem("lastUniverse")
const you = lastUniverse === "theme-padel" ? "te" : "vous"
const your = lastUniverse === "theme-padel" ? "ton" : "votre"
export function trainingConfirm(confirmedSubscription) {
    return (
        <span className="longCommandSubtitle">
            {confirmedSubscription?.kid ? (
                <span className="confirmCmdKidNamePhrase">
                    Nous {you} confirmons l'inscription de <span className="confirmCmdKidName">{confirmedSubscription.kid.firstName + " " + confirmedSubscription.kid.lastName}</span>.
                </span>
            ) : (
                <span className="confirmCmdKidNamePhrase">
                    Nous {you} confirmons {your} inscription.
                </span>
            )}
        </span>
    )
}

export function leagueSharePaymentConfirm(leagueSubscription) {
    return (
        <span className="longCommandSubtitle">
            Nous te confirmons ton paiement pour le match de ton équipe <strong className="orangeTxt">{leagueSubscription.championshipTeam.name }</strong>
        </span>
    )
}

export function leagueConfirm(subscription) {
    return (
        <span className="longCommandSubtitle">
            Nous te confirmons l'inscription de ton équipe <strong className="orangeTxt">{subscription.championshipTeam.name }</strong>
        </span>
    )
}

export function cupConfirm(cupSubscription) {
    return (
        <span className="longCommandSubtitle">
            Nous te confirmons l'inscription de ton équipe <strong>{cupSubscription.teamName }</strong><br/>
            au tournoi <strong>{cupSubscription.cup.name}</strong>
        </span>
    )
}
