import { func, number, string } from "prop-types"
import { useCallback, useEffect, useRef, useState } from "react"
import Cropper from "react-easy-crop"

import getCroppedImg from "./cropImage"
import useIsMobile from "../../../../hooks/useIsMobile.js"
import ButtonCta from "../../../Buttons/button/button-cta"

import "./ImageCrop.scss"

function ImageCrop({ src, cropSizeWidth, cropSizeHeight, outputWidth, outputHeight, onCloseCallback, onValidationCallback }) {
    const [ crop, setCrop ] = useState({ width: 0, x: 0, y: 0 })
    const [ rotation, setRotation ] = useState(0)
    const [ zoom, setZoom ] = useState(1)
    const [ croppedAreaPixels, setCroppedAreaPixels ] = useState(null)
    const [ , setCroppedImage ] = useState(null)
    const zoomRef = useRef(zoom)
    const isMobile = useIsMobile()
    useEffect(
        () => {
            function handleKeyPressed(e) {
                var newZoom = zoomRef.current
                if (e.key === "-" || e.key === "_" || e.key === "6") {
                    newZoom -= 0.01
                    setZoom(newZoom)
                } else if (e.key === "=" || e.key === "+") {
                    newZoom += 0.01
                    setZoom(newZoom)
                }
            }
            window.addEventListener(
                "keydown",
                handleKeyPressed,
            )
            return () => {
                window.removeEventListener(
                    "keydown",
                    handleKeyPressed,
                )
            }
        }, [],
    )

    useEffect(
        () => {
            zoomRef.current = zoom
        }, [ zoom ],
    )

    const onCropComplete = useCallback(
        (_, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels)
        }, [ zoom, crop ],
    )

    const showCroppedImage = useCallback(
        async () => {
            try {
                return new Promise(
                    (resolve) => {
                        getCroppedImg(
                            src,
                            croppedAreaPixels,
                            rotation,
                            {
                                height: outputHeight,
                                width: outputWidth,
                            },
                        ).then(
                            (croppedImage) => {
                                setCroppedImage(croppedImage)
                                resolve(croppedImage)
                            },
                        )
                    },
                )
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e)
            }
        }, [ croppedAreaPixels, rotation ],
    )

    const onClose = useCallback(
        (e) => {
            setCroppedImage(null)
            if (onCloseCallback) {
                onCloseCallback(e)
            }
        }, [],
    )

    const onValidation = useCallback(() => {
        showCroppedImage().then(
            (image) => {
                if (onValidationCallback) {
                    onValidationCallback(image)
                }
            },
        )
        
    })

    return (
        <div>
            <div className="imageCrop">
                <Cropper
                    image={src}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    cropSize={{
                        height: cropSizeHeight,
                        width: cropSizeWidth,
                    }}
                    minZoom={0.25}
                    maxZoom={2}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    zoomWithScroll={!isMobile}
                    objectFit={"contain"}
                    restrictPosition={false}
                />
            </div>
            <div className='controls'>
                <ButtonCta
                    text="Annuler"
                    isCta2Alt={true}
                    onClick={onClose}
                />
                <ButtonCta
                    text="Valider"
                    isAlt={true}
                    onClick={onValidation}
                />
            </div>
        </div>
    )
}

ImageCrop.propTypes = {
    cropSizeHeight: number,
    cropSizeWidth: number,
    onCloseCallback: func,
    onValidationCallback: func,
    outputHeight: number,
    outputWidth: number,
    src: string,
}

export default ImageCrop
