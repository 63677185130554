import { bool, func, object } from "prop-types"
import React, { useState } from "react"

import ButtonCta from "../../Buttons/button/button-cta"

import "./BlocInfoSuperSub.scss"

function BlocInfoSuperSub({ response, confirmAction, refuseAction, noReponses }) {

    const [ displayContactNumber, setDisplayContactNumber ] = useState(false)

    function confirm() {
        confirmAction?.()
    }

    function refuse() {
        refuseAction?.()
    }

    function contact() {
        setDisplayContactNumber(true)
    }
    const formatPhoneNumber = (number) =>  number[0] === "+" ?
        number.substring(0,3) + " " + number.substring(3,4) + " " + number.substring(4,6) + " " + number.substring(6,8) + " " + number.substring(8,10) + " " + number.substring(10,12)
        : number.substring(0,2) + " " + number.substring(2,4) + " " + number.substring(4,6) + " " + number.substring(6,8) + " " + number.substring(8,10)

    return (
        !noReponses ? (
            <>
                <div className="cardInfo_arrow"></div>
                <div className="blocInfoSuperSub">
                    {
                        response && response.id ?
                            response.contact && (
                                <React.Fragment>
                                    <h1>INFORMATIONS</h1>
                                    <div className="content">
                                        <div className="supersubInfoContent">
                                            <div className="left">
                                                <img src={response.contact.pictureUrl ? response.contact.pictureUrl :  "/assets/images/user-placeholder-gray.png"} className="picture"/> 
                                            </div>
                                            <div className="center">
                                                <div className="name">{response.contact.firstname} {response.contact.lastname.charAt(0)}.</div>
                                
                                                {
                                                    !response.contact.supersubPlayed ?
                                                        (<div className="noMatchPlayed">
                                                                Nouveau SuperSub
                                                        </div>)
                                                        :
                                                        (<div className="bottomCenter">
                                                            <div className="rating">
                                                                <div className="round-rating">{response.contact.supersubRating ? response.contact.supersubRating : "-"}</div>
                                                                <div className="rating-label">Note</div>
                                                            </div>
                                                            <div className="right">
                                                                <div className="matchPlayed">
                                                                    <div className="nb">{response.contact.supersubPlayed}</div>
                                                                    <div className="matchPlayed-label">Match{response.contact.supersubPlayed > 1 ? "s" : ""} joué{response.contact.supersubPlayed > 1 ? "s" : ""}</div>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                }
                                            
                                            </div>
                                    
                                        </div>

                                        {response.description && (
                                            <div className="description">
                                                {response.description}
                                            </div>
                                        )}

                                        <div className="btnsDiv">
                                            {
                                                response.status === 0 &&
                                                    (<>
                                                        <ButtonCta
                                                            className="buttonCta--w-100 buttonCta--mb"
                                                            onClick={confirm}
                                                            text="Confirmer"
                                                        />
                                                        <ButtonCta
                                                            className="buttonCta--w-100 buttonCta--black"
                                                            onClick={refuse}
                                                            text="Refuser"
                                                        />
                                                    </>)
                                            }
                                            {
                                                response.status === 1 &&
                                                    (<React.Fragment>
                                                        <button data-testid="contactButton" className={displayContactNumber ? "btn btn--orange btn--full contactBtnNotClicable" : "btn btn--orange btn--full"} onClick={contact}>
                                                            {
                                                                displayContactNumber ?
                                                                    (<React.Fragment>
                                                                        <a className="show-sm contactPhoneNumber" href={"tel:" + response.contact.phoneNumber}>{formatPhoneNumber(response.contact.phoneNumber)}</a>
                                                                        <span className="hide-sm">{formatPhoneNumber(response.contact.phoneNumber)}</span>
                                                                    </React.Fragment>)
                                                                    :
                                                                    <span>Contacter</span>
                                                            
                                                            }
                                                        </button>
                                                    </React.Fragment>)
                                            }
                                        
                                        </div>

                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="noData">
                                    <div className="noDataText">
                                        Sélectionne une réponse de SuperSub
                                    </div>
                                </div>
                            )
                    }

                </div>
            </>
        ) : null
    )
}

BlocInfoSuperSub.propTypes = {
    confirmAction: func,
    noReponses: bool,
    refuseAction: func,
    response: object,
}

export default BlocInfoSuperSub
