import { _apiCall } from "../../../backend/axios.js"
import { returnAnswerData } from "../../../backend/helpers.js"

export function getVisualData(data, visualType) {
    return _apiCall(
        `read/league/visual/${visualType}`,
        "POST",
        {
            "Content-Type": "application/json",
        },
        data,
    ).then(returnAnswerData)
}

export function getLeagueDetailed(id) {
    return _apiCall(
        `read/league/${id}/detailed`,
        "GET",
        {
            "Content-Type": "application/json",
        },
    ).then(returnAnswerData)
}
