
import { array } from "prop-types"
import { useEffect, useState } from "react"

import { VideoGrid } from "../../../../components/league/VideoGrid/VideoGrid.jsx"
import { prepareVideoData } from "../../service/service.js"
import { useLeagueStore } from "../../store/useLeagueStore.js"

import "./LeagueMatchVideos.scss"

const LeagueMatchVideos = () => {
    const { currentGame } = useLeagueStore()

    const [ videos, setVideos ] = useState([])
    useEffect(() => {
        setVideos((prepareVideoData(currentGame, 0, (score, video) => {
            return (
                video?.type === "goal" ? (
                    <>
                        <p>{video?.playerName}</p>
                        <h3 className={"score"}>{score}</h3>
                    </>
                ) : (
                    <p>Match complet</p>
                )
            )
        })))
    }, [ currentGame.videos ])

    return (
        <div className="leagueMatchVideos">
            <VideoGrid videos={videos}/>
        </div>
    )
}

LeagueMatchVideos.propTypes = {
    videos: array,
}

export default LeagueMatchVideos
