import { motion } from "framer-motion"
import { arrayOf, bool, node, oneOfType, string } from "prop-types"
import { useSelector } from "react-redux"

import OverlayConfirmationContent from "./overlay_confirmation_content"

const animationVariants = {
    hidden: {
        opacity: 0,
        y: "-100%",
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.12,
        },
        y: 0,
    },
}

function OverlayConfirmation({
    displayValidationButton,
}) {
    const confirmationOverlay = useSelector(state => state.confirmationOverlay)
    const confirmMessage = (confirmationOverlay && confirmationOverlay.props) ?
        confirmationOverlay.props.confirmMessage
        :
        undefined
    
    const unconfirmMessage = (confirmationOverlay && confirmationOverlay.props) ?
        confirmationOverlay.props.unconfirmMessage
        :
        undefined

    function renderClassName() {
        let classes = ""
        classes += confirmationOverlay.open ? "" : "hidden "
        return classes
    }

    return (
        <motion.div
            id="overlay-confirmation"
            className={renderClassName()}
            initial="hidden"
            animate={confirmationOverlay.open ? "visible" : "hidden"}
            variants={animationVariants}
        >
            <OverlayConfirmationContent
                displayValidationButton={displayValidationButton}
                confirmMessage={confirmMessage}
                unconfirmMessage={unconfirmMessage}
            />
        </motion.div>
    )
}

OverlayConfirmation.propTypes = {
    confirmMessage: oneOfType([ string, node, arrayOf(node) ]),
    displayValidationButton: bool,
    open: bool,
    unconfirmMessage: oneOfType([ string, node, arrayOf(node) ]),
}

export default OverlayConfirmation
