import { isBusinessEnvironment } from "../../utilities/helpers.js"

export class LeaguePath {
    static baseRoute = isBusinessEnvironment() ? "/" : "/urbanleague/"
    static listingLeague = `${this.baseRoute}inscriptionLeague`
    static newLeague = `${this.baseRoute}new`
    static newLeagueWithId = ":id"
    static leagueInfosBase = `${this.baseRoute}myLeague`
    static leagueInfos = `${this.baseRoute}myLeague/:teamId`
    static leagueInfosHome = "general"
    static leagueInfosCalendar = "calendrier"
    static leagueInfosRanking = "classement"
    static leagueTeamDetails = `${this.baseRoute}team/:teamId/details`
    static leagueMatchBase = `${this.baseRoute}myLeague/:teamId/match`
    static leagueMatchDetails = ":matchId"
    static leagueMatchCompo = "composition"
    static leagueMatchRoster = "effectif"
    static leagueMatchSummary = "resume"
    static leagueMatchVideo = "videos"
    static leagueRanking = `${this.baseRoute}classement`
    static leaguePalmares = `${this.baseRoute}palmares`
    static leaguePalmaresDetails = `${this.baseRoute}palmares/:leagueId/:teamId`
    static leaguePlayerDetails = `${this.baseRoute}team/:teamId/player/:playerId/details`
    static communicationPackage = "pack-communication"
    static toLeagueInfos(teamId) {
        if (teamId) {
            return `${this.leagueInfosBase}/${teamId}`
        }
    }

    static toLeagueInfosHome(teamId) {
        if (teamId) {
            return `${this.leagueInfosBase}/${teamId}/general`
        } else {
            return `${this.leagueInfosBase}/general`
        }
    }

    static toLeagueTeamDetails(teamId) {
        if (teamId) {
            return `${this.leagueTeamDetails.split(":teamId")[0]}${teamId}/details`
        }
    }

    static toLeagueMatchDetails(teamId, matchId) {
        if (matchId) {
            return `${this.toLeagueInfos(teamId)}/match/${matchId}/`
        }
    }

    static toLeagueMatchCompo(teamId, matchId) {
        if (matchId) {
            return `${this.toLeagueInfos(teamId)}/match/${matchId}/composition`
        }
    }

    static toLeagueMatchRoster(teamId, matchId) {
        if (matchId) {
            return `${this.toLeagueInfos(teamId)}/match/${matchId}/effectif`
        }
    }

    static toLeagueMatchSummary(teamId, matchId) {
        if (matchId) {
            return `${this.toLeagueInfos(teamId)}/match/${matchId}/resume`
        }
    }

    static toLeagueMatchVideos(teamId, matchId) {
        if (matchId) {
            return `${this.toLeagueInfos(teamId)}/match/${matchId}/videos`
        }
    }

    static toLeagueInfosCalendar(teamId) {
        if (teamId) {
            return `${this.leagueInfosBase}/${teamId}/calendrier`
        } else {
            return `${this.leagueInfosBase}/calendrier`
        }
    }

    static toLeagueInfosRanking(teamId) {
        if (teamId) {
            return `${this.leagueInfosBase}/${teamId}/classement`
        } else {
            return `${this.leagueInfosBase}/classement`
        }
    }

    static toLeaguePalmaresDetails(leagueId, teamId) {
        if (teamId && leagueId) {
            return `${this.leaguePalmares}/${leagueId}/${teamId}`
        } else {
            return this.leaguePalmares
        }
    }

    static toLeaguePlayerDetails(teamId, playerId) {
        if (playerId) {
            return `${this.leaguePlayerDetails.split(":teamId")[0]}${teamId}/player/${playerId}/details`
        }
    }
}
