export default function (state = {}, action) {
    switch (action.type) {
    case  "UPDATE_DOM_DETAILS":
        return {
            ...state,
            ...action.payload,
        }
    }

    return state
}
