import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { loadUrlQueryParameters } from "../../../store/actions/scaffold"
import { useTheme, useThemeUpdate } from "../../ThemeContext/ThemeContext"

import "./SwitchEnv.scss"

function SwitchEnv() {
    const theme = useTheme()
    const setTheme = useThemeUpdate()
    const navigate = useNavigate()
    const queryParameters = useSelector(state => state.queryParameters)
    const dispatch = useDispatch()

    function updateTheme(theme) {
        if (queryParameters && Object.keys(queryParameters)?.length) {dispatch(loadUrlQueryParameters(null))}
        setTheme(theme)
        navigate("/")
    }

    return (
        <div className="quickAction-switchEnv">
            <button
                onClick={() => updateTheme("theme-padel")}
                className={classNames(
                    { "is-active": theme === "theme-padel" },
                    "quickAction-padel",
                )}
                data-testid="switchEnv"
                title="Passer côté Padel"
            >
                <svg viewBox="0 0 35 35" width="36" height="36">
                    <path d="M22.63 6.99v7.5c0 .1-.1.2-.1.2l-11.2 3c-.1 0-.3-.1-.3-.2v-9.8c0-.1.1-.2.1-.2l1.9-.5c.2-.1.3 0 .3.2v7.4c0 .1.1.2.3.2l6.6-1.8c.1 0 .1-.1.1-.2v-6.8c-1.1-.4-2.3-.6-3.5-.6-6.4 0-11.5 5.2-11.5 11.7 0 4.3 2.3 8.1 5.7 10.1v-7.5c0-.1.1-.2.1-.2l11.1-3c.1 0 .3.1.3.2v7.8c0 .1-.1.2-.1.2l-2 .6h-.2l-6.7-.3c-.1 0-.2.1-.2.2v3.2c1.1.4 2.3.6 3.5.6 6.4 0 11.5-5.2 11.5-11.7 0-4.5-2.3-8.3-5.7-10.3"/>
                    <path d="M20.13 22.79c.1 0 .2-.1.2-.2v-3c0-.1-.1-.2-.3-.2l-6.6 1.8c0 .1-.1.1-.1.2v.8c0 .1.1.2.2.2l6.6.4Z"/>
                </svg>
            </button>

            <button
                onClick={() => updateTheme("theme-soccer")}
                className={classNames(
                    { "is-active": theme === "theme-soccer" },
                    "quickAction-soccer",
                )}
                data-testid="switchEnv"
                title="Passer côté Foot"
            >
                <svg viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" width="36" height="36">
                    <path className="quickAction-soccer-bg" fill="#FF7832" d="M8.56 8.39v16c2 2.5 5.1 4.1 8.5 4.1 3.4 0 6.5-1.6 8.5-4.1v-16h-17Z"/>
                    <path fill="#FFF" d="M10.56 23.69c1.7 1.8 4 2.8 6.5 2.8s4.8-1 6.5-2.8v-13.3h-13v13.3Zm11.8-.5c-.7.6-1.4 1.1-2.2 1.5a8.15 8.15 0 0 1-6.2 0c-.8-.4-1.6-.9-2.2-1.5v-11.7h10.6v11.7Z"/>
                </svg>
            </button>
        </div>
    )
}

export default SwitchEnv
