import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import Modal from "../../../../components/Modal/Modal"
import useMediaPath from "../../../../hooks/useMediaPath"
import ModalHandler from "../../../../providers/Modal/ModalHandler"
import usePath from "../../../../routes/services/usePath"
import { FavoriteSlotItem } from "../FavoriteSlotItem/FavoriteSlotItem"

import "./SlotListModal.scss"

export const SlotListModal = ModalHandler.create(({
    visible,
    hide,
    slots,
    onSlotSelected,
    preSelectionValues,
}) => {
    const mediaPath = useMediaPath()
    const path = usePath()
    const navigate = useNavigate()

    const handleAddClick = () => {
        navigate(path("/profil/creneauxFavoris"), {
            state: {
                openModal: true,
                preSelectionValues: preSelectionValues ?? null,
            },
        })
    }

    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className={"slot-list-modal"}
        >
            <header>
                Mes créneaux
            </header>
            <div className={"slot-list-modal-container"}>
                <div className={"slot-list-modal-slots"}>
                    {slots.map(slot => (
                        <FavoriteSlotItem
                            searchable
                            slot={slot}
                            key={slot.id}
                            onSearch={() => {
                                onSlotSelected(slot)
                                hide()
                            }}
                        />
                    ))}
                </div>
                {slots.length < 3 && (
                    <ButtonCta
                        icon={mediaPath([ "/assets/icons/favoriteSlot/add-favorite-slot.svg", "/assets/icons/favoriteSlot/add-favorite-slot-black.svg" ])}
                        hoverChangeIcon={"/assets/icons/favoriteSlot/add-favorite-slot.svg"}
                        text={"Ajouter un créneau"}
                        onClick={() => {
                            handleAddClick()
                            hide()
                        }}
                        isCta2Alt
                    />
                )}
            </div>
        </Modal>
    )
})

SlotListModal.propTypes = {
    onSlotSelected: PropTypes.func,
    preSelectionValues: PropTypes.object,
    slots: PropTypes.array,
}
