import { string } from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import Preloader from "../../../components/loaders/preloader/preloader"
import StepManager from "../../../components/stepsManagement/StepManager"
import StepsContainer from "../../../components/stepsManagement/StepsContainer"
import { useTheme } from "../../../components/ThemeContext/ThemeContext"
import useAddSearchParam from "../../../hooks/useAddSearchParams"
import { goToStep, nextStep } from "../../../store/stepsManagement/actions"
import NikeEquipment from "../../kids/steps/NikeEquipment"
import OrderSummary from "../../payments/OrderSummary/OrderSummary"
import ChoixEquipe from "../../teams/steps/ChoixEquipe"
import useRestoreTeamTunnel from "../hooks/useRestoreTeamTunnel"
import ChoixEffectif from "../urbanCup/steps/ChoixEffectif"
import ChoixTournoi from "../urbanCup/steps/ChoixTournoi"
import PrestationSupp from "../urbanCup/steps/PrestationSupp"

function TeamSteps({ tunnelType }) {
    const [ searchParams ] = useSearchParams()
    const addSearchParams = useAddSearchParam()
    const dispatch = useDispatch()
    const tournamentRegistration = useSelector(state => state.forms.TournamentRegistration)

    const theme = useTheme()

    const restoreStep = () => {
        if (searchParams?.get("equipmentId")) {
            dispatch(goToStep(5))
        } else if (searchParams?.get("optionId")) {
            dispatch(goToStep(4))
        } else if (searchParams?.get("teamComposition")) {
            dispatch(goToStep(3))
        } else if (searchParams?.get("cupId") || searchParams?.get("leagueId")) {
            dispatch(goToStep(2))
        } else if (searchParams?.get("teamId")) {
            dispatch(goToStep(1))
        }
    }

    let id

    if (location.state) {
        id = location.state.id
    }

    useEffect(() => {
        if (tournamentRegistration?.id) {
            addSearchParams("registrationId", tournamentRegistration.id)
        }
    }, [ tournamentRegistration ])

    const registrationId = searchParams.get("registrationId")
    const ajaxLoading = useRestoreTeamTunnel(tunnelType, undefined, id || registrationId, null, restoreStep)

    return (
        <React.Fragment>
            {ajaxLoading &&
                <Preloader fixed />
            }
            <StepManager customCls={tunnelType?.toLowerCase()}>
                <StepsContainer stepsToShow='3'>
                    <ChoixEquipe tunnelType={tunnelType}/>
                    <ChoixTournoi tunnelType={tunnelType}/>
                    <ChoixEffectif tunnelType={tunnelType}/>
                </StepsContainer>
                <PrestationSupp tunnelType={tunnelType} updateSearchParams />
                <NikeEquipment
                    tunnelType={tunnelType}
                    theme={theme}
                    updateSearchParams={equipment => {
                        addSearchParams("equipmentId", equipment?.length ? equipment[0]?.id : "none")
                        dispatch(nextStep())
                    }}
                />
                <OrderSummary tunnelType={tunnelType}/>
            </StepManager>
        </React.Fragment>
    )
}

TeamSteps.propTypes = {
    tunnelType: string,
}

export default TeamSteps
