import classNames from "classnames"
import { bool, object, oneOfType, string } from "prop-types"

export default function CenterLeftComponent({ customCls, currentResponse }) {
    return (
        <div className={classNames("centerLeft", {
            [customCls]: customCls,
        })}>
            <div className="name">{currentResponse.contact.firstname} {currentResponse.contact.lastname.charAt(0)}.</div>

            {
                currentResponse.contact.supersubPlayed === 0 ?
                    (
                        <div className="noMatchPlayed">
                        Nouveau SuperSub
                        </div>
                    ) : (
                        <div className="bottomCenter">
                            <div className="rating">
                                <div className="round-rating">{currentResponse.contact.supersubRating ? currentResponse.contact.supersubRating : "-"}</div>
                                <div className="rating-label">Note</div>
                            </div>
                            <div className="right">
                                <div className="matchPlayed">
                                    <div className="nb">{currentResponse.contact.supersubPlayed ? currentResponse.contact.supersubPlayed : 1}</div>
                                    <div className="matchPlayed-label">Match{currentResponse.contact.supersubPlayed > 1 ? "s" : ""} joué{currentResponse.contact.supersubPlayed > 1 ? "s" : ""}</div>
                                </div>
                            </div>
            
                        </div>
                    )
            }
        </div>
    )
}

CenterLeftComponent.propTypes = {
    currentResponse: object,
    customCls: oneOfType([ bool, string ]),
}
