import { bool, object, oneOfType , string } from "prop-types"
import { useEffect, useState } from "react"

import useMediaPath from "../../hooks/useMediaPath"
import dayValues from "../../model/enums/dayValues"
import supersubLevels from "../../model/enums/superSubLevels"
import { getTypeName } from "../../pages/reservation/tunnelReservation/function"
import formatTwoDigits from "../../services/formatTwoDigits"
import playersNumber from "../reservation/LocationBlock/playersNumber"
import LevelStars from "../SuperSub/SuperSubRequest/LevelStars"
import HourDisplay from "../Time/HourDisplay"

import "./LocationInfoBar.scss"

function LocationInfoBar({ location, displaySelectSuperSubData, tunnelType }) {

    const numberOfPlayers = playersNumber
    const mediaPath = useMediaPath()
    
    const [ players, setPlayers ] = useState(null)

    const { centerName, resourceType, resourceTypeDisplay, start, duration, isOwner, organizer, discount, price } = location ? location : {}
    const realPrice = discount ? price - discount.discount : price

    let startDate, startDataString, hour
    if (start) {
        startDate = new Date(start)
        startDataString = dayValues[
            startDate.getDay()
        ].substring(0, 3) + " " +
            formatTwoDigits(
                startDate.getDate(),
            ) + "/" +
            formatTwoDigits(
                startDate.getMonth() + 1,
            )
        
        hour = <HourDisplay date={startDate} displayZero={true}/>
    }

    let supRequest
    if (tunnelType === "SUPERSUB") {
        supRequest = location.supersubRequests && location.supersubRequests.length - 1 >= 0 ? location.supersubRequests[location.supersubRequests.length - 1] : {}
    }

    useEffect(() => {
        if (location && (location.resourceType || location.resourceType === 0)) {
            const correspondingPlayers = numberOfPlayers.find(type => type.types.includes(location.resourceType))
            if (correspondingPlayers) {
                setPlayers(correspondingPlayers.players)
            }
        }
    }, [ location ])

    return (
        <div className="o-location-info-bar">
            <div className="heading">
                <h2>
                    Récapitulatif
                </h2>
                {!isOwner && organizer && (
                    <em>
                        Match géré par&nbsp;
                        <strong>
                            <span>{organizer.firstname}</span><span>{" " + organizer.lastname[0] + "."}</span>
                        </strong>
                    </em>
                )}
            </div>
            <div className="data-part">
                <ul className="line">
                    <li className="centerInfo1">
                        <img src={mediaPath([ "/assets/icons/center-orange.svg" , "/assets/images/padel/icons/icon-center.svg" ])}/>
                        <span>{ centerName }</span>
                    </li>

                    <li className="centerInfo2">
                        <img src={mediaPath([ "/assets/icons/terrain-orange.svg" , "/assets/images/padel/icons/icon-terrain.svg" ])}/>
                        <span>{getTypeName(resourceType, resourceTypeDisplay)}</span>
                    </li>

                    <li className="centerInfo3">
                        <img src={mediaPath([ "/assets/icons/calendar-orange.svg" , "/assets/images/padel/icons/icon-calendar-green.svg" ])}/>
                        <span>{ startDataString }</span>
                    </li>

                    <li className="centerInfo4">
                        <img src={mediaPath([ "/assets/icons/heure-orange.svg" , "/assets/images/padel/icons/icon-heure.svg" ])}/>
                        <span>{ hour }</span>
                    </li>

                    <li className={
                        price > 0 && tunnelType === "BOOKING" ?
                            "centerInfo5"
                            :
                            price === 0 ?
                                "centerInfo6"
                                :
                                ""
                    }>
                        <img src={mediaPath([ "/assets/icons/chrono-orange.svg", "/assets/images/padel/icons/icon-oclock.svg" ])}/>
                        { duration && 
                            <span>{ duration.replace("45 min", "0h45").replace(/\s|(?!h)[a-z]+( )?/g, "")}</span>
                        }
                    </li>
                    
                    {
                        tunnelType === "BOOKING" && price > 0 && (
                            <li className="playerPrice centerInfo6">
                                <img src={mediaPath([ "/assets/icons/euroSymbol.svg" , "/assets/images/padel/icons/icon-euro.svg" ])}/>
                                <span>
                                    {players ?
                                        location.pricePerPart ? location.pricePerPart :
                                            realPrice % players === 0 ?
                                                realPrice / players
                                                :
                                                (realPrice / players).toFixed(2)
                                        :
                                        location.supersubPlayerPrice
                                    }&euro; par joueur<br />
                                    <i>soit {realPrice % 1 === 0 ? realPrice : realPrice.toFixed(2)}&euro; au total</i>
                                </span>

                            </li>
                        )                               
                    }
                </ul>

                {
                    displaySelectSuperSubData && supRequest &&
                        (<ul className="line">
                            <li>
                                <img src="/assets/icons/player.svg"/>
                                <span>{ supRequest.askedPlayers } { supRequest.askedPlayers > 1 ? "SuperSubs recherchés" : "SuperSub recherché"} </span>
                            </li>

                            <li className="stars-span">
                                <LevelStars level={supRequest.level} isStrokeStars />
                                <span className="spanLevel">{ supersubLevels[supRequest.level] }</span>
                            </li>

                            <li>
                                <img src="/assets/icons/euro.svg"/>
                                <span>{ supRequest.price } &euro;&nbsp;/&nbsp;SuperSub</span>
                            </li>

                        </ul>)
                }
            </div>
        </div>
    )
}

LocationInfoBar.propTypes = {
    displaySelectSuperSubData: bool,
    location: object,
    subscription: oneOfType([ bool, object ]),
    tunnelType: string,
}

export default LocationInfoBar
