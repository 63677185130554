import { disableBodyScroll } from "body-scroll-lock-upgrade"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types"
import { useEffect, useId, useRef, useState } from "react"
import ReactDOM from "react-dom"

import "./Modal.scss"

const modalVariants = {
    closed: {
        opacity: 0,
        scale: 0,
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        },
    },
    open: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        },
    },
}

const overlayVariants = {
    closed: {
        opacity: 0,
        pointerEvents: "none",
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        },
    },
    open: {
        opacity: 1,
        pointerEvents: "auto",
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        },
    },
}

const Modal = ({
    identifier,
    isOpen,
    onClose = () => {},
    beforeClose,
    afterClose,
    afterOpen,
    closeOnEsc,
    withOverlay,
    exitOnOverlayClicked,
    onOverlayClick,
    className,
    children,
    closeButton,
    withPortal,
    portalIdentifier,
    lockScroll,
}) => {
    const [ isDisplayed, setIsDisplayed ] = useState(isOpen)
    const [ isClosing, setIsClosing ] = useState(false)

    const id = useId()
    const modalContentRef = useRef(null)

    useEffect(() => {
        if (!isOpen && isClosing) {
            const timer = setTimeout(() => {
                onClose()
                setIsClosing(false)
                setIsDisplayed(false)
                afterClose?.()
            }, 200)
            return () => clearTimeout(timer)
        } else {
            setIsDisplayed(isOpen)
        }
    }, [ isOpen, isClosing, onClose, afterClose ])

    useEffect(() => {
        if (isOpen) {
            setIsClosing(false)
            setIsDisplayed(true)
            afterOpen?.()

            if (lockScroll) {
                if (typeof lockScroll === "string") {
                    const element = document.querySelector(lockScroll)
                    if (element) {
                        disableBodyScroll(element)
                    }
                } else {
                    disableBodyScroll(modalContentRef.current)
                }
            }

        }
    }, [ isOpen, afterOpen ])

    const handleClose = () => {
        setIsClosing(true)
        beforeClose?.()
    }

    const handleOverlayClick = () => {
        if (exitOnOverlayClicked) {
            handleClose()
        }
        onOverlayClick?.()
    }

    const handleEscPress = (e) => {
        if (closeOnEsc && e.key === "Escape") {
            handleClose()
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleEscPress)
        return () => document.removeEventListener("keydown", handleEscPress)
    }, [ closeOnEsc ])

    const renderCloseButton = () => {
        if (closeButton === true && !!onClose) {
            return <a className="modal-close-button" data-testid="closeButton" onClick={handleClose}><span>&times;</span></a>
        } else if (closeButton) {
            return closeButton
        } else {
            return null
        }
    }

    const renderModal = () => (
        <AnimatePresence>
            {isDisplayed && (
                <div>
                    {withOverlay && (
                        <motion.div
                            variants={overlayVariants}
                            animate={isClosing ? "closed" : "open"}
                            initial="closed"
                            exit="closed"
                            key={id + "-overlay"}
                            onClick={() => {
                                handleOverlayClick()
                            }}
                            className="modal-overlay"
                        />
                    )}
                    <motion.div
                        variants={modalVariants}
                        animate={isClosing ? "closed" : "open"}
                        initial="closed"
                        exit="closed"
                        className={classNames("modal", className)}
                        id={identifier}
                        key={id}
                        onAnimationComplete={() => {
                            if (isClosing) {
                                setIsDisplayed(false) // Ensure modal is no longer displayed after animation completes
                                onClose() // Call onClose after the animation completes
                                afterClose?.()
                            }
                        }}
                    >
                        <div
                            className={"modal-content"}
                            ref={modalContentRef}
                            key={id + "-content"}
                        >
                            {renderCloseButton()}
                            {children}
                        </div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    )

    if (withPortal) {
        const portalElement = portalIdentifier ? document.querySelector(portalIdentifier) : document.body
        return ReactDOM.createPortal(
            renderModal(),
            portalElement || document.body,
        )
    }

    return renderModal()
}

Modal.defaultProps = {
    closeButton: true,
    closeOnEsc: true,
    exitOnOverlayClicked: true,
    isOpen: false,
    portalIdentifier: null,
    withOverlay: true,
    withPortal: false,
}

Modal.propTypes = {
    afterClose: PropTypes.func,
    afterOpen: PropTypes.func,
    beforeClose: PropTypes.func,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    closeButton: PropTypes.oneOfType([ PropTypes.bool, PropTypes.node ]),
    closeOnEsc: PropTypes.bool,
    disableScroll: PropTypes.bool,
    exitOnOverlayClicked: PropTypes.bool,
    identifier: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    lockScroll: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
    onClose: PropTypes.func,
    onOverlayClick: PropTypes.func,
    portalIdentifier: PropTypes.string,
    withOverlay: PropTypes.bool,
    withPortal: PropTypes.bool,
}

export default Modal
