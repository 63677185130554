import { bool, func, number, object, oneOfType, string } from "prop-types"
import React, { useEffect, useState } from "react"

import { getSuperSubResponse, rateSupersubReponse } from "./api"
import CenterLeftComponent from "./CenterLeftComponent"
import Preloader from "../../../entries/main/../../components/loaders/preloader/preloader"
import { createFriendshipInvitationFromEmail } from "../../../globalAPI/api"
import useThemeHeader from "../../../hooks/useThemeHeader"
import getBaseUrl from "../../../services/getBaseUrl"
import Button from "../../Buttons/button/button"
import CheckBlock from "../../Forms/CheckBlock/CheckBlock"
import TextArea from "../../Forms/textArea/TextArea"
import RatingComponent from "../RatingComponent/RatingComponent"

import "./SupersubRatingCheckBloc.scss"

function SupersubRatingCheckBloc({ response, checked, id, clickOnCheckblock, areFriends }) {

    /* VARIABLES */
    const [ loading, setLoading ] = useState(false)
    const [ currentReponse, setCurrentReponse ] = useState(response ? response : {})
    const [ supersubNotCome, setSupersubNotCome ] = useState(false)
    const [ applyFade, setApplyFade ] = useState(false)
    const [ displayNotifLabel, setDisplayNotifLabel ] = useState(false)
    const [ comment, setComment ] = useState("")
    const [ note, setNote ] = useState(currentReponse?.rating ? currentReponse.rating :  null)
    const [ displayContactNumber, setDisplayContactNumber ] = useState(false)
    const [ addToFriendClicked, setAddToFriendClicked ] = useState(false)

    const customCls = currentReponse?.rating !== null ? "responseWithRating" : null
    const themeHeader = useThemeHeader()

    /* EFFECTS */
    useEffect(
        () => {
        }, [],
    )

    /* FUNCTIONS */
    function validate() {
        setLoading(true)
        /* istanbul ignore next */
        rateSupersubReponse(currentReponse.id, note, comment, supersubNotCome ? supersubNotCome : null).then(
            () => {
                refreshResponse()
            },
        )
    }

    function refreshResponse() {

        getSuperSubResponse(currentReponse.id).then((res) => {
            setCurrentReponse(res)
            setLoading(false)
            setDisplayNotifLabel("Ta note a bien été prise en compte ")

            setTimeout(() => {
                setApplyFade(true) 
            }, 9000)
            
            setTimeout(() => {
                setApplyFade(false)
                setDisplayNotifLabel(false) 
            }, 10000)
        })
    }

    function addToFriendList() {
        setAddToFriendClicked(true)
        setLoading(true)
        createFriendshipInvitationFromEmail(
            currentReponse.contact.emailAddress,
            getBaseUrl() + "/", // add / for mobile app purpose
            null,
            themeHeader,
        ).then(
            () => {
                setLoading(false)
            },
        )
    }

    function contact() {
        setDisplayContactNumber(true)
    }

    return (
        <React.Fragment>
            { loading &&
                /* istanbul ignore next */
                <Preloader fixed={true}/>
            }

            <div className="ratingCheckBlocParent">
                <CheckBlock
                    supersubNotCome={supersubNotCome}
                    callback={() => {
                        if (clickOnCheckblock) {
                            clickOnCheckblock(response)
                        }
                    }}
                    checked={checked && !currentReponse.rating}
                    key={id}
                    name={id}
                    value={id}
                    cls="ratingCheckBloc"
                >
                    { response && (
                        <div className={customCls ?  "ratingCheckBlocContent " + customCls : "ratingCheckBlocContent"}>

                            <div className={response.isRatingActivated ? "firstLine" : "firstLine rating-deactivated"}>
                                <div className="left">
                                    <img src={currentReponse?.contact?.pictureUrl ? currentReponse.contact.pictureUrl :  "/assets/images/user-placeholder-gray.png"} className="picture hide-sm"/> 
                                </div>
                                <div className="center">
                                    <div className="mobileTopInfo show-sm">
                                        <img src={currentReponse?.contact?.pictureUrl ? currentReponse.contact.pictureUrl :  "/assets/images/user-placeholder-gray.png"} className="picture"/> 
                                        <CenterLeftComponent currentResponse={currentReponse} />
                                    </div>
                                    <CenterLeftComponent customCls="hide-sm" currentResponse={currentReponse} />
                                
                                    { (response.isRatingActivated && (
                                        <RatingComponent
                                            supersubNotCome = {supersubNotCome}
                                            name={"rate-supersub-" + currentReponse?.contact?.id}
                                            label="NOTE :"
                                            initialValue={currentReponse?.rating}
                                            disabled={currentReponse?.rating !== null || supersubNotCome}
                                            customMessage={displayNotifLabel}
                                            updateRatingCallback={(val) => {setNote(val)}}
                                            customLabelCls={applyFade ? "ratingLabelFade" : null}
                                        />
                                    )) || (!response.isRatingActivated && (
                                        <div className="impossible-rating">
                                            Notation impossible
                                        </div>
                                    ))}

                                    { currentReponse?.rating !== null ? (
                                        <div className="sideBtns">
                                            <Button
                                                customCls={addToFriendClicked ? "clicked" : ""}
                                                text={
                                                    areFriends ? 
                                                        "Tu es déjà ami(e)s"
                                                        : addToFriendClicked ?
                                                            "Demande d'ami envoyée"
                                                            : "Ajouter à la liste des amis"
                                                }
                                                color="grey"
                                                disabled={areFriends}
                                                onClick={addToFriendClicked ? undefined : addToFriendList}
                                            />
                                            <Button
                                                color="grey" 
                                                onClick={contact}
                                                text={
                                                    displayContactNumber ? (
                                                        <React.Fragment>
                                                            <a className="show-sm contactPhoneNumber" href={"tel:" + currentReponse?.contact?.phoneNumber}>{currentReponse?.contact?.phoneNumber}</a>
                                                            <span className="hide-sm">{currentReponse?.contact?.phoneNumber}</span>
                                                        </React.Fragment>)
                                                        :
                                                        <span>Contacter</span>
                                            
                                                }
                                            />
                                        </div>
                                    ) : response.isRatingActivated && (
                                        <div className="c-inputHolder--radioSquare">
                                            <input
                                                type="checkbox" id={"supersubNotCome-" + currentReponse?.contact?.id}
                                                name="supersubNotCome" value={supersubNotCome}
                                                data-testid="ratingInput"
                                                onChange={
                                                    () => {
                                                        const newValue = !supersubNotCome
                                                        setSupersubNotCome(newValue)
                                                    }
                                                }
                                                checked={supersubNotCome}
                                                onClick={e => e?.stopPropagation()}
                                            />
                                            <label htmlFor={"supersubNotCome-" + currentReponse?.contact?.id}>Le SuperSub n’est pas venu</label>
                                        </div>
                                    )}
                                </div>
                            </div>

                            { response?.isRatingActivated && currentReponse?.rating === null && (
                                <div className="secondLine">
                                    <TextArea
                                        isRequired={!supersubNotCome && note <= 3}
                                        isEmpty={comment.length === 0}
                                        disabled={supersubNotCome}
                                        labelText="Commentaire"
                                        maxLength="500"
                                        onChange={setComment}
                                        value={comment}
                                        onClick={e => e?.stopPropagation()}
                                    />
                                    <button
                                        className="btn btn--orange"
                                        disabled={
                                            (!supersubNotCome && note <= 3 && comment.length < 3)
                                            || (!note && !supersubNotCome)
                                        }
                                        onClick={validate}
                                        data-testid="validateButton"
                                    >
                                        <span>Valider</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </CheckBlock>
            </div>
        </React.Fragment>
    )
}

SupersubRatingCheckBloc.propTypes = {
    areFriends: bool,
    checked: bool,
    clickOnCheckblock: func,
    id: oneOfType([ string, number ]),
    response: object,
}

export default SupersubRatingCheckBloc
