import { func } from "prop-types"

import Modal from "../../../../../components/Modal/Modal"
import SelectUserFriends from "../../../../../components/user/selectUserFriends/selectUserFriends"
import ModalHandler from "../../../../../providers/Modal/ModalHandler"

import "./FriendsPopin.scss"

const FriendsPopin = ModalHandler.create(({ callback, visible, hide }) => {
    return (
        <Modal
            className="friendsAddPopin"
            isOpen={visible}
            onClose={hide}
        >
            <SelectUserFriends
                emptyInListing
                onlyOne
                emptyText="Tu n'as pas encore de coéquipiers"
                callback={(user) => {
                    callback(user)
                    hide()
                }}
            />
        </Modal>
    )
})

FriendsPopin.displayName = "FriendsPopin"

FriendsPopin.propTypes = {
    callback: func,
}

export default FriendsPopin
