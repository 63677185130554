import PropTypes from "prop-types"
import { components } from "react-select"

export const CustomIndicatorsContainer = props => {
    const { children, selectProps, isDisabled } = props

    return (
        <components.IndicatorsContainer
            {...props}
            innerProps={{
                ...props.innerProps,
            }}
        >
            {children}
            {!isDisabled && selectProps.isMulti && (
                <div/>
            )}
        </components.IndicatorsContainer>
    )
}

CustomIndicatorsContainer.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    isDisabled: PropTypes.bool,
    selectProps: PropTypes.shape({
        isMulti: PropTypes.bool,
    }),
}
