import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"

import MapToggle from "../../../../../components/Buttons/mapToggle/mapToggle"
import InputDate from "../../../../../components/Forms/InputDate/InputDate"
import { SelectInput } from "../../../../../components/Inputs/Select/SelectInput.jsx"
import { formArgs } from "../services/function"
import { useBookingStore } from "../services/store"

import "./NewBookingParams.scss"

dayjs.extend(customParseFormat)

export default function NewBookingParams() {
    const { control, setValue, watch } = useFormContext()
    const { bookingInfosData } = useBookingStore()

    const currentCenterId = watch(formArgs.CENTER_ID)
    const currentActivityId = watch(formArgs.ACTIVITY_ID)
    const activeTypes = watch(formArgs.TYPE)

    useEffect(() => {
        // CHANGE CURRENT ACTIVITY ID IF IT DOESN'T EXIST IN NEW CENTER ACTIVITIES,  ALSO RESET TYPE FILTER
        if (bookingInfosData?.centerOptions?.length && currentCenterId) {
            const centerActivities = bookingInfosData?.centerActivities
            if (centerActivities?.length > 0 && !centerActivities.find(activity => activity.id === parseInt(currentActivityId))) {
                setValue(formArgs.ACTIVITY_ID, centerActivities[0].id)
            }
        }
    }, [ currentCenterId, bookingInfosData ])

    useEffect(() => {
        if (bookingInfosData?.centersOptions) {
            const types = bookingInfosData?.centerOptions?.find(
                center => center[0] === parseInt(currentCenterId),
            )?.types?.filter(
                type => type?.categories[0]?.id === parseInt(currentActivityId),
            )

            if (currentActivityId && currentCenterId && types?.length > 0 && activeTypes?.length) {
                const newTypes = activeTypes?.filter(type => {
                    return types?.find(
                        centerType => {
                            return centerType.key === type?.[0]
                        },
                    )
                })

                setValue(formArgs.TYPE_IDS, newTypes.map(type => type[0]) ?? [])
                setValue(formArgs.TYPE, newTypes)
            } else {
                setValue(formArgs.TYPE_IDS, [])
                setValue(formArgs.TYPE, [])
            }
        }
    }, [ currentActivityId ])

    return (
        <div className={"mainWrapper"}>
            <div className="bookingParams">
                <div className="inputField center">
                    <img alt="" src="/assets/icons/center-black.svg" className="paramLogo" />
                    <Controller
                        control={control}
                        render={
                            ({ field: { onChange, value } }) => (
                                <SelectInput
                                    label="Centre"
                                    id="reservationCenter"
                                    options={bookingInfosData?.centerOptions?.map(center => ({ label: center[1], value: center[0] }))}
                                    customRootCls="center"
                                    customCls="reservationSelect center"
                                    onChange={onChange}
                                    value={value ?? ""}
                                    required
                                    placeholderOption="Choisir un centre"
                                    hasAfterSelect={(
                                        <MapToggle
                                            headerTitle="Choix du centre"
                                            markersData={bookingInfosData?.centerOptions?.map(center => ({ label: center[1], value: center[0], ...center }))}
                                            cls="map"
                                            closeDialogCallBack={onChange}
                                        />
                                    )}
                                    customArrow={[ "url('/assets/icons/arrow-down-grey.svg')", "url('/assets/icons/arrow-down-grey.svg')" ]}
                                />
                            )}
                        rules={{ required: true }}
                        name={formArgs.CENTER_ID}/>
                </div>
                <div className="inputField activity">
                    <img alt={""} src="/assets/icons/terrain-black.svg" className="paramLogo" />
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <SelectInput
                                label="Activité"
                                id="reservationActivities"
                                options={bookingInfosData?.centerActivities?.map(activity => ({ label: activity.name, value: activity.id })) ?? []}
                                customRootCls="activity"
                                customCls="reservationSelect activity"
                                required
                                disabled={!bookingInfosData?.centerActivities?.length}
                                placeholderOption="Choisir une activité"
                                customArrow={[ "url('/assets/icons/arrow-down-grey.svg')", "url('/assets/icons/arrow-down-grey.svg')" ]}
                                onChange={onChange}
                                value={value ?? ""}
                            />
                        )}
                        rules={{ required: true }}
                        name={formArgs.ACTIVITY_ID}
                    />
                </div>

                <div className="inputField date">
                    <img alt={""} src="/assets/icons/calendar-black.svg" className="calendar"/>
                    <Controller
                        control={control}
                        render={
                            ({ field: { onChange, value } }) => {
                                return (
                                    <InputDate
                                        customIcon="url('/assets/icons/arrow-down-grey.svg')"
                                        label="Date"
                                        bind={[ new Date(value), onChange ]}
                                        min={new Date()}
                                        useDatePicker
                                        customClass="customDayPicker reservationSelect date"
                                        placeholder="Date réservation"
                                        required
                                    />
                                )
                            }}
                        rules={{ required: true }}
                        name={formArgs.DATE}/>
                </div>
            </div>
        </div>
    )
}

NewBookingParams.propTypes = {
    bookingInfosData: PropTypes.shape({
        centerActivities: PropTypes.array,
        centerOptions: PropTypes.array,
    }),
}
