import { arrayOf, bool, func, node, number, oneOfType, string } from "prop-types"
import { useState } from "react"

import Preloader from "../../../entries/main/../../components/loaders/preloader/preloader"
import { useTheme } from "../../ThemeContext/ThemeContext"

import "./CheckList.scss"

function CheckList({
    name,
    value,
    children,
    checkbox,
    checked,
    disabled = false,
    callback,
    cls,
    onDelete,
    unselect = false,
    action = false,
}) {
    const [ loading, setLoading ] = useState(false)
    const theme = useTheme()

    function handleInputClick(event) {
        if (callback) {
            callback(event, name)
        }
    }

    function handleDeletion() {
        if (onDelete) {
            onDelete(value)
            setLoading(true)
        }
    }

    return (
        <div
            className={"radioListe__item" + (disabled ? " disabled" : "") + (unselect && checked ? " unselect" : "")}
            key={value}
            name={name}
        >
            <input
                id={"checklist-" + value}
                type={checkbox ? "checkbox" : "radio"}
                checked={ !!checked }
                name={ name }
                onChange={()=>{}}
            />
            <label
                id={value}
                className={`${checked ? "CheckList no-hover" : "CheckList"}` + (cls ? ` ${cls}` : "")}
                htmlFor={"checklist" + value}
                onClick={(e) => handleInputClick(e, name)}
                name={name}
            >
                { children }
                { onDelete && (
                    <span className="deleteButton" onClick={handleDeletion}>
                        {loading ?
                            <Preloader/>
                            :
                            <img src="/assets/icons/cross-white.svg"/>
                        }
                    </span>
                )}
            </label>
            {theme === "theme-soccer" && unselect && checked && (
                <img src="/assets/icons/cross-orange-white.svg" className="unselect" onClick={action ? action : handleInputClick}/>
            )
            }
            {theme === "theme-padel" && unselect && checked && (
                <img src="/assets/images/padel/icons/icons-cross-black.svg" className="unselect" onClick={action ? action : handleInputClick}/>
            )
            }
        </div>
    )
}

CheckList.propTypes = {
    action: oneOfType([ func, bool ]),
    callback: func,
    checkbox: bool,
    checked: bool,
    children: oneOfType([ string, node, arrayOf(node) ]),
    cls: string,
    disabled: bool,
    name: oneOfType([ string, number ]),
    onDelete: func,
    unselect: bool,
    value: oneOfType([ string, number ]),
}

export default CheckList
