import { bool, func, number, string } from "prop-types"
import { useState } from "react"
import { useSelector } from "react-redux"

import useMediaPath from "../../../hooks/useMediaPath"
import { modifyFavoriteUniverse } from "../../../pages/profil/api"
import ButtonCta from "../../Buttons/button/button-cta"
import Preloader from "../../loaders/preloader/preloader.jsx"
import { useThemeUpdate } from "../../ThemeContext/ThemeContext"
import Stepper from "../Stepper"

export default function Step3({ onNextStep, theme, step, disableActions }) {
    const currentUser = useSelector(state => state.userLoaded)
    const mediaPath = useMediaPath()
    const [ selected, setSelected ] = useState(null)
    const setTheme = useThemeUpdate()

    return (
        <div className={"step3"}>
            <header><img className=""
                src={mediaPath([ "/assets/images/logo-white.svg", "/assets/images/padel/logo/logo-green.svg" ])}/>
            </header>

            <h4 className={"step3-title"}><span>SOCCER VS PADEL</span></h4>
            <h4 className={"step3-intro"}>quel type de joueur es-tu ?</h4>
            <div className={"step3-intro2"}>Plutôt raquette ou ballon ? Sélectionne le sport que tu pratiques le
                plus chez Urban !
            </div>
            <div className={"step3-content"}>
                <div className={"step3-input"}>
                    <button
                        className={`step3-input-soccer ${selected === "theme-soccer" ? "selected" : ""}`}
                        onClick={() => {
                            setSelected("theme-soccer")
                        }}>
                        <span className={"label"}>Soccer</span>
                        <img src="/assets/images/bordersoccernoopacity.svg"/>
                    </button>
                </div>
                <div className={"step3-input"}>
                    <button
                        className={`step3-input-padel ${selected === "theme-padel" ? "selected" : ""}`}
                        onClick={() => {
                            setSelected("theme-padel")
                        }}>
                        <span className={"label"}>Padel</span>
                        <img src="/assets/images/padel/backgrounds/firstConnectionPadel.svg"/>
                    </button>
                </div>
            </div>
            <div className={"change-step"}>
                {disableActions ? (
                    <Preloader small alt/>
                ) : (
                    <>
                        <ButtonCta
                            onClick={() => {
                                modifyFavoriteUniverse(currentUser, selected).then((theme) => {
                                    setTheme(theme)
                                    onNextStep(selected)
                                })
                            }}
                            text={
                                (
                                    <div className={"button-first-connection"}>
                                        <span>j'ai choisi</span>
                                        {theme === "theme-soccer" ? (
                                            <img src={mediaPath([ "/assets/icons/chevronsoccer.svg", "" ])}/>) : null}
                                    </div>
                                )
                            }
                            disabled={!selected || disableActions}
                        />
                    </>
                )}
            </div>
            <div className={"first-connection-stepper"}>
                <Stepper activeStep={step} enableStepFour={selected === "theme-padel"}/>
            </div>
            <footer>
                <img className=""
                    src={mediaPath([ "/assets/images/bordersoccer.svg", "/assets/images/padel/backgrounds/first-connection-footer.svg" ])}/>
            </footer>
        </div>
    )

}

Step3.propTypes = {
    disableActions: bool,
    onNextStep: func,
    step: number,
    theme: string,
}
