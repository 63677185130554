import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import SignUpPage from "./signUpPage/signUpPage"
import AuthBackgroundContent from "../../components/authentification/authBackgroundContent/authBackgroundContent"
import LoginComponent from "../../components/authentification/loginComponent/loginComponent"
import SignupComponent from "../../components/authentification/signupComponent/signupComponent"
import Footer from "../../components/layout/footer/footer"
import Preloader from "../../components/loaders/preloader/preloader"
import useAppRedirect from "../../hooks/useAppRedirect"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import { getCookie } from "../../services/cookies"
import UserAgentHandler from "../../services/UserAgentHandler"
import { toggleShowFooter } from "../../store/actions/scaffold"

import "./authentication.scss"

function Authentication() {
    
    const [ displayForgotPassword, setDisplayForgotPassword ] = useState(false)
    const [ displaySignupPage, setDisplaySignupPage ] = useState(false)
    const [ currentUrl ] = useState(window.location.href.replace(import.meta.env.VITE_URL_BASE, ""))
    const navigate = useNavigate()
    const appRedirect = useAppRedirect()
    const dispatch = useDispatch()

    const isAndroid = UserAgentHandler.isAndroid()
    const isIos = UserAgentHandler.isIOS()
    const isWebview = !!getCookie("webview")

    useEffect(
        () => {
            return () => dispatch(toggleShowFooter(true))
        }, [],
    )

    function displaySignupPageAction(isDisplay) {
        setDisplaySignupPage(isDisplay)
    }
    const { state } = useLocation()

    return (
        <ModalHandler.Provider>
            {state?.afterLoginUrl?.includes("notifToken=") ?
                <Preloader fixed="true" />
                : (
                    <div id="overlay-authentication" className="overlay-level3 no-pdg flex-column">
                        <div className="authenticationPage">
                            {
                                !displayForgotPassword && !displaySignupPage &&

                                    (<React.Fragment>
                                        <div className="loginDiv show-tablet">
                                            <LoginComponent forgotPasswordCallback={
                                                () => {
                                                    navigate("/forgotPassword")
                                                    setDisplayForgotPassword(true)
                                                }
                                            }
                                            redirectSignupCallback={
                                                () => {
                                                    setDisplaySignupPage(true)
                                                }
                                            }/>
                                        </div>
                                        <img alt="" src="/assets/images/padel/backgrounds/frieze-red-top-left.svg" className="redFrieze"/>

                                        <div
                                            className="loginDivMobile hide-tablet"
                                        >
                                            <AuthBackgroundContent customContent="true">
                                                {
                                                    (
                                                        <LoginComponent
                                                            forgotPasswordCallback={
                                                                () => {
                                                                    setDisplayForgotPassword(true)
                                                                }
                                                            }
                                                            redirectSignupCallback={
                                                                () => {
                                                                    setDisplaySignupPage(true)
                                                                }
                                                            }
                                                            forSignup={setDisplaySignupPage}
                                                            isMobile={true}
                                                        />
                                                    )
                                                }
                                            </AuthBackgroundContent>
                                        </div>

                                        <div className="signUpDiv show-tablet">
                                            <SignupComponent displaySignupPageAction={displaySignupPageAction}/>
                                        </div>
                                    </React.Fragment>)
                            }

                            {  (displaySignupPage || currentUrl === "/signup") &&
                                (<SignUpPage
                                    prevAction={() => {
                                        appRedirect("/login")
                                        setDisplaySignupPage(false)
                                    }}
                                >
                                </SignUpPage>)
                            }
                        </div>
                        <Footer
                            customFooterClass={classNames("desktopAuthFooter relative", {
                                hide: (isAndroid || isIos || isWebview),
                            })}
                        />
                        {!displayForgotPassword && !displaySignupPage && <img alt="" src="/assets/images/padel/backgrounds/frieze-yellow-bottom-left.svg" className="yellowFrieze" />}
                    </div>
                )}
        </ModalHandler.Provider>
    )

}

export default Authentication
