import { bool, func, object } from "prop-types"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import { SelectInput } from "../../../../../components/Inputs/Select/SelectInput.jsx"
import playersNumber from "../../../../../components/reservation/LocationBlock/playersNumber"
import getPartsSelect from "../../services/getPartsSelect"
import getPlayersSelect from "../../services/getPlayersSelect"

import "./PartsSelection.scss"

export default function PartsSelection({ callback, specialDiscountCalculation, disablePartSelection, checkPlayerNumber, fromPrebook = null }) {
    let prebook = fromPrebook ? fromPrebook : useSelector(state => state.forms.ChooseCenterActivityData.preReservation)
    const showSelectTypes = [ 0, 1, 8, 10, 13 ]
    const numberOfPlayers = playersNumber.find(type => type.types.includes(prebook.resourceType))?.players
    const [ searchParams ] = useSearchParams()

    const [ part, setPart ] = useState(searchParams.get("part") ?? 1)
    const [ playersOptions, setPlayersOptions ] = useState([])
    const [ displayPlayerNumber, setDisplayPlayerNumber ] = useState(false)
    const [ partOptions, setPartOptions ] = useState([])
    const [ typePlayers, setTypePLayers ] = useState("")
    const [ players, setPlayers ] = useState(searchParams.get("players") ?? typePlayers)

    useEffect(() => {
        if (prebook) {
            setPlayersOptions(getPlayersSelect(prebook))
            setTypePLayers(numberOfPlayers)
            setPlayers(searchParams.get("players") ?? numberOfPlayers)
            setDisplayPlayerNumber(showSelectTypes.includes(prebook?.resourceType))
        }
    }, [ prebook ])

    useEffect(() => {
        if (players) {
            setPartOptions(getPartsSelect(prebook, players, setPart, specialDiscountCalculation, part))
            callback?.({ players: parseInt(players) })
        }
    }, [ players, specialDiscountCalculation,prebook ])

    useEffect(() => {
        if (part) {
            callback?.({ part: parseInt(part) })
        }
    }, [ part ])

    useEffect(() => {
        if (disablePartSelection) {
            // set select part to 1 part
            setPart(1)
        }
    }, [ disablePartSelection ])

    useEffect(() => {
        if (checkPlayerNumber) {
            if (players < numberOfPlayers) {
                setPlayers(numberOfPlayers)
            }
        }
    }, [ checkPlayerNumber ])

    return (
        <div className="partsSelection">
            {prebook && displayPlayerNumber && (
                <div className="selectLine">
                    Nombre de joueurs
                    <SelectInput
                        id={"playersNumber"}
                        key={`playerNumbers-select-${checkPlayerNumber}`}
                        fullWidth
                        value={players}
                        onChange={(e) => setPlayers(e.target.value)}
                        required
                        customArrow={[ "url('/assets/icons/arrow-down-orange.svg')", "url('/assets/images/padel/icons/icon-arrow-down-red.svg')" ]}
                        options={playersOptions.map(x => ({ isDisabled: checkPlayerNumber && x[0] < numberOfPlayers, label: x[1], value: x[0] }))}
                    />
                </div>
            )}
            <div className="selectLine">
                Je règle
                <SelectInput
                    id={"partsNumber"}
                    fullWidth
                    value={part}
                    onChange={(e) => setPart(e.target.value)}
                    required
                    disabled={disablePartSelection}
                    customArrow={[ "url('/assets/icons/arrow-down-orange.svg')", "url('/assets/images/padel/icons/icon-arrow-down-red.svg')" ]}
                    options={partOptions.map(x => ({ label: x[1], value: x[0] }))}
                />
            </div>
        </div>
    )
}

PartsSelection.propTypes = {
    callback: func,
    checkPlayerNumber: bool,
    disablePartSelection: bool,
    fromPrebook: object,
    specialDiscountCalculation: bool,
}
