import mustChangeTeamsNames from "./mustChangeTeamsNames.js"
import alphabeticalPlayerSort from "./Sorting/alphabeticalPlayerSort"
import APP_CONFIG from "../business/config"
import {
    getPlayersComposition,
    registerLocationTeamsNames,
} from "../pages/reservation/infoReservation/LocationCompositionManagement/api.js"

export const processPlayersCompositionResponse = (res, themeHeader, setLoadingFunction, setLocation, oldLoc) => {
    const data = res?.data.data
    getPlayersComposition(data.id, { activity: themeHeader }).then(
        (resCompo) => {
            const loc = resCompo?.location
            if (loc?.id) {
                const presentsInvites = loc?.invites?.filter(player => player.status === 1).sort(alphabeticalPlayerSort)
                const filteredAttendees = presentsInvites.map(player => ({
                    ...player,
                    teamId: resCompo?.attendees?.find(attendee => player.id === attendee.id)?.teamId,
                    teamSlot: resCompo?.attendees?.find(attendee => player.id === attendee.id)?.teamSlot,
                }))

                if (themeHeader === 2) {
                    const { notCustomizedTeam1Name, notCustomizedTeam2Name } = mustChangeTeamsNames(oldLoc)
                    const { team1Name, team2Name } = getTeamNames(filteredAttendees)
                    const team1ToChange = (notCustomizedTeam1Name || team1Name === APP_CONFIG.padelDefaultTeamsNames.team1) ? team1Name : oldLoc.team1Name
                    const team2ToChange = (notCustomizedTeam2Name || team2Name === APP_CONFIG.padelDefaultTeamsNames.team2) ? team2Name : oldLoc.team2Name
                    data.team1Name = team1ToChange
                    data.team2Name = team2ToChange
                    data.team1CustomName = team1ToChange
                    data.team2CustomName = team2ToChange
                    registerLocationTeamsNames(loc.id, team1ToChange, team2ToChange, themeHeader)
                }
                data.composition = filteredAttendees
            }
            if (resCompo.goals) {
                data.goal = resCompo.goals
            }

            setLocation(data)
            setLoadingFunction?.(false)
        },
    ).catch(() => setLoadingFunction?.(false))
}

export const getTeamNames = (filteredAttendees) => {
    const team1Bis = filteredAttendees.filter(attendee => attendee.teamId === 1).sort(alphabeticalPlayerSort)
    const team2Bis = filteredAttendees.filter(attendee => attendee.teamId === 2).sort(alphabeticalPlayerSort)

    const team1Name = team1Bis?.length === 2 ? `${team1Bis[0].firstname}/${team1Bis[1].firstname}` : APP_CONFIG.padelDefaultTeamsNames.team1
    const team2Name = team2Bis?.length === 2 ? `${team2Bis[0].firstname}/${team2Bis[1].firstname}` : APP_CONFIG.padelDefaultTeamsNames.team2

    return { team1Name, team2Name }
}

export const hasUserAlreadyPaidWithGymlib = (userId, location) => {
    if (!location?.prepayments || !userId) {return false}

    return !!location.prepayments?.find(prepayment => prepayment.contact?.id === parseInt(userId) && prepayment.mean === APP_CONFIG.paymentTypes.GYMLIB)
}

export const hasUserAlreadyPaidWithAvoir = (userId, location) => {
    if (!location?.preCreditAllocations || !userId) {return false}

    return !!location.preCreditAllocations?.find(avoirPayment => avoirPayment.contact?.id === parseInt(userId))
}
