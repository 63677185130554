import * as actions from "../actions/actions"

const initialState = {
    currentAuthStepId: 1,
}

function auth(state = initialState, action) {
    switch (action.type) {
    case actions.GO_NEXT_AUTH_STEP:
        return Object.assign({}, state, {
            currentAuthStepId: state.currentAuthStepId + 1,
        })

    case actions.GO_PREV_AUTH_STEP:
        return Object.assign({}, state, {
            currentAuthStepId: state.currentAuthStepId > 1 ? state.currentAuthStepId - 1 : state.currentAuthStepId, 
        })
        
    case actions.RESET_AUTH_STEPPER: 
        return initialState
            
    default:
        return state
    }
}

export default auth
