
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import ButtonCta from "../../../components/Buttons/button/button-cta.jsx"
import { SelectInput } from "../../../components/Inputs/Select/SelectInput.jsx"
import GeneralRanking from "../../../components/league/GeneralRanking/GeneralRanking.jsx"
import { LeagueHeader } from "../../../components/league/LeagueHeader/LeagueHeader.jsx"
import ScorerRanking from "../../../components/league/ScorerRanking/ScorerRanking.jsx"
import Preloader from "../../../components/loaders/preloader/preloader.jsx"
import { useAssets } from "../../../providers/AssetsProvider.jsx"
import { sortArray } from "../../../utilities/helpers.js"
import { getCenter, getLeaguesByPeriodAndCenterId } from "../api/api.js"
import { getDayNumberFromDayLabel, getTeamsRanking } from "../service/service.js"
import { useLeagueStore } from "../store/useLeagueStore.js"

import "./OtherLeagues.scss"

export const OtherLeagues = () => {
    const  [ searchParams, setSearchParams ] = useSearchParams()
    const initialSelectedYear = searchParams?.get("year")
    const initialSelectedLeague = searchParams?.get("league")

    const [ selectedLeague, setSelectedLeague ] = useState(initialSelectedLeague)
    const [ autoFetch, setAutoFetch ] = useState(!!initialSelectedYear && !!initialSelectedLeague)
    const [ data, setData ] = useState({})
    const [ selectedYear, setSelectedYear ] = useState(initialSelectedYear)
    const [ isLoading, setIsLoading ] = useState({
        leagueList: false,
        stats: false,
    })
    const [ leagueOptions, setLeagueOptions ] = useState([])
    const user = useSelector((state) => state.userLoaded)
    const { userCenter, setUserCenter } = useLeagueStore()
    const { m } = useAssets()

    const yearOptions = Array.from({ length: 5 }, (_, i) => {
        const year = dayjs().year() - i
        return ({
            label: year,
            value: year,
        })
    })

    const updateUrlParams = (year, league) => {
        const params = {
            league,
            year,
        }
        if (!league) {
            delete params.league
        }
        setSearchParams(params)
    }

    useEffect(() => {
        if (!selectedYear) {return}

        const leagues = async () => {
            setIsLoading({
                leagueList: true,
                stats: autoFetch,
            })
            const start = dayjs().year(parseInt(selectedYear)).startOf("year").format("YYYY-MM-DDTHH:mm:ss")
            const leagues = await getLeaguesByPeriodAndCenterId(user?.centerId, start)

            setLeagueOptions(sortArray(leagues?.items?.map((league) => ({
                dayNumber: getDayNumberFromDayLabel(league.day),
                label: league.name,
                start: dayjs(league.start),
                value: league.id,
            })), { key: "dayNumber", order: "ASC" }, { key: "start", order: "ASC" }))
        }

        leagues().finally(() => setIsLoading({
            leagueList: false,
            stats: autoFetch,
        }))

        updateUrlParams(selectedYear, selectedLeague)
    }, [ selectedYear ])

    useEffect(() => {
        if (selectedLeague) {
            updateUrlParams(selectedYear, selectedLeague)
        }
    }, [ selectedLeague ])

    useEffect(() => {
        !!user?.centerId && (userCenter?.id !== user?.centerId) && getCenter(user?.centerId).then((center) => {
            setUserCenter(center)
        })
    }, [ user ])

    useEffect(() => {
        if (!yearOptions.find((option) => option.value === parseInt(initialSelectedYear))) {
            setSelectedYear(null)
            setSearchParams({})
            return
        }

        if (initialSelectedYear && initialSelectedLeague && autoFetch) {
            fetchLeagueRanking().then(() => setAutoFetch(false))
        }
    }, [ initialSelectedYear, initialSelectedLeague ])

    const fetchLeagueRanking = async () => {
        if (
            selectedLeague && selectedYear
        ) {
            setIsLoading({
                leagueList: autoFetch,
                stats: true,
            })
            const ranking  = await getTeamsRanking(selectedLeague, {
                selectedLeague: selectedLeague,
            })
            setData({
                ...ranking,
                currentLeague: selectedLeague,
                currentYear: selectedYear,
            })
            setIsLoading({
                leagueList: false,
                stats: false,
            })
        }
    }

    return (
        <>
            <LeagueHeader
                title={"Autres leagues"}
                cls={"thunder-ornament"}
            >
            </LeagueHeader>
            {!autoFetch && (
                <div className={"other-leagues-header-wrapper"}>
                    <div className={"c-row justify-center other-leagues"}>
                        <div className="c-col c-col--10 c-col--sm--12 no-padding">
                            <div className={"other-leagues-header"}>
                                <div className={"other-leagues-header-text"}>
                                    {userCenter?.name && userCenter?.id === user?.centerId ? (
                                        <>
                                            <h3>Sélectionne la league de ton choix au sein du centre</h3>
                                            <h3><img alt="icone centre"
                                                src={m("icons.league.center")}/>{userCenter?.name}
                                            </h3>
                                        </>
                                    ) : <Preloader small/>}
                                </div>
                                <div className={"other-leagues-header-form"}>
                                    <SelectInput
                                        fullWidth
                                        placeholderOption={"Choisis une année"}
                                        options={yearOptions}
                                        value={selectedYear ?? ""}
                                        label="Année de la league"
                                        disabled={isLoading?.stats}
                                        onChange={(e) => {
                                            setSelectedLeague(null)
                                            setSelectedYear(e.target.value)
                                        }}
                                    />
                                    <SelectInput
                                        fullWidth
                                        placeholderOption={leagueOptions.length ? "Choisis une league" : "Aucune league disponible"}
                                        value={selectedLeague ?? ""}
                                        options={leagueOptions}
                                        loading={isLoading?.leagueList}
                                        disabled={isLoading?.leagueList || isLoading?.stats || !leagueOptions.length}
                                        label="League de ton choix"
                                        onChange={(e) => setSelectedLeague(e.target.value)}
                                    />
                                    <ButtonCta
                                        text={"Rechercher"}
                                        isAlt
                                        onClick={() => {
                                            setData({})
                                            fetchLeagueRanking()
                                        }}
                                        disabled={!selectedLeague || !selectedYear || isLoading?.stats || (selectedLeague === data?.currentLeague && selectedYear === data?.currentYear)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className={"c-row justify-center league-layout other-leagues-content"}>
                <div className="c-col c-col--10 c-col--sm--12 no-padding">
                    {!isLoading?.stats && !data?.ranking?.length && !data?.scorers?.length && (
                        <div className={"no-league-data"}>
                            <p>{!selectedLeague || !data?.currentLeague ? "Choisis une league pour voir les statistiques." : "Aucune donnée pour la league sélectionnée."}</p>
                        </div>
                    )}
                    <GeneralRanking loading={isLoading?.stats} data={data?.ranking}/>
                    {isLoading?.stats && <Preloader/>}
                    <ScorerRanking loading={isLoading?.stats} data={data?.scorers}/>
                </div>
            </div>
        </>
    )
}
