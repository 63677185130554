import { useSelector } from "react-redux"

import genders from "../../model/enums/User/genders"
import { PadelLevelArray } from "../../model/enums/User/PadelLevel"
import PadelLevels from "../../pages/profil/PadelLevels"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import Form from "../Forms/Form/Form"
import InputDate from "../Forms/InputDate/InputDate"
import { SelectInput } from "../Inputs/Select/SelectInput.jsx"
import { TextInput } from "../Inputs/TextInput/TextInput.jsx"
import { useSchoolTrainingCurrent } from "../SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"

import "./AdultForm.scss"

export default function AdultForm() {
    const user = useSelector(state => state.userLoaded)
    const { firstname, lastname } = user || {}
    const { value: { adultForm }, setValue: setCurrentSelection } = useSchoolTrainingCurrent() || {}

    return (
        <div className="formsHolder c-row justify-center align-start layoutSmall adultForm">
            <div className="c-col c-col--12 c-col--sm--12 no-padding" id="child-form">
                <Form
                    title={firstname && lastname && (
                        <>
                            {firstname} {lastname.charAt(0)}.
                        </>
                    )}
                    desc={"Vérifie tes informations ci-dessous"}
                >
                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <TextInput
                            label="Prénom"
                            value={firstname}
                            required
                            disabled
                        />
                    </div>

                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <TextInput
                            label="Nom"
                            value={lastname}
                            required
                            disabled
                        />
                    </div>
                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <InputDate
                            label={(
                                <span>
                                    Date de naissance<span className="label-star"> *</span>
                                </span>
                            )}
                            bind={[ adultForm.birthdate, (val => setCurrentSelection(prev => ({ ...prev, adultForm: { ...prev.adultForm, birthdate: val } }))) ]} 
                            max={new Date()}
                            useDatePicker={false}
                            customClass="customDayPicker"
                            placeholder="JJ/MM/AAAA"
                            checkValue
                            required
                        />
                    </div>
                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <SelectInput
                            id={"gender"}
                            label={"Civilité"}
                            options={genders.map(gender => ({
                                label: gender[1],
                                value: gender[0],
                            }))}
                            required
                            value={adultForm.title}
                            onChange={(event)=>{setCurrentSelection(prev => ({ ...prev, adultForm: { ...prev.adultForm, title: event.target.value } }))}}
                            placeholderOption="Genre"
                        />
                    </div>
                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <SelectInput
                            id={"level"}
                            label={"Niveau"}
                            options={PadelLevelArray}
                            value={adultForm.level}
                            onChange={(event)=>{setCurrentSelection(prev => ({ ...prev, adultForm: { ...prev.adultForm, level: event.target.value } }))}}
                        />
                    </div>

                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <TextInput
                            label="Classement FFT"
                            type={"number"}
                            inputMode={"numeric"}
                            value={adultForm.ranking}
                            onChange={val => setCurrentSelection(prev => ({ ...prev, adultForm: { ...prev.adultForm, ranking: val } }))}
                        />

                    </div>
                    <div
                        className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1 showLevel"
                        onClick={() => ModalHandler.show(PadelLevels)}
                    >
                        Voir les niveaux
                    </div>
                </Form>
            </div>
        </div>
    )
}
