
import { preregisterBirthday } from "../../../kids/birthdayReservation/api"
import {
    preregisterSchoolSubscription,
} from "../../../kids/ecoleDeFoot/api"
import {
    preregisterStageSubscription,
} from "../../../kids/stageDeFoot/api"
import {
    preregisterLeagueSubscription,
} from "../../../league/api/api.js"
import {
    preregisterCup,
} from "../../../teams/urbanCup/api"

function getPreregistrationMethodFor(tunnelType) {
    const doNothing = () => {}
    switch (tunnelType) {
    case "BIRTHDAY":
        return preregisterBirthday

    case "SCHOOL":
        return preregisterSchoolSubscription
            
    case "CUP":
        return preregisterCup

    case "TRAINING":
        return preregisterStageSubscription

    case "LEAGUE":
        return preregisterLeagueSubscription

    case "SUPERSUB":
        return doNothing

    case "BOOKING":
        return doNothing

    default:
        // eslint-disable-next-line no-console
        console.error("usePreregistrationMethod - not implemented tunnelType : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        break
    }
}

export default getPreregistrationMethodFor
