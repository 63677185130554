import dayjs from "dayjs"
import { bool ,func, object, oneOfType, string } from "prop-types"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../../../../hooks/useMediaPath"

export default function NormalCancel({ freeCancelDate, cancellationMessage, cancelReservation, goToCreateSuperSub, location }) {
    const { tu } = useUrbanLanguage()
    const mediaPath = useMediaPath()

    if (!!location.end && dayjs(location.end).add(1, "day").isBefore(dayjs())) {
        return null
    }

    return (
        <div className="blocAnnuler">
            <div className="title">
                ANNULATION
                {cancellationMessage && (
                    <div className="annulationGratuite">
                        <p>{cancellationMessage}</p>
                    </div>
                )}
                {!cancellationMessage && (
                    <>
                        {
                            freeCancelDate ?
                                (<div className="annulationGratuite">
                                    <img src={mediaPath([ "/assets/icons/calendar-orange.svg" , "/assets/images/padel/icons/icon-calendar-green.svg" ])} className="calendarIcon" />
                                    <div className="freeCancel">
                                        <p>Annulation gratuite jusqu’au :</p>
                                        <strong>{freeCancelDate}</strong>
                                    </div>
                                </div>)
                                :
                                (<div className="annulationPayante">
                                    <img src={mediaPath([ "/assets/icons/calendar-orange.svg" , "/assets/images/padel/icons/icon-calendar-green.svg" ])} className="calendarIcon" />
                                    <p>
                                        La date d'annulation gratuite est dépassée.<br />
                                        L'acompte versé sera perdu en cas d'annulation.
                                    </p>
                                </div>)
                        }
                    </>
                )}
            </div>
            <div className="btnsDiv">
                <ButtonCta
                    text="Annuler ma réservation"
                    color="grey"
                    onClick={cancelReservation}
                    isCta2Alt
                />
                {location.isSupersubRequestAvailable &&
                    (<div className="missingPlayers" onClick={() => {
                        goToCreateSuperSub()
                    }} >
                        Il te manque des joueurs ? <span>{tu("booking.supersub.thinkAbout")}</span>
                    </div>)
                }
            </div>
        </div>
    )
}

NormalCancel.propTypes = {
    cancelReservation: func,
    cancellationMessage: string,
    freeCancelDate: oneOfType([ bool, string ]),
    goToCreateSuperSub: func,
    location: object,
}
