import { array, func, number, oneOfType, string } from "prop-types"
import React, { useState } from "react"
import { useDispatch, useStore } from "react-redux"
import { Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import useMediaPath from "../../../../hooks/useMediaPath"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import { addBirthdayCatalogArticle, removeBirthdayCatalogArticle } from "../../../../pages/kids/birthdayReservation/api"
import CreneauDispo from "../../../../pages/kids/birthdayReservation/steps/CreneauxDispo"
import { addSchoolProduct, deleteSchoolProduct } from "../../../../pages/kids/ecoleDeFoot/api"
import { addStageProduct, deleteStageProduct } from "../../../../pages/kids/stageDeFoot/api"
import { addLeagueProduct, deleteLeagueProduct } from "../../../../pages/league/api/api.js"
import { addCupProduct, removeCupProduct } from "../../../../pages/teams/urbanCup/api"
import { setFormEntry } from "../../../../store/forms/actions"
import ButtonCta from "../../../Buttons/button/button-cta"
import ActivationTrigger from "../../../Buttons/Triggers/ActivationTrigger"
import CurrencyDisplay from "../../../currencies/CurrencyDisplay"
import { SelectInput } from "../../../Inputs/Select/SelectInput.jsx"
import Preloader from "../../../loaders/preloader/preloader"
import Tooltip from "../../../tooltip/Tooltip"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

import "./ShopProduct.scss"

const ShopProduct = ({ id, name, description, price, sizes, pictureUrl, secondaryPicturesUrls, tunnelType, openMissingInfo, openProductZoom }) => {
    const formattedSizes = sizes.map((size) => {
        return { label: size.Value, value: size.Key }
    }) || []
    const [ ajaxLoading, setAjaxLoading ] = useState(false)

    const mediaPath = useMediaPath()
    const themeHeader = useThemeHeader()
    const [ selectedSize, setSelectedSize ] = useState(formattedSizes.length > 0 ? formattedSizes[0].value : "")
    const [ chosenCount, setChosenCount ] = useState(1)
    const store = useStore()
    const dispatch = useDispatch()

    const [ cartDisplay, setCartDisplay ] = useState(false)

    const swiperParams = {
        modules: [ Navigation, Pagination ],
        pagination: {
            clickable: true,
        },
        slidesPerView: 1,
    }

    // birthday data
    const formCreneauDispo = store.getState().forms[CreneauDispo.formName] !== undefined ?
        store.getState().forms[CreneauDispo.formName]
        : {}

    const avoirFormData = store.getState().forms.avoirFormData !== undefined ?
        store.getState().forms.avoirFormData
        : {}

    function addProduct(id, size, count) {
        const forms = store.getState().forms

        size = !!size && parseInt(size)
        /* istanbul ignore if */
        if ((formattedSizes.length > 0 && !formattedSizes.find(e => e.value === size) || size === "")) {
            return openMissingInfo("taille")
        }
        else /* istanbul ignore if */ if (!count) {
            return openMissingInfo("quantité")
        }
        else /* istanbul ignore else */if ((formattedSizes.find(e => e.value === size) || /* istanbul ignore next */ formattedSizes.length === 0) && count !== 0 && count !== "") {

            let initialValue = 0
            let product = {}
            switch (tunnelType) {
            case "BIRTHDAY":
                if (formCreneauDispo?.birthdaySubscription?.id) {
                    let catalogArticleId
                    formCreneauDispo.birthdaySubscription.catalogArticles.forEach(
                        (article) => {
                            /* istanbul ignore else */
                            if (article.sizeValue === parseInt(size) && article.product.id === id) {
                                initialValue = article.quantity
                                catalogArticleId = article.id
                            }
                        },
                    )

                    product = {
                        productId: id,
                        quantity: parseInt(count) + parseInt(initialValue),
                        size: size,
                    }

                    setAjaxLoading(true)
                    /* istanbul ignore else */
                    if (initialValue !== 0) {
                        removeBirthdayCatalogArticle(formCreneauDispo.birthdaySubscription.id, catalogArticleId).then(
                            () => {
                                addBirthdayCatalogArticle(formCreneauDispo.birthdaySubscription.id, product).then((res) => {
                                    dispatch(setFormEntry(CreneauDispo.formName, {
                                        ...formCreneauDispo,
                                        birthdaySubscription: res.data.data,
                                    }))
                                    dispatch(
                                        setFormEntry("avoirFormData", {
                                            ...avoirFormData,
                                            recalcAvoir: true,
                                        }))
                                    setAjaxLoading(false)
                                })
                            },
                        )
                    } else {
                        addBirthdayCatalogArticle(formCreneauDispo.birthdaySubscription.id, product).then((res) => {
                            dispatch(setFormEntry(CreneauDispo.formName, {
                                ...formCreneauDispo,
                                birthdaySubscription: res.data.data,
                            }))
                            dispatch(
                                setFormEntry("avoirFormData", {
                                    ...avoirFormData,
                                    recalcAvoir: true,
                                }))
                            setAjaxLoading(false)
                        })
                    }
                }
                break
                /* istanbul ignore next */
            case "SCHOOL":{
                const firstSubscription = forms.ozmoSubscription.firstSubscription
                firstSubscription.catalogArticles.forEach(
                    (article) => {
                        if (article.sizeValue === parseInt(size) && article.product.id === id) {
                            initialValue = article.quantity
                        }
                    },
                )

                let schoolSubscriptionId = forms.ozmoSubscription.firstSubscription.id
                product["product-" + id] = size
                product["quantity-product-" + id] = parseInt(count) + parseInt(initialValue)

                setAjaxLoading(true)
                const handleSchoolProductAdd = () => {
                    addSchoolProduct(schoolSubscriptionId, product).then(
                        (res) => {
                            dispatch(setFormEntry("ozmoSubscription", {
                                ...forms["ozmoSubscription"],
                                firstSubscription: res,
                            }))
                            dispatch(
                                setFormEntry("avoirFormData", {
                                    ...avoirFormData,
                                    recalcAvoir: true,
                                }))
                        },
                    ).finally(() => setAjaxLoading(false))
                }
                if (initialValue !== 0) {
                    deleteSchoolProduct(schoolSubscriptionId, id, size).then(
                        () => {
                            handleSchoolProductAdd()
                        },
                    )
                } else {
                    handleSchoolProductAdd()
                }
                break
            }
            /* istanbul ignore next */
            case "CUP":{
                const cupSubscription = store.getState().forms.TournamentRegistration
                const articleInCatalog = cupSubscription.catalogArticles.filter(x => x.sizeValue === parseInt(size) && x.product.id === id)
                if (articleInCatalog.length === 1) {
                    initialValue = articleInCatalog[0].quantity
                }

                setAjaxLoading(true)
                const handleCupProductAdd = () => {
                    addCupProduct(cupSubscription.id, id, size, parseInt(count) + parseInt(initialValue), themeHeader).then(
                        (res) => {
                            setAjaxLoading(false)
                            dispatch(setFormEntry("TournamentRegistration", {
                                ...forms["TournamentRegistration"],
                                ...res.data.data,
                            }))
                            dispatch(
                                setFormEntry("avoirFormData", {
                                    ...avoirFormData,
                                    recalcAvoir: true,
                                }))
                        },
                    )
                }
                if (initialValue !== 0) {
                    removeCupProduct(cupSubscription.id, id, size, themeHeader).then(handleCupProductAdd)
                } else {
                    handleCupProductAdd()
                }
                break
            }
            /* istanbul ignore next */
            case "TRAINING":{
                const stageSubscription = forms.ozmoSubscription.firstSubscription
                const initialArticleInCatalog = stageSubscription.catalogArticles.filter(
                    x => x.sizeValue === parseInt(size) &&
                            x.product.id === id,
                )
                if (initialArticleInCatalog.length === 1) {
                    initialValue = initialArticleInCatalog[0].quantity
                }
                setAjaxLoading(true)
                const handleStageProductAdd = () => {
                    addStageProduct(
                        stageSubscription.id,
                        id,
                        size,
                        parseInt(count) + parseInt(initialValue),
                    ).then(
                        (res) => {
                            setAjaxLoading(false)
                            dispatch(setFormEntry("ozmoSubscription", {
                                ...forms["ozmoSubscription"],
                                firstSubscription: res.data.data,
                            }))
                            dispatch(
                                setFormEntry("avoirFormData", {
                                    ...avoirFormData,
                                    recalcAvoir: true,
                                }))
                        },
                    )
                }

                if (initialValue !== 0) {
                    deleteStageProduct(
                        stageSubscription.id,
                        id,
                        size,
                    ).then(handleStageProductAdd)
                } else {
                    handleStageProductAdd()
                }
                break
            }
            /* istanbul ignore next */
            case "LEAGUE": {
                const leagueSubscription = store.getState().forms.TournamentRegistration
                const leagueArticleInCatalog = leagueSubscription.catalogArticles.filter(x => x.sizeValue === parseInt(size) && x.product.id === id)
                if (leagueArticleInCatalog.length === 1) {
                    initialValue = leagueArticleInCatalog[0].quantity
                }

                setAjaxLoading(true)
                const handleLeagueProductAdd = () => {
                    addLeagueProduct(leagueSubscription.id, id, size, parseInt(count) + parseInt(initialValue)).then(
                        (res) => {
                            setAjaxLoading(false)
                            dispatch(setFormEntry("TournamentRegistration", {
                                ...forms["TournamentRegistration"],
                                ...res.data.data,
                            }))
                            dispatch(
                                setFormEntry("avoirFormData", {
                                    ...avoirFormData,
                                    recalcAvoir: true,
                                }))
                        },
                    )
                }
                if (initialValue !== 0) {
                    deleteLeagueProduct(leagueSubscription.id, id, size).then(handleLeagueProductAdd)
                } else {
                    handleLeagueProductAdd()
                }
                break
            }
            /* istanbul ignore next */
            default:
                // eslint-disable-next-line no-console
                console.error(
                    "NOT IMPLEMENTED TUNNEL TYPE or forgotten break statement : %o",
                    tunnelType,
                )
                // eslint-disable-next-line no-debugger
                debugger
                break
            }
        }
    }

    function useCallbackWithInfo() {
        if (selectedSize) {
            addProduct(id, selectedSize, chosenCount)
        } else {
            openMissingInfo("taille")
        }
        closeToolTipSmall()
    }

    // method
    // open dialog cart
    function openToolTipSmall() {
        setCartDisplay(true)
    }

    // close dialog cart
    function closeToolTipSmall() {
        setCartDisplay(false)
    }

    const popinContent = (
        <Swiper
            navigation={true}
            {...swiperParams}
            key={id + name + description + price}
        >
            <SwiperSlide>
                <img src={pictureUrl} />
            </SwiperSlide>
            {secondaryPicturesUrls.map(
                (el, idx) => (
                    <SwiperSlide key={"sky-" + idx}>
                        <img src={el} />
                    </SwiperSlide>
                ),
            )}
        </Swiper>
    )

    return (
        <div className="productItem">
            {ajaxLoading &&
                /* istanbul ignore next */
                <Preloader fixed />
            }
            {
                description && (
                    <ActivationTrigger
                        id={"buttonInfoBullePrestaSup-" + id}
                        cls="infoBubble"
                        target={"#infoBubble-product-" + id}
                    >
                        <div className="infoBubble__icon">
                            <img src={mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])} alt="info"/>
                        </div>
                        <Tooltip
                            id={"infoBubble-product-" + id}>
                            <p dangerouslySetInnerHTML={{ __html: description }}></p>
                        </Tooltip>
                    </ActivationTrigger>
                )
            }

            <div className="productItem__picture">
                { secondaryPicturesUrls.length === 0 ?
                    <img data-testid="secondaryPicture" onClick={() => openProductZoom(<img src={pictureUrl} />)} src={pictureUrl}/>
                    :
                    (<React.Fragment>
                        <Swiper {...swiperParams}>
                            <SwiperSlide>
                                <img data-testid="secondaryPicture" onClick={() => openProductZoom(popinContent)} src={pictureUrl}/>
                            </SwiperSlide>
                            { secondaryPicturesUrls.map(
                                (el, idx) => (
                                    <SwiperSlide key={"swi-" + idx}>
                                        <img  data-testid="secondaryPicture" onClick={() => openProductZoom(popinContent)} src={el}/>
                                    </SwiperSlide>
                                ),
                            )}
                        </Swiper>
                    </React.Fragment>)
                }
            </div>

            <div className="productItem__info priceBox">
                <div className="priceBox__name">{name}</div>
                <div className="priceBox__price">
                    <CurrencyDisplay price={price}/>
                </div>
            </div>

            <div className={`productItem__action  ${cartDisplay ? "is-visible" : ""}`}>
                <a className="productItem__close" onClick={ closeToolTipSmall } title="Fermer">
                    <img src={mediaPath([ "/assets/icons/cross-orange.svg", "/assets/icons/cross-black.svg" ])} alt="close"/>
                </a>

                {formattedSizes && (
                    formattedSizes.length > 1
                    || (formattedSizes.length === 1 && formattedSizes[0].label !== "Unique")
                ) && (
                    <SelectInput
                        fullWidth
                        label={cartDisplay ? "Taille" : null}
                        id={id + "_sizes"}
                        value={selectedSize}
                        options={formattedSizes}
                        onChange={(event)=>{setSelectedSize(event.target.value)}}
                        customRootCls="sizeSelect"
                        customArrow={[
                            "url('/assets/icons/arrow-down-orange.svg')",
                            "url('/assets/images/padel/icons/icon-arrow-down-red.svg')",
                        ]}
                    />
                )}

                <SelectInput
                    fullWidth
                    label={cartDisplay ? "Quantité" : null}
                    id={id + "_count"}
                    value={chosenCount}
                    options={Array.from({ length: 10 }).map((_, i) => {
                        return { label: i + 1, value: i + 1 }
                    })}                    onChange={(event)=>{setChosenCount(event.target.value)}}
                    customArrow={[
                        "url('/assets/icons/arrow-down-orange.svg')",
                        "url('/assets/images/padel/icons/icon-arrow-down-red.svg')",
                    ]}
                />
                <ButtonCta
                    className="addToCart"
                    onClick={useCallbackWithInfo}
                    text="Ajouter"
                    disabled={formattedSizes?.length === 0}
                />
            </div>

            <div
                onClick={closeToolTipSmall}
                className={`productItem__overlay  ${cartDisplay ? "is-visible" : ""}`}></div>

            <div className="productItem__action--small">
                <div className="priceBox">
                    <div className="priceBox__price">
                        <CurrencyDisplay price={price}/>
                    </div>
                </div>

                <button data-testid="openCart" className="btn btn--orange" onClick={ openToolTipSmall }>
                    <img src="/assets/icons/icon--cart--white.svg" />
                </button>
            </div>
        </div>
    )
}

ShopProduct.propTypes = {
    description: string,
    id: oneOfType([ string, number ]),
    name: string,
    openMissingInfo: func,
    openProductZoom: func,
    pictureUrl: string,
    price: number,
    secondaryPicturesUrls: oneOfType([ string, array ]),
    sizes: array,
    tunnelType: string,
}

export default ShopProduct
