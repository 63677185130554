import classNames from "classnames"
import PropTypes from "prop-types"

import "./TeamHeading.scss"

export const TeamHeading = ({ data, big, ctx }) => {
    const { leagueTeam } = data

    return (
        <div className={"teamHeading"}>
            <div className={"leagueName"}>
                <h2 className={"tw-bold"}>{leagueTeam?.leagueName}</h2>
                <span className={"tw-regular"}>Saison {ctx?.leagueSeason}</span>
            </div>
            <div className={classNames("teamName tw-bold", {
                big,
            })}>
                {leagueTeam?.name}
            </div>
            <div className={"sponsorLogo"}>
                <img src={leagueTeam?.blasonUrl}/>
            </div>
        </div>

    )
}

TeamHeading.propTypes = {
    big: PropTypes.bool,
    ctx: PropTypes.object,
    data: PropTypes.object,
}
