const fieldTypeSort = (a, b) => {
    if (a[1] === b[1]) {
        return 0
    } else if (a[1] > b[1]) {
        return 1
    } else {
        return -1
    }
}

export default fieldTypeSort
