import { useDispatch } from "react-redux"

import PlayerSelection from "./components/PlayerSelection/PlayerSelection"
import SchoolTrainingEmergencyContact from "./components/SchoolTrainingEmergencyContact/SchoolTrainingEmergencyContact"
import SchoolTrainingEquipement from "./components/SchoolTrainingEquipement/SchoolTrainingEquipement"
import SchoolTrainingOrderSummary from "./components/SchoolTrainingOrderSummary/SchoolTrainingOrderSummary"
import SchoolTrainingPresta from "./components/SchoolTrainingPresta/SchoolTrainingPresta"
import ServiceChoice from "./components/ServiceChoice/ServiceChoice"
import SlotChoice from "./components/SlotChoice/SlotChoice"
import { SchoolTrainingSubscriptionContextProvider } from "../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import StepsManager from "../../components/stepsManagement/StepManager"
import StepsContainer from "../../components/stepsManagement/StepsContainer"
import { useTheme } from "../../components/ThemeContext/ThemeContext"
import useAddSearchParam from "../../hooks/useAddSearchParams"
import { nextStep } from "../../store/stepsManagement/actions"

import "./SchoolTrainingSubscription.scss"

export default function SchoolTrainingSubscription() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const addSearchParams = useAddSearchParam()

    return (
        <SchoolTrainingSubscriptionContextProvider>
            <StepsManager customCls="schoolTrainingSubscription">
                <StepsContainer stepsToShow='3'>
                    <PlayerSelection title="Sélection du joueur" />
                    <ServiceChoice title="Choix de la prestation" />
                    <SlotChoice title="Choix du créneau" />
                </StepsContainer>
                <SchoolTrainingPresta />
                <SchoolTrainingEmergencyContact/>
                <SchoolTrainingEquipement
                    theme={theme}
                    updateSearchParams={
                        equipment => {
                            addSearchParams("idEquipement", equipment?.length ? equipment[0]?.id : "vide")
                            dispatch(nextStep())
                        }
                    }
                />
                <SchoolTrainingOrderSummary />
            </StepsManager>
        </SchoolTrainingSubscriptionContextProvider>
    )
}
