export let padelProducts = [
    {
        contentIdentifier: "reserver",
        isMyUrban: true,
        label: "Piste",
        link: "/padel/reserver",
        lvl1: "reserver",
        lvl2: null,
        props: {},
    },
    {
        contentIdentifier: "urbanCupTunnel",
        isMyUrban: true,
        label: "Tournoi",
        link: "/padel/tournois/new",
        lvl1: "urbanCup",
        lvl2: null,
        props: {
            onglet: "new",
        },
    },
    {
        isMyUrban: false,
        label: "Abonnement",
        link: "https://www.urbanpadel.fr/formulaire-abonnement/",
    },
    {
        contentIdentifier: "stagesDeFootTunnel",
        isMyUrban: true,
        label: "Stage",
        link: "/padel/coursStages/inscription",
        lvl1: "kids",
        lvl2: "stagesDeFoot",
        props: {
            onglet: "new",
        },
    },
    {
        contentIdentifier: "ecoleDeFootTunnel",
        isMyUrban: true,
        label: "Cours",
        link: "/padel/coursStages/inscription",
        lvl1: "kids",
        lvl2: "ecoleDeFoot",
        props: {
            onglet: "new",
        },
    },
    {
        isMyUrban: false,
        label: "Evènement d'entreprise",
        link: "https://www.urbanevent.fr/contact/",
    },
]
