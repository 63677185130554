import { arrayOf, bool, func, node, oneOfType, string } from "prop-types"

function CheckBtn({ id, name, type = "radio", round, label, defaultChecked, checked, disabled, value, onChange }) {
    return (
        <div className={
            "c-inputHolder--" + (round ? "radioRound" : "radioSquare")
        }>
            <input
                type={type}
                id={id}
                name={name} 
                value={value}
                defaultChecked={defaultChecked}
                checked={checked}
                disabled={disabled}
                onChange={
                    onChange
                }
            />
            { label &&
                (<label htmlFor={id}>
                    {label}
                </label>)
            } 
        </div>
    )
}

CheckBtn.propTypes = {
    checked: bool,
    defaultChecked: bool,
    disabled: bool,
    id: string,
    label: oneOfType([ string, node, arrayOf(node) ]),
    name: string,
    onChange: func,
    round: bool,
    type: string,
    value: string,
}

export default CheckBtn
