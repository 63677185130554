import { _apiCall } from "../../../backend/axios"

export function getSupersubReservationReponses(reservationId, status) {
    let url = "read/supersubs/reservation/" + reservationId + "/responses"

    if (status) {
        url += "?status=" + status
    }

    return _apiCall(
        url,
        "GET",
    ).then((res) => { return res.data.data })
}
