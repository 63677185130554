import FindAMatch from "../../pages/supersub/findAMatch/FindAMatch"
import MySupersubsRequests from "../../pages/supersub/MySupersubsRequests/MySupersubsRequests"
import SuperSubCaptainInfo from "../../pages/supersub/WhatIsSuperSub/SuperSubCaptainInfo.jsx"
import SuperSubPlayerInfo from "../../pages/supersub/WhatIsSuperSub/SuperSubPlayerInfo.jsx"
import WhatIsSuperSub from "../../pages/supersub/WhatIsSuperSub/WhatIsSuperSub"
import { SupersubPaths } from "../ApplicationPaths/supersubPaths.js"

const RoutesSuperSub = [
    {
        element: (<FindAMatch/>),
        path: SupersubPaths.findAMatch,
    },
    {
        element: (<MySupersubsRequests/>),
        path: SupersubPaths.mySupersubsRequests,
    },
    {
        children: [
            {
                element: (<SuperSubCaptainInfo />),
                path: SupersubPaths.whatIsSuperSubCaptain,
            },
            {
                element: (<SuperSubPlayerInfo/>),
                path: SupersubPaths.whatIsSuperSubPlayer,
            },
        ],
        element: (<WhatIsSuperSub isOwner={false} />),
        path: SupersubPaths.whatIsSuperSub,
    },
]

export default RoutesSuperSub
