export const businessLeagueDaysOptions = [
    { label: "Lundi", value: "lundi" },
    { label: "Mardi", value: "mardi" },
    { label: "Mercredi", value: "mercredi" },
    { label: "Jeudi", value: "jeudi" },
]

export const businessLeagueHoursOptions = [
    { label: "18h00 - 19h00", value: "18h00 - 19h00" },
    { label: "19h00 - 20h00", value: "19h00 - 20h00" },
    { label: "20h00 - 21h00", value: "20h00 - 21h00" },
    { label: "21h00 - 22h00", value: "21h00 - 22h00" },
]
