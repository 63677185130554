import { _apiCall } from "../../../backend/axios"

export function deleteFavoriteSlot(id) {
    return _apiCall(
        "delete/favoriteSlot/" + id,
        "DELETE",
        {
            "Content-Type": "multipart/form-data",
        },
    ).then((response) => {
        return response?.data?.data
    })
}

export function createFavoriteSlot(data) {
    return _apiCall(
        "create/favoriteSlot",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then((response) => {
        return response
    })
}

export function editFavoriteSlot(id, data) {
    return _apiCall(
        `modify/favoriteSlot/${id}`,
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then((response) => {
        return response
    })
}
