import { bool, func, number, object, string } from "prop-types"
import { useState } from "react"

import useMediaPath from "../../../hooks/useMediaPath"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import TitleValues from "../../../model/enums/User/TitleValues"
import ButtonCta from "../../Buttons/button/button-cta"
import InputDate from "../../Forms/InputDate/InputDate"
import { SelectInput } from "../../Inputs/Select/SelectInput.jsx"
import Stepper from "../Stepper"

export default function Step2({ theme, step, onNextStep, saveUser, disableActions, currentUser }) {
    const mediaPath = useMediaPath()
    const themeFeatures = useThemeFeatures()
    const [ gender, setGender ] = useState("")
    const [ newsLetter, setNewsLetter ] = useState(currentUser.newsletter)
    const [ partnerNewsletter, setPartnerNewsletter ] = useState(currentUser.partnerNewsletter ?? false)
    const [ padelNewsletter, setPadelNewsletter ] = useState(currentUser.padelNewsletter ?? false)
    const [ birthDate, setBirthDate ] = useState("")

    return (
        <div className={"step2"}>
            <header><img className="" src={mediaPath([ "/assets/images/logo-white.svg", "/assets/images/padel/logo/logo-green.svg" ])} /></header>
            <h4 className={"step2-intro"}>personnalise ton expérience !</h4>
            <div className={"step2-intro2"}>Encore quelques infos sur toi pour profiter d'avantages adaptés à ton profil !</div>
            <div className={"step2-content"}>
                <div className={"step2-input"}>
                    <SelectInput
                        id={"Civilité"}
                        label={"Civilité"}
                        options={TitleValues}
                        value={gender}
                        onChange={(event)=>{setGender(event.target.value)}}
                        placeholderOption="Genre"
                    />
                </div>
                <div className={"step2-input"}>
                    <InputDate
                        bind={[ birthDate, setBirthDate ]}
                        max={new Date()}
                        useDatePicker={false}
                        customClass="customDayPicker"
                        label={themeFeatures([ undefined, "Date de naissance" ])}
                        placeholder={themeFeatures([ "Date de naissance", undefined ])}
                    />
                </div>
                <div className="newsletters">
                    <h2>Profite de cadeaux, codes promos et remises en recevant les meilleures offres et exclusivités :</h2>
                    <div className="wrapper">

                        <div className="c-inputHolder--radioSquare">
                            <input
                                type="checkbox" id="padel_exlu"
                                name="padelNewsletter" value={padelNewsletter}
                                onChange={
                                    () => {
                                        setPadelNewsletter(!padelNewsletter)
                                    }
                                }
                                defaultChecked={padelNewsletter}
                                checked={padelNewsletter}
                            />
                            <label htmlFor="padel_exlu">UrbanPadel</label>
                        </div>
                        <div className="c-inputHolder--radioSquare">
                            <input
                                type="checkbox" id="mailing_exclu"
                                name="newsletter" value={newsLetter}
                                onChange={
                                    () => {
                                        setNewsLetter(!newsLetter)
                                    }
                                }
                                defaultChecked={newsLetter}
                                checked={newsLetter}
                            />
                            <label htmlFor="mailing_exclu">UrbanSoccer</label>
                        </div>
                        <div className="c-inputHolder--radioSquare">
                            <input
                                type="checkbox" id="partner_exclu"
                                name="partnerNewsletter" value={partnerNewsletter}
                                onChange={
                                    () => {
                                        setPartnerNewsletter(!partnerNewsletter)
                                    }
                                }
                                defaultChecked={partnerNewsletter}
                                checked={partnerNewsletter}
                            />
                            <label htmlFor="partner_exclu">Partenaires</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"change-step"}>
                <ButtonCta
                    onClick={() => {
                        saveUser(associateGender(gender), birthDate, newsLetter, padelNewsletter, partnerNewsletter)
                        onNextStep()
                    }}
                    disabled={disableActions}
                    text={
                        (
                            <div className={"button-first-connection"}>
                                <span>je valide</span>
                                {theme === "theme-soccer" ? (
                                    <img src={mediaPath([ "/assets/icons/chevronsoccer.svg", "" ])} />) : null}
                            </div>
                        )}
                />
            </div>
            <div className={"first-connection-stepper"}>
                <Stepper activeStep={step}/>
            </div>
            <footer><img className="" src={mediaPath([ "/assets/images/bordersoccer.svg", "/assets/images/padel/backgrounds/first-connection-footer.svg" ])} /></footer>
        </div>
    )

    function associateGender(gender) {
        let genderData = null
        if (gender === "male") {
            genderData = 1
        } else if (gender === "female") {
            genderData = 2
        } else if (gender === "other") {
            genderData = 9
        }
        return genderData
    }
}

Step2.propTypes = {
    birthDate: string,
    currentUser: object,
    disableActions: bool,
    gender: string,
    newsLetter: bool,
    onNextStep: func,
    padelNewsletter: bool,
    partnerNewsletter: bool,
    saveUser: func,
    setBirthDate: func,
    setGender: func,
    setNewsLetter: func,
    setPadelNewsletter: func,
    setPartnerNewsletter: func,
    step: number,
    theme: string,
}
