function useGetSubTournament(registration, tunnelType) {
    switch (tunnelType) {
    case "CUP":
        return registration.cup ? 
            registration.cup
            :
            undefined
    case "LEAGUE":
        return registration.league ? 
            registration.league
            :
            undefined
    default:
        // eslint-disable-next-line no-console
        console.error("useGetSubTournament - Tunnel not implemented : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        break
    }
}

export default useGetSubTournament
