import {
    getUserLeagueRegistrations,
} from "../../league/api/api.js"
import {
    getCupList,
} from "../urbanCup/api"

export default function useGatherUserRegistrations(tunnelType) {
    switch (tunnelType) {
    case "CUP":
        return getCupList
    case "LEAGUE":
        return getUserLeagueRegistrations
    default:
        // eslint-disable-next-line no-console
        console.error("useUpdateRegistration - Not implemented tunneltype : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        return () => {}
    }
}
