import dayjs from "dayjs"

export function resetNativeHistoryToCurrentLocation(url) {
    window.history.pushState(null, null, url)
    // usefull for safari when you want to disable "next" button after a redirection
    window.addEventListener("pageshow", (event) => {
        if (event.persisted) {
            window.history.pushState(null, null, url)
        }
        // remove listener after calling
        window.removeEventListener("pageshow", () => {})
    })
}

/**
 * Sorts an array of objects based on two sorting criteria, where each object has a 'values' Map containing the data to sort by.
 * The primary and secondary sort criteria are specified by objects that include the key to sort by and the order.
 * Objects in the array can contain other properties besides 'id' and 'values', which are not utilized by this function and can be of any type.
 *
 * @param {Array<{id: number, values: Map<string, any>, ...}>} array - The array of objects to be sorted. Each object has an 'id' and a 'values' Map.
 * Other properties may be present in these objects but are not relevant to the sorting process.
 * @param {Object} primarySort - The primary sorting criteria, an object with 'key' (the Map key to sort by) and 'order' (either 'ASC' for ascending or 'DESC' for descending).
 * @param {Object} secondarySort - The secondary sorting criteria, used when the primary keys are equal. It follows the same structure as primarySort.
 * @returns {Array<{id: number, values: Map<string, any>, ...}>} The sorted array of objects. Note that the returned array maintains any additional properties present in the input objects.
 *
 * @example
 * const items = [
 *   { id: 1, values: new Map([['score', 10], ['team', 'red']]), extraProp: 'unused' },
 *   { id: 2, values: new Map([['score', 12], ['team', 'blue']]), anotherProp: 123 },
 *   { id: 3, values: new Map([['score', 10], ['team', 'green']]), yetAnother: true },
 * ];
 *
 * const sortedItems = sortTableData(items, { key: 'score', order: 'ASC' }, { key: 'team', order: 'DESC' });
 * console.log(sortedItems);
 */
export function sortTableData(array, primarySort, secondarySort) {
    function compareValues(value1, value2, order) {
        const valType = typeof value1

        let comparison = 0
        if (valType === "number") {
            comparison = value1 - value2
        } else if (valType === "string") {
            comparison = value1.localeCompare(value2)
        }

        return order.toUpperCase() === "DESC" ? comparison * -1 : comparison
    }

    return array.sort((a, b) => {
        const aValue1 = a.values.get(primarySort.key)
        const bValue1 = b.values.get(primarySort.key)
        let primaryComparison = compareValues(aValue1, bValue1, primarySort.order)
        if (primaryComparison !== 0) {return primaryComparison}

        const aValue2 = a.values.get(secondarySort.key)
        const bValue2 = b.values.get(secondarySort.key)
        return compareValues(aValue2, bValue2, secondarySort.order)
    })
}

export function sortArray(array, primarySort, secondarySort) {
    function compareValues(value1, value2, order) {
        const valType = typeof value1

        let comparison = 0
        if (valType === "number") {
            comparison = value1 - value2
        } else if (valType === "string") {
            comparison = value1.localeCompare(value2)
        } else if (dayjs.isDayjs(value1) && dayjs.isDayjs(value2)) {
            comparison = value1.isBefore(value2) ? -1 : value1.isAfter(value2) ? 1 : 0
        }

        return order.toUpperCase() === "DESC" ? comparison * -1 : comparison
    }

    return array.sort((a, b) => {
        const aValue1 = a[primarySort.key]
        const bValue1 = b[primarySort.key]
        let primaryComparison = compareValues(aValue1, bValue1, primarySort.order)
        if (primaryComparison !== 0) {return primaryComparison}

        const aValue2 = a[secondarySort.key]
        const bValue2 = b[secondarySort.key]
        return compareValues(aValue2, bValue2, secondarySort.order)
    })
}

export function isBusinessEnvironment() {
    return window.myurbanTheme === "business-league"
}

export function mapToObject(map) {
    if (!map) {return {}}
    return Object.fromEntries(map.entries())
}

export const convertAllImagesToBase64 = (proxyURL, cloned) => {
    const pendingImagesPromises = []
    const pendingPromisesData = []
    const images = cloned.getElementsByTagName("img")

    for (let i = 0; i < images.length; i += 1) {
        const imgSrc = images[i].src.toLowerCase()
        if (!imgSrc.startsWith(window.location.origin) && imgSrc.startsWith("http")) {
            const promise = new Promise((resolve, reject) => {
                pendingPromisesData.push({
                    index: i, reject, resolve, type: "img",
                })
            })
            pendingImagesPromises.push(promise)
        }
    }

    for (let i = 0; i < images.length; i += 1) {
        const imgSrc = images[i].src.toLowerCase()
        if (!imgSrc.startsWith(window.location.origin) && imgSrc.startsWith("http")) {
            fetch(`${proxyURL}?url=${images[i].src}`)
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = reject
                    reader.readAsDataURL(blob)
                }))
                .then(dataUrl => {
                    const pending = pendingPromisesData.find((p) => p.index === i && p.type === "img")
                    images[i].src = dataUrl
                    pending.resolve(dataUrl)
                })
                .catch(e => {
                    const pending = pendingPromisesData.find((p) => p.index === i && p.type === "img")
                    pending.reject(e)
                })
        }
    }
    return Promise.all(pendingImagesPromises)
}

export function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, "") // trim leading/trailing white space
    str = str.toLowerCase() // convert string to lowercase
    str = str.replace(/[^a-z0-9 -]/g, "") // remove any non-alphanumeric characters
        .replace(/\s+/g, "-") // replace spaces with hyphens
        .replace(/-+/g, "-") // remove consecutive hyphens
    return str
}
