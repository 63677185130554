import { string } from "prop-types"
import { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { listConvocations, listLocations } from "./api"
import InfiniteScroller from "../../../components/InfiniteScroller/InfiniteScroller"
import Preloader from "../../../components/loaders/preloader/preloader"
import LocationBlock from "../../../components/reservation/LocationBlock/LocationBlock"
import PayFreeAmount from "../../../components/reservation/PayFreeAmount/PayFreeAmount"
import { getArdoises, getLocation } from "../../../globalAPI/api"
import useProceedCheckout from "../../../hooks/reservation/useProceedCheckout"
import useUrbanLanguage from "../../../hooks/urbanLanguage/useUrbanLanguage"
import useThemeHeader from "../../../hooks/useThemeHeader"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import usePath from "../../../routes/services/usePath"
import {
    hasUserAlreadyPaidWithAvoir,
    hasUserAlreadyPaidWithGymlib,
} from "../../../services/locationManagementService.js"
import updateNextBooking from "../../../services/updateNextBooking"
import BookingInvitation from "../infoReservation/components/BookingInvitation/BookingInvitation"

import "./listingReservation.scss"

function ListingReservation() {
    const handleConvocation = useRef(null)
    const hasArdoises = useSelector(state => state.hasArdoises)
    const user = useSelector(state => state.userLoaded)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ ardoises, setArdoises ] = useState([])
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ gatherConvocations, setGatherConvocations ] = useState(true)
    const [ locationToPay, setLocationToPay ] = useState({})
    const [ fullLocation, setFullLocation ] = useState(null)
    const [ isAddPaiementPopInOpen, setIsAddPaymentPopInOpen ] = useState(false)
    const [ useAlias, setUseAlias ] = useState(false)

    const navigate = useNavigate()
    const path = usePath()
    const themeHeader = useThemeHeader()
    const { tu } = useUrbanLanguage()

    const {
        proceedCheckout,
        payzenForm,
    } = useProceedCheckout(
        {
            customData: {
                centerId: locationToPay?.centerId,
                fromGestion: true,
                id: locationToPay.id,
                start: locationToPay.start,
            },
            firstSubscription: locationToPay,
            isUsingUserCB: useAlias,
            lyraPaymentUrl: path("/reserver/paiement/reservation/" + locationToPay?.id),
            redirectToListing: true,
            setAjaxLoading: setAjaxLoading,
            setPayFreeAmountPopInOpen: setIsAddPaymentPopInOpen,
            tunnelType: "BOOKING",
        },
    )

    function goToReservation() {
        navigate(path("/reserver"))
    }
    const openInvitPlayersPopIn = (locationId) => {
        setAjaxLoading(true)
        getLocation(locationId).then(
            (res) => {
                setAjaxLoading(false)
                ModalHandler.show(BookingInvitation, {
                    location: res,
                })
            },
        ).catch(() => setAjaxLoading(false))
    }
    
    useEffect(() => {
        if (hasArdoises) {getArdoises().then(res => {
            if (res.hitCount) {setArdoises(res.items.filter(ardoise => ardoise.reservationId))}
        })}
    }, [ hasArdoises ])

    const extraPaymentMethodAlreadyUsed = useMemo(() => {
        return hasUserAlreadyPaidWithGymlib(user?.contactId, fullLocation) || hasUserAlreadyPaidWithAvoir(user?.contactId, fullLocation)
    }, [ fullLocation, user ])

    useEffect(() => {
        if (locationToPay?.id) {
            getLocation(locationToPay.id).then(res => {
                setFullLocation(res)
            })
        }
    }, [ locationToPay?.id ])

    return (
        <div id="listingBooking" className={"step noMarginBottom matchs reservation"}>
            <InfiniteScroller
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                noGoBack={true}
                getItemBlockById={
                    (id) => document.getElementById("locationBlock-" + id)
                }
                
                gatherTopData={
                    (PAGE_SIZE, offset) => listLocations({
                        offset: offset,
                        pagesize: PAGE_SIZE,
                        periodEnd: new Date().toISOString(),
                        periodStart: null,
                        theme: { activity: themeHeader },
                    })
                }

                gatherBotData = {
                    (PAGE_SIZE, offset) => {
                        const listLoc = listLocations({
                            offset: offset,
                            orderbyasc: true,
                            pagesize: PAGE_SIZE,
                            periodEnd: null,
                            periodStart: new Date().toISOString(),
                            theme: { activity: themeHeader },
                        })
                        
                        if (gatherConvocations) {
                            let promises = []
                            promises.push(
                                listLoc,
                            )
                            promises.push(
                                listConvocations(themeHeader),
                            )
                            setGatherConvocations(false)
                            return Promise.all(promises).then(
                                (values) => {
                                    let resData = []
                                    values.forEach(
                                        (value) => {
                                            const data = value.data.data
                                            const isLocations = !!data.items
                                            const dataToConcat = isLocations ?
                                                data.items
                                                :
                                                data.filter(
                                                    res => res.status === 0,
                                                )
                                            
                                            resData = resData.concat(dataToConcat)
                                        },
                                    )
                                    return resData
                                },
                            )
                        } else {
                            return listLoc
                        }
                    } 
                }

                topDisplayMapper={
                    (location) => (
                        <LocationBlock
                            location={location}
                            key={location.id}
                            locationHasArdoise={!!ardoises?.find(ardoise => ardoise.reservationId === location.id)}
                        />
                    )
                }

                botDisplayMapper={
                    (location) => (
                        <LocationBlock
                            location={location}
                            setAjaxLoading={setAjaxLoading}
                            key={location.id}
                            callBack={handleConvocation ?
                                () => {
                                    handleConvocation.current.removingBotItem(
                                        match => match.id === location.id && match.reservation === location.reservation,
                                    )
                                    updateNextBooking()
                                }
                                :
                                undefined
                            }
                            openInvitPlayersPopIn={openInvitPlayersPopIn}
                            setAddPaiementPopInOpen={setIsAddPaymentPopInOpen}
                            setLocationToPay={setLocationToPay}
                        />
                    )
                }
                
                emptyDataText={tu("booking.listing.noBooking")}
                emptyDataButtonText="Réserver"
                emptyDataButtonCallback={goToReservation}
                ref={handleConvocation}
                botTitle="À venir"
                topTitle="Joués"
            />
            { ajaxLoading && (
                <Preloader 
                    fixed={true}
                />
            )}
            {payzenForm}
            <PayFreeAmount
                registration={
                    {
                        ...fullLocation,
                        amountTotal: locationToPay.discountedPrice ? locationToPay.discountedPrice : locationToPay.price,
                        centerName: locationToPay.centerName,
                        dueAmount: locationToPay.amountDue,
                        id: locationToPay.id,
                        start: locationToPay.start,
                    }
                }
                pricePerPart={locationToPay.pricePerPart}
                withGymlibPayments={!extraPaymentMethodAlreadyUsed && !!fullLocation}
                withPartPayment
                withAvoir={!extraPaymentMethodAlreadyUsed && !!fullLocation}
                paiementCallback={proceedCheckout}
                isOpen={isAddPaiementPopInOpen}
                setIsOpen={setIsAddPaymentPopInOpen}
                tunnelType="BOOKING"
                onUseAlias={(v) => setUseAlias(v)}
                canUseAlias
            />
        </div>
    )
}

ListingReservation.propTypes = {
    onglet: string,
}

export default ListingReservation
