import { array, bool, func, string } from "prop-types"
import { useState } from "react"
import { IMaskInput } from "react-imask"

export default function PadelScore({ padelScore, setPadelScore, addSet, disabledScore, prefix }) {
    const [ addIcon, setAddIcon ] = useState("/assets/images/padel/icons/icon-add-green.svg")
    return (
        <>
            {padelScore.map((_, i) => 
                (<IMaskInput
                    className={disabledScore ? "" : "dottedBorder"}
                    disabled={disabledScore}
                    mask={Number}
                    max={7}
                    scale={0}
                    value={padelScore[i]}
                    onChange={
                        (e) => {
                            let tempScore = [ ...padelScore ]
                            tempScore[i] = e.target.value
                            setPadelScore(tempScore)
                        }
                    }
                    normalizeZeros={false}
                    type="number"
                    pattern="\\d*"
                    key={prefix + i}
                />),
            )}
            {padelScore.length < 5 && !disabledScore && (
                <img
                    src={addIcon}
                    className="addSet"
                    onClick={addSet}
                    onMouseEnter={() => setAddIcon("/assets/images/padel/icons/icon-add-black.svg")}
                    onMouseLeave={() => setAddIcon("/assets/images/padel/icons/icon-add-green.svg")}
                />
            )}
        </>
    )
}

PadelScore.propTypes = {
    addSet: func,
    disabledScore: bool,
    padelScore: array,
    prefix: string,
    setPadelScore: func,
}
