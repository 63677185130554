import { bool, func, number, oneOfType, string } from "prop-types"

import ButtonCta from "../../../components/Buttons/button/button-cta"
import { TextInput } from "../../../components/Inputs/TextInput/TextInput.jsx"
import Modal from "../../../components/Modal/Modal"
import useUrbanLanguage from "../../../hooks/urbanLanguage/useUrbanLanguage"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"

import "./PayWithUsCard.scss"

const PayWithUsCard = ModalHandler.create(({ usCardAmount, setUsCardAmount, proceedCheckout, visible, hide }) => {
    const { tu } = useUrbanLanguage()
    return (
        <Modal
            className="payWithUsCard"
            hideOnOverlayClicked
            isOpen={visible}
            onClose={hide}
        >
            <header>PAYER</header>
            <section>
                <h2>
                    Tu souhaites régler avec une carte {tu("payWithUsCard.typeOfCard")} ou des chèques {tu("payWithUsCard.typeOfCard")} ?
                </h2>
                <p>Finalise ta réservation en déposant une empreinte CB.</p>
                <p>Le jour du match, présente ta carte {tu("payWithUsCard.typeOfCard")} ou tes chèques {tu("payWithUsCard.typeOfCard")} à l’accueil de ton centre. Ton empreinte CB sera annulée et tu ne seras pas débité.</p>
                <div className="inputWrapper">
                    <h2>MONTANT À RÉGLER</h2>
                    <TextInput
                        value={usCardAmount}
                        disabled
                        onChange={setUsCardAmount}
                    />
                </div>
                <ButtonCta
                    color="orange"
                    text="Régler"
                    onClick={proceedCheckout}
                />
            </section>
        </Modal>
    )
})

PayWithUsCard.propTypes = {
    isModalOpen: bool,
    isUsingUserCB2: bool,
    proceedCheckout: func,
    setIsModalOpen: func,
    setUsCardAmount: func,
    usCardAmount: oneOfType([ string, number ]),
}

PayWithUsCard.displayName = "PayWithUsCard"

export default PayWithUsCard
