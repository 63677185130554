import React from "react"

import { useClickOutside } from "../../hooks/useClickOutside"
import { useTracker } from "../../providers/TrackerProvider"
import ShareLink from "../ShareLink/ShareLink"

import "./SessionHelper.scss"

export const SessionHelper = () => {
    const [ isOpen, setIsOpen ] = React.useState(false)
    const tracker = useTracker()
    const ref = useClickOutside(() => {
        setIsOpen(false)
    })

    if (!tracker.sessionUrl) {return null}

    return (
        <div className={"sessionHelper"} ref={ref}>
            <div className={"sessionIcon"} data-testid="sessionHelper" onClick={() => setIsOpen(!isOpen)}>
                <img src="/assets/icons/settings.svg" alt="session helper" />
            </div>
            {isOpen && (
                <ShareLink label={"Copier l'url de la session"} shareLink={tracker.sessionUrl} customCls={"copyButton"}/>
            )}
        </div>
    )
}
