
const positioningOptions = {
    0: "Droite",
    1: "Gauche",
    2: "Indifférent",
}

export const PadelPlacementArray = Object.entries(positioningOptions).map(([ value, label ]) => ({ label, value: parseInt(value) }))

export default positioningOptions
