import { string } from "prop-types"
import Countdown, { zeroPad } from "react-countdown"

import usePlural from "../../../hooks/usePlural"

import "./StartCountDown.scss"

export default function StartCountDown({ date }) {
    return (
        <div className="startCountDown">
            <Countdown
                date={new Date(date)}
                renderer={({ days, hours, minutes, seconds }) => (
                    <div className="countDown">
                        <div className="wrapper">
                            <div className="number">{days}</div>
                            <div className="desc">{usePlural(days, "Jour", "Jours")}</div>
                        </div>
                        <div className="wrapper">
                            <div className="number">{zeroPad(hours)}</div>
                            <div className="desc">{usePlural(hours, "Heure", "Heures")}</div>
                        </div>
                        <div className="wrapper">
                            <div className="number">{zeroPad(minutes)}</div>
                            <div className="desc">{usePlural(minutes, "Min", "Mins")}</div>
                        </div>
                        <div className="wrapper">
                            <div className="number">{zeroPad(seconds)}</div>
                            <div className="desc">Sec</div>
                        </div>
                    </div>
                )}
            />
        </div>
    )
}

StartCountDown.propTypes =  {
    date: string,
}
