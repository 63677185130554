export const SET_FORM_ENTRY = "ADD_FORM_ENTRY"
export const REMOVE_FORM_ENTRY = "REMOVE_FORM_ENTRY"
export const CLEAR_ALL = "CLEAR_ALL"
export const UPDATE_BIRTHDAY_FORM_ENTRY = "UPDATE_BIRTHDAY_FORM_ENTRY"

/**
 * Action that clear all the form
 */
export function clearAllForms() {
    return {
        type: CLEAR_ALL,
    }
}

/**
 * Ajoute un objet nommé correspondant à une partie d'un formulaire par étape dans le store
 * @param {string} formName Le nom du formulaire à ajouter
 * @param {Object} formData Les données du formulaire à ajouter
 */
export function setFormEntry(formName, formData) {
    return {
        payload: {
            data: formData,
            name: formName,
        },
        type: SET_FORM_ENTRY,
    }
}

export function updateBirthdayFormEntry(formsData) {
    return {
        payload: {
            data: formsData,
        },
        type: UPDATE_BIRTHDAY_FORM_ENTRY,
    }
}

/**
 * Supprime une entrée de formulaire et ses données dans le store à partir de son nom
 * @param {*} formName Le nom du formulaire à supprimer dans le store
 */
export function removeFormEntry(formName) {
    return {
        payload: formName,
        type: REMOVE_FORM_ENTRY,
    }
}
