import classNames from "classnames"
import { arrayOf, bool, func, node, number, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"

import "./RatingComponent.scss"

function RatingComponent({
    label,
    initialValue,
    disabled,
    updateRatingCallback,
    customLabelCls,
    customMessage,
    supersubNotCome,
}) {
    const [ value, setValue ] = useState(initialValue || 0)
    const [ hover, setHover ] = useState(0)

    const stars = Array(5).fill(0)

    useEffect(() => {
        if (supersubNotCome) {
            setValue(1)
        }
    }, [ supersubNotCome ])

    const handleClick = (index) => {
        if (!disabled && !supersubNotCome) {
            setValue(index)
            updateRatingCallback?.(index)
        }
    }

    const handleMouseOver = (index) => {
        if (!disabled && !supersubNotCome) {
            setHover(index)
        }
    }

    const handleMouseLeave = () => {
        if (!disabled && !supersubNotCome) {
            setHover(value)
        }
    }

    return (
        <div className="ratingDiv">
            <div className="rating-label">{label}</div>
            <div className={classNames("rating-component",{ disabled })}>
                {stars.map((_, index) => (
                    <span
                        key={index}
                        className={`star ${index < (hover || value) ? "filled" : ""}`}
                        onClick={() => handleClick(index + 1)}
                        onMouseOver={() => handleMouseOver(index + 1)}
                        onMouseLeave={handleMouseLeave}
                        data-testid="stars"
                    >
                        ★
                    </span>
                ))}
            </div>
            <div className={
                customLabelCls ?
                    "ratingLabel " + customLabelCls
                    :
                    "ratingLabel"
            }>
                {customMessage ?
                    customMessage
                    :
                    (hover !== -1 && customIcons[hover - 1]) ?
                        customIcons[hover - 1].label
                        :
                        ""
                }
            </div>
        </div>
    )
}

const customIcons = [
    {
        label: "Insatisfait",
    },
    {
        label: "Peu satisfait",
    },
    {
        label: "Assez satisfait",
    },
    {
        label: "Satisfait",
    },
    {
        label: "Très satisfait",
    },
]

RatingComponent.propTypes = {
    customLabelCls: string,
    customMessage: oneOfType([ string, bool ]),
    disabled: bool,
    initialValue: oneOfType([ string, number ]),
    label: oneOfType([ string, node, arrayOf(node) ]),
    name: string,
    supersubNotCome: bool,
    updateRatingCallback: func,
}

export default RatingComponent
