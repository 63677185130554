import PropTypes from "prop-types"
import { useEffect } from "react"

import "./AlmaBadge.scss"

const AlmaBadge = ({ amount, customId = "alma-widget" }) => {
    const merchantId = import.meta.env.VITE_ALMA_MERCHANT_ID

    if (!merchantId) {
        // eslint-disable-next-line no-console
        console.error("You must provide a valid Alma merchant ID in your .env file")
        return null
    }

    useEffect(() => {
        const scriptCss = document.createElement("link")
        scriptCss.rel = "stylesheet"
        scriptCss.href = "https://cdn.jsdelivr.net/npm/@alma/widgets@3.x.x/dist/widgets.min.css"
        document.head.appendChild(scriptCss)

        const scriptJs = document.createElement("script")
        scriptJs.src = "https://cdn.jsdelivr.net/npm/@alma/widgets@3.x.x/dist/widgets.umd.js"
        scriptJs.onload = () => {
            // eslint-disable-next-line no-undef
            const env = import.meta.env.VITE_ENV === "production" ? Alma.ApiMode.LIVE : Alma.ApiMode.TEST
            // eslint-disable-next-line no-undef
            const widgets = Alma.Widgets.initialize(merchantId, env)
            // eslint-disable-next-line no-undef
            widgets.add(Alma.Widgets.PaymentPlans, {
                container: `#${customId}`,
                hideIfNotEligible: false,
                locale: "fr",
                plans: [
                    {
                        installmentsCount: 3,
                        maxAmount: 200000,
                        minAmount: 5000,
                    },
                ],
                purchaseAmount: amount * 100,
            })
        }
        document.head.appendChild(scriptJs)

        return () => {
            document.head.removeChild(scriptCss)
            document.head.removeChild(scriptJs)
        }
    }, [ amount ])

    return <div id={customId} className={"alma-widget"}></div>
}

AlmaBadge.propTypes = {
    amount: PropTypes.number.isRequired,
    customId: PropTypes.string,
}

export default AlmaBadge
