import { bool, func, node, string } from "prop-types"

import useMediaPath from "../../../hooks/useMediaPath"
import RoundCaptionnedIconButton from "../../Buttons/roundCaptionnedIconButton/RoundCaptionnedIconButton"
import Help from "../../tooltip/Help/Help"

import "./AuthHeader.scss"

function AuthHeader({ title, goBack, help, hideGoBack }) {
    const mediaPath = useMediaPath()

    return (
        <div className="o-auth-header">
            {
                !hideGoBack &&
                    (<RoundCaptionnedIconButton
                        icon={mediaPath([ "arrow-left.svg", "arrow-left-black-alt.svg", "arrow-left.svg" ])}
                        text={ "Précédent" }
                        clickCallback={goBack}
                    />)
            }

            <div className="o-auth-header__main">
                <img src={mediaPath([ "/assets/images/logo-white.svg", "/assets/images/padel/logo/logo-green.svg", "/assets/images/business/logo-business-white.svg" ])}/>
                <h1>{title}</h1>
            </div>

            {help &&
                (<Help id="help">
                    { help }
                </Help>)
            }
        </div>
    )
}

AuthHeader.propTypes = {
    goBack: func,
    help: node,
    hideGoBack: bool,
    title: string,
}

export default AuthHeader
