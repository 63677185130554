import classNames from "classnames"
import { arrayOf, bool, node, oneOfType, string } from "prop-types"

import "./Tooltip.scss"

function Tooltip({ id, cls, children, isLeft }) {
    return (
        <div className={classNames("tooltipContainer", {
            [cls]: cls,
            left: isLeft,
        })}>
            <div className="tooltip" id={id}>
                <div className="arrow"></div>
                { children }
            </div>
        </div>
    )
}

Tooltip.propTypes = {
    children: oneOfType([ string, node, arrayOf(node) ]),
    cls: string,
    id: string,
    isLeft: bool,
}

export default Tooltip
