import { arrayOf, node, oneOfType, string } from "prop-types"
import { forwardRef } from "react"

const Form = forwardRef(
    ({ title, desc, children }, ref) => {
        return (
            <div className="customForm" ref={ref}>
                <div className="c-row">
                    <div className="c-col formHeader">
                        <div className="formHeader__title">{ title }</div>
                        <p className="formHeader__desc">{ desc }</p>
                    </div>
                </div>

                <div className="c-row formInputBox">
                    { children }
                </div>
            </div>
        )
    },
)

Form.propTypes = {
    children: oneOfType([ string, node, arrayOf(node) ]),
    desc: oneOfType([ string, node, arrayOf(node) ]),
    title: oneOfType([ string, node, arrayOf(node) ]),
}
Form.displayName = "Form"

export default Form
