export default {
    0: {
        label: "XS Enfant (5-6 ans – Entre 122 et 128 cm)",
        short: "XS Enfant",
        value: "0",
    },
    1: {
        label: "S Enfant (7-8 ans – Entre 128 et 137 cm)",
        short: "S Enfant",
        value: "1",
    },
    2: {
        label: "M Enfant (9-10 ans – Entre 137 et 147 cm)",
        short: "M Enfant",
        value: "2",
    },
    3: {
        label: "L Enfant (11-12 ans – Entre 147 et 158 cm)",
        short: "L Enfant",
        value: "3",
    },
    4: {
        label: "XL Enfant (13 ans – Entre 158 et 170 cm)",
        short: "XL Enfant",
        value: "4",
    },
    5: {
        label: "S Adulte (14 ans – Entre 170 et 175 cm)",
        short: "S Adulte",
        value: "5",
    },
    6: {
        label: "M Adulte (15-16 ans – Entre 175 et 182 cm)",
        short: "M Adulte",
        value: "6",
    },
    7: {
        label: "L Adulte (16-17 ans – Entre 182 et 190 cm)",
        short: "L Adulte",
        value: "7",
    },
    8: {
        label: "XL Adulte (17 ans – à partir de 190 cm)",
        short: "XL Adulte",
        value: "8",
    },
}
