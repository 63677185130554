import {
    cancelPreregisterLeagueSubscription,
} from "../../league/api/api.js"
import {
    cancelCupPreregistration,
} from "../urbanCup/api"

export default function useCancelPreregistration(tunnelType) {
    switch (tunnelType) {
    case "CUP":
        return cancelCupPreregistration
            
    case "LEAGUE":
        return cancelPreregisterLeagueSubscription

    default:
        // eslint-disable-next-line no-console
        console.error("useCancelPreregistration - Not implemented tunneltype : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        return () => {}
    }
}

