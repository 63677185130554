import classNames from "classnames"
import { arrayOf, bool, node, oneOfType, string } from "prop-types"

import "./PreForm.scss"

function PreForm({
    label,
    fullsize = false,
    children,
    noWrap = false,
    supersubsRow,
    bgRedGreen,
    bgGreen,
    bgRed,
    customCls,
    paddingBottomOnly = false,
}) {
    return (
        <div className={classNames("preForm", {
            "bottomPaddingOnly": paddingBottomOnly,
            [customCls]: customCls,
            "has-bgGreen": bgGreen,
            "has-bgRed": bgRed,
            "has-bgRedGreen": bgRedGreen,
        })}>
            <div className="centerPicker c-row justify-center align-start layoutSmall">
                <div
                    className={
                        "wrapper flex align-center " + (noWrap ? "" : "wrap ") +
                        (fullsize ? "c-col c-col--12" : supersubsRow ? "" : "c-col c-col--10 c-col--sm--12 space-between")
                    }
                >
                    {label &&
                        <label className="h2 w-auto">{label}</label>
                    }
                    { children }
                </div>
            </div>
        </div>
    )
}

PreForm.propTypes = {
    bgGreen: bool,
    bgRed: bool,
    bgRedGreen: bool,
    children: oneOfType([ arrayOf(node), node ]),
    customCls: oneOfType([ bool, string ]),
    fullsize: bool,
    label: oneOfType([ string, node ]),
    noWrap: bool,
    paddingBottomOnly: bool,
    supersubsRow: bool,
}

export default PreForm
