import { motion } from "framer-motion"
import PropTypes from "prop-types"
import { useState } from "react"

import ButtonCta from "../../../components/Buttons/button/button-cta.jsx"
import { VideoCard } from "../../../components/league/Videos/VideoCard.jsx"
import { leagueVideoGroupType } from "../../../model/types/league.types.js"
import { AnimationPresets } from "../../../utilities/animations.js"

import "./VideoGrid.scss"

export const VideoGrid = ({ videos }) => {
    const [ showAll, setShowAll ] = useState(false)

    const handleClick = () => {
        setShowAll(!showAll)
    }

    const limitedVideos = showAll ? videos : videos?.slice(0, 6)

    return (
        <div className="video-grid">
            <h2>Les vidéos</h2>
            <div className="video-list">
                {limitedVideos?.map((group, index) => (
                    <motion.div
                        key={(group.name + index).toString()}
                        custom={index}
                        variants={AnimationPresets.gridRevealBuilder(0.5)}
                        initial="hidden"
                        animate="visible"
                    >
                        <VideoCard group={group} />
                    </motion.div>
                ))}
            </div>
            {videos?.length > 6 && (
                <ButtonCta text={showAll ? "voir moins" : "voir plus"} isAlt onClick={handleClick}/>
            )}
        </div>
    )
}

VideoGrid.propTypes = {
    videos: PropTypes.arrayOf(leagueVideoGroupType).isRequired,
}
