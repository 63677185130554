import { bool, func, object } from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { displayBill } from "../../../globalAPI/api.js"
import { useCanDownload } from "../../../hooks/useCanDownload.jsx"
import {
    cancelPreregisterLeagueSubscription,
} from "../../../pages/league/api/api.js"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import usePath from "../../../routes/services/usePath.js"
import { popUrl } from "../../../services/routingManagment.js"
import { setFormEntry } from "../../../store/forms/actions.js"
import { goToStep } from "../../../store/stepsManagement/actions.js"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import ListingRegistrationHolder from "../../listing/ListingRegistrationHolder.jsx"
import StatusDisplay from "../../listing/StatusDisplay.jsx"
import Modal from "../../Modal/Modal.jsx"
import MessagePopin from "../../popin/MessagePopin/MessagePopin.jsx"
import DateDisplay from "../../Time/DateDisplay.js"

import "./LeagueListingElement.scss"

function LeagueListingElement(
    {
        leagueRegistration,
        displayDetail,
        callback,
        effectifCallback,
        openPaiementPopIn,
    },
) {
    const [ isLoading, setIsLoading ] = useState(false)

    const userLoaded = useSelector(state => state.userLoaded)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = usePath()
    const { check, message } = useCanDownload()

    useEffect(
        () => {
            if (displayDetail === true) {
                setTimeout(() => {
                    openDetailPopIn()
                }, 200)
            } else if (displayDetail === false) {
                ModalHandler.hide(DetailsPopIn)
            }
        }, [],
    )

    function quitterFileAttente() {
        cancelPreregisterLeagueSubscription(leagueRegistration.id).then(
            () => {
                callback()
            },
        )
    }

    function finaliser() {
        dispatch(
            setFormEntry(
                "TournamentRegistration",
                leagueRegistration,
            ),
        )

        dispatch(
            goToStep(6),
        )
        
        navigate(path("/ordersummary"), {
            state: {
                title: "Ma préinscription",
                tunnelType: "LEAGUE",
            },
        })
    }

    function annuler() {
        finaliser()
    }

    function openDetailPopIn() {
        ModalHandler.show(DetailsPopIn, {
            leagueRegistration: leagueRegistration,
        })
    }

    return (
        <ListingRegistrationHolder
            isLoading={isLoading}
            logo={leagueRegistration.league.pictureUrl}
            status={
                (<StatusDisplay
                    id={leagueRegistration.id}
                    status={leagueRegistration.status}
                    statusValue={leagueRegistration.statusValue}
                    preRegistrationLimitDate={leagueRegistration.preRegistrationLimitDate}
                    isFamiliarity
                />)
            }
            name={
                leagueRegistration.league.name
            }
            details={
                (<React.Fragment>
                    <div>UrbanSoccer {leagueRegistration.centerName}</div>
                    Début : <DateDisplay stringDate={leagueRegistration.league.start}/>
                    <div>Équipe : {leagueRegistration.teamName ?? ""}</div>
                    <div
                        data-testid="openPopin"
                        className="detail-btn"
                        onClick={/* istanbul ignore next */
                            () => {
                                // openPopin('listing', 'details', leagueRegistration.id)
                                openDetailPopIn()
                            }
                        }
                    >
                        Voir le détail
                    </div>
                </React.Fragment>)
            }
            actions={
                (<React.Fragment>
                    { (
                        (leagueRegistration.bill && parseInt(leagueRegistration.dueAmount) > 0) &&
                        (
                            (userLoaded && leagueRegistration.contact.id === userLoaded.contactId) ||
                            (leagueRegistration.playerBillShareToken && leagueRegistration.playerBillShareToken.token)
                        )
                    ) && (
                        <ButtonCta
                            className="buttonCta--league"
                            onClick = {openPaiementPopIn}
                            isAlt = {true}
                            text = "Payer"
                        />
                    )}
                    <ButtonCta
                        className="buttonCta--league"
                        onClick = {effectifCallback}
                        text = "Gérer l'effectif"
                        isCta2Alt = {true}
                    />
                    {
                        (leagueRegistration.billId > 0 
                            && leagueRegistration.statusValue === 3
                            && (userLoaded && leagueRegistration.contact.id === userLoaded.id)
                        ) &&
                            (<>
                                <ButtonCta
                                    className="buttonCta--league"
                                    isCta2Alt={true}
                                    text="Télécharger la facture"
                                    onClick={() => {
                                        check(() => {
                                            displayBill(leagueRegistration.billId, setIsLoading).then((res) => {
                                                if (res) {
                                                    ModalHandler.show(MessagePopin, {
                                                        message: res,
                                                        title: "Erreur",
                                                    })
                                                }
                                            })
                                        })
                                    }}
                                />
                            </>)
                    }

                    {
                        (leagueRegistration.statusValue === 1) &&
                            (<ButtonCta
                                className="buttonCta--league"
                                isCta2Alt={true}
                                text="Quitter la file d'attente"
                                onClick={quitterFileAttente}
                            />)
                    }

                    {
                        (leagueRegistration.statusValue === 2) &&
                            (<ButtonCta 
                                className="buttonCta--league"
                                text="Finaliser ma pré-inscription"
                                isAlt={true}
                                onClick={finaliser}
                            />)
                    }

                    {
                        (leagueRegistration.statusValue === 2) &&
                            (<ButtonCta 
                                className="buttonCta--league"
                                text="Annuler ma pré-inscription"
                                isAlt={true}
                                onClick={annuler}
                            />)
                    }
                    {message}
                </React.Fragment>)
            }
        />
    )
}

/* istanbul ignore next */
const DetailsPopIn = ModalHandler.create(({ leagueRegistration, visible,hide }) => {
    let catalogArticles = []
    if (leagueRegistration.catalogArticles) {
        leagueRegistration.catalogArticles.forEach((catalogArticle) => {
            catalogArticles.push(
                <div className="catalog-article">
                    <img src={catalogArticle.product.pictureUrl} />
                    <div>
                        Qté : <span>{catalogArticle.quantity}</span>
                    </div>
                </div>,
            )
        })
    }

    // build details popin
    const popin = (
        <>
            <header>Détails de l'inscription</header>
            <article>
                <section className="details">
                    <div>
                        <div className="header">{leagueRegistration.league.name}</div>
                        <div>Centre : {leagueRegistration.centerName}</div>
                        Début : <DateDisplay stringDate={leagueRegistration.league.start}/>
                        { leagueRegistration.league.maxRegistrations &&
                            <div>Nombre d'équipe: {leagueRegistration.league.maxRegistrations}</div>
                        }

                        <div className="details-equipe-div">
                            { leagueRegistration.championshipTeam &&
                                <div>Equipe : {leagueRegistration.championshipTeam.name}</div>
                            }
                            <div>Capitaine : {leagueRegistration.captainName} </div>
                        </div>

                    </div>
                    {leagueRegistration.optionProducts && leagueRegistration.optionProducts.length > 0 &&
                        (<div>
                            <div className="header-options">Mes options :</div>
                            {leagueRegistration.optionProducts.map(
                                (option, idx) => (
                                    <li key={"opt-" + idx}>
                                        {option.name}
                                    </li>
                                ),
                            )}
                        </div>)
                    }
                </section>

                { leagueRegistration.catalogArticles && leagueRegistration.catalogArticles.length > 0 &&
                    (<section>
                        <div className="header">Mes équipements :</div>
                        <div className="catalogArticlesDiv">
                            {catalogArticles}
                        </div>
                    </section>)
                }
            </article>
        </>
    )

    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            beforeClose={popUrl}
        >
            {popin}
        </Modal>
    )
})

LeagueListingElement.propTypes = {
    callback: func,
    displayDetail: bool,
    effectifCallback: func,
    leagueRegistration: object,
    openPaiementPopIn: func,
}

export default LeagueListingElement
