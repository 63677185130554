import { Controller, useFormContext } from "react-hook-form"

import { useIsWebview } from "../../../../../components/ThemeContext/ThemeContext"
import useMediaPath from "../../../../../hooks/useMediaPath"

import "./SlotStep.scss"

export const SlotStep3 = () => {
    const isWebView = useIsWebview()
    const mediaPath = useMediaPath()
    const { control, watch } = useFormContext()

    const hourValue = watch("hour")?.value

    const NotificationCheckboxes = [
        // TODO: uncomment when SMS is available
        // {
        //     id: 2,
        //     fieldName: "sms",
        //     label: "SMS",
        // },
        {
            fieldName: "email",
            id: 1,
            label: "Email",
        },
        {
            disabled: !isWebView,
            disabledMessage: "Télécharge l'application si ce n'est pas déjà fait pour recevoir les notifications.",
            fieldName: "push",
            id: 0,
            label: "Notification appli",
        },
    ]

    return (
        <div className={"slot-step step-3"} key={"step3"}>
            <div className={"slot-step-header"}>
                <img src={mediaPath([ "/assets/icons/favoriteSlot/step-notification.svg", "/assets/icons/favoriteSlot/padel/step-notification.svg" ])} alt={"Step 3"} />
                <h2>Comment te prévenir ?</h2>
            </div>
            <div className={"step-form-content gap16"}>
                {NotificationCheckboxes.map((checkbox) => (
                    <div key={checkbox.id + checkbox.fieldName}>
                        <Controller
                            defaultValue={false}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <div className="c-inputHolder--radioSquare">
                                        <input
                                            type="checkbox" id={checkbox.fieldName}
                                            name={checkbox.fieldName}
                                            onChange={onChange}
                                            checked={value}
                                            disabled={checkbox.disabled}
                                        />
                                        <label htmlFor={checkbox.fieldName}>{checkbox.label}</label>
                                    </div>
                                    {checkbox.disabledMessage && !isWebView && (
                                        <p className={"disabled-message"}>{checkbox.disabledMessage}</p>
                                    )}
                                </>
                            )}
                            name={`notifications.${checkbox.id}`}
                            disabled={checkbox.disabled}
                        />
                    </div>
                ))}
                {hourValue && (
                    <div
                        className={"alert-message"}
                        key={"alert-message"}
                    >
                        Tu seras alerté des créneaux libres entre {hourValue}h
                        et {(hourValue + 2) < 24 ? `${hourValue + 2}h` : "00h"}.
                    </div>
                )}
            </div>
        </div>
    )
}
