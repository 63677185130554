import classNames from "classnames"
import { arrayOf, bool, func, node, number, object, oneOfType, string } from "prop-types"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"

import NextStepButton from "./NextStepButton"
import PreviousStepButton from "./PreviousStepButton"
import StepHeader from "./StepHeader/StepHeader"

import "./Step.scss"

const Step = forwardRef(({ children, optionnal, otherId, numero, title, cls, className, onHeaderClick, help, helpId, onValidation, promise, onReturn, goToStep, goToPreviousStep, returnText, noReturn, customHeaderClass, hideNextBtn, checkBeforeNextStep, articleCls, customSkip, nextText }, ref) => {
    const [ valid, setValid ] = useState(false)
    const [ recap, setRecapState ] = useState(undefined)

    const customTitle = cls?.includes("disabled") && title === "Créneaux disponibles" ?
        "Choix du créneau"
        :
        title
    const isCompact = cls !== undefined ? cls.includes("compact") : false
    const id = (numero) ? numero : otherId

    const stepHead = useRef(null)

    useImperativeHandle(
        ref,
        () => ({
            getRecap: () => recap,
            setRecap: rec => setRecapState(rec),
            unvalidate: () => setValid(false),
            validate: () => setValid(true),
        }),
    )

    return (
        <div
            className={classNames("step ", {
                [className]: className,
                [cls]: cls,
                disabled: !valid,
                optionnal: optionnal,
                "valid enabled": valid,
            })}
            ref={ref}
        >
            <article
                className={classNames("stepArticle", { [articleCls]: articleCls })}
                id={"step-article-" + id }
            >
                <StepHeader
                    numero={numero}
                    title={isCompact && recap !== undefined ? recap : customTitle}
                    optionnal={optionnal}
                    onHeaderClick={
                        onHeaderClick ?
                            () => {
                                onHeaderClick(numero - 1)
                            }
                            :
                            undefined
                    }
                    help={ help }
                    helpId={helpId}
                    customClass={customHeaderClass}
                    customSkip={customSkip}
                    ref={stepHead}
                />

                <section className="content stepContent">
                    { children }
                </section>
            </article>
            {!noReturn && (
                <PreviousStepButton
                    text={returnText}
                    callback={onReturn}
                    goToStepProps={goToPreviousStep}
                    height={
                        stepHead.current ?
                            stepHead.current.offsetHeight
                            :
                            undefined
                    }
                />
            )}
            { (id !== "last" && !hideNextBtn) && (
                <NextStepButton
                    checkBeforeNextStep={checkBeforeNextStep}
                    callback={onValidation}
                    promise={promise}
                    goToStepProps={goToStep}
                    text={nextText}
                />
            )}
        </div>
    )
})

Step.propTypes = {
    articleCls: oneOfType([ bool, string ]),
    checkBeforeNextStep: func,
    children: oneOfType([ string, node, arrayOf(node) ]),
    className: string,
    cls: string,
    customHeaderClass: string,
    customSkip: oneOfType([ bool, func ]),
    goToPreviousStep: oneOfType([ func, number ]),
    goToStep: oneOfType([ func, number ]),
    help: oneOfType([ string, node, arrayOf(node) ]),
    helpId: string,
    hideNextBtn: bool,
    nextText: oneOfType([ bool, node, string ]),
    noReturn: oneOfType([ object, bool ]),
    numero: oneOfType([ string, number ]),
    onHeaderClick: func,
    onReturn: func,
    onValidation: func,
    optionnal: bool,
    otherId: oneOfType([ string, number, bool ]),
    promise: bool,
    returnText: oneOfType([ bool, node, string ]),
    title: string,
}

Step.displayName = "Step"

export default Step
