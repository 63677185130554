import { bool, func, number, oneOfType, string } from "prop-types"
import React, { Component } from "react"

import Form from "../../../../../components/Forms/Form/Form"
import FormValidator from "../../../../../components/Forms/FormValidator"
import InputDate from "../../../../../components/Forms/InputDate/InputDate"
import { SelectInput } from "../../../../../components/Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../../../../components/Inputs/TextInput/TextInput.jsx"

import "./ChildForm.scss"

class ChildForm extends Component {
    constructor(props) {
        super(props)

        this.state = {}

        this.form = React.createRef()
        this.formValidator = React.createRef()

        this.clear = this.clear.bind(this)
        this.getInfo = this.getInfo.bind(this)
        this.validate = this.validate.bind(this)

        this.equipements = {
            "0":  "XS Enfant (5-6 ans – Entre 122 et 128 cm)",
            "1":  "S Enfant (7-8 ans – Entre 128 et 137 cm)",
            "2":  "M Enfant (9-10 ans – Entre 137 et 147 cm)",
            "3":  "L Enfant (11-12 ans – Entre 147 et 158 cm)",
            "4":  "XL Enfant (13 ans – Entre 158 et 170 cm)",
            "5":  "S Adulte (14 ans – Entre 170 et 175 cm)",
            "6":  "M Adulte (15-16 ans – Entre 175 et 182 cm)",
            "7":  "L Adulte (16-17 ans – Entre 182 et 190 cm)",
            "8":  "XL Adulte (17 ans – à partir de 190 cm)",
        }

        this.positions = {
            "0" : "Gardien",
            "1" : "Défenseur",
            "2" : "Attaquant",
        }

        this.genders = {
            "Fille": "Fille",
            "Garçon": "Garçon",
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.id !== state.id) {
            let date = props.birthDate
            if (date !== undefined) {
                if (date.includes("T")) {
                    date = date.split("T")[0].split("-").reverse().join("/")
                }
                date = date.split("/")
                date = date.reverse()
                date = date.join("-")
            }

            var isNotNull = (x) => {
                return x !== null && x !== undefined
            }

            const data = {
                date: date,
                equipment: isNotNull(props.equipment) ? props.equipment.toString() : undefined,
                firstName: props.firstName,
                gender: props.gender,
                id: props.id,
                lastName: props.lastName,
                position: isNotNull(props.position) ? props.position.toString() : undefined,
            }

            return data
        }

        // Return null if the state hasn't changed
        return null
    }

    clear() {
        this.form.current.querySelectorAll("input").forEach(element => {
            element.value = ""
        })
    }

    getInfo() {
        return this.state
    }

    validate() {
        return this.formValidator.current ? this.formValidator.current.validate() : undefined
    }
    componentDidUpdate() {
        const { onUnvalidation, tunnelType, validateStep } = this.props
        const { equipment, firstName, lastName, date, gender } = this.state
        if (tunnelType === "TRAINING" || tunnelType === "SCHOOL") {
            if (!equipment || !firstName || !lastName || !date || !gender) {onUnvalidation()}
            else if (equipment && firstName && lastName && date && gender && validateStep) {validateStep()}
        } else if (tunnelType === "BIRTHDAY") {
            if (!firstName || !lastName || !date || !gender) {onUnvalidation()}
            else if (firstName && lastName && date && gender && validateStep) {validateStep()}
        }
    }
    componentWillUnmount() {
        this.props.onUnvalidation()
    }

    render() {
        const { onValidation, onUnvalidation, tunnelType } = this.props
        const { equipment, firstName, lastName, date, gender, position } = this.state

        const customSetState = (key, value) => {
            this.setState({
                [key]: value,
            })
        }

        return (
            <FormValidator onValidation={onValidation} onUnvalidation={onUnvalidation} ref={this.formValidator}>
                <Form
                    ref={this.form}
                    title={
                        (firstName !== undefined && lastName !== undefined) ? (
                            <React.Fragment>
                                {firstName} <strong>{lastName}</strong>
                            </React.Fragment>)
                            : 
                            "Ajouter un enfant"
                    }
                    desc={
                        this.props.kidId ? 
                            "Merci de vérifier et compléter les informations suivantes" 
                            : 
                            "Veuillez remplir les informations concernant votre enfant"
                    }
                >
                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <TextInput
                            label="Prénom"
                            value={firstName}
                            onChange={(v) => {
                                customSetState("firstName", v)
                            }}
                            required
                        />
                    </div>

                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <TextInput
                            label="Nom"
                            value={lastName}
                            onChange={(v) => {
                                customSetState("lastName", v)
                            }}
                            required
                        />
                    </div>

                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <InputDate
                            label={(
                                <span>Date de naissance 
                                    <span className="label-star">*</span>
                                </span>
                            )}
                            bind={[ date, "date", customSetState ]}
                            max={new Date()} 
                            required
                        />
                    </div>

                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <SelectInput
                            label="Genre"
                            required
                            value={gender ?? ""}
                            onChange={(e) => {
                                customSetState("gender", e?.target?.value)
                            }}
                            options={Object.entries(this.genders).map(([ value, label ]) => {
                                return { label: label, value: value }
                            })}
                        />
                    </div>

                    {(tunnelType === "SCHOOL" || tunnelType === "TRAINING") &&  (
                        <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                            <SelectInput
                                label="Equipement"
                                required
                                value={equipment ?? ""}
                                onChange={(e) => {
                                    customSetState("equipment", e?.target?.value)
                                }}
                                options={Object.entries(this.equipements).map(([ value, label ]) => {
                                    return { label: label, value: value }
                                })}
                            />
                        </div>
                    )}
                    { tunnelType === "TRAINING" &&
                        (<div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                            <SelectInput
                                label="Poste"
                                value={position ?? ""}
                                onChange={(e) => {
                                    customSetState("position", e?.target?.value)
                                }}
                                options={Object.entries(this.positions).map(([ value, label ]) => {
                                    return { label: label, value: value }
                                })}
                            />
                        </div>)
                    }
                </Form>
            </FormValidator>
        )
    }
}

ChildForm.propTypes = {
    birthDate: string,
    equipment: oneOfType([ number, string ]),
    firstName: string,
    gender: string,
    id: number,
    kidId: number,
    lastName: string,
    onUnvalidation: func,
    onValidation: func,
    position: oneOfType([ number, string ]),
    tunnelType: string,
    validateStep: oneOfType([ bool, func ]),
}

export default ChildForm
