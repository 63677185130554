export default async function getBase64ImageFromUrl(imageUrl) {
    let res = await fetch(`/proxy.php?url=${imageUrl}`)
    let base = await res.blob()

    return new Promise((resolve, reject) => {
        let reader  = new FileReader()
        reader.addEventListener("load", function () {
            resolve(reader.result)
        }, false)

        reader.onerror = () => {
            return reject(this)
        }
        reader.readAsDataURL(base)
    })
}
