import dayjs from "dayjs"

import { serializationStrategies } from "./serialization/serializationStrategies"
import { hourOptions } from "../../../../../components/HoursList/HoursList"
import playersNumber from "../../../../../components/reservation/LocationBlock/playersNumber"
import { getArdoises, getUsCentersArea, getUsCentersBookable } from "../../../../../globalAPI/api"
import locationHours from "../../../../../model/data/reservation/locationHours"
import ModalHandler from "../../../../../providers/Modal/ModalHandler"
import { getTypeIdsFromCategoryId } from "../../../../profil/FavoriteSlots/functions"
import { SlotListModal } from "../../../../profil/FavoriteSlots/SlotListModal/SlotListModal"
import { SlotModal } from "../../../../profil/FavoriteSlots/SlotModal/SlotModal"
import { getActivitiesForRessourcesTypes } from "../../services/function/functions"
import sortCenters from "../../services/sortCenters"

export const formArgs = {
    ACTIVITY_ID: "activity",
    CENTER_ID: "centerId",
    DATE: "date",
    DISCOUNT_FILTER: "discount",
    DURATION: "durationFilter",
    FROM_NOTIFICATION: "fromNotif",
    HOUR: "time",
    PREBOOK_DISCOUNT_ID: "discountId",
    PREBOOK_DISCOUNT_LABEL: "discountLabel",
    PREBOOK_DURATION: "duration",
    PREBOOK_HOUR: "hour",
    PREBOOK_MN: "mn",
    SLOT_DATETIME: "datetime",
    SLOT_ID: "slot",
    TIME_FILTER: "timePeriod",
    TYPE: "type",
    TYPE_IDS: "typeresource",
}

export const timeFilters = [
    {
        displayEnd: "11h30",
        displayStart: "09h00",
        end: "T11:59:00",
        name: "morning",
        start: "T08:59:00",
    },
    {
        displayEnd: "17h30",
        displayStart: "12h00",
        end: "T17:59:00",
        name: "afternoon",
        start: "T11:59:00",
    },
    {
        displayEnd: "00h00",
        displayStart: "18h00",
        end: "T23:59:00",
        name: "night",
        start: "T17:59:00",
    },
]

export const durationString = [ "45 min", "1 heure", "1 heure 30", "2 heures" ]
export const durationNumber = [ 45, 60, 90, 120 ]

export function getAppropriateHeadingTitle(theme, language) {
    if (theme === "theme-padel") {
        return `1/ Je réserve une ${language}`
    } else {
        return `1/ Je réserve un ${language}`
    }
}

export function formatAndUpdateSearchParams(values, setSearchParams, excludeKeys = []) {
    const formattedSearchParams = new URLSearchParams()

    Object.entries(values).forEach(([ key, value ]) => {
        if (excludeKeys.includes(key)) {
            return
        }

        const strategies = serializationStrategies()

        if (strategies[key]) {
            value = strategies[key](value)
        }

        switch (true) {
        case Array.isArray(value):
            formattedSearchParams.set(key, value.join(","))
            break
        case value instanceof Date:
            formattedSearchParams.set(key, dayjs(value).format("YYYY-MM-DD"))
            break
        case value !== null && value !== undefined && value !== "":
            formattedSearchParams.set(key, value)
            break
        }
    })

    setSearchParams(formattedSearchParams)
}

export function fetchCentersAndActivities(themeHeader, userLoaded, params) {
    return Promise.all([
        getUsCentersBookable(themeHeader),
        getUsCentersArea(),
        getArdoises(),
    ])
        .then(([ res, resu, ardoises ]) => {
            if (res?.data && resu?.data) {
                const areas = resu.data
                const tempCenters = Object.values(res.data)
                const sortedCenters = sortCenters(tempCenters)

                let selectedCenterData = null
                let centerOptions = sortedCenters

                if (params) {
                    params = Object.fromEntries(params)
                }

                if (!params?.centerId && userLoaded?.centerId) {
                    const favCenterArea = areas?.find(area => area.centers?.includes(userLoaded.centerId))
                    const haveOtherCenters = favCenterArea?.centers?.filter(center => center !== userLoaded.centerId && sortedCenters[center])?.[0]
                    const haveFavCenter = sortedCenters.findIndex(center => center[0] === userLoaded.centerId)
                    const favIndex = haveFavCenter === -1 && haveOtherCenters ? sortedCenters.findIndex(center => center[0] === haveOtherCenters) : haveFavCenter

                    if (favIndex !== -1) {
                        const favCenter = sortedCenters[favIndex]
                        sortedCenters.splice(favIndex, 1)
                        sortedCenters.unshift(favCenter)
                        const centerToSelect = sortedCenters.find(center => center[0] === favCenter[0])
                        const centerToSelectActivities = getActivitiesForRessourcesTypes(centerToSelect?.types, themeHeader)

                        selectedCenterData = {
                            bookableCenter: sortedCenters,
                            centerActivities: centerToSelectActivities,
                            proximityCenters: favCenter.proximity,
                            selectedCenterId: parseInt(favCenter[0]),
                            selectedCenterTypes: favCenter.types,
                        }
                    }
                } else if (params?.centerId) {
                    const selectedCenterId = parseInt(params.centerId)
                    const centerToSelect = sortedCenters.find(center => center[0] === selectedCenterId)

                    selectedCenterData = {
                        selectedCenterId: selectedCenterId,
                        selectedCenterTypes: centerToSelect.types,
                    }
                } else {
                    selectedCenterData = {
                        bookableCenter: sortedCenters,
                        centerActivities: [],
                        proximityCenters: [],
                        selectedCenterId: null,
                        selectedCenterTypes: [],
                    }
                }

                return {
                    ardoises: ardoises?.items?.length > 0,
                    centerOptions: centerOptions,
                    selectedCenterData: selectedCenterData ?? null,
                }
            }

            return null
        })
        .catch(error => {
            // eslint-disable-next-line no-console
            console.error("Error fetching data:", error)
            return null
        })
}

export const handleError = (error, date, tu) => {
    let errorMessage = ""

    if (error.Message) {
        if (error.Data?.MaximumFutureReservationPerContact) {
            errorMessage = `Tu as atteint le nombre maximum de ${error.Data.MaximumFutureReservationPerContact} locations ${tu("shortName")} à venir.`
        } else if (error.Data?.ExtranetReservationPeriodLimit || error.Data?.ExtranetReservationPeriodLimit === 0) {
            if (error.Data?.ExtranetReservationPeriodLimit === 0) {
                errorMessage = "Les réservations en ligne sont momentanément indisponibles."
            } else {
                const choosenDate = dayjs(dayjs(date).format("YYYY-MM-DD"))
                const now = dayjs(new Date()).format("YYYY-MM-DD")
                const openDays = choosenDate.diff(now, "d") - error.Data?.ExtranetReservationPeriodLimit + 1
                errorMessage = `Les réservations en ligne ne sont pas encore ouvertes pour cette date. Elles ouvriront dans ${openDays} jour${openDays > 1 ? "s" : ""}.`
            }
        } else if (error.Message.includes("Veuillez-vous rapprocher directement de votre centre pour réserver un second terrain le même jour ou sélectionner une autre date.")) {
            errorMessage = `Tu as déjà réservé un terrain dans un centre ${tu("name")} pour le jour demandé. Rapproche-toi directement de ton centre pour réserver un second terrain le même jour ou sélectionne une autre date.`
        } else {
            errorMessage = error.Message
        }
    }

    return errorMessage
}

export function compareArrays(array1, array2) {
    return array1.length === array2.length && array1.every((value, index) => value === array2[index])
}

export const handleSlotModal = ({
    slots = [],
    selectedSlot,
    bookingInfosData,
    userLoaded,
    refreshUser,
    selectedDate,
    typeFilter,
    timeFilter,
    hourFilter,
    centerId,
    onSlotSelected = null,
    fromAlert = false,
    activityId,
}) => {
    let type = getTypeIdsFromCategoryId(activityId, bookingInfosData.centerOptions)

    const preSelectionValues = {
        center: centerId,
        day: dayjs(selectedDate).get("day"),
        hour: hourOptions.find(option => option.display === hourFilter?.display),
        type: typeFilter?.length > 0 ? typeFilter[0][0] : null,
    }

    if ((!typeFilter || typeFilter?.length === 0) && activityId) {
        preSelectionValues.type = type
    }

    if ((!hourFilter || hourFilter === "noHourFilter") && timeFilter) {
        preSelectionValues.hour = hourOptions?.find(
            option => option.display === locationHours?.find(
                option => option.value === timeFilter.start,
            )?.display)
    }

    if (slots.length > 0 && !fromAlert) {
        ModalHandler.show(SlotListModal, {
            onSlotSelected: onSlotSelected,
            preSelectionValues: slots.length < 3 ? preSelectionValues : null,
            slots: slots,
        })
    } else {
        ModalHandler.show(SlotModal, {
            bookables: bookingInfosData.centerOptions,
            onSlotCreated: refreshUser,
            preSelectionValues: (selectedSlot && fromAlert) ? null : preSelectionValues,
            slot: (selectedSlot && fromAlert) ? selectedSlot : null,
            user: userLoaded,
        })
    }
}

export function attemptToRecoversPreBookSlots(data, availableDiscounts, prebook, selectedActivity, date, currentCenterId) {

    if (parseInt(currentCenterId) !== parseInt(prebook.centerId)) {
        return false
    }

    const preBookDuration = durationString.findIndex(duration => duration === prebook.duration)

    const isPreReservationStillAvailable = data.findIndex(
        slot => dayjs(slot.start).format("YYYY-MM-DD-HH-mm") === dayjs(prebook.start).format("YYYY-MM-DD-HH-mm") && slot.resourceType === prebook.resourceType && slot.duration === durationNumber[preBookDuration],
    )
    const prebookCategory = playersNumber.find(activity => activity.types.includes(prebook.resourceType))
    const indexOfCategory = prebookCategory.types.findIndex(type => type === prebook.resourceType)
    const category = indexOfCategory !== -1 && prebookCategory.categories[indexOfCategory]

    const isSameDate = dayjs(prebook.start).isSame(date, "day")

    if (
        isPreReservationStillAvailable === -1 &&
            category === parseInt(selectedActivity) &&
            isSameDate
    ) {
        return {
            centerId: prebook.centerId,
            centerName: prebook.centerName,
            count: 1,
            discounts: prebook.discount ? [ prebook.discount ] : null,
            duration: durationNumber[preBookDuration],
            durationDisplay: prebook.duration,
            end: prebook.end,
            price: prebook.price,
            resourceType: prebook.resourceType,
            resourceTypeDisplay: prebook.resourceTypeDisplay,
            start: prebook.start,
            type: prebook.type,
            typeDisplay: prebook.typeDisplay,
        }
    } else {
        return false
    }
}

export function filterBookables(bookables, type) {
    return bookables?.filter(bookable => bookable.types.includes(type))
}

export const createFakeSlot = (slot, types, typeIndex = 0) => {
    return {
        centerId: slot.center,
        faked: true,
        price: 0,
        resourceType: parseInt(slot.type[typeIndex]),
        resourceTypeDisplay: types?.find(type => type.key === parseInt(slot.type[typeIndex]))?.value,
        start: dayjs(slot.date).hour(slot.start).format("YYYY-MM-DDTHH:mm:ss"),
    }
}
