import classNames from "classnames"
import { arrayOf, bool, func, node, number, object, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { useCanDownload } from "../../../hooks/useCanDownload.jsx"
import useMediaPath from "../../../hooks/useMediaPath"

import "./PlayerLine.scss"

function PlayerLine({
    customCls,
    isPlayerMVP,
    userMVPVote,
    isMVPVoteOpened,
    onClick,
    playerScored,
    isMatchPlayed,
    isUserPlayer,
    isSelected,
    selectPlayer,
    invite,
    buyerContact,
    buyer,
    leftPart,
    rightPart,
    isOwner,
    bottomPart,
    isProfil,
    isLocationInvit,
    tunnelType,
    download,
    clickable,
    displayTeam,
}) {
    const [ vote, setVote ] = useState(userMVPVote)
    const userLoaded = useSelector(state => state.userLoaded)
    const mediaPath = useMediaPath()
    const { check, message } = useCanDownload()

    const rightIcons = (
        <>
            {playerScored > 0 && (
                <div className="score">
                    <img src="/assets/icons/score-icon.svg" />
                    <span className={playerScored > 9 ? "two-digits" : ""}>{playerScored}</span>
                </div>
            )}
            {((invite?.supersubResponse?.status === 1) || (invite?.playerInvite?.supersubResponse?.status === 1)) && 
                <img src={mediaPath([ "/assets/images/supersub--orange.svg", "/assets/images/padel/icons/icon-ultimo.svg" ])}/>
            }
            {(!isUserPlayer && isMVPVoteOpened) && (
                <div
                    data-testid="vote"
                    className={vote ? "selected star" : "star"}
                    onClick={(e) => {
                        e.stopPropagation()
                        /* istanbul ignore else */
                        if (isMVPVoteOpened) {
                            setVote(!vote)
                            onClick(invite.playerId)
                        }
                    }}
                />
            )}
            {isMatchPlayed && !isMVPVoteOpened && isPlayerMVP && (
                <div
                    className="selected star voteClosed"
                />
            )}
        </>
    )

    useEffect(
        () => {
            setVote(userMVPVote)
        },[ userMVPVote ],
    )

    const inviteIsOwnerAndConnected = (isOwner && userLoaded.contactId !== invite.playerId) || (isOwner && userLoaded.contactId === invite.playerId && clickable) 

    return (
        <div
            className={classNames("player-line", {
                [customCls]: customCls,
            })}
            data-testid="selectPlayer"
            onClick={
                (inviteIsOwnerAndConnected && customCls && customCls === "isClickable") || isProfil || isLocationInvit ?
                    () => {
                        if (isLocationInvit) {
                            selectPlayer(invite.ozmoSystemId)
                        } else if (isSelected) {
                            selectPlayer(null)
                        } else {
                            if (isProfil) {
                                selectPlayer(invite)
                            } else {
                                selectPlayer(invite.playerId)
                            }
                        }
                    }
                    :
                    undefined
            }
        >
            <div className={isSelected ? "player-line__main selected" : "player-line__main"}>
                <div className="left-part">

                    {leftPart ? (
                        <>
                            {leftPart}
                        </>
                    ) : invite ? (
                        <>
                            {invite.pictureUrl ?
                                <img src={invite.pictureUrl}/>
                                :
                                <img src="/assets/images/empty-avatar.svg"/>
                            }
                            <span className="name">
                                {invite.firstname} {tunnelType && tunnelType === "PROFIL" ? invite.lastname : invite.lastname[0] + "."}
                            </span>
                            {displayTeam && !isSelected && <span className="teamName">{displayTeam}</span>}
                        </>
                    ) : buyer && (
                        <>
                            {buyer.contact && buyer.contact.pictureUrl ?
                                <img src={buyer.contact.pictureUrl}/>
                                : buyer.pictureUrl ?
                                    <img src={buyer.pictureUrl}/>
                                    :
                                    <img src="/assets/images/empty-avatar.svg"/>
                            }
                            <span className="name">{buyer.buyerName ? buyer.contact.firstname + " " + buyer.contact.lastname.charAt(0) : buyer.firstname + " " + buyer.lastname.charAt(0)}.</span>
                        </>
                    )}
                </div>

                {rightPart ? (
                    <div className="right-part">
                        {rightPart}
                    </div>
                ) : isOwner || isProfil ? (
                    <div className="right-part">
                        {rightIcons}
                        {(
                            (inviteIsOwnerAndConnected || isProfil)
                            && customCls
                            && customCls === "isClickable"
                        ) && (
                            <div
                                className={
                                    "three-dots" + (isSelected ?
                                        " active"
                                        :
                                        ""
                                    )
                                }
                            >
                                <div className="dot"></div>
                            </div>
                        )}
                    </div>
                ) : buyer ? (
                    <span className="right-part">
                        {(
                            (buyerContact && buyerContact.supersubResponse && buyerContact.supersubResponse.status === 1)
                            || (buyer.supersubResponse && buyer.supersubResponse.status === 1)
                        ) &&
                            <img src={mediaPath([ "/assets/images/supersub--orange.svg", "/assets/images/padel/icons/icon-ultimo.svg" ])}/>
                        }
                        <span className={"amount"}>
                            {buyer.amount ?
                                (buyer.amount % 1 === 0 ? buyer.amount : buyer.amount.toFixed(2)) + "€"
                                :
                                buyer.supersubResponse ?
                                    <span className="standby">Offert</span>
                                    :
                                    <span className="standby">En attente</span>
                            }
                            {download && (<img src={mediaPath([ "/assets/icons/download.svg", "/assets/images/padel/icons/download.svg" ])} className="download" onClick={() => {
                                check(() => download())
                            }}/>)}
                        </span>
                    </span>
                ) : invite && (
                    <div className="right-part">
                        {rightIcons}
                    </div>
                )}
            </div>
            {message}
            { (isSelected && bottomPart) && 
                bottomPart
            }
        </div> 
    )
}

PlayerLine.propTypes = {
    bottomPart: oneOfType([ string, node, arrayOf(node) ]),
    buyer: object,
    buyerContact: object,
    clickable: bool,
    customCls: oneOfType([ string, bool ]),
    displayTeam: oneOfType([ bool, string ]),
    download: oneOfType([ bool, func ]),
    invite: object,
    isLocationInvit: bool,
    isMVPVoteOpened: bool,
    isMatchPlayed: bool,
    isOwner: bool,
    isPlayerMVP: bool,
    isProfil: bool,
    isSelected: bool,
    isUserPlayer: bool,
    leftPart: oneOfType([ string, node, arrayOf(node) ]),
    onClick: func,
    playerScored: oneOfType([ number, bool ]),
    rightPart: oneOfType([ string, node, arrayOf(node) ]),
    selectPlayer: func,
    tunnelType: oneOfType([ bool, string ]),
    userMVPVote: oneOfType([ number, bool ]),
}

export default PlayerLine
