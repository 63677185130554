import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

function useTeamSubscriptionLoaded() {
    const [ loadingTeamSubsIds,       setLoadingTeamSubsIds ]       = useState(false)
    const alreadySubscribedCupIds = useSelector(state => state.forms.alreadySubscribedCupIds)
    const alreadySubscribedLeagueIds = useSelector(state => state.forms.alreadySubscribedLeagueIds)

    useEffect(
        () => {
            if (alreadySubscribedCupIds || alreadySubscribedLeagueIds) {
                setLoadingTeamSubsIds(false)
            } else {
                setLoadingTeamSubsIds(true)
            }
        }, [ alreadySubscribedCupIds, alreadySubscribedLeagueIds ],
    )

    return {
        alreadySubscribedCupIds: alreadySubscribedCupIds,
        alreadySubscribedLeagueIds: alreadySubscribedLeagueIds,
        loadingTeamSubsIds: loadingTeamSubsIds,
    }
}

export default useTeamSubscriptionLoaded
