import classNames from "classnames"
import { array, bool, string } from "prop-types"
import { useDispatch } from "react-redux"
import {
    NavLink,
} from "react-router-dom"

import { selectHamburgerMenu } from "../../../../../store/actions/scaffold"

import "./overlayMenuLvl2.scss"
    
function OverlayMenuLvl2({ isOpen, parentId, lvl2Items }) {

    const dispatch = useDispatch()
    
    return (
        <div
            id={parentId + "-lvl2"} 
            className={classNames("overlayMenuItemLvl2", { overlayMenuItemLvl2Positionned : isOpen })}
        >  
            <div className="list-parent-part">
                <div className="list-part">
                    {Object.entries(lvl2Items).map(
                        ([ name, infos ]) => {
                            if (infos.externalLink) {
                                return (
                                    <a
                                        id={name}
                                        className={"list-part-item-link"}
                                        href={infos.externalLink}
                                        key={"ron-" + name}
                                    >
                                        {infos.label} 
                                    </a>
                                )
                            } else if (infos.appRoute) {
                                return (
                                    <NavLink
                                        to={infos.appRoute}
                                        className={
                                            ({ isActive }) => classNames({
                                                [infos.customCls]: infos.customCls,
                                                "list-part-item": !isActive,
                                                "list-part-item-link selected": isActive,
                                            })
                                        }
                                        id={name}
                                        onClick={() => dispatch(selectHamburgerMenu(false))}
                                        key={"rom-" + name}
                                    >
                                        { infos.hasNotif && (
                                            <img
                                                className="notif"
                                                src={"/assets/icons/icons-graphics-notifications-orange.svg"}
                                                style={{ visibility: isOpen ? "visible" : "hidden" }}
                                            />
                                        )}
                                        {infos.label}
                                    </NavLink>
                                )
                            } else {
                                return (
                                    "Missing appRoute or externalLink"
                                )
                            }
                        },
                    )}
                </div>
            </div>   
        </div>
    )

}

OverlayMenuLvl2.propTypes = {
    isOpen: bool,
    lvl2Items: array,
    parentId: string,
} 

export default OverlayMenuLvl2
