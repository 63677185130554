import { hourOptions } from "../../../../../../components/HoursList/HoursList"
import { formArgs } from "../function"

export const serializationStrategies = () => {
    return {
        [formArgs.HOUR]: hourFilterSerialization,
        [formArgs.TYPE_IDS]: (value) => value?.join(",") ?? null,
    }
}

const hourFilterSerialization = (value) => {
    return hourOptions.find(
        option => option.display === value.display,
    )?.value || null
}
