import html2canvas from "html2canvas"
import { useEffect, useState } from "react"

import { getPlayersComposition, modifyPlayersComposition, registerLocationTeamsNames } from "./api"
import CompositionAside from "../../../../components/CompositionField/CompositionAside/CompositionAside"
import CompositionField from "../../../../components/CompositionField/CompositionField"
import Preloader from "../../../../components/loaders/preloader/preloader"
import playersNumber from "../../../../components/reservation/LocationBlock/playersNumber"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext"
import useDebounce from "../../../../hooks/useDebounce"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import { getTeamNames, processPlayersCompositionResponse } from "../../../../services/locationManagementService"
import mustChangeTeamsNames from "../../../../services/mustChangeTeamsNames"
import { shuffleArray } from "../../../../services/shuffleArray"
import { useLocationStore } from "../../store/store.js"
import BookingInvitation from "../components/BookingInvitation/BookingInvitation.jsx"

import "./LocationCompositionManagement.scss"

function LocationCompositionManagement() {
    const { currentLocation, setLocationComposition, setCurrentLocation } = useLocationStore()

    const [ players, setPlayers ] = useState(currentLocation?.invites?.filter(player => player.status === 1))
    const [ team1Name, setTeam1name ] = useState(currentLocation?.team1CustomName ? currentLocation?.team1CustomName : currentLocation?.team1Name)
    const [ team2Name, setTeam2name ] = useState(currentLocation?.team2CustomName ? currentLocation?.team2CustomName : currentLocation?.team2Name)
    const [ selectedPlayer, setSelectedPlayer ] = useState(null)
    const [ emptyCaseSelection, setEmptyCaseSelection ] = useState(null)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ playersBenchList, setPlayersBenchList ] = useState([])
    const [ goals, setGoals ] = useState([])
    const [ initialized, setInitialized ] = useState(false)

    const themeHeader = useThemeHeader()
    const theme = useTheme()

    const locationPlayersNumber = currentLocation ? playersNumber?.find(item => item.types.includes(currentLocation.resourceType))?.players : 10
    const teamDisplay = locationPlayersNumber ?
        Array.from(Array(locationPlayersNumber / 2)).map((_, i) => `${i + 1}`)
        :
        [ "joueur1", "joueur2", "joueur3", "joueur4", "joueur5" ]
    
    const formation = "1-1-2-1"

    const { debounce } = useDebounce()

    const formatTeamPlayers = (teamArray) => {
        const object = { formation: formation }
        teamArray.forEach(player => {
            object[player.teamSlot] = player
        })
        return object
    }

    const playerScored = (player) => {
        if (player && goals && goals.length) {
            return goals.reduce(
                (goalsCount, newGoalsCount) => {
                    if (newGoalsCount.playerId === player.playerId) {
                        return goalsCount + 1
                    } else {
                        return goalsCount
                    }
                }, 0,
            )
        }
        return false
    }

    const handlePlayersFilling = (player, fieldPlace) => {
        
        if ((!selectedPlayer && !emptyCaseSelection)
        || (emptyCaseSelection && !player)
        || (selectedPlayer && !selectedPlayer.teamId && player && !player.teamId && selectedPlayer.id !== player.id)) {
            
            player && !emptyCaseSelection ?
                setSelectedPlayer(player)
                : emptyCaseSelection && emptyCaseSelection[0] === fieldPlace[0] && emptyCaseSelection[1] === fieldPlace[1] ?
                    setEmptyCaseSelection(null) :
                    !player && fieldPlace && setEmptyCaseSelection(fieldPlace)
            
        } else if ((selectedPlayer && player) || (selectedPlayer && !player) || (emptyCaseSelection && player)) {
            const tempPlayers = players
            
            if (player && !emptyCaseSelection) {
                const indexOfPlayer = players.findIndex(person  => person.id === player.id)
                tempPlayers[indexOfPlayer].teamId = selectedPlayer.teamId
                tempPlayers[indexOfPlayer].teamSlot = selectedPlayer.teamSlot
            }
            
            if (emptyCaseSelection && player) {
                const playerIndex = players.findIndex(person  => person.id === player.id)
                tempPlayers[playerIndex].teamId = emptyCaseSelection[0]
                tempPlayers[playerIndex].teamSlot = emptyCaseSelection[1]
                setLocationComposition(tempPlayers)
                setPlayers(tempPlayers)
                saveTeamsCompositions(tempPlayers)

                return setEmptyCaseSelection(null)
            }
            const indexOfSelectedPlayer = players.findIndex(player => player.id === selectedPlayer.id)
            tempPlayers[indexOfSelectedPlayer].teamId = fieldPlace[0]
            tempPlayers[indexOfSelectedPlayer].teamSlot = fieldPlace[1]
            if (selectedPlayer && player && selectedPlayer.id === player.id) {
                return setSelectedPlayer(null)
            } else {
                setLocationComposition(tempPlayers)
                setPlayers([ ...tempPlayers ])
                saveTeamsCompositions(tempPlayers)
            }
            setSelectedPlayer(null)
        }
    }

    const removePlayerFromField = () => {
        const tempPlayers2 = players
        const playerIndex = players.findIndex(player => player.id === selectedPlayer.id)
        tempPlayers2[playerIndex].teamId = null
        tempPlayers2[playerIndex].teamSlot = null
        setSelectedPlayer(null)
        setLocationComposition(tempPlayers2)
        setPlayers([ ...tempPlayers2 ])
        saveTeamsCompositions(tempPlayers2)
    }

    const autoComposition = () => {
        const tempPlayers = players
        const fieldPositions = teamDisplay.map((_, i) => [ 1, i ]).concat(
            teamDisplay.map((_, i) => [ 2, i ]),
        )
        const emptyPositionsReducer = () => fieldPositions.reduce(((emptyFieldPositions, currentPosition) => {
            
            const tempReduce = emptyFieldPositions
            !players.some(player => player.teamId === currentPosition[0] 
                && player.teamSlot === currentPosition[1]) 
            && tempReduce.push(currentPosition)
            return tempReduce
        }),[])

        shuffleArray(fieldPositions)

        const autoFillField = (array) => {
            array.forEach(position => {
                if (noPositionPlayers.length > 0) {
                    const indexInPlayers = players.findIndex(player => player.id === noPositionPlayers[0].id)
                    tempPlayers[indexInPlayers].teamId = position[0]
                    tempPlayers[indexInPlayers].teamSlot = position[1]
                    noPositionPlayers.shift()
                }
            })
        }

        let noPositionPlayers = players?.filter(player => !player.teamId)
        let emptyPositions = emptyPositionsReducer()
        
        if (noPositionPlayers.length === 0 || emptyPositions.length === 0) {
            noPositionPlayers = players.map(player => {
                player.teamId = null
                player.TeamSlot = null
                return { ...player }
            })
            emptyPositions = fieldPositions
            autoFillField(emptyPositions)
        } else {
            autoFillField(emptyPositions)
        }
        setLocationComposition(tempPlayers)
        setPlayers([ ...tempPlayers ])
        saveTeamsCompositions(tempPlayers)
    }
    
    const nodeToImage = () => {
        const options = {}
        if (import.meta.env.VITE_URL_BASE === "http://localhost:3000") {
            options.proxy = "/imageProxy"
        } else {
            options.allowTaint = true
            options.useCORS = true
        }
        html2canvas(
            document.getElementById("change-composition"),
            options,
        ).then(function (canvas) {
            const dataUrl = canvas.toDataURL("image/jpeg")
            let link = document.createElement("a")
            link.download = "my-composition.jpeg"
            link.href = dataUrl
            link.click()
        })
    }

    const saveTeamsCompositions = (player) => {
        if (!player) {
            removePlayerFromField()
        }
        debounce(
            () => {
                const team1Players = player ? player.filter(player => player.teamId === 1) : players.filter(player => player.teamId === 1)
                const team2Players = player ? player.filter(player => player.teamId === 2) : players.filter(player => player.teamId === 2)
                modifyPlayersComposition(
                    currentLocation.id, {
                        team1: team1Players.length > 0 ? JSON.stringify(formatTeamPlayers(team1Players)) : JSON.stringify({ formation: formation }),
                        team2: team2Players.length > 0 ? JSON.stringify(formatTeamPlayers(team2Players)) : JSON.stringify({ formation: formation }),
                    }, themeHeader,
                ).then((res) => processPlayersCompositionResponse(res, themeHeader, null, setCurrentLocation, currentLocation))
            },
        )
    }

    useEffect(
        () => {
            if (currentLocation?.composition) {
                setPlayersBenchList(currentLocation?.composition?.filter(player => !player.teamId || player.teamSlot === null))
            } else {
                setPlayersBenchList(currentLocation.invites)
            }
        }, [ players, emptyCaseSelection ],
    )

    useEffect(
        () => {
            if (currentLocation?.composition && currentLocation?.id === currentLocation.id) {
                setPlayers(currentLocation.composition)
                setAjaxLoading(false)
                setInitialized(true)
            } else {
                if (currentLocation.id) {
                    setAjaxLoading(true)
                    getPlayersComposition(currentLocation.id, { activity: themeHeader }).then(
                        (res) => {
                            if (res?.location?.id) {
                                const loc = res.location
                                const presentsInvites = loc?.invites?.filter(player => player.status === 1)
                                const filteredAttendees = res?.attendees?.length > 0 ? presentsInvites?.map(player => ({
                                    ...player,
                                    teamId: res?.attendees?.find(attendee => player.id === attendee.id)?.teamId ,
                                    teamSlot: res?.attendees?.find(attendee => player.id === attendee.id)?.teamSlot,
                                })) : []

                                const data = {
                                    ...loc,
                                    composition: filteredAttendees,
                                    goal: res.goals ?? null,
                                    team1Name: team1Name,
                                    team2Name: team2Name,
                                }

                                if (theme === "theme-padel") {
                                    const { notCustomizedTeam1Name, notCustomizedTeam2Name } = mustChangeTeamsNames(currentLocation)
                                    const { team1Name, team2Name } = getTeamNames(filteredAttendees)
                                    const team1ToChange = notCustomizedTeam1Name ? team1Name : currentLocation.team1Name
                                    const team2ToChange = notCustomizedTeam2Name ? team2Name : currentLocation.team2Name
                                    data.team1Name = team1ToChange
                                    data.team2Name = team2ToChange
                                    data.team1CustomName = team1ToChange
                                    data.team2CustomName = team2ToChange
                                    registerLocationTeamsNames(loc.id, team1ToChange, team2ToChange, themeHeader)
                                    setTeam1name(team1ToChange)
                                    setTeam2name(team2ToChange)
                                }

                                setCurrentLocation({
                                    ...loc,
                                    composition: filteredAttendees,
                                    goal: res.goals ?? null,
                                    team1Name: data.team1Name,
                                    team2Name: data.team2Name,

                                })
                                setPlayers(filteredAttendees)

                            }
                            setAjaxLoading(false)
                            setInitialized(true)
                        },
                    )
                }
            }
            if (currentLocation?.goal) {
                setGoals(currentLocation.goal)
            }
        }, [ currentLocation?.id ],
    )

    useEffect(() => {
        setTeam1name(currentLocation?.team1CustomName ? currentLocation?.team1CustomName : currentLocation?.team1Name)
        setTeam2name(currentLocation?.team2CustomName ? currentLocation?.team2CustomName : currentLocation?.team2Name)
    }, [ currentLocation ])

    const { show } = ModalHandler.useReactiveModal(BookingInvitation, {
        location: currentLocation,
    })

    return (
        <div className="compo-management">
            {ajaxLoading && <Preloader fixed={true} />}
            {initialized && (
                <div className="composition">
                    <CompositionField
                        teamDisplay={teamDisplay}
                        players={players}
                        saveTeamsCompositions={saveTeamsCompositions}
                        handlePlayersFilling={handlePlayersFilling}
                        selectedPlayer={selectedPlayer}
                        emptyCaseSelection={emptyCaseSelection}
                        playerScored={playerScored}
                        team1Name={team1Name}
                        team2Name={team2Name}
                        composition={currentLocation}
                        location={currentLocation}
                        removePlayerFromField={removePlayerFromField}
                        ajaxLoading={ajaxLoading}
                    />
                    <CompositionAside
                        players={players}
                        playersBenchList={playersBenchList}
                        autoComposition={autoComposition}
                        nodeToImage={nodeToImage}
                        handlePlayersFilling={handlePlayersFilling}
                        selectedPlayer={selectedPlayer}
                        emptyCaseSelection={emptyCaseSelection}
                        team1Name={team1Name}
                        setTeam1Name={setTeam1name}
                        team2Name={team2Name}
                        setTeam2Name={setTeam2name}
                        ajaxLoading={ajaxLoading}
                        openInvitationPopin={show}
                    />
                </div>
            )}
            
        </div>
    )
}

export default LocationCompositionManagement
