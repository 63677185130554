import classNames from "classnames"
import { array, bool, func, object, oneOfType, string } from "prop-types"

import getFieldClassName from "../../services/getFieldClassName"
import getFieldSrc from "../../services/getFieldSrc"
import Player from "../reservation/Player/Player"

import "./CompositionField.scss"

export default function CompositionField({
    teamDisplay,
    players,
    saveTeamsCompositions,
    handlePlayersFilling,
    selectedPlayer,
    emptyCaseSelection,
    playerScored,
    team1Name,
    team2Name,
    removePlayerFromField,
    ajaxLoading,
    location,
    composition: playerComposition,
}) {
    const isOwner = location.isOwner

    const fieldClassName = getFieldClassName(location?.resourceType)
    const hasScore = location.scoreTeam1 !== null && location.scoreTeam2 !== null
    const whoIsWinner = typeof location.scoreTeam1 === "number" && typeof location.scoreTeam2 === "number" ? location.scoreTeam1 > location.scoreTeam2 : null
    const isDrawGame = location.scoreTeam1 && location.scoreTeam2 && location.scoreTeam1 === location.scoreTeam2
    const missingPlayers = location && (location.maxAttendees - (location.currentAttendees === 0 ? 1 : location.currentAttendees))
    const fieldSrc = getFieldSrc(location.resourceType)

    return (
        <div
            className={classNames("change-composition", fieldClassName, {
                invite : !isOwner && !ajaxLoading,
            })}
            id="change-composition">
            <img src={fieldSrc} className="field" />
            <div className="missingPlayers">
                {missingPlayers > 0 ? `${missingPlayers} joueur${missingPlayers > 1 ? "s" : ""} à recruter` : "Match complet"}
            </div>
            {isOwner && !ajaxLoading && (
                <>
                    <div className="team1">
                        {teamDisplay.map(
                            (p, i) => {
                                const player = playerComposition?.composition?.find(player => player.teamId === 1 && player.teamSlot === i)
                                return (
                                    <Player
                                        closeOnClick={() => saveTeamsCompositions()}
                                        key={i + "-team1-" + p}
                                        player={players.find(player => player.teamId === 1 && player.teamSlot === i)}
                                        onClick={handlePlayersFilling}
                                        fieldPlace={[ 1,i ]}
                                        selected={
                                            (players.find(player => player.teamId === 1 && player.teamSlot === i) && selectedPlayer &&
                                                selectedPlayer.id === players.find(player => player.teamId === 1 && player.teamSlot === i).id)
                                            || (emptyCaseSelection && emptyCaseSelection[0] === 1 && emptyCaseSelection[1] === i)
                                        }
                                        isMVP={player && location.mvp && player.playerId === location.mvp.id}
                                        goalCount={playerScored?.(player)}
                                    />
                                )
                            },
                        )}
                        <div className={whoIsWinner && !isDrawGame ? "team1Name winner" : "team1Name"}>
                            {team1Name === "" ? "Team Urban" : team1Name}
                            {hasScore && <span>{location.scoreTeam1}</span>}
                        </div>
                    </div>
                    <div className="team2">
                        {teamDisplay.map(
                            (p, i) => {
                                const player = playerComposition?.composition?.find(player => player.teamId === 2 && player.teamSlot === i)
                                return (
                                    <Player
                                        closeOnClick={removePlayerFromField}                                    
                                        key={i + "-team2-" + p}
                                        player={player}
                                        onClick={handlePlayersFilling}
                                        fieldPlace={[ 2,i ]}
                                        selected={
                                            (player && selectedPlayer && selectedPlayer.id === player.id)
                                            || (emptyCaseSelection && emptyCaseSelection[0] === 2 && emptyCaseSelection[1] === i)
                                        }
                                        isMVP={player && location.mvp && player.playerId === location.mvp.id}
                                        goalCount={playerScored?.(player)}
                                    />
                                )
                            },
                        )}
                        <div
                            className={!whoIsWinner && whoIsWinner !== null && !isDrawGame ? "team2Name winner" : "team2Name"}
                        >
                            {team2Name === "" ? "Team Soccer" : team2Name}
                            {hasScore && <span>{location.scoreTeam2}</span>}
                        </div>
                    </div>
                </>
            )}
            {!isOwner && !ajaxLoading && (
                <>
                    <div className="team1">
                        {teamDisplay.map(
                            (p, i) => {
                                const player = players?.find(player => player.teamId === 1 && player.teamSlot === i)
                                return (
                                    <Player
                                        key={i + "-team1-" + p}
                                        player={player}
                                        fieldPlace={[ 1,i ]}
                                        isMVP={player && location.mvp && player.playerId === location.mvp.id}
                                        goalCount={playerScored?.(player)}
                                    />
                                )
                            },
                        )}
                        <div className={whoIsWinner ? "team1Name winner" : "team1Name"}>
                            {team1Name === "" ? "Team Urban" : team1Name}
                            {hasScore && <span>{location.scoreTeam1}</span>}
                        </div>
                    </div>

                    <div className="team2">
                        {teamDisplay.map(
                            (p, i) => {
                                const player = players?.find(player => player.teamId === 2 && player.teamSlot === i)
                                return (
                                    <Player
                                        key={i + "-team2-" + p}
                                        player={player}
                                        fieldPlace={[ 2,i ]}
                                        isMVP={player && location.mvp && player.playerId === location.mvp.id}
                                        goalCount={playerScored?.(player)}
                                    />
                                )
                            },
                        )}
                        <div className={
                            !hasScore ?
                                "team2Name"
                                : whoIsWinner ?
                                    "team2Name"
                                    :
                                    "team2Name winner"}
                        >
                            {team2Name === "" ? "Team Soccer" : team2Name}
                            {hasScore && <span>{location.scoreTeam2}</span>}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

CompositionField.propTypes = {
    ajaxLoading: bool,
    composition: object,
    emptyCaseSelection: oneOfType([ bool, array ]),
    handlePlayersFilling: func,
    location: object,
    playerScored: func,
    players: array,
    removePlayerFromField: func,
    saveTeamsCompositions: func,
    selectedPlayer: oneOfType([ bool, object ]),
    team1Name: string,
    team2Name: string,
    teamDisplay: array,
}
