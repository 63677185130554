import { motion } from "framer-motion"
import { useEffect, useState } from "react"

import { PrizeCard } from "./PrizeCard/PrizeCard.jsx"
import { LeagueHeader } from "../../../components/league/LeagueHeader/LeagueHeader.jsx"
import Preloader from "../../../components/loaders/preloader/preloader.jsx"
import { getUserLeaguePalmares } from "../api/myLeague.js"

import "./LeaguePalmares.scss"

export const LeaguePalmares = () => {
    const [ palmaresTeams, setPalmaresTeams ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    
    useEffect(() => {
        if (palmaresTeams?.length === 0) {
            setIsLoading(true)
            getUserLeaguePalmares().then((teams) => {
                if (teams.length) {
                    setPalmaresTeams(teams)
                }
            }).finally(() => setIsLoading(false))
        }
    }, [])

    return (
        <>
            <LeagueHeader
                title={"Palmarès"}
                cls={"thunder-ornament"}
            >
            </LeagueHeader>
            {isLoading ? (
                <Preloader/>
            ) : (
                <>
                    {palmaresTeams.length === 0 ? (
                        <h3 style={{ padding: "40px 20px", textAlign: "center" }}>Tu n'as aucun historique à afficher</h3>
                    ) : (
                        <div className={"league-palmares"}>
                            <div className={"c-row justify-center league-layout"}>
                                <motion.div
                                    className="c-col c-col--10 c-col--sm--12 no-padding content"
                                    variants={containerVariants}
                                    initial="hidden"
                                    animate="visible"
                                >
                                    {palmaresTeams?.map((prize, index) => (
                                        <motion.article
                                            key={index}
                                            layout
                                            variants={itemVariants}
                                        >
                                            <PrizeCard data={prize}/>
                                        </motion.article>
                                    ))}
                                </motion.div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

const containerVariants = {
    visible: {
        transition: {
            staggerChildren: 0.05, // Stagger animation for children
        },
    },
}

// Variants for each child
const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
    },
}

