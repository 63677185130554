import { _apiCall } from "../../../backend/axios.js"
import { returnAnswerData } from "../../../backend/helpers.js"

export async function editGameComposition(gameId, teamId, data) {
    return await _apiCall(
        `modify/league/game/${gameId}/team/${teamId}/compo`,
        "POST",
        null,
        data,
    ).then(returnAnswerData)
}

export async function addPlayersToLeagueTeam(teamId, players) {
    return await _apiCall(
        `create/league/teams/${teamId}/players/add`,
        "POST",
        null,
        { players },
    ).then(returnAnswerData)
}

export async function gameMvpVote(teamId, gameId, playerId) {
    return await _apiCall(
        `modify/league/team/${teamId}/game/${gameId}/vote/mvp/${playerId}`,
        "POST",
        null,
    ).then(returnAnswerData)
}

export async function gameForfeit(gameId, teamId, comment) {
    return await _apiCall(
        `modify/league/game/${gameId}/team/${teamId}/forfeit`,
        "POST",
        null,
        { comment },
    ).then(returnAnswerData)
}

export async function sendGameReminder(gameId, teamId) {
    return await _apiCall(
        `modify/league/game/${gameId}/team/${teamId}/reminder`,
        "POST",
    ).then(returnAnswerData)
}
