
import { leagueVideoType } from "../../../model/types/league.types.js"

export const Video = ({ video }) => {
    return (
        <div className="video">
            <h3 className="video-name">{video.name}</h3>
            <video className="video__player" controls poster={video.thumbnail || video?.miniatureUrl}>
                <source src={video.url} type="video/mp4" />
            </video>
        </div>
    )
}

Video.propTypes = {
    video: leagueVideoType,
}
