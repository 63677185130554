import { useState } from "react"

function useDebounce() {
    const [ countdown, setCountdown ] = useState(null)

    function cancelTimer() {
        if (countdown) {
            clearTimeout(countdown)
        }
    }

    function debounce(doSomething, timer = 2000) {
        cancelTimer()
        setCountdown(
            setTimeout(
                function () {
                    doSomething()
                    setCountdown(null)
                }, timer,
            ),
        )
    }

    return {
        cancelTimer,
        countdown,
        debounce,
    }
}

export default useDebounce
