function getReturnText(isPreregistration, tunnelType) {
    if (!isPreregistration) {
        return undefined
    } else {
        switch (tunnelType) {
        case "SCHOOL":
            return "Voir mes écoles"
        case "BIRTHDAY":
            return "Voir mes anniversaires"
        case "CUP":
            return "Voir mes tournois"
        case "LEAGUE":
            return "Voir mes ligues"
        case "TRAINING":
            return "Voir mes inscriptions"
        default:
            return "Précédent"
        }
    }
}

export default getReturnText
