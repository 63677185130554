class TimeSort {
    static sortByAscSchoolDay(a, b) {
        let aDetails = a.schoolDetails
        let bDetails = b.schoolDetails
        let aDay = a.day === 0 ? 7 : a.day
        let bDay = b.day === 0 ? 7 : b.day
        if (aDay === bDay) {
            if (aDetails.hour === bDetails.hour) {
                return 0
            }
            return (aDetails.hour < bDetails.hour ? -1 : 1)
        }
        else {
            return (aDay < bDay ? -1 : 1)
        }
    }

    static sortByAscSchoolStart(a, b) {
        const aStartDate = a.schoolDetails.start
        const bStartDate = b.schoolDetails.start
        const aStartHour = a.schoolDetails.hour
        const bStartHour = b.schoolDetails.hour
        
        if (aStartDate === bStartDate) {
            if (aStartHour === bStartHour) {
                return 0
            }
            return (aStartHour < bStartHour ? -1 : 1)
        }
        else {
            return (aStartDate < bStartDate ? -1 : 1)
        }
    }    

    static sortByAscStageStart(a, b) {
        let aDate = new Date(a.start)
        let bDate = new Date(b.start)
        return aDate === bDate ?
            0
            :
            aDate < bDate ?
                -1
                :
                1
        
    }
}

export default TimeSort
