import PropTypes, { shape } from "prop-types"

export const leagueVideoType = shape({
    name: PropTypes.string,
    thumbnail: PropTypes.string,
    url: PropTypes.string.isRequired,
})

export  const leagueVideoGroupType = PropTypes.shape({
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    thumbnail: PropTypes.string,
    videos: PropTypes.arrayOf(leagueVideoType),
})

export const teamDisplayType = {
    blazon: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
}
