
import { confirmEmail } from "../../../components/authentification/emailConfirmation/api"
import { getArdoises, getFactures, getNextLocation } from "../../../globalAPI/api"
import CreneauxDispo from "../../../pages/kids/ecoleDeFoot/steps/CreneauxDispo"
import disconnectUser from "../../../services/disconnectUser"
import TimeSort from "../../../services/Sorting/TimeSort"
import store from "../../store"
import { hasArdoises, hasUnpaidBills, loadUserFriends, loadUserInvitations, nextBooking, toggleLevel3Overlay } from "../content/index"

/**
 * (Un)freeze the body scroll
 * @param {boolean} freeze
 */
function freezeBodyScroll(freeze) {
    if (typeof(freeze) !== "boolean") {
        // eslint-disable-next-line no-console
        console.error("Index.js - FreezeBodyScroll ERROR : Parameter [freeze] should be of type [boolean]")
    }
    if (freeze) {
        let forcedTop = window.scrollY
        document.documentElement.style.top = "-" + forcedTop + "px"
        document.body.classList.add("no-scroll")
    } else {
        document.documentElement.style.top = "initial"
        document.body.classList.remove("no-scroll")
    }
}

export const selectHamburgerMenu = (open) => {
    freezeBodyScroll(open)
    return {
        payload: {
            open: open,
        },
        type: "HAMBURGER_MENU_SELECTED",
    }
}

export const selectUserProfilMenu = (open) => {
    freezeBodyScroll(open)
    return {
        payload: {
            open: open,
        },
        type: "USERPROFIL_MENU_SELECTED",
    }
}

export const loadUser = (dataUser, profileOnly = false) => {
    let payload = dataUser.data ?? {}

    if ((payload?.preferredSlots || [])?.length > 0) {
        payload.preferredSlots = payload.preferredSlots?.filter(slot => slot.status !== 2) ?? []
    }

    if (profileOnly === false) {
        if (Object.keys(payload).length > 0) {
            getArdoises().then((result) => {
                if (result.items) {
                    store.dispatch(
                        hasArdoises(result.items.length > 0),
                    )
                } else {
                    store.dispatch(
                        hasArdoises(false),
                    )
                }
            })
            getNextLocation().then(res => {
                if (res?.filter(booking => booking.amountDue && !booking.subscriptionId)?.length) {store.dispatch(nextBooking(true))}
            })
            getFactures().then(
                res => {
                    const typesToExclude = [ 19, 20, 24, 28, 29, 30, 34, 36, 37, 39, 41, 43, 99 ]
                    const filteredBill = res.filter(bill => !typesToExclude.includes(bill.serviceType))
                    const notPaid = filteredBill.filter(bill => bill.status === 0)
                    store.dispatch(hasUnpaidBills(notPaid?.length > 0))
                },
            )
        }

        if (payload.friends !== undefined) {
            store.dispatch(
                loadUserFriends(payload.friends),
            )
        }
        if (payload.invitations !== undefined) {
            store.dispatch(
                loadUserInvitations(payload.invitations),
            )
        }
    }

    return {
        payload: payload,
        type: "USER_LOAD",
    }
}

/**
 *
 * @param searchString - ex. ?param1=x&param2=xx...
 * @returns {{type: string, payload: *}}
 */
export const loadUrlQueryParameters = (searchString) => {
    var parameters = {}
    if (searchString) {
        searchString = searchString.substr(1)
        let rawSplitArray = searchString.split("&")
        if (rawSplitArray.length > 0) {
            rawSplitArray.forEach((row) => {
                let paramArray = row.split("=")
                if (paramArray.length >= 2) {
                    let value = ""
                    paramArray.forEach((val,idx)=>{
                        if (idx === 0) {
                            return
                        }
                        value += val
                        if (idx > 1) {
                            value += ("=" + val)
                        }
                    })
                    parameters[paramArray[0]] = decodeURI(value)
                }
            })
        }
    } else if (searchString === null) {
        parameters = {}
    }

    // Check and handle the parameters for a well form tokenActivation
    if (parameters.action === "tokenActivation") {
        if (
            parameters.invitation !== "TYPE_FRIENDSHIP" &&
            parameters.invitation !== "TYPE_FRIENDSHIP_AND_LEAGUE" &&
            parameters.invitation !== "TYPE_LOCATION" &&
            parameters.invitation !== "TYPE_FRIENDSHIP_AND_CUP" &&
            parameters.invitation !== "TYPE_LEAGUE_TEAM"
        ) {
            parameters.action = ""
        }
    }

    return {
        payload: parameters,
        type: "QUERY_PARAMETERS_LOAD",
    }
}

export const handleOzmoReloadingAction = (identitier) => {
    let queryParameters = store.getState().queryParameters
    let token = queryParameters.token
    const theme = queryParameters.theme

    switch (identitier) {
    case "accountVerify":
        confirmEmail(token).then(res => {
            if (res?.data?.data?.Message) {disconnectUser(`/login?accountVerificationError=${encodeURI(res.data.data.Message)}&accountIsVerified=false`)}
            else if (res.data.data.id) {disconnectUser("/login?accountIsVerified=true")}
            else {disconnectUser(`/login?accountVerificationError=${encodeURI(" ")}`)}
            // dispatch to close heartbeat loading
            store.dispatch(toggleLevel3Overlay(false, "", {}, true))
        })
        break

    case "accountVerifyPadel":
        confirmEmail(token).then(res => {
            if (res?.data?.data?.Message) {disconnectUser(`/login?accountVerificationError=${encodeURI(res.data.data.Message)}&accountIsVerified=false`)}
            else if (res.data.data.id) {disconnectUser("/login?accountIsVerified=true")}
            else {disconnectUser(`/login?accountVerificationError=${encodeURI(" ")}`)}
            // dispatch to close heartbeat loading
            store.dispatch(toggleLevel3Overlay(false, "", {}, true))
        })

        break
    case "locationPrivateInvit":
        window.location = (import.meta.env.VITE_URL_BASE) + "?action=" + identitier + "&token=" + token + "&theme=" + (theme ? theme : "theme-soccer")
        break

    case "":
        window.location.href = "/"
    }

    return {
        payload: {},
        type: "OZMO_RELOADING_HANDLED",
    }
}

export const toggleAjaxCalling = (calling) => {
    return {
        payload: calling,
        type: "AJAX_CALLING",
    }
}

export const toggleGlobalLoader = (bool) => {
    return {
        payload: bool,
        type: "GLOBAL_LOADER",
    }
}

export const changeDateValue = (value) => {
    return {
        payload: value,
        type: "DATE_VALUE",
    }
}

export const toggleShowFooter = (bool) => {
    return {
        payload: bool,
        type: "SHOW_FOOTER",
    }
}

export const updateUser = (user) => {
    return {
        payload: user,
        type: "USER_LOAD",
    }
}

export const currentDomDetails = (data) => {
    return {
        payload: data,
        type: "UPDATE_DOM_DETAILS",
    }
}

export const checkSkipStepEDF = (forms, selectedTimeslot) => {
    const creneauDataForm = forms[CreneauxDispo.formDataName]

    const optionProducts = selectedTimeslot?.schoolDetails ? selectedTimeslot.schoolDetails.optionProducts : []
    let remainingTimeslots = {}
    const selectedTimeslotToRemove = selectedTimeslot?.value
    if (creneauDataForm) {
        Object.values(creneauDataForm.timeslots).forEach(
            element => {
                if (element.value !== selectedTimeslotToRemove) {
                    remainingTimeslots[element.value] = element
                }
            },
        )
    }
    remainingTimeslots = Object.values(remainingTimeslots).sort(TimeSort.sortASC)
    const discount = selectedTimeslot?.schoolDetails ? selectedTimeslot.schoolDetails.discount : 0
    var skipPrestaSupp = (optionProducts.length === 0 || !optionProducts.find(option => option.showAtPayment === false)) &&
        (Object.keys(remainingTimeslots).length === 0 || discount !== 0)
    var skipEquipment = selectedTimeslot?.schoolDetails ? selectedTimeslot.schoolDetails.catalogProducts.length === 0 : null
    return {
        payload: {
            skipEquipment: skipEquipment,
            skipPrestaSupp: skipPrestaSupp,
        },
        type: "CHECK_SKIP_EDF",
    }
}
