import { generatePath } from "react-router-dom"

export class LocationPaths {
    static listingReservations = "/reservations/locations"
    static reservationBase = "/reservations/locations/:locationId"
    static reservationInfos = "infos"
    static reservationComposition = "compo"
    static reservationPlayers = "joueurs"
    static reservationPayments = "paiements"
    static reservationVideos = "videos"
    static searchSuperSub = "/reservations/locations/:locationId/searchsupersub"
    static candidatureSuperSub = "/reservations/locations/:locationId/candidaturesupersub"
    static candidatureSuperSubAvenir = "/reservations/locations/avenir/candidatureSupersub/:locationId"
    static superSubRating = "/reservations/locations/:locationId/supersubRating"
    static superSubRatingPassees = "/reservations/locations/passees/noterSupersub/:locationId"
    static bookingPage = "/reserver"
    static newBookingOrderSummary = "/reserver/:preBookingId"
    static lyraEmbedPayment = "/reserver/paiement/:preBookingId"
    // used for additional payments
    static lyraEmbedLocationPaiement = "/reserver/paiement/reservation/:locationId"

    static toReservationInfos = (locationId) => {
        return generatePath(this.reservationBase + "/" + this.reservationInfos, { locationId })
    }

    static toReservationComposition = (locationId) => {
        return generatePath(this.reservationBase + "/" + this.reservationComposition, { locationId })
    }

    static toReservationPlayers = (locationId) => {
        return generatePath(this.reservationBase + "/" + this.reservationPlayers, { locationId })
    }

    static toReservationPayments = (locationId) => {
        return generatePath(this.reservationBase + "/" + this.reservationPayments, { locationId })
    }

    static toReservationVideos = (locationId) => {
        return generatePath(this.reservationBase + "/" + this.reservationVideos, { locationId })
    }

    static toCandidatureSuperSub = (locationId) => {
        return generatePath(this.candidatureSuperSub, { locationId })
    }

}
