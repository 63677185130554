import classNames from "classnames"
import dayjs from "dayjs"
import { arrayOf, bool, func, node, object, oneOfType, string } from "prop-types"
import { forwardRef } from "react"

import useIsMobile from "../../../hooks/useIsMobile"

import "./DayDisplay.scss"

const DayDisplay = forwardRef(({
    selectedDate,
    inOrange = null,
    disabled = false,
    children,
    day,
    onClick,
    altDays,
}, ref) => {
    const isMobile = useIsMobile()

    return (
        <article
            className={classNames("o-day", {
                active: inOrange || (inOrange === null && selectedDate && dayjs(selectedDate).format("DD/MM/YYYY") === dayjs(day).format("DD/MM/YYYY")),
                disabled: disabled,
                "other-activity": children?.props?.children[1] > 0,
            })}
            onClick={onClick}
            ref={ref}
        >
            {!altDays && dayjs(day) !== "Invalid Date" && (
                <span data-testid="date">
                    {dayjs(day).format("DD/MM")}
                    {isMobile ?
                        <br/>
                        :
                        " - "
                    }
                    <span className="weekDay" data-testid="weekDay">
                        {dayjs(day).format("ddd")}
                    </span>
                </span>
            )}
            {altDays && (
                <>
                    <span className="weekDay" data-testid="weekDay">{dayjs(day).format("ddd")?.replace(".", "")}</span>
                    <span className="day" data-testid="date">{dayjs(day).format("DD")}</span>
                    <span className="month">{dayjs(day).format("MMM")?.replace(".", "")}</span>
                </>
            )}
            {children}
        </article>
    )
})

DayDisplay.displayName = "DayDisplay"
DayDisplay.propTypes = {
    altDays: bool,
    children: oneOfType([ string, node, arrayOf(node) ]),
    day: oneOfType([ string, object ]),
    disabled: bool,
    inOrange: bool,
    onClick: func,
    selectedDate: oneOfType([ string, object ]),
}

export default DayDisplay
