
import { Outlet } from "react-router-dom"

import PageHeader from "../../../components/layout/pageHeader/PageHeader"
import TabSwitcher from "../../../components/TabSwitcher/TabSwitcher.jsx"
import Help from "../../../entries/main/../../components/tooltip/Help/Help"
import useResetScroll from "../../../hooks/useResetScroll"
import { SupersubPaths } from "../../../routes/ApplicationPaths/supersubPaths.js"

import "./WhatIsSuperSub.scss"

function WhatIsSuperSub() {
    useResetScroll()
    return (
        <div className="step superSubExplanation">
            <PageHeader
                title="Qu'est-ce qu'un supersub ?"
                goBack={true}
                button={(
                    <Help
                        id="MatchHelp"
                    >
                        <strong>0 806 23 00 24</strong>
                    </Help>
                )}
            />
            <section className="whatIsSuperSub">
                <div className="whatIsSuperSub__wrapper">
                    <main className="whatIsSuperSub__content">
                        <img className="arrow" src="/assets/images/WhatIsSuperSub/arrow-dotted-1.svg" alt=""/>
                        <img className="arrow" src="/assets/images/WhatIsSuperSub/arrow-dotted-2.svg" alt=""/>
                        <img className="arrow" src="/assets/images/WhatIsSuperSub/arrow-dotted-3.svg" alt=""/>
                        <img className="arrow" src="/assets/images/WhatIsSuperSub/arrow-dotted-4.svg" alt=""/>
                        <div className={"c-row justify-center"} id="tab-switcher-wrapper">
                            <TabSwitcher
                                defaultTab={SupersubPaths.whatIsSuperSubPlayer}
                                tabs={[
                                    {
                                        name: "Je suis capitaine",
                                        route: SupersubPaths.whatIsSuperSubCaptain,
                                    },
                                    {
                                        name: "Je suis joueur",
                                        route: SupersubPaths.whatIsSuperSubPlayer,
                                    },
                                ]}
                            />
                        </div>
                        <Outlet/>
                    </main>
                </div>
            </section>
        </div>
    )
}
export default WhatIsSuperSub
