import { bool } from "prop-types"

import IconPadel from "./assets/IconPadel"
import IconSoccer from "./assets/IconSoccer"
import { useTheme } from "../../ThemeContext/ThemeContext"

function Notifications({ hasChildActive }) {
    const theme = useTheme()

    return (
        <>
            {theme === "theme-soccer" && (
                <IconSoccer
                    backgroundColor={hasChildActive ? "#ffffff" : "#ff7832"}
                    textColor={hasChildActive ? "#ff7832" : "#ffffff"}
                />
            )}

            {theme === "theme-padel" && (
                <IconPadel
                    backgroundColor={hasChildActive ? "#f5233c" : "#f5233c"}
                    textColor={hasChildActive ? "#ffffff" : "#ffffff"}
                />
            )}
        </>
    )
}

Notifications.propTypes = {
    hasChildActive: bool,
}

export default Notifications
