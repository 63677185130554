import classNames from "classnames"
import { arrayOf, bool, func, node, number, oneOfType, string } from "prop-types"
import { useState } from "react"

import Preloader from "../../../entries/main/../../components/loaders/preloader/preloader"
import useMediaPath from "../../../hooks/useMediaPath"
import { useTheme } from "../../ThemeContext/ThemeContext"

import "./CheckBlock.scss"

function CheckBlock({
    name,
    value,
    children,
    checkbox,
    checked,
    disabled = false,
    callback,
    cls,
    onDelete,
    unselect = false,
    action = false,
}) {
    const [ loading, setLoading ] = useState(false)
    const mediaPath = useMediaPath()
    const theme = useTheme()

    function handleInputClick(event) {
        if (callback) {
            callback(event, name)
        }
    }

    function handleDeletion() {
        onDelete(value)
        setLoading(true)
    }

    return (
        <div
            className={classNames("radioList__item", {
                disabled,
                unselect: unselect && checked,
            })}
            key={value}
        >
            <input
                id={"checkblock-" + value}
                type={checkbox ? "checkbox" : "radio"}
                checked={!!checked}
                name={name}
                readOnly
            />
            <label
                id={value}
                className={`${checked ? "CheckBlock no-hover" : "CheckBlock"}` + (cls ? ` ${cls}` : "")}
                htmlFor={"checkblock" + value}
                onClick={(e) => handleInputClick(e, name)}
            >
                {children}
                {onDelete && (
                    <span className="deleteButton" onClick={handleDeletion} data-testid="checkblock-delete">
                        {loading ?
                            <Preloader />
                            :
                            (<>
                                {
                                    theme === "theme-padel" && (
                                        <img alt="" className="deletePlayerOfTournament" src="/assets/images/padel/icons/icon-cross-black.svg"/>
                                    )
                                }
                                {
                                    theme === "theme-soccer" && (
                                        <img alt="" src="/assets/icons/cross-white.svg"/>
                                    )
                                }
                            </>)

                        }
                    </span>
                )}
            </label>
            {unselect && checked && (
                <img
                    src={mediaPath([ "/assets/icons/cross-orange-white.svg", "/assets/images/padel/icons/icons-cross-black.svg" ])}
                    className="unselect"
                    data-testid="checkblock-unselect"
                    onClick={action ? action : handleInputClick}
                />
            )}

        </div>
    )
}

CheckBlock.propTypes = {
    action: oneOfType([ func, bool ]),
    callback: func,
    checkbox: bool,
    checked: bool,
    children: oneOfType([ string, node, arrayOf(node) ]),
    cls: string,
    disabled: bool,
    name: oneOfType([ string, number ]),
    onDelete: func,
    unselect: bool,
    value: oneOfType([ string, number ]),
}

export default CheckBlock
