import classNames from "classnames"
import { arrayOf, bool, func, node, oneOfType, string } from "prop-types"
import React from "react"

import useCookies from "../../../hooks/useCookies"
import Footer from "../../layout/footer/footer"
import AuthHeader from "../authHeader/AuthHeader"

import "./authBackgroundContent.scss"

function AuthBackgroundContent({ customContent, children, title, contentCls, goBack, help, cls, hideGoBack }) {
    const { isWebview, isAppV2 } = useCookies()

    return (
        <React.Fragment>
            <div
                className={
                    "authBackgroundContent " + 
                    (customContent ?
                        ""
                        :
                        " authWhiteContent "
                    ) +
                    (cls ? cls : "")
                }
            >
                { customContent ?
                    children
                    : (
                        <React.Fragment>
                            <AuthHeader
                                goBack={goBack}
                                help={help}
                                title={title}
                                hideGoBack={hideGoBack}
                            />
                            <div className="c-row justify-center">
                                <div className="c-col c-col--8 c-col--m--10 c-col--sm--11 flex justify-center">
                                    <div className={
                                        contentCls ?
                                            contentCls + " white-content"
                                            :
                                            "white-content"
                                    }>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
            <Footer
                customFooterClass={classNames("mobileAuthFooter", {
                    hide: isWebview || isAppV2,
                })}
            />
        </React.Fragment>
    )
}

AuthBackgroundContent.propTypes = {
    children: oneOfType([ string, node, arrayOf(node) ]),
    cls: string,
    contentCls: string,
    customContent: oneOfType([ bool, string ]),
    goBack: func,
    help: oneOfType([ string, node, arrayOf(node) ]),
    hideGoBack: bool,
    title: string,
}

export default AuthBackgroundContent
