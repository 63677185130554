import dayjs from "dayjs"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import GeneralRanking from "../../../../components/league/GeneralRanking/GeneralRanking.jsx"
import { LeagueHeader } from "../../../../components/league/LeagueHeader/LeagueHeader.jsx"
import ScorerRanking from "../../../../components/league/ScorerRanking/ScorerRanking.jsx"
import Preloader from "../../../../components/loaders/preloader/preloader.jsx"
import { useAssets } from "../../../../providers/AssetsProvider.jsx"
import { LeaguePath } from "../../../../routes/ApplicationPaths/LeaguePaths.js"
import { mapToObject } from "../../../../utilities/helpers.js"
import { getTeamsRanking } from "../../service/service.js"

import "./PalmaresDetails.scss"

export const PalmaresDetails = () => {
    const [ data, setData ] = useState({})
    const [ isLoading, setIsLoading ] = useState(false)
    const { leagueId, teamId } = useParams()
    const navigateTo = useNavigate()
    const { m } = useAssets()

    const leagueTeam = data?.league

    useEffect(() => {
        setIsLoading(true)
        getTeamsRanking(leagueId).then((ranking) => {
            setData(ranking)
            setIsLoading(false)

            if (!ranking?.league?.id) {
                navigateTo(LeaguePath.toLeaguePalmaresDetails())
            }
        })
    }, [ leagueId ])

    const selectedTeam = mapToObject((data?.ranking?.find(team => team.id === Number(teamId))?.values))

    const renderTeamPodium = () => {
        if (!data?.ranking?.length || data?.ranking?.length < 3) {return null}
        const podiumTeams = data?.ranking?.slice(0, 3)
        return (
            <motion.div
                className="podium podium-team"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                <header>
                    <h2>{leagueTeam?.name} - Saison {dayjs(leagueTeam?.start).year()}</h2>
                    <h3><img alt="center" src={m("icons.league.center")}/> {leagueTeam?.centerName}</h3>

                </header>
                <motion.section variants={containerVariants}>
                    {podiumTeams.map((team, index) => {
                        const rank = index + 1 === 1 ? index + 1 + "er" : index + 1 + "ème"
                        return (
                            <motion.div key={team.id} className={`podium-position position-${index + 1}`}
                                variants={itemVariants}
                            >
                                <span className="podium-rank">{rank}</span>
                                <picture><img src={m("icons.league.cup")} alt={"cup"}/></picture>
                                <span>{team.values.get("name")}</span>
                            </motion.div>
                        )
                    })}
                </motion.section>
            </motion.div>
        )
    }

    const renderPlayerPodium = () => {
        if (!data?.scorers?.length || data?.scorers?.length < 3) {return null}

        const podiumScorers = data?.scorers?.slice(0, 3)
        return (
            <motion.div
                className="podium podium-scorers"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                <motion.section variants={containerVariants}>
                    {podiumScorers.map((team, index) => {
                        const rank = index + 1 === 1 ? index + 1 + "er" : index + 1 + "ème"
                        const playerName = team.values.get("firstname") ? `${team.values.get("firstname")} ${team.values.get("lastname").slice(0, 1)}.` : team.values.get("lastname")

                        return (
                            <motion.div key={team.id} className={`podium-position position-${index + 1}`}
                                variants={itemVariants}
                            >
                                <span className="podium-rank">{rank}</span>
                                <picture><img src={m("icons.league.ball")} alt={"cup"}/></picture>
                                <span>{playerName}</span>
                                <span className={"team-name"}>{team.values.get("teamName")}</span>
                            </motion.div>
                        )
                    })}
                </motion.section>
            </motion.div>
        )
    }

    return (
        <>
            <LeagueHeader
                title={selectedTeam?.name ?? "Palmarès"}
                cls={"thunder-ornament"}
                previous={LeaguePath.toLeaguePalmaresDetails()}
            >
            </LeagueHeader>
            <div className={"c-row justify-center league-layout palmares-details"}>
                <div className="c-col c-col--10 c-col--sm--12 no-padding">
                    {renderTeamPodium()}
                    <GeneralRanking loading={isLoading} data={data?.ranking} highlightedTeamId={selectedTeam?.id}/>
                    {isLoading && <Preloader /> }
                    {renderPlayerPodium()}
                    <ScorerRanking loading={isLoading} data={data?.scorers}/>
                </div>
            </div>
        </>
    )
}

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.3, // Delay between each child animation
        },
    },
}

const itemVariants = {
    hidden: { scale: 0 },
    visible: {
        delay: 1,
        scale: 1,
        transition: {
            
            damping: 20, 
            // Spring animation for a pop effect
            stiffness: 260,
            type: "spring",
        },
    },
}
