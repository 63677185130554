import classNames from "classnames"
import { array, arrayOf, node, object, oneOfType , string } from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import {
    NavLink,
} from "react-router-dom"

import useShowBanScreen from "../../../../hooks/supersub/showBanScreen"
import ProfileMenuLvl2 from "../profileMenuLvl2/profileMenuLvl2"

import "./menuLvl2.scss"

function MenuLvl2({
    parentId,
    level1,
    childrenList,
    promoLevel2,
}) {
    const isProfileMenu = level1 === "profil"
    const userLoaded = useSelector(state => state.userLoaded)
    
    const showBanScreen = useShowBanScreen()

    function checkSupersubBanned(e) {
        // disable link to and click actions
        e.preventDefault()
        e.stopPropagation()
        showBanScreen(level1)
    }

    function buildLinks() {
        if (childrenList) {
            const arrList = []
            const arrLinks = childrenList.filter(item => item.isVisible !== false).map(
                ({ label, appRoute, restrictAccessToSuperSubBanned, hasNotif, customCls }, index) => (
                    <NavLink
                        to={appRoute}
                        key={"r-" + index}
                        className={({ isActive }) => classNames("list-part-item", {
                            [customCls]: customCls,
                            selected: isActive,
                        })}
                        onClick={
                            (restrictAccessToSuperSubBanned && userLoaded.supersubBanned) ?
                                (e) => checkSupersubBanned(e)
                                :
                                undefined
                        }
                    >
                        { hasNotif && (
                            <img
                                className="notif"
                                src={"/assets/icons/icons-graphics-notifications-orange.svg"}
                            />
                        )}
                        {label}
                    </NavLink>
                ),
            )

            if (!isProfileMenu) {
                let nextIndex = 0
                arrLinks.forEach(
                    (linkEl, index) => {
                        const nbPerCol = arrLinks.length % 3 === 1 ?
                            2
                            :
                            3

                        if (index === nextIndex) {
                            const listEl = (
                                <div className="list-part" key={"menulvl2-listEl-" + index}>
                                    {arrLinks.slice(index, index + nbPerCol)}
                                </div>
                            )
                            arrList.push(
                                listEl,
                            )
                            nextIndex = index + nbPerCol // we want to group by nbPerCol
                        }
                    },
                )
            }
            return arrList
        } else {
            return []
        }

    }

    return (
        <React.Fragment>
            {
                <div
                    id={parentId + "-lvl2"}
                    className={
                        "menuItemLvl2"
                        + (
                            isProfileMenu ?
                                " menuItemLvl2Profile"
                                :
                                ""
                        )
                    }
                >
                    <div className="list-parent-part">
                        { isProfileMenu ? (
                            <ProfileMenuLvl2
                                parentId={parentId}
                                level1={level1}
                                childrenList={childrenList}
                            />
                        ) :
                            buildLinks()
                        }
                    </div>

                    { promoLevel2 && !isProfileMenu && (
                        (promoLevel2.link && promoLevel2.link !== "#") ? (
                            <a
                                className="promo-part"
                                href={promoLevel2.link}
                                style={{ backgroundImage: "url(" + promoLevel2.srcImage + ")" }}
                                target={promoLevel2.newTab ? "_blank" : undefined}
                                rel="noreferrer"
                            ></a>
                        ) : (
                            <div
                                className="promo-part"
                                style={{ backgroundImage: "url(" + promoLevel2.srcImage + ")" }}
                            ></div>
                        )
                    )}

                </div>
            }
        </React.Fragment>
    )

}

MenuLvl2.propTypes = {
    childrenList: oneOfType([ string, node, arrayOf(node), array, object ]),
    level1: string,
    parentId: string,
    promoLevel2: object,
}

export default MenuLvl2
