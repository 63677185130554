import { allContainers } from "../componentsContainers/allContainers"

class ContainersHandler
{
    static load(targetIdentifier) {
        let containers = allContainers()

        // authorize to pass dynamic Prop "id" to the component
        return containers[targetIdentifier]
    }
}

export default ContainersHandler
