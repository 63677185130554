export class CommonHandler
{
    static freezeBodyScroll(freeze) {
        if (typeof(freeze) !== "boolean") {
            // eslint-disable-next-line no-console
            console.error("CommonHandler - FreezeBodyScroll ERROR : Parameter [freeze] should be of type [boolean]")
        }
        if (freeze) {
            let forcedTop = window.scrollY
            document.documentElement.style.top = "-" + forcedTop + "px"
            document.body.classList.add("no-scroll")
        } else {
            document.documentElement.style.top = "initial"
            document.body.classList.remove("no-scroll")
        }
    }

    static scrollTo(element, to, duration) {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20

        var animateScroll = function () {
            currentTime += increment
            var val = Math.easeInOutQuad(currentTime, start, change, duration)
            element.scrollTop = val
            if (currentTime < duration) {
                setTimeout(animateScroll, increment)
            }
        }
        animateScroll()
    }
}

export default CommonHandler
