import classNames from "classnames"
import { bool, func, object, oneOfType, string } from "prop-types"

import { TeamPicture } from "../TeamPicture/TeamPicture.jsx"

import "./CompositionPlayer.scss"

export default function CompositionPlayer({ player, bench, action, selected, unselect, theme }) {
    const picture = theme === "theme-business" ? player?.businessLeaguePictureUrl || player?.pictureUrl : player?.pictureUrl
    return (
        <div
            className={classNames("compositionPlayer", {
                bench: bench,
                clickable: !!action,
                empty: !player,
                selected: selected,
            })}
        >
            <div className="image" data-testid="compositionPlayer" onClick={action}>
                {unselect && selected && player && !bench && (
                    <img
                        className="unselect"
                        src="/assets/images/Close-W-B.svg"
                        data-testid="remove"
                        onClick={(e) => {
                            e.stopPropagation()
                            unselect()
                        }}
                    />
                )}
                {player && <TeamPicture image={picture} size={bench ? 55 : 40} placeHolder="/assets/images/user-placeholder-b.svg" />}
            </div>
            <div className="name">{(player?.firstname ?? "") + (player?.lastname ? " " +  player.lastname.charAt(0) + "." : "")}</div>
        </div>
    )
}

CompositionPlayer.propTypes = {
    action: oneOfType([ bool, func ]),
    bench: bool,
    player: oneOfType([ bool, object ]),
    selected: bool,
    theme: string,
    unselect: oneOfType([ bool, func ]),
}
