const playersNumber = [
    {
        categories: [ 8, 1, 9 ],
        players: 2,
        strings: [ "Tennis", "Badminton", "Squash" ],
        types: [ 5, 9, 11 ],
    },
    {
        categories: [ 2, 2, 7, 7, 7, 7 ],
        players: 4,
        strings: [ "Foot 2 - Intérieur", "2 contre 2", "Padel - Intérieur", "Padel", "Padel - Intérieur - Filmé", "Padel - Extérieur" ],
        types: [ 3, 3, 12, 12, 14, 15 ],
    },
    {
        categories: [ 3, 3 ],
        players: 6,
        strings: [ "Foot 3 - Extérieur", "3 contre 3" ],
        types: [ 2, 2 ],
    },
    {
        categories: [ 4, 4 ],
        players: 8,
        strings: [ "Foot 4 - Intérieur", "4 contre 4" ],
        types: [ 4, 4 ],
    },
    {
        categories: [ 5, 5, 5, 5, 5, 5, 5, 5 ],
        players: 10,
        strings: [ "Foot 5 - Intérieur", "Intérieur", "Foot 5 - Extérieur", "Extérieur", "Foot 5 - Intérieur - Filmé", "Filmé indoor", "Foot 5 - Extérieur - Filmé", "Filmé outdoor" ],
        types: [ 0, 0, 1, 1, 8, 8, 10, 10 ],
    },
    {
        categories: [ 6, 6 ],
        players: 14,
        strings: [ "Foot 7 - Extérieur", "7 contre 7", "Foot à 7 - Extérieur" ],
        types: [ 13, 13 ],
    },
]

export default playersNumber
