import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import { getUserPlayerProfile } from "../../../../globalAPI/api"
import PadelLevel from "../../../../model/enums/User/PadelLevel"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import PadelLevels from "../../PadelLevels"
import ProfilCardWrapper from "../ProfilCardWrapper/ProfilCardWrapper"

import "./MySportInfos.scss"

function MySportInfos() {
    const navigate = useNavigate()
    const user = useSelector(state => state.userLoaded)
    const [ playerProfile, setPlayerProfile ] = useState(user?.playerProfile)

    const getUserPosition = () => {
        switch (playerProfile?.positioning) {
        case 0:
            return "Droite"
        case 1:
            return "Gauche"
        case 2:
            return "Indifférent"
        default:
            return "Non renseigné"
        }
    }

    useEffect(() => {
        if (user.playerProfile) {setPlayerProfile(user.playerProfile)}
        else if (user.id) {getUserPlayerProfile(user.id).then(res => setPlayerProfile(res))}
    }, [ user ])

    return (
        <div className="my-sport-infos">
            <h2>Mes infos sportives</h2>
            <ProfilCardWrapper>
                <div className="p-myInformation__infos__line grid noBottomStyle">
                    <div className="grid-item">
                        <h3>Placement</h3>
                        <p>{getUserPosition()}</p>
                    </div>
                    <div className="grid-item">
                        <h3>N° de licence</h3>
                        <p>{playerProfile?.licenseNumber ? playerProfile.licenseNumber : "Non renseigné"}</p>
                    </div>
                    <div className="grid-item">
                        <h3>Classement fft</h3>
                        <p>{playerProfile?.ranking ? playerProfile.ranking : "Non renseigné"}</p>
                    </div>
                    <div className="grid-item">
                        <h3>Niveau</h3>
                        <p>{playerProfile?.level ? PadelLevel[playerProfile?.level] : "Non renseigné"}</p>
                    </div>
                </div>
                <div className="grid">
                    <div />
                    <div
                        className="grid-item level"
                        onClick={() => ModalHandler.show(PadelLevels)}
                    >
                        Voir les niveaux
                    </div>
                </div>
            </ProfilCardWrapper>
            <ButtonCta
                text="Modifier mes infos sportives"
                onClick={
                    () => {
                        navigate("/padel/profil/sportinfos/edit")
                    }
                }
            />
        </div>
    )
}

export default MySportInfos
