import classNames from "classnames"
import { bool, oneOfType, string } from "prop-types"

import useMediaPath from "../../../hooks/useMediaPath.js"

import "./preloader.scss"

function Preloader({ fixed, cls, alt, small = false }) {
    const mediaPath = useMediaPath()
    return (
        <div
            className={classNames(cls, {
                "fixed-preloader": fixed,
                preloader: !fixed,
                small: small,
            })}
        >
            <div style={{ backgroundImage: `url('/assets/images/${alt ? "loader-white.svg" : mediaPath([ "loader.svg", "loader.svg", "loader-business.svg" ])}')` }}></div>
        </div>
    )
}

Preloader.propTypes = {
    alt: bool,
    cls: string,
    fixed: oneOfType([ bool, string ]),
    small: bool,
}

export default Preloader
