import { arrayOf, bool, node, number, oneOfType, string } from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import getStepPositionClass from "./functions/getStepPositionClass"
import { addStep, nextStep, previousStep, resetStepCount } from "../../store/stepsManagement/actions"

/**
 * Gère le système d'étape et son avancée dans le store
 *
 * Tout ses enfants doivent être des Step
 */
export default function StepsManager({ children, customProgressInit, customStepCount, hideProgressPar, customCls, hasPaymentExtraStep = true }) {
    const [ progress, setProgress ] = useState(null)

    const dispatch = useDispatch()

    const currentStep = useSelector(state => state.stepManagement.currentStep)
    const stepCount = useSelector(state => state.stepManagement.stepCount)

    useEffect(() => {
        if (!!currentStep && !!stepCount && currentStep + 1 > stepCount && stepCount > 0 && currentStep > 1) {
            dispatch(previousStep())
        }
        if (currentStep === -1) {
            dispatch(nextStep())
        }
    }, [ currentStep, stepCount ])

    const resetStep = () => new Promise((resolve) => {
        dispatch(resetStepCount())
        resolve()
    })

    const parseChildren = () => {
        /* istanbul ignore else */
        if (children.length) {
            children.forEach(
                (child) => {
                    if (child.props.children !== undefined) { // Si l'enfant à des children c'est un StepContainer
                        child.props.children.forEach(
                            () => {
                                dispatch(addStep())
                            },
                        )
                    } else {
                        dispatch(addStep())
                    }
                },
            )
        }
        if (hasPaymentExtraStep) {
            dispatch(addStep())
        }
    }

    const updateStepsProps = (steps) => {
        let updatedSteps = []
        let index = -1
        if (steps?.length) {
            steps.forEach(
                (element) => {
                    if (element.props.children !== undefined) { // Si l'enfant à des children c'est un StepContainer
                        let start = index + 1
                        element.props.children.forEach(
                            () => {
                                index ++
                            },
                        )
                        let end = index
                        /* istanbul ignore else */
                        if (currentStep >= start && currentStep <= end) {
                            updatedSteps.push(React.cloneElement(element, {
                                key: index + 1,
                                max: end,
                                min: start,
                            }))
                        }
                    } else {
                        index ++
                        if (currentStep === index) { // Si elle corresponds à l'étape actuelle
                            updatedSteps.push(React.cloneElement(element, {  
                                cls: getStepPositionClass(index, stepCount),
                                // On l'ajoute aux éléments à afficher
                                key: index + 1,
                            }))
                        }
                        // }
                    }
                },
            )
        } else {
            return steps
        }
        return updatedSteps
    }

    useEffect(() => {
        resetStep()
        parseChildren()
    }, [])

    useEffect(() => {
        if (customProgressInit && customStepCount) {
            setProgress((customProgressInit + currentStep + 1) * 100 / customStepCount)
        } else {
            setProgress((currentStep + 1) * 100 / stepCount)
        }
    }, [ customProgressInit, customStepCount, currentStep, stepCount ])

    return (
        <div className={customCls ? customCls + " stepManager" : "stepManager"}>
            {
                !hideProgressPar &&
                    (<div className="progressBar">
                        <div className="progressBar__inner" style={{ width: progress + "%" }}></div>
                    </div>)
            }

            { updateStepsProps(children) }
        </div>
    )
}

StepsManager.propTypes = {
    children: oneOfType([ string, node, arrayOf(node) ]),
    customCls: oneOfType([ string, bool ]),
    customProgressInit: number,
    customStepCount: number,
    hasPaymentExtraStep: bool,
    hideProgressPar: bool,
}
