import { _apiCall } from "../../../backend/axios.js"
import { returnAnswerData } from "../../../backend/helpers.js"

export async function uploadBusinessLeagueLogo(file, teamId) {
    const fileData = new FormData()
    fileData.append("file", file)
    fileData.append("identifier", teamId)

    return _apiCall(
        "create/businessLeagueBlazon",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        fileData,
    ).then(returnAnswerData)
}

export function  postRegistration(data) {
    return _apiCall(
        "create/businessLeagueRegistrationQuery",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then(returnAnswerData)
}

