import classNames from "classnames"
import dayjs from "dayjs"
import { number, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import CheckBlock from "../../../../components/Forms/CheckBlock/CheckBlock"
import FormValidator from "../../../../components/Forms/FormValidator"
import InputDate from "../../../../components/Forms/InputDate/InputDate"
import { SelectInput } from "../../../../components/Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../../../components/Inputs/TextInput/TextInput.jsx"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import ReservationSlotInfoCard from "../../../../components/reservation/slotInfoCard/ReservationSlotInfoCard"
import Step from "../../../../components/stepsManagement/Step"
import slugify from "../../../../entries/main/contents/main/helpers/removeAccent"
import { getUsCentersArea, getUsCentersBookable, postContact } from "../../../../globalAPI/api"
import useAddSearchParam from "../../../../hooks/useAddSearchParams"
import useIsMobile from "../../../../hooks/useIsMobile"
import useResetScroll from "../../../../hooks/useResetScroll"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import { centersToExclude } from "../../../../model/data/centersToExclude"
import usePath from "../../../../routes/services/usePath"
import { clearAllForms, setFormEntry } from "../../../../store/forms/actions"
import { goToStep, nextStep } from "../../../../store/stepsManagement/actions"
import PreForm from "../../../kids/components/preForm/PreForm"
import useGetPrivateTournament from "../../hooks/useGetPrivateTournament"
import useTeamSubscriptionLoaded from "../../hooks/useTeamSubscriptionLoaded"
import { getAreaCups, getCupDetails, getCupList, postCupRegistration } from "../../urbanCup/api"
import CupCheckblock from "../../urbanCup/components/CupCheckblock/CupCheckblock"
import NoCup from "../../urbanCup/components/NoCup/NoCup"
import CupFilterPopin from "../components/CupFilterPopin/CupFilterPopin"

import "../../urbanCup/steps/ChoixTournois.scss"
import "./CupChoice.scss"

function CupChoice(props) {
    useResetScroll()
    const { tunnelType } = props
    const step = useRef(null)
    const select = useRef(null)
    const form = useRef(null)
    const filterPopin = useRef(null)
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = usePath()
    const isMobile = useIsMobile()
    const themeHeader = useThemeHeader()
    const addSearchParams = useAddSearchParam()

    const getPrivateTournament = useGetPrivateTournament(tunnelType)
    const [ centersArea, setCentersArea ] = useState([])
    const [ userCenterId, setUserCenterId ] = useState({})
    const [ selectedAreaId, setSelectedAreaId ] = useState(null)
    const [ selectedArea, setSelectedArea ] = useState(null)
    const [ selectedAreaName, setSelectedAreaName ] = useState(null)
    const [ centers, setCenters ]  = useState([])
    const [ loadingCups, setLoadingCups ] = useState(false)
    const [ cups, setCups ] = useState([])
    const [ filteredCups, setFilteredCups ] = useState(null)
    const [ selectedCup, setSelectedCup ] = useState(null)
    const [ selectedCupDetails, setSelectedCupDetails ] = useState(null)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ errorMsg, setErrorMsg ] = useState(null)
    const [ loadingCupDetails, setLoadingCupsDetails ] = useState(false)
    const [ buttonDisabled, setButtonDisabled ] = useState(true)
    const [ tournamentCodeCenterId, setTournamentCodeCenterId ] = useState(null)
    const [ tournamentLoading, setTournamentLoading ] = useState(null)
    const [ requestWip, setRequestWip ] = useState(false)
    const [ periodStart, setPeriodStart ] = useState(new Date())
    const [ cupToDisplay, setCupToDisplay ] = useState(9)
    const [ gotUserCups, setGotUserCups ] = useState(false)
    const [ initialized, setInitialized ] = useState(false)
    const [ searchParams ] = useSearchParams()
    const [ loadingError, setLoadingError ] = useState(false)

    const currentFilter = useSelector(state => state.forms[CupChoice.formName])
    const { selectedCenter, selectedGender, selectedType } = currentFilter || {}
    const setCurrentFilter = filters => dispatch(setFormEntry("CupChoice", filters))
    const registration = useSelector(state => state.forms.TournamentRegistration)

    const tournamentCode = useSelector(state => state.forms.tournamentCode)
    const user = useSelector(state => state.userLoaded)
    const currentStep = useSelector(state =>  state.stepManagement.currentStep)
    const alreadySubscribedCupIds = useSelector(state => state.forms.alreadySubscribedCupIds)
    const { loadingTeamSubsIds } = useTeamSubscriptionLoaded()

    const help = <PhoneCallHelp tunnelType={tunnelType}/>

    const genderOption = [
        { label: "Homme", value: "1" },
        { label: "Femme", value: "2" },
        { label: "Mixte", value: "3" },
    ]

    const typeOption = [
        { label: "Homologué", value: "2" },
        { label: "Loisir", value: "1" },
    ]

    function handleTournamentResult(res) {
        setLoadingCups(false)
        if (res?.data?.data?.items) {
            let filteredCups = res.data.data.items
            filteredCups = filteredCups.sort((a, b) => {
                a = a.start.split("/").reverse().join("")
                b = b.start.split("/").reverse().join("")
                return a.localeCompare(b)    
            })
            if (filteredCups.length > 0 && registration && selectedArea?.centers?.includes(registration.centerId)) {
                let allIds = filteredCups.map(x => x.id)
                const chosenId = registration.cup.id
                if (
                    !allIds.includes(
                        chosenId,
                    ) && tournamentCodeCenterId
                ) {
                    const newTournament = registration.cup
                    let newcup = [ newTournament ]
                    setTournamentCode("CODESECRET")
                    setTournamentCodeCenterId(registration.centerId)
                    setCups(newcup)
                    selectCup(newTournament)
                    setInitialized(true)
                } else {
                    setCups(filteredCups)
                    setInitialized(true)
                }
            } else {
                setCups(filteredCups)
                setInitialized(true)
            }
            setCupToDisplay(9)
        }
    }

    /** Initialisation de l'étape */
    useEffect(() => {
        const fetchData = async () => {
            const periodStart = dayjs(new Date()).subtract(3, "year").format("YYYY-MM-DD")

            const [ cupListResult, centersBookableResult ] = await Promise.all([
                getCupList({
                    offset: 0,
                    orderByAsc: false,
                    periodStart: periodStart,
                    status: "[2,1,3]",
                }),
                getUsCentersBookable(themeHeader),
            ])

            if (cupListResult?.items.length) {
                await dispatch(
                    setFormEntry(
                        "alreadySubscribedCupIds",
                        cupListResult?.items.reduce((prev, curr) => [ ...prev, curr?.cup?.id ], []),
                    ),
                )
                setGotUserCups(true)
            } else {
                setGotUserCups(true)
            }
            
            const centers = centersBookableResult.data ? Object.values(centersBookableResult.data) : []
            const filteredCenters = centers.filter((c) => !centersToExclude?.includes(c.id))

            setCenters(filteredCenters)

            const userCenterAreaResult = await getUsCentersArea(user.centerId)
            const area = userCenterAreaResult.data?.filter(
                area => area.name !== "Centre fermé" && filteredCenters.find(center => area.centers.includes(center.key)),
            )

            if (!selectedAreaId && user?.centerId) {
                const centerArea = area?.find(area => area.centers?.includes(user.centerId))
                setUserCenterId(centerArea?.id)
                changeSelectedCenter()
            }

            setCentersArea(area)

            if (tournamentCode) {
                tryGetTournamentFromCode()
            }
        }

        fetchData()
    }, [])

    /** Reload center cups */
    useEffect(
        () => {
            if (tournamentCodeCenterId === null && !tournamentCode && !requestWip) {
                reloadCentersCup()
            }
        }, [
            currentStep,
            selectedAreaId,
            registration,
            tournamentCodeCenterId,
            selectedType,
            selectedGender,
            periodStart,
            gotUserCups,
        ])

    useEffect(
        () => {
            if (registration === undefined) {
                setSelectedCup(null)
                setSelectedCupDetails(null)
                unvalidateStep()
            } else if (step.current) {
                const centerArea = centersArea?.find(area => area.centers?.includes(registration?.cup?.centerId))
                step.current.setRecap(
                    <div>
                        {registration?.cup?.name}
                        <br/>
                        {centerArea?.name}
                    </div>,
                )
            }
        }, [ registration ],
    )

    useEffect(
        () => {
            if (!selectedCup) {
                dispatch(
                    setFormEntry(
                        "selectedPadelCup",
                        null,
                    ),
                )
                setSelectedCupDetails(null)
                unvalidateStep()
            } else {
                if (selectedCup?.canRegister || (selectedCup && tournamentCode)) {
                    step?.current?.validate()
                } else {
                    step?.current?.unvalidate()
                }
                dispatch(
                    setFormEntry(
                        "selectedPadelCup",
                        selectedCup,
                    ),
                )
            }
        }, [ selectedCup ],
    )

    useEffect(
        () => {
            if (selectedCupDetails) {
                if (!selectedCup) {
                    setSelectedCupDetails(null)
                    unvalidateStep()
                }
                if (selectedCupDetails.remainingPlaces === 0) {unvalidateStep()}
            }
        }, [ selectedCupDetails ],
    )

    /** Remove error message when tournamentCode is deleted by user */
    useEffect(
        () => {
            if (!!tournamentCode && tournamentCode.length === 0) {
                setErrorMsg(null)
            }
        },[ tournamentCode ],
    )

    function getFilter() {
        return x => x.id === selectedCup.id
    }

    /** Mise à jour auto du tournois selectionné / du récap */
    useEffect(
        () => {
            if (selectedCup) {
                if (cups.length > 1) {
                    let registrationCup = cups.filter(getFilter())[0]
                    if (registrationCup) {
                        selectCup(registrationCup)
                        dispatch(
                            setFormEntry(
                                "selectedPadelCup",
                                selectedCup,
                            ),
                        )
                    }
                }
                const centerArea = centersArea?.find(area => area.centers?.includes(selectedCup.centerId))
                if (selectedArea?.centers) {
                    if (!selectedArea.centers.includes(selectedCup.centerId)) {
                        
                        setSelectedArea(centerArea)
                        setSelectedAreaId(centerArea?.id)
                    }
                }
                if (step.current) {
                    step.current.setRecap(
                        <div>
                            {selectedCup?.name}
                            <br/>
                            {centerArea?.name}
                        </div>,
                    )
                }
            }
            
        }, [ selectedCup, cups ],
    )

    /** Update centers */
    useEffect(
        () => {
            if (centersArea?.length) {changeSelectedCenter()}
        }, [ centersArea ],
    )

    function reloadCentersCup() {
        setLoadingError(false)
        if ((selectedAreaId || registration) && gotUserCups) {
            setRequestWip(true)
            setButtonDisabled(true)
            setLoadingCups(true)
            setCups([])
            const cupDetailsRestore = selectedCupDetails?.id ?
                { ...selectedCupDetails } : null
            if (cupDetailsRestore) {
                setSelectedCupDetails("Loading")
            } else {
                setSelectedCupDetails(null)
            }
            const params = {
                category: selectedType,
                gender: selectedGender,
                offset: 0,
                pagesize: 50,
                periodStart: dayjs(periodStart).format("YYYY-MM-DD"),
                teamId: "_NEW",
            }
            
            if (selectedArea?.centers?.length && currentStep === 0) {
                getAreaCups(
                    { ...params, centerId: JSON.stringify(selectedArea?.centers) },
                    themeHeader,
                ).then((res) => {
                    setButtonDisabled(false)
                    setRequestWip(false)
                    handleTournamentResult(res)
                    if (cupDetailsRestore) {
                        setSelectedCupDetails(cupDetailsRestore)
                    }
                }).catch(() => {
                    setLoadingError(true)
                    setLoadingCups(false)
                })
            } else if (registration && currentStep === 0) {
                const centerArea = centersArea.find(area => area.centers?.includes(registration.centerId))
                if (centerArea?.centers) {
                    getAreaCups(
                        {
                            ...params,
                            centerId: JSON.stringify(centerArea?.centers),
                        },
                        themeHeader,
                    ).then((res) => {
                        setButtonDisabled(false)
                        setRequestWip(false)
                        handleTournamentResult(res)
                        if (cupDetailsRestore) {
                            setSelectedCupDetails(cupDetailsRestore)
                        }
                    }).catch(() => {
                        setButtonDisabled(false)
                        setRequestWip(false)
                        setLoadingCups(false)
                    })
                } else {
                    setButtonDisabled(false)
                    setRequestWip(false)
                    setLoadingCups(false)
                    setInitialized(true)
                }
            } else {
                setRequestWip(false)
            }
        }
    }

    function changeSelectedCenter(e) {
        let newValue
        if (select.current) {
            newValue = select.current.value
        } else if (e) {
            newValue = e.target.value
        }

        if (newValue) {
            if (newValue !== selectedAreaId && selectedAreaId !== null) {
                clearTournamentCode()
                setTournamentCodeCenterId(null)
                setSelectedCup(null)
                setSelectedCupDetails(null)
                unvalidateStep()
            }
            setSelectedAreaId(newValue)
            const currentArea = centersArea.find(x => x.id === parseInt(newValue))
            setSelectedArea(currentArea)
            
            setSelectedAreaName(currentArea?.name)
        }
    }

    function clearTournamentCode() {
        dispatch(
            setFormEntry(
                "tournamentCode",
                "",
            ),
        )
    }

    function setTournamentCode(code) {
        dispatch(
            setFormEntry(
                "tournamentCode",
                code,
            ),
        )
    }

    function onCupClick(event) {
        event.stopPropagation()
        event.preventDefault()
        const clickedCup = cups?.filter(x => x.id === parseInt(event.currentTarget.id))[0]
        if (selectedCup && selectedCup.id === clickedCup.id) {
            setSelectedCup(null)
            setSelectedCupDetails(null)
            unvalidateStep()
        } else {
            selectCup(clickedCup)
        }
    }

    function selectCup(cup) {
        setLoadingCupsDetails(true);
        (
            cup.remainingPlaces === 0
            || (!alreadySubscribedCupIds)
            || (alreadySubscribedCupIds?.includes(cup.id))
            || ((cup.full || cup.isFull) && !cup.canRegister && !!cup.reservedForVIPUntil)
            || (cup.isFull)
        ) ?
            unvalidateStep()
            :
            validateStep()
        let alreadySubscribed
        if (alreadySubscribedCupIds) {
            if (alreadySubscribedCupIds?.includes(cup.id)) {
                setSelectedCup(null)
                setLoadingCupsDetails(false)
                setSelectedCupDetails(null)
                unvalidateStep()
                alreadySubscribed = true
            }	
        }
        if (!alreadySubscribed) {
            setSelectedCup(cup)
            if (!loadingCupDetails) {
                getCupDetails(cup.id).then(
                    (r) => {
                        setLoadingCupsDetails(false)
                        setSelectedCupDetails(r.data.data)
                    },
                )
            }
            setSelectedCupDetails("Loading")
        }
    }

    function validateStep() {
        if (step.current) {
            if (!loadingCupDetails) {step.current.validate()}
        }
    }

    function unvalidateStep() {
        if (step.current) {
            step.current.unvalidate()
        }
    }

    function storeCupInfo() {
        if (step.current) {
            step.current.setRecap(
                <div>
                    {selectedCup.name}
                    <br/>
                    {selectedAreaName}
                </div>,
            )
        }
        addSearchParams("centerFilter", selectedCenter ?? "")
        addSearchParams("cupId", selectedCup?.id)
        if (selectedCup?.player2Required) {
            setAjaxLoading(false)
            dispatch(nextStep())
        } else {
            setAjaxLoading(true)
            let teamId = "_NEW"

            const changeRegistration = (registration) => {
                dispatch(
                    setFormEntry(
                        "TournamentRegistration",
                        registration,
                    ),
                )
            }
            
            postCupRegistration(
                selectedCup.centerId,
                selectedCup.id,
                teamId,
                user?.firstname,
                null,
                null,
                null,
                null,
                null,
                true,
                user.contactId,
                null,
                themeHeader,
            ).then(
                async (res) => {
                    const registration = res?.data?.data
                    await changeRegistration(registration)
                    await dispatch(
                        setFormEntry(
                            "tunnelTournamentRegistration",
                            {
                                isNewRegistration: true,
                            },
                        ),
                    )
                    setAjaxLoading(false)
                    dispatch(goToStep(2))
                }, () => {
                    setAjaxLoading(false)
                },
            )
        }
    }

    function subscribeToWaitingList() {
        let teamId = "_NEW"
        setAjaxLoading(true)
        const postRegistration = partnerId => {
            postCupRegistration(
                selectedCup.centerId,
                selectedCup.id,
                teamId,
                user?.firstname,
                undefined,
                undefined,
                undefined,
                undefined,
                true,
                true,
                user.contactId,
                partnerId,
                themeHeader,
            ).then(
                (res) => {
                    setAjaxLoading(false)
                    dispatch(clearAllForms())
                    goToStep(0)
                    navigate(
                        path("/confirmcommand"), {
                            state: {
                                confirmedSubscription: res.data.data,
                                message: "Un email te sera envoyé en cas de places disponibles.",
                                subtitle: "Nous te confirmons l'inscription de ton équipe en liste d’attente.",
                                success: true,
                                title: "Inscription à la liste d’attente",
                                tunnelType: tunnelType,
                            },
                        },
                    )
                },
            )
        }
        const waitingListPartner = {
            centerId:2,
            emailAddress: "a.tran+001@debussac.net",
            firstname: "waitingList",
            lastname: "waitingList",
            phoneNumber: "33777777777",
        }

        if (selectedCup?.player2Required) {
            postContact(waitingListPartner, themeHeader).then(res =>{
                postRegistration(res?.contact?.id)
            })
        } else {
            postRegistration()
        }        
    }

    function tryGetTournamentFromCode() {
        setTournamentLoading(true)
        const codeFormatted = slugify(tournamentCode)
        if (!tournamentCode) {
            setTournamentLoading(false)
            setErrorMsg("Veuillez saisir un code")
        } else if (codeFormatted && codeFormatted !== "") {
            getPrivateTournament(codeFormatted, themeHeader).then(
                (res) => {
                    if (res) {
                        if (!res.data.data.Message) {
                            let sub = res.data.data
                            const area = centersArea.find(area => area.centers?.includes(sub.centerId))
                            setSelectedArea(area)
                            setSelectedAreaId(area?.id)
                            setTournamentCodeCenterId(sub.centerId)
                            setCups([ sub ])
                            selectCup(sub)
                            setErrorMsg(null)
                        } else {
                            setErrorMsg("Code incorrect")
                        }
                    } else {
                        setErrorMsg("Code incorrect")
                    }
                    setTournamentLoading(false)
                    setInitialized(true)
                },
            )
        }

    }

    function clearTournament() {
        clearTournamentCode()
        setTournamentCodeCenterId(null)
        setSelectedCup(null)
    }
    useEffect(() => {
        if (selectedCenter) {
            setFilteredCups(cups?.filter(cup => cup.centerId === selectedCenter))
        } else if (searchParams.get("centerFilter") && currentStep === 0) {
            setCurrentFilter({
                selectedCenter: selectedArea?.centers?.find(centerId => centerId === parseInt(searchParams.get("centerFilter"))),
                selectedGender: selectedGender,
                selectedType: selectedType,
            })
            addSearchParams("centerFilter", "")
        } else {
            setFilteredCups(null)
        }
    }, [ cups, selectedCenter ])

    const privateCupFunction = tournamentCodeCenterId ? 
        clearTournament
        :
        tryGetTournamentFromCode

    useEffect(
        () => {
            setCurrentFilter({
                selectedCenter: null,
                selectedGender: selectedGender,
                selectedType: selectedType,
            })
        }, [ selectedAreaId ],
    )
    useEffect(() => {
        const urlCorrespondingCup = searchParams?.get("cupId") && cups?.find(cup => cup.id === parseInt(searchParams.get("cupId")))
        if (initialized && urlCorrespondingCup) {
            selectCup(urlCorrespondingCup)
        }
    }, [ initialized ])

    return (
        <Step
            {...props}
            title="Choix du tournoi"
            help={ help }
            helpId={"monTournoiHelp"}
            noReturn={alreadySubscribedCupIds?.length === 0}
            onReturn={() => navigate("/padel/tournois")}
            checkBeforeNextStep={storeCupInfo}
            returnText={<span className="customText">Voir mes tournois</span>}
            nextText={<span className="customText">Suivant</span>}
            promise={true}
            className={classNames(tunnelType, "noMarginBottom cupChoices")}
            ref={step}
        >
            {ajaxLoading && <Preloader fixed/>} 
            <PreForm>
                <div className="flexVerticalOnMobile">
                    <label className="h2">
                        <span>Ville :</span>
                        <SelectInput
                            ref={select}
                            id="schoolPicker"
                            fullWidth
                            disabled={buttonDisabled}
                            value={ selectedAreaId ?
                                selectedAreaId 
                                :
                                userCenterId
                            }
                            options={ centersArea?.length > 0 ?
                                centersArea.map(x => ({ label: x.name, value: x.id }))
                                :
                                undefined
                            }
                            onChange={changeSelectedCenter}
                            placeholderOption="Sélectionne une ville"
                            required
                        />
                    </label>
                    <label className="h2">
                        <span>Date :</span>
                        <InputDate
                            bind={[ periodStart, setPeriodStart ]} 
                            min={new Date()}
                            useDatePicker
                            customClass={
                                "customDayPicker"
                            }
                            checkValue
        
                        />
                    </label>
                </div>
                <div className="rightPart">
                    <label className="h2 private">
                        <span className="label"><span className="join">Rejoins un</span><span>tournoi privé :&nbsp;</span></span>
                        <TextInput
                            label="Code"
                            customRootCls={"codeInput"}
                            value={tournamentCode ?? ""}
                            onChange={setTournamentCode}
                            placeholder="Entre ton code"
                            errorMsg={errorMsg}
                            actions={[ {
                                onClick: tournamentLoading ?
                                    () => {}
                                    :
                                    privateCupFunction,
                                render: <ButtonCta
                                    text={tournamentLoading ?
                                        <Preloader/>
                                        :
                                        tournamentCodeCenterId ?
                                            "/assets/icons/cross-white.svg" : "OK"}
                                    noSkew
                                    noShadow
                                />,
                            } ]}
                        />
                    </label>
                    <div className="moreOptions">
                        <ButtonCta
                            isAlt
                            text= "+ plus d'options"
                            onClick={() => filterPopin?.current?.show()}
                        />
                    </div>
                    {/* TODO : Replace with new modal system when cup are working */}
                    <CupFilterPopin
                        centersOptions={centers?.filter(center => selectedArea?.centers?.includes(center.id))}
                        currentFilter={currentFilter}
                        setCurrentFilter={setCurrentFilter}
                        ref={filterPopin}
                    />
                </div>
            </PreForm>
            {(selectedCenter || selectedGender || selectedType) && (
                <div className="activeFilters">
                    {selectedCenter && (
                        <CheckBlock
                            checked
                            unselect
                            action={
                                () => {
                                    setCurrentFilter({
                                        selectedCenter: null,
                                        selectedGender: selectedGender,
                                        selectedType: selectedType,
                                    })
                                }
                            }
                        >
                            {centers?.find(center => center.key === selectedCenter)?.value}
                        </CheckBlock>
                    )}
                    {selectedGender && (
                        <CheckBlock
                            checked
                            unselect
                            action={
                                () => {
                                    setCurrentFilter({
                                        selectedCenter: selectedCenter,
                                        selectedGender: null,
                                        selectedType: selectedType,
                                    })
                                }
                            }
                        >
                            {genderOption?.find(gender => gender.value === selectedGender)?.label}
                        </CheckBlock>
                    )}
                    {selectedType && (
                        <CheckBlock
                            checked
                            unselect
                            action={
                                () => {
                                    setCurrentFilter({
                                        selectedCenter: selectedCenter,
                                        selectedGender: selectedGender,
                                        selectedType: null,
                                    })
                                }
                            }
                        >
                            {typeOption?.find(type => type.value === selectedType)?.label}
                        </CheckBlock>
                    )}
                </div>
            )}
            <div className="formsHolder centerPicker c-row justify-center align-start layoutSmall">
                { (loadingCups === false  && initialized && (cups?.length === 0 || (selectedCenter && !filteredCups?.length)) && centersArea?.length > 0 && selectedAreaId !== null) ?
                    (<NoCup 
                        tunnelType={tunnelType}
                        selectedCenterName={selectedAreaName}
                        customMessage={""}
                        haveFilter={!!(selectedCenter || selectedGender || selectedType)}
                    />)
                    :
                    (<React.Fragment>
                        <div className="c-col c-col--6 c-col--sm--12 flex space-between align-center wrap ">
                            <FormValidator
                                onValidation={validateStep}
                                onUnvalidation={unvalidateStep}
                                ref={form}
                            >
                                <div className={"radioList sm--checkbox"}>
                                    {(loadingCups || loadingTeamSubsIds || !gotUserCups || !initialized) && <Preloader />}
                                    {loadingError && !(loadingCups || loadingTeamSubsIds || !gotUserCups || !initialized) && <div className="loadingError">Une erreur est survenue lors du chargement. Recharge la page.</div>}
                                    {!selectedAreaId && !loadingError && !(loadingCups || loadingTeamSubsIds || !gotUserCups || !initialized) && <div className="loadingError">Sélectionne une ville</div>}
                                    {!(loadingCups || loadingTeamSubsIds || !gotUserCups || !initialized) && (
                                        filteredCups ?
                                            filteredCups.map(
                                                (cup, i) => {
                                                    if (i <= cupToDisplay) {
                                                        return (
                                                            <CupCheckblock
                                                                cup={cup}
                                                                selectedCup={selectedCup}
                                                                onClick={onCupClick}
                                                                isMobile={isMobile}
                                                                selectedCupDetails={selectedCupDetails}
                                                                subscribeToWaitingList={subscribeToWaitingList}
                                                                key={"cup-" + cup.id}
                                                                isLoading={loadingCupDetails}
                                                            />
                                                        )
                                                    }
                                                },
                                            )
                                            :
                                            cups.map(
                                                (cup, i) => {
                                                    if (i <= cupToDisplay) {
                                                        return (
                                                            <CupCheckblock
                                                                cup={cup}
                                                                selectedCup={selectedCup}
                                                                onClick={onCupClick}
                                                                isMobile={isMobile}
                                                                selectedCupDetails={selectedCupDetails}
                                                                subscribeToWaitingList={subscribeToWaitingList}
                                                                key={"cup-" + cup.id}
                                                                isLoading={loadingCupDetails}
                                                            />
                                                        )
                                                    }
                                                },
                                            )
                                    )}
                                    {((filteredCups && filteredCups?.length  > cupToDisplay + 1) || (!filteredCups && cups?.length !== 0 && cups.length > cupToDisplay + 1)) && (
                                        <ButtonCta
                                            className="moreCups" 
                                            isAlt
                                            text= "Voir plus"
                                            onClick={() => setCupToDisplay(prev => prev + 10)}
                                        />
                                    )}
                                </div>
                            </FormValidator>
                        </div>
                        <div className="c-col c-col--3 c-col--sm--12 flex space-between align-center wrap c-col--offset--left--md--1 sticky no-padding">
                            {!isMobile && (
                                <ReservationSlotInfoCard
                                    reservationSlot={selectedCupDetails}
                                    subscribeToWaitingListCallback={subscribeToWaitingList}
                                    slotName="tournoi"
                                    pleaseSelectText="ton tournoi"
                                    tunnelType={tunnelType}
                                />
                            )}
                        </div>
                    </React.Fragment>)
                }
            </div>
        </Step>
    )
}

CupChoice.propTypes = {
    cls: string,
    numero: number,
    tunnelType: string,
}

CupChoice.formName = "CupChoice"

export default CupChoice
