import * as yup from "yup"

import { BusinessLeagueTunnelPaths } from "../../../routes/ApplicationPaths/BusinessLeagueTunnelPaths.js"

const stepGeneralValidation = yup.object().shape({
    general: yup.object().shape({
        billingAdress: yup.string().required("Ce champ est requis"),
        blazon: yup.string().required("Ce champ est requis"),
        center: yup.object().shape({
            label: yup.string().required("Ce champ est requis"),
            value: yup.number().required("Ce champ est requis"),
        }),
        company: yup.string().min(2, "Le nom de l'entreprise doit contenir au moins 2 caractères").required("Ce champ est requis"),
        teamName: yup.string().min(2, "Le nom de l'équipe doit contenir au moins 2 caractères").required("Ce champ est requis"),
    }),
})

const stepCaptainValidation = yup.object().shape({
    captain: yup.object().shape({
        email: yup.string().email("L'adresse email est invalide").required("Ce champ est requis"),
        firstName: yup.string().min(2, "Le prénom doit contenir au moins 2 caractères").required("Ce champ est requis"),
        lastName: yup.string().min(2, "Le nom doit contenir au moins 2 caractères").required("Ce champ est requis"),
        phone: yup.string().matches(/^[0-9]{10}$/, "Le numéro de téléphone est invalide").required("Ce champ est" +
            " requis"),
    }),
})

const stepPreferencesValidation = yup.object().shape({
    preferences: yup.object().shape({
        comment: yup.string(),
        preferredDays: yup.array().min(1, "Vous devez sélectionner au moins un jour").required("Ce champ est requis"),
        preferredHours: yup.array().min(1, "Vous devez sélectionner au moins un créneau").required("Ce champ est requis"),
    }),
})

export const mergesValidationSchemas = yup.object().shape({
    ...stepGeneralValidation.fields,
    ...stepCaptainValidation.fields,
    ...stepPreferencesValidation.fields,
})

export const businessLeagueTunnelValidation = {
    [BusinessLeagueTunnelPaths.stepGeneral]: stepGeneralValidation,
    [BusinessLeagueTunnelPaths.stepCaptain]: stepCaptainValidation,
    [BusinessLeagueTunnelPaths.stepCommunication]: null,
    [BusinessLeagueTunnelPaths.stepPreferences]: mergesValidationSchemas,
}

export const getStepsWithErrors = (formStateErrors) => {
    const errorSteps = []

    const checkErrors = (fields) => {
        if (!fields) {return false}
        return Object.values(fields).some(field => field && field.message)
    }

    if (checkErrors(formStateErrors?.general)) {errorSteps.push(BusinessLeagueTunnelPaths.stepGeneral)}
    if (checkErrors(formStateErrors?.captain || {})) {errorSteps.push(BusinessLeagueTunnelPaths.stepCaptain)}
    if (checkErrors(formStateErrors?.com || {})) {errorSteps.push(BusinessLeagueTunnelPaths.stepCommunication)}
    if (checkErrors(formStateErrors?.preferences || {})) {errorSteps.push(BusinessLeagueTunnelPaths.stepPreferences)}

    return errorSteps
}
