/* # Connecteurs endpoints API CUP */

import { _apiCall } from "../../../backend/axios"
import { buildQueryString, returnApiAnswer } from "../../../backend/helpers"

/* ## Listing */
export function getCupList(params) {
    const query = buildQueryString(params)

    return _apiCall(
        "read/cup/registrations" + query,
        "GET",
    ).then((response) => {
        return {
            count: response.data.data.hitCount ?? response.data.data.length ?? 0,
            items: response.data.data.items ?? response.data.data,
        }
    })
}

export function getCupRegistrationScores(id) {
    return _apiCall(
        `read/cups/${id}/printScores`,
        "GET",
        [],
        null,
        true,
        true,
        null,
        null,
        null,
        null,
        null,
        false,
    ).then(res => {
        return res
    })
}

/* ## Registration Read / Write*/
export function getCupRegistrationDetails(id) {
    return _apiCall(
        `read/cup/registration/${id}/detail`,
        "GET",
    ).then(returnApiAnswer)
}
    
export function postCupRegistration(
    centerId,
    cupId,
    selectedTeamId,
    teamName,
    picture,
    registrationId,
    selectedPlayers,
    comments,
    waitingForAvailability,
    sendEmailToTeamMembers,
    captainId,
    viceCaptainId,
) {
    return _apiCall(
        "modify/cup/registration/edit",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        registrationId ? 
            {
                captainId: captainId,
                centerId,
                comments,
                cupId,
                newTeamName: teamName,
                registrationId,
                selectedPlayers,
                selectedTeamId,
                sendEmailToTeamMembers,
                viceCaptainId: viceCaptainId,
                waitingForAvailability: waitingForAvailability,
            }
            :
            {
                captainId: captainId,
                centerId,
                cupId,
                newTeamName: teamName,
                picture,
                selectedTeamId: selectedTeamId ? selectedTeamId : "_NEW",
                sendEmailToTeamMembers,
                viceCaptainId: viceCaptainId,
                waitingForAvailability,
            },
    ).then(returnApiAnswer)
}

/* ## Choix Equipe*/
export function getUserTeams() {
    return _apiCall(
        "read/cup/registration/teams",
        "GET",
    ).then(returnApiAnswer)
}

export function uploadTeamBlazon(teamId, file, bar, tunnelType) {
    const id = tunnelType === "league" ? "baseTeam" : "team"
    return _apiCall(
        `modify/${tunnelType}/${id}/${teamId}/blazon`,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        file,
        null,
        null,
        null,
        true,
        bar,
    ).then(returnApiAnswer)
}

export function deleteTeamBlazon(teamId) {
    return _apiCall(
        `delete/cup/team/${teamId}/blazon`,
        "POST",
    )
}

/* ## Choix Tournois */
export function getCupCenters() {
    return _apiCall(
        "read/cup/centers",
        "GET",
    ).then(returnApiAnswer)
}
export function getAreaCups(params) {
    const query = buildQueryString(params)
    return _apiCall(
        `read/cups/registrations${query}`,
        "GET",
        { 
            "Content-Type" : "application/json",
        },
    ).then(returnApiAnswer)
}
    
export function getCenterCups(centerId, teamId) {
    if (teamId === null) {
        teamId = "_NEW"
    }
    return _apiCall(
        `read/cup/center/${centerId}/team/${teamId}/list`,
        "GET",
    ).then(returnApiAnswer)
}
    
export function getCupDetails(cupId) {
    return _apiCall(
        `read/cup/${cupId}`,
        "GET",
    ).then(returnApiAnswer)
}

export function getPrivateTournament(privateCode) {
    return _apiCall(
        `read/cup/private/${privateCode}`,
        "GET",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true,
    ).then(returnApiAnswer)
}

/* ## Choix Effectif */
export function addOnePlayerToCupTeam(registrationId, contactId) {
    return _apiCall(
        "create/cup/registration/players/pick",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        {
            contactId : contactId,
            registrationId : registrationId,
        },
    ).then(returnApiAnswer)
}
export function addMultiplePlayersToCupTeam(teamSubscriptionId, contactIds) {
    return _apiCall(
        `modify/cup/team/subscription/${teamSubscriptionId}/pickUsFriend`,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        {
            contactIds : contactIds,
        },
    )
}
export function deletePlayerFromCupTeam(registrationId, contactId) {
    return _apiCall(
        "modify/cup/registration/unpick/player",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        {
            contactId : contactId,
            registrationId : registrationId,
        },
    ).then(returnApiAnswer)
}
export function sendEmailInvitation(email, tokenBaseUrl, teamId, cupId) {
    return _apiCall(
        "create/profil/invitation/friend-cup/email",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {
            cupId: cupId,
            email: email,
            linkedTeamSubscription: teamId,
            tokenBaseUrl: tokenBaseUrl,
        },
    ).then(returnApiAnswer)
}

/* ## Prestation Supplémentaires */
export function addCupOption(cupId, optionId) {
    return _apiCall(
        `modify/cup/${cupId}/option/add`,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        {
            optionId: optionId,
        },
    ).then(returnApiAnswer)
}
export function  removeCupOption(cupId, optionId) {
    return _apiCall(
        `modify/cup/${cupId}/option/delete`,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        {
            optionId: optionId,
        },
    ).then(returnApiAnswer)
}

/* ## Nike Equipment */
export function addCupProduct(teamRegistrationId, productId, size, quantity) {
    return _apiCall(
        `modify/cup/registration/${teamRegistrationId}/product/add`,
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {
            ["product-" + productId] : size,
            ["quantity-product-" + productId] : quantity,
        },
    ).then(returnApiAnswer)
}
export function removeCupProduct(teamRegistrationId, productId, size) {
    return _apiCall(
        `modify/cup/registration/${teamRegistrationId}/product/delete/${productId}/${size}`,
        "POST",
    ).then(returnApiAnswer)
}

/* ## Order Summary */
export function addCupPromoCode(registrationId, code) {
    return _apiCall(
        `modify/cup/registration/${registrationId}/discountCode/add`,
        "POST",
        { "Content-Type" : "multipart/form-data" },
        {
            code: code.toUpperCase(),
        },
    ).then(returnApiAnswer)
}
export function removeCupPromoCode(registrationId, code) {
    return _apiCall(
        `modify/cup/registration/${registrationId}/discountCode/delete`,
        "POST",
        { "Content-Type" : "multipart/form-data" },
        {
            code: code,
        },
    ).then(returnApiAnswer)
}
export function generatePayzenCupPaymentReqs(data) {
    return _apiCall(
        "create/profil/payment/card/cup/registration/payment/reqs",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then(returnApiAnswer)
}
export function preregisterCup(registrationId) {
    return _apiCall(
        "modify/cup/team/registration/preregister",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },{
            registrationId: registrationId,
        },
    ).then(returnApiAnswer)
}
export function cancelCupPreregistration(registrationId) {
    return _apiCall(
        "modify/cup/team/registration/preregister/cancel",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },{
            registrationId: registrationId,
        },
    ).then(returnApiAnswer)
}

export function getUSUserByName(firstname, lastname) {
    return _apiCall(
        "read/users/search_firstname_lastname",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },{
            firstname: firstname,
            lastname: lastname,
        },
    ).then(returnApiAnswer)
}

export function getCupTeamPlayersPick(teamId) {
    return _apiCall(
        `read/cup/registration/teams/${teamId}/players/pick`,
        "GET",
    ).then(returnApiAnswer)
}
