import classNames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"

import FileInput from "../../Forms/FileInput/FileInput"

import "./TeamPicture.scss"

export const TeamPicture = ({ image, onClick, size, editable, editCallback, placeHolder = USER_PLACEHOLDER }) => {
    const [ imgSrc, setImgSrc ] = useState(image)
    const inputUpload = useRef(null)

    const handleImageError = (e) => {
        e.stopPropagation()
        setImgSrc(null)
    }

    useEffect(() => {
        setImgSrc(image)
    }, [ image ])

    return (
        <div className={classNames("team-picture", {
            action: Boolean(onClick),
        })} onClick={onClick}  style={{ width: `${size}px` }}>
            {imgSrc ? (
                <img src={imgSrc} data-testid="teamLogo" alt="team logo" onError={handleImageError}/>
            ) : (
                <div className="blazon-placeholder">
                    <img src={placeHolder} alt="Photo de profil"/>
                </div>
            )}
            {editable && (
                <>
                    <FileInput
                        id="avatarUpload"
                        label={
                            <img src="/assets/icons/pencil--white.svg" alt="upload icon"/>
                        }
                        acceptType="image"
                        onFileUploadPromiseCallback={editCallback}
                        imageCropWidth={window.innerWidth < 375 ? 200 : 300}
                        imageCropHeight={window.innerWidth < 375 ? 200 : 300}
                        outputWidth={320}
                        outputHeight={320}
                        hiddenInput={true}
                        subLabel={`Ton Image doit avoir une dimension de ${320}px`}
                        maxSize={5}
                        ref={inputUpload}
                        customModalTitle={"Modifier le blason"}
                    />
                </>
            )}
        </div>
    )
}

const USER_PLACEHOLDER = "/assets/icons/league/blazonPlaceholder.svg"

TeamPicture.propTypes = {
    editCallback: PropTypes.func,
    editable: PropTypes.bool,
    image: PropTypes.string,
    onClick: PropTypes.func,
    placeHolder: PropTypes.string,
    size: PropTypes.number,
}
