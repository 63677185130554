import { number, object, oneOfType, string } from "prop-types"

function StepComponent({
    numero,
    icon,
    image,
    title,
    text,
}) {

    return (
        <article className="StepComponent">
            <div className="icon">
                <span className="numero">
                    {numero}
                </span>
                <img src={"/assets/images/WhatIsSuperSub/" + icon}/>
            </div>

            <h2>{title}</h2>
            <p>{text}</p>
            <img src={"/assets/images/WhatIsSuperSub/" + image}/>
        </article>
    )
}
StepComponent.propTypes = {
    icon: string,
    image: string,
    numero: number,
    text: oneOfType([ string, object ]),
    title: string, 
}

export default StepComponent
