import { bool, func, object, oneOfType, string } from "prop-types"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import Form from "../../../../../components/Forms/Form/Form"
import FormValidator from "../../../../../components/Forms/FormValidator"
import InputDate from "../../../../../components/Forms/InputDate/InputDate"
import TextArea from "../../../../../components/Forms/textArea/TextArea"
import { SelectInput } from "../../../../../components/Inputs/Select/SelectInput.jsx"

import "./SlotDemandRequestForm.scss"

const SlotDemandRequestForm = forwardRef(
    (
        {
            onValidationFunct,
            data,
            customCls,
        },
        ref,
    ) => {
        const [ jourDemande, setJourDemande ] = useState(new Date())
        const [ horaire, setHoraire ] = useState("")

        /* REFS */
        const commentaires = useRef(null)
        const form = useRef(null)

        if (data && data.date && data.horaire && !horaire) {
            setJourDemande(data.date)
            setHoraire(data.horaire)

            if (commentaires.current) {
                commentaires.current.value = data.commentaires
            }
        }

        function onValidation() {
        }

        function getInfo() {
            return {
                commentaires: commentaires.current ? commentaires.current.value : null,
                date: jourDemande,
                horaire: horaire,
            }
        }

        function onUnvalidation() {
        }

        useImperativeHandle(
            ref, 
            () => ({
                clear() {
                    if (this.form) {
                        this.form.current.querySelectorAll("input").forEach(element => {
                            element.value = ""
                        })
                    }
                },
            }),
        )

        let hoursOptions = {
            "09H00": "09h00",
            "09H30": "09h30",
            "10H00": "10h00",
            "10H30": "10h30",
            "11H00": "11h00",
            "11H30": "11h30",
            "12H00": "12h00",
            "12H30": "12h30",
            "13H00": "13h00",
            "13H30": "13h30",
            "14H00": "14h00",
            "14H30": "14h30",
            "15H00": "15h00",
            "15H30": "15h30",
            "16H00": "16h00",
            "16H30": "16h30",
            "17H00": "17h00",
            "17H30": "17h30",
            "18H00": "18h00",
            "18H30": "18h30",
            "19H00": "19h00",
            "19H30": "19h30",
            "20H00": "20h00",
        }

        return (
            <FormValidator onValidation={onValidation} onUnvalidation={onUnvalidation} className={customCls ? customCls : ""}>
                <Form title="Demander un créneau" ref={form}>
                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1">
                        <InputDate
                            label={<span>Jour <span className="label-star">*</span></span>} 
                            bind={[ jourDemande, setJourDemande ]}
                            min={new Date()}
                            required
                            useDatePicker={true}
                        />
                    </div>
                    <div className="c-col c-col--6 c-col--sm--12 c-mrg--btm--1 select-horaire">
                        <SelectInput
                            disabled={!hoursOptions}
                            value={horaire}
                            options={Object.keys(hoursOptions).map(x => {
                                return { label: x, value: x }
                            })}
                            label="Horaire"
                            onChange={e => setHoraire(e?.target?.value)}
                            placeholderOption="Choisissez un créneau"
                            required
                        />
                    </div>

                    <div className="c-col c-mrg--btm--1">
                        <TextArea
                            ref={commentaires}
                            placeholder="Commentaires (non obligatoire)"
                        />
                    </div>

                    <div className="bottomMentionHelp">
                        <p>En cas de besoin, n'hésitez pas à nous contacter au</p>
                        <strong>0 806 23 00 24</strong>
                    </div>

                    <div className="c-col c-col--4 c-col--sm--12 c-mrg--btm--1 c-row align-center send-btn-div">
                        <ButtonCta 
                            disabled={!jourDemande || !horaire}
                            onClick={
                                () => {
                                    const info = getInfo()
                                    setHoraire(null)
                                    onValidationFunct(info)
                                }}
                            text="ENVOYER LA DEMANDE"
                        />
                    </div>
                </Form>
            </FormValidator>
        )
    },
)

SlotDemandRequestForm.propTypes = {
    customCls: oneOfType([ bool, string ]),
    data: object,
    onValidationFunct: func,
}

SlotDemandRequestForm.displayName = "SlotDemandRequestForm"

export default SlotDemandRequestForm
