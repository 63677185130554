import { func, object, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"

import countries from "./assets/countries"
import flag from "./assets/countries-flags.png"

import "./CountrySelection.scss"

const CountrySelection = ({ selectedCountry, setSelectedCountry }) => {
    const rawCountries = countries
    const [ openDropMenu, setOpenDropMenu ] = useState(false)

    function listenerClickOutside(event) {
        const countriesDiv = document.querySelector(".countryselection")
        let targetEl = event.target
        do {
            if (targetEl === countriesDiv) {
                return
            }
            // Go up the DOM
            targetEl = targetEl.parentNode
        } while (targetEl)
        // This is a click outside.      
        document.removeEventListener("click", listenerClickOutside)
        setOpenDropMenu(false)
    }

    useEffect(
        () => {
            if (openDropMenu) {
                document.addEventListener("click", listenerClickOutside)
                const countriesList = document.querySelector(".countries")
                const selectedFlag = document.querySelector(`.countries .${selectedCountry.code}`)
                countriesList.scrollTo(0, selectedFlag.offsetTop - 16)
            } else {
                document.removeEventListener("click", listenerClickOutside)
            }
        },[ openDropMenu ],
    )

    return (
        <div
            className="countrySelection"
            onClick={() => setOpenDropMenu(!openDropMenu)}
        >
            <div className="flagWrapper">
                <div
                    className={"flag " + selectedCountry.code}
                    title={selectedCountry.name}
                    style={{ backgroundImage: `url(${flag})` }}
                >
                </div>
                <div className={openDropMenu ? "arrowUp" : "arrowDown"}></div>
            </div>
            {openDropMenu && (
                <ul className="countries">
                    {rawCountries.map(country => (
                        <li
                            className={country.name === selectedCountry.name ? "highlight country" : "country"}
                            key={country.name + country.code}
                            onClick={() =>{
                                setSelectedCountry(country)
                                setOpenDropMenu(!openDropMenu)
                            }}
                        >
                            <div
                                className={"flag " + country.code}
                                title={country.name}
                                style={{ backgroundImage: `url(${flag})` }}
                            >
                            </div>
                            <span >{country.name}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

CountrySelection.propTypes = {
    selectedCountry: oneOfType([ string, object ]),
    setSelectedCountry: func,
}

export default CountrySelection
