import { useTheme } from "../components/ThemeContext/ThemeContext"

function useMediaPath() {
    const theme = useTheme()

    return paths => {
        const [ soccerPath, padelPath, businessPath ] = paths

        switch (theme) {
        case "theme-soccer":
        default:
            return soccerPath

        case "theme-padel":
            return padelPath

        case "theme-business":
            return businessPath
        }
    }
}

export default useMediaPath
