import { bool, oneOfType, string } from "prop-types"

import { useTheme } from "../../../../../components/ThemeContext/ThemeContext"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage"

import "./Nocup.scss"

export default function NoCup({ selectedCenterName, tunnelType, customMessage, haveFilter }) {
    const theme = useTheme()
    const { tu } = useUrbanLanguage()
    const cupHref = theme === "theme-soccer" ?
        "https://www.urbansoccer.fr/type_offre/tournois/"
        :
        "https://www.urbanpadel.fr/tournois/"

    return (
        <div className="c-col c-col--10 c-col--sm--12 flex justify-center align-center wrap ">
            <span className="noCupWarning">
                {!haveFilter && (` Aucun${tunnelType === "LEAGUE" ? "e" : ""} ${tunnelType === "CUP" ? "tournoi" : tu("league.name")} n’est actuellement organisé${tunnelType === "LEAGUE" ? "e" : ""} ${theme === "theme-padel" && tunnelType === "CUP" ? " dans la ville de " : " à "}${selectedCenterName}${customMessage ?? ", nous te conseillons d’essayer un centre à proximité"}.`)}
                {haveFilter && "Aucun tournoi correspondant à tes critères n’est actuellement organisé."}
            </span> 
            <span className="noCupMoreInfo">
                Plus d’informations sur les prochain{tunnelType === "LEAGUE" ? "e" : ""}s {tunnelType === "CUP" ? "tournois" : `${tu("league.name")}s`} sur notre site&nbsp;
                <a 
                    href={tunnelType === "CUP" ?
                        cupHref
                        :
                        "https://www.urbansoccer.fr/les-urbanleagues/"
                    }
                    target="_blank" rel="noreferrer"
                >
                    {tu("wordpress")}
                </a>
            </span>
        </div>
    )
}

NoCup.propTypes = {
    customMessage: oneOfType([ bool, string ]),
    haveFilter: bool,
    selectedCenterName: string,
    tunnelType: string,
}
