import PropTypes from "prop-types"
import { components } from "react-select"

import { CustomMultiValueContainer } from "./CustomMultiValueContainer"
import { CustomMultiValueLabel } from "./CustomMultiValueLabel"
import { CustomMultiValueRemove } from "./CustomMultiValueRemove"

export const CustomMultiValue = props => {
    const { children } = props

    return (
        <components.MultiValue
            {...props}
            components={{
                Container: CustomMultiValueContainer,
                Label: CustomMultiValueLabel,
                Remove: CustomMultiValueRemove,
            }}
        >
            <div className="select-input-multi-value">
                {children}
            </div>
        </components.MultiValue>
    )
}

CustomMultiValue.propTypes = {
    children: PropTypes.node,
}
