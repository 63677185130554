import { bool, func, object } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { StageListingElementPopIn } from "./StageListingElementPopIn.jsx"
import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import ListingRegistrationHolder from "../../../../../components/listing/ListingRegistrationHolder"
import StatusDisplay from "../../../../../components/listing/StatusDisplay"
import Preloader from "../../../../../components/loaders/preloader/preloader"
import MessagePopin from "../../../../../components/popin/MessagePopin/MessagePopin"
import { getMedicalCertificateButtonText } from "../../../../../components/reservation/medicalCertificateText"
import { useTheme } from "../../../../../components/ThemeContext/ThemeContext"
import { displayBill } from "../../../../../globalAPI/api"
import { useCanDownload } from "../../../../../hooks/useCanDownload.jsx"
import ModalHandler from "../../../../../providers/Modal/ModalHandler"
import usePath from "../../../../../routes/services/usePath"
import { displayCenterName } from "../../../../../services/displayCenterName"
import { openPopin } from "../../../../../services/routingManagment"
import { setFormEntry } from "../../../../../store/forms/actions"
import QuitWaitingListPopin from "../../../../teams/components/QuitWaitingListPopin/QuitWaitingListPopin"
import { addStageMedicalCertificate, cancelStageRegistration } from "../../api"

import "./StageListingElement.scss"

function StageListingElement({ stageRegistration, displayDetail, callback }) {
    const {
        id,
        status,
        statusValue,
        training,
        kid,
        preregistrationLimitDate,
        healthSurveyStatusValue,
        billId,
        validationStatusValue,
    } = stageRegistration

    const {
        pictureUrl,
        name,
        nameBis,
        type,
        centerId,
        centerName,
        startDate,
        endDate,
    } = training

    const { firstName, lastName } = kid || {}

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = usePath()
    const theme = useTheme()
    const { check, message } = useCanDownload()

    /* STATE */
    const [ isLoading, setIsLoading ] = useState(false)
    const [ uploadingCertificate, setUploadingCertificate ] = useState(false)

    const listAttachment = useRef(null)
    const quitWaitingListPopin = useRef(null)

    const ozmoSubscription = useSelector(state => state.forms.ozmoSubscription)

    const openDetailModal = () => {
        ModalHandler.show(StageListingElementPopIn, {
            stageRegistration: stageRegistration,
        })
    }
    function annuler() {
        finaliser()
    }

    function finaliser() {
        dispatch(
            setFormEntry(
                "ozmoSubscription", {
                    ...ozmoSubscription,
                    firstSubscription: stageRegistration,
                },
            ),
        )

        setIsLoading(false)
        navigate(path("/ordersummary"), {
            state: {
                isPreregistration: true,
                title: "Ma préinscription",
                tunnelType: "TRAINING",
            },
        })
    }

    function uploadMedicalCertif() {
        listAttachment.current.click()
    }

    function quitterFileAttente() {
        setIsLoading(true)
        cancelStageRegistration(id).then(
            (res) => {
                callback(res)
            },
        ).catch((e) => {
            if (e?.response?.data?.data?.Message) {
                quitWaitingListPopin?.current?.hide()
                ModalHandler.show(MessagePopin, {
                    message: e?.response?.data?.data?.Message,
                    title: "Erreur",
                })
            }
        }).finally(() => setIsLoading(false))
    }

    useEffect(
        () => {
            if (displayDetail === true) {
                setTimeout(() => {
                    openDetailModal()
                }, 200)
            } else if (displayDetail === false) {
                ModalHandler.hide(StageListingElementPopIn)
            }
        }, [ stageRegistration ],
    )

    return (
        <ListingRegistrationHolder
            isLoading={isLoading}
            logo={pictureUrl}
            status={
                (<StatusDisplay
                    id={id}
                    status={status}
                    statusValue={statusValue}
                    preRegistrationLimitDate={preregistrationLimitDate}
                    isFamiliarity={theme === "theme-padel"}
                />)
            }

            name={name}
            details={
                (<React.Fragment>
                    {nameBis &&
                        <div className="nameBis">{nameBis}</div>
                    }
                    <div className="type">{type}</div>
                    <div className="centerName">{displayCenterName(centerId ,centerName)}</div>
                    {!nameBis &&
                        (<div className="startenddate">
                            Du {startDate} au {endDate}
                        </div>)
                    }
                    {firstName && <div className="childName">{firstName} {lastName?.toUpperCase()}</div>}
                    {statusValue !== 1 &&
                        (<div 
                            className="detail-btn"
                            onClick={
                                () => {
                                    openPopin(
                                        "listing",
                                        "details",
                                        id,
                                    )
                                    openDetailModal()
                                }
                            }
                        >
                            Voir le détail
                        </div>)
                    }
                </React.Fragment>)
            }
            actions={
                (<>
                    <input
                        // capture="user"
                        type="file"
                        accept="application/pdf, image/jpeg, image/png"
                        className="hidden-file-input"
                        ref={listAttachment} 
                        value={""}
                        onChange={(e) => {
                            const file = e.target.files[0]
                            setUploadingCertificate(true)
                            addStageMedicalCertificate(
                                id, file,
                            ).then(
                                (resultSubscription) => {
                                    if (resultSubscription) {
                                        callback(() => {
                                            ModalHandler.show(MessagePopin, {
                                                message: (
                                                    <div>
                                                        <p>Nous avons bien reçu votre certificat médical.</p>
                                                        <p>Un délai est nécessaire pour le valider.</p>
                                                    </div>
                                                ),
                                                title: "Envoi confirmé",
                                            })
                                        })
                                        setUploadingCertificate(false)
                                    } else {
                                        ModalHandler.show(MessagePopin, {
                                            message: (
                                                <div>
                                                    <p>Un problème technique est survenu lors de l'envoi.</p>
                                                    <p>Veuillez réessayer ultérieurement.</p>
                                                </div>
                                            ),
                                            title: "Echec envoi",
                                        })
                                    }
                                },
                            ).catch(() => setUploadingCertificate(false))
                        }}
                    />
                    {
                        (billId > 0 && statusValue === 3 &&
                            healthSurveyStatusValue !== 1) && kid &&
                                (<ButtonCta
                                    className="buttonCta--mb"
                                    isCta2Alt
                                    disabled={
                                        validationStatusValue === 1 ||
                                            validationStatusValue === 2
                                    }
                                    onClick={uploadMedicalCertif}
                                    text={
                                        !uploadingCertificate ?
                                            getMedicalCertificateButtonText(stageRegistration)
                                            :
                                            (<div>
                                                <Preloader alt={theme === "theme-soccer"}/>
                                            </div>)
                                    }
                                />)

                    }

                    {
                        (billId > 0 
                            && statusValue === 3
                        ) &&
                            (<>
                                <ButtonCta
                                    isCta2Alt
                                    text="Télécharger la facture"
                                    onClick={() => {
                                        check(() =>
                                            displayBill(billId, setIsLoading).then((res) => {
                                                if (res) {
                                                    ModalHandler.show(MessagePopin, {
                                                        message: res,
                                                        title: "Erreur",
                                                    })
                                                }
                                            }))
                                    }}
                                />
                            </>)
                    }

                    {
                        (statusValue === 1) &&
                            (<ButtonCta
                                isCta2Alt
                                text="Quitter la file d'attente"
                                onClick={() => quitWaitingListPopin?.current?.show()}
                            />)
                    }

                    {
                        (statusValue === 2) &&
                            (<ButtonCta
                                className="buttonCta--mb"
                                isAlt
                                text="Finaliser ma pré-inscription"
                                onClick={() => {
                                    openPopin("listing", "finaliserOuAnnulerPreInscription", id)
                                    finaliser()
                                }}
                            />)
                    }       

                    {
                        (statusValue === 2) &&
                            (<ButtonCta
                                isAlt
                                text="Annuler ma pré-inscription"
                                onClick={() => {
                                    openPopin("listing", "finaliserOuAnnulerPreInscription", id)
                                    annuler()
                                }}
                            />)
                    }
                    {message}
                    {statusValue === 1 && <QuitWaitingListPopin ref={quitWaitingListPopin} confirmCancel={quitterFileAttente} title="Quitter la file d'attente" isLoading={isLoading} />}
                </>)
            }
        />
    )
}
StageListingElement.propTypes = {
    callback: func,
    displayDetail: bool,
    stageRegistration: object,
}

export default StageListingElement
