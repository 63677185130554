import classNames from "classnames"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { NavLink, useLocation, useMatch } from "react-router-dom"

import MenuLvl2 from "./menuLvl2/menuLvl2"
import usePath from "../../../routes/services/usePath"
import { removeAccent } from "../../../services/routingManagment.js"
import { clearAllForms } from "../../../store/forms/actions"
import Notifications from "../../Icons/Notifications/Notifications"

import "./menuItem.scss"

// eslint-disable-next-line react/prop-types
function Icon({ icon }) {
    return icon !== "userProfile.svg" ? (
        <img className="icon" src={icon} alt="" />
    ) : (
        <div className="icon icon-profile">
            <svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.5 0C5.175 0 0 5.177 0 11.5 0 17.826 5.175 23 11.5 23S23 17.826 23 11.5C23 5.177 17.824 0 11.5 0m0 4.262c1.955 0 3.45 1.495 3.45 3.45s-1.495 3.45-3.45 3.45-3.45-1.495-3.45-3.45 1.495-3.45 3.45-3.45m0 14.707c-2.875 0-5.405-1.494-6.9-3.68 0-2.3 4.6-3.565 6.9-3.565 2.3 0 6.9 1.265 6.9 3.565-1.495 2.186-4.025 3.68-6.9 3.68" />
            </svg>
        </div>
    )
}

function MenuItem({
    name,
    customCls = "",
    icon,
    hasNotif,
    id,
    childrenList,
    promoLevel2,
    appRoute,
    level1,
    externalLink,
}) {
    const path = usePath()
    const dispatch = useDispatch()
    const location = useLocation()
    const isSelectedLvl1Item = Boolean(useMatch(path("/") + removeAccent(level1) + "/*"))

    const itemClass = classNames("menuItem", customCls, {
        "menuItemWithChildren": childrenList,
        "selectedItem": isSelectedLvl1Item,
    })

    function handleClick() {
        if ((appRoute === "/reserver" || appRoute === "/padel/reserver") && !location.pathname.includes("/reserver")) {
            dispatch(clearAllForms())
        }
    }

    const ItemContent = (
        <>
            {hasNotif && <Notifications hasChildActive={isSelectedLvl1Item} />}
            {icon && <Icon icon={icon} />}
            <div className={classNames("name", { "nameWithIcon": icon })}>{name}</div>
            {childrenList && (
                <MenuLvl2
                    parentId={id || ""}
                    childrenList={childrenList}
                    promoLevel2={promoLevel2}
                    level1={level1}
                />
            )}
        </>
    )

    return externalLink ? (
        <a
            id={id}
            href={externalLink}
            className={itemClass}
            target="_blank"
            rel="noopener noreferrer"
        >
            {ItemContent}
        </a>
    ) : appRoute ? (
        <NavLink
            id={id}
            to={appRoute}
            className={({ isActive }) => `${itemClass}${isActive ? " active" : ""}`}
            onClick={handleClick}
        >
            {ItemContent}
        </NavLink>
    ) : (
        <div id={id} className={itemClass}>{ItemContent}</div>
    )
}

MenuItem.propTypes = {
    appRoute: PropTypes.string,
    childrenList: PropTypes.array,
    customCls: PropTypes.string,
    externalLink: PropTypes.string,
    hasNotif: PropTypes.bool,
    icon: PropTypes.string,
    id: PropTypes.string,
    level1: PropTypes.string,
    name: PropTypes.string,
    promoLevel2: PropTypes.object,
}

export default MenuItem
