import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { components } from "react-select"

export const CustomOption = props => {
    const { children, isSelected, isDisabled, isFocused } = props

    const pRef = React.useRef(null)

    return (
        <components.Option
            {...props}
            className={classNames("select-input-option", {
                isDisabled,
                isFocused,
                isSelected,
            })}
        >
            <p
                ref={pRef}
            >
                {children}
            </p>
            {isSelected && (
                <div className="text-primary">
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.34812 11.8368L1.6041 7.17586L0 8.75186L6.34812 15L20 1.58718L18.3959 0L6.34812 11.8368Z"
                        />
                    </svg>
                </div>
            )}
        </components.Option>
    )
}

CustomOption.propTypes = {
    children: PropTypes.node,
    isDisabled: PropTypes.bool,
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
}
