import dayjs from "dayjs"
import { string } from "prop-types"
import { useMemo } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getCupList } from "./api.js"
import CupListingElement from "./components/CupListingElement/CupListingElement.jsx"
import Listing from "../../../components/listing/Listing.jsx"
import EffectifPopIn from "../../../components/popin/invitations/EffectifPopIn.jsx"
import { usePaginatedListing } from "../../../hooks/usePaginatedListing.js"
import useThemeFeatures from "../../../hooks/useThemeFeatures.js"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import { clearAllForms, setFormEntry } from "../../../store/forms/actions.js"

function ListingUrbanCup({ onglet }) {
    const themeFeatures = useThemeFeatures()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const periodStart = dayjs().subtract(3, "year").format("YYYY-MM-DD")

    const showModal = () => {
        ModalHandler.show(EffectifPopIn, {
            callback: () => getItems(true),
            tunnelType: "CUP",
        }, { keepPreviousProps: true, removeOnHide: false }).then(
            () => {
                dispatch(
                    clearAllForms(),
                )
            },
        )
    }

    const {
        disableActions,
        displayToIndex,
        getItems,
        isLoading,
        listItems,
        loadMore,
        totalElements,
    } = usePaginatedListing(
        getCupList,
        {
            orderByAsc: false,
            periodStart: periodStart,
            status: "[2,1,3]",
        },
        (res) => {
            dispatch(
                setFormEntry(
                    "RegistrationCount",
                    res.count || res.length,
                ),
            )
            if (res.count === 0) {
                goToTunnel(true)
            } else {
                if (onglet === "new") {
                    goToTunnel(false)
                }
            }
        },
    )

    const goToTunnel = (noReturn = false) => {
        dispatch(
            clearAllForms(),
        )
        navigate(
            themeFeatures([ "/cup/new", "/padel/tournois/new" ]),
            {
                state: {
                    denyReturn: noReturn,
                },
            },
        )
    }

    const content = useMemo(() => {
        return listItems.map((item, index) => {
            if (index > displayToIndex) { return }

            return (
                <CupListingElement
                    key={index}
                    callback={getItems}
                    cupRegistration={item}
                    effectifCallback={
                        () => {
                            dispatch(
                                setFormEntry(
                                    "TournamentRegistration",
                                    item,
                                ),
                            )
                            showModal()
                        }
                    }
                />
            )
        })
    }, [ listItems, displayToIndex ])

    return (
        <Listing
            title="Mes tournois"
            newButtonText="Nouvelle inscription"
            type='CUP'
            loadMore={loadMore}
            itemsData={content}
            isLoading={isLoading}
            goToTunnel={() => goToTunnel()}
            disableLoadMore={disableActions}
            canShowMore={displayToIndex + 1 < totalElements}
        />
    )
}

ListingUrbanCup.propTypes = {
    onglet: string,
}

export default ListingUrbanCup
