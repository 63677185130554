/* eslint-disable sort-keys-fix/sort-keys-fix */
export const businessLeagueTunnelLabels = {
    general: {
        billingAdress: "Adresse de facturation",
        blazon: "Logo de l'équipe",
        centerLabel: "Centre",
        company: "Nom de l'entreprise",
        teamName: "Nom de l'équipe",
    },
    captain: "Capitaine",
    com: "Responsable communication interne",
    preferences: {
        preferredDays: "Jours de match préférés",
        preferredHours: "Créneaux horaires préférés",
        comment: "Commentaires",
    },
}

export const businessLeagueTunnelSubmissionStrategies = {
    preferredDays: (value) => value?.map(v => v?.label).join(", "),
    preferredHours: (value) => value?.map(v => v?.label).join(", "),
}

const formatNestedObject = (data, labels) => {
    return Object.keys(data).map(key => {
        const value = data[key]
        const label = labels[key]

        if (!label) {
            return null
        }

        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            return formatNestedObject(value, label)
        }
        const formattedValue = businessLeagueTunnelSubmissionStrategies[key]?.(value) || value
        return `${label}: ${formattedValue}`
    }).filter(Boolean).join("\n")
}

export const formatSubmission = (data) => {
    return Object.keys(businessLeagueTunnelLabels).map(key => {
        const value = data[key]
        if (value === undefined) {return null}
        const formattedValue = value
        const labelObject = businessLeagueTunnelLabels[key]

        if (typeof labelObject === "object" && value !== null && !Array.isArray(value)) {
            return formatNestedObject(value, businessLeagueTunnelLabels[key])
        }

        if (typeof formattedValue === "object" && formattedValue !== null) {
            return `${businessLeagueTunnelLabels[key]}: ${Object.values(formattedValue).join(" - ")}`
        }
        return `${businessLeagueTunnelLabels[key]}: ${formattedValue}`
    }).filter(Boolean).join("\n")
}

export function validateLeague(league) {
    const businessLeagueRegistrationsCheckSteps = [
        { label: "Devis signé", key: "signedQuote" },
        { label: "Formulaire d’information complété", key: "completedForm" },
        { label: "Commande maillot passée", key: "pastShirtOrders" },
        { label: "Facture réglée", key: "billStatus", customValidation: (value) => value === 20 },
    ]

    return businessLeagueRegistrationsCheckSteps.map(step => ({
        label: step.label,
        disabled: !(step.customValidation ? step.customValidation(league[step.key]) : !!league[step.key]),
    }))
}
