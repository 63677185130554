import { LocationPaths } from "./ApplicationPaths/locationPaths.js"
import { getLocation } from "../globalAPI/api.js"
import LyraEmbedPayment from "../pages/payments/LyraEmbedPayment/LyraEmbedPayment.jsx"
import { NewBookingOrderSummary } from "../pages/payments/OrderSummary/NewBookingOrderSummary.jsx"
import { LocationLayout } from "../pages/reservation/infoReservation/components/HOC/LocationLayout.jsx"
import LocationCompositionManagement
    from "../pages/reservation/infoReservation/LocationCompositionManagement/LocationCompositionManagement.jsx"
import InfoTab from "../pages/reservation/infoReservation/LocationInformations/InfoTab.jsx"
import LocationPaymentsManagement
    from "../pages/reservation/infoReservation/LocationPaymentsManagement/LocationPaymentsManagement.jsx"
import LocationPlayersManagement
    from "../pages/reservation/infoReservation/LocationPlayersManagement/LocationPlayersManagement.jsx"
import LocationVideos from "../pages/reservation/infoReservation/LocationVideos/LocationVideos.jsx"
import ListingReservation from "../pages/reservation/listingReservation/listingReservation.jsx"
import { getPreBook } from "../pages/reservation/tunnelReservation/api.js"
import { BookingPage } from "../pages/reservation/tunnelReservation/NewBookingTunnel/BookingPage.jsx"
import CandidatureSupersub from "../pages/supersub/candidatureSupersub/candidatureSupersub.jsx"
import SearchSuperSub from "../pages/supersub/searchSuperSub/SearchSuperSub.jsx"
import SupersubRating from "../pages/supersub/supersubRating/supersubRating.jsx"

const generateRoutesReservations = (theme = "") => {
    const basePath = theme ? `/${theme}` : ""
    return [
        {
            element: (<ListingReservation/>),
            path: `${basePath}${LocationPaths.listingReservations}`,
        },
        {
            children: [
                {
                    element: (<LocationCompositionManagement/>),
                    path: LocationPaths.reservationComposition,
                },
                {
                    element: (<LocationPlayersManagement/>),
                    path: LocationPaths.reservationPlayers,
                },
                {
                    element: (<InfoTab/>),
                    path: LocationPaths.reservationInfos,
                },
                {
                    element: (<LocationPaymentsManagement/>),
                    path: LocationPaths.reservationPayments,
                },
                {
                    element: (<LocationVideos/>),
                    path: LocationPaths.reservationVideos,
                },
            ],
            element: (<LocationLayout/>),
            path: `${basePath}${LocationPaths.reservationBase}`,
        },
        {
            element: (<SearchSuperSub />),
            path: `${basePath}${LocationPaths.searchSuperSub}`,
        },
        {
            element: (<CandidatureSupersub />),
            path: `${basePath}${LocationPaths.candidatureSuperSub}`,
        },
        {
            element: (<CandidatureSupersub />),
            path: `${basePath}${LocationPaths.candidatureSuperSubAvenir}`,
        },
        {
            element: (<SupersubRating />),
            path: `${basePath}${LocationPaths.superSubRating}`,
        },
        {
            element: (<SupersubRating />),
            path: `${basePath}${LocationPaths.superSubRatingPassees}`,
        },
        {
            element: (<BookingPage type={theme === "padel" ? 2 : 1} />),
            path: `${basePath}${LocationPaths.bookingPage}`,
        },
        {
            element: (
                <LyraEmbedPayment
                    title={"3/ PAIEMENT"}
                    tunnelType={"BOOKING"}
                    getPayment={(params) => getPreBook(params.preBookingId).then(res => {
                        return res?.preReservation
                    })}
                />
            ),
            path: `${basePath}${LocationPaths.lyraEmbedPayment}`,
        },
        {
            element: (
                <LyraEmbedPayment
                    title={"3/ PAIEMENT"}
                    tunnelType={"BOOKING"}
                    getPayment={(params) => {
                        return getLocation(params.locationId)
                    }}
                />
            ),
            path: `${basePath}${LocationPaths.lyraEmbedLocationPaiement}`,
        },
        {
            element: (<NewBookingOrderSummary/>),
            path: `${basePath}${LocationPaths.newBookingOrderSummary}`,
        },
    ]
}

export default generateRoutesReservations
