const validateZipCode = (zipCode) => /^([0-9]{5})$/g.test(zipCode)

const validateEmail = (email) => /\S+@\S+\.\S+/.test(email)

const validatePhone = (phone) => /^(\+33)[0-9 ]{14}/.test(phone)

const validatePhoneWithoutSpace = (phone) => /^((\+)33|0)[1-9](\d{2}){4}$/.test(phone)

export {
    validateZipCode,
    validateEmail,
    validatePhone,
    validatePhoneWithoutSpace,
}
