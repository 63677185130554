/* eslint-disable no-irregular-whitespace */
import StepComponent from "./StepComponent"

function SuperSubPlayerInfo() {

    return (
        <>
            
            <div className="c-row justify-center">
                <div className="c-col c-col--10">
                    <p>
                        Tu souhaites jouer au foot sans contraintes ?<br/>
                        <br/>
                        Rejoins un match dans le centre UrbanSoccer le plus proche de chez toi.
                    </p>
                </div>
            </div>
            <div>
                <StepComponent
                    numero={1}
                    icon={"magnifying-glass.svg"}
                    image={"FindAMatch.png"}
                    title={"Trouver un Match"}
                    text={(
                        <>
                            Consulte les matchs à la recherche d'un SuperSub lors des 5 prochains jours.<br/>
                            <br/>
                            Pour cela, rends-toi dans le menu SuperSub &gt; Trouver un match.
                        </>
                    )}
                />
                <StepComponent
                    numero={2}
                    icon={"form.svg"}
                    image={"Informations.png"}
                    title={"Sélectionner le match qui te convient"}
                    text={(
                        <>
                            Postule pour rejoindre un match et attends une réponse de l'organisateur.<br/>
                            <br/>
                            Tant que ta participation n'est pas validée, il reste possible de l'annuler.
                        </>
                    )}
                />
                <StepComponent
                    numero={3}
                    icon={"gift.svg"}
                    image={"gift-screenshot.png"}
                    title={"Paie ta place"}
                    text={(
                        <>
                            Ton paiement sera débité seulement si l'organisateur accepte ta demande et si le match a bien lieu.<br/>
                            <br/>
                            <strong>L'organisateur peut choisir de  t'offrir la place.</strong>
                        </>
                    )}
                />
                <StepComponent
                    numero={4}
                    icon={"gift.svg"}
                    image={"candidatures.png"}
                    title={"Suivre tes candidatures"}
                    text={(
                        <>
                            Depuis le menu SuperSub &gt; Mes demandes.<br/>
                            <br/>
                            <strong>
                                Attention : Une fois ta demande validée, tu ne peux plus annuler ta participation.
                            </strong>
                        </>
                    )}
                />
                <StepComponent
                    numero={5}
                    icon="star.svg"
                    image={"rate-match.png"}
                    title={"Noter le Match"}
                    text={
                        "Une fois le match joué, retourne dans tes demandes, onglet « joués » et note ton expérience sur ce match, l’organisateur en fera de même."
                    }
                />
            </div>
        </>
    )
}

export default SuperSubPlayerInfo
