import classNames from "classnames"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import Preloader from "../../../../../components/loaders/preloader/preloader.jsx"
import { TooltipLight } from "../../../../../components/tooltip/tooltipLight/tooltipLight.jsx"
import { getUser } from "../../../../../globalAPI/api.js"
import useMediaPath from "../../../../../hooks/useMediaPath"
import { editFavoriteSlot } from "../../../../profil/FavoriteSlots/api.js"

import "./FavoriteSlot.scss"

export const FavoriteSlot = (props) => {
    const day = dayjs().day(props.slot.day).format("dddd")
    const mediaPath = useMediaPath()
    const navigate = useNavigate()
    const [ isLoading, setIsLoading ] = useState(false)

    const handleSlotRedirection = () => {
        navigate(props.url, {
            state: {
                fromDashboard: true,
            },
        })
    }

    const toggleSlotNotifications = () => {
        if (!props.slot.notificationSource.length) {return}
        setIsLoading(true)
        editFavoriteSlot(props.slot.id, {
            ...props.slot,
            notificationSource: JSON.stringify(props.slot.notificationSource),
            resourceTypes: JSON.stringify(props.slot.resourceTypes),
            status: props.slot.status === 0 ? 1 : 0,
        }).then(() => {
            getUser(true).finally(() => {
                setIsLoading(false)
            })
        })
    }

    return (
        <div className={"favorite-slot-wrapper"}>
            <span className={"favorite-slot-time"}>
                {day}&nbsp;
                <span>
                    {props.slot.start}h - {(props.slot.start + 2) < 24 ? props.slot.end + "h" : "00h"}
                </span>
            </span>
            <div className={"favorite-slot-details"}>
                <TooltipLight text={props.slot.notificationSource.length ? (props.slot.status === 0 ? "Désactiver les" +
                    " notifications" : "Activer les notifications") : null} position={"top"} dark centered>
                    <div className={classNames("favorite-slot-notification", {
                        active: props.notification,
                        disabled: !props.slot.notificationSource.length,
                        loading: isLoading,
                    })}>
                        {isLoading ? <Preloader small cls={"favorite-slot-loader"} alt/> : (
                            <img
                                onClick={toggleSlotNotifications}
                                alt={"Notification state"}
                                src={(props.notification &&  props.slot.notificationSource.length ?
                                    "/assets/icons/favoriteSlot/bell-check.svg" :
                                    mediaPath([ "/assets/icons/favoriteSlot/bell-cancel.svg",
                                        "/assets/icons/favoriteSlot/padel/bell-cancel.svg" ]))}
                            />
                        )}
                    </div>
                </TooltipLight>
                <a className={"favorite-slot-action"} onClick={handleSlotRedirection}>
                    Réserver
                    <img alt={"Arrow"}
                        src={mediaPath([ "/assets/icons/favoriteSlot/large-booking-arrow-right.svg", "/assets/icons/favoriteSlot/padel/large-booking-arrow-right.svg" ])}/>
                </a>
            </div>

        </div>
    )
}

FavoriteSlot.propTypes = {
    notification: PropTypes.bool.isRequired,
    slot: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
}
