
class UserAgentHandler
{
    static isAndroid() {
        return null !== navigator.userAgent.match(/Android/i)
    }

    static isBlackBerry() {
        return null !== navigator.userAgent.match(/BlackBerry/i)
    }

    static isIOS() {
        return null !== navigator.userAgent.match(/iPhone|iPad|iPod/i)
    }

    static isSafari() {
        return navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1
    }

    static isOpera() {
        return null !== navigator.userAgent.match(/Opera Mini/i)
    }
    
    static isWindows() {
        return null !== navigator.userAgent.match(/IEMobile/i)
    }

    static isFirefox() {
        return null !== navigator.userAgent.match(/Firefox/i)
    }

    // static isAny() {
    //   return null !==  (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    // }
}

export default UserAgentHandler
