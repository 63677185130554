
import { useEffect, useState } from "react"

import GeneralRanking from "../../../components/league/GeneralRanking/GeneralRanking.jsx"
import ScorerRanking from "../../../components/league/ScorerRanking/ScorerRanking.jsx"
import Preloader from "../../../components/loaders/preloader/preloader.jsx"
import { getTeamsRanking, hasTeamChanged } from "../service/service.js"
import { useLeagueStore } from "../store/useLeagueStore.js"

import "./LeagueRanking.scss"

export const LeagueRanking = () => {
    const [ isLoading, setIsLoading ] = useState(false)

    const { selectedLeagueTeam, selectedTeamData, setSelectedTeamData } = useLeagueStore()

    useEffect(() => {
        if (!! selectedLeagueTeam?.id && !! selectedLeagueTeam?.leagueId) {
            fetchLeagueRanking()
        }
    }, [ selectedLeagueTeam?.id, selectedLeagueTeam?.leagueId ])

    const fetchLeagueRanking = async () => {
        if (
            selectedLeagueTeam?.id
            && hasTeamChanged(selectedLeagueTeam.leagueId, selectedLeagueTeam.id, selectedTeamData)
            || !Object.keys(selectedTeamData?.ranking ?? {})?.length
        ) {
            setIsLoading(true)
            const ranking  = await getTeamsRanking(selectedLeagueTeam?.leagueId)
            setSelectedTeamData(ranking)
            setIsLoading(false)
        }
    }

    return (
        <div className={"league-ranking"}>
            <GeneralRanking loading={isLoading} data={selectedTeamData?.ranking} highlightedTeamId={selectedLeagueTeam.id}/>
            {isLoading && <Preloader /> }
            <ScorerRanking loading={isLoading} data={selectedTeamData?.scorers}/>
        </div>
    )
}
