import { object } from "prop-types"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import MenuItem from "./menuItem/menuItem"
import { readMenuItemsBlocPromo } from "../../services/menu/api"
import Logo from "../Logo/Logo"
import SwitchEnv from "../QuickActions/SwitchEnv/SwitchEnv"
import { useTheme } from "../ThemeContext/ThemeContext"

import "./menu.scss"

function Menu({ menuLevel1, menuLevel2 }) {
    const hasArdoises = useSelector(state => state.hasArdoises)
    const nextBooking = useSelector(state => state.nextBooking)
    const hasUnpaidBills = useSelector(state => state.hasUnpaidBills)
    const [ promoLevel2, setPromoLevel2 ] = useState(null)
    const theme = useTheme()

    useEffect(() => {
        readMenuItemsBlocPromo(theme).then(
            (res) => {
                const menuItems = res
                const allPromos = {}
                if (menuItems) {
                    menuItems.forEach(
                        (item) => {
                            if (item.status) {
                                allPromos[item.slug] = { link: item.url, newTab: item.newTab, srcImage: item.image }
                            }
                        },
                    )
                }

                setPromoLevel2(allPromos)
            },
        )
    }, [ theme ])

    return (
        <div className='menu'>
            <div className="menu-desktop-level1">
                <div className="logo-part">
                    <div className="logo-part-logo">
                        <Logo />
                    </div>

                    {/* {!isWebview && ( */}
                    <div className="logo-part-switchEnv">
                        <SwitchEnv />
                    </div>
                    {/* )} */}
                </div>

                <div className="menu-item-part">
                    <div className="main-part">
                        {menuLevel1 && (
                            Object.entries(menuLevel1).filter(
                                ([ , info ]) => info.desktopSection === "main-part" && !info.hidden,
                            ).map(
                                ([ name, infos ], idx) => {
                                    return (
                                        <MenuItem
                                            id={"menuItem-" + idx}
                                            name={infos.label}
                                            customCls={infos.customCls}
                                            hasNotif={name === "reservations" && !!nextBooking}
                                            childrenList={menuLevel2[name]}
                                            level1={name}
                                            appRoute={infos.appRoute}
                                            externalLink={infos.externalLink}
                                            key={"arrlvl1End-" + name}
                                            icon={infos.icon}
                                            promoLevel2={promoLevel2?.[infos.slug]}
                                        />
                                    )
                                },
                            )
                        )}
                    </div>

                    <div className="other-part">
                        {menuLevel1 && (
                            Object.entries(menuLevel1).filter(
                                ([ ,info ]) => info.desktopSection === "other-part" && !info.hidden,
                            ).map(
                                ([ name, infos ], idx) => {
                                    return (
                                        <MenuItem
                                            id={"menuItem-" + idx}
                                            name={infos.label}
                                            customCls={infos.customCls}
                                            hasNotif={!!(name === "profil" && (hasArdoises || hasUnpaidBills))}
                                            childrenList={menuLevel2[name]}
                                            externalLink={infos.externalLink}
                                            level1={name}
                                            appRoute={infos.appRoute}
                                            key={"arrlvl1EndOther-" + name}
                                            icon={infos.icon}
                                            promoLevel2={promoLevel2?.[infos.label]}
                                        />
                                    )
                                },
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )

}
Menu.propTypes = {
    menuLevel1: object,
    menuLevel2: object,
}

export default Menu
