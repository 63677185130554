import { generatePayzenPaymentReqs } from "../../globalAPI/api"
import { generatePayzenBirthdayPaymentReqs } from "../../pages/kids/birthdayReservation/api"
import { generatePayzenSchoolPaymentReqs } from "../../pages/kids/ecoleDeFoot/api"
import { generatePayzenStagePaymentReqs } from "../../pages/kids/stageDeFoot/api"
import { generatePayzenLeaguePaymentReqs } from "../../pages/league/api/api.js"
import { generatePayzenCupPaymentReqs } from "../../pages/teams/urbanCup/api"

function usePayzenPaymentRequest(tunnelType) {
    switch (tunnelType) {
    case "BIRTHDAY":
        return generatePayzenBirthdayPaymentReqs

    case "SCHOOL":
        return generatePayzenSchoolPaymentReqs

    case "CUP":
        return generatePayzenCupPaymentReqs

    case "TRAINING":
        return generatePayzenStagePaymentReqs

    case "LEAGUE":
        return generatePayzenLeaguePaymentReqs

    case "SUPERSUB":
        return () => {}

    case "BOOKING":
        return generatePayzenPaymentReqs

    case "DEBT":
        return generatePayzenPaymentReqs

    case "BILL":
        return generatePayzenPaymentReqs

    default:
        // eslint-disable-next-line no-console
        console.error("usePayzenPaymentRequest - tunnelType not implemented : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        return () => {}
    }
}

export default usePayzenPaymentRequest
