import { number, object } from "prop-types"
import { useEffect } from "react"

import { resetNativeHistoryToCurrentLocation } from "../../utilities/helpers.js"
import TeamSteps from "../teams/steps/TeamSteps.jsx"

export function LeagueTunnel() {
    const tunnelType = "LEAGUE"

    useEffect(() => {
        resetNativeHistoryToCurrentLocation(window.location.href)
    }, [])

    return <TeamSteps tunnelType={tunnelType}/>
}

LeagueTunnel.propTypes = {
    initialStep: number,
    leagueSubId: number,
    params: object,
}

export default LeagueTunnel
