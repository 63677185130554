import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { sendPasswordEmail } from "../../../../globalAPI/api"
import { editUserSessionMeta, openConfirmationOverlay, toggleAuthenticationOverlay } from "../../../../store/actions/content/index"
import _OverlayContentComponent from "../_OverlayContentComponent"

class authenticationExceptionContent extends _OverlayContentComponent
{

    constructor(props) {
        super(props)
        this.state = {}
    }

    getName() {
        return "authentication-exception"
    }

    preRenderDesktopContent() {
        return this.preRenderContent()
    }

    preRenderContent()
    {
        return (
            <div className="align-flex authentication-exception-div">
                <div className="text-center">
                    <img className="picto" src="/assets/images/Infos-O.svg" />
                    <div className="row white msg-row">{this.props.message}</div>

                    {
                        this.props.displayBlockInfoReceiveMailDuration && 
            
                            (<div className="text-mail-warning">
                                <span className="warning-label-text-mail">Attention : </span>
                                <span>
                                    La reception de l'email peut prendre quelques minutes, pendant ce temps, ne pas tenter de te connecter ni demander l'envoi d'un nouvel email.
                                </span>
                            </div>)
                    }

                    <button className="button-orange" onClick={()=>{
                        this.props.toggleLevel3Overlay(false,"",{},true)
                    }}>J'AI COMPRIS</button>

                    {
                        this.props.displayBlockGetNewConfirmationMail && 
                            (<div className="block-get-new-confirm-mail">
                                <div className="text-mail-confirm">
                                    Si tu n'as pas reçu d'email après plusieurs minutes d'attente ou si le lien de validation a expiré, clique ci-dessous pour recevoir un nouvel email de validation
                                </div>
                                <div className="btn-get-new-mail" onClick={()=>{
                                    if (this.props.userEmail) {
                                        sendPasswordEmail(this.props.userEmail).then(() => {
                                            toggleAuthenticationOverlay(true, { failedAuthenticationMessage: "Mail envoyé, vérifie ton adresse mail pour confirmer ton inscription avant de pouvoir te connecter" })
                                        })
                                        this.props.toggleLevel3Overlay(false,"",{},true)
                                    }
                                }}>Recevoir un nouvel email</div>
                            </div>)
                    }

                </div>
            </div>
        )
    }
}

// CONNECT THE STORE (DATA) TO THE COMPONENT
const stateToProps = (state) => {
    return {
        ..._OverlayContentComponent.stateToProps(state),
        ...{
            ajaxCalling: state.ajaxCalling,
        },
    }
}

// BIND ACTIONS TO CONTAINER
const dispatchToProps = (dispatch) => {
    let properActions = {
        editUserSessionMeta: editUserSessionMeta,
        openConfirmationOverlay: openConfirmationOverlay,
    }
    let mergedActions = {
        ... properActions,
        ... _OverlayContentComponent.dispatchToProps(),
    }
    return bindActionCreators(
        mergedActions
        ,
        dispatch,
    )
}

export default connect(stateToProps, dispatchToProps)(authenticationExceptionContent)
