import {
    editLeagueSubscription,
} from "../../league/api/api.js"
import {
    postCupRegistration,
} from "../urbanCup/api"

export default function useUpdateRegistration(tunnelType) {
    switch (tunnelType) {
    case "CUP":
        return postCupRegistration
    case "LEAGUE":
        return editLeagueSubscription
    default:
        // eslint-disable-next-line no-console
        console.error("useUpdateRegistration - Not implemented tunneltype : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        return () => {}
    }
}
