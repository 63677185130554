import React from "react"

import { allContents } from "../contents/allContents.jsx"

class ContentChanger
{
    static load(targetIdentifier, props) {
        let contents = allContents()
        let properties = { id: targetIdentifier }
        if (props !== undefined) {
            if (Object.keys(props).length > 0) {
                properties = {
                    ...props,
                    ...properties,
                }
            }
        }
        if (!contents[targetIdentifier]) {
            throw new Error(targetIdentifier + " NOT FOUND IN CONTENTS")
        }
        // authorize to pass dynamic Prop "id" to the component
        let content = React.cloneElement(
            contents[targetIdentifier],
            properties,
        )
        return content
    }
}

export default ContentChanger
