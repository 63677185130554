import classNames from "classnames"
import { bool } from "prop-types"
import { useEffect, useMemo, useRef, useState } from "react"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import FoldableSection from "../../../../components/layout/FoldableSection/FoldableSection"
import Preloader from "../../../../components/loaders/preloader/preloader"
import Modal from "../../../../components/Modal/Modal"
import PlayerLine from "../../../../components/reservation/PlayerLine/PlayerLine"
import useLocationVideos from "../../../../hooks/reservation/location/useLocationVideos"
import useUrbanLanguage from "../../../../hooks/urbanLanguage/useUrbanLanguage"
import UserAgentHandler from "../../../../services/UserAgentHandler"
import "swiper/css"
import { useLocationStore } from "../../store/store.js"

import "./LocationVideos.scss"

function LocationVideos() {
    const { currentLocation: location } = useLocationStore()
    const { invites } = location
    const { loadingVideos, videos } = useLocationVideos(location) // { loadingVideos: false, };
    const { main, goals } = videos ? videos : {} // videosDataMock
    const { angles } = main ? main : {}
    const { tu } = useUrbanLanguage()

    const [ selectedPlayer, setSelectedPlayer ] = useState(null)
    const [ isLoadingVideo, setIsLoadingVideo ] = useState(false)
    const [ thumb, setThumb ] = useState("")
    const [ isVideoPlayerPopInOpen, setIsVideoPlayerPopInOpen ] = useState(false)

    const goalsByTeamsByPlayers = useMemo(() => {
        if (!goals) {return {}}

        let score1 = 0
        let score2 = 0
        const initialTeamsByPlayers = goals.reduce((acc, goal) => {
            goal.details.teamId === 1 ? score1++ : score2++
            goal.details.score = `${score1}-${score2}`

            if (!acc[goal.details.teamId]) {
                acc[goal.details.teamId] = {}
            }
            if (!acc[goal.details.teamId][goal.details.playerId]) {
                acc[goal.details.teamId][goal.details.playerId] = []
            }

            acc[goal.details.teamId][goal.details.playerId].push(goal)

            return acc
        }, {})

        return Object.entries(initialTeamsByPlayers).reduce((acc, [ teamId, players ]) => {
            // eslint-disable-next-line no-unused-vars
            const sortedPlayers = Object.entries(players).sort(([ player1, goals1 ], [ player2, goals2 ]) => {
                if (goals1.length === goals2.length) {
                    return goals1[0].playerName.localeCompare(goals2[0].playerName)
                }
                return goals2.length - goals1.length
            })

            acc[teamId] = Object.fromEntries(sortedPlayers)
            return acc
        }, {})
    }, [ goals ])

    const [ videoSrc, setVideoSrc ] = useState("")
    const [ anglesUrls, setAnglesUrls ] = useState([])

    const videosWrapper = useRef(null)

    useEffect(
        () => {
            if (videosWrapper.current) {
                const videos = videosWrapper.current.querySelectorAll("video")
                let videosLoaded = true
                videos.forEach(
                    video => {
                        if (video.readyState !== 4) {
                            videosLoaded = false
                        }
                    },
                )

                if (!videosLoaded) {
                    setIsLoadingVideo(true)
                }
            }
        }, [ videoSrc, anglesUrls ],
    )

    function endLoading() {
        setIsLoadingVideo(false)
    }

    return (
        <div className="locationVideos">
            {(loadingVideos) ?
                <Preloader/>
                : (
                    <>
                        {(!main && !goals) && (
                            <div className="noVideo">
                                Aucune vidéo n'est présente pour le match sélectionné.
                                <br /> S'il s'agit d'une erreur, prends contact avec ton centre.
                            </div>
                        )}
                        {main && <h2>Le match</h2>}
                        <section className="section--angles">
                            {angles && Object.keys(angles).map(
                                (key) => {
                                    const angle = angles[key]
                                    return (

                                        <figure
                                            className="angle"
                                            onClick={
                                                () => {
                                                    setVideoSrc(angle.url)
                                                    setAnglesUrls([])
                                                    setThumb(angle.miniatureUrl)
                                                    setIsVideoPlayerPopInOpen(true)
                                                }
                                            }
                                            key={"Angle" + key}
                                        >
                                            <img  alt="" src={angle.miniatureUrl}/>
                                            <figcaption>Angle n°{key}</figcaption>
                                        </figure>
                                    )
                                },
                            )}
                        </section>
                        {goals && <h2 className="goalsTitle">{tu("booking.videos.goals")}</h2>}
                        {goalsByTeamsByPlayers && Object.keys(goalsByTeamsByPlayers).map(
                            (teamId, index) => {
                                return (
                                    <section key={teamId} className={"goalsTeam-" + index}>
                                        <h3>{location["team" + teamId + "Name"]}</h3>
                                        <div className="collectionOfFoldables">
                                            { Object.values(goalsByTeamsByPlayers[teamId]).map(
                                                (values) => {

                                                    const currentPlayer = invites.find(
                                                        i => i.playerId === values[0].details.playerId,
                                                    )

                                                    return (
                                                        <FoldableSection
                                                            cls={classNames("playerGoals", {
                                                                isOpen: values[0].details.contactId === selectedPlayer,
                                                            })}
                                                            summary={(
                                                                <PlayerLine
                                                                    leftPart={(
                                                                        <>
                                                                            <img
                                                                                alt=""
                                                                                src={
                                                                                    currentPlayer ?
                                                                                        currentPlayer.pictureUrl ?
                                                                                            currentPlayer.pictureUrl
                                                                                            :
                                                                                            "/assets/images/empty-avatar.svg"
                                                                                        :
                                                                                        "/assets/images/empty-avatar.svg"
                                                                                }
                                                                            />
                                                                            <span className="name">
                                                                                {values[0].playerName + "   "}
                                                                                {"(" + values.length + ")"}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                />
                                                            )}
                                                            detailsContent={(
                                                                <div className="goalsHolder">
                                                                    {values.map(
                                                                        (goal, idx) => (
                                                                            <figure
                                                                                className="goal"
                                                                                onClick={
                                                                                    () => {
                                                                                        const aurls = Object.values(goal.angles).map(
                                                                                            a => a.url,
                                                                                        )?.filter(
                                                                                            x => !!x,
                                                                                        )
                                                                                        if (aurls.length === 1) {
                                                                                            setVideoSrc(goal.angles[1].url)
                                                                                            setAnglesUrls([])
                                                                                        } else {
                                                                                            setAnglesUrls(aurls)
                                                                                            setVideoSrc("")
                                                                                        }
                                                                                        setThumb(goal.details.thumb)
                                                                                        setIsVideoPlayerPopInOpen(true)
                                                                                    }
                                                                                }
                                                                                key={idx}
                                                                            >
                                                                                <div className="goal-thumb-wrapper">
                                                                                    <img
                                                                                        alt=""
                                                                                        src={goal.details.thumb}
                                                                                    />
                                                                                </div>
                                                                                <figcaption>
                                                                                    {goal.details.date.split("-")[1].trim().replace(":", "h")}
                                                                                    <strong> | </strong>
                                                                                    {goal.details.score}</figcaption>
                                                                            </figure>
                                                                        ),
                                                                    )}
                                                                </div>
                                                            )}
                                                            key={values[0].playerId}
                                                            isSelected={values[0].details.contactId === selectedPlayer}
                                                            identification={values[0].details.playerId ? values[0].details.playerId : 0}
                                                            selectSection={setSelectedPlayer}
                                                        />
                                                    )
                                                },
                                            )}
                                        </div>
                                    </section>
                                )
                            },
                        )
                        }

                        <Modal
                            className="location-video-player-pop-in"
                            isOpen={isVideoPlayerPopInOpen}
                            onClose={() => setIsVideoPlayerPopInOpen(false)}
                        >
                            <div
                                className={
                                    "video-wrapper " + (
                                        isLoadingVideo ?
                                            "loading"
                                            :
                                            "loaded"
                                    )
                                }
                                ref={videosWrapper}
                            >
                                <Preloader/>
                                <img src={thumb} alt=""/>
                                {anglesUrls.length ? (
                                    <Swiper
                                        navigation={true}
                                        modules={[ Navigation ]}
                                        slidesPerView={1}
                                    >
                                        { anglesUrls.map(
                                            (angleUrl, idx) => (
                                                <SwiperSlide key={"video-" + idx}>
                                                    <video
                                                        controls
                                                        src={angleUrl + "#t=0.001"}
                                                        onLoadedMetadata={UserAgentHandler.isSafari() ? endLoading : undefined}
                                                        onCanPlay={!UserAgentHandler.isSafari() ? endLoading : undefined}
                                                    >
                                                        <source
                                                            src={angleUrl + "#t=0.001"}
                                                            type="video/mp4"
                                                        />
                                                    </video>
                                                </SwiperSlide>
                                            ),
                                        )
                                        }
                                    </Swiper>
                                ) : videoSrc && (
                                    <>
                                        <video
                                            controls
                                            onLoadedMetadata={UserAgentHandler.isSafari() ? endLoading : undefined}
                                            onCanPlay={!UserAgentHandler.isSafari() ? endLoading : undefined}
                                            src={videoSrc + "#t=0.001"}
                                        >
                                            <source
                                                src={videoSrc + "#t=0.001"}
                                                type="video/mp4"
                                            />
                                        </video>
                                    </>
                                )}
                            </div>
                        </Modal>
                    </>
                )}
        </div>
    )
}

LocationVideos.propTypes = {
    parentLoading: bool,
}

export default LocationVideos
