/* eslint-disable no-irregular-whitespace */
import classNames from "classnames"
import { arrayOf, bool, func, node, object, oneOfType, string } from "prop-types"
import React from "react"

import Preloader from "../../../components/loaders/preloader/preloader"
import useUrbanLanguage from "../../../hooks/urbanLanguage/useUrbanLanguage"
import supersubLevels from "../../../model/enums/superSubLevels"
import { getTypeName } from "../../../pages/reservation/tunnelReservation/function"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import { displayCenterName } from "../../../services/displayCenterName"
import AlmaBadge from "../../AlmaBadge/AlmaBadge.jsx"
import ButtonCta from "../../Buttons/button/button-cta"
import CurrencyDisplay from "../../currencies/CurrencyDisplay"
import Modal from "../../Modal/Modal"
import { useTheme } from "../../ThemeContext/ThemeContext"
import DateDisplay from "../../Time/DateDisplay"

import "./ReservationSlotInfoCard.scss"

function ReservationSlotInfo({
    reservationSlot,
    subscribeToWaitingListCallback,
    pleaseSelectText,
    slotName,
    slotNameIsFemale,
    tunnelType,
    btnAction,
    afterClose,
    beforeOpen,
}) {
    const spellingS = reservationSlot && reservationSlot.askedPlayers > 1 ? "S" : ""
    const theme = useTheme()
    const { tu } = useUrbanLanguage()

    const reservationSlotAmount = reservationSlot?.amount ?
        reservationSlot?.amount
        :
        reservationSlot?.price

    let displayArrow = !(tunnelType === "LEAGUE" || tunnelType === "CUP")

    function openPopIn() {
        ModalHandler.show(ReservationSlotInfoCardPopIn, {
            afterClose,
            beforeOpen,
            reservationSlot,
        })
    }

    const reservationSlotContent = reservationSlot?.id ? (
        <div className="cardInfo__content">
            {!reservationSlot.full && !reservationSlot.isFull ? (
                <React.Fragment>
                    <div className="h1 text-uppercase">Informations</div>
                    {tunnelType !== "SUPERSUB" && (
                        <div className="avatarRow">
                            {reservationSlot.pictureUrl && tunnelType !== "LEAGUE" &&
                                <img className="avatarRow__img" src={reservationSlot.pictureUrl}/>
                            }
                            <div className="avatarRow__label">
                                {tunnelType !== "LEAGUE" ?
                                    (<React.Fragment>
                                        {reservationSlot.type}
                                        { reservationSlot.centerName &&
                                                (<React.Fragment>
                                                    <br/>
                                                    { displayCenterName(reservationSlot.centerId, reservationSlot.centerName) }
                                                </React.Fragment>)
                                        }
                                    </React.Fragment>)
                                    :
                                    reservationSlot.name
                                }
                            </div>
                        </div>
                    )}

                    {!reservationSlot.canRegister && !!reservationSlot.reservedForVIPUntil && (
                        <strong className="vipWarn">
                            L'inscription à {slotNameIsFemale ? "cette" : "ce"} {slotName} sera possible à partir du
                            {" "}
                            <DateDisplay
                                stringDate={reservationSlot.reservedForVIPUntil}
                                displayTime={false}
                            />
                        </strong>
                    )}

                    <div className="cardInfo__text">
                        {!!reservationSlot.resourceType && tunnelType !== "LEAGUE" && tunnelType !== "SCHOOL" && (
                            <p>
                                <strong>Type de terrain : </strong>
                                {reservationSlot.resourceType === "Filmé indoor" ?
                                    "Intérieur"
                                    :
                                    reservationSlot.resourceType
                                }
                            </p>
                        )}
                        {
                            tunnelType === "LEAGUE" &&
                                <strong>Description : </strong>
                        }

                        {tunnelType === "SUPERSUB" && (
                            <React.Fragment>
                                { reservationSlot.askedPlayers && (
                                    <div className="radioList__item__subTitle">
                                        {reservationSlot.askedPlayers + " SUPERSUB" + spellingS + " RECHERCHÉ" + spellingS}
                                    </div>
                                )}
                                <ul>
                                    <li>
                                        Niveau : { supersubLevels[reservationSlot.level] }
                                    </li>
                                    <li>
                                        Capitaine : { reservationSlot.organizer.firstname + " " + reservationSlot.organizer.lastname.substring(0, 1) + "." }
                                    </li>
                                    <li>
                                        Terrain : {getTypeName(reservationSlot.reservation.resourceTypeDisplay, reservationSlot.reservation.resourceTypeDisplay)}
                                    </li>
                                </ul>
                                {reservationSlot.description && (
                                    <div className="cardInfo__description supersub">{reservationSlot.description}</div>
                                )}
                            </React.Fragment>
                        )}

                        {reservationSlot.description && tunnelType !== "SUPERSUB" && (
                            <div className="cardInfo__description" dangerouslySetInnerHTML={{
                                __html: reservationSlot.description,
                            }} />
                        )}

                    </div>

                    <div className="cardInfo__price h1">
                        Tarif : {reservationSlot.isFree || (reservationSlot.reservation && reservationSlot.reservation.amountDue === 0) ?
                            "Offert"
                            : (
                                <CurrencyDisplay price={reservationSlot.amountTotal ?
                                    reservationSlot.amountTotal
                                    :
                                    reservationSlotAmount
                                }
                                />
                            )}
                    </div>

                    {reservationSlot?.allowPaymentSpread && (
                        <AlmaBadge amount={reservationSlot.amountTotal ?
                            reservationSlot.amountTotal
                            :
                            reservationSlotAmount}
                        />
                    )}

                    {tunnelType === "SUPERSUB" &&
                        (
                            <ButtonCta
                                text= "Postuler"
                                onClick={
                                    () => {
                                        if (btnAction) {
                                            btnAction()
                                        } else {
                                            window.scrollTo({
                                                behavior: "smooth",
                                                left: 0,
                                                top: document.getElementById("subform").offsetTop,
                                            })
                                        }

                                    }
                                }
                            />
                        )}

                    { reservationSlot.detailedDescription && (
                        <ButtonCta
                            onClick={openPopIn}
                            text="Plus d’informations"
                        />
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className={tunnelType === "CUP" ? "h1 h1Cup" : "h1"}>
                        {((tunnelType === "CUP" && theme !== "theme-padel") || tunnelType !== "CUP") && (
                            <>
                                {slotNameIsFemale ? "Cette" : "Ce"} {slotName} est compl{slotNameIsFemale ? "ète" : "et"} !
                            </>
                        )}
                        {tunnelType === "CUP" && theme === "theme-padel" && (
                            <>Malheureusement, ce tournoi est complet !</>
                        )}
                    </div>

                    { (reservationSlot.waitingListOpened || reservationSlot.waitingListOpen) && (
                        <React.Fragment>
                            <div className="cardInfo__text">
                                <p>Nous pouvons te proposer :</p>
                                <ul className="ul">
                                    <li>de sélectionner {slotNameIsFemale ? "une" : "un"} autre {slotName}{((tunnelType === "CUP" && theme !== "theme-padel") || tunnelType !== "CUP") && <> ou un autre centre</>}</li>
                                    <li>de t'inscrire à la liste d’attente</li>
                                </ul>
                            </div>

                            <div className="cardInfo__text waitingList">
                                <ButtonCta
                                    onClick={subscribeToWaitingListCallback}
                                    text="Inscription liste d'attente"
                                />
                            </div>

                            <div className="cardInfo__text mail">
                                {
                                    tunnelType === "LEAGUE" ?
                                        <p><strong>Tu seras prévenu par e-mail en cas de désistement pour {tu("league.definedArticle")}{tu("league.name")} sélectionnée.</strong></p>
                                        :
                                        <p><strong>Tu seras prévenu par e-mail en cas de désistement pour {slotNameIsFemale ? "la" : "le"} {slotName} sélectionné{slotNameIsFemale && "e"}.</strong></p>
                                }
                            </div>
                        </React.Fragment>
                    )}
                    { reservationSlot.detailedDescription && (
                        <ButtonCta
                            onClick={openPopIn}
                            isCta2Alt={true}
                            text="Plus d’informations"
                        />
                    )}
                </React.Fragment>)
            }
        </div>
    ) : (
        <div className="text-center cardInfo__empty">
            Sélectionne {pleaseSelectText}
        </div>
    )

    return (
        <div className={(tunnelType ? tunnelType.toLowerCase() : "") + "-slotInfoCard"} style={{
            width: "100%",
        }}>
            {displayArrow && <div className="cardInfo_arrow"></div>}
            <div className={classNames("cardInfo", {
                "is-empty": !reservationSlot || Object.keys(reservationSlot).length === 0,
            })}>
                {
                    reservationSlot === "Loading" ?
                        <Preloader alt={theme === "theme-padel"}/>
                        :
                        reservationSlotContent
                }
            </div>
        </div>
    )
}
/* istanbul ignore next */
export const ReservationSlotInfoCardPopIn = ModalHandler.create(({ visible, hide, afterClose, beforeOpen, reservationSlot }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            afterClose={afterClose}
            beforeOpen={beforeOpen}
        >
            <div className="description-popup"
                dangerouslySetInnerHTML={{
                    __html: reservationSlot.detailedDescription,
                }}>
            </div>
        </Modal>
    )
})

ReservationSlotInfo.propTypes = {
    afterClose: oneOfType([ func, bool ]),
    beforeOpen: oneOfType([ func, bool ]),
    btnAction: func,
    pleaseSelectText: oneOfType([ string, node, arrayOf(node) ]),
    reservationSlot: oneOfType([ object,string ]),
    slotName: string,
    slotNameIsFemale: bool,
    subscribeToWaitingListCallback: func,
    tunnelType: string,
}

export default ReservationSlotInfo
