import classNames from "classnames"
import { any, bool, func, object, oneOfType } from "prop-types"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import NotFullyPaid from "./NotFullyPaid"
import playersNumber from "./playersNumber"
import { acceptConvocation, declineConvocation, getLocation } from "../../../globalAPI/api"
import useLocationVideos from "../../../hooks/reservation/location/useLocationVideos"
import useMediaPath from "../../../hooks/useMediaPath"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import dayValues from "../../../model/enums/dayValues"
import months from "../../../model/enums/months"
import { isCompositionTabAvailable } from "../../../pages/reservation/infoReservation/functions/functions"
import { useLocationStore } from "../../../pages/reservation/store/store.js"
import { getTypeName } from "../../../pages/reservation/tunnelReservation/function"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import usePath from "../../../routes/services/usePath"
import formatTwoDigits from "../../../services/formatTwoDigits"
import haveCompo from "../../../services/haveCompo"
import ButtonCta from "../../Buttons/button/button-cta"
import Modal from "../../Modal/Modal"
import { useTheme } from "../../ThemeContext/ThemeContext"
import HourDisplay from "../../Time/HourDisplay"

import "./LocationBlock.scss"

function LocationBlock({ location, locationHasArdoise, setAjaxLoading, callBack, openInvitPlayersPopIn, setAddPaiementPopInOpen, setLocationToPay }) {
    const user = useSelector(state => state.userLoaded)
    const { currentLocation, clearStore } = useLocationStore()
    const reservation = location.reservation ?
        location.reservation
        :
        location

    const numberOfPlayers = playersNumber
    const correspondingPlayers = numberOfPlayers.find(type => type.types.includes(reservation.resourceType))
    const isConvocation = !!location.reservation

    const {
        id,
        Organizer,
        start,
        end,
        duration,
        discount,
        centerName,
        isOwner,
        resourceType,
        resourceTypeDisplay,
        amountDue,
        price,
        scorePadelDetailled,
        scoreTeam1,
        scoreTeam2,
        team1Name,
        team2Name,
        subscriptionId,
    } = reservation
    const realPrice = discount ? price - discount.discount : price

    const [ details, setDetails ] = useState({})
    const [ buttonToShow, setButtonToShow ] = useState(null)
    const [ userPaid, setUserPaid ] = useState()

    const navigate = useNavigate()
    const path = usePath()
    const mediaPath = useMediaPath()
    const themeFeatures = useThemeFeatures()
    const theme = useTheme()
    const startDate = new Date(start)
    const endDate = new Date(end)
    const now = new Date()
    const day = dayValues[startDate.getDay()]
    const date = formatTwoDigits(startDate.getDate())
    const month = months[startDate.getMonth() + 1]
    const isMatchPlayed = endDate <= now
    const attendeesCount = details?.attendeesCount

    const { hasVideos } = isConvocation ?
        false
        :
        useLocationVideos(reservation)

    const formatOrganizer = () => {
        const tempOrganizer = Organizer.split(" ")?.filter(item => item !== "")
        tempOrganizer[tempOrganizer.length - 1] = tempOrganizer[tempOrganizer.length - 1].charAt(0) + "."
        return tempOrganizer.join(" ")
    }
    const hasScore = !!(
        scoreTeam2 !== null &&
        scoreTeam1 !== null
    )

    function goToLocation(onglet) {
        if (currentLocation?.id && (parseInt(currentLocation?.id) !== parseInt(id))) {
            clearStore()
        }

        navigate(path("/reservations/locations/" + id + `/${onglet ?? "infos"}`),
            {
                state: {
                    blockHasVideos: hasVideos,
                },
            },
        )
    }

    function confirmRefuseConvo(e) {
        e.stopPropagation()
        ModalHandler.show(RefuseInvitationPopin, {
            refuseConvocation: refuseConvocation,
        })
    }

    function refuseConvocation(e) {
        e?.stopPropagation?.()
        setAjaxLoading(true)
        ModalHandler.hide(RefuseInvitationPopin)
        declineConvocation(location.id).then(
            () => {
                callBack(location)
                setAjaxLoading(false)
            },
        )
    }
    async function acceptConvo(e) {
        e.stopPropagation()
        setAjaxLoading(true)
        acceptConvocation(location.id).then(
            () => {
                getLocation(location?.reservation?.id).then(
                    fullLocation => {
                        navigate(path("/confirmcommand"), {
                            state: {
                                locationDetails: fullLocation,
                                message: "acceptBookingInvitation",
                                subtitle: "",
                                success: true,
                                title: "Tu as rejoint le match !",
                                token: encodeURIComponent(fullLocation.publicJoinToken),
                                tunnelType: "BOOKING",
                            },
                        })
                    },
                )
            },
        )
    }

    function goToArdoise(e) {
        e.stopPropagation()
        navigate(path("/profil/ardoises"))
    }

    const pricePerPlayer = location.pricePerPart ? location.pricePerPart : (correspondingPlayers ?
        (realPrice / correspondingPlayers.players) % 1 !== 0 ?
            (realPrice / correspondingPlayers.players).toFixed(2)
            :
            realPrice / correspondingPlayers.players
        :
        (realPrice / 10) % 1 !== 0 ?
            (realPrice / 10).toFixed(2)
            :
            realPrice / 10
    )

    const missingPlayers = location.maxAttendees - (attendeesCount ? attendeesCount : 1)
    const plural = missingPlayers > 1 ? "s" : ""
    const compo = haveCompo(location.resourceType)
    const matchIsReady = missingPlayers <= 0
        && ((compo && (details?.team1?.length + details?.team2?.length) === playersNumber.find(type => type.types.includes(details.resourceType))?.players)
        || !compo)
        && !details.amountDue
    const getComingMatchButton = () => {
        const isCompoDone = details?.team1?.length + details?.team2?.length === playersNumber.find(type => type.types.includes(details.resourceType))?.players
        if (missingPlayers > 0) {
            setButtonToShow(
                <ButtonCta
                    onClick={(e) => {
                        e.stopPropagation()
                        openInvitPlayersPopIn(location.id)
                    }}
                    className="inviteButton"
                    text="INVITER MES JOUEURS"
                />,
            )
        } else if (compo && !isCompoDone) {
            setButtonToShow(
                <ButtonCta
                    onClick={(e) => {
                        e.stopPropagation()
                        goToLocation("compo")
                    }}
                    className="inviteButton"
                    text="FAIRE LES COMPOS"
                />,
            )
        } else if (((compo && isCompoDone) || !compo) && details.amountDue) {
            setButtonToShow(
                <ButtonCta
                    onClick={(e) => {
                        e.stopPropagation()
                        goToLocation("paiements")
                    }}
                    className="inviteButton"
                    text="PAYER EN LIGNE"
                />,
            )
        }
    }
    useEffect(() => {
        if (id && startDate > now) {
            getLocation(id).then(res => {
                setDetails(res)
                setUserPaid(res?.prepayments?.find(payment => payment?.contact?.id === user.contactId && !payment.cancelationDate))
            })
        }
    }, [])
    useEffect(() => {
        if (details?.id) {
            getComingMatchButton()
        }
    }, [ details ])
    const passedMatchDom = isOwner ? (
        <div className="ownerScoreCTA">
            <em>Alors ce match, quel est le score ?</em>
            <ButtonCta
                text = "Renseigner le score"
                isAlt
                onClick = {(e) => {
                    e.stopPropagation()
                    goToLocation()
                }}
            />
        </div>
    ) : (
        <div className="waitingOrganizerScores">
            <img src={mediaPath([ "/assets/icons/hourglass-in-circle.svg", "/assets/images/padel/icons/icon-hourglass-red-line.svg" ])}/>
            <em>On attend le score de l'organisateur</em>
        </div>
    )

    const comingMatchDom = isOwner ?
        price > 0 && (
            <section className="pricing">
                <p>
                    <span className="priceNotif">
                        {!!amountDue && !subscriptionId && (
                            <NotFullyPaid id={id} isOwner={isOwner} />
                        )}
                        <strong>{(realPrice - amountDue) % 1 !== 0 ? (realPrice - amountDue).toFixed(2) : (realPrice - amountDue)}€ payés</strong><br/>
                    </span>
                    sur {realPrice % 1 !== 0 ? realPrice.toFixed(2) : realPrice}€ au total
                </p>
            </section>
        )
        : price > 0 && (
            <section className="pricing" key={"pricePerPlayer-" + pricePerPlayer}>
                <p>
                    <span className="priceNotif">
                        {!!amountDue && !subscriptionId && !userPaid && (
                            <NotFullyPaid id={id} />
                        )}
                        <strong>
                            {pricePerPlayer}€ / joueur
                        </strong>
                    </span>
                    soit {realPrice}€ au total
                </p>
            </section>
        )

    return (
        <div
            id={"locationBlock-" + location.id }
            className={isConvocation ? "locationBlock convocation" : "locationBlock" }
            data-testid="locationBlock"
            onClick={
                (e) => {
                    e.stopPropagation()
                    if (!isConvocation) {goToLocation(isCompositionTabAvailable(location) ? "compo" : undefined)}
                }
            }
        >
            <div className="info">
                <section className="global">
                    {isConvocation && (
                        <div>
                            Invitation de{" "}
                            <strong className="organizer">
                                {formatOrganizer()}
                            </strong>
                        </div>
                    )}
                    <strong>
                        {day} {date} {month} -
                        {" "}
                        <HourDisplay
                            date={startDate}
                        />
                        {" "}
                        ({duration ? duration.replace("45 min", "0h45").replace(/\s|(?!h)[a-z]+( )?/g, "") : ""})
                    </strong>
                    <div>
                        {centerName} / {getTypeName(resourceType, resourceTypeDisplay)}
                    </div>
                    {reservation.type === 3 && (
                        <div>
                            {reservation.typeDisplay}
                        </div>
                    )}
                    { !isOwner && !isConvocation &&
                        (<div>
                            Match géré par&nbsp;<strong>{formatOrganizer()}</strong>
                        </div>)
                    }
                </section>
                { endDate < now ? (
                    <section className="score" key={"test" + pricePerPlayer}>
                        {hasScore ? (
                            <>
                                <div className={scoreTeam1 > scoreTeam2 ? "won firstTeam" : "firstTeam"}>
                                    <span className="teamName">{team1Name}</span>
                                    <span className="scoreWrapper">
                                        {
                                            themeFeatures([
                                                scoreTeam1,
                                                scorePadelDetailled ? scorePadelDetailled.map((score, i) => <span key={"padelTeam1scoreSet-" + (i + 1)} className="padelScore"><span>{score.scoreTeam1}</span></span>) : scoreTeam1,
                                            ])
                                        }
                                    </span>
                                </div>

                                <div className={ scoreTeam2 > scoreTeam1 ? "won secondTeam" : "secondTeam" }>
                                    <span className="teamName">{team2Name}</span>
                                    <span className="scoreWrapper">
                                        {
                                            
                                            themeFeatures([
                                                scoreTeam2,
                                                scorePadelDetailled ? scorePadelDetailled.map((score, i) => <span key={"padelTeam2scoreSet-" + (i + 1)} className="padelScore"><span>{score.scoreTeam2}</span></span>) : scoreTeam2,
                                            ])
                                        }
                                    </span>
                                </div>
                            </>
                        ) : passedMatchDom}
                    </section>
                ) : comingMatchDom
                }

                <section className="actions">
                    {isConvocation ? (
                        <div className="invitation-response">
                            <em>Dispo pour participer au match&nbsp;?</em>
                            <div className="invitation-response__btn-holder">
                                <ButtonCta
                                    className={classNames("refuseButton", { rotateIcon45: theme === "theme-soccer" })}
                                    icon={mediaPath([ "/assets/icons/cross-white.svg", "/assets/images/padel/icons/icon-cross-black.svg" ])}
                                    onlyIcon={true}
                                    noSkew
                                    onClick={confirmRefuseConvo}
                                    hoverChangeIcon={mediaPath([ "/assets/icons/cross-white.svg", "/assets/images/padel/icons/icon-cross-green.svg" ])}

                                />
                                <ButtonCta
                                    icon={mediaPath([ "/assets/images/check--white.svg", "/assets/images/padel/icons/icon-check-black.svg" ])}
                                    onlyIcon={true}
                                    noSkew
                                    onClick={acceptConvo}
                                    hoverChangeIcon={mediaPath([ "/assets/images/check--orange.svg", "/assets/images/padel/icons/icon-check-green.svg" ])}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            {endDate < now ? (
                                <div className="buttons">
                                    {locationHasArdoise && (
                                        <ButtonCta
                                            isCta2
                                            text="Ardoise"
                                            className="ardoise"
                                            onClick={goToArdoise}
                                            customCls={"ardoise"}
                                        />
                                    )}
                                    {hasVideos && (
                                        <ButtonCta
                                            noSkew
                                            className="hasVideo"
                                            isAlt
                                            onlyIcon
                                            icon={mediaPath([ "/assets/icons/videos-orange.svg", "/assets/images/padel/icons/icons-camera-black.svg" ])}
                                            hoverChangeIcon={mediaPath([ "/assets/icons/videos.svg", "/assets/images/padel/icons/icons-camera.svg" ])}
                                            onClick = {(e) => {
                                                e.stopPropagation()
                                                goToLocation("videos")
                                            }}
                                        />
                                    )}
                                </div>
                            ) : (startDate > now) && (
                                <>
                                    {isOwner && (
                                        <div className="ownerComingMatch">
                                            {matchIsReady && details?.id && (
                                                <div className="isReady">
                                                    <img src={mediaPath([ "/assets/icons/checked-orange.svg", "/assets/images/padel/icons/icon-valid-red-line.svg" ])} />
                                                    <div>TON MATCH EST PRÊT !</div>
                                                </div>
                                            )}
                                            {!matchIsReady && details.id && (
                                                <div className="notReady">
                                                    { missingPlayers > 0 ?
                                                        `${missingPlayers} joueur${plural} manquant${plural}`
                                                        :
                                                        "MATCH COMPLET  !"
                                                    }
                                                    {buttonToShow}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {!isOwner && !isMatchPlayed && (
                                        <span className="icon-txt">
                                            <img src={mediaPath([ "/assets/icons/check-in-circle.svg", "/assets/images/padel/icons/icon-valid-red-line.svg" ])}/>
                                            <em>Tu participes au match</em>
                                        </span>
                                    )}
                                    {!isOwner && !isMatchPlayed && amountDue > 0 && reservation.acceptPrePayment && (
                                        <ButtonCta
                                            isAlt
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setLocationToPay({ ...reservation, playersNumber: correspondingPlayers?.players })
                                                setAddPaiementPopInOpen(true)
                                            }
                                            }
                                            className="payButton"
                                            text="PAYER MA PART"
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </section>
            </div>
        </div>
    )
}
/* istanbul ignore next */
export const RefuseInvitationPopin = ModalHandler.create(({ visible, hide, refuseConvocation }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className={"refuseInvitationPopin"}
        >
            <header> Demande de participation </header>
            <section>
                <p className="cancel">Es-tu sûr de vouloir refuser l'invitation ?</p>

                <div className="buttonsWrapper">
                    <ButtonCta
                        text="Oui"
                        onClick={refuseConvocation}
                    />
                    <ButtonCta
                        text="Non"
                        onClick={
                            (e) => {
                                e.stopPropagation()
                                hide()
                            }
                        }
                    />
                </div>
            </section>
        </Modal>
    )
})

LocationBlock.propTypes = {
    callBack: oneOfType([ bool, func ]),
    location: object,
    locationHasArdoise: bool,
    openInvitPlayersPopIn: oneOfType([ bool, func ]),
    setAddPaiementPopInOpen: any,
    setAjaxLoading: func,
    setLocationToPay: func,
}

export default LocationBlock
