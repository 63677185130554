import { yupResolver } from "@hookform/resolvers/yup"
import dayjs from "dayjs"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"

import { getUser } from "../../globalAPI/api.js"
import { genderValues } from "../../model/enums/User/genderOptions.js"
import { editUser } from "../../pages/profil/api.js"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import ButtonCta from "../Buttons/button/button-cta.jsx"
import InputDate from "../Forms/InputDate/InputDate.jsx"
import { Select } from "../Inputs/NewSelect/Select.jsx"
import { TextInput } from "../Inputs/TextInput/TextInput.jsx"
import Preloader from "../loaders/preloader/preloader.jsx"
import Modal from "../Modal/Modal.jsx"

import "./BusinessLeagueProfile.scss"

export const BusinessLeagueProfile = ModalHandler.create(({ visible, hide, currentUser }) => {
    const [ isLoading, setIsLoading ] = useState(false)

    const form = useForm({
        defaultValues: {
            birthdate: currentUser?.birthdate ? dayjs(currentUser?.birthdate)?.toDate() : null,
            fonction: currentUser?.jobTitle,
            gender: genderValues?.find(x => x.value === currentUser?.title),
        },
        mode: "onBlur",
        reValidateMode: "onChange",
        resolver: yupResolver(yup.object().shape({
            birthdate: yup.string().required("Ce champ est requis"),
            fonction: yup.string().max(40, "La fonction ne peut pas dépasser 40 caractères").required("Ce champ est requis"),
            gender: yup.object().shape({
                label: yup.string(),
                value: yup.string(),
            }).required("Ce champ est requis"),
        })),
    })

    const onSubmit = form.handleSubmit(async (data) => {
        setIsLoading(true)
        await editUser({
            ...currentUser,
            birthdate: dayjs(data.birthdate).format("YYYY-MM-DDTHH:mm:ss"),
            jobTitle: data.fonction,
            title: data.gender,
        }).then(getUser).then(() => {
            hide()
        })
        setIsLoading(false)
    })

    return (
        <Modal
            className={"businessLeague-profile-modal"}
            isOpen={visible}
            onClose={hide}
            closeOnEsc={false}
            exitOnOverlayClicked={false}
            closeButton={null}
            lockScroll
        >
            <div className={"content"}>
                <img src={"/assets/images/business/logo-business-white.svg"} alt={"logo"} />
                <h1>Complète ton profil Business League</h1>
                <p>Dis-nous en plus sur toi pour personnaliser les packs de communication</p>
                <form onSubmit={onSubmit}>
                    <Controller
                        render={({ field }) => (
                            <Select
                                options={genderValues}
                                placeholder="Genre"
                                required
                                disabled={isLoading}
                                label="Genre"
                                value={field.value}
                                onChange={field.onChange}
                                menuPlacement={"auto"}
                                error={form.formState.errors?.gender?.message}
                            />
                        )}
                        name={"gender"}
                        rules={{ required: true }}
                        control={form.control}
                    />
                    <Controller
                        render={({ field: { value, onChange } }) => (
                            <InputDate
                                label={(
                                    <span>
                                    Date de naissance<span className="label-star"> *</span>
                                    </span>
                                )}
                                bind={[ value, onChange ]}
                                max={new Date()}
                                useDatePicker={false}
                                customClass="customDayPicker"
                                placeholder="JJ/MM/AAAA"
                                checkValue
                                disabled={isLoading}
                                required
                                error={form.formState.errors?.birthdate?.message}
                            />
                        )}
                        name={"birthdate"}
                        rules={{ required: true }}
                        control={form.control}
                    />
                    <TextInput
                        label="fonction dans l’entreprise"
                        required
                        disabled={isLoading}
                        rhf
                        maxLength={40}
                        error={form.formState.errors?.fonction?.message}
                        {...form.register("fonction", {
                            required: true,
                        })}
                    />
                    {isLoading ? (
                        <Preloader small alt/>
                    ) : (
                        <ButtonCta
                            text={"Je suis prêt.e"}
                            onClick={onSubmit}
                        />
                    )}
                </form>
            </div>
        </Modal>
    )
})
