import { useFormContext } from "react-hook-form"

import { TextInput } from "../../../components/Inputs/TextInput/TextInput.jsx"
import { useBusinessFormNavigation } from "../store/useBusinessFormNavigation.js"

export const Captain = () => {
    const methods = useFormContext()
    const { nextStep } = useBusinessFormNavigation()
    const onSubmit = methods.handleSubmit(() => nextStep())

    return (
        <form onSubmit={onSubmit}>
            <h2>Capitaine</h2>
            <TextInput
                {...methods.register("captain.firstName")}
                placeholder="Prénom"
                label="Prénom du capitaine"
                required
                rhf
                error={methods.formState.errors?.captain?.firstName?.message}
            />
            <TextInput
                {...methods.register("captain.lastName")}
                placeholder="Nom"
                label="Nom du capitaine"
                required
                rhf
                error={methods.formState.errors?.captain?.lastName?.message}
            />
            <TextInput
                {...methods.register("captain.email")}
                placeholder="Adresse email"
                label="Email pro"
                type="email"
                required
                rhf
                error={methods.formState.errors?.captain?.email?.message}
            />
            <TextInput
                {...methods.register("captain.phone")}
                placeholder="Téléphone"
                label="Téléphone mobile"
                required
                type="tel"
                rhf
                error={methods.formState.errors?.captain?.phone?.message}
            />
        </form>
    )
}
