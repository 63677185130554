export default function hoursSort(a, b) {
    if (a.start === b.start) {
        if (a.type === b.type) {
            return 0
        }
        else if (a.type > b.type) {
            return 1
        } else {
            return -1
        }
    } else if (a.start > b.start) {
        return 1
    } else {
        return -1
    }
}
