
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import { components } from "react-select"

export const CustomMultiValueContainer = props => {
    const { children } = props

    return (
        <motion.div
            key={props.data.label}
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: "auto" }}
            exit={{ opacity: 0, width: 0 }}
            transition={{ duration: 0.2 }}
        >
            <components.MultiValueContainer
                {...props}
                innerProps={{
                    className: "select-input-multi-value-container",
                }}
            >
                {children}
            </components.MultiValueContainer>
        </motion.div>
    )
}

CustomMultiValueContainer.propTypes = {
    children: PropTypes.node,
    data: PropTypes.shape({
        label: PropTypes.string,
    }),
    selectProps: PropTypes.shape({
        isFocused: PropTypes.bool,
    }),
}
