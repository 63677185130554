import { motion } from "framer-motion"
import PropTypes from "prop-types"
import { components } from "react-select"

export const CustomPlaceholder = props => {
    return (
        <components.Placeholder
            {...props}
            className="select-input-placeholder"
        >
            <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                {props.children}
            </motion.div>
        </components.Placeholder>
    )
}

CustomPlaceholder.propTypes = {
    children: PropTypes.node,
}
