import { bool, object } from "prop-types"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { handlePartnerModify, modifyLicenses } from "./functions"
import ButtonCta from "../../../../components/Buttons/button/button-cta"
import { TextInput } from "../../../../components/Inputs/TextInput/TextInput.jsx"
import Modal from "../../../../components/Modal/Modal"

import "./ModifyLicensesPopin.scss"

const ModifyLicensesPopin = forwardRef(({ partner, allowTemporaryPartnerEdit }, ref) => {
    const user = useSelector(state => state.userLoaded)
    const dispatch = useDispatch()

    const [ licence1, setLicence1 ] = useState(user?.playerProfile?.licenseNumber ?? "")
    const [ licence2, setLicence2 ] = useState(partner?.licenseNumber ?? "")
    const [ canModifyPartner, setCanModifyPartner ] = useState(false)
    const [ internalModalState, setInternalModalState ] = useState(false)

    useImperativeHandle(ref, () => ({
        hide: () => {
            setInternalModalState(false)
        },
        show: () => {
            setInternalModalState(true)
        },
    }))

    useEffect(() => {
        handlePartnerModify(partner, setLicence2, setCanModifyPartner)
    }, [ partner ])

    return (
        <Modal
            isOpen={internalModalState}
            onClose={() => {
                licence1.length === 0 && setLicence1(user?.playerProfile?.licenseNumber ?? "")
                setInternalModalState(false)
            }}
            className="modifyLicensesPopin"
        >
            <div className="modal-close-button showPadel" onClick={() => setInternalModalState(false)}/>
            <header>Numéros de licence</header>
            <section className="licenses">
                <div className="form">

                    <div className="playerName">
                        {`${user?.firstname} ${user?.lastname ? user.lastname.charAt(0) + "." : ""}`}
                    </div>

                    <TextInput
                        label={"N° de licence"}
                        value={licence1}
                        onChange={val => setLicence1(val ? val.replace(/[^A-Za-z0-9]/g, "") : "")}
                    />

                    {partner && partner?.firstname !== "WaitingList" && partner?.lastname !== "WaitingList" && (
                        <>
                            <div className="playerName">
                                {`${partner?.firstname} ${partner?.lastname ? (partner.lastname.charAt(0) + ".") : ""}`}
                            </div>
                            <TextInput
                                label={"N° de licence"}
                                value={licence2}
                                disabled={allowTemporaryPartnerEdit ? false : !canModifyPartner }
                                onChange={val => setLicence2(val ? val.replace(/[^A-Za-z0-9]/g, "") : "")}
                            />
                        </>
                    )}
                </div>
                <ButtonCta
                    text="Modifier"
                    disabled={licence1.length === 0}
                    onClick={() => modifyLicenses(user, partner, licence1, licence2, ref, dispatch)}
                />
            </section>
        </Modal>
    )
})

ModifyLicensesPopin.displayName = "ModifyLicensesPopin"

ModifyLicensesPopin.propTypes = {
    allowTemporaryPartnerEdit: bool,
    partner: object,
}

export default ModifyLicensesPopin
