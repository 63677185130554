import { bool, func, object, oneOfType, string } from "prop-types"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import Modal from "../../../../components/Modal/Modal"
import useMediaPath from "../../../../hooks/useMediaPath"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"

const RemindPopin = ModalHandler.create(({ location, visible, hide , nextPossibleRemindDate, confirmAction }) => {
    const mediaPath = useMediaPath()

    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className="remindPopin"
        >
            <header> Relancer mes joueurs </header>
            <section className="confirmRemindSending">
                {location && location.canRemindAttendees ? (
                    <div className="remind-possible">
                        <h2>Veux-tu relancer les joueurs en attente ?</h2>
                        <p>Tu es limité à une relance par jour.</p>
                        <div className="buttonWrapper">
                            <ButtonCta
                                id={"remind-possible-button1"}
                                text="Oui"
                                onClick={
                                    () => {
                                        confirmAction()
                                        hide()
                                    }
                                }
                            />
                            <ButtonCta
                                id={"remind-possible-button2"}
                                text="Non"
                                onClick={hide}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <p>
                            Les joueurs ont déjà été relancés.<br />
                            Prochaine relance possible le<br />
                            <span className="date-wrapper">
                                <img src={mediaPath([ "/assets/icons/calendar-orange.svg", "/assets/images/padel/icons/icon-calendar.svg" ])} />
                                <span>{nextPossibleRemindDate}</span>
                            </span>
                        </p>
                        <ButtonCta
                            text="Terminer"
                            onClick={hide}
                            className="remindClose"
                        />
                    </>
                )}
            </section>
        </Modal>
    )
})

RemindPopin.displayName = "RemindPopin"

RemindPopin.propTypes = {
    location: oneOfType([ bool, object ]),
    nextPossibleRemindDate: oneOfType([ bool, string ]),
    setFormEntry: func,
    setIsLoading: func,
}

export default RemindPopin
