import { AnimatePresence } from "framer-motion"
import { useEffect, useRef, useState } from "react"

import ButtonCta from "../../../components/Buttons/button/button-cta.jsx"
import { SelectInput } from "../../../components/Inputs/Select/SelectInput.jsx"
import DayMatches from "../../../components/league/DayMatches/DayMatches.jsx"
import Preloader from "../../../components/loaders/preloader/preloader.jsx"
import useIsMobile from "../../../hooks/useIsMobile.js"
import { sortArray } from "../../../utilities/helpers.js"
import { getLeagueGames } from "../api/myLeague.js"
import { findNextWeek, hasTeamChanged } from "../service/service.js"
import { useLeagueStore } from "../store/useLeagueStore.js"

import "./LeagueCalendar.scss"

export const LeagueCalendar = () => {
    const [ currentDay, setCurrentDay ] = useState(null)
    const [ isNextMatchInView, setIsNextMatchInView ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ init, setInit ] = useState(false)
    const [ activeAccordion, setActiveAccordion ] = useState(null)
    const { selectedLeagueTeam, selectedTeamData, shouldRevalidate, setSelectedTeamData, setShouldRevalidate } = useLeagueStore()
    const [ teamFilter, setTeamFilter ] = useState("")
    const dayRefs = useRef([])

    const isMobile = useIsMobile()

    useEffect(() => {
        if (selectedLeagueTeam?.id) {
            fetchLeagueGames()
            setActiveAccordion(null)
            setTeamFilter("")
        }
    }, [ selectedLeagueTeam?.id, selectedLeagueTeam?.leagueId, shouldRevalidate ])

    useEffect(() => {
        if (selectedTeamData?.games) {
            const dayIndex = findNextWeek(selectedTeamData.games, teamFilter ? teamFilter : selectedLeagueTeam?.id)
            setCurrentDay(dayIndex)
        }
    }, [ selectedTeamData?.games ])

    useEffect(() => {
        scrollToNextMatch()
    }, [ currentDay ])

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0]?.isIntersecting) {
                    setIsNextMatchInView(true)
                } else {
                    setIsNextMatchInView(false)
                }
            },
            { threshold: 0.3 },
        )

        if (currentDay !== null && dayRefs.current[currentDay]) {
            observer.observe(dayRefs.current[currentDay])
        }

        return () => {
            if (currentDay !== null && dayRefs.current[currentDay]) {
                observer.unobserve(dayRefs.current[currentDay])
            }
        }
    }, [ currentDay, selectedTeamData, isLoading ])

    const scrollToNextMatch = () => {
        if (currentDay !== null && dayRefs.current[currentDay]) {
            dayRefs.current[currentDay].scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }

    const handleAccordionClick = (index, week) => {
        setActiveAccordion(index === activeAccordion ? null : index)

        if (index === activeAccordion || !isMobile) {return}
        setTimeout(() => {
            dayRefs.current[week].scrollIntoView({ behavior: "smooth", block: "start" })
        }, 250)
    }

    const fetchLeagueGames = async () => {
        if (
            selectedLeagueTeam?.id
            && hasTeamChanged(selectedLeagueTeam.leagueId, selectedLeagueTeam.id, selectedTeamData)
            || !Object.keys(selectedTeamData?.games ?? {})?.length
            || selectedTeamData?.shouldRevalidate
            || shouldRevalidate
        ) {
            if (!init || !shouldRevalidate) {
                setInit(true)
                setIsLoading(true)
            }
            const data = await getLeagueGames(selectedLeagueTeam.leagueId, selectedLeagueTeam?.id)
            const transformedGames = {}

            Object.entries(data.games ?? {}).forEach(([ week, matches ]) => {
                transformedGames[week] = matches
                    .sort((a, b) => (a.localTeam.teamId === selectedLeagueTeam.id ? -1 : (b.localTeam.teamId === selectedLeagueTeam.id ? 1 : 0)))
            })

            setSelectedTeamData({
                games: transformedGames,
                shouldRevalidate: false,
                teams: data.teams,
            })
            setShouldRevalidate(false)
            setIsLoading(false)
        }
    }

    const isTeamInMatch = (match) => {
        return !teamFilter || match.localTeam.teamId === teamFilter || match.visitorTeam.teamId === teamFilter
    }

    if (isLoading) {return <Preloader />}

    if (Object.keys(selectedTeamData?.games ?? {}).length === 0) {
        return (
            <div className={"league-calendar"}>
                <div className={"no-match"}>
                    <h3>Calendrier bientôt disponible</h3>
                </div>
            </div>
        )
    }

    return (
        <div className={"league-calendar"}>
            <div className="team-filter">
                <SelectInput
                    label={"Filtrer par équipe"}
                    fullWidth
                    options={sortArray(Object.entries(selectedTeamData?.teams || {}).map(
                        team => ({ label: team[1], value: team[0] }),
                    ) ?? [], {
                        key: "label",
                        order: "asc",
                    })}
                    placeholderOption={"Toutes les équipes"}
                    value={teamFilter || ""}
                    onChange={(e) => setTeamFilter(parseInt(e.target.value))}
                />
            </div>
            <div className={"days-wrapper"}>
                <AnimatePresence>
                    {selectedTeamData?.games && Object.entries(selectedTeamData.games).map((week, index) => (
                        <div
                            ref={(el) => (dayRefs.current[week[0]] = el)}
                            key={week[0].toString()}
                        >
                            <DayMatches
                                day={index + 1}
                                week={week[0].toString()}
                                matches={week[1]?.filter(isTeamInMatch)}
                                isNext={week[0] === currentDay && week[1].some(isTeamInMatch)}
                                isOpen={activeAccordion === index}
                                onClick={() => handleAccordionClick(index, week[0].toString())}
                                filtered={!teamFilter ? null : parseInt(teamFilter)}
                            />
                        </div>
                    ))}
                </AnimatePresence>
            </div>
            <AnimatePresence>
                {!isLoading && !isNextMatchInView && !!currentDay && (
                    <div>
                        <ButtonCta text="Prochain match" className={"next-match"} onClick={scrollToNextMatch}/>
                    </div>
                )}
            </AnimatePresence>
        </div>
    )
}
