import Modal from "../../../../components/Modal/Modal"
import ModalHandler from "../../../../providers/Modal/ModalHandler"

import "./ExpiredSlotModal.scss"

export const ExpiredSlotModal = ModalHandler.create(({ visible, hide }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}

        >
            <header>Le créneau n'est plus disponible</header>
            <section className="noSlotLeft">
                Sélectionne un autre créneau.
            </section>
        </Modal>
    )
})
