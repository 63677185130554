import { object, string } from "prop-types"
import React from "react"

function SchoolCenterTypeDisplay({ type, center, tunnelType, birthdayName }) {

    return (
        <React.Fragment>
            <div className="radioList__item--logo hide-sm">
                <img src={type.pictureUrl}/>
            </div>

            <div className="radioList__item--content">
                <div className="radioList__item--logo--small">
                    <img src={type.pictureUrl}/>
                </div>

                <div className="radioList__item__title">{tunnelType === "BIRTHDAY" ? birthdayName : type.name}</div>
                {
                    tunnelType !== "BIRTHDAY" &&
                        <div className="radioList__item__title">{center.name}</div>
                }
                <div className="radioList__item__desc">
                    <p dangerouslySetInnerHTML={{ __html: type.description }}></p>
                </div>
            </div>
        </React.Fragment>
    )
}

SchoolCenterTypeDisplay.propTypes = {
    birthdayName: string,
    center: object,
    tunnelType: string,
    type: object,
}

export default SchoolCenterTypeDisplay
