import { create } from "zustand"

const initialState = {}

export const useLocationStore = create(set => ({
    ...initialState,
    actionClosePaymentModal: () => set({ paymentModalOpen: false }),
    actionOpenPaymentModal: () => set({ paymentModalOpen: true }),
    ads: null,
    clearStore: () => set(() => initialState),
    currentLocation: {},
    openInvitationPopin: false,
    paymentModalOpen: false,
    setAds: ads => set({ ads }),
    setCurrentLocation: currentLocation => set({ currentLocation }),
    setLocationComposition: locationComposition => set(state => ({
        currentLocation: {
            ...state.currentLocation,
            composition: locationComposition,
        },
    })),
}))
