import months from "../../model/enums/months"
import formatTwoDigits from "../../services/formatTwoDigits"

function DateDisplay({ stringDate, slash, slashDisplay, displayTime = true }) {
    let date
    let hour
    let minutes
    let day
    let month
    let year

    if (stringDate) {
        if (stringDate.includes("T")) {
            let dateHour = stringDate.split("T")
            date = dateHour[0]
            hour = dateHour[1]
    
            date = date.split("-").reverse()
            day = date[0]
            month = date[1]
            year = date[2]
            hour = hour.split(":")
            minutes = hour[1]
            hour = hour[0]

            if (slashDisplay) {
                return (
                    formatTwoDigits(day) + "/" + formatTwoDigits(month) + "/" + year
                )
            }
            return (
                (slash ?
                    `${day}/${month}/${year}`
                    :
                    day + " " + months[parseInt(month)] + " " + year
                ) +
                (displayTime ? 
                    " à " + hour + "h" + minutes
                    :
                    ""
                )
            )
        }
        return stringDate
    } else {
        return "DateDisplay : String Date is undefined"
    }
}

export default DateDisplay
