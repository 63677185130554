import { arrayOf, bool, node, number, oneOfType, string } from "prop-types"
import React from "react"

import Tooltip from "../../entries/main/../../components/tooltip/Tooltip"
import useMediaPath from "../../hooks/useMediaPath"
import ActivationTrigger from "../Buttons/Triggers/ActivationTrigger"
import DateDisplay from "../Time/DateDisplay"

import "./StatusDisplay.scss"

function StatusDisplay({ id, status, statusValue, preRegistrationLimitDate, isFamiliarity, licenseNumberMissing }) {
    const mediaPath = useMediaPath()
    return (
        <React.Fragment>
            {status}
            <div className={"flex"}>
                { statusValue === 2 &&
                    (<div className="infoBulle statusDisplay">
                        <ActivationTrigger id={"tooltip-preregistration-" + id} cls='mini' target={"#tooltipPreregistration-" + id}>

                            <img src={
                                mediaPath([
                                    "/assets/icons/notifications.svg",
                                    "/assets/images/padel/icons/icons-notifications.svg",
                                ],
                                )} />

                        </ActivationTrigger>
                        <Tooltip id={"tooltipPreregistration-" + id}>
                            {isFamiliarity ? "Tu as" : "Vous avez"} jusqu'au {
                                <DateDisplay stringDate={preRegistrationLimitDate}/>
                            } pour finaliser l'inscription. Au-delà de cette date, la pre-inscription sera définitivement annulée.
                        </Tooltip>
                    </div>)
                }
                { licenseNumberMissing &&
                    (<div className="infoBulle licenseNumberMissing">
                        <ActivationTrigger id={"tooltip-licenseNumberMissing-" + id} cls='mini' target={"#tooltipLicenseNumberMissing-" + id}>

                            <img
                                src={mediaPath([
                                    "/assets/icons/notifications.svg",
                                    "/assets/images/padel/icons/icons-notifications.svg",
                                ])}
                            />

                        </ActivationTrigger>
                        <Tooltip id={"tooltipLicenseNumberMissing-" + id}>
                            Le numéro de licence de l'un des joueurs est manquant, il est obligatoire pour participer au tournoi.
                        </Tooltip>
                    </div>)
                }
            </div>
        </React.Fragment>
    )
}

StatusDisplay.propTypes = {
    id: oneOfType([ string, number ]),
    isFamiliarity: bool,
    licenseNumberMissing: bool,
    preRegistrationLimitDate: string,
    status: oneOfType([ string, node, arrayOf(node) ]),
    statusValue: number,
}

export default StatusDisplay
