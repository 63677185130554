import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { generatePayzenSplitPaymentTokenLink } from "../../../../globalAPI/api"
import UserAgentHandler from "../../../../services/UserAgentHandler"
import { openConfirmationOverlay } from "../../../../store/actions/content/index"
import _OverlayContentComponent from "../_OverlayContentComponent"

class splitPaymentLinkContent extends _OverlayContentComponent
{

    constructor(props) {
        super(props)
        this.state = {
            amount: "",
            dataValidity: false,
            tokenLink: null,
            tokenLinkCopied: false,
        }
        if (!props.resourceId || !props.resourceType || !props.remainder) {
            throw new Error("missing resourceId or resourceType")
        }
        this.onChangeRadio = this.onChangeRadio.bind(this)
        this.onChangeAmount = this.onChangeAmount.bind(this)
        this.generatePaymentLink = this.generatePaymentLink.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
    }

    getName() {
        return "split-payment-link"
    }

    getTitle() {
        return this.showHumanResourceType()
    }

    generatePaymentLink() {
        let data = {
            amount: this.state.amount,
            resourceId: this.props.resourceId,
            resourceType: this.props.resourceType.toUpperCase(),
            tokenBaseUrl: import.meta.env.VITE_URL_BASE + "/", // add / for mobile app purpose
        }
        generatePayzenSplitPaymentTokenLink(data).then((result) => {
            this.setState({
                amount: "",
                tokenLink: {
                    url: result.url,
                    value: result.value,
                },
            })
        })
    }

    copyToClipboard() {
        var el = document.createElement("input")

        document.body.appendChild(el)
        el.value = this.state.tokenLink.url

        if (UserAgentHandler.isIOS()) {
            el.contentEditable = true
            el.readOnly = false
            var range = document.createRange()
            range.selectNodeContents(el)
            var s = window.getSelection()
            s.removeAllRanges()
            s.addRange(range)
            el.setSelectionRange(0, 999999)
        } else {
            el.select()
        }

        try {
            document.execCommand("copy", false)
        } catch (err) {
            alert("unable to copy")
        }

        document.body.removeChild(el)
        let tokencopyconfirmationElem = this.refs["tokencopyconfirmation"]
        tokencopyconfirmationElem.style.visibility = "visible"
        tokencopyconfirmationElem.style.opacity = 1
        setTimeout(
            () => {
                tokencopyconfirmationElem.style.visibility = "hidden"
                tokencopyconfirmationElem.style.opacity = 0
            },
            2500,
        )
    }

    showHumanResourceType() {
        switch (this.state.resourceType) {
        case "bill":
            return "FACTURE"
        default:
            return "PAIEMENT"
        }
    }

    onChangeAmount(e) {
        let ref = e.currentTarget.getAttribute("data-ref")
        let newState = {}

        if (ref) {
            // let value = parseFloat(e.currentTarget.value);
            let value = e.currentTarget.value
            let lastChar = value.charAt(value.length - 1)
            if ([ ".","," ].indexOf(lastChar) === -1) {
                value = parseFloat(value)
            } else {
                value = value.replace(",",".")
            }

            if (isNaN(value)) {
                value = ""
            }
            newState[ref] = value
            if (value > 0.00 && value <= this.props.remainder) {
                newState.dataValidity = true
                if (this.state.amount === "") {
                    newState.tokenLink = null
                }
            } else {
                newState.dataValidity = false
            }
        }
        this.setState(newState)
    }

    onChangeRadio(e)
    {
        let newState = {}
        let realRef = e.currentTarget.getAttribute("data-parentref")
        let value = e.currentTarget.value
        newState[realRef] = value

        if (realRef === "useAliasToken") {
            this.toggleUseAliasToken(value)
        }
    }

    checkIfMobile() {
        return (
            (
                window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth
            ) <= 640
        )
    }

    preRenderDesktopContent() {
        return this.preRenderContent()
    }

    renderTokenLinkPreview() {
        if (this.state.tokenLink !== null) {
            return <div className="link-area">{"..." + this.state.tokenLink.url.substr(30,40) + "... (valeur : " + this.state.tokenLink.value + "€)"}</div>
        }
        return ""
    }
    renderAvailableAction() {
        let submitButtonClass = this.props.ajaxCalling !== true ? "button-orange fifty-width post-form" : "button-orange fifty-width post-form hidden"
        let generateDisabled = this.state.dataValidity ? false : true
        if (this.state.tokenLink) {
            return (<button type="submit"
                disabled={generateDisabled}
                className="button-orange fifty-width"
                onClick={this.copyToClipboard}
            >Copier le lien de partage</button>)
        } else {
            return (<button type="submit"
                disabled={generateDisabled}
                className={submitButtonClass}
                onClick={this.generatePaymentLink}
            >Générer</button>)
        }
    }

    preRenderContent()
    {
        return (
            <div className="page-content cb-form">
                <div>
                    <div className="white">
                        <div className="text-center">Indique ci-dessous le montant du lien de partage à générer</div>
                        <div className="text-center margin-top-line">(Restant dû :  <span className="weight-bold orange">{this.props.remainder}€</span>)</div>
                    </div>
                    <div className="align-flex">
                        <input
                            // eslint-disable-next-line react/no-string-refs
                            type={this.checkIfMobile ? "number" : "text"} ref="amount" data-ref="amount" placeholder="Montant *" className="amount text-center" onChange={this.onChangeAmount} value={this.state.amount}
                        />
                        {/*{this.renderLargeInput('amount', 'Montant *', true, null, false, this.onChangeAmount,'', false, false)}*/}
                    </div>
                    <div className="token-links-container white">
                        {this.renderTokenLinkPreview()}
                    </div>
                </div>
                <div
                    // eslint-disable-next-line react/no-string-refs
                    className="token-copy-confirmation width100pct text-center" ref="tokencopyconfirmation"
                >
                    Lien copié.
                </div>
                <div className="row align-flex">
                    {this.props.ajaxCalling === true &&
                        this._renderPreloader()
                    }
                    {/*<button type="submit" className="button-orange fifty-width post-form">Enregistrer</button>*/}
                    {this.renderAvailableAction()}
                </div>
                <div className="row">
                    <div className="text-center">
                        <span className="micro-font-size white">*Champs obligatoires</span>
                    </div>
                </div>
            </div>
        )
    }
}

// CONNECT THE STORE (DATA) TO THE COMPONENT
const stateToProps = (state) => {
    return {
        ..._OverlayContentComponent.stateToProps(state),
        ...{
            ajaxCalling: state.ajaxCalling,
            datacache: state.datacache,
        },
    }
}

// BIND ACTIONS TO CONTAINER
const dispatchToProps = (dispatch) => {
    let properActions = {
        openConfirmationOverlay: openConfirmationOverlay,
    }
    let mergedActions = {
        ... properActions,
        ... _OverlayContentComponent.dispatchToProps(),
    }
    return bindActionCreators(
        mergedActions
        ,
        dispatch,
    )
}

export default connect(stateToProps, dispatchToProps)(splitPaymentLinkContent)
