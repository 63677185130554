import { bool, func, node, oneOfType, string } from "prop-types"

import "./Radio.scss"

const RadioInput = ({ name, value, label, checked, onChange, onClick, disabled }) => {
    return (
        <label className="radioInput">
            {label}
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                onClick={onClick}
                disabled={disabled}
            />
        </label>
    )
}

RadioInput.propTypes = {
    checked: bool.isRequired,
    disabled: bool,
    label: oneOfType([ string.isRequired, node.isRequired ]),
    name: string.isRequired,
    onChange: func.isRequired,
    onClick: func.isRequired,
    value: string.isRequired,
}
  
export default RadioInput
