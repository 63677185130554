import { useEffect, useState } from "react"

function useIsPaymentEnabled(tunnelType, paymentEnabled, representantLegalChecked) {
    const [ isPaymentEnabled, setIsPaymentEnabled ] = useState(false)

    useEffect(
        () => {
            if (tunnelType === "TRAINING") {
                setIsPaymentEnabled(paymentEnabled && representantLegalChecked)
            } else {
                setIsPaymentEnabled(paymentEnabled)
            }
        },[ paymentEnabled, representantLegalChecked ],
    )

    return isPaymentEnabled
}

export default useIsPaymentEnabled
