import { useEffect, useState } from "react"

import { useTheme } from "../components/ThemeContext/ThemeContext"

function useThemeHeader() {
    const theme = useTheme()
    const [ themeHeader, setThemeHeader ] = useState(() => {
        switch (theme) {
        case "theme-soccer":
            return 1
        case "theme-business":
            return 3
        default:
            return 2
        }
    })

    useEffect(() => {
        switch (theme) {
        case "theme-soccer":
            setThemeHeader(1)
            break
        case "theme-business":
            setThemeHeader(3)
            break
        default:
            setThemeHeader(2)
        }
    }, [ theme ])

    return themeHeader
}

export default useThemeHeader
