import dayjs from "dayjs"

import Modal from "../../../../../components/Modal/Modal.jsx"
import { getMedicalCertificateStatus } from "../../../../../components/reservation/medicalCertificateText.js"
import useThemeFeatures from "../../../../../hooks/useThemeFeatures.js"
import childOutfitSizes from "../../../../../model/enums/childOutfitSizes.js"
import ModalHandler from "../../../../../providers/Modal/ModalHandler.jsx"
import { displayCenterName } from "../../../../../services/displayCenterName.js"
import { popUrl } from "../../../../../services/routingManagment.js"

export const StageListingElementPopIn = ModalHandler.create(({ visible, hide, stageRegistration }) => {
    const themeFeature = useThemeFeatures()
    let certificateStatus = getMedicalCertificateStatus(stageRegistration)

    const renderCatalogArticles = () => {
        return stageRegistration.catalogArticles.map((catalogArticle, index) => {
            return (
                <div className="catalog-article" key={"ca-" + index}>
                    <img src={catalogArticle.product.pictureUrl} />
                    <div>
                        Qté : <span>{catalogArticle.quantity}</span>
                    </div>
                </div>
            )
        })
    }

    const renderOptionProducts = () => {
        return stageRegistration.optionProducts.map((optionProduct, index) => {
            return (
                <li key={"option-" + index}>
                    {optionProduct.name}
                </li>
            )
        })
    }
    const popin = (
        <>
            <header> Détails inscription </header>
            <article>
                <section className="details">
                    <div>
                        <div className="header">Stage de Foot</div>
                        <strong>{stageRegistration.training.type}</strong>
                        <div>{displayCenterName(stageRegistration.training.centerId ,stageRegistration.training.centerName)}</div>
                        <div>
                            <strong>Date : </strong>
                            <span>Du {stageRegistration.training.startDate} au {stageRegistration.training.endDate}</span>
                        </div>
                        {stageRegistration.kid && (
                            <><div>
                                <strong>Enfant : </strong>
                                {stageRegistration.kid.firstName + " " + stageRegistration.kid.lastName}
                                <span> - </span>
                                <span>{stageRegistration.kid.birthDate}</span>
                            </div>
                            {stageRegistration.outfitSizeValue &&
                                    (<div>
                                        <strong>Taille équipement : </strong>
                                        {
                                            childOutfitSizes[
                                                stageRegistration.outfitSizeValue
                                            ].short
                                        }
                                    </div>)
                            }

                            <div>
                                <strong>Certificat médical : </strong>
                                {certificateStatus}
                            </div>
                            </>
                        )}
                    </div>

                </section>

                <section className="section-contact-urgence">
                    <div className="header">Contact en cas d'urgence</div>
                    <div>
                        <div className="contact-strong">CONTACT PRINCIPAL</div>
                        <div>{stageRegistration.urgencyContact1.firstName} {stageRegistration.urgencyContact1.lastName}</div>
                        <div>{stageRegistration.urgencyContact1.phone}</div>
                        <div>{stageRegistration.urgencyContact1.email}</div>
                    </div>
                    {
                        (stageRegistration.urgencyContact2?.firstName) &&
                        (<div className="">
                            <div className="contact-strong">CONTACT SECONDAIRE</div>
                            <div>{stageRegistration.urgencyContact2.firstName} {stageRegistration.urgencyContact2.lastName}</div>
                            <div>{stageRegistration.urgencyContact2.phone}</div>
                            <div>{stageRegistration.urgencyContact2.email}</div>
                        </div>)
                    }

                </section>

                { (stageRegistration.optionProducts?.length > 0) &&
                    (<section>
                        <div className="header">Options facultatives :</div>
                        <ul>
                            {renderOptionProducts()}
                        </ul>
                    </section>)
                }

                {
                    (stageRegistration.catalogArticles?.length > 0) &&
                    (<section>
                        <div className="header">OPTION ÉQUIPEMENTS</div>
                        <div className="catalogArticlesDiv">
                            {renderCatalogArticles()}
                        </div>
                    </section>)
                }

                <section className="trainingComment">
                    <div className="header">Commentaires</div>
                    {
                        stageRegistration.informations?.length > 0 &&
                        (<div className="comment">
                            {stageRegistration.informations}
                        </div>)
                    }
                    {
                        (!stageRegistration.informations || stageRegistration.informations?.length === 0) &&
                        <div>Aucun commentaire pour cette inscription</div>
                    }

                </section>
            </article>
        </>
    )

    const padelPopin = (
        <>
            <header> Détails inscription </header>
            <article>
                <section className="details">
                    <div>
                        <strong>{stageRegistration.training.name}
                        </strong>
                        <div> {stageRegistration.training.centerName}</div>
                        <div>{stageRegistration.training.type}</div>
                        <div>
                            Date : Du {dayjs(stageRegistration.training.start).format("DD/MM/YY")} au {dayjs(stageRegistration.training.end).format("DD/MM/YY")}
                        </div>
                        {stageRegistration.kid && (
                            <>
                                <div>
                                    <span>Enfant : </span>
                                    {stageRegistration.kid.firstName + " " + stageRegistration.kid.lastName}
                                    <span> - </span>
                                    <span>{stageRegistration.kid.birthDate}</span>
                                </div>

                                <div>
                                    <span>Certificat médical : </span>
                                    {certificateStatus}
                                </div>

                                <section className="section-contact-urgence">
                                    <div className="header">Contact en cas d'urgence :</div>
                                    <div className="firstContact">
                                        <div className="title">Contact principal :</div>
                                        <div>{stageRegistration.urgencyContact1.firstName} {stageRegistration.urgencyContact1.lastName}</div>
                                        <div>{stageRegistration.urgencyContact1.phone}</div>
                                        <div>{stageRegistration.urgencyContact1.email}</div>
                                    </div>
                                    {stageRegistration.urgencyContact2?.firstName && (
                                        <div className="secondContact">
                                            <div className="title">Contact secondaire :</div>
                                            <div>{stageRegistration.urgencyContact2.firstName} {stageRegistration.urgencyContact2.lastName}</div>
                                            <div>{stageRegistration.urgencyContact2.phone}</div>
                                            <div>{stageRegistration.urgencyContact2.email}</div>
                                        </div>
                                    )}
                                </section>
                            </>
                        )}
                    </div>
                    {stageRegistration.optionProducts?.length > 0 && (
                        <div>
                            <strong>Mes options :</strong>
                            <div>
                                {stageRegistration.optionProducts.map((option, index) => (
                                    <div key={"option-" + index}>
                                        {option.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </section>

                {stageRegistration.catalogArticles?.length > 0 && (
                    <section>
                        <div className="header">Mes équipements :</div>
                        <div className="catalogArticlesDiv">
                            {renderCatalogArticles()}
                        </div>
                    </section>
                )}
                {stageRegistration.kid && (<section className="comment">
                    <div className="header">Commentaires</div>
                    {
                        stageRegistration.informations?.length > 0 &&
                            (<div className="comment">
                                {stageRegistration.informations}
                            </div>)
                    }
                    {
                        (!stageRegistration.informations || stageRegistration.informations?.length === 0) &&
                            <div>Aucun commentaire pour cette inscription</div>
                    }

                </section>
                )}</article>
        </>
    )

    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            beforeClose={popUrl}
            key={stageRegistration.id}
            className={"schoolTrainingListingPopIn"}
        >
            {themeFeature([ popin, padelPopin ])}
        </Modal>
    )
})
