export default function goalAlphabeticalPlayerSort(a, b) {
    const aGoals = a.goals
    const bGoals = b.goals
    const aFirstname = a.firstname ? a.firstname.trim() : ""
    const bFirstname = b.firstname ? b.firstname.trim() : ""
    const aLastname = a.lastname ? a.lastname.trim() : ""
    const bLastname = b.lastname ? b.lastname.trim() : ""

    if (aGoals === bGoals) {
        if (aFirstname === bFirstname) {
            if (aLastname === bLastname) {
                return 0
            }
            else if (aLastname > bLastname) {
                return 1
            } else {
                return -1
            }
        } else if (aFirstname > bFirstname) {
            return 1
        } else {
            return -1
        }
    } else if (aGoals > bGoals) {
        return -1
    } else {
        return 1
    }
}
