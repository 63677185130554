import useMediaPath from "../../hooks/useMediaPath"
import ButtonCta from "../Buttons/button/button-cta"
import { useTheme } from "../ThemeContext/ThemeContext"

import "./notFound.scss"

function NotFound() {
    const mediaPath = useMediaPath()
    const theme = useTheme()
    return (
        <div className="notFound__wrapper">
            <div className="notFound__content">
                <img src={mediaPath([ "/assets/images/Hors-Jeu-O.svg", "/assets/images/padel/Hors-Jeu-red.svg" ])}/>
                <h1>Erreur 404</h1>
                <p>
                    L'adresse demandée ne correspond à aucune page ou ressource existante.<br/>
                    <br/>
                    Tu peux utiliser le menu pour retrouver ton chemin ou cliquer sur ce bouton :
                </p>
                <ButtonCta 
                    isAlt={true}
                    text="Retour à l'accueil"
                    link={`/?theme=${theme}`}
                />
            </div>
        </div>
    )
}

export default NotFound
