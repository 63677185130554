import { bool, func, object, oneOfType, string } from "prop-types"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../../../../hooks/useMediaPath"

export default function BillSubscriptionCancel({ freeCancelDate, cancelReservation, goToCreateSuperSub, location, openReport }) {
    const { tu } = useUrbanLanguage()
    const mediaPath = useMediaPath()
    return (
        <div className="blocAnnuler">
            <div className="title">{freeCancelDate ? "REPORT" : "ANNULATION"}</div>
            {
                freeCancelDate ?
                    (<div className="annulationGratuite">
                        <img src={mediaPath([ "/assets/icons/calendar-orange.svg" , "/assets/images/padel/icons/icon-calendar-green.svg" ])} className="calendarIcon" />
                        <div className="freeCancel">
                            <p>Demande de report possible jusqu'au :</p>
                            <strong>{freeCancelDate}</strong>
                        </div>
                    </div>)
                    :
                    (<div className="annulationPayante">
                        <img src={mediaPath([ "/assets/icons/icon--warning.svg" , "/assets/images/padel/icons/icon--warning-red.svg" ])} className="calendarIcon" />
                        <p>
                            Tu es hors délai pour annuler cette session.<br />
                            <strong>Si tu annules ta session, elle sera perdue.</strong>
                        </p>
                    </div>)
            }

            <div className="btnsDiv">
                <ButtonCta
                    text={freeCancelDate ? "Demander un report" : "Annuler ma session"}
                    onClick={freeCancelDate ? openReport : cancelReservation}
                    // link={freeCancelDate ? "https://www.urbansoccer.fr/contact/2" : undefined}
                    isCta2Alt
                />
                {location.isSupersubRequestAvailable && !freeCancelDate &&
                    (<div className="missingPlayers" onClick={() => {
                        goToCreateSuperSub()
                    }} >
                        Il te manque des joueurs ? <span>{tu("booking.supersub.thinkAbout")}</span>
                    </div>)
                }

            </div>
        </div>
    )
}

BillSubscriptionCancel.propTypes = {
    cancelReservation: func,
    freeCancelDate: oneOfType([ bool, string ]),
    goToCreateSuperSub: func,
    location: object,
    openReport: func,
}
