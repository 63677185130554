import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import { generatePayzenPaymentReqs } from "../../../../globalAPI/api"
import { editUserSessionMeta, openConfirmationOverlay } from "../../../../store/actions/content/index"
import _OverlayContentComponent from "../_OverlayContentComponent"

class payzenCbPaymentContent extends _OverlayContentComponent
{

    constructor(props) {
        super(props)
        this.state = {
            amount: props.amount,
            dataValidity: false,
            directCard: {},
            directOwner: "",
            freeAmount: "",
            navigate: props.navigate,
            orderInfo: props.orderInfo,
            payment3DS: {},
            paymentType: props.paymentType,
            registeredCard: props.card ? {
                ...props.card,
                expiryMonth: props.card.tokenDetails?.expiryMonth ?? "",
                expiryYear: props.card.tokenDetails?.expiryYear ?? "",
                owner: (props.card.customer?.billingDetails?.firstName + " " + props.card.customer?.billingDetails?.lastName) || "",
                pan: props.card.tokenDetails?.pan ?? "",
            } : {},
            reloaded: props.reloaded,
            resourceId: props.resourceId,
            returnUrl: props.returnUrl,
        }
        if (props.card) {
            this.state.useAliasToken = Object.keys(props.card).length > 0 ? "true" : "false"
        }
        this.onChangeRadio = this.onChangeRadio.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onChangeFreeAmount = this.onChangeFreeAmount.bind(this)
        this.onChangeCardDataRadio = this.onChangeCardDataRadio.bind(this)
        this.formatCardNumber = this.formatCardNumber.bind(this)
        this.handleAutoTab = this.handleAutoTab.bind(this)
        this.showHumanPaymentType = this.showHumanPaymentType.bind(this)
        this.submitPayment = this.submitPayment.bind(this)
    }

    getName() {
        return "payzen-cb-payment"
    }

    getTitle() {
        return this.preRenderUnderlineTitle()
    }

    componentDidMount() {
        this.toggleUseAliasToken(this.state.useAliasToken)
    }

    // componentDidUpdate(prevProps, prevState)
    // {
    //   if (this.state.useAliasToken==='false') {
    //     this.validateData();
    //   }
    // }

    showHumanPaymentType() {
        switch (this.state.paymentType) {
        case "bill":
            return "FACTURE"
        case "debt ":
            return "ARDOISE"
        default:
            return "PAIEMENT"
        }
    }

    submitPayment()
    {
        let data = {
            amount:           this.props.splitable === true ? this.state.freeAmount : this.state.amount,
            askRegisterPay: this.state.useAliasToken,
            isUsingUserCB: this.state.useAliasToken,
            resourceId:       this.state.resourceId,
            serviceType:      this.state.paymentType.toUpperCase(),
            vads_order_info:  this.state.orderInfo,
            vads_return_url:  this.state.returnUrl,
        }
        if (this.state.useAliasToken === "true") {
            data.withAlias = true
        } else {
            // let regex = /^([a-zA-ZéèêÉÈÊ\-]+) ([a-zA-ZéèêÉÈÊ\-]+)$/;
            // let match = this.state.directOwner.match(regex);
            // data.vads_cust_first_name = match[1];
            // data.vads_cust_last_name = match[2];
        }
        if (this.props.splitToken) {
            data.shareToken = this.props.splitToken
        }
        generatePayzenPaymentReqs(data).then((result) => {
            let returnUrl = this.state.returnUrl
            const lyraPaymentUrl = this.props.path(`/profil/payment/splitPayment/${data.shareToken}`)
            if (result) {
                if (result.ipn) {
                    returnUrl += "&ipn=" + encodeURIComponent("/api/" + result.ipn.split("/US-API/myurban/")[1])
                }

                this.props.toggleLevel3Overlay(false,"payzenCbPayment", {})
                this.props.navigate(lyraPaymentUrl, { state: { lyraEmbedParams: { ...result, returnUrl } } })
            }
            this.props.editUserSessionMeta("beforePayzenForm", true)
        })
    }

    handleAutoTab(currentTargetElement)
    {
        let maxLength = currentTargetElement.maxLength
        if (maxLength) {
            if (currentTargetElement.value.length === maxLength) {
                let refValue = currentTargetElement.getAttribute("data-ref")
                let currentRef = null
                let nextRef = null
                for (var i in this.refs) {
                    if (currentRef) {
                        nextRef = this.refs[i]
                        break
                    }
                    if (i === refValue) {
                        currentRef = this.refs[i]
                    }
                }
                if (nextRef) {
                    nextRef.focus()
                }
            }
        }
        this.validateData()
    }

    onChange(e) {

        let ref = e.currentTarget.getAttribute("data-ref")
        let newState = {}
        newState[ref] = e.currentTarget.value

        this.setState(newState)

        if (ref) {
            this.validateData()
        }
    }

    onChangeFreeAmount(e) {
        let ref = e.currentTarget.getAttribute("data-ref")
        let newState = {}
        let value = e.currentTarget.value
        let lastChar = value.charAt(value.length - 1)
        if ([ ".","," ].indexOf(lastChar) === -1) {
            value = parseFloat(value)
        } else {
            value = value.replace(",",".")
        }
        if (isNaN(value)) {
            value = ""
        }
        newState[ref] = value
        if (parseFloat(value) > 0.00 && parseFloat(value) <= this.props.remainder) {
            newState.dataValidity = true
        } else {
            newState.dataValidity = false
        }
        this.setState(newState)
    }

    onChangeCardDataRadio(e)
    {
        let newState = {}
        let realRef = e.currentTarget.getAttribute("data-parentref")
        let value = e.currentTarget.value
        newState[realRef] = value

        this.setState({
            directCard: {
                ...this.state.directCard,
                ...newState,
            },
        })

        this.validateData()
    }

    toggleUseAliasToken(value)
    {
        this.validateData()

        this.setState({
            dataValidity: this.props.splitable === true ? this.state.dataValidity : true,
            useAliasToken: value,
        })
    }

    checkIfMobile() {
        return (
            (
                window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth
            ) <= 640
        )
    }

    onChangeRadio(e)
    {
        let newState = {}
        let realRef = e.currentTarget.getAttribute("data-parentref")
        let value = e.currentTarget.value
        newState[realRef] = value

        if (realRef === "useAliasToken") {
            this.toggleUseAliasToken(value)
        }
    }

    formatCardNumber(e)
    {
        e.preventDefault()

        let value = e.currentTarget.value.replace(/[^\d ]/g, "")
        let newValue = ""

        if (value) {

            let withoutSpaceValue = value.replace(/ /g, "")
            withoutSpaceValue = withoutSpaceValue.substr(0, 16)
            if (/^\d+$/.test(withoutSpaceValue) === false) {
                return
            }
            let explodedParts = withoutSpaceValue.match(/.{1,4}/g)
            explodedParts.forEach((elem, idx) => {
                if (elem.length === 4) {
                    newValue += elem + (idx + 1 < explodedParts.length ? " " : "")
                    return
                }
                newValue += elem
            })
        } else {
            newValue = value
        }

        this.handleAutoTab(e.currentTarget)

        this.setState({
            directCard: {
                ...{ number: newValue },
            },
        })
    }

    renderLargeInput(ref, placeholder, required, maxLength, isNumeric, onChange, classes)
    {
        classes = classes ? classes + " large" : "large"

        let value = this.state.registeredCard[ref] ? this.state.registeredCard[ref] : ""

        return (<div className="large-input-container">
            <input
                ref={ref}
                required={required}
                data-ref={ref}
                key={ref}
                id={ref}
                type='text'
                autoCapitalize="none"
                maxLength={maxLength}
                minLength={maxLength}
                inputMode={isNumeric === true ? "numeric" : "text"}
                placeholder={placeholder}
                className={classes}
                defaultValue={value}
                disabled
                onChange={onChange}
            />
        </div>)
        
    }

    renderLargeRadioInput(ref, refIndex, value, required, selecterIsImage, selecter, onClick, onChange, classes)
    {
        let realRef = ref + "_" + refIndex
        let checked = false
        if (ref === "useAliasToken") {
            if (this.refs[realRef] !== undefined) {
                checked =  this.refs[realRef].value === this.state.useAliasToken ? true : false
            } else {
                if (this.props.card && Object.keys(this.props.card).length > 0 && value === "true") {
                    checked = true
                }
            }
        } else {
            checked = this.state.directCard[ref] === value ? true : false
        }

        classes = classes ? classes + " large-input-container large-input-radio" : "large-input-container large-input-radio"
        if (selecterIsImage === true) {
            selecter = <label className="align-flex width100pct" htmlFor={realRef}><img src={selecter} /></label>
        } else {
            selecter = <label className="align-flex float-left" htmlFor={realRef}>{selecter}</label>
        }

        return (<div className={classes + (selecterIsImage === true ? " label-image" : " label-text")}>
            {selecter}
            <div className={"text-center" + (selecterIsImage === true ? " width100pct" : "")}>
                <input
                    type='radio'
                    key={realRef}
                    required={required === true ? true : false}
                    name={ref}
                    id={realRef}
                    ref={realRef}
                    data-parentref={ref}
                    value={value}
                    checked={checked}
                    onClick={onClick}
                    onChange={onChange}
                />
            </div>
        </div>)
    }

    validateData()
    {
        let isValid = true
        for (var ref in this.refs) {
            if (ref === "") {
                continue
            }
            // ref==='payzen-payment-form' ||
            if (this.refs[ref].validity === undefined) {
                continue
            }
            if (this.refs[ref].validity.valid === false) {
                isValid = false
                break
            }
        }

        if (isValid !== this.state.dataValidity) {
            this.setState({ dataValidity: isValid })
        }
    }

    renderPayzenVadsInput(name,value) {
        return (<input
            type="hidden"
            ref={name}
            name={name}
            value={value}
        />)
    }

    preRenderDesktopContent() {
        return this.preRenderContent()
    }

    preRenderUnderlineTitle() {
        if (this.props.splitToken) {
            return "Régler " + this.props.amount + "€ sur la " + this.showHumanPaymentType() + " #" + this.props.resourceId
        }
        return this.showHumanPaymentType()
    }

    preRenderContent()
    {
        let submitButtonClass = this.props.ajaxCalling !== true ? "fifty-width post-form" : "fifty-width post-form hidden"
        return (
            <div className={"page-content cb-form" + (this.props.splitable === true ? " splitable" : "")}>
                {Object.keys(this.state.registeredCard).length > 0 &&
                    (<div className="row white x-small text-center text-info-encart-second align-flex">
                        <div>
                            <div className="float-left">J'utilise ma carte enregistrée :</div>
                            <div className="float-left">{this.renderLargeRadioInput("useAliasToken","0","true",false,false,"Oui",this.onClickRadio, this.onChangeRadio)}</div>
                            <div className="float-left">{this.renderLargeRadioInput("useAliasToken","1","false",false,false,"Non",this.onClickRadio, this.onChangeRadio)}</div>
                        </div>
                    </div>)
                }

                {this.state.useAliasToken === "true" ?
                    (<div>
                        {this.renderLargeInput("owner", "Titulaire *", true, null, false, this.onChange, "")}
                        {this.renderLargeInput("pan", "Numéro *", true, 19, true, this.formatCardNumber, "credit-card-input " + this.state.registeredCard.brand)}
                        <div className="large-input-container date-and-cvv">
                            <div className="float-left">
                                <div className="text-center x-small white weight-bold">Date d'expiration</div>
                                <div className="left">
                                    {this.renderLargeInput("expiryMonth", "Mois *", true, 2, true, this.onChange, "")}
                                </div>
                                <div className="right">
                                    {this.renderLargeInput("expiryYear", "Année *", true, 4, true, this.onChange, "")}
                                </div>
                            </div>
                        </div>
                    </div>)
                    :
                    null
                }

                {this.props.splitable === true &&
                    (<div className="large-input-container free-amount">
                        <div className="white text-center">Définir le montant à régler (restant dû {this.props.remainder}€) :</div>
                        <div className="align-flex">
                            <input
                                type={this.checkIfMobile() ? "number" : "text"}
                                // eslint-disable-next-line react/no-string-refs
                                ref="freeAmount"
                                data-ref="freeAmount"
                                placeholder="Montant *"
                                className="input-large text-center"
                                value={this.state.freeAmount}
                                onChange={this.onChangeFreeAmount}
                            />
                        </div>
                    </div>)
                }

                <div className="row align-flex-vertical">
                    {this.props.ajaxCalling === true &&
            this._renderPreloader()
                    }
                    <ButtonCta
                        isCtaAlt
                        type="submit"
                        disabled={this.state.dataValidity !== true}
                        className={submitButtonClass}
                        onClick={this.submitPayment}
                        text="Régler"
                    >Régler</ButtonCta>
                </div>
                { this.state.useAliasToken === "true" && (
                    <div className="row">
                        <div className="text-center">
                            <span className="micro-font-size white">*Champs obligatoires</span>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

// CONNECT THE STORE (DATA) TO THE COMPONENT
const stateToProps = (state) => {
    return {
        ..._OverlayContentComponent.stateToProps(state),
        ...{
            ajaxCalling: state.ajaxCalling,
            datacache: state.datacache,
        },
    }
}

// BIND ACTIONS TO CONTAINER
const dispatchToProps = (dispatch) => {
    let properActions = {
        editUserSessionMeta: editUserSessionMeta,
        openConfirmationOverlay: openConfirmationOverlay,
    }
    let mergedActions = {
        ... properActions,
        ... _OverlayContentComponent.dispatchToProps(),
    }
    return bindActionCreators(
        mergedActions
        ,
        dispatch,
    )
}

export default connect(stateToProps, dispatchToProps)(payzenCbPaymentContent)
