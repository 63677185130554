import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import CompositionBench from "../../../../components/league/CompositionBench/CompositionBench.jsx"
import CompositionPlayer from "../../../../components/league/CompositionPlayer/CompositionPlayer.jsx"
import AskTeammates from "../../../../components/popin/invitations/askTeammates.jsx"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext.jsx"
import useDebounce from "../../../../hooks/useDebounce.js"
import { useAssets } from "../../../../providers/AssetsProvider.jsx"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import { LeaguePath } from "../../../../routes/ApplicationPaths/LeaguePaths.js"
import { shuffleArray } from "../../../../services/shuffleArray.js"
import { addPlayersToLeagueTeam, editGameComposition } from "../../api/leagueGame.js"
import { isGameForfeit, resolveTeamPJT } from "../../service/service.js"
import { useLeagueStore } from "../../store/useLeagueStore.js"

import "./LeagueComposition.scss"

function LeagueComposition() {
    const {
        currentGame,
        setCurrentGame,
        selectedLeagueTeam,
        gameComposition,
        setGameComposition,
        setShouldRevalidate,
        setSelectedLeagueTeam,
    } = useLeagueStore()
    const { players } = currentGame
    const [ selectedPlayer, setSelectedPlayer ] = useState(null)
    const { debounce } = useDebounce()
    const navigate = useNavigate()
    const userLoaded = useSelector(state => state.userLoaded)
    const theme = useTheme()
    const { m } = useAssets()

    const setPlayers = (players) => {
        setCurrentGame({ ...currentGame, players })
    }

    const fieldPosition = [ 0, 1, 2, 3, 4 ]

    const autoComposition = () => {
        setSelectedPlayer(null)
        const tempPlayers = gameComposition?.map(player => ({ ...player, slotPosition: undefined }))
        const fieldPosition = [ 0, 1, 2, 3, 4 ]
        let newComposition = []
        const formation = "1-1-2-1"

        if (tempPlayers?.length) {
            shuffleArray(fieldPosition)
            const loopTimes = Math.min(5, tempPlayers.length)
            newComposition = Array.from({ length: loopTimes }, () => {
                const randomIndex = Math.floor(Math.random() * tempPlayers.length)
                const [ randomPlayer ] = tempPlayers.splice(randomIndex, 1)
                randomPlayer.slotPosition = fieldPosition.shift()
                return randomPlayer
            })

            setGameComposition([ ...newComposition, ...tempPlayers ])
            debouncedEditGameComposition(currentGame?.id, selectedLeagueTeam?.id, {
                formation,
                players: JSON.stringify(newComposition),
            })
        }
    }

    const handleApiCall = (gameId, teamId, data) => {
        editGameComposition(gameId, teamId, data)
    }

    const debouncedEditGameComposition = (gameId, teamId, data) => debounce(() => handleApiCall(gameId, teamId, data), 2000)

    const handleSelection = (player, position) => {
        const updatedPlayers = [ ...gameComposition ]
        if (!selectedPlayer) {
            setSelectedPlayer(player ? { ...player, slotPosition: position } : { slotPosition: position })
        } else {
            if (selectedPlayer?.id === player?.id) {
                setSelectedPlayer(null)
            } else {
                const selected = updatedPlayers?.find(person => person?.id === selectedPlayer?.id) ?? selectedPlayer
                if (player?.id) {
                    const clicked = updatedPlayers?.find(person => person?.id === player?.id)
                    clicked.slotPosition = selected.slotPosition
                }
                if (selected?.id) {
                    selected.slotPosition = position
                }
            }
            setSelectedPlayer(null)
            setGameComposition(updatedPlayers)
            debouncedEditGameComposition(currentGame?.id, selectedLeagueTeam?.id, {
                players: JSON.stringify(updatedPlayers),
            })
        }
    }

    const moveToBench = player => {
        const updatedPlayers = [ ...gameComposition ]
        const playerToMove = updatedPlayers?.find(person => person?.id === player?.id)
        playerToMove.slotPosition = null
        setPlayers(updatedPlayers)
        setSelectedPlayer(null)
        debouncedEditGameComposition(currentGame?.id, selectedLeagueTeam?.id, {
            players: JSON.stringify(updatedPlayers),
        })
    }

    const openFriendInvitationPopin = () => {
        const hideModal = () => ModalHandler.hide(AskTeammates)
        ModalHandler.show(AskTeammates, {
            alreadySelectedMatesIds: players?.map(player => player.id),
            callback: (e) => {
                addPlayersToLeagueTeam(selectedLeagueTeam?.id, e.join(",")).then(() => {
                    hideModal()
                    setShouldRevalidate(true)
                    navigate(LeaguePath.toLeagueMatchRoster(selectedLeagueTeam?.id, currentGame?.id))
                })
            },
            className: "leagueAskTeammates",
        })
    }

    useEffect(() => {
        if (!gameComposition?.length && players?.length) {
            setGameComposition(players?.filter(player => player.status === 1))
        }
    }, [ currentGame ])

    useEffect(() => {
        if (selectedLeagueTeam?.id) {
            setSelectedLeagueTeam({ ...selectedLeagueTeam, shareLink: resolveTeamPJT(selectedLeagueTeam, theme, userLoaded) })
        }
    }, [ selectedLeagueTeam?.id ])

    const canEdit = currentGame?.canEdit && !isGameForfeit(currentGame)

    return (
        <div className="leagueComposition">
            <div className="fieldWrapper">
                <img src={m("images.league.field")} className="field" />
                <div className="team">
                    {fieldPosition.map(position => {
                        const player = gameComposition?.find(player => player?.slotPosition === position)
                        const key = position + "-" + player?.id

                        return (
                            <CompositionPlayer
                                key={key}
                                theme={theme}
                                player={player}
                                selected={selectedPlayer?.slotPosition === position}
                                action={canEdit ? () => handleSelection(player, position) : undefined}
                                unselect={() => moveToBench(player)}
                            />
                        )
                    })}
                </div>
            </div>
            {!isGameForfeit(currentGame) && (
                <CompositionBench
                    autoComposition={autoComposition}
                    players={gameComposition?.filter(player => !player.slotPosition && player?.slotPosition !== 0)}
                    handleSelection={handleSelection}
                    selected={selectedPlayer}
                    canEdit={canEdit}
                    openInvitationPopin={openFriendInvitationPopin}
                />
            )}
        </div>
    )
}

export default LeagueComposition
