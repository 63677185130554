import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import useShowBanScreen from "./showBanScreen"
import usePath from "../../routes/services/usePath"
import { toggleLevel3Overlay } from "../../store/actions/content"

function useGoToCreateSuperSub(location, amountDue, supersubPlayerPrice) {
    const userLoaded = useSelector(state => state.userLoaded)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = usePath()
    const showBanScreen = useShowBanScreen()

    function closeLevel3Overlay() {
        dispatch(
            toggleLevel3Overlay(
                false,
                "",
                {},
                true,
            ),
        )
    }

    return () => {
        if (userLoaded.supersubBanned && !location.isOwner) {
            showBanScreen("superSub")
            closeLevel3Overlay()
        } else {
            window.scrollTo(0, 0)
            navigate(path("/reservations/locations/" + location?.id + "/searchsupersub"), { state: { amountDue: amountDue, supersubPlayerPrice: supersubPlayerPrice } })
        }
    }
}

export default useGoToCreateSuperSub
