import { useLocation, useNavigate } from "react-router-dom"

import { TeamSwitcherModal } from "./TeamSwitcherModal/TeamSwitcherModal.jsx"
import useMediaPath from "../../../hooks/useMediaPath.js"
import { useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import { TeamPicture } from "../TeamPicture/TeamPicture.jsx"

import "./TeamSwitcher.scss"

export const TeamSwitcher = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const mediaPath = useMediaPath()
    const {
        selectedLeagueTeam,
    } = useLeagueStore()

    const openTeamSwitcherModal = () => {
        ModalHandler.show(TeamSwitcherModal)
    }

    const navigateToTeamDetails = () => {
        if (location.pathname !== LeaguePath.toLeagueTeamDetails(selectedLeagueTeam?.id)) {
            navigate(LeaguePath.toLeagueTeamDetails(selectedLeagueTeam?.id), { state: {
                hasPreviousHistory: true,
            } })
        }
    }

    if (!selectedLeagueTeam?.id) {return null}
    
    return (
        <div className={"team-switcher"}>
            <TeamPicture image={selectedLeagueTeam?.blasonUrl || selectedLeagueTeam?.blazon} size={48} onClick={navigateToTeamDetails}/>
            <div className={"team-switcher-content"}>
                <p data-testid="switchTeam" onClick={navigateToTeamDetails}>
                    {selectedLeagueTeam?.name}
                    <img alt={"ornament arrow"} src={mediaPath([
                        "/assets/icons/league/team-arrow.svg",
                        "/assets/icons/league/team-arrow.svg",
                        "/assets/icons/business/arrow-blue.svg",
                    ])}/>
                </p>
                <span>{selectedLeagueTeam?.leagueName}</span>
            </div>
            <ButtonCta
                text={"changer d'équipe"}
                icon={mediaPath([
                    "/assets/icons/league/team-swap.svg",
                    "/assets/icons/league/team-swap.svg",
                    "/assets/icons/business/team-swap.svg",
                ])}
                hoverChangeIcon={mediaPath([
                    "/assets/icons/league/team-swap-hover.svg",
                    "/assets/icons/league/team-swap-hover.svg",
                    "/assets/icons/business/team-swap-hover.svg",
                ])}
                isAlt
                className={"team-switcher-button"}
                onClick={openTeamSwitcherModal}
            />
        </div>
    )
}
