import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { HoursList, hourOptions } from "../../../../../components/HoursList/HoursList"
import { SelectInput } from "../../../../../components/Inputs/Select/SelectInput.jsx"
import useMediaPath from "../../../../../hooks/useMediaPath"

import "./SlotStep.scss"

export const SlotStep1 = ({ slot, fromBooking }) => {
    const hoursListRef = useRef(null)
    const hours = hourOptions
    const mediaPath = useMediaPath()
    const { control, watch, setValue, trigger, register } = useFormContext()
    const daysOptions = []
    for (let i = 0; i < 7; i++) {
        daysOptions.push({
            label : dayjs().locale(frenchLocaleWithSundayStart).startOf("week").add(i, "day").format("dddd"),
            value: i,
        })
    }

    daysOptions.push(daysOptions.shift())

    const watchDay = parseInt(watch("day"))
    const watchHour = watch("hour")

    useEffect(() => {
        setTimeout(() => {
            // unselect hour if new slot or coming from booking tunnel
            !slot && !fromBooking && setValue("hour", null)
        }, 0)
    }, [ slot, watchDay ])

    useEffect(() => {
        trigger("hour")
    }, [ watchHour ])

    return (
        <div className={"slot-step step-1"} key={"step1"}>
            <div className={"slot-step-header"}>
                <img src={mediaPath([ "/assets/icons/favoriteSlot/step-calendar.svg", "/assets/icons/favoriteSlot/padel/step-calendar.svg" ])} alt={"Step 1"} />
                <h2>Quand ?</h2>
            </div>
            <div className={"step-form-content"}>
                <div>
                    <SelectInput
                        label={"Jour"}
                        id={"day"}
                        required
                        options={daysOptions}
                        {...register("day", { required: true, validate: value => value !== null })}
                    />
                </div>
                <div>
                    <h3>Horaire à partir de :</h3>
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <HoursList
                                setSelectedHour={onChange}
                                hours={hours}
                                selectedHour={value}
                                ref={hoursListRef}
                                defaultScrollToHour={!slot && !fromBooking ? (watchDay === 6 || watchDay === 0 ? hours[3].value : hours[9].value) : watchHour?.value}
                                hideForbidden
                            />
                        )}
                        rules={{ required: true, validate: value => value !== null }}
                        name={"hour"}
                    />
                </div>
            </div>
        </div>
    )
}

export const frenchLocaleWithSundayStart = {
    ...dayjs.Ls.fr,
    weekStart: 0,
}

SlotStep1.propTypes = {
    fromBooking: PropTypes.bool,
    slot: PropTypes.object,
}
