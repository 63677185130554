import { _apiCall } from "../../../backend/axios"

export function changePassword(token, newPassword, theme) {
    return _apiCall(
        "replace/confirm-forgotpassword",
        "PUT",
        {
            "Content-Type": "application/json",
            activity: theme,
        },
        {
            password: newPassword,
            token: token,
        },
    )
}
