import PropTypes from "prop-types"

import "./TeamGameSeries.scss"

export const TeamGameSeries = ({ team }) => {
    const { fivePastGamesResult } = team
    return (
        <div className="gameSeries">
            {
                fivePastGamesResult?.split(",").map((game, index) => <div key={index} className={`circle ${game}`}></div>)
            }
        </div>
    )
}

TeamGameSeries.propTypes = {
    team: PropTypes.shape({
        fivePastGamesResult: PropTypes.string,
    }),
}
