
import { string } from "prop-types"

function PhoneCallHelp({ tunnelType }) {
    let phoneNumber
    switch (tunnelType) {
    case "BIRTHDAY":
        phoneNumber = "0 806 23 00 24"
        break
    case "SCHOOL":
        phoneNumber = "0 806 23 00 24"
        break
    case "TRAINING":
        phoneNumber = "0 806 23 00 24"
        break
    case "CUP":
        phoneNumber = "0 806 23 00 24"
        break
        
    case "LEAGUE":
        phoneNumber = "0 806 23 00 24"
        break

    case "SUPERSUB":
        phoneNumber = "0 806 23 00 24"
        break

    case "RESERVATION":
        phoneNumber = "0 806 23 00 24"
        break

    case "BOOKING":
        phoneNumber = "0 806 23 00 24"
        break
    }
    return (
        <p>
            En cas de besoin n'hésite pas à nous joindre au <br/>
            <strong>{phoneNumber}</strong>
        </p>
    )
}

PhoneCallHelp.propTypes = {
    tunnelType: string,
}

export default PhoneCallHelp
