import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { createSearchParams, useLocation, useNavigate } from "react-router-dom"
import { useShallow } from "zustand/react/shallow"

import { AgeCheckModal } from "./AgeCheckModal.jsx"
import { BookableItem } from "./BookableItem/BookableItem"
import { hourOptions } from "../../../../../components/HoursList/HoursList.jsx"
import Preloader from "../../../../../components/loaders/preloader/preloader"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage"
import useDeepCompareEffect from "../../../../../hooks/useDeepCompareEffect"
import useThemeHeader from "../../../../../hooks/useThemeHeader"
import locationHours from "../../../../../model/data/reservation/locationHours.js"
import ModalHandler from "../../../../../providers/Modal/ModalHandler"
import usePath from "../../../../../routes/services/usePath"
import { editUser } from "../../../../profil/api.js"
import { checkAvailabilities, extendPreBookValidity, getPreBook, preBook } from "../../api"
import { deleteDoubleDiscountCode, getDiscountCode } from "../../function"
import durationSort from "../../services/function/durationSort"
import hoursSort from "../../services/function/hoursSort"
import { ExpiredSlotModal } from "../ExpiredSlotModal"
import {
    attemptToRecoversPreBookSlots,
    createFakeSlot,
    durationNumber,
    durationString,
    formArgs,
    handleError,
} from "../services/function"
import { useBookingStore } from "../services/store"

import "./BookableListing.scss"

export const BookableListing = () => {
    const userLoaded = useSelector(state => state.userLoaded)
    const { watch, getValues, setValue } = useFormContext()
    const [ bookableListing, setBookableListing ] = useState([])
    const [ bookablesDisplay, setBookablesDisplay ] = useState([])
    const [ expectedSlot, setExpectedSlot ] = useState(null)
    const [ slotVisible, setSlotVisible ] = useState(false)
    const [ forceDisableFakedSlot, setForceDisableFakedSlot ] = useState(false)
    const [ fromDashboard, setFromDashboard ] = useState(false)
    const [ activeRequests, setActiveRequests ] = useState(0)
    const [ inMemoryData, setInMemoryData ] = useState(null)
    const themeHeader = useThemeHeader()
    const isListingLoading = activeRequests > 0

    const {
        setAvailableDiscounts,
        setError,
        error,
        setPreReservation,
        setOnlyOneRemaining,
        setIsLoading,
        setBookableDisplayLength,
        setBookableListingLength,
        setFromOrderSummary,
        setSummaryData,
        selectedSlot,
        isLoading,
        fromOrderSummary,
        summaryData,
        setSavedFilters,
        availableDiscounts,
        shouldTriggerSlotNotification,
    } = useBookingStore()

    const bookingInfosData = useBookingStore(useShallow(state => state.bookingInfosData))
    const { tu } = useUrbanLanguage()
    const navigate = useNavigate()
    const location = useLocation()
    const path = usePath()
    const preReservation = summaryData?.preReservation

    const [
        centerId,
        activityId,
        selectedDate,
        hourFilter,
        durationFilter,
        typeFilter,
        timeFilter,
        typeIds,
        slotId,
    ] = watch([
        formArgs.CENTER_ID,
        formArgs.ACTIVITY_ID,
        formArgs.DATE,
        formArgs.HOUR,
        formArgs.DURATION,
        formArgs.TYPE,
        formArgs.TIME_FILTER,
        formArgs.TYPE_IDS,
        formArgs.SLOT_ID,
    ])

    const types = bookingInfosData?.centerOptions?.find(
        center => center[0] === parseInt(centerId),
    )?.types?.filter(type => type?.categories[0]?.id === parseInt(activityId))

    useEffect(() => {
        if (JSON.stringify(inMemoryData) === (JSON.stringify({
            activities: bookingInfosData?.centerActivities || [],
            activityId,
            centerId,
            selectedDate,
        })) && !fromOrderSummary) {return}

        setInMemoryData({
            activities: bookingInfosData?.centerActivities,
            activityId,
            centerId,
            selectedDate,
        })

        // FETCH THE AVAILABILITIES WETHER THE USER CHANGES THE DATE, THE ACTIVITY OR THE CENTER
        if (!centerId
            || !activityId
            || !selectedDate
            || !bookingInfosData?.centerActivities?.length > 0
        ) {
            setBookableListing([])
            return
        }

        setActiveRequests(prev => prev + 1)
        setIsLoading(true)
        setFromOrderSummary(false)

        const checkAvailabilitiesData = {
            categories: JSON.stringify([ parseInt(activityId) ]),
            centerId,
            periodStart: selectedDate && dayjs(selectedDate).format("YYYY-MM-DDT01:00:00"),
        }

        setBookableListing([])
        setAvailableDiscounts([])

        if (summaryData?.bookablesListing?.length > 0) {
            setBookableListing(summaryData.bookablesListing)
            setAvailableDiscounts(summaryData?.availableDiscounts || [])
            setActiveRequests(0)
            setSummaryData({
                ...summaryData,
                availableDiscounts: [],
                bookablesListing: [],
            })
            return
        }

        checkAvailabilities(checkAvailabilitiesData, themeHeader).then((res) => {
            if (res && res.length > 0 && res[1]) {
                const errorMessage = handleError(res[1], selectedDate, tu)
                if (errorMessage) {
                    setIsLoading(false)
                    setError(errorMessage)
                } else {
                    const data = res[1]
                    const availableDiscounts = deleteDoubleDiscountCode(getDiscountCode(data))
                    setError(null)

                    if (preReservation) {
                        const recoveredSlot = attemptToRecoversPreBookSlots(data, availableDiscounts, preReservation, activityId, selectedDate, centerId)
                        if (!recoveredSlot) {
                            setPreReservation(null)
                        } else if (recoveredSlot?.start) {
                            data.push(recoveredSlot)
                        }
                    }

                    setBookableListing(formatBookablesForDisplay(data))
                    setAvailableDiscounts(availableDiscounts)

                    if (!data.length) {
                        setIsLoading(false)
                        setBookableListingLength(0)
                        setBookablesDisplay([])
                    }
                }
            }
        }).finally(() => setActiveRequests(prev => prev - 1))
    }, [
        parseInt(centerId),
        parseInt(activityId),
        dayjs(selectedDate).format("YYYY-MM-DD"),
        JSON.stringify(bookingInfosData?.centerActivities),
    ])

    useDeepCompareEffect(
        () => {
            // FILTERS THE BOOKABLES WHEN THE USER CHANGES THE FILTERS
            filterBookables()
            return () => {}
        },[
            hourFilter,
            durationFilter,
            typeFilter,
            timeFilter,
            bookableListing,
            expectedSlot,
            shouldTriggerSlotNotification,
        ],
    )

    const filterBookables = () => {
        if (shouldTriggerSlotNotification !== null && bookableListing?.length > 0 && types?.length > 0) {
            const dateFilter = hourFilter && hourFilter !== "noHourFilter" ? dayjs(selectedDate).format("YYYY-MM-DD") + hourFilter.value : null

            // Create of copy of array to avoid mutating the original
            const bookables = JSON.parse(JSON.stringify(bookableListing.filter(bookable => bookable?.faked !== true)))

            let filteredBookables = bookables.filter(bookable => {
                // Type Filter
                let typeCheck = true
                if (typeFilter && typeFilter.length > 0) {
                    typeCheck = typeFilter.findIndex(type => type[0] === bookable.typeId) !== -1
                }

                // Duration Filter
                let durationCheck = true
                if (durationFilter && durationFilter.length > 0) {
                    const validDurations = bookable.bookables.filter(item => durationFilter.findIndex(dur => dur.value === item.duration) !== -1)
                    durationCheck = validDurations.length > 0
                    if (durationCheck) {
                        bookable.bookables = validDurations
                    }
                }

                // Hour Filter
                let hourCheck = true
                if (dateFilter && bookable.start < dateFilter) {
                    hourCheck = false
                }

                // Time Filter
                let timeCheck = true
                if (timeFilter && Object.keys(timeFilter).length > 0) {
                    const selectedDateAsString = dayjs(selectedDate).format("YYYY-MM-DD")
                    const startTime = selectedDateAsString + timeFilter.start
                    const endTime = selectedDateAsString + timeFilter.end
                    const bookableStartTime = dayjs(bookable.start)
                    if (!(bookableStartTime.isAfter(dayjs(startTime)) && bookableStartTime.isBefore(dayjs(endTime)))) {
                        timeCheck = false
                    }
                }

                return typeCheck && durationCheck && hourCheck && timeCheck
            })

            const typesMatch = types?.some(type => expectedSlot?.type?.includes(type.key))
            // INSERT THE EXPECTED SLOT IF IT DOESN'T EXIST
            const stateMatchingExpectedSlot =
                expectedSlot?.center === centerId &&
                JSON.stringify(expectedSlot?.type?.slice(0, 1)) === JSON.stringify(typeIds?.slice(0, 1)) &&
                dayjs(expectedSlot?.date).isSame(dayjs(selectedDate), "day") &&
                expectedSlot?.slotId === parseInt(slotId) &&
                typesMatch

            const noMatchingSlot = filteredBookables.filter(bookable => {
                return expectedSlot?.type[0] === bookable.typeId
            })?.length === 0

            if (expectedSlot && !!types && stateMatchingExpectedSlot) {
                let fakedSlot = createFakeSlot(expectedSlot, types)

                if (noMatchingSlot) {
                    fakedSlot = createFakeSlot(expectedSlot, types, 1)
                }

                let displayFakedSlot = shouldTriggerSlotNotification
                if (filteredBookables.find(
                    bookable => bookable.start === fakedSlot.start && bookable.type === fakedSlot.resourceTypeDisplay,
                )) {
                    displayFakedSlot = false
                }

                if (displayFakedSlot) {
                    const insertIndex = filteredBookables.findIndex(bookable =>
                        dayjs(bookable.start).isAfter(dayjs(fakedSlot.start)),
                    )
                    if (insertIndex !== -1) {
                        filteredBookables.splice(insertIndex, 0, fakedSlot)
                    } else {
                        filteredBookables.push(fakedSlot)
                    }
                    setSlotVisible(true)
                }
            } else if (!stateMatchingExpectedSlot && slotVisible) {
                setExpectedSlot(null)
                setSlotVisible(false)
            }

            setBookableListingLength(bookableListing?.length ?? 0)
            setBookablesDisplay([ ...filteredBookables ])
            setIsLoading(false)
        } else if (!bookableListing?.length) {
            setIsLoading(false)
            setBookableListingLength(0)
            setBookablesDisplay([])
        }
    }

    const openAgeCheckModal = (discount, bookable) => {
        ModalHandler.show(AgeCheckModal, {
            closeCallback: () => {
                // reset discount
                setValue(formArgs.DISCOUNT_FILTER, null)
            },
            confirmCallback: date => {
                ModalHandler.hide(AgeCheckModal)

                setIsLoading(true)

                editUser({ ...userLoaded, birthdate: date }).catch()

                handleItemClick(bookable, false).catch()
            },
            discount:discount,
        })
    }

    const handleItemClick = async (bookable, withAgeCheck = true) => {
        let discountFilter = getValues(formArgs.DISCOUNT_FILTER)

        if (discountFilter && discountFilter.label === AGE_CHECK_LABEL && withAgeCheck && !userLoaded?.birthdate) {
            openAgeCheckModal(discountFilter, bookable)
            return
        }

        const formValues = getValues()
        setIsLoading(true)
        setFromOrderSummary(false)
        let validPrebook
        if (preReservation && ((discountFilter && preReservation.discount && discountFilter.id === preReservation.discount.id) || (!discountFilter && !preReservation.discount))) {
            await getPreBook(preReservation.id, themeHeader).then(res => {
                if (res?.preReservation) {
                    validPrebook = true
                }
            })
        }

        const startDate = new Date(bookable.start)
        setSavedFilters({
            ...formValues,
            theme: themeHeader,
        })

        if (preReservation
            && !preReservation.Message
            && new Date(preReservation.start).toISOString() === startDate.toISOString()
            && bookable.durationDisplay === preReservation.duration
            && validPrebook
        ) {
            extendPreBookValidity(preReservation.id).then(
                () => {
                    setSummaryData({
                        ...summaryData,
                        availableDiscounts: availableDiscounts,
                        bookablesListing: bookableListing,
                        centerActivities: bookingInfosData?.centerActivities,
                    })
                    navigate({
                        pathname: path(`/reserver/${preReservation.id}`),
                        search: createSearchParams(changeUrlForPreBook(preReservation, bookable.discounts)).toString(),
                    })
                },
            ).finally(() => setIsLoading(false))
        } else {
            preBook({
                centerId: centerId,
                discountConfigId: discountFilter ? bookable?.discounts?.find(discount => discountFilter.label === discount.label)?.id : null,
                duration: bookable.duration,
                preferredSlotId: shouldTriggerSlotNotification ? watch(formArgs.SLOT_ID) : null,
                resourceType: bookable.resourceType,
                start: dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss"),
                theme: themeHeader,
            }).then(
                (res) => {
                    if (res[1].preReservation
                        && dayjs(res[1].preReservation.start).format("YYYY-MM-DDTHH:mm:ss")
                        === dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss")
                    ) {
                        setOnlyOneRemaining(res[1].onlyOneRemaining)
                        setSummaryData({
                            ...summaryData,
                            availableDiscounts: availableDiscounts,
                            bookablesListing: bookableListing,
                            centerActivities: bookingInfosData?.centerActivities,
                            preReservation: res[1].preReservation,
                        })
                        navigate({
                            pathname: path(`/reserver/${res[1].preReservation.id}`),
                            search: createSearchParams(changeUrlForPreBook(res[1].preReservation, bookable.discounts)).toString(),
                        })
                    } else {
                        let newBookablesArray = bookableListing
                        let indexToRemove
                        newBookablesArray.forEach(
                            (startDateBookable, index) => {
                                if (startDateBookable.start === bookable.start) {
                                    const newBookables = startDateBookable.bookables.filter(
                                        b => !(b.start === bookable.start && b.duration === bookable.duration),
                                    )
                                    if (newBookables.length) {
                                        startDateBookable.bookables = newBookables
                                    } else {
                                        indexToRemove = index

                                    }
                                }
                            },
                        )
                        if (typeof indexToRemove === "number") {
                            newBookablesArray.splice(indexToRemove, 1)
                        }
                        setBookableListing([ ...newBookablesArray ])
                        ModalHandler.show(ExpiredSlotModal)
                    }
                    setIsLoading(false)
                },
            )
        }
    }

    const changeUrlForPreBook = (preReservation, discounts) => {
        const prebookStart = preReservation?.start && new Date(preReservation?.start)
        const prebookDuration = preReservation?.duration && durationString.find(string => string === preReservation.duration)
            && durationNumber[durationString?.findIndex(string => string === preReservation.duration)]
        const prebookDiscount = preReservation?.discount?.label && discounts?.find(discount => preReservation.discount.label === discount.label).id

        const params = {
            activity: activityId,
            centerId: centerId,
            date: prebookStart ? dayjs(prebookStart).format("YYYY-MM-DD") : selectedDate,
            discountId: prebookDiscount,
            discountLabel: preReservation?.discount?.label && encodeURI(preReservation?.discount?.label),
            duration: prebookDuration,
            hour: prebookStart && dayjs(prebookStart).format("HH"),
            mn: prebookStart && dayjs(prebookStart).format("mm"),
            resourceType: preReservation?.resourceType,
        }
        Object.keys(params).forEach((param) => !params[param] && delete params[param])
        return params
    }

    useEffect(() => {
        setBookableDisplayLength(bookablesDisplay?.length ?? 0)
    }, [ bookablesDisplay ])

    useEffect(() => {
        if (fromOrderSummary) {
            setForceDisableFakedSlot(true)
            if (preReservation) {
                getPreBook(preReservation.id).then(res => {
                    if (res?.preReservation) {
                        extendPreBookValidity(preReservation.id).finally(() => setIsLoading(false))
                    }
                })
            }
        }

    }, [ fromOrderSummary ])

    useEffect(() => {
        if (selectedSlot && !selectedSlot?.fromUserAction && !forceDisableFakedSlot && !fromDashboard) {
            if (watch(formArgs.SLOT_DATETIME)) {
                const notifDateTime = dayjs(watch(formArgs.SLOT_DATETIME))

                if (notifDateTime) {
                    setValue(formArgs.DATE, notifDateTime.format("YYYY-MM-DD"))

                    const newHourFilters = locationHours.find(
                        option => option.display === hourOptions.find(
                            option => option.value === notifDateTime.hour(),
                        ).display,
                    ) ?? "noHourFilter"
                    setValue(formArgs.HOUR, newHourFilters)
                }

                setExpectedSlot({
                    center: selectedSlot?.centerId,
                    date: notifDateTime?.toDate(),
                    slotId: selectedSlot?.id,
                    start: notifDateTime?.hour(),
                    type: typeIds,
                })
            } else {
                setExpectedSlot({
                    center: selectedSlot?.centerId,
                    date: selectedDate,
                    slotId: selectedSlot?.id,
                    start: selectedSlot?.start,
                    type: typeIds,
                })
            }
        }
    }, [ selectedSlot ])

    useEffect(() => {
        if (timeFilter) {
            setExpectedSlot(null)
        }
    }, [ timeFilter ])

    useEffect(() => {
        if (location?.state?.fromDashboard) {
            setFromDashboard(true)
        }
    }, [ location?.state?.fromDashboard ])

    const bookableList = useMemo(() => {
        return bookablesDisplay?.map((bookable, index) => (
            <BookableItem
                bookable={bookable}
                key={index}
                onSlotSelected={handleItemClick}
                types={types}
                bookableListing={bookablesDisplay}
            />
        ))
    }, [ bookablesDisplay ])

    return (
        <div className={"bookablesListing c-col c-col--12"} >
            {isListingLoading && !isLoading && <Preloader fixed/>}
            <div className="bookablesContainer">
                {!isLoading && !isListingLoading && !error ? bookableList : null}
            </div>
        </div>
    )

    function formatBookablesForDisplay(bookables) {
        const groupMap = new Map()

        if (bookables) {
            bookables.forEach(bookable => {
                const groupKey = bookable.start + bookable.resourceTypeDisplay

                if (!groupMap.has(groupKey)) {
                    groupMap.set(groupKey, {
                        bookables: [ bookable ],
                        minDuration: bookable.duration,
                        name: groupKey,
                        start: bookable.start,
                        type: bookable.resourceTypeDisplay,
                        typeId: bookable.resourceType,
                    })
                } else {
                    groupMap.get(groupKey).bookables.push(bookable)
                }
            })
        }

        return Array.from(groupMap.values()).map(group => ({
            ...group,
            bookables: group.bookables.sort(durationSort),
        })).sort(hoursSort)
    }
}

export const AGE_CHECK_LABEL = "Tarif - 26 ans"
