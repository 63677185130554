import useUrbanLanguage from "../../hooks/urbanLanguage/useUrbanLanguage"

export default function products() {
    const { tu } = useUrbanLanguage()
    return [
        {
            contentIdentifier: "reserver",
            isMyUrban: true,
            label: "Terrain",
            link: "/reserver",
            lvl1: "reserver",
            lvl2: null,
            props: {},
        },
        {
            contentIdentifier: "leagueTunnel",
            isMyUrban: true,
            label: tu("league.name"),
            link: "/urbanleague/new",
            lvl1: "urbanleague",
            lvl2: "inscriptionLeague",
            props: {
                onglet: "new",
            },
        },
        {
            contentIdentifier: "urbanCupTunnel",
            isMyUrban: true,
            label: "Tournoi",
            link: "/cup/new",
            lvl1: "urbanCup",
            lvl2: null,
            props: {
                onglet: "new",
            },
        },
        {
            isMyUrban: false,
            label: "Abonnement",
            link: "https://www.urbansoccer.fr/formulaire-abonnement/",
        },
        {
            contentIdentifier: "birthdayReservation",
            isMyUrban: true,
            label: "Anniversaire",
            link: "kids/anniversaire/new",
            lvl1: "kids",
            lvl2: "listingBirthday",
            props: {
                onglet: "new",
            },
        },
        {
            contentIdentifier: "stagesDeFootTunnel",
            isMyUrban: true,
            label: "Stage de Foot",
            link: "/kids/stagesDeFoot/new",
            lvl1: "kids",
            lvl2: "stagesDeFoot",
            props: {
                onglet: "new",
            },
        },
        {
            contentIdentifier: "ecoleDeFootTunnel",
            isMyUrban: true,
            label: "Ecole de Foot",
            link: "/kids/ecoleDeFoot/new",
            lvl1: "kids",
            lvl2: "ecoleDeFoot",
            props: {
                onglet: "new",
            },
        },
        {
            isMyUrban: false,
            label: "Evènement d'entreprise",
            link: "https://www.urbanevent.fr/contact/",
        },
    ]
}
