export const PREVIOUS_STEP = "PREVIOUS_STEP"
export const NEXT_STEP = "NEXT_STEP"
export const ADD_STEP = "ADD_STEP"
export const GO_TO_STEP = "GO_TO_STEP"
export const SKIP_STEP = "SKIP_STEP"
export const RESET_STEP_COUNT = "RESET_STEP_COUNT"

export function resetStepCount() {
    return { type: RESET_STEP_COUNT }
}

export function previousStep() {
    return { type: PREVIOUS_STEP }
}

export function skipStep() {
    return { type: SKIP_STEP }
}

export function nextStep() {
    return { type: NEXT_STEP }
}

export function addStep() {
    return { type: ADD_STEP }
}

export function goToStep(number) {
    return { 
        payload: number,
        type: GO_TO_STEP,
    }
}
