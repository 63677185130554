// import TeamSteps from "../../pages/teams/steps/TeamSteps";
import ListingUrbanCup from "../../pages/teams/urbanCup/ListingUrbanCup"
import UrbanCup from "../../pages/teams/urbanCup/UrbanCup"

const RoutesCup = [
    {
        element: (<ListingUrbanCup/>),
        path: "/cup",
    },
    {
        children: [
            // ...TeamSteps.stepsArray.map(step => {
            //     return {
            //         path: step,
            //         element: (<UrbanCup />),
            //     };
            // }),
            {
                element: (<UrbanCup />),
                path: ":id",
            },
        ],
        element: (<UrbanCup />),
        path: "/cup/new",
    },
    {
        element: (<ListingUrbanCup/>),
        path: "/cup/listing",
    },
]

export default RoutesCup
