import { useEffect, useId, useRef } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useRouteLoaderData } from "react-router-dom"

import FileInput from "../../../components/Forms/FileInput/FileInput.jsx"
import { Select } from "../../../components/Inputs/NewSelect/Select.jsx"
import { TextInput } from "../../../components/Inputs/TextInput/TextInput.jsx"
import { BusinessLeagueTunnelPaths } from "../../../routes/ApplicationPaths/BusinessLeagueTunnelPaths.js"
import { uploadBusinessLeagueLogo } from "../service/api.js"
import { useBusinessFormNavigation } from "../store/useBusinessFormNavigation.js"

export const General = () => {
    const methods = useFormContext()
    const centersData = useRouteLoaderData(BusinessLeagueTunnelPaths.loaderId)
    const { nextStep } = useBusinessFormNavigation()
    const placeholderTeamId = useId()

    const onSubmit = methods.handleSubmit(() => nextStep())
    const inputUpload = useRef(null)
    const blazon = methods.watch("general.blazon")
    const uploadFile = async (file) => {
        const id = methods.watch("general.teamName") || placeholderTeamId
        const logo  = await uploadBusinessLeagueLogo(file, id)
        methods.setValue("general.blazon", logo)
        methods.trigger("general.blazon")
    }

    useEffect(() => {
        methods.register("general.blazon")
    }, [])

    function deleteAttachment() {
        methods.setValue("general.blazon", null)
        inputUpload.current.value = ""
    }

    return (
        <form onSubmit={onSubmit}>
            <h2>Général</h2>
            <Controller
                render={({ field: { onChange, value } }) => (
                    <Select
                        options={centersData}
                        hideSelectedOptions={false}
                        value={value}
                        onChange={onChange}
                        required
                        menuPlacement={"auto"}
                        placeholder="Sélectionne ton centre"
                        label="Centre"
                        error={methods.formState.errors?.general?.center?.value?.message}
                    />
                )}
                rules={{ required: true }}
                name={"general.center"}
                control={methods.control}
            />
            <TextInput
                {...methods.register("general.company")}
                placeholder="Urban Soccer"
                label="Nom de l'entreprise"
                required
                rhf
                error={methods.formState.errors?.general?.company?.message}
            />
            <TextInput
                {...methods.register("general.teamName")}
                placeholder="Urban"
                label="Nom de l'équipe"
                required
                rhf
                error={methods.formState.errors?.general?.teamName?.message}
            />
            <FileInput
                id="blazonUpload"
                label="Logo de l'équipe"
                acceptType="image"
                initButtonUploadText="Importe le logo de ton équipe"
                onFileUploadPromiseCallback={uploadFile}
                deleteAttachment={deleteAttachment}
                initFileName={
                    typeof blazon === "string" ?
                        blazon
                        :
                        blazon ?
                            URL.createObjectURL(blazon)
                            :
                            null
                }
                asFile
                required
                subLabel="Format png, jpg, pdf (3Mo max)"
                ref={inputUpload}
                error={methods.formState.errors?.general?.blazon?.message}
            />
            <TextInput
                {...methods.register("general.billingAdress")}
                placeholder="Adresse"
                label="Adresse de facturation"
                required
                rhf
                error={methods.formState.errors?.general?.billingAdress?.message}
            />
        </form>
    )
}
