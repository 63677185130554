import classNames from "classnames"
import dayjs from "dayjs"
import { bool, func, object, oneOfType, string } from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import CheckBlock from "../../../../../components/Forms/CheckBlock/CheckBlock"
import Preloader from "../../../../../components/loaders/preloader/preloader"
import ReservationSlotInfoCard from "../../../../../components/reservation/slotInfoCard/ReservationSlotInfoCard"
import { useTheme } from "../../../../../components/ThemeContext/ThemeContext"
import DateDisplay from "../../../../../components/Time/DateDisplay"
import HourDisplay from "../../../../../components/Time/HourDisplay"
import useIsMobile from "../../../../../hooks/useIsMobile"
import useMediaPath from "../../../../../hooks/useMediaPath"

import "./CupCheckblock.scss"

function CupCheckblock(props) {
    const { cup, selectedCup, onClick, selectedCupDetails, subscribeToWaitingList, loadingCallback, isLoading } = props
    const alreadySubscribedCupIds = useSelector(state => state.forms.alreadySubscribedCupIds)

    const mediaPath = useMediaPath()
    const theme = useTheme()
    const isMobile = useIsMobile()

    if (loadingCallback) {
        if (!alreadySubscribedCupIds) {
            loadingCallback(true)
        } else {
            loadingCallback(false)
        }
    }

    return (
        <React.Fragment key={cup.id}>
            { alreadySubscribedCupIds && (
                <CheckBlock
                    cls="cup"
                    callback={onClick}
                    checked={selectedCup ?
                        cup.id === selectedCup.id
                        : 
                        false
                    }
                    name="cups"
                    value={cup.id}
                    disabled={ 
                        (alreadySubscribedCupIds?.includes(cup.id))
                        || (!cup.waitingListOpen && cup.isFull)
                    }
                >
                    {!alreadySubscribedCupIds?.includes(cup.id) && cup.isFull && theme === "theme-padel" && <div className="full"><span>Complet</span></div>}
                    <div className="radioList__item--logo">
                        <img src={
                            cup.pictureUrl ? 
                                cup.pictureUrl
                                :
                                mediaPath([ "/assets/images/placeholder-team.svg", "/assets/images/padel/logo/black-bg-logo.svg" ])
                        }/>
                    </div>
                    <div className="radioList__item--content">
                        <span className="titlePrice">
                            <span className="radioList__item__title">
                                {cup.name}
                            </span>
                        </span>

                        <span className="radioList__item__desc">
                            <span>
                                <DateDisplay
                                    stringDate={cup.start}
                                    slashDisplay={true}
                                />
                                {theme === "theme-padel" && cup?.end && cup?.start !== cup?.end && <span> - {dayjs(cup.end).format("DD/MM/YYYY")}</span>}
                            </span>
                            <span>
                                <HourDisplay
                                    minutes={cup.hour}
                                    displayZero={true}
                                />
                                {cup.centerName && ` - ${cup.centerName}`}
                            </span>
                        
                            {(alreadySubscribedCupIds?.includes(cup.id)) ?
                                <span>Déjà inscrit</span>
                                :
                                cup.isFull ?
                                    theme === "theme-soccer" && <span>Complet</span>
                                    :
                                    cup.remainingPlaces === 0 ?
                                        <span>Complet</span>
                                        : (
                                            <>
                                                {cup.playerMatchPrice && (
                                                    <div
                                                        className={classNames("playerMatchPrice", {
                                                            marginBottom: cup.remainingPlaces !== null && cup.remainingPlaces <= 3,
                                                        })}
                                                    >
                                                        {cup.playerMatchPrice}€ / joueur
                                                    </div>
                                                )}
                                                {cup.remainingPlaces !== null && cup.remainingPlaces <= 3 && (
                                                    <span>
                                                        {cup.remainingPlaces} {(cup.remainingPlaces > 1 ? " places restantes" : " place restante")}
                                                    </span>
                                                )}
                                            </>
                                        )

                            }
                            {(theme !== "theme-padel" || isMobile) && (<span className="radioList__item__price">
                                {
                                    (cup.remainingPlaces !== 0) && 
                                        (<strong>
                                            {cup.amount}€
                                        </strong>)
                                }
                            </span>)}
                        </span>
                    
                    </div>
                    {theme === "theme-padel" && !isMobile && (<span className="radioList__item__price">
                        {
                            (cup.remainingPlaces !== 0) && 
                                    (cup.amount  + "€")
                        }
                    </span>)}
                    {
                        (isLoading && selectedCup && (cup.id === selectedCup.id)) &&
                            <Preloader cls="tournament-checkblock-loader"/>
                    }
                </CheckBlock>
            )}
            {(isMobile && alreadySubscribedCupIds) && selectedCupDetails && selectedCupDetails.id === cup.id &&
                (
                    <div
                        className={
                            "c-col c-col--3 c-col--sm--12 tooltip-description tournoi-tooltip-description left" +
                    (selectedCupDetails && selectedCupDetails.id === cup.id ? " active" : "")
                        }
                        id={"description-card-" + (cup.id)}
                        key={"description-card-" + (cup.id)}
                    >
                        <ReservationSlotInfoCard
                            reservationSlot={selectedCupDetails}
                            subscribeToWaitingListCallback={subscribeToWaitingList}
                            slotName="tournoi"
                            pleaseSelectText="ton tournoi"
                            tunnelType="CUP"
                        />
                    </div>
                )
            }
        </React.Fragment>
    )
}

CupCheckblock.propTypes = {
    cup: object,
    isLoading: bool,
    loadingCallback: func,
    onClick: func,
    selectedCup: object,
    selectedCupDetails: oneOfType([ string, object ]),
    subscribeToWaitingList: func,
}

export default CupCheckblock
