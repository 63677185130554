import { useState } from "react"

import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import TextArea from "../../Forms/textArea/TextArea.jsx"
import { LeagueModalBase } from "../LeagueModalBase/LeagueModalBase.jsx"

import "./ForfeitModal.scss"

export const ForfeitModal = ModalHandler.create(({ visible, hide, callback }) => {
    const [ comment, setComment ] = useState("")
    return (
        <LeagueModalBase
            onClose={hide}
            visible={visible}
            className={"forfeitModal"}
            icon={"/assets/icons/league/whistle.svg"}
            title={<>SOUHAITES-TU<br/><strong>— DÉCLARER FORFAIT ?</strong></>}
        >
            <div className="desc">Laisse un message pour prévenir le capitaine de l'équipe adverse.</div>
            <TextArea
                labelText="Commentaire"
                value={comment}
                onChange={setComment}
                isRequired
            />
            <ButtonCta text="Oui, je déclare forfait" isAlt disabled={comment.length < 3} onClick={() => {
                callback?.(comment)
                hide()
            }}/>
            <ButtonCta text="Non, je joue mon match" onClick={hide} />
        </LeagueModalBase>
    )
})
