import { node, oneOfType, string } from "prop-types"
import React from "react"
import { connect } from "react-redux"

class OverlayLevel3Content extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // loadData: props.loadData
        }
    }
    populate() {

    }
    render() {
        return (
            <div id="overlay-level3-content" className="content">
                {this.props.content}
            </div>
        )
    }
}

// Container glue
function mapStateToProps(state) {

    let newPropsObject = {}

    if (null !== state.level3Overlay) {
        newPropsObject.content = state.level3Overlay.targetContent
    }

    return newPropsObject
}

OverlayLevel3Content.propTypes = {
    content: oneOfType([ node, string ]),
}

export default connect(mapStateToProps)(OverlayLevel3Content)
