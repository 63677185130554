import classNames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"

import Step1padel from "./padel/Step1padel.jsx"
import Step4Padel from "./padel/Step4padel.jsx"
import Step1 from "./soccer/Step1.jsx"
import Step2 from "./soccer/Step2.jsx"
import Step3 from "./soccer/Step3.jsx"
import { getUser } from "../../globalAPI/api.js"
import { editUser } from "../../pages/profil/api.js"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import Modal from "../Modal/Modal.jsx"
import { useIsWebview, useTheme, useThemeUpdate } from "../ThemeContext/ThemeContext.jsx"

import "./FirstConnection.scss"

const stepComponents = {
    "0": Step1,
    "0-theme-padel": Step1padel,
    "1": Step2,
    "2": Step3,
    "3": Step4Padel,
}

const FirstConnection = ModalHandler.create(({ visible, hide, currentUser }) => {
    const [ step, setStep ] = useState(0)
    const [ isLoading, setIsLoading ] = useState(false)
    const theme = useTheme()
    const isWebview = useIsWebview()
    const updateTheme = useThemeUpdate()

    const handleNextStep = async (choice = null) => {
        if (choice === "theme-padel") {
            setStep((prevStep) => prevStep + 1)
        } else if (step === 2 && choice !== "theme-padel") {
            await closeAndSave()
        } else {
            setStep((prevStep) => prevStep + 1)
        }
    }

    const closeAndSave = async () => {
        setIsLoading(true)
        const newUser = { ...currentUser, firstConnection: false }
        await editUser(newUser).then(getUser).then(() => {
            updateTheme(theme)
        })
        setIsLoading(false)
        hide()
    }

    const saveUser = async (gender, birthdate, newsletter, padelNewsletter, partnerNewsletter) => {
        setIsLoading(true)
        const newUser = {
            ...currentUser,
            birthdate,
            newsletter,
            padelNewsletter,
            partnerNewsletter,
            title: gender,
        }
        await editUser(newUser).then(getUser)
        setIsLoading(false)
    }

    const CurrentStepComponent = stepComponents[`${step}-${theme}`] || stepComponents[step]

    useEffect(() => {
        if (!visible) {setStep(0)} // Reset step to 0 when the modal is not visible
    }, [ visible ])

    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            beforeClose={closeAndSave}
            className={classNames("skylight-first-connection", { isWebview })}
        >
            <div className={theme === "theme-soccer" ? "soccer" : "padel"}>
                {CurrentStepComponent && (
                    <CurrentStepComponent
                        step={step}
                        onNextStep={handleNextStep}
                        saveUser={saveUser}
                        closeAndSave={closeAndSave}
                        theme={theme}
                        currentUser={currentUser}
                        disableActions={isLoading}
                    />
                )}
            </div>
        </Modal>
    )
})

FirstConnection.propTypes = {
    currentUser: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
}

export default FirstConnection
