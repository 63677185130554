import { useEffect, useState } from "react"

import { listExhibitionVideos } from "../../../globalAPI/api"
import useThemeHeader from "../../useThemeHeader"

function useLocationVideos(
    location,
) {
    let { GameId } = location ?? {}
    const themeHeader = useThemeHeader()
    const [ hasVideos, setHasVideos ] = useState(false)
    const [ videos, setVideos ] = useState(null)
    const [ loadingVideos, setLoadingVideos ] = useState(false)

    function playerScored(player) {
        if (player) {
            return hasVideos && videos && videos.goals.reduce(
                (goalsCount, newGoalsCount) => {
                    if (newGoalsCount.details.playerId === player.playerId) {
                        return goalsCount + 1
                    } else {
                        return goalsCount
                    }
                }, 0,
            )
        } else {
            return undefined
        }
    }

    useEffect(
        () => {
            if (GameId) {
                setLoadingVideos(true)
                listExhibitionVideos(GameId, { activity: themeHeader }).then(
                    (videoRes) => {
                        let newData = videoRes
                        if (newData) {
                            if (Object.keys(newData.main).length > 0 || newData.goals.length > 0) {
                                setVideos(newData)
                                setHasVideos(true)
                            }
                        }
                        setLoadingVideos(false)
                    },
                )
            }
        }, [ GameId ],
    )

    return {
        hasVideos,
        loadingVideos,
        playerScored,
        videos,
    }
}

export default useLocationVideos
