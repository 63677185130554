import { bool, func } from "prop-types"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import useMediaPath from "../../../hooks/useMediaPath"
import SignUpPage from "../../../pages/authentication/signUpPage/signUpPage"
import usePath from "../../../routes/services/usePath"
import { setFormEntry } from "../../../store/forms/actions"
import ButtonCta from "../../Buttons/button/button-cta"

import "./signupComponent.scss"

function SignupComponent({
    displaySignupPageAction,
    isMobile,
}) {
    const signUpPageData = useSelector(state => state.forms[SignUpPage.formName])
    /* ACTIONS */
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const path = usePath()
    const mediaPath = useMediaPath()
    const [ , updateState ] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }

    function emailSignUp() {
        setFormInStore(
            SignUpPage.formName, {
                ...signUpPageData,
                accountType: 0,
            },
        )
        navigate(path("/signup"))
        displaySignupPageAction(true)
    }

    return (
        <React.Fragment>
            <div className="signupComponent">
                {!isMobile && (<img className="appLogo" src={mediaPath([
                    "/assets/images/logo-white.svg",
                    "/assets/images/padel/logo/logo-green.svg",
                    "/assets/images/business/logo-business-white.svg",
                ])} />)}
                <h2 className="signUpLabel">Tu n'as pas encore de compte ?</h2>
                <div className="signUpChoices">
                    <ButtonCta 
                        text= "Créer&nbsp;mon&nbsp;compte"
                        isAlt
                        onClick= {emailSignUp}
                    />          
                </div>
            </div>
        </React.Fragment>
    )

}

SignupComponent.propTypes = {
    displaySignupPageAction: func,
    isMobile: bool,
}

export default SignupComponent
