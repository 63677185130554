import dayjs from "dayjs"
import { bool, number, string } from "prop-types"
import { useEffect, useState } from "react"
import { useDispatch, useSelector, useStore } from "react-redux"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"

import { getAllKidsSchoolsSubscriptions, getKidsSchoolSubscription } from "./api"
import CreneauxDispo from "./steps/CreneauxDispo"
import EmergencyContact from "./steps/EmergencyContact"
import PrestationSupp from "./steps/PrestationSupp"
import StepsManager from "../../../components/stepsManagement/StepManager"
import StepsContainer from "../../../components/stepsManagement/StepsContainer"
import { useTheme } from "../../../components/ThemeContext/ThemeContext"
import useInitialStep from "../../../hooks/routing/useInitialStep"
import useQueryParametersObject from "../../../hooks/routing/useQueryParametersObject.js"
import useAddSearchParam from "../../../hooks/useAddSearchParams"
import useThemeHeader from "../../../hooks/useThemeHeader"
import { setFormEntry } from "../../../store/forms/actions"
import { goToStep, nextStep } from "../../../store/stepsManagement/actions"
import { resetNativeHistoryToCurrentLocation } from "../../../utilities/helpers"
import ChooseCenterAndType from "../steps/ChooseCenterAndType"
import MesEnfants from "../steps/MesEnfants"
import NikeEquipment from "../steps/NikeEquipment"
import OrderSummary from "../steps/OrderSummary"

import "./EcoleDeFoot.scss"

/**
 * Fonction de contenu (page) permettant de réaliser une inscription à une école de foot
 */
function EcoleDeFoot({ firstSubId, secondSubId, centerId, schoolType, denyReturn = true }) {
    const dispatch = useDispatch()
    const addSearchParams = useAddSearchParam()
    const store = useStore()
    const { state } = useLocation()
    const navigate = useNavigate()
    const themeHeader = useThemeHeader()
    const theme = useTheme()
    const params = useParams()
    const [ searchParams, setSearchParams ] = useSearchParams()
    const searchParamsObject = useQueryParametersObject()

    const formsEnfants = useSelector(state => state.forms[MesEnfants.formName])
    
    if (state) {
        firstSubId = state.firstSubId
        secondSubId = state.secondSubId
        centerId = state.centerId
        schoolType = state.schoolType
        denyReturn = state.denyReturn
    }

    const [
        searchQuery,
        // setSearchQuery
    ] = useSearchParams()
    const registrationId = searchQuery.get("registrationId")

    let [ noReturn, setNoReturn ] = useState(denyReturn)

    const location = useLocation()
    if (location.state) {
        firstSubId = location.state.firstSubId
        secondSubId = location.state.secondSubId
    }

    if (registrationId) {
        firstSubId = registrationId
    }

    const initialStep = useInitialStep(EcoleDeFoot)

    const type = "SCHOOL"

    useEffect(
        () => {
            resetNativeHistoryToCurrentLocation(window.location.href)

            const periodStart = dayjs(new Date()).subtract(3, "year").format("YYYY-MM-DD")
            getAllKidsSchoolsSubscriptions({
                offset: 0,
                orderByAsc: false,
                periodStart: periodStart,
                status: "[2,1,3]",
            }).then(
                (subs) => {
                    if (subs) {
                        if (subs.count !== 0) {
                            setNoReturn(false)
                        }
                    }
                },
            )
            if (firstSubId) {

                getKidsSchoolSubscription(firstSubId).then(
                    (firstSub) => {
                        dispatch(
                            setFormEntry("ozmoSubscription", {
                                ...store.getState().forms.ozmoSubscription,
                                firstSubscription: firstSub,
                            }),
                        )
                        if (initialStep === -1) {
                            dispatch(goToStep(6))
                        } else {
                            dispatch(goToStep(initialStep))
                        }
                    
                        dispatch(
                            setFormEntry(
                                ChooseCenterAndType.formName, {
                                    centerId: firstSub.school.centerId,
                                    typeId: firstSub.school.typeId,
                                },
                            ),
                        )
                        
                        dispatch(
                            setFormEntry(
                                CreneauxDispo.formName, {
                                    selectedTimeslotId: firstSub.school.id,
                                },
                            ),
                        )

                        if (secondSubId) {
                            getKidsSchoolSubscription(secondSubId).then(
                                (secondSub) => {
                                    dispatch(
                                        setFormEntry(
                                            "ozmoSubscription", {
                                                ...store.getState().forms.ozmoSubscription,
                                                secondSubscription: secondSub,
                                            },
                                        ),
                                    )
                                    if (formsEnfants && !formsEnfants.selectedKid) {
                                        dispatch(
                                            setFormEntry(
                                                MesEnfants.formName, {
                                                    equipmentId: secondSub.outfitSizeValue,
                                                    selectedKidId: firstSub.kid.id,
                                                },
                                            ),
                                        )
                                    }
                                },
                            )
                        } else if (firstSub.correlationId) {
                            getKidsSchoolSubscription(firstSub.correlationId).then(
                                (secondSub) => {
                                    dispatch(
                                        setFormEntry(
                                            "ozmoSubscription", {
                                                ...store.getState().forms.ozmoSubscription,
                                                secondSubscription: secondSub,
                                            },
                                        ),
                                    )
                                    if (formsEnfants && !formsEnfants.selectedKid) {
                                        dispatch(
                                            setFormEntry(
                                                MesEnfants.formName, {
                                                    equipmentId: secondSub.outfitSizeValue,
                                                    selectedKidId: firstSub.kid.id,
                                                },
                                            ),
                                        )
                                    }
                                },
                            )
                        } else if (formsEnfants && !formsEnfants.selectedKid) {
                            dispatch(
                                setFormEntry(
                                    MesEnfants.formName, {
                                        equipmentId: firstSub.outfitSizeValue,
                                        selectedKidId: firstSub.kid.id,
                                    },
                                ),
                            )
                        }
                    },
                )
            } else if (centerId !== undefined && schoolType !== undefined) {
                dispatch(
                    setFormEntry(
                        ChooseCenterAndType.formName, {
                            centerId: centerId,
                            typeId: schoolType,
                        },
                    ),
                )
                dispatch(goToStep(1))
            } else {
                dispatch(goToStep(initialStep))
            }
        },
        [],
    )
    
    return (
        <StepsManager customCls="soccerSchool">
            <StepsContainer stepsToShow='3'>
                <ChooseCenterAndType initialCenterId={centerId} initialTypeId={schoolType} tunnelType={type} noReturn={noReturn}/>
                <MesEnfants tunnelType={type}/>
                <CreneauxDispo
                    tunnelType={type}
                    navigate={navigate}
                    themeHeader={themeHeader}
                    router={{ location, navigate, params, searchParams, searchParamsObject, setSearchParams }}
                />
            </StepsContainer>
            <PrestationSupp/>
            <NikeEquipment tunnelType={type}  theme={theme} updateSearchParams={
                equipment => {
                    addSearchParams("equipmentId", equipment?.length ? equipment[0]?.id : "none")
                    dispatch(nextStep())
                }
            }/>
            <EmergencyContact tunnelType={type}/>
            <OrderSummary />
        </StepsManager>
    )
}

EcoleDeFoot.propTypes = {
    centerId: number,
    denyReturn: bool,
    firstSubId: number,
    schoolType: string,
    secondSubId: number,
}

EcoleDeFoot.stepsArray = [
    "centre-activite",
    "enfant",
    "creneaux",
    "prestation-supp",
    "equipement",
    "contact",
    "finalisation",
]

export default EcoleDeFoot
