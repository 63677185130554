import classNames from "classnames"
import PropTypes from "prop-types"

export const Title = ({ title, subtitle, size, cls, margin }) => {
    return (
        <div
            className={classNames("title", cls)}
            style={{
                color: "white",
                margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : "0",
                textAlign: "center",
            }}
        >
            {title && (<h1 className="tw-bold" style={{ fontSize: size?.[0] }}>{title}</h1>)}
            {subtitle && (<h2 className="tw-regular" style={{ fontSize: size?.[1] || size?.[0] }}>{subtitle}</h2>)}
        </div>
    )
}

Title.propTypes = {
    cls: PropTypes.string,
    margin: PropTypes.arrayOf(PropTypes.number),
    size: PropTypes.arrayOf(PropTypes.number),
    subtitle: PropTypes.string,
    title: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
}
