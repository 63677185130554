
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { editProfilPictureUpload } from "../../../../store/actions/content/index"
import _ConfirmOverlayContentComponent from "../_ConfirmOverlayContentComponent"

class formValidationErrorsDisplayConfirmContent extends _ConfirmOverlayContentComponent
{
    constructor(props) {
        super(props)
        this.state = {
            formErrors: props.formErrors,
        }
    }

    getName() {
        return "formValidationErrorsDisplay"
    }

    preRenderDesktop() {
        return this.preRender()
    }

    preRender()
    {
        return (
            <div className="align-flex">
                <div className="text-center">
                    <img className="confirmation-content-picto" src="/assets/images/Infos-O.svg" />
                    <div className="row"></div>
                    <div className="row">
                        {this.props.formErrors.map(function (value, index) {
                            return <div className="row white weight-bold" key={ index }>{value}</div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

// CONNECT THE STORE (DATA) TO THE COMPONENT
const stateToProps = (state) => {
    return _ConfirmOverlayContentComponent.stateToProps(state)
}

// BIND ACTIONS TO CONTAINER
const dispatchToProps = (dispatch) => {
    let properActions = {
        editProfilPictureUpload: editProfilPictureUpload,
    }
    let mergedActions = {
        ... properActions,
        ... _ConfirmOverlayContentComponent.dispatchToProps(),
    }
    return bindActionCreators(
        mergedActions
        ,
        dispatch,
    )
}

export default connect(stateToProps, dispatchToProps)(formValidationErrorsDisplayConfirmContent)
