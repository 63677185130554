/* eslint-disable no-irregular-whitespace */
import classNames from "classnames"
import { bool, number, oneOfType, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector, useStore } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import APP_CONFIG from "../../../business/config"
import CheckBlock from "../../../components/Forms/CheckBlock/CheckBlock"
import FormValidator from "../../../components/Forms/FormValidator"
import { SelectInput } from "../../../components/Inputs/Select/SelectInput.jsx"
import Preloader from "../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import Step from "../../../components/stepsManagement/Step"
import { getUsCenters } from "../../../globalAPI/api"
import useQueryParametersObject from "../../../hooks/routing/useQueryParametersObject"
import useUrbanLanguage from "../../../hooks/urbanLanguage/useUrbanLanguage"
import useResetScroll from "../../../hooks/useResetScroll"
import useThemeHeader from "../../../hooks/useThemeHeader"
import usePath from "../../../routes/services/usePath"
import { displayCenterName } from "../../../services/displayCenterName"
import { setFormEntry } from "../../../store/forms/actions"
import { goToStep } from "../../../store/stepsManagement/actions"
import { getCenterBirthdaysTypes } from "../birthdayReservation/api"
import PersonnaliserAnniv from "../birthdayReservation/steps/PersonnaliserAnniv"
import PreForm from "../components/preForm/PreForm"
import { getKidsSchoolFromCenter } from "../ecoleDeFoot/api"
import SchoolCenterTypeDisplay from "../ecoleDeFoot/components/SchoolCenterTypeDisplay"
import CreneauxDispo from "../ecoleDeFoot/steps/CreneauxDispo"
import { getKidsStagesCenterTypes } from "../stageDeFoot/api"

import "./ChooseCenterAndType.scss"

/**
 * Etape du formulaire EcoleDeFoot permettant de choisir son Ecole
 * @param title Titre de l'étape
 */
function ChooseCenterAndType(props) {
    useResetScroll()
    /* VARIABLES */
    const { noReturn, tunnelType, numero, initialCenterId, initialTypeId } = props
    const store = useStore()
    const forms = useSelector(state => state.forms)
    const userLoaded = useSelector(state => state.userLoaded)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = usePath()
    const { tu } = useUrbanLanguage()
    const themeHeader = useThemeHeader()
    // centres à exclure pour anniversaires
    const centersToExcludeBirthday = [
        1, 38, 36, 35, 23, 18, 17, 8, 40, 21,
    ]

    /* STATE */
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ initialized, setInitialized ] = useState(false)
    const [ centersOptions, setCentersOptions ] = useState([])
    const [ sortedCentersOptions, setSortedCentersOptions ] = useState([])
    const [ proximity, setProximity ] = useState(null)

    const currentStep = useSelector(state =>  state.stepManagement.currentStep)

    const searchParamsObject = useQueryParametersObject()
    const [ searchParams, setSearchParams ] = useSearchParams()

    const typeIdFromUrl = searchParams.get("typeId")
    const centerIdFromUrl = searchParams.get("centerId")
    const configIdFromUrl = searchParams.get("configId")

    /* REFS */
    const step = useRef(null)
    const form = useRef(null)
    const select = useRef(null)

    const validateStep = () => {
        if (step.current) { step.current.validate() }
    }
    const unvalidateStep = () => {
        if (step.current) { step.current.unvalidate() }
    }

    /* FORMULAIRES */
    const storedForm = useSelector(state => state.forms[ChooseCenterAndType.formName])

    const storedFormData = useSelector(state => state.forms[ChooseCenterAndType.formDataName])

    const formPersonnaliserAnnivData = store.getState().forms[PersonnaliserAnniv.formDataName] !== undefined ? 
        store.getState().forms[PersonnaliserAnniv.formDataName] 
        : null 
    
    /* DONNEES FORMULAIRE */
    const { typesOptions, typesRecommendation } = storedFormData
    const { selectedCenterId, typeId, centerId } = storedForm

    const selectedCenterName = centersOptions[selectedCenterId]
    const chosenCenterId = storedForm.centerId
    const chosenType = storedForm.typeId

    const [ typesOptionsArray, setTypesOptionsArray ] = useState(null)

    /* ACTIONS */
    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        // forceUpdate();
    }

    /* DOM HELPERS */
    const help = <PhoneCallHelp tunnelType={tunnelType}/>

    let recommendationsDOM = []
    if (typesRecommendation) {
        typesRecommendation.forEach(
            (recommendation) => {
                let center = recommendation.center
                let types = recommendation.types
                types.forEach(
                    (type) => {
                        recommendationsDOM.push(
                            centerTypeElementDOM(
                                type, 
                                {
                                    id: center.id,
                                    name: tunnelType === "TRAINING" ? 
                                        displayCenterName(center.id, center.name) : center.name,
                                },
                            ),
                        )
                    },
                )
            },
        )
    }
    recommendationsDOM = recommendationsDOM.slice(0,3) // take only first 3 elements

    /**
     * Lorsque le composant est chargé on récupère les centre depuis l'api
     */

    useEffect(
        () => {
            getCenters()
            setRecap()
        }, [],
    )

    useEffect(
        () => {
            if (selectedCenterId && proximity?.centerArea && !typesRecommendation?.length && proximity?.id === parseInt(selectedCenterId)) {
                setFormInStore(ChooseCenterAndType.formDataName,{
                    ...storedFormData,
                    typesRecommendation: proximity.centerArea,
                })
            }

        }, [ typesRecommendation, proximity, selectedCenterId ],
    )

    useEffect(
        () => {
            // Si il n'y a pas un formulaire de choix d'école existant, 
            // on récupère le centre passé en argument ou celui de l'utilisateur
            // et on charge des écoles correspondantes.
            if (sortedCentersOptions?.length) {
                if (!storedForm?.selectedCenterId) {
                    if (initialCenterId && initialTypeId && sortedCentersOptions?.find(center => parseInt(center[0]) === parseInt(initialCenterId))) {
                        setFormInStore(
                            ChooseCenterAndType.formName, {
                                ...storedForm,
                                centerId: initialCenterId,
                                selectedCenterId: initialCenterId,
                                typeId: initialTypeId,
                            },
                        )
                    } else {
                        if (typeIdFromUrl && centerIdFromUrl && sortedCentersOptions?.find(center => parseInt(center[0]) === parseInt(centerIdFromUrl))) {
                            const newState = {
                                centerId: centerIdFromUrl,
                                typeId: typeIdFromUrl,
                            }
                            if (configIdFromUrl) {
                                newState.configId = configIdFromUrl
                            }
                            setFormInStore(
                                ChooseCenterAndType.formName, {
                                    ...storedForm,
                                    ...newState,
                                    selectedCenterId: centerIdFromUrl,
                                },
                            )
                        } else {
                            const centerToSelect = userLoaded.centerId && sortedCentersOptions?.find(center => parseInt(center?.[0]) === parseInt(userLoaded.centerId)) ? userLoaded.centerId : 1
                            setFormInStore(
                                ChooseCenterAndType.formName, {
                                    ...storedForm,
                                    centerId: centerToSelect,
                                    selectedCenterId: centerToSelect,
                                },
                            )
                        }
                    }
                } else { // Sinon
                    if (storedFormData.typesOptions[storedForm.typeId] && storedFormData.centersOptions[storedForm.centerId]) {
                        // On définit le récap avec les informations des formulaire
                        setRecap()
                    }
                    // Et on recharge les centre disponibles pour le centre préselectionné
                    if (!initialized) {
                        changeSelectedCenter({ initial: true })
                    }
                }

                // On vérifie la validitée du formulaire après chargement du composant
                if (form.current) {
                    if (form.current.validate()) {
                        step.current.validate()
                    }
                }
            }
        }, [ selectedCenterId, sortedCentersOptions ],
    )

    useEffect(
        () => {
            if (storedForm.centerId !== undefined && 
                storedForm.typeId !== undefined && 
                storedForm.selectedCenterId === undefined 
            ) {
                setFormInStore(ChooseCenterAndType.formName, {
                    ...storedForm,
                    selectedCenterId: storedForm.centerId,
                })
            }
            
            setRecap()
            if (form.current) {
                form.current.validate()
            }
        }, [ storedForm, form ],
    )

    useEffect(() => {
        if (chosenType && chosenCenterId && !ajaxLoading && initialized) {step?.current?.validate()}
    }, [ storedForm, form, ajaxLoading, initialized ])

    useEffect(
        () => {
            const thisStepIndex = numero - 1
            if (currentStep > thisStepIndex) {
                if (
                    (!chosenType && !chosenCenterId)
                    && (!typeIdFromUrl && !centerIdFromUrl)
                ) {
                    dispatch(
                        goToStep(thisStepIndex),
                    )
                }
            }
        }, [ currentStep ],
    )

    useEffect(
        () => {
            if (!Array.isArray(typesOptions)) {
                setTypesOptionsArray(Object.values(typesOptions))
            }
        }, [ typesOptions ],
    )

    /** TYPE DEPENDANT */
    let title
    let listingIdentifier
    switch (tunnelType) {
    case "BIRTHDAY":
        title = "Anniversaire"
        listingIdentifier = "/kids/anniversaire"
        break
    case "SCHOOL":
        title = tu("school.title")
        listingIdentifier = "/kids/ecoleDeFoot"
        break
    case "TRAINING":
        title = tu("training.title")
        listingIdentifier = "/kids/stagesDeFoot"
        break
    default:
        // eslint-disable-next-line no-console
        console.error("UNKNOWN OR NOT IMPLEMENTED TUNNEL TYPE : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        break
    }

    function selectedCenterEmptyErrorMessage(centerName) {
        if (!centerName || ajaxLoading) {
            return "Veuillez patienter ou selectionner un centre"
        }
        switch (tunnelType) {
        case "SCHOOL" :
            return tu("school.noSchool") + centerName
        case "BIRTHDAY":
            return "Le centre sélectionné n’a actuellement pas d’anniversaire ouvert aux réservations. </br> N’hésitez pas à vous rapprocher de votre centre pour plus d’informations. "
        case "TRAINING":
            return tu("training.noTraining") + centerName + ". </br> N'hésitez pas à vous rapprocher de votre centre pour plus d'informations."
        default:
            // eslint-disable-next-line no-console
            console.error("UNKNOWN OR NOT IMPLEMENTED TUNNEL TYPE : %o", tunnelType)
            // eslint-disable-next-line no-debugger
            debugger
            break
        }
    }

    /*
    *   Rajoute le centre : UF SAS, en tant que Autre site pour Stage de Foot
    */
    function customAvailableCenter()
    {
        return { id: 1, name: "Autres Sites" }
    }  

    /**
     * Récupère les centre disponible et les filtre au besoin
     */
    async function getCenters() {
        if (centersOptions.length === 0) {
            await getUsCenters().then(
                (response) => {
                    if (response) {
                        let newCentersOptions = {}

                        response.data.forEach(element => {
                            if (element.commercialActivities !== APP_CONFIG.padelCenterActivity) {
                                if (tunnelType === "BIRTHDAY") {
                                    if (!centersToExcludeBirthday.find((id) => id === element.id)) {
                                        newCentersOptions[element.id] = element.name
                                    }
                                } else {
                                    newCentersOptions[element.id] = element.name
                                }
                            }
                        })
    
                        if (tunnelType === "TRAINING" || tunnelType === "SCHOOL") {
                            const customCenter = customAvailableCenter()
                            newCentersOptions[customCenter.id] = customCenter.name
                        }
                        setFormInStore(ChooseCenterAndType.formDataName, {
                            ...storedFormData,
                            centersOptions: newCentersOptions,
                        })
                        setCentersOptions(newCentersOptions)
                        sortCenters(newCentersOptions)
                    }
                },
            )
        }
    }

    /**
     * Lorsque l'utilisateur clique sur un type d'école, ses choix de centre et d'école sont soumis à validation et si complets, ils sont sauvegardés dans le store, sinon, le formulaire est supprimé du store.
     * @param {*} event
     */
    function onSelectedType(event, schoolInfos) {
        event.preventDefault()
        event.stopPropagation()
        const datas = event.currentTarget.id.split("-")
        const newTypeId = parseInt(datas[0])
        const newCenterId = parseInt(datas[1])
        const newConfigId = parseInt(datas[2]) 
        const oldTypeId = storedForm.typeId
        const oldCenterId = storedForm.centerId

        const formCreneau = forms[CreneauxDispo.formName]

        if (
            oldTypeId === newTypeId
            && oldCenterId === newCenterId
        ) {
            setSearchParams({})
            setFormInStore(ChooseCenterAndType.formName, {
                ...storedForm,
                centerId: undefined,
                configId: undefined,
                typeId: undefined,
            })
        } else {
            setFormInStore(CreneauxDispo.formName, {
                ...formCreneau,
                selectedTimeslot: undefined,
                selectedTimeslotId: undefined,
                selectedTimeslotValue: undefined,
            })

            if (schoolInfos) {
                step.current.setRecap(
                    <div>
                        {schoolInfos.name}
                        <br />
                        {schoolInfos.center}
                    </div>,
                )
            }

            if (formPersonnaliserAnnivData) {
                setFormInStore(PersonnaliserAnniv.formDataName, {
                    ...formPersonnaliserAnnivData,
                    selectedCake : {},
                    selectedDrink : {},
                    // can select only one option by category
                    selectedEnfantSupp : {},
                    
                    selectedGift : {},
                    
                    selectedGiftSize : null, 
                    
                    // can select multiple options
                    selectedOptionWithCategory : [], 
                    selectedOptions : [],
                })
            } else {
                setFormInStore(ChooseCenterAndType.formName, {
                    ...storedForm,
                    centerId: newCenterId,
                    configId: Number.isNaN(newConfigId) ? null : newConfigId,
                    typeId: newTypeId,
                })
                if (schoolInfos) {
                    step.current.setRecap(
                        <div>
                            {schoolInfos.name}
                            <br />
                            {schoolInfos.center}
                        </div>,
                    )
                }
            }

            const newState = {
                centerId: newCenterId,
                typeId: newTypeId,
            }

            if (newConfigId !== "undefined" && !Number.isNaN(newConfigId)) {
                newState.configId = newConfigId
            }

            setFormInStore(ChooseCenterAndType.formName, {
                ...storedForm,
                ...newState,
            })

            setSearchParams({
                ...searchParamsObject,
                ...newState,
            })
            setRecap()
        }
    }

    function setRecap() {
        if (selectedCenterId !== centerId && ((storedFormData.typesRecommendation && storedFormData.typesRecommendation.length > 0) || Object.values(storedFormData.typesOptions).length === 0)) {
            if (storedFormData.typesRecommendation) {
                storedFormData.typesRecommendation.forEach(
                    (el) => {
                        if (el.center.id === centerId) {
                            el.types.forEach(
                                (type) => {
                                    if (type.id === parseInt(typeId)) {
                                        step.current.setRecap(
                                            <div>
                                                {type.name}
                                                <br/>
                                                { 
                                                    tunnelType === "TRAINING" ?
                                                        displayCenterName(centerId, el.center.name)
                                                        :
                                                        el.center.name
                                                }
                                            </div>,
                                        )
                                    }
                                },
                            )
                        }
                    },
                )
            } 
            if (storedFormData.typesOptions) {
                let typeOptions = storedFormData.typesOptions
                if (typeId && typeOptions[typeId ? typeId : storedForm.selectedCenterId]) {
                    step.current.setRecap(
                        <div>
                            {!typeOptions[typeId ? typeId : storedForm.selectedCenterId].type ?
                                typeOptions[typeId ? typeId : storedForm.selectedCenterId].name
                                :
                                typesOptions[typeId ? typeId : storedForm.selectedCenterId].type ?
                                    tunnelType === "BIRTHDAY" ?
                                        storedFormData.typesOptions[typeId].name
                                        :
                                        storedFormData.typesOptions[typeId ? typeId : storedForm.selectedCenterId].type.name
                                    :
                                    ""
                            }
                            <br />
                            {selectedCenterName ? selectedCenterName : storedFormData.typesOptions[storedForm.typeId] ? storedFormData.typesOptions[storedForm.typeId].centerName  : ""}
                        </div>,
                    )
                }
            }
        } else {
            let typeOptions = storedFormData.typesOptions
            if (typeId && typeOptions[typeId ? typeId : storedForm.selectedCenterId]) {
                step.current.setRecap(
                    <div>
                        {!typeOptions[typeId ? typeId : storedForm.selectedCenterId].type ?
                            typeOptions[typeId ? typeId : storedForm.selectedCenterId].name
                            :
                            typesOptions[typeId ? typeId : storedForm.selectedCenterId].type ?
                                tunnelType === "BIRTHDAY" ? 
                                    storedFormData.typesOptions[typeId].name
                                    :
                                    storedFormData.typesOptions[typeId ? typeId : storedForm.selectedCenterId].type.name
                                :
                                ""
                        }
                        <br/>
                        {selectedCenterName ? selectedCenterName : storedFormData.typesOptions[storedForm.typeId] ? storedFormData.typesOptions[storedForm.typeId].centerName : ""}
                    </div>,
                )
            }
        }
    }

    /**
     * Récupère les écoles disponibles en fonction du centre choisit par l'utilisateur
     */
    function changeSelectedCenter({ initial }) {
        let selectCenterId
        if (select.current && !initial) {
            selectCenterId = select.current.value
        }
        if (selectCenterId) {
            setFormInStore(ChooseCenterAndType.formName, {
                ...store.getState().forms[ChooseCenterAndType.formName],
                selectedCenterId: selectCenterId,
            })
        }
        
        let storeSelectedCenterId = forms[ChooseCenterAndType.formName] ? forms[ChooseCenterAndType.formName].selectedCenterId : selectedCenterId

        if (storeSelectedCenterId !== "") {
            let data = {
                centerId: selectCenterId ?
                    selectCenterId
                    :
                    storeSelectedCenterId ?
                        storeSelectedCenterId
                        :
                        userLoaded.centerId,
            }
            gatherOptionsFromAPI(data)
        } else {
            setFormInStore(ChooseCenterAndType.formDataName,{
                ...storedFormData,
                typesOptions: [],
                typesRecommendation: [],
            })
        }
    }

    useEffect(() => {
        if (centersToExcludeBirthday.includes(parseInt(selectedCenterId)) && sortedCentersOptions.length > 0) {
            changeSelectedCenter(parseInt(sortedCentersOptions[0][0]))
        }
    }, [ selectedCenterId ])
    
    function sortCenters(centers) {
        let ignoreCenterIds = [ 1, 8, 17, 18, 21, 23, 35, 36, 38, 40 ]

        if (tunnelType === "TRAINING") {
            ignoreCenterIds = [ 8, 17, 18, 21, 23, 35, 36, 38, 40 ]
        }

        let newSortedCentersOptions = Object.keys(centers).filter(cent => !ignoreCenterIds.includes(parseInt(cent)))
            .map(centerId => [ centerId, centers[centerId] ])
            .sort(function (a, b) {
                return a[1] === b[1] ? 
                    0
                    :
                    a[1] < b[1] ?
                        -1
                        :
                        1
                
            })
        
        if (tunnelType === "TRAINING") {
            if (newSortedCentersOptions) {
                const autreSiteObjIdx = newSortedCentersOptions.findIndex((el) => el[0] === "1")
                if (autreSiteObjIdx && autreSiteObjIdx !== -1) {
                    const autreSiteObj = [ ...newSortedCentersOptions[autreSiteObjIdx] ]
        
                    newSortedCentersOptions.splice(autreSiteObjIdx, 1) // remove old el
                    newSortedCentersOptions.unshift(autreSiteObj)
                    // newSortedCentersOptions.splice(0, 0, autreSiteObj) // insert at beginning
                }
            }
        }
        if (tunnelType === "SCHOOL") {newSortedCentersOptions.unshift([ "1", "Autres sites" ])}
        setSortedCentersOptions(newSortedCentersOptions)
    }

    function gatherOptionsFromAPI(data) {
        // setEmptyMessage("");
        setAjaxLoading(true)
        let updatedStoredFormData = store.getState().forms[ChooseCenterAndType.formDataName]
        switch (tunnelType) {
        case "SCHOOL":
            getKidsSchoolFromCenter(data, themeHeader).then(
                (response) => {
                    if (response) {
                        let typesOptions = {}
                        if (response.types) {
                            response.types.forEach(
                                (el) => {
                                    typesOptions[el.id] = el
                                },
                            )
                        }
                        setFormInStore(ChooseCenterAndType.formDataName,{
                            ...updatedStoredFormData,
                            typesOptions: typesOptions,
                            typesRecommendation: response.centerArea,
                        })
                        if (response.centerArea) {setProximity({ centerArea: response.centerArea, id: response.center.id })}
                        else {setProximity(null)}
                        setAjaxLoading(false)
                    }
                })
            break
        case "BIRTHDAY":
            if (data.centerId && !centersToExcludeBirthday.includes(data.centerId)) {
                getCenterBirthdaysTypes(data.centerId).then(
                    (result) => {
                        let updatedResult = {}
                        result.forEach(
                            (el) => {
                                updatedResult[el.type.id] = el
                            },
                        )
                        setFormInStore(ChooseCenterAndType.formDataName,{
                            ...updatedStoredFormData,
                            typesOptions: updatedResult,
                            typesRecommendation: [],
                        })
        
                        setAjaxLoading(false)
                    },
                )
            }
            break
        case "TRAINING":
            getKidsStagesCenterTypes(data, themeHeader).then(
                (res) => {
                    let updatedResult = {}
                    if (res.data.data.types) {
                        res.data.data.types.forEach(
                            (el) => {
                                updatedResult[el.id] = { ...el, centerName: res.data.data.center.name }
                            },
                        )
                    }
                    setFormInStore(ChooseCenterAndType.formDataName,{
                        ...updatedStoredFormData,
                        typesOptions: updatedResult,
                        typesRecommendation: res.data.data.centerArea ? res.data.data.centerArea : [],
                    })
                    if (res.data.data.centerArea) {setProximity({ centerArea: res.data.data.centerArea, id: res.data.data.center.id })}
                    else {setProximity(null)}
                    setAjaxLoading(false)
                },
            )
            break
        default:
            // eslint-disable-next-line no-console
            console.error(
                "gatherOptionsFromAPI NOT IMPLEMENTED tunnelType : %o",
                tunnelType,
            )
            // eslint-disable-next-line no-debugger
            debugger
            break
        }
    }

    function centerTypeElementDOM(type, center) {
        let typeId 
        let configId
        switch (tunnelType) {
        case "SCHOOL":
            typeId = type.id
            break

        case "BIRTHDAY":
            typeId = type.type.id
            configId = type.id
            break

        case "TRAINING":
            typeId = type.id
            break
        }

        return (
            <CheckBlock
                callback={(e) => onSelectedType(
                    e, tunnelType === "SCHOOL" ? { center: center.name, name: type.name } : null,
                )}
                checked={
                    parseInt(typeId) === parseInt(chosenType)
                    && parseInt(center.id) === parseInt(chosenCenterId)
                }
                id={ typeId + "-" + center.id + "-" + configId }
                value={ typeId + "-" + center.id + "-" + configId }
                name="typeIds"
                key={ typeId + "-" + center.id + "-" + configId }
            >
                <SchoolCenterTypeDisplay
                    tunnelType={tunnelType}
                    type={
                        configId ?
                            type.type
                            :
                            type
                    }
                    center={center}
                    birthdayName={tunnelType === "BIRTHDAY" ? type.name : ""}
                />
                
            </CheckBlock>
        )
    }

    function getReturnText() {
        switch (tunnelType) {
        case "BIRTHDAY":
            return "Voir mes anniversaires"
        case "SCHOOL":
            return "Voir mes écoles"
        case "TRAINING":
            return "Voir mes inscriptions"
        }
    }

    function getBelowText() {
        switch (tunnelType) {
        case "TRAINING":
            return (
                <p>
                        Plus d'informations sur les prochains stages sur notre site&nbsp;
                    <a href="https://www.urbansoccer.fr/kids-stages/" target="_blank" rel="noreferrer">www.urbansoccer.fr</a>
                </p>
            )
        default:
            return
        }
    }
    useEffect(()=> {
        if (sortedCentersOptions.length && selectedCenterId && typesOptionsArray) {setInitialized(true)}      
    }, [ sortedCentersOptions, selectedCenterId, typesOptionsArray ])

    return (
        <Step
            {...props}
            title={title}
            help={ help }
            helpId={"ecoleDeFootHelp"}
            className={classNames("chooseCenterAndType noMarginBottom", { anniversaire: tunnelType === "BIRTHDAY" })}
            onReturn={
                () => {
                    navigate(path(listingIdentifier))
                }
            }
            noReturn={noReturn}
            returnText={getReturnText()}
            ref={step}
        >
            <PreForm>
                <label className="h2">
                    Sélectionnez votre centre :
                    <SelectInput
                        id={"schoolPicker"}
                        label="Centre"
                        required
                        placeholderOption="Sélectionnez un centre"
                        value={selectedCenterId}
                        onChange={changeSelectedCenter}
                        options={ sortedCentersOptions?.map(center => ({ label: center[1], value: center[0] })) }
                        ref={select}
                    />
                </label>
            </PreForm>
            <div className="formsHolder c-row layoutSmall">
                <div className="c-col c-col--11 c-col--sm--12">
                    <FormValidator onValidation={validateStep} onUnvalidation={unvalidateStep} ref={form} >
                        <div className="radioList sm--checkbox">
                            {typesOptionsArray === null || (typesOptionsArray?.length === 0 && selectedCenterId) && (
                                <React.Fragment>
                                    <h2 className="h2 text--centered--sm customH2ChooseCenterAndType"
                                        dangerouslySetInnerHTML={{ __html: selectedCenterEmptyErrorMessage(selectedCenterName) }}>
                                    </h2>
                                    {getBelowText()}
                                </React.Fragment>
                            )}
                            {(ajaxLoading || !initialized) &&
                                <Preloader />
                            }
                            {(!ajaxLoading && selectedCenterId && selectedCenterName && typesOptionsArray && typesOptionsArray.length > 0) &&
                                typesOptionsArray.map(
                                    (el) => (
                                        centerTypeElementDOM(
                                            el,
                                            {
                                                id: selectedCenterId,
                                                name: tunnelType === "TRAINING" ? 
                                                    displayCenterName(selectedCenterId, selectedCenterName)
                                                    :
                                                    selectedCenterName,
                                            },
                                        )
                                    ),
                                )}

                        </div>

                        {(!ajaxLoading && (typesRecommendation && typesRecommendation.length > 0) && initialized && selectedCenterName) &&
                            (<div className="radioList sm--checkbox">
                                <h2 className="h2 text--centered--sm customH2ChooseCenterAndType">À découvrir également à proximité de {selectedCenterName}&nbsp;:</h2>
                                {recommendationsDOM}
                            </div>)
                        }
                    </FormValidator>
                </div>
            </div>
        </Step>
    )
}

ChooseCenterAndType.formName = "choixEcole"
ChooseCenterAndType.formDataName = "ecoleData"
ChooseCenterAndType.propTypes = {
    initialCenterId: oneOfType([ number, string ]),
    initialTypeId: oneOfType([ number, string ]),
    noReturn: bool,
    numero: number,
    tunnelType: string,
}

export default ChooseCenterAndType
