import { _apiCall } from "../../../../backend/axios"

export function invitationChangeState(invitationId, value) {
    return _apiCall(
        "modify/invitations/" + invitationId + "/changeState?value=" + value,
        "PUT",
        {
            "Content-Type": "application/json",
        },
    ).then(res => res)
}

export function remindPendingPlayers(locationId)  {
    return _apiCall(
        "create/reservation/" + locationId + "/attendees/remind",
        "POST",
    ).then((response) => {
        return response ? response.data.data : []
    })
}

export function getMVP(locationId)  {
    return _apiCall(
        "read/reservation/" + locationId + "/mvp",
        "GET",
    ).then((response) => {
        return response ? response.data.data : null
    })
}

export function voteForMVP(locationId, data)  {
    return _apiCall(
        "modify/reservation/" + locationId + `/mvp?playerId=${data}`,
        "PUT",
    ).then((response) => {
        return response ? response.data.data : ""
    })
}

export function deleteMVPVote(locationId)  {
    return _apiCall(
        "delete/reservation/" + locationId + "/mvp",
        "DELETE",
    ).then((response) => {
        return response ? response.data.data : ""
    })
}
