import { createBrowserHistory } from "history"
import { createStore } from "redux"

import allReducers from "./reducers/allReducers"

// Create a browser history object
const history = createBrowserHistory()

// Configure your Redux store
export const store = createStore(
    allReducers,
)

if (window.Cypress) {
    window.store = store
}

export default store

export { history }
