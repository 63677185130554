import { Outlet } from "react-router-dom"

import RoutesCup from "./padel/RoutesCup"
import RoutesProfile from "./padel/RoutesProfile"
import RoutesSchoolTraining from "./padel/RoutesSchoolTraining"
import RoutesPayments from "./payments/RoutesPayments.jsx"
import generateRoutesReservations from "./RoutesReservations.jsx"
import Preloader from "../components/loaders/preloader/preloader"
import NotFound from "../components/notFound/notFound"
import ConfirmationCmd from "../entries/main/contents/main/helpers/ConfirmationCmd"
import NotificationsSettings from "../entries/main/contents/main/notifications/notificationsSettings"
import Home from "../pages/home/home"
import OrderSummarySchool from "../pages/kids/steps/OrderSummary"
import OrderSummary from "../pages/payments/OrderSummary/OrderSummary"

const RoutesPadel = [
    // PROFILES
    ...RoutesProfile,
    // RESERVATIONS
    ...generateRoutesReservations("padel"),
    // KIDS
    ...RoutesSchoolTraining,
    // CUP
    ...RoutesCup,
    {
        element: (<OrderSummary />),
        path: "/padel/ordersummary",
    },
    {
        element: (<ConfirmationCmd />),
        path: "/padel/confirmcommand",
    },
    {
        element: (<OrderSummarySchool />),
        path: "/padel/schoolordersummary",
    },
    {
        element: (<Home />),
        path: "/padel",
    },
    {
        element: (<Preloader fixed />),
        path: "/padel/reload/*",
    },
    {
        element: (<NotificationsSettings />),
        path: "/padel/notificationsSettings",
    },
    {
        children: [
            ...RoutesPayments,
        ],
        element: <Outlet />,
        path: "/padel",
    },
    {
        element: (<NotFound />),
        path: "/padel/*",
    },
]

export default RoutesPadel
