import axios from "axios"

export const apiLog = (type, data) => axios.post("/api/create/log", {
    type: type ?? "LOG",
    ...data,
    url: document.location.href,
    userAgent: navigator.userAgent,
}).catch()

window.onerror = function (
    message,
    source,
    line,
    col,
    error,
) {
    apiLog("ERROR", { col, error, line, message, source })
}
