import { string } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import CheckBlock from "../../../../components/Forms/CheckBlock/CheckBlock"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import {
    useSchoolTrainingCurrent, useSchoolTrainingTunnel,
} from "../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import Step from "../../../../components/stepsManagement/Step"
import resetScroll from "../../../../services/resetScroll"
import { nextStep } from "../../../../store/stepsManagement/actions"
import { getKidsSchoolFromCenters } from "../../../kids/ecoleDeFoot/api"
import { getKidsStagesFromCenters } from "../../../kids/stageDeFoot/api"
import NoSchoolTraining from "../SlotChoice/components/NoSchoolTraining/NoSchoolTraining"

import "./ServiceChoice.scss"

export default function ServiceChoice(props) {
    const { title } = props

    const [ initialized, setInitialized ] = useState(false)
    const [ searchParams, setSearchParams ] = useSearchParams()
    const currentStep = useSelector(state => state.stepManagement.currentStep)
    const [ loading, setLoading ] = useState(false)

    const serviceChoiceRef = useRef(null)
    const navigate = useNavigate()
    const currentSelection = useSchoolTrainingCurrent()
    const { value: tunnelType, setValue: setTunnelType } = useSchoolTrainingTunnel()
    const dispatch = useDispatch()
    const { value: { types, selectedAreaId, prestation, areas, isAdult }, setValue: setCurrentSelection } = currentSelection

    // get types from selected area and aggregate them in one array, adding a type property to each item to differentiate them
    useEffect(() => {
        setLoading(true)
        setCurrentSelection(prev => ({ ...prev, isGlobalLoading: true }))
        const centerIdsFromArea = areas?.find(area => area.id === parseInt(selectedAreaId))?.centers
        if (selectedAreaId && areas && centerIdsFromArea) {
            Promise.all([
                getKidsSchoolFromCenters(centerIdsFromArea, isAdult ? 1 : 0),
                getKidsStagesFromCenters(centerIdsFromArea, isAdult ? 1 : 0),
            ]).then(([ schoolsResponse, stagesResponse ]) => {
                const schoolsArray = Array.isArray(schoolsResponse)
                    ? schoolsResponse.map(item => ({ ...item, type: "SCHOOL" }))
                    : []
                const stagesArray = Array.isArray(stagesResponse)
                    ? stagesResponse.map(item => ({ ...item, type: "TRAINING" }))
                    : []
                setCurrentSelection(prev => ({ ...prev, types: [ ...schoolsArray, ...stagesArray ] }))
            }).finally(() => {
                setCurrentSelection(prev => ({ ...prev, isGlobalLoading: false }))
                setLoading(false)
            })
        }
    }, [ selectedAreaId, areas, isAdult ])

    // step validation
    useEffect(() => {
        if (prestation && types?.some(type => type.value === prestation.value)) {
            serviceChoiceRef?.current?.validate()
            serviceChoiceRef?.current?.setRecap(
                <div>
                    {prestation.label}
                </div>,
            )
        } else {
            setCurrentSelection(prev => ({ ...prev, prestation: undefined }))
            serviceChoiceRef?.current?.unvalidate()
        }
    }, [ prestation, types ])
    
    const handleSelect = (value) => {
        setTunnelType(value?.type)
        setCurrentSelection(prev => ({ ...prev, prestation: value }))
        dispatch(nextStep())
    }

    useEffect(() => {
        if (types?.length) {
            if (prestation) {
                setSearchParams(params => {
                    params.set("prestation", prestation.value)
                    params.set("type", prestation.type)
                    return params
                })
            } else {
                const prestaParams = searchParams.get("prestation")
                const typeParams = searchParams.get("type")
                if (!initialized && prestaParams && typeParams) {
                    const matchingPresta = types?.find(type => type.type === typeParams && type.value === parseInt(prestaParams))
                    if (matchingPresta) {
                        setTunnelType(matchingPresta.type)
                        setCurrentSelection(prev => ({ ...prev, prestation: matchingPresta }))
                        setInitialized(true)
                    } else {
                        setInitialized(true)
                    }
                } else {
                    setInitialized(true)
                }
            }
        }
    }, [ prestation, types ])

    useEffect(() => {
        const areAdultInfoComplete = searchParams.get("adulte") === "oui"
            && searchParams.get("areaId")
            && searchParams.get("prestation")
            && searchParams.get("type")
        if (areAdultInfoComplete && initialized && currentStep !== 0) {
            dispatch(nextStep())
        }
    }, [ initialized ])

    useEffect(() => {
        resetScroll()
    }, [ currentStep ])

    return (
        <Step
            {...props}
            title={title}
            help={<PhoneCallHelp tunnelType={tunnelType ?? "TRAINING"}/> }
            helpId={"serviceChoiceHelp"}
            className="serviceChoice"
            onReturn={
                () => {
                    navigate("/padel/coursStages/inscription")
                }
            }
            ref={serviceChoiceRef}
        >
            <div className="formsHolder noHPadding c-row justify-center layoutSmall">
                <div className="c-col c-col--8 c-col--sm--12 radioList sm--checkbox">
                    {!loading && types?.map(type => (
                        <CheckBlock
                            callback={() => handleSelect(type)}
                            checked={
                                prestation?.value === type?.value
                            }
                            id={ type.type + "-" + type.value + "-" + selectedAreaId }
                            value={ type.type + "-" + type.value + "-" + selectedAreaId }
                            name="typeIds"
                            key={ type.type + "-" + type.value + "-" + selectedAreaId }
                        >
                            <>
                                <div className="radioList__item--logo hide-sm">
                                    <img src={type.pictureUrl ?? "/assets/images/padel/logo/logo-black-alt.svg"} alt={type.label}/>
                                </div>

                                <div className="radioList__item--content">
                                    <div className="radioList__item--logo--small">
                                        <img src={type.pictureUrl ?? "/assets/images/padel/logo/logo-black-alt.svg"} alt={type.label}/>
                                    </div>
                                    <div className={"radioList__item__title"}>
                                        {type.label}
                                    </div>
                                    <div className="radioList__item__desc">
                                        <p dangerouslySetInnerHTML={{ __html: type.description }}/>
                                    </div>
                                </div>
                            </>
                        </CheckBlock>
                    ))}
                </div>
            </div>
            {!loading && types.length === 0 && (
                <NoSchoolTraining />
            )}
            {loading && <Preloader fixed/>}
        </Step>
    )
}

ServiceChoice.propTypes = {
    title: string,
}
