import { useDispatch } from "react-redux"
import { useMatch, useNavigate } from "react-router-dom"

import useMediaPath from "../../hooks/useMediaPath"
import { LeaguePath } from "../../routes/ApplicationPaths/LeaguePaths.js"
import { clearAllForms } from "../../store/forms/actions"
import { useTheme } from "../ThemeContext/ThemeContext.jsx"

function Logo() {
    const mediaPath = useMediaPath()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useTheme()
    const shouldPreventRedirect = theme === "theme-business" && !!useMatch(LeaguePath.leagueInfos + "/" + LeaguePath.leagueInfosHome)

    return (
        <a id="cy-home-btn" data-testid="logo" onClick={(e) =>  {
            e.stopPropagation()
            e.preventDefault()

            if (shouldPreventRedirect) {
                return
            }

            dispatch(clearAllForms())
            navigate(mediaPath([ "/", "/", LeaguePath.leagueInfosBase ]))
        }} >
            <img src={mediaPath([
                "/assets/images/Logo-MyUrban-Soccer-2022.svg",
                "/assets/images/padel/logo/Logo-MyUrban-Padel-2022.svg",
                "/assets/images/business/logo-business.png",
            ])} />
        </a>
    )
}

export default Logo
