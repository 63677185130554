import { bool, number, oneOfType, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import CheckBlock from "../../../../components/Forms/CheckBlock/CheckBlock"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import CategoryCheckHolder from "../../../../components/reservation/PrestaSupp/CategoryCheckHolder"
import OptionCheckboxContent from "../../../../components/reservation/PrestaSupp/OptionCheckboxContent"
import Step from "../../../../components/stepsManagement/Step"
import Tooltip from "../../../../components/tooltip/Tooltip"
import useAddSearchParam from "../../../../hooks/useAddSearchParams"
import useIsMobile from "../../../../hooks/useIsMobile"
import useResetScroll from "../../../../hooks/useResetScroll"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import { setFormEntry } from "../../../../store/forms/actions"
import { nextStep, skipStep } from "../../../../store/stepsManagement/actions"
import { slugify } from "../../../../utilities/helpers.js"
import { addStageOption, deleteStageOption, getKidsStageDetail } from "../../../kids/stageDeFoot/api"
import { addLeagueOption, deleteLeagueOption } from "../../../league/api/api.js"
import { addCupOption, removeCupOption } from "../api"

import "./PrestationSupp.scss"

function PrestationSupp(props) {
    useResetScroll()
    const { tunnelType, goToPreviousStep, updateSearchParams } = props
    const step = useRef(null)
    const tournamentRegistration = useSelector(state => state.forms.TournamentRegistration)
    const stageSchoolRegistration = useSelector(state => state.forms.ozmoSubscription)
    const optionsProducts = useSelector(state => state.forms.optionsProducts)
    const avoirFormData = useSelector(state => state.forms.avoirFormData)
    const [ optionProductsByCategory, setOptionProductsByCategory ] = useState(null)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const dispatch = useDispatch()
    const addSearchParams = useAddSearchParam()

    const themeHeader = useThemeHeader()

    const help = <PhoneCallHelp tunnelType={tunnelType} />

    const isMobile = useIsMobile()

    let registration
    switch (tunnelType) {
    case "CUP":
        registration = tournamentRegistration
        break

    case "TRAINING":
        if (stageSchoolRegistration) {
            registration = stageSchoolRegistration.firstSubscription
        }
        break

    case "LEAGUE":
        registration = tournamentRegistration
        break
    default:
        // eslint-disable-next-line no-console
        console.error("PrestationSupp not implemented tunnelType : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        break
    }
    
    useEffect(
        () => {
            let optionsByCategory = {}
            let handleOptions = (option) => {
                if (optionsByCategory[option.category]) {
                    optionsByCategory[option.category].push(option)
                } else {
                    optionsByCategory[option.category] = []
                    optionsByCategory[option.category].push(option)
                }
            }
            if (registration) {
                switch (tunnelType) {
                case "CUP":
                    registration?.cup?.optionProducts?.filter(
                        x => x.showAtPayment === false,
                    ).forEach(handleOptions)
                    break

                case "TRAINING":
                    if (optionsProducts) {
                        optionsProducts.filter(
                            x => x.showAtPayment === false,
                        ).forEach(handleOptions)
                    } else {
                        setAjaxLoading(true)
                        getKidsStageDetail(registration.id).then(
                            (stage) => {
                                dispatch(
                                    setFormEntry(
                                        "hasOption",
                                        stage.optionProducts.length !== 0,
                                    ),
                                )
                                dispatch(
                                    setFormEntry(
                                        "optionsProducts",
                                        stage.optionProducts,
                                    ),
                                )
                                dispatch(
                                    setFormEntry(
                                        "catalogProducts",
                                        stage.catalogProducts,
                                    ),
                                )
                                dispatch(
                                    setFormEntry(
                                        "hasCatalogProducts",
                                        stage.catalogProducts.length !== 0,
                                    ),
                                )
                                setAjaxLoading(false)
                            },
                        )
                    }
                    break

                case "LEAGUE":
                    registration.league.optionProducts?.filter(
                        x => x.showAtPayment === false,
                    ).forEach(handleOptions)
                    break
                }
            }
            if (Object.keys(optionsByCategory).length === 0) { // Si vide on passe l'étape
                dispatch(
                    skipStep(),
                )
            }
            setOptionProductsByCategory(optionsByCategory)
        }, [ registration ],
    )

    function storeTournamentResult(res) {
        setAjaxLoading(false)
        dispatch(
            setFormEntry(
                "TournamentRegistration",
                res.data.data,
            ),
        )
    }

    function storeStageResult(res) {
        setAjaxLoading(false)
        dispatch(
            setFormEntry(
                "ozmoSubscription", {
                    firstSubscription: res.data.data,
                },
            ),
        )
    }
    function clickOnOption(event, name) {
        const optionCategory = name ?? event.currentTarget.name
        const optionId = event.currentTarget.id.replace("opt-", "")
        setAjaxLoading(true)

        let addMethod
        let removeMethod
        let storeMethod

        switch (tunnelType) {
        case "CUP":
            addMethod = addCupOption
            removeMethod = removeCupOption
            storeMethod = storeTournamentResult
            break

        case "TRAINING":
            addMethod = addStageOption
            removeMethod = deleteStageOption
            storeMethod = storeStageResult
            break
        case "LEAGUE":
            addMethod = addLeagueOption
            removeMethod = deleteLeagueOption
            storeMethod = storeTournamentResult
        }
        if (optionCategory === "null") {
            if (registration.optionProducts?.filter(x => x.id === parseInt(optionId)).length === 1) {
                removeMethod(registration.id, optionId, themeHeader).then(storeMethod)
            } else {
                addMethod(registration.id, optionId, themeHeader).then(storeMethod)
            }
        } else {
            let alreadyTakenOption = registration.optionProducts.find(x => x.id === parseInt(optionId))
            if (alreadyTakenOption) {
                removeMethod(registration.id, alreadyTakenOption.id, themeHeader).then(storeMethod)
            } else {
                if (registration?.optionProducts?.length) {
                    let alreadyHaveAnotherOption
                    registration.optionProducts.forEach(option => {
                        const alreadyHaveOptionInSameCategory = optionProductsByCategory[name].find(product => product.id === option.id)
                        if (alreadyHaveOptionInSameCategory) {
                            alreadyHaveAnotherOption = option
                        }
                    })
                    if (alreadyHaveAnotherOption) {
                        removeMethod(registration.id, alreadyHaveAnotherOption.id, themeHeader).then(() => addMethod(registration.id, optionId, themeHeader).then(storeMethod))
                    } else {
                        addMethod(registration.id, optionId, themeHeader).then(storeMethod)
                    }
                } else {
                    addMethod(registration.id, optionId, themeHeader).then(storeMethod)
                }
            }
        }
    }

    function storeCupInfo() {
        dispatch(
            setFormEntry("avoirFormData", {
                ...avoirFormData,
                recalcAvoir: true,
            }))
    }

    const optProductsByCatArray = optionProductsByCategory ? Object.keys(optionProductsByCategory) : []
    const idx = optProductsByCatArray.findIndex((v) => v === "null")
    if (idx !== -1) {
        optProductsByCatArray.splice(idx, 1)
        optProductsByCatArray.push("null")
    }

    function isEven(idx) {
        return idx % 2 === 0
    }

    const handleSearchParams = () => {
        addSearchParams("optionId", registration?.optionProducts?.length ? registration.optionProducts[0]?.id : "none")
        dispatch(nextStep())
    }

    const optionTitle = category => category === "null" && optProductsByCatArray.length === 1 ? "Options" : category

    return (
        <Step
            {...props}
            className="noMarginBottom padelCupPres"
            title="Prestations Supplémentaires"
            help={help}
            helpId={"prestaHelp"}
            onValidation={storeCupInfo}
            goToPreviousStep={goToPreviousStep}
            checkBeforeNextStep={updateSearchParams ? handleSearchParams : undefined}
            optionnal
            ref={step}
        >
            {ajaxLoading &&
                <Preloader fixed={true} />
            }
            {optionProductsByCategory &&
                optProductsByCatArray.map(
                    (category) => (
                        <CategoryCheckHolder
                            title={category === "null" && optProductsByCatArray.length > 1 ?
                                "Autres options"
                                :
                                optionTitle(category)
                            }
                            bordered={true}
                            key={category}
                        >
                            {
                                optionProductsByCategory[category].map(
                                    (option, idx) => (
                                        <React.Fragment key={category + "-" + option.id}>
                                            <CheckBlock
                                                callback={clickOnOption}
                                                checkbox={category === "null"}
                                                name={category}
                                                disabled={option.isFull}
                                                checked={registration.optionProducts?.filter(x => x.id === option.id).length === 1}
                                                value={"opt-" + option.id}
                                            >
                                                <OptionCheckboxContent
                                                    category={category}
                                                    option={option}
                                                    idx={idx}
                                                    isMobile={isMobile}
                                                />
                                            </CheckBlock>
                                            {(isMobile &&
                                                (
                                                    idx === (optionProductsByCategory[category].length - 1) ||
                                                    (!isEven(idx) && idx !== 0)
                                                )
                                            ) &&
                                                (<React.Fragment>
                                                    {
                                                        (idx >= 1 && !isEven(idx) && optionProductsByCategory[category][idx - 1].description) &&
                                                        (<Tooltip
                                                            isLeft
                                                            id={"infoBubble-supp-category-" + slugify(category) + "-" + (idx - 1)}
                                                        >
                                                            <p dangerouslySetInnerHTML={{ __html: optionProductsByCategory[category][idx - 1].description }}></p>
                                                        </Tooltip>)
                                                    }
                                                    {
                                                        option.description &&
                                                        (<Tooltip
                                                            isLeft={idx === 0 || isEven(idx)}
                                                            id={"infoBubble-supp-category-" + slugify(category) + "-" + idx}
                                                        >
                                                            <p dangerouslySetInnerHTML={{ __html: option.description }}></p>
                                                        </Tooltip>)
                                                    }
                                                </React.Fragment>)
                                            }
                                        </React.Fragment>
                                    ),
                                )
                            }
                        </CategoryCheckHolder>
                    ),
                )
            }
        </Step>
    )
}

PrestationSupp.propTypes = {
    goToPreviousStep: oneOfType([ bool, number ]),
    tunnelType: string,
    updateSearchParams: bool,
}

export default PrestationSupp
