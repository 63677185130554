import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useSearchParams } from "react-router-dom"

import { changePassword } from "./api"
import AuthStep from "../../../components/authentification/authStep/authStep"
import AuthStepper from "../../../components/authentification/authStepper/authStepper"
import PasswordComplexity from "../../../components/authentification/passwordComplexity/passwordComplexity"
import ButtonCta from "../../../components/Buttons/button/button-cta"
import Preloader from "../../../components/loaders/preloader/preloader"
import Modal from "../../../components/Modal/Modal"
import { useThemeUpdate } from "../../../components/ThemeContext/ThemeContext"
import useAppRedirect from "../../../hooks/useAppRedirect"
import useThemeHeader from "../../../hooks/useThemeHeader"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import usePath from "../../../routes/services/usePath"
import { toggleLevel3Overlay } from "../../../store/actions/content"
import { toggleShowFooter } from "../../../store/actions/scaffold"
import { clearAllForms } from "../../../store/forms/actions"

import "./ChooseNewPassword.scss"

function ChooseNewPassword() {
    const [ complexity, setComplexity ] = useState(0)
    const [ password, setPassword ] = useState("")
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ errorTitle, setErrorTitle ] = useState(null)
    const [ errorMsg, setErrorMsg ] = useState(null)

    const location = useLocation()
    const setTheme = useThemeUpdate()
    const themeHeader = useThemeHeader()
    const path = usePath()
    const dispatch = useDispatch()

    const appRedirect = useAppRedirect()
    const [ searchParams ] = useSearchParams()
    
    if (location?.pathname === "/padel/reload/passwordReset") {setTheme("theme-padel")}

    const beforeClose = () => {
        dispatch(
            toggleLevel3Overlay(
                false,
                null,
            ),
        )
        appRedirect(path("/login"))
    }

    const openModal = () => {
        ModalHandler.show(errorMsgPopIn, {
            beforeClose : beforeClose,
            msg: errorMsg,
            title: errorTitle,
        })
    }

    return (
        <React.Fragment>
            {ajaxLoading && (
                <Preloader
                    fixed={true}
                />
            )}
            <AuthStepper
                title={"Réinitialiser ton mot de passe"}
                prevAction={() => {
                    dispatch(clearAllForms())
                    dispatch(
                        toggleLevel3Overlay(
                            false,
                            null,
                        ),
                    )
                    appRedirect(path("/login"))
                }}
                rootClasses="women-bkg changePassword"
                help={<p>Tu rencontres un problème pour te connecter ou créer un compte ? Contacte-nous au 0 806 23 00 24</p>}
            >
                <AuthStep>
                    <PasswordComplexity
                        introText='Choisis un nouveau mot de passe.'
                        onPasswordChange={
                            (changedPassword) => {
                                setPassword(changedPassword)
                            }
                        }
                        onComplexityChange={
                            (data) => {
                                setComplexity(data)
                            }
                        }
                        onFocus={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(false))
                            }
                        }}
                        onBlur={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(true))
                            }
                        }}
                        label="Mot de passe"
                        isMaterialInput={true}
                    />
                    <ButtonCta
                        isAlt={true}
                        text="Valider"
                        disabled={complexity !== 3 && complexity !== 4}
                        onClick={
                            () => {
                                setAjaxLoading(true)
                                changePassword(
                                    searchParams.get("token"),
                                    password,
                                    themeHeader,
                                ).then(
                                    (res) => {
                                        setAjaxLoading(false)
                                        if (res) {
                                            const apiMsg = res?.data?.data?.Message
                                            if (apiMsg || res?.status === 401) {
                                                setErrorTitle("Erreur du lien")
                                                setErrorMsg(<p>{apiMsg || "Problème lors de l'enregistrement."}<br /><br />Tu seras redirigé vers la page de connexion lors de la fermeture de cette pop-in.</p>)
                                                openModal()
                                            } else {
                                                appRedirect(path("/login?passwordChanged=true"))
                                            }
                                        } else {
                                            appRedirect(path("/login?passwordChanged=false"))
                                        }
                                    },
                                ).catch(() => {
                                    setErrorTitle("Erreur du lien")
                                    setErrorMsg(<p>Problème lors de l'enregistrement.<br /><br />Tu seras redirigé vers la page de connexion lors de la fermeture de cette pop-in.</p>)
                                    openModal()
                                })
                            }
                        }
                    />
                </AuthStep>
            </AuthStepper>
        </React.Fragment>
    )
}

const errorMsgPopIn = ModalHandler.create(({ title, msg, beforeClose, visible, hide }) => {
    return (
        <Modal
            hideOnOverlayClicked
            beforeClose={beforeClose}
            className="password-change-error"
            isOpen={visible}
            onClose={hide}
        >
            <header>{title}</header>
            <span className="errorMsg">{msg}</span>
        </Modal>
    )
})

export default ChooseNewPassword
