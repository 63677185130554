import dayjs from "dayjs"
import { bool } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import CreneauxDispo from "./CreneauxDispo"
import ActivationTrigger from "../../../../components/Buttons/Triggers/ActivationTrigger"
import CheckBlock from "../../../../components/Forms/CheckBlock/CheckBlock"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import CategoryCheckHolder from "../../../../components/reservation/PrestaSupp/CategoryCheckHolder"
import OptionCheckboxContent from "../../../../components/reservation/PrestaSupp/OptionCheckboxContent"
import Step from "../../../../components/stepsManagement/Step"
import HourDisplay from "../../../../components/Time/HourDisplay"
import Tooltip from "../../../../components/tooltip/Tooltip"
import {
    addOptionToSchool,
    addSchoolDiscountCode,
    deleteSchoolDiscountCode,
    getAllKidsSchoolsSubscriptions,
    getKidsSchoolDetail,
    getKidsSchoolSubscription,
    getKidsSchools,
} from "../../../../globalAPI/api"
import useAddSearchParam from "../../../../hooks/useAddSearchParams"
import useIsMobile from "../../../../hooks/useIsMobile"
import useMediaPath from "../../../../hooks/useMediaPath"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import days from "../../../../model/enums/dayValues"
import dateToYear from "../../../../services/dateToYear"
import TimeSort from "../../../../services/Sorting/TimeSort"
import { addDiscountCode, addSchoolOptionInStore, removeSchoolOption, removeSecondSchoolSubscription, setSecondSchoolSubscription } from "../../../../store/cart/actions"
import { setFormEntry } from "../../../../store/forms/actions"
import { skipStep } from "../../../../store/stepsManagement/actions.js"
import { slugify } from "../../../../utilities/helpers.js"
import MesEnfants from "../../steps/MesEnfants"
import { cancelSchoolRegistration, deleteSchoolOption, editSchoolSecondSubscription } from "../api"

import "./PrestationSupp.scss"

function PrestationSupp(props) {
    const { updateSearchParams } = props
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ actualSubscriptions, setActualSubscriptions ] = useState(undefined)
    const [ remainingTimeslots, setRemainingTimeslots ] = useState({})
    const [ timeslotsWithDiscount, setTimeslotsWithDiscount ] = useState([])

    const [ optionsToDisplay, setOptionsToDisplay ] = useState(undefined)

    const forms = useSelector(state => state.forms)
    const cart = useSelector(state => state.cart)
    const checkSkipStepEDF = useSelector(state => state.checkSkipStepEDF)
    const stepManagement = useSelector(state => state.stepManagement)
    const creneauDataForm = useSelector(state => state.forms[CreneauxDispo.formDataName])
    const ozmoSubscription = useSelector(state => state.forms.ozmoSubscription)
    const firstSubscription = useSelector(state => state.forms.ozmoSubscription.firstSubscription)

    const dispatch = useDispatch()
    const mediaPath = useMediaPath()
    const themeHeader = useThemeHeader()
    const isMobile = useIsMobile()
    const addSearchParams = useAddSearchParam()

    const step = useRef()

    const handleSearchParams = (value) => {
        addSearchParams("optionId", value)
    }

    const secondSubId = (ozmoSubscription && ozmoSubscription.secondSubscription) ?
        ozmoSubscription.secondSubscription.id
        :
        undefined

    const selectedTimeslotToRemove = firstSubscription?.school
    const optionProducts = firstSubscription?.optionProducts

    let child = firstSubscription ? firstSubscription.kid : {}
    let childName =  child?.firstName ? " pour " + child.firstName  : ""
    const actualKidSubscriptionsIds = creneauDataForm ?
        creneauDataForm.actualKidSubscriptionsIds !== undefined ?
            creneauDataForm.actualKidSubscriptionsIds
            :
            []
        :
        []

    // CHILD FORM
    const enfants = forms[MesEnfants.formName]
    const selectedKidId = enfants?.selectedKid?.id
    const suppSchoolData = forms[PrestationSupp.formNameData]
    const secondSchoolSubscription = cart?.secondSchoolSubscription !== undefined ?
        cart.secondSchoolSubscription
        :
        {
            id: undefined,
        }

    let { categories } = suppSchoolData || { categories: {} }
    const help = <PhoneCallHelp tunnelType="SCHOOL" />

    function setFormInStore(name, form) {
        dispatch(setFormEntry(name, form))
    }

    useEffect(
        () => {
            const periodStart = dayjs(new Date()).subtract(3, "year").format("YYYY-MM-DD")
            if (actualKidSubscriptionsIds === undefined) {
                getAllKidsSchoolsSubscriptions(periodStart, null, "[2,1,3]", false, 0, null, themeHeader).then(
                    (subscriptions) => {
                        setActualSubscriptions(subscriptions)
                    },
                )
            } else {
                sortSubIdAndGetTimeslots()
            }

            if (secondSubId) {
                getKidsSchoolSubscription(secondSubId).then(
                    (secondSub) => {
                        dispatch(
                            setSecondSchoolSubscription(secondSub.school.id, secondSub.amountTotal),
                        )
                    },
                )
            }
        }, [],
    )

    useEffect(
        () => {
            if (optionProducts) {
                optionProducts.forEach(
                    (el) => {
                        if (el.category === null) {
                            dispatch(
                                addSchoolOptionInStore("Autres", el.id + "", el.price),
                            )
                        } else {
                            dispatch(
                                addSchoolOptionInStore(el.category, el.id + "", el.price),
                            )
                        }
                    },
                )
            }
        }, [ optionProducts ],
    )

    useEffect(
        () => {
            let categories = {}

            // Je reformate les options pour les trier par categories
            const getOptionsByCategory = (el) => {
                if (el?.showAtPayment !== true) {
                    if (el.category === null) {
                        if (categories["Autres"] === undefined) {
                            categories["Autres"] = {}
                        }
                        categories["Autres"][el.id] = el
                    } else {
                        if (categories[el.category] === undefined) {
                            categories[el.category] = {}
                        }
                        categories[el.category][el.id] = el
                    }
                }
            }

            const storeData = () => {
                setFormInStore(
                    PrestationSupp.formNameData, {
                        categories: categories,
                    },
                )
            }

            // timeslots
            if (forms[CreneauxDispo.formName]?.selectedTimeslot) {
                forms[CreneauxDispo.formName].selectedTimeslot.schoolDetails?.optionProducts?.forEach(getOptionsByCategory)
                storeData()
            } else if (forms[CreneauxDispo.formName].selectedTimeslotId && forms[CreneauxDispo.formDataName].timeslots) {
                forms[CreneauxDispo.formDataName].timeslots[forms[CreneauxDispo.formName].selectedTimeslotId]?.schoolDetails?.optionProducts?.forEach(getOptionsByCategory)
                storeData()
            } else if (selectedTimeslotToRemove) {
                getKidsSchoolDetail(firstSubscription.school.id).then(
                    (res) => {
                        res?.data?.optionProducts?.forEach(getOptionsByCategory)
                        storeData()
                    },
                )
            }

            let newRemainingTimeslots = {}
            let promotedSecondTimeSlots = null
            if (selectedTimeslotToRemove && creneauDataForm?.timeslots && firstSubscription.school.discount === 0) {
                Object.values(creneauDataForm.timeslots).forEach(
                    element => {
                        if (element.value !== selectedTimeslotToRemove.id) {
                            newRemainingTimeslots[element.value] = element
                        }
                    },
                )
                const otherTimeSlots = Object.values(newRemainingTimeslots).sort(TimeSort.sortByAscSchoolDay)
                promotedSecondTimeSlots = otherTimeSlots.filter(slot => slot.schoolDetails.promotedForSecondRegistration)
            }

            if (Object.keys(categories).length === 0 && !promotedSecondTimeSlots) { // Si vide on passe l'étape
                dispatch(
                    skipStep(),
                )
            }
        }, [ firstSubscription, forms[CreneauxDispo.formName], forms[CreneauxDispo.formDataName] ],
    )

    useEffect(
        () => {
            handleheaderHeight(isMobile)
        }, [ isMobile ],
    )

    useEffect(
        () => {
            sortSubIdAndGetTimeslots()
        }, [ actualSubscriptions ],
    )

    // Defined remaining timeslot by removing the old that's selected before
    useEffect(
        () => {
            let newRemainingTimeslots = {}
            if (selectedTimeslotToRemove && creneauDataForm?.timeslots && firstSubscription.school.discount === 0) {
                Object.values(creneauDataForm.timeslots).forEach(
                    element => {
                        if (element.value !== selectedTimeslotToRemove.id) {
                            newRemainingTimeslots[element.value] = element
                        }
                    },
                )
                const otherTimeSlots = Object.values(newRemainingTimeslots).sort(TimeSort.sortByAscSchoolDay)
                const promotedSecondTimeSlots = otherTimeSlots.filter(slot => slot.schoolDetails.promotedForSecondRegistration)
                setRemainingTimeslots(promotedSecondTimeSlots)
            }
        }, [ firstSubscription, creneauDataForm ],
    )

    // When remainingTimeslot change setTimeslotWithDiscount
    useEffect(
        () => {
            const newTimeslotsWithDiscount = []
            const remainingTimeslotsArray = remainingTimeslots ? Object.values(remainingTimeslots) : []

            let firstIsWeak = false
            let weakDiscount = 0

            if (selectedTimeslotToRemove && firstSubscription.school.promotedForSecondRegistration === true) {
                if (forms[CreneauxDispo.formName].selectedTimeslot) {
                    forms[CreneauxDispo.formName].selectedTimeslot.schoolDetails.discountCodes.forEach(
                        discountCode => {
                            if (discountCode.secondRegistrationDiscount) {
                                firstIsWeak = true
                                weakDiscount = discountCode.amount
                            }
                        },
                    )
                }
            }
            if (remainingTimeslotsArray.length > 0) {
                remainingTimeslotsArray.forEach(
                    (el, idx) => {
                        let price = el.schoolDetails.amountTotal

                        if (!firstIsWeak) {
                            if (el.schoolDetails.promotedForSecondRegistration === true) {
                                el.schoolDetails.discountCodes.forEach(
                                    discountCode => {
                                        if (discountCode.secondRegistrationDiscount) {
                                            price -= discountCode.amount
                                        }
                                    },
                                )
                            }
                        } else {
                            price -= weakDiscount
                        }
                        newTimeslotsWithDiscount.push(
                            <CheckBlock
                                callback={addSecondSubscription}
                                checked={parseInt(el.value) === parseInt(secondSchoolSubscription.id)}
                                disabled={el.schoolDetails.full || actualKidSubscriptionsIds.includes(el.schoolDetails.id)}
                                name="timeslots"
                                value={el.value}
                                key={el.value}
                            >
                                {(el.schoolDetails.description && !actualKidSubscriptionsIds.includes(el.schoolDetails.id) && !el.schoolDetails.full) &&
                                    (<div className="infoBubble">
                                        {!isMobile &&
                                            (<Tooltip id={"infoBubble-2nd-supp-" + idx}>
                                                <p dangerouslySetInnerHTML={{ __html: el.schoolDetails.description }}></p>
                                            </Tooltip>)
                                        }
                                        <ActivationTrigger id="buttonInfoBullePrestaSup" target={"#infoBubble-2nd-supp-" + idx}>
                                            <div className="infoBubble__icon">
                                                <img src={mediaPath([ "/assets/icons/info-small.svg", "/assets/images/padel/icons/icons-info.svg" ])} alt="info"/>
                                            </div>
                                        </ActivationTrigger>
                                    </div>)
                                }
                                <div className="radioList__item__subTitle">
                                    {days[el.schoolDetails.day]}
                                </div>
                                <div className="radioList__item__price">
                                    <HourDisplay minutes={el.schoolDetails.hour} /> - <HourDisplay minutes={el.schoolDetails.hour + el.schoolDetails.rentalDuration} /> | {el.schoolDetails.resourceType === "Filmé indoor" ? "Intérieur" : el.schoolDetails.resourceType}
                                    <br />
                                    {actualKidSubscriptionsIds.includes(el.schoolDetails.id) ?
                                        <strong>Déjà inscrit{child?.gender === "Fille" ? "e" : ""}</strong>
                                        :
                                        el.schoolDetails.full ?
                                            <strong>COMPLET</strong>
                                            :
                                            price > 0 && <strong>{price + " €"}</strong>
                                    }

                                </div>
                            </CheckBlock>,
                        )

                        if (isMobile) {
                            if (idx === (remainingTimeslotsArray.length - 1) || (!isEven(idx) && idx !== 0)) {
                                if (idx >= 1 && !isEven(idx)) {
                                    const previousEl = remainingTimeslotsArray[idx - 1]
                                    if (previousEl.schoolDetails.description) {
                                        newTimeslotsWithDiscount.push(
                                            <Tooltip isLeft={(idx - 1) === 0 || isEven(idx - 1)} id={"infoBubble-2nd-supp-" + (idx - 1)}>
                                                <p dangerouslySetInnerHTML={{ __html: el.schoolDetails.description }}></p>
                                            </Tooltip>,
                                        )
                                    }
                                }
                                if (el.schoolDetails.description) {
                                    newTimeslotsWithDiscount.push(
                                        <Tooltip isLeft={idx === 0 || isEven(idx)} id={"infoBubble-2nd-supp-" + idx}>
                                            <p dangerouslySetInnerHTML={{ __html: el.schoolDetails.description }}></p>
                                        </Tooltip>,
                                    )
                                }
                            }
                        }
                    },
                )
            }
            setTimeslotsWithDiscount(newTimeslotsWithDiscount)
        }, [ remainingTimeslots, cart, isMobile ],
    )

    // On Category change
    useEffect(
        () => {
            // On retire les categories incluant uniquement des options à montrer lors du payement
            let filteredCategories = {}

            Object.keys(categories).forEach(
                (category) => {
                    let categoryOptions = []
                    Object.values(categories[category]).forEach(
                        (option) => {
                            if (!option.showAtPayment) {
                                categoryOptions.push(option)
                            }
                        },
                    )
                    filteredCategories[category] = categoryOptions
                },
            )

            if (Object.values(filteredCategories).length) {
                Object.keys(filteredCategories).forEach(
                    (category) => {
                        const options = []
                        let optionsDisplay = []
                        const filteredCategoriesArray = Object.values(filteredCategories[category])
                        filteredCategoriesArray.forEach(
                            (el, idx) => {
                                if (!el.showAtPayment) {
                                    options.push(
                                        <CheckBlock
                                            callback={(e) => clickOnOption(e, category)}
                                            checkbox={category === "Autres"}
                                            checked={cart.options[category] ? cart.options[category].includes(el.id + "") : false}
                                            name={category}
                                            value={"opt-" + el.id}
                                            key={"secSub-" + el.id}
                                        >
                                            <OptionCheckboxContent
                                                category={category}
                                                option={el}
                                                idx={idx}
                                                isMobile={isMobile}
                                            />
                                        </CheckBlock>,
                                    )

                                    if (isMobile) {
                                        if (idx === (filteredCategoriesArray.length - 1) || (!isEven(idx) && idx !== 0)) {
                                            if (idx >= 1 && !isEven(idx)) {
                                                const previousEl = filteredCategoriesArray[idx - 1]
                                                if (previousEl.description) {
                                                    options.push(
                                                        <Tooltip
                                                            isLeft
                                                            id={"infoBubble-supp-category-" + slugify(category) + "-" + (idx - 1)}
                                                            key={"infoBubble-supp-category-" + slugify(category) + "-" + (idx - 1)}
                                                        >
                                                            <p dangerouslySetInnerHTML={{ __html: previousEl.description }}></p>
                                                        </Tooltip>,
                                                    )
                                                }
                                            }
                                            if (el.description) {
                                                options.push(
                                                    <Tooltip
                                                        isLeft={idx === 0 || isEven(idx)}
                                                        id={"infoBubble-supp-category-" + slugify(category) + "-" + idx}
                                                        key={"infoBubble-supp-category-" + slugify(category) + "-" + idx}
                                                    >
                                                        <p dangerouslySetInnerHTML={{ __html: el.description }}></p>
                                                    </Tooltip>,
                                                )
                                            }
                                        }
                                    }
                                }
                            },
                        )

                        if (options.length) {
                            optionsDisplay.push(
                                <CategoryCheckHolder
                                    title={category === "Autres" ?
                                        "Autres options"
                                        :
                                        category
                                    }
                                    bordered={true}
                                    key={category}
                                >
                                    {options}
                                </CategoryCheckHolder>,
                            )
                            setOptionsToDisplay(optionsDisplay)
                        }

                    },
                )
            }
        }, [ categories, cart ],
    )

    function sortSubIdAndGetTimeslots() {
        if (creneauDataForm === undefined) {
            let actualKidSubscriptionsIds = []
            if (actualSubscriptions !== undefined) {
                actualSubscriptions.forEach(
                    subscription => {
                        if (subscription.kid !== undefined) {
                            if (subscription.kid.id === selectedKidId) {
                                actualKidSubscriptionsIds.push(subscription.school.id)
                            }
                        }
                    },
                )
            }
            let data = {
                birthYear: dateToYear(firstSubscription.kid.birthDate.split("/")[2]),
                "centersId[0]": firstSubscription.school.centerId,
                typeId: firstSubscription.school.typeId,
            }

            getKidsSchools(data).then(
                (res) => {
                    const timeslots = {}
                    res?.sessions?.forEach(timeslot => {
                        timeslots[timeslot.value] = timeslot
                    })

                    setFormInStore(
                        CreneauxDispo.formDataName, {
                            ...creneauDataForm,
                            actualKidSubscriptionsIds: actualKidSubscriptionsIds,
                            timeslots: timeslots,
                        },
                    )
                },
            )
        }
    }

    function addSecondSubscription(event) {
        const oldCorrelationId = cart.secondSchoolSubscription ? cart.secondSchoolSubscription.id : undefined
        const selectedTimeslot = creneauDataForm.timeslots[event.currentTarget.id]
        const selectedTimeslotDetails = selectedTimeslot.schoolDetails

        let correlationId = event.currentTarget.id
        if (oldCorrelationId === correlationId) {
            correlationId = undefined
        }

        dispatch(
            setSecondSchoolSubscription(correlationId, selectedTimeslotDetails.amountTotal),
        )
        if (selectedTimeslotDetails.promotedForSecondRegistration) {
            selectedTimeslotDetails.discountCodes.forEach(discountCode => {
                if (discountCode.secondRegistrationDiscount) {
                    dispatch(
                        addDiscountCode(discountCode.code),
                    )
                }
            })
        }
    }

    function clickOnOption(event, optionCategory) {
        const optionId = event.currentTarget.id.replace("opt-", "")
        if (optionCategory !== "Autres") {
            if (cart.options[optionCategory] === optionId) {
                removeSchoolOption(optionCategory, optionId)
            } else {
                if (cart.options[optionCategory] !== undefined) {
                    dispatch(
                        removeSchoolOption(optionCategory, cart.options[optionCategory]),
                    )
                    dispatch(
                        addSchoolOptionInStore(optionCategory, optionId, suppSchoolData.categories[optionCategory][optionId].price),
                    )
                } else {
                    dispatch(
                        addSchoolOptionInStore(optionCategory, optionId, suppSchoolData.categories[optionCategory][optionId].price),
                    )
                }

            }
        } else {
            if (cart.options[optionCategory] !== undefined) {
                if (cart.options[optionCategory].includes(optionId)) {
                    dispatch(
                        removeSchoolOption(optionCategory, optionId),
                    )
                } else {
                    dispatch(
                        addSchoolOptionInStore(optionCategory, optionId, suppSchoolData.categories[optionCategory][optionId].price),
                    )
                }
            } else {
                dispatch(
                    addSchoolOptionInStore(optionCategory, optionId, suppSchoolData.categories[optionCategory][optionId].price),
                )
            }
        }
    }

    function ozmoRegistration() {
        return new Promise(
            (resolve) => {
                const ozmoSubscription = forms.ozmoSubscription
                const enfants = forms[MesEnfants.formName]
                const timeslots = creneauDataForm ? creneauDataForm.timeslots : undefined
                const selectedKid = enfants.selectedKid
                const firstSubscription = ozmoSubscription.firstSubscription
                const firstSubId = firstSubscription.id
                const secondSubSchoolId = cart.secondSchoolSubscription ? cart.secondSchoolSubscription.id : undefined
                const isResultOK = (res) => {
                    return (res.data.data.subscription !== null && res.data !== null) && (res.data.data.message === "" || res.data.data.message === undefined)
                }

                const avoirFormData = forms.avoirFormData !== undefined ?
                    forms.avoirFormData
                    : {}
                if (secondSubSchoolId === undefined) {
                    setAjaxLoading(true)
                    if (ozmoSubscription.secondSubscription !== undefined) {
                        cancelSchoolRegistration(ozmoSubscription.secondSubscription.id).then(
                            async () => {
                                // Si la premiere inscription était un créneau faible on supprime le code promo
                                await firstSubscription.discountCodes.forEach(
                                    code => {
                                        if (code.secondRegistrationDiscount) {
                                            deleteSchoolDiscountCode(firstSubscription.id, code.code).then(
                                                (res) => {
                                                    if (isResultOK(res)) {
                                                        setFormInStore("ozmoSubscription", {
                                                            ...forms["ozmoSubscription"],
                                                            firstSubscription: res.data.data,
                                                            secondSubscription: undefined,
                                                        })
                                                    }
                                                },
                                            )
                                        }
                                    },
                                )

                                dispatch(
                                    removeSecondSchoolSubscription(),
                                )

                                handleOptions().then(
                                    () => {
                                        setAjaxLoading(false)
                                        setFormInStore("avoirFormData", {
                                            ...avoirFormData,
                                            recalcAvoir: true,
                                        })
                                        resolve()
                                    },
                                )
                            },
                        )
                    } else {
                        handleOptions().then(
                            () => {
                                setAjaxLoading(false)
                                setFormInStore("avoirFormData", {
                                    ...avoirFormData,
                                    recalcAvoir: true,
                                })
                                resolve()
                            },
                        )
                    }
                    setAjaxLoading(false)
                } else if (secondSubSchoolId !== undefined && timeslots[secondSubSchoolId] !== undefined) {
                    let secSubSchoolDetails = timeslots[secondSubSchoolId].schoolDetails
                    let dataSub2 = {
                        child: selectedKid?.id,
                        dailySlot: secondSubSchoolId,
                        firstSubscriptionId: firstSubId,
                        waitingForAvailability: secSubSchoolDetails.waitingListOpened && secSubSchoolDetails.full,
                    }

                    setAjaxLoading(true)

                    const secondSubEdit = async (firstSub) => {
                        await firstSubscription?.discountCodes?.forEach(
                            code => {
                                if (code.secondRegistrationDiscount) {
                                    deleteSchoolDiscountCode(firstSubscription.id, code.code).then(
                                        (res) => {
                                            if (isResultOK(res)) {
                                                setFormInStore("ozmoSubscription", {
                                                    ...forms["ozmoSubscription"],
                                                    firstSubscription: res.data.data,
                                                    secondSubscription: undefined,
                                                })
                                            }
                                        },
                                    )
                                }
                            },
                        )
                        editSchoolSecondSubscription(dataSub2).then(
                            (res1) => {
                                setFormInStore("ozmoSubscription", {
                                    ...forms["ozmoSubscription"],
                                    firstSubscription: firstSub,
                                    secondSubscription: res1.data,
                                })

                                setAjaxLoading(false)

                                handleOptions().then(
                                    () => {
                                        setAjaxLoading(false)
                                        setFormInStore("avoirFormData", {
                                            ...avoirFormData,
                                            recalcAvoir: true,
                                        })
                                        resolve()
                                    },
                                )

                            },
                        )
                    }

                    let dailySlot
                    if (forms[CreneauxDispo.formName]) {
                        if (forms[CreneauxDispo.formName].selectedTimeslot) {
                            dailySlot = forms[CreneauxDispo.formName].selectedTimeslot
                        }
                    }
                    let firstIsWeak = false
                    if (dailySlot !== undefined) {
                        if (dailySlot.schoolDetails.promotedForSecondRegistration) {
                            if (forms[CreneauxDispo.formName].selectedTimeslot) {
                                forms[CreneauxDispo.formName].selectedTimeslot.schoolDetails.discountCodes.forEach(
                                    discountCode => {
                                        if (discountCode.secondRegistrationDiscount) {
                                            firstIsWeak = true
                                        }
                                    },
                                )
                            }
                        }
                    }

                    if (firstIsWeak) {
                        dailySlot?.schoolDetails?.discountCodes?.forEach(
                            discountCode => {
                                if (discountCode.secondRegistrationDiscount) {
                                    addSchoolDiscountCode(firstSubId, discountCode.code).then(
                                        (res) => {
                                            if (res.data.data.subscription) {
                                                setFormInStore("ozmoSubscription", {
                                                    ...forms["ozmoSubscription"],
                                                    firstSubscription: res.data.data.subscription,
                                                })
                                            }
                                            secondSubEdit(res.data.data.subscription)
                                        },
                                    )
                                }
                            },
                        )
                    } else {
                        secondSubEdit(firstSubscription)
                    }

                } else {
                    handleOptions().then(
                        () => {
                            setAjaxLoading(false)
                            setFormInStore("avoirFormData", {
                                ...avoirFormData,
                                recalcAvoir: true,
                            })
                            resolve()
                        },
                    )
                }
            },
        )
    }

    function handleOptions() {
        setAjaxLoading(true)
        return new Promise(
            (resolv) => {
                const firstSub = forms.ozmoSubscription.firstSubscription
                const firstSubId = firstSub.id
                let currentOptions = []

                let optionsToDelete = []
                let optionsToAdd = []

                // On récupére les options à supprimer
                Object.values(firstSub.optionProducts).forEach(
                    (el) => {
                        let category = el.category
                        if (category === null) {
                            category = "Autres"
                        }
                        if (category === "Autres") {
                            if (!cart.options[category].includes(el.id + "")) {
                                optionsToDelete.push(el.id + "")
                            } else {
                                currentOptions.push(el.id + "")
                            }
                        }
                        else {
                            if (cart.options[category] === el.id) {
                                optionsToDelete.push(el.id + "")
                            } else {
                                currentOptions.push(el.id + "")
                            }
                        }
                    },
                )

                // On récupére les options à ajouter
                Object.values(cart.options).forEach(
                    (categoryOption) => {
                        if (typeof (categoryOption) === "object") {
                            categoryOption.forEach(optionId => {
                                if (!currentOptions.includes(optionId)) {
                                    optionsToAdd.push(optionId + "")
                                }
                            })
                        } else {
                            if (!currentOptions.includes(categoryOption)) {
                                optionsToAdd.push(categoryOption + "")
                            }
                        }
                    },
                )

                // Promise qui se resolve à la fin d'une une fonction recursive permettant de supprimer les options les unes après les autres
                function deleteAllOptions(optionsToDelete) {
                    let index = 0
                    return new Promise(
                        (resolve) => {
                            if (optionsToDelete.length === 0) {
                                resolve()
                            }
                            function x(i) {
                                deleteSchoolOption(firstSubId, optionsToDelete[i]).then(
                                    (res) => {
                                        setFormInStore("ozmoSubscription", {
                                            ...forms["ozmoSubscription"],
                                            firstSubscription: res,
                                        })
                                        if (i + 1 < optionsToDelete.length) {
                                            x(i + 1)
                                        }
                                        else {
                                            resolve()
                                        }
                                    },
                                )
                            }
                            x(index)
                        },
                    )
                }

                // Promise qui se resolve à la fin d'une une fonction recursive permettant d'ajouter les options les unes après les autres
                function addAllOptions(optionsToAdd) {
                    let index = 0
                    return new Promise(
                        (resolve) => {
                            if (optionsToAdd.length === 0) {
                                resolve()
                            } else {
                                // eslint-disable-next-line no-inner-declarations
                                function x(i) {
                                    addOptionToSchool(firstSubId, optionsToAdd[i]).then(
                                        (resopt) => {
                                            setFormInStore("ozmoSubscription", {
                                                ...forms["ozmoSubscription"],
                                                firstSubscription: resopt,
                                            })
                                            if (i + 1 < optionsToAdd.length) {
                                                x(i + 1)
                                            }
                                            else {
                                                resolve()
                                            }
                                        },
                                    )
                                }
                                x(index)
                            }
                        },
                    )
                }
                // On supprime si besoin puis ajoute les options
                if (optionsToDelete.length === 0) {
                    if (optionsToAdd.length !== 0) {
                        addAllOptions(optionsToAdd).then(
                            () => {
                                if (updateSearchParams) {
                                    handleSearchParams(optionsToAdd[0])
                                }
                                resolv()
                            },
                        )
                    } else {
                        if (updateSearchParams) {
                            handleSearchParams("aucune")
                        }
                        resolv()
                    }
                }
                else {
                    deleteAllOptions(optionsToDelete).then(
                        () => {
                            addAllOptions(optionsToAdd).then(
                                () => {
                                    handleSearchParams(optionsToAdd?.length ? optionsToAdd[0] : "aucune")
                                    resolv()
                                },
                            )
                        },
                    )
                }
            },
        )
    }

    function isEven(idx) {
        return idx % 2 === 0
    }

    function handleheaderHeight(isMobile) {
        const btn = document.getElementById("previous-step-btn")
        if (isMobile) {
            closeAllInfoBulle()
            const stepHeader = document.getElementsByClassName("stepHeader")[0]

            btn.style.height = stepHeader.offsetHeight + "px"
        } else {
            btn.style.height = ""
        }
    }

    function closeAllInfoBulle() {
        const buttonTooltipPrestaSup = document.querySelectorAll("#buttonInfoBullePrestaSup")
        buttonTooltipPrestaSup.forEach(btn => {
            if (btn?.classList.contains("active")) {
                btn.click()
            }
        })
    }

    return (
        <Step
            {...props}
            className="noMarginBottom padelCupPres"
            title="Prestations Supplémentaires"
            help={help}
            helpId={"prestaSuppHelp"}
            ref={step}
            onValidation={ozmoRegistration}
            cls="arrows-on-sides noMarginBottom prestaSupp"
            promise={true}
            optionnal={true}
            otherId='4'
            goToStep={
                checkSkipStepEDF ?
                    checkSkipStepEDF.skipEquipment ?
                        stepManagement.currentStep + 2
                        :
                        null
                    : null
            }
        >
            { ajaxLoading && <Preloader fixed /> }
            { timeslotsWithDiscount?.length > 0 && (
                <CategoryCheckHolder
                    title="Option 2ème séance"
                    text={`Encore plus de fun${childName}. Réserver une 2ème session hebdomadaire :`}
                    bordered={optionsToDisplay?.length > 0}
                >
                    {timeslotsWithDiscount}
                </CategoryCheckHolder>
            )}
            {categories && optionsToDisplay}
        </Step>
    )
}

PrestationSupp.propTypes = {
    updateSearchParams: bool,
}

PrestationSupp.formName = "suppSchoolForm"
PrestationSupp.formNameData = "suppSchoolData"

export default PrestationSupp
