import { number, oneOfType, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { acceptSupersubReponse, completeSuperSubRequest, getSuperSubsRequestResponses, refuseSupersubReponse } from "./api"
import Preloader from "../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import { getLocation } from "../../../globalAPI/api"
import useIsMobile from "../../../hooks/useIsMobile"
import superSubResponseStatus from "../../../model/enums/superSubResponseStatus"
import { useLocationStore } from "../../../pages/reservation/store/store"
import CandidatureSupersub  from "../../../pages/supersub/candidatureSupersub/candidatureSupersub"
import usePath from "../../../routes/services/usePath"
import { clearAllForms, setFormEntry } from "../../../store/forms/actions"
import { nextStep } from "../../../store/stepsManagement/actions"
import CheckBlock from "../../Forms/CheckBlock/CheckBlock"
import LocationInfoBar from "../../LocationInfoBar/LocationInfoBar"
import Step from "../../stepsManagement/Step"
import BlocInfoSuperSub from "../BlocInfoSuperSub/BlocInfoSuperSub"

import "./SelectSuperSub.scss"

function SelectSuperSub({ locationId }) {
    const path = usePath()

    /* STORE */
    /* istanbul ignore next */
    const candidatureSuperSubData = useSelector(state => state.forms[CandidatureSupersub.formName])
    const { setCurrentLocation, setLocationComposition } = useLocationStore()
    /* VARIABLES */
    const [ location, setLocation ] = useState({})
    const [ currentSupersubRequest, setCurrentSupersubRequest ] = useState({})
    const [ supersubRequestsResponses, setSupersubRequestsResponses ] = useState([])
    const [ selectedResponse, setSelectedResponse ] = useState({})
    const [ noReponses, setNoReponses ] = useState(false)

    const [ acceptingReponse, setAcceptingReponse ] = useState(false)

    const [ initializeAppData, setInitializeAppData ] = useState(false)

    /* PAGINATION */
    const [ offset, setOffset ] = useState(0)
    const [ dataCount, setDataCount ] = useState(0)
    const [ hasMoreData, setHasMoreData ] = useState(true)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const PAGE_SIZE = 50
    const isMobile = useIsMobile()
    const help = <PhoneCallHelp tunnelType="SUPERSUB"/>

    const step = useRef(null)

    const validateStep = () => {
        step?.current?.validate?.()
    }

    /* ACTIONS */
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ , updateState ] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }

    useEffect(
        () => {
            validateStep()
            refreshData()
        }, [],
    )

    useEffect(
        () => {
            const infiniteScrollEl = document.querySelector(".infinite-scroll-component")
            /* istanbul ignore else */
            if (infiniteScrollEl) {
                const moreDataAndNoScroll = (hasMoreData && infiniteScrollEl.scrollHeight <= infiniteScrollEl.clientHeight)
                /* istanbul ignore else */
                if (moreDataAndNoScroll) {
                    fetchData()
                }
            }
        }, [ dataCount, supersubRequestsResponses ],
    )

    function refreshData() {
        /* istanbul ignore else */
        if (locationId) {
            setAjaxLoading(true)
            getLocation(locationId).then(
                (res) => {
                    setLocation(res)
                    if (res.supersubRequests && res.supersubRequests.length > 0) {
                        const lastRequest = res.supersubRequests[
                            res.supersubRequests.length - 1
                        ]
                        setCurrentSupersubRequest(lastRequest)

                        const previouslyAcceptedSupersubResponses = res?.invites.filter(
                            i => i.supersubResponse && i.supersubResponse.status === 1 && i.supersubResponse.request.id !== lastRequest.id,
                        ).map(
                            i => i.supersubResponse,
                        )

                        getSuperSubsRequestResponses(
                            lastRequest.id,
                            offset,
                            PAGE_SIZE,
                        ).then(
                            (resRresp) => {
                                setSupersubRequestsResponses(
                                    previouslyAcceptedSupersubResponses.concat(resRresp.items),
                                )

                                setNoReponses(
                                    !!(resRresp.items && resRresp.items.length === 0),
                                )

                                setHasMoreData(
                                    (offset + PAGE_SIZE < resRresp.hitCount),
                                )

                                setDataCount(resRresp.hitCount)
                            },
                        ).finally(
                            () => {
                                setAjaxLoading(false)
                            },
                        )
                    } else {
                        setAjaxLoading(false)
                    }
                },
            )
        }
    }

    function isRefused(r) {
        return r.status === -1
    }

    function buildCheckBloc(r) {
        return (
            <CheckBlock
                callback={() => {
                    clickOnResponse(r)
                }}
                checked={selectedResponse.id === r.id}
                key={r.id}
                name={r.id}
                value={r.id}
                disabled={isRefused(r)}
                cls={
                    "checkblockResponses" + (isRefused(r) ?
                        " grey"
                        :
                        ""
                    )
                }
            >

                <div className={ r.contact.supersubPlayed === 0 ? "checkblockResponsesContent newSupersub" : "checkblockResponsesContent"}>

                    <div className="left">
                        <img src={r.contact.pictureUrl ? r.contact.pictureUrl :  "/assets/images/user-placeholder-gray.png"} className="picture"/>
                    </div>
                    <div className="center">
                        <div className="name">{r.contact.firstname} {r.contact.lastname.charAt(0)}.</div>

                        {
                            /* istanbul ignore next */
                            r.contact.supersubPlayed === 0 ?
                                (<div className="noMatchPlayed">
                                        Nouveau SuperSub
                                </div>)
                                :
                                (<div className="bottomCenter">
                                    <div className="rating">
                                        <div className="round-rating">{r.contact.supersubRating ? r.contact.supersubRating : "-"}</div>
                                        <div className="rating-label">Note</div>
                                    </div>
                                    <div className="right">
                                        <div className="matchPlayed">
                                            <div className="nb">{r.contact.supersubPlayed ? r.contact.supersubPlayed : 1}</div>
                                            <div className="matchPlayed-label">Match{r.contact.supersubPlayed > 1 ? "s" : ""} joué{r.contact.supersubPlayed > 1 ? "s" : ""}</div>
                                        </div>
                                    </div>
                                </div>)
                        }

                    </div>

                    <div className="responseStatus">
                        {superSubResponseStatus[r.status]}
                    </div>
                </div>
            </CheckBlock>
        )
    }

    function handleMobileBlocInfo(resp) {
        const updatedResp = []
        resp.forEach(
            (sr) => {
                updatedResp.push(
                    buildCheckBloc(sr),
                )
                updatedResp.push(
                    <div className="c-col c-col--3 c-col--sm--12 tooltip-sup-res-info-card"
                        id={"sup-res-info-card-" + (sr.id)} key={"sup-res-info-card-" + (sr.id)}>
                        {!acceptingReponse && !ajaxLoading &&
                            (<BlocInfoSuperSub
                                response={sr}
                                confirmAction={confirmAction}
                                refuseAction={refuseAction}
                            />
                            )}
                    </div>,

                )

            },
        )
        return updatedResp
    }

    function clickOnResponse(r) {

        setSelectedResponse(r)
        closeAllTooltip()
        const resp = document.querySelector("#sup-res-info-card-" + r.id)

        if (selectedResponse.id === r.id) {
            setSelectedResponse({})
            resp?.classList?.remove?.("active")
        } else {
            setSelectedResponse(r)
            resp?.classList?.add?.("active")
        }

        setFormInStore(CandidatureSupersub.formName, {
            ...candidatureSuperSubData,
            selectedResponse: selectedResponse.id === r.id ? {} : r,
        })
    }

    function closeAllTooltip() {
        const tooltips = document.querySelectorAll(".tooltip-sup-res-info-card")
        tooltips?.forEach(t => {
            t.classList.remove("active")
        })
    }

    function confirmAction() {
        setCurrentLocation({})
        setLocationComposition({})
        if (currentSupersubRequest.isFree && currentSupersubRequest.reservation.amountDue > 0) {
            setFormInStore("supersubForm", {
                response: selectedResponse,
                supersubRequest: currentSupersubRequest,
            })

            dispatch(nextStep())
            // empreinte paiement dans l'étape suivante
        } else {
            // api call
            // validation supersub response
            setAcceptingReponse(true)
            acceptSupersubReponse(selectedResponse.id).then(() => {

                const displayConfirm = () => {
                    displayConfirmCmd(
                        "Félicitations&nbsp;!",
                        "Nous te confirmons la convocation de ton SuperSub.",
                        true,
                        "organizerAcceptSupersub",
                        "/reservations/locations/" + locationId + "/candidatureSupersub/",
                        "locations",
                    )
                }

                getLocation(locationId).then(
                    (res) => {
                        const lastRequest = res.supersubRequests[res.supersubRequests.length - 1]
                        if (
                            res.amountDue === 0
                            && res.supersubRequests
                            && res.supersubRequests.length > 0
                            && lastRequest.status !== 1
                        ) {
                            completeSuperSubRequest(lastRequest.id).then(
                                () => {
                                    setAcceptingReponse(false)
                                    displayConfirm()
                                },
                            )
                        } else {
                            setAcceptingReponse(false)
                            displayConfirm()
                        }
                    },
                )

            })
        }

    }

    function refuseAction() {
        setAcceptingReponse(true)
        refuseSupersubReponse(selectedResponse.id).then(
            () => {
                setAcceptingReponse(false)
                setCurrentLocation({})
                displayConfirmCmd(
                    "Convocation refusée&nbsp;!",
                    "",
                    true,
                    "organizerRefuseSupersub",
                    "/reservations/locations/" + locationId + "/candidatureSupersub/",
                    "locations",
                    selectedResponse,
                )
            },
        )
    }

    function displayConfirmCmd(title, subtitle, success, message, customLink, customContentIdentifier, supersubPlayer) {
        navigate(path("/confirmcommand"), {
            state: {
                customContentIdentifier : customContentIdentifier,
                customLink: customLink,
                message: message,
                subtitle: subtitle,
                success: success,
                supersubPlayer: supersubPlayer,
                title: title,
                tunnelType: "SUPERSUB",
            },
        })
    }
    /* istanbul ignore next */
    function gatherSuperSubRequests(offset = 0) {
        getSuperSubsRequestResponses(
            currentSupersubRequest.id,
            offset,
            PAGE_SIZE,
        ).then(
            (resRresp) => {
                /* istanbul ignore else */
                if (resRresp) {
                    const data = resRresp.items
                    const newData = supersubRequestsResponses.concat(data)
                    /* istanbul ignore next */
                    if (
                        data.length === 0 ||
                        (newData.length === dataCount)
                    ) {
                        setHasMoreData(false)
                    }

                    setSupersubRequestsResponses(newData)
                }
            },
        )
    }

    function fetchData() {
        const newOffset = offset + PAGE_SIZE
        if (newOffset <= dataCount) {
            gatherSuperSubRequests(newOffset)
            setOffset(newOffset)
        } else {
            setHasMoreData(false)
        }
    }

    /* istanbul ignore else */
    if (candidatureSuperSubData?.selectedResponse) {
        const resp = document.querySelector("#sup-res-info-card-" + candidatureSuperSubData.selectedResponse.id)
        if (!initializeAppData && resp) {
            setInitializeAppData(true)
            resp.classList.add("active")
        }
    }

    return (
        <Step
            title="SELECTIONNER UN SUPERSUB"
            help={ help }
            helpId="selectSuperSubHelp"
            className="chooseCenterAndType selectSuperSub"
            onReturn={
                () => {
                    dispatch(clearAllForms())
                    setCurrentLocation({})
                    navigate(path("/reservations/locations/" + locationId + "/joueurs"))
                }
            }
            returnText="Précedent"
            ref={step}
            otherId="scrollableSuperSubResponses"
            hideNextBtn={true}
        >
            <div className="formsHolder c-row justify-center align-start
            layoutSmall c-col c-col--10 c-col--sm--12" id="selectSuperSub">
                <div className="c-col c-col--10 c-col--sm--12">
                    <LocationInfoBar location={location} displaySelectSuperSubData={true}/>
                </div>
                <div className={noReponses ? "c-col c-col--10" : "c-col c-col--6 c-col--sm--12 flex space-between align-center wrap blocCreneau"}>
                    <div className="radioList sm--checkbox">
                        {ajaxLoading || acceptingReponse ?
                            /* istanbul ignore next */
                            <Preloader fixed={true} />
                            : (
                                <>
                                    {noReponses && <div className="noResponses">Aucune demande reçue pour ce match </div>}
                                    {!noReponses && (
                                        <InfiniteScroll
                                            dataLength={supersubRequestsResponses.length}
                                            next={fetchData}
                                            hasMore={hasMoreData}
                                            loader={<Preloader/>}
                                            endMessage=""
                                            scrollableTarget="selectSuperSub"
                                        >
                                            {
                                                (supersubRequestsResponses && supersubRequestsResponses.length > 0) &&
                                                (<>
                                                    {isMobile ?
                                                        handleMobileBlocInfo(supersubRequestsResponses)
                                                        :
                                                        supersubRequestsResponses.map(
                                                            (r) => {
                                                                return buildCheckBloc(r)
                                                            },
                                                        )
                                                    }
                                                </>)
                                            }
                                        </InfiniteScroll>
                                    )}
                                </>
                            )}
                    </div>

                </div>

                {
                    !isMobile && !acceptingReponse && !ajaxLoading && (
                        <div className="c-col c-col--3 c-col--sm--12 flex space-between align-center wrap c-col--offset--left--md--1 blocInfoDesktop">
                            <BlocInfoSuperSub
                                response={selectedResponse}
                                confirmAction={confirmAction}
                                refuseAction={refuseAction}
                                noReponses={noReponses}
                            />
                        </div>
                    )
                }

            </div>

        </Step>
    )
}

SelectSuperSub.propTypes = {
    locationId: oneOfType([ string, number ]),
}

export default SelectSuperSub
