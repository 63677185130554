import { arrayOf, bool, func, node, oneOfType, string } from "prop-types"
import { useEffect } from "react"

import ActivationTrigger from "../../Buttons/Triggers/ActivationTrigger"
import { useTheme } from "../../ThemeContext/ThemeContext"
import Tooltip from "../Tooltip"

import "./Help.scss"

export default function Help({ id, cls, callback, children }) {

    const theme = useTheme()

    useEffect(() => {
        if (id === undefined) {
            throw new Error("Help component must have an id given as parameter")
        }
    }, [])

    return (
        <div className={"help" + (cls ? ` ${cls}` : "")}>
            <ActivationTrigger id={"buttonTooltip-" + id} target={"#" + id} callback={callback}>
                <div className="questionMark">
                    {theme === "theme-padel" ? (
                        <svg width="22" height="27" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 27">
                            <g fill="#F5233C" fillRule="evenodd">
                                <path d="M6.1 23.3 5.5 27h5.1l1-3.7H6.7M.7 10l2-9.8L21.3 0l-3.6 16.8H13l-1 4.4H6.5l2-8.1H13l2.5-8.3-8.9.1-1 5.1z"/>
                            </g>
                        </svg>
                    ) : (
                        "?"
                    )}
                </div>
                <div className="text hide-sm">Aide</div>
            </ActivationTrigger>
            <Tooltip
                id={id}
                cls="tooltipContainer--bottom"
            >
                { children }
            </Tooltip>
        </div>
    )
}

Help.propTypes = {
    callback: oneOfType([ bool, func ]),
    children: oneOfType([ string, node, arrayOf(node) ]),
    cls: string,
    id: string,
}
