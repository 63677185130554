const countries = [
    {
        "code": "af",
        "iso": "004",
        "name": "Afghanistan",
    },
    {
        "code": "al",
        "iso": "008",
        "name": "Albania",
    },
    {
        "code": "dz",
        "iso": "012",
        "name": "Algeria",
    },
    {
        "code": "ad",
        "iso": "020",
        "name": "Andorra",
    },
    {
        "code": "ao",
        "iso": "024",
        "name": "Angola",
    },
    {
        "code": "ag",
        "iso": "028",
        "name": "Antigua and Barbuda",
    },
    {
        "code": "ar",
        "iso": "032",
        "name": "Argentina",
    },
    {
        "code": "am",
        "iso": "051",
        "name": "Armenia",
    },
    {
        "code": "aw",
        "iso": "533",
        "name": "Aruba",
    },
    {
        "code": "au",
        "iso": "036",
        "name": "Australia",
    },
    {
        "code": "at",
        "iso": "040",
        "name": "Austria",
    },
    {
        "code": "az",
        "iso": "031",
        "name": "Azerbaijan",
    },
    {
        "code": "bs",
        "iso": "044",
        "name": "Bahamas",
    },
    {
        "code": "bh",
        "iso": "048",
        "name": "Bahrain",
    },
    {
        "code": "bd",
        "iso": "050",
        "name": "Bangladesh",
    },
    {
        "code": "bb",
        "iso": "052",
        "name": "Barbados",
    },
    {
        "code": "by",
        "iso": "112",
        "name": "Belarus",
    },
    {
        "code": "be",
        "iso": "056",
        "name": "Belgium",
    },
    {
        "code": "bz",
        "iso": "084",
        "name": "Belize",
    },
    {
        "code": "bj",
        "iso": "204",
        "name": "Benin",
    },
    {
        "code": "bt",
        "iso": "064",
        "name": "Bhutan",
    },
    {
        "code": "bo",
        "iso": "068",
        "name": "Bolivia",
    },
    {
        "code": "ba",
        "iso": "070",
        "name": "Bosnia and Herzegovina",
    },
    {
        "code": "bw",
        "iso": "072",
        "name": "Botswana",
    },
    {
        "code": "br",
        "iso": "076",
        "name": "Brazil",
    },
    {
        "code": "io",
        "iso": "086",
        "name": "British Indian Ocean Territory",
    },
    {
        "code": "bn",
        "iso": "096",
        "name": "Brunei",
    },
    {
        "code": "bg",
        "iso": "100",
        "name": "Bulgaria",
    },
    {
        "code": "bf",
        "iso": "854",
        "name": "Burkina Faso",
    },
    {
        "code": "bi",
        "iso": "108",
        "name": "Burundi",
    },
    {
        "code": "kh",
        "iso": "116",
        "name": "Cambodia",
    },
    {
        "code": "cm",
        "iso": "120",
        "name": "Cameroon",
    },
    {
        "code": "ca",
        "iso": "124",
        "name": "Canada",
    },
    {
        "code": "cv",
        "iso": "132",
        "name": "Cape Verde",
    },
    {
        "code": "bq",
        "iso": "535",
        "name": "Caribbean Netherlands",
    },
    {
        "code": "cf",
        "iso": "140",
        "name": "Central African Republic",
    },
    {
        "code": "td",
        "iso": "148",
        "name": "Chad",
    },
    {
        "code": "cl",
        "iso": "152",
        "name": "Chile",
    },
    {
        "code": "cn",
        "iso": "156",
        "name": "China",
    },
    {
        "code": "co",
        "iso": "170",
        "name": "Colombia",
    },
    {
        "code": "km",
        "iso": "174",
        "name": "Comoros",
    },
    {
        "code": "cd",
        "iso": "180",
        "name": "Congo",
    },
    {
        "code": "cg",
        "iso": "178",
        "name": "Congo",
    },
    {
        "code": "cr",
        "iso": "188",
        "name": "Costa Rica",
    },
    {
        "code": "ci",
        "iso": "384",
        "name": "Côte d’Ivoire",
    },
    {
        "code": "hr",
        "iso": "191",
        "name": "Croatia",
    },
    {
        "code": "cu",
        "iso": "192",
        "name": "Cuba",
    },
    {
        "code": "cw",
        "iso": "531",
        "name": "Curaçao",
    },
    {
        "code": "cy",
        "iso": "196",
        "name": "Cyprus",
    },
    {
        "code": "cz",
        "iso": "203",
        "name": "Czech Republic",
    },
    {
        "code": "dk",
        "iso": "208",
        "name": "Denmark",
    },
    {
        "code": "dj",
        "iso": "262",
        "name": "Djibouti",
    },
    {
        "code": "dm",
        "iso": "212",
        "name": "Dominica",
    },
    {
        "code": "do",
        "iso": "214",
        "name": "Dominican Republic",
    },
    {
        "code": "ec",
        "iso": "218",
        "name": "Ecuador",
    },
    {
        "code": "eg",
        "iso": "818",
        "name": "Egypt",
    },
    {
        "code": "sv",
        "iso": "222",
        "name": "El Salvador",
    },
    {
        "code": "gq",
        "iso": "226",
        "name": "Equatorial Guinea",
    },
    {
        "code": "er",
        "iso": "232",
        "name": "Eritrea",
    },
    {
        "code": "ee",
        "iso": "233",
        "name": "Estonia",
    },
    {
        "code": "et",
        "iso": "231",
        "name": "Ethiopia",
    },
    {
        "code": "fj",
        "iso": "242",
        "name": "Fiji",
    },
    {
        "code": "fi",
        "iso": "246",
        "name": "Finland",
    },
    {
        "code": "fr",
        "iso": "250",
        "name": "France",
    },
    {
        "code": "gf",
        "iso": "254",
        "name": "French Guiana",
    },
    {
        "code": "pf",
        "iso": "258",
        "name": "French Polynesia",
    },
    {
        "code": "ga",
        "iso": "266",
        "name": "Gabon",
    },
    {
        "code": "gm",
        "iso": "270",
        "name": "Gambia",
    },
    {
        "code": "ge",
        "iso": "268",
        "name": "Georgia",
    },
    {
        "code": "de",
        "iso": "276",
        "name": "Germany",
    },
    {
        "code": "gh",
        "iso": "288",
        "name": "Ghana",
    },
    {
        "code": "gr",
        "iso": "300",
        "name": "Greece",
    },
    {
        "code": "gd",
        "iso": "308",
        "name": "Grenada",
    },
    {
        "code": "gp",
        "iso": "312",
        "name": "Guadeloupe",
    },
    {
        "code": "gu",
        "iso": "316",
        "name": "Guam",
    },
    {
        "code": "gt",
        "iso": "320",
        "name": "Guatemala",
    },
    {
        "code": "gn",
        "iso": "324",
        "name": "Guinea",
    },
    {
        "code": "gw",
        "iso": "624",
        "name": "Guinea-Bissau",
    },
    {
        "code": "gy",
        "iso": "328",
        "name": "Guyana",
    },
    {
        "code": "ht",
        "iso": "332",
        "name": "Haiti",
    },
    {
        "code": "hn",
        "iso": "340",
        "name": "Honduras",
    },
    {
        "code": "hk",
        "iso": "344",
        "name": "Hong Kong",
    },
    {
        "code": "hu",
        "iso": "348",
        "name": "Hungary",
    },
    {
        "code": "is",
        "iso": "352",
        "name": "Iceland",
    },
    {
        "code": "in",
        "iso": "356",
        "name": "India",
    },
    {
        "code": "id",
        "iso": "360",
        "name": "Indonesia",
    },
    {
        "code": "ir",
        "iso": "364",
        "name": "Iran",
    },
    {
        "code": "iq",
        "iso": "368",
        "name": "Iraq",
    },
    {
        "code": "ie",
        "iso": "372",
        "name": "Ireland",
    },
    {
        "code": "il",
        "iso": "376",
        "name": "Israel",
    },
    {
        "code": "it",
        "iso": "380",
        "name": "Italy",
    },
    {
        "code": "jm",
        "iso": "388",
        "name": "Jamaica",
    },
    {
        "code": "jp",
        "iso": "392",
        "name": "Japan",
    },
    {
        "code": "je",
        "format": "832",
        "name": "Jersey",
    },
    {
        "code": "jo",
        "iso": "400",
        "name": "Jordan",
    },
    {
        "code": "kz",
        "iso": "398",
        "name": "Kazakhstan",
    },
    {
        "code": "ke",
        "iso": "404",
        "name": "Kenya",
    },
    {
        "code": "ki",
        "iso": "296",
        "name": "Kiribati",
    },
    {
        "code": "xk",
        "iso": null,
        "name": "Kosovo",
    },
    {
        "code": "kw",
        "iso": "414",
        "name": "Kuwait",
    },
    {
        "code": "kg",
        "iso": "417",
        "name": "Kyrgyzstan",
    },
    {
        "code": "la",
        "iso": "418",
        "name": "Laos",
    },
    {
        "code": "lv",
        "iso": "428",
        "name": "Latvia",
    },
    {
        "code": "lb",
        "iso": "422",
        "name": "Lebanon",
    },
    {
        "code": "ls",
        "iso": "426",
        "name": "Lesotho",
    },
    {
        "code": "lr",
        "iso": "430",
        "name": "Liberia",
    },
    {
        "code": "ly",
        "iso": "434",
        "name": "Libya",
    },
    {
        "code": "li",
        "iso": "438",
        "name": "Liechtenstein",
    },
    {
        "code": "lt",
        "iso": "440",
        "name": "Lithuania",
    },
    {
        "code": "lu",
        "iso": "442",
        "name": "Luxembourg",
    },
    {
        "code": "mo",
        "iso": "446",
        "name": "Macau",
    },
    {
        "code": "mk",
        "iso": "807",
        "name": "Macedonia",
    },
    {
        "code": "mg",
        "iso": "450",
        "name": "Madagascar",
    },
    {
        "code": "mw",
        "iso": "454",
        "name": "Malawi",
    },
    {
        "code": "my",
        "iso": "458",
        "name": "Malaysia",
    },
    {
        "code": "mv",
        "iso": "462",
        "name": "Maldives",
    },
    {
        "code": "ml",
        "iso": "466",
        "name": "Mali",
    },
    {
        "code": "mt",
        "iso": "470",
        "name": "Malta",
    },
    {
        "code": "mh",
        "iso": "584",
        "name": "Marshall Islands",
    },
    {
        "code": "mq",
        "iso": "474",
        "name": "Martinique",
    },
    {
        "code": "mr",
        "iso": "478",
        "name": "Mauritania",
    },
    {
        "code": "mu",
        "iso": "480",
        "name": "Mauritius",
    },
    {
        "code": "mx",
        "iso": "484",
        "name": "Mexico",
    },
    {
        "code": "fm",
        "iso": "583",
        "name": "Micronesia",
    },
    {
        "code": "md",
        "iso": "498",
        "name": "Moldova",
    },
    {
        "code": "mc",
        "iso": "492",
        "name": "Monaco",
    },
    {
        "code": "mn",
        "iso": "496",
        "name": "Mongolia",
    },
    {
        "code": "me",
        "iso": "499",
        "name": "Montenegro",
    },
    {
        "code": "ma",
        "iso": "504",
        "name": "Morocco",
    },
    {
        "code": "mz",
        "iso": "508",
        "name": "Mozambique",
    },
    {
        "code": "mm",
        "iso": "104",
        "name": "Myanmar",
    },
    {
        "code": "na",
        "iso": "516",
        "name": "Namibia",
    },
    {
        "code": "nr",
        "iso": "520",
        "name": "Nauru",
    },
    {
        "code": "np",
        "iso": "524",
        "name": "Nepal",
    },
    {
        "code": "nl",
        "iso": "528",
        "name": "Netherlands",
    },
    {
        "code": "nc",
        "iso": "540",
        "name": "New Caledonia",
    },
    {
        "code": "nz",
        "iso": "554",
        "name": "New Zealand",
    },
    {
        "code": "ni",
        "iso": "558",
        "name": "Nicaragua",
    },
    {
        "code": "ne",
        "iso": "562",
        "name": "Niger",
    },
    {
        "code": "ng",
        "iso": "566",
        "name": "Nigeria",
    },
    {
        "code": "kp",
        "iso": "408",
        "name": "North Korea",
    },
    {
        "code": "no",
        "iso": "578",
        "name": "Norway",
    },
    {
        "code": "om",
        "iso": "512",
        "name": "Oman",
    },
    {
        "code": "pk",
        "iso": "586",
        "name": "Pakistan",
    },
    {
        "code": "pw",
        "iso": "585",
        "name": "Palau",
    },
    {
        "code": "ps",
        "iso": "275",
        "name": "Palestine",
    },
    {
        "code": "pa",
        "iso": "591",
        "name": "Panama",
    },
    {
        "code": "pg",
        "iso": "598",
        "name": "Papua New Guinea",
    },
    {
        "code": "py",
        "iso": "600",
        "name": "Paraguay",
    },
    {
        "code": "pe",
        "iso": "604",
        "name": "Peru",
    },
    {
        "code": "ph",
        "iso": "608",
        "name": "Philippines",
    },
    {
        "code": "pl",
        "iso": "616",
        "name": "Poland",
    },
    {
        "code": "pt",
        "iso": "620",
        "name": "Portugal",
    },
    {
        "code": "pr",
        "iso": "630",
        "name": "Puerto Rico",
    },
    {
        "code": "qa",
        "iso": "634",
        "name": "Qatar",
    },
    {
        "code": "re",
        "iso": "638",
        "name": "Réunion",
    },
    {
        "code": "ro",
        "iso": "642",
        "name": "Romania",
    },
    {
        "code": "ru",
        "iso": "643",
        "name": "Russia",
    },
    {
        "code": "rw",
        "iso": "646",
        "name": "Rwanda",
    },
    {
        "code": "kn",
        "iso": "659",
        "name": "Saint Kitts and Nevis",
    },
    {
        "code": "lc",
        "iso": "662",
        "name": "Saint Lucia",
    },
    {
        "code": "vc",
        "iso": "670",
        "name": "Saint Vincent and the Grenadines",
    },
    {
        "code": "ws",
        "iso": "882",
        "name": "Samoa",
    },
    {
        "code": "sm",
        "iso": "674",
        "name": "San Marino",
    },
    {
        "code": "st",
        "iso": "678",
        "name": "São Tomé and Príncipe",
    },
    {
        "code": "sa",
        "iso": "682",
        "name": "Saudi Arabia",
    },
    {
        "code": "sn",
        "iso": "686",
        "name": "Senegal",
    },
    {
        "code": "rs",
        "iso": "688",
        "name": "Serbia",
    },
    {
        "code": "sc",
        "iso": "690",
        "name": "Seychelles",
    },
    {
        "code": "sl",
        "iso": "694",
        "name": "Sierra Leone",
    },
    {
        "code": "sg",
        "iso": "702",
        "name": "Singapore",
    },
    {
        "code": "sk",
        "iso": "703",
        "name": "Slovakia",
    },
    {
        "code": "si",
        "iso": "705",
        "name": "Slovenia",
    },
    {
        "code": "sb",
        "iso": "090",
        "name": "Solomon Islands",
    },
    {
        "code": "so",
        "iso": "706",
        "name": "Somalia",
    },
    {
        "code": "za",
        "iso": "710",
        "name": "South Africa",
    },
    {
        "code": "kr",
        "iso": "410",
        "name": "South Korea",
    },
    {
        "code": "ss",
        "iso": "728",
        "name": "South Sudan",
    },
    {
        "code": "es",
        "iso": "724",
        "name": "Spain",
    },
    {
        "code": "lk",
        "iso": "144",
        "name": "Sri Lanka",
    },
    {
        "code": "sd",
        "iso": "729",
        "name": "Sudan",
    },
    {
        "code": "sr",
        "iso": "740",
        "name": "Suriname",
    },
    {
        "code": "sz",
        "iso": "748",
        "name": "Swaziland",
    },
    {
        "code": "se",
        "iso": "752",
        "name": "Sweden",
    },
    {
        "code": "ch",
        "iso": "756",
        "name": "Switzerland",
    },
    {
        "code": "sy",
        "iso": "760",
        "name": "Syria",
    },
    {
        "code": "tw",
        "iso": "158",
        "name": "Taiwan",
    },
    {
        "code": "tj",
        "iso": "762",
        "name": "Tajikistan",
    },
    {
        "code": "tz",
        "iso": "834",
        "name": "Tanzania",
    },
    {
        "code": "th",
        "iso": "764",
        "name": "Thailand",
    },
    {
        "code": "tl",
        "iso": "626",
        "name": "Timor-Leste",
    },
    {
        "code": "tg",
        "iso": "768",
        "name": "Togo",
    },
    {
        "code": "to",
        "iso": "776",
        "name": "Tonga",
    },
    {
        "code": "tt",
        "iso": "780",
        "name": "Trinidad and Tobago",
    },
    {
        "code": "tn",
        "iso": "788",
        "name": "Tunisia",
    },
    {
        "code": "tr",
        "iso": "792",
        "name": "Turkey",
    },
    {
        "code": "tm",
        "iso": "795",
        "name": "Turkmenistan",
    },
    {
        "code": "tv",
        "iso": "798",
        "name": "Tuvalu",
    },
    {
        "code": "ug",
        "iso": "800",
        "name": "Uganda",
    },
    {
        "code": "ua",
        "iso": "804",
        "name": "Ukraine",
    },
    {
        "code": "ae",
        "iso": "784",
        "name": "United Arab Emirates",
    },
    {
        "code": "gb",
        "iso": "826",
        "name": "United Kingdom",
    },
    {
        "code": "us",
        "iso": "840",
        "name": "United States",
    },
    {
        "code": "uy",
        "iso": "858",
        "name": "Uruguay",
    },
    {
        "code": "uz",
        "iso": "860",
        "name": "Uzbekistan",
    },
    {
        "code": "vu",
        "iso": "548",
        "name": "Vanuatu",
    },
    {
        "code": "va",
        "iso": "336",
        "name": "Vatican City",
    },
    {
        "code": "ve",
        "iso": "862",
        "name": "Venezuela",
    },
    {
        "code": "vn",
        "iso": "704",
        "name": "Vietnam",
    },
    {
        "code": "ye",
        "iso": "887",
        "name": "Yemen",
    },
    {
        "code": "zm",
        "iso": "894",
        "name": "Zambia",
    },
    {
        "code": "zw",
        "iso": "716",
        "name": "Zimbabwe",
    },
]

export default countries
