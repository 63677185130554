import { create } from "zustand"

export const useLeagueStore = create((set) => ({
    ads: [],
    currentGame: {},
    gameComposition: [],
    leagueTeamCache: [],
    leagueTeams: [],
    resetLeagueTeamCache: () => set({ leagueTeamCache: [] }),
    selectedLeagueTeam: {},
    selectedTeamData: {},
    setAds: (ads) => set({ ads }),
    setCurrentGame: (currentGame) => set({ currentGame }),
    setGameComposition: (gameComposition) => set({ gameComposition }),
    setLeagueTeamCache: (leagueTeamCache) => set((state) => ({
        leagueTeamCache: [
            ...state.leagueTeamCache,
            leagueTeamCache,
        ],
    })),
    setLeagueTeams: (leagueTeams) => set({ leagueTeams }),
    setSelectedLeagueTeam: (selectedLeagueTeam) => {
        localStorage.setItem(SELECTED_LEAGUE_TEAM_KEY, selectedLeagueTeam?.id || "")

        set({
            selectedLeagueTeam,
            selectedTeamData: {},
        })
    },
    setSelectedTeamData: (selectedTeamData) =>
        set((state) => ({
            selectedTeamData: {
                ...state.selectedTeamData,
                ...selectedTeamData,
            },
        })),
    setShouldRevalidate: (shouldRevalidate) => {
        set({ shouldRevalidate: shouldRevalidate })
    },
    setUserCenter: (userCenter) => set({ userCenter }),
    shouldRevalidate: false,
    userCenter: {},
}))

export const AD_MY_LEAGUE = "ma_league"
export const AD_TEAM_SHOP = "boutique_equipe"

export const SELECTED_LEAGUE_TEAM_KEY = "selectedLeagueTeam"
