import dayjs from "dayjs"
import { bool, func, object } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import ListingRegistrationHolder from "../../../../../components/listing/ListingRegistrationHolder"
import StatusDisplay from "../../../../../components/listing/StatusDisplay"
import Modal from "../../../../../components/Modal/Modal"
import MessagePopin from "../../../../../components/popin/MessagePopin/MessagePopin"
import { useTheme } from "../../../../../components/ThemeContext/ThemeContext"
import HourDisplay from "../../../../../components/Time/HourDisplay"
import { displayBill, getContactPlayerProfile } from "../../../../../globalAPI/api"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage"
import { useCanDownload } from "../../../../../hooks/useCanDownload.jsx"
import useMediaPath from "../../../../../hooks/useMediaPath"
import useThemeFeatures from "../../../../../hooks/useThemeFeatures"
import days from "../../../../../model/enums/dayValues"
import ModalHandler from "../../../../../providers/Modal/ModalHandler"
import usePath from "../../../../../routes/services/usePath"
import dateFormatter from "../../../../../services/dateFormatter"
import formatTwoDigits from "../../../../../services/formatTwoDigits"
import openPdfTab from "../../../../../services/openPdfTab"
import { popUrl } from "../../../../../services/routingManagment"
import { setFormEntry } from "../../../../../store/forms/actions"
import ModifyLicensesPopin from "../../../components/ModifyLicensesPopin/ModifyLicensesPopin"
import QuitWaitingListPopin from "../../../components/QuitWaitingListPopin/QuitWaitingListPopin"
import { cancelCupPreregistration, getCupRegistrationScores } from "../../api"

function CupListingElement({ cupRegistration, displayDetail, callback, effectifCallback }) {
    const quitWaitingListPopin = useRef()
    const modifyLicensesPopin = useRef()
    const { check, message } = useCanDownload()

    /* STATE */
    const [ isLoading, setIsLoading ] = useState(false)
    const [ partnerProfile, setPartnerProfile ] = useState(null)
    const [ result, setResult ] = useState()

    /* STORE */
    const navigate = useNavigate()
    const path = usePath()
    const dispatch = useDispatch()
    const mediaPath = useMediaPath()
    const themeFeatures = useThemeFeatures()
    const theme = useTheme()
    const { tu } = useUrbanLanguage()

    const userLoaded = useSelector(state => state.userLoaded)

    function quitterFileAttente() {
        cancelCupPreregistration(cupRegistration.id).then(
            () => {
                callback()
            },
        )
    }

    function finaliser() {
        dispatch(setFormEntry("customReturn", themeFeatures([ "/cup", "/padel/tournois" ])))
        navigate(path("/ordersummary"), {
            state: {
                cupRegistration: cupRegistration,
                isPreregistration: true,
                title: "Finaliser ma préinscription",
                tunnelType: "CUP",
            },
        })
    }

    function annuler() {
        finaliser()
    }

    useEffect(() => {
        if (displayDetail === true) {
            setTimeout(() => {
                openDetailPopIn()
            }, 200)
        } else if (displayDetail === false) {
            ModalHandler.hide(DetailsPopIn)
        }
        if (cupRegistration?.cup?.id) {
            getCupRegistrationScores(cupRegistration?.cup?.id).then(res => {
                setIsLoading(false)
                setResult(res)
            })
        }

        if (cupRegistration?.viceCaptainId) {
            const partnerId = cupRegistration.viceCaptainId === userLoaded.contactId ? cupRegistration.captainId : cupRegistration.viceCaptainId
            const partner = cupRegistration.viceCaptainId === userLoaded.contactId ? cupRegistration.captain : cupRegistration.viceCaptain
            getContactPlayerProfile(partnerId).then(res => setPartnerProfile({ ...partner, ...res }))
        }
    }, [])

    let cupStartDate = null
    let temp = null

    if (cupRegistration.cup.start && cupRegistration.cup.start.includes("/")) {
        temp = cupRegistration.cup.start.split("/")
        cupStartDate = new Date(temp[2] + "-" + temp[1] + "-" + temp[0])
    } else {
        cupStartDate = new dayjs(cupRegistration.cup.start).format("DD/MM/YYYY")
    }

    function openDetailPopIn() {
        ModalHandler.show(DetailsPopIn, {
            cupRegistration: cupRegistration,
        })
    }

    return (
        <ListingRegistrationHolder
            isLoading={isLoading}
            logo={
                cupRegistration.cup.pictureUrl ?
                    cupRegistration.cup.pictureUrl
                    :
                    mediaPath([ "/assets/images/placeholder-team.svg", "/assets/images/padel/logo/logo-black.svg" ])
            }
            status={
                (<StatusDisplay
                    id={cupRegistration.id}
                    status={cupRegistration.status}
                    statusValue={cupRegistration.statusValue}
                    preRegistrationLimitDate={cupRegistration.preRegistrationLimitDate}
                    isFamiliarity
                    licenseNumberMissing={theme === "theme-padel" && cupRegistration.cup.category === 2 && (!userLoaded?.playerProfile?.licenseNumber || !partnerProfile?.licenseNumber)}
                />)
            }
            name={cupRegistration.cup.name}
            details={
                (<React.Fragment>
                    <div>{tu("name")} {cupRegistration.centerName}</div>
                    { temp ? (
                        days[cupStartDate.getDay()] + " " +
                        dayjs(cupStartDate).format("DD/MM") + " - "
                    ) : (
                        cupStartDate + " - "
                    )}
                    <HourDisplay minutes={cupRegistration.cup.hour}/>
                    {theme === "theme-soccer" && cupRegistration.cupTeam &&
                        <div>{cupRegistration.cupTeam.teamName}</div>
                    }
                    {theme === "theme-padel" && cupRegistration.captain?.firstname &&
                        <div>{`${cupRegistration.captain?.firstname}${cupRegistration.viceCaptain && cupRegistration.viceCaptain.firstname !== "WaitingList" ? "/" + cupRegistration.viceCaptain.firstname : ""}`}</div>
                    }
                    <div
                        className="detail-btn"
                        onClick={() => {
                            // openPopin('listing', 'details', cupRegistration.id)
                            openDetailPopIn()
                        }}
                    >
                        Voir le détail
                    </div>
                </React.Fragment>)
            }
            actions={(
                <React.Fragment>
                    {theme === "theme-soccer" && (
                        <ButtonCta
                            isCta2Alt
                            text="Gérer l'effectif"
                            onClick={effectifCallback}
                        />
                    )}
                    {theme === "theme-padel" && cupRegistration?.cup?.category === 2 && (
                        <ButtonCta
                            text="Mon numéro de licence"
                            onClick={() => {
                                modifyLicensesPopin?.current?.show()
                            }}
                        />
                    )}
                    {result && (
                        <ButtonCta
                            text="Voir mes résultats"
                            onClick={() => {
                                result && openPdfTab(result)
                            }}
                        />
                    )}
                    {(cupRegistration.billId > 0 
                        && cupRegistration.statusValue === 3
                        && (userLoaded && cupRegistration.contact.id === userLoaded.contactId)
                    ) && (
                        <>
                            <ButtonCta
                                text="Télécharger la facture"
                                onClick={() => {
                                    check(() => displayBill(cupRegistration.billId, setIsLoading).then((res) => {
                                        if (res) {
                                            ModalHandler.show(MessagePopin, {
                                                message: res,
                                                title: "Erreur",
                                            })
                                        }
                                    }))
                                }}
                            />
                        </>
                    )}

                    {(cupRegistration.statusValue === 1) && (
                        <ButtonCta
                            isCta2Alt
                            text="Quitter la file d'attente"
                            onClick={() => quitWaitingListPopin?.current?.show()}
                        />
                    )}

                    {(cupRegistration.statusValue === 2) && (
                        <ButtonCta
                            text="Finaliser ma pré-inscription"
                            onClick={finaliser}
                        />
                    )}

                    {(cupRegistration.statusValue === 2) && (
                        <ButtonCta
                            text={tu("cup.cancelPreregistration")}
                            onClick={annuler}
                            isCta2
                        />
                    )}
                    {theme === "theme-padel" && cupRegistration?.cup?.category === 2 && <ModifyLicensesPopin partner={partnerProfile} ref={modifyLicensesPopin} />}
                    {message}
                    {cupRegistration.statusValue === 1 && <QuitWaitingListPopin ref={quitWaitingListPopin} confirmCancel={quitterFileAttente} title="Quitter la file d'attente"  isLoading={isLoading} />}
                </React.Fragment>
            )}
        />
    )
}

const DetailsPopIn = ModalHandler.create(({ cupRegistration, visible,hide, partnerProfile }) => {
    const theme = useTheme()
    const { tu } = useUrbanLanguage()
    const userLoaded = useSelector(state => state.userLoaded)

    let cupStartDate = null
    let temp = null

    if (cupRegistration.cup.start && cupRegistration.cup.start.includes("/")) {
        temp = cupRegistration.cup.start.split("/")
        cupStartDate = new Date(temp[2] + "-" + temp[1] + "-" + temp[0])
    } else {
        cupStartDate = new dayjs(cupRegistration.cup.start).format("DD/MM/YYYY")
    }

    let catalogArticles = []
    if (cupRegistration?.catalogArticles) {
        cupRegistration?.catalogArticles.forEach((catalogArticle) => {
            catalogArticles.push(
                <div className="catalog-article" key={`${cupRegistration.id}-catalogArticle-` + catalogArticle.product.id}>
                    <img src={catalogArticle.product.pictureUrl} />
                    <div>
                        Qté : <span>{catalogArticle.quantity}</span>
                    </div>
                </div>,
            )
        })
    }
    // build details popin
    const popin = (
        <>
            <header>Détails de l'inscription</header>
            <article>
                <section className="details">
                    <div>
                        <div className="header">{cupRegistration.cup.name}</div>
                        <div>{tu("name")} {cupRegistration.centerName}</div>
                        {theme === "theme-soccer" &&  (<>
                            { temp ? (
                                days[cupStartDate.getDay()] + " " +
                                formatTwoDigits(cupStartDate.getDate()) + "/" +
                                formatTwoDigits(cupStartDate.getMonth() + 1) + "/" +
                                cupStartDate.getFullYear() + " - "
                            ) : (
                                cupStartDate + " - "
                            )}
                            <HourDisplay minutes={cupRegistration.cup.hour}/>
                            {cupRegistration.cupTeam &&
                                <div>{cupRegistration.cupTeam.teamName}</div>
                            }
                        </>)}
                        {theme === "theme-padel" && (<>
                            <div>
                                {cupRegistration?.cup?.start && cupRegistration?.cup?.end && cupRegistration?.start !== cupRegistration?.end && (
                                    "Du " + dateFormatter(cupRegistration.cup.start) + " au " +  dateFormatter(cupRegistration.cup.end)
                                )}
                                {cupRegistration?.start === cupRegistration?.end && dateFormatter(cupRegistration.cup.start)}
                            </div>
                            {cupRegistration?.call && <div>{cupRegistration?.call}</div>}
                            <div>{cupRegistration?.captain?.firstname} {cupRegistration?.captain?.lastname} {userLoaded?.playerProfile?.licenseNumber}</div>
                            {cupRegistration?.statusValue !== 1 && cupRegistration?.viceCaptain?.firstname && <div>{cupRegistration?.viceCaptain?.firstname} {cupRegistration?.viceCaptain?.lastname} {partnerProfile?.licenseNumber}</div>}
                        </>)}
                    </div>
                    { cupRegistration?.optionProducts?.length > 0 &&
                        (<div>
                            <strong>Mes options</strong>
                            {cupRegistration?.optionProducts?.map(
                                (option) => (
                                    <li key={option.id}>
                                        {option.name}
                                    </li>
                                ),
                            )}
                        </div>)
                    }
                </section>

                { cupRegistration?.catalogArticles && cupRegistration?.catalogArticles?.length > 0 &&
                    (<section>
                        <div className="header">Mes équipements :</div>
                        <div className="catalogArticlesDiv">
                            {catalogArticles}
                        </div>
                    </section>)
                }
            </article>
        </>
    )

    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            beforeClose={popUrl}
        >
            {popin}
        </Modal>
    )
})

CupListingElement.propTypes = {
    callback: func,
    cupRegistration: object,
    displayDetail: bool,
    effectifCallback: func,
}

export default CupListingElement
