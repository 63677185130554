import classNames from "classnames"
import { any, bool, object, oneOfType, string } from "prop-types"
import { useState } from "react"

import PageHeader from "../../../components/layout/pageHeader/PageHeader"
import useCookies from "../../../hooks/useCookies"
import useIsMobile from "../../../hooks/useIsMobile"
import UserAgentHandler from "../../../services/UserAgentHandler"

import "./_ContentComponent.scss"

function _ContentComponent({
    name = "",
    preRenderContent,
    preRenderDesktopContent,
    title,
    // level1Label,
    customCls,
}) {
    
    const [
        withFilters,
        // setWithFilters
    ] = useState(false)

    const isAppV2 = useCookies()
    const isMobile = useIsMobile()

    function preRender() {
        if (isMobile) {
            return preRenderContent()
        }
        return preRenderDesktopContent()
    }

    function renderContentLabel() {
        return <PageHeader title={title} />
    }

    return (
        // <StyleRoot>
        <div
            className={classNames("container-mainContent", {
                [customCls]: customCls,
                "padding-bottom": (UserAgentHandler.isIOS() || UserAgentHandler.isAndroid()) && !isAppV2,
                "with-filters": withFilters,
            })}
            data-identifier={name.toLowerCase()}
        >
            {renderContentLabel()}
            {preRender()}
            {withFilters && this.props.domDetails.windowScrollY > 0 && (
                <div
                    className="shortcut-to-top"
                    onClick={
                        () => {
                            let scrollElement = UserAgentHandler.isSafari() ? document.body : document.documentElement
                            scrollTo(scrollElement,0,200)
                        }
                    }
                >

                </div>
            )}
        </div>
        // </StyleRoot>
    )
}

function scrollTo(element, to, duration) {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20

    var animateScroll = function () {
        currentTime += increment
        var val = Math.easeInOutQuad(currentTime, start, change, duration)
        element.scrollTop = val
        if (currentTime < duration) {
            setTimeout(animateScroll, increment)
        }
    }
    animateScroll()
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2
    if (t < 1) {return c / 2 * t * t + b}
    t--
    return -c / 2 * (t * (t - 2) - 1) + b
}

_ContentComponent.propTypes = {
    appConfig: object,
    customCls: oneOfType([ bool, string ]),
    datacache: object,
    displayCustomTitle: any,
    domDetails: object,
    isMobile: bool,
    level1Label: string,
    mainContent: object,
    name: string,
    preRenderContent: any,
    preRenderDesktopContent: any,
    title: string,
}

export default _ContentComponent
