import classNames from "classnames"
import { string } from "prop-types"

import "./LoadingBar.scss"

export const LoadingBar = ({ cls }) => {
    return (
        <div className={classNames("loader", {
            [cls]: cls,
        })}>
            <div className="loaderBar"></div>
        </div>
    )
}

LoadingBar.propTypes = {
    cls: string,
}
