import { FavoriteSlots } from "./FavoriteSlots/FavoriteSlots"
import HomeProfil from "./HomeProfil/HomeProfil"

import "./ProfilHomeBlock.scss"

export default function ProfilHomeBlock() {
    return (
        <div className="profil-home-block">
            <HomeProfil />
            <FavoriteSlots/>
        </div>
    )
}
