import { string } from "prop-types"

function IconSoccer({ backgroundColor, textColor }) {
    return (
        <svg className="notif" width="15" height="16" viewBox="0 0 15 16">
            <g fill="none" fillRule="evenodd" transform="translate(0 .5)">
                <circle cx="7.5" cy="7.5" r="7.5" fill={backgroundColor} />
                <path fill={textColor} fillRule="nonzero" d="M8.254,9.109 L8.474,4.5 L7,4.5 L7.22,9.109 L8.254,9.109 Z M8.397,11.441 L8.397,9.956 L7.066,9.956 L7.066,11.441 L8.397,11.441 Z"/>
            </g>
        </svg>
    )
}

IconSoccer.propTypes = {
    backgroundColor: string,
    textColor: string,
}

export default IconSoccer
