//expire in minutes

export function saveInCache({ key, value, expire = 10, theme, overRide = false }) {
    const data = {
        expire: Date.now() + (expire * 60 * 1000),
        message: value,
        theme: theme,
    }
    if ((localStorage.getItem(key) && overRide) || !localStorage.getItem(key)) {
        localStorage.setItem(key, JSON.stringify(data))
    }
}

export function getInCache(keyName, theme) {
    const data = localStorage.getItem(keyName)
    if (data) {
        const item = JSON.parse(data)
        if (Date.now() > item.expire || item.theme !== theme || !item.theme) {
            localStorage.removeItem(keyName)
        } else {
            return item.message
        }
    }
    
    return false
}
