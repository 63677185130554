import { useNavigate } from "react-router-dom"

import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import Modal from "../Modal.jsx"

import "./ErrorModal.scss"

export const ErrorModal = ModalHandler.create(({
    title,
    message,
    visible,
    hide,
    redirectionUrl = "/",
    redirectionText,
}) => {
    const navigate = useNavigate()
    return (
        <Modal
            isOpen={visible}
            closeOnEsc={false}
            closeButton={false}
            exitOnOverlayClicked={false}
            onClose={hide}
            className='errorModal'
        >
            <header>{title}</header>
            <section>
                {message}
                <ButtonCta onClick={() => {
                    hide()
                    navigate(redirectionUrl)
                }} text={redirectionText}/>
            </section>
        </Modal>
    )
})
