import { useState } from "react"

import { useIsWebview } from "../components/ThemeContext/ThemeContext.jsx"

// Hook to check if the user can download the invoice.
// It is generally not recommended to put jsx in hooks, but in this case it is the best solution for reusability,
// and it doesn't impact the performance.
export const useCanDownload = () => {
    const isWebview = useIsWebview()
    const [ visible, setVisible ] = useState(false)
    
    const check = (onCanDownload) => {
        if (!isWebview) {
            onCanDownload()
            return true
        }
        setVisible(true)
        return false
    }

    const message = isWebview && visible ? (
        <div className="downloadError" style={{
            fontSize: "15px", marginLeft: "24px", marginRight: "24px", textAlign: "center",
        }}>
            Pour télécharger ta facture, rends-toi sur myurban.fr
        </div>
    ) : null

    return { check, message }
}
