/* eslint-disable react/prop-types */
import { bool, func, string } from "prop-types"
import { useState } from "react"

import EffectifDisplay from "../../../pages/teams/components/EffectifDisplay/EffectifDisplay"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import Modal from "../../Modal/Modal"

const EffectifPopIn = ModalHandler.create(({ callback, tunnelType, visible, hide }) => {
    const [ refreshKey, setRefreshKey ] = useState("effectifRefresh")
    return (
        <div className="effectifPopIns">
            <div className="big">
                <Modal
                    className="mainPopIn"
                    afterOpen={
                        () => {
                            let dialogs = document.getElementsByClassName("modal-content")
                            for (var i = 0; i < dialogs.length; i ++) {
                                dialogs[i].scrollTo(0, 0)
                            }
                        }
                    }
                    afterClose={
                        () => {
                            setRefreshKey(prev => prev + "effectifRefresh")
                        }
                    }
                    isOpen={visible}
                    onClose={hide}
                    key={refreshKey}
                >
                    <header>Mon Effectif</header>
                    <section className="invitationOptionHolder">
                        <div className="centerPicker c-row justify-center align-start layoutSmall">
                            <div className="c-col c-col--8 c-col--m--10 c-col--sm--12 flex justify-center align-center wrap">
                                <EffectifDisplay
                                    hasParent
                                    callback={callback}
                                    tunnelType={tunnelType}
                                />
                            </div>
                        </div>
                    </section>
                </Modal>
            </div>
        </div>
    )
})

EffectifPopIn.propTypes = {
    callback: func,
    isOpen: bool,
    setIsOpen: func,
    tunnelType: string,
}

EffectifPopIn.displayName = "EffectifPopIn"

export default EffectifPopIn
