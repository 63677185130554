import OpenReplay from "@openreplay/tracker"
import { createContext, useContext, useMemo, useState } from "react"

import { SessionHelper } from "../components/SessionHelper/SessionHelper"

const TrackerContext = createContext(null)

export const useTracker = () => {
    const context = useContext(TrackerContext)
    if (!context) {
        throw new Error("useTracker must be used within a TrackerProvider")
    }
    return context
}

// eslint-disable-next-line react/prop-types
export const TrackerProvider = ({ children }) => {
    const [ trackerActive, setTrackerActive ] = useState(null)
    const [ sessionUrl, setSessionUrl ] = useState(null)
    const projectKey = import.meta.env.VITE_OPENREPLAY_PROJECT_KEY
    let tracker

    // Initialize tracker only if projectKey is provided and not in development mode
    if (projectKey && !import.meta.env.DEV) {
        tracker = new OpenReplay({
            network: {
                capturePayload: true,
            },
            projectKey: projectKey,
        })
        tracker.start().then(() => {
            setSessionUrl(tracker.getSessionURL({ withCurrentTime: true }))
            setTrackerActive(true)
        })
    }

    const setUserID = (userID) => {
        if (tracker) {
            tracker.setUserID(userID)
        }
    }

    const providerValue = useMemo(() => {
        return { sessionUrl, setUserID, tracker }
    }, [ setUserID, tracker, sessionUrl ])

    return (
        <TrackerContext.Provider value={providerValue}>
            {trackerActive && <SessionHelper/>}
            {children}
        </TrackerContext.Provider>
    )
}
