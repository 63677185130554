import { _apiCall } from "../../../../backend/axios"
import APP_CONFIG from "../../../../business/config"

export function registerLocationTeamsNames(locationId, team1Name, team2Name, theme)  {
    const teamNamesCustomized = (team1Name !== APP_CONFIG.padelDefaultTeamsNames.team1 && team2Name !== APP_CONFIG.padelDefaultTeamsNames.team2)
        || (team1Name !== APP_CONFIG.padelDefaultTeamsNames.team1 && team2Name === APP_CONFIG.padelDefaultTeamsNames.team2)
        || (team1Name === APP_CONFIG.padelDefaultTeamsNames.team1 && team2Name !== APP_CONFIG.padelDefaultTeamsNames.team2)
    
    return _apiCall(
        "modify/reservation/" + locationId + "/team-names",
        "PUT",
        {
            "Content-Type" : "application/json",
            activity: theme,
        },
        {
            team1Name: team1Name,
            team2Name: team2Name,
            teamNamesCustomized: teamNamesCustomized,
        },
    ).then((response) => {
        return response
    })
}

export function getPlayersComposition(locationId, theme)  {
    return _apiCall(
        "read/reservation/" + locationId + "/attendees",
        "GET",
        theme,
    ).then((response) => {
        if (response && response.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

export function modifyPlayersComposition(id, data, theme) {
    return _apiCall(
        "modify/reservation/" + id + "/detail",
        "POST",
        {   
            "Content-Type" : "multipart/form-data",
            activity: theme,
        },
        data,
    ).then((response) => {
        return response
    })
}
