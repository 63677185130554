import classNames from "classnames"
import PropTypes from "prop-types"
import { forwardRef } from "react"

import "./Base.scss"

export const Base = forwardRef((props, ref) => {
    return (
        <div ref={ref} style={{ display: "none" }}>
            {/*<div ref={ref} style={{ height: 1080, pointerEvents: "none", position: "fixed", right: 0, top: 0, width: 1920, zIndex: 999 }}>*/}
            {/*<div ref={ref} style={{ height: 1080, position: "fixed", right: 0, top: 0, width: 1920, zIndex: 999 }}>*/}
            {props?.children}
        </div>
    )
})

export const BaseLayout = ({ children, withGradient, decorator, cls }) => {
    const decoratorClass = decorator ? "decorator-" + decorator : null

    return (
        <div className={classNames("pdf theme-business-print", decoratorClass, cls)}>
            {children}
            {withGradient && (
                <div className={"gradient"}>
                    <img src={"/assets/images/business/communicationPack/gradient.svg"} />
                </div>
            )}
            <footer>
                <img src={"/assets/images/business/communicationPack/footerLogo.png"} />
                <img src={"/assets/images/business/communicationPack/footerLink.svg"} />
            </footer>
        </div>
    )

}

Base.displayName = "Base"

BaseLayout.propTypes = {
    children: PropTypes.node,
    cls: PropTypes.string,
    decorator: PropTypes.oneOf([ "left", "top", "bottom", "right" ]),
    withGradient: PropTypes.bool,
}

Base.propTypes = {
    children: PropTypes.node,
}
