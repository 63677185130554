import { string } from "prop-types"

function ProfilePicture({ src }) {
    return (
        <img src={src ? src : "/assets/images/user-placeholder-gray.png"}/>
    )
}
ProfilePicture.propTypes = {
    src: string,
}

export default ProfilePicture
