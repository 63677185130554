import classNames from "classnames"
import { node, string } from "prop-types"

import "./ProfilCardWrapper.scss"

export default function ProfilCardWrapper({ children, customCls }) {
    return (
        <div className={classNames("profil-card", {
            [customCls]: customCls,
        })}>
            {children}
        </div>
    )
}

ProfilCardWrapper.propTypes = {
    children: node,
    customCls: string,
}
