
import RoutesLeagues from "./soccer/RoutesLeagues"
import RoutesProfile from "./soccer/RoutesProfile"
import Preloader from "../components/loaders/preloader/preloader.jsx"
import NotFound from "../components/notFound/notFound.jsx"

const RoutesBusiness = [
    {
        element: (<Preloader fixed />),
        path: "/reload/*",
    },
    {
        element: (<NotFound/>),
        path: "/*",
    },
    ...RoutesProfile,
    ...RoutesLeagues,
]

export default RoutesBusiness
