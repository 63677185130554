import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import { deleteFavoriteSlot } from "./api"
import { FavoriteSlotItem } from "./FavoriteSlotItem/FavoriteSlotItem"
import { filterSlotsByActivity, hydrateSlots } from "./functions"
import { SlotModal } from "./SlotModal/SlotModal"
import ButtonCta from "../../../components/Buttons/button/button-cta"
import ConfirmPopin from "../../../components/ConfirmPopin/ConfirmPopin"
import PageHeader from "../../../components/layout/pageHeader/PageHeader"
import Preloader from "../../../components/loaders/preloader/preloader"
import { getUsCentersBookable, getUser } from "../../../globalAPI/api"
import useUrbanLanguage from "../../../hooks/urbanLanguage/useUrbanLanguage.js"
import useMediaPath from "../../../hooks/useMediaPath"
import useThemeHeader from "../../../hooks/useThemeHeader"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import sortCenters from "../../reservation/tunnelReservation/services/sortCenters"

import "./FavoriteSlotsPage.scss"

export const FavoriteSlotsPage = () => {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ displayLimitMessage, setDisplayLimitMessage ] = useState(false)
    const [ centers, setCenters ] = useState([])
    const [ slots, setSlots ] = useState(null)
    const hasFavoriteSlots = slots?.length > 0
    const hasReachedMaxSlots = slots?.length >= 3
    const location = useLocation()
    const navigate = useNavigate()
    const userLoaded = useSelector(state => state.userLoaded) ?? []
    const themeHeader = useThemeHeader()
    const mediaPath = useMediaPath()
    const { tu } = useUrbanLanguage()

    useEffect(() => {
        setIsLoading(true)
        getUsCentersBookable(themeHeader).then((res) => {
            const tempCenters = Object.values(res.data)
            const sortedCenters = sortCenters(tempCenters)
            setCenters(sortedCenters)
            if (userLoaded?.preferredSlots?.length > 0) {
                const hydratedSlots = hydrateSlots(userLoaded.preferredSlots, sortedCenters)
                setSlots(filterSlotsByActivity(hydratedSlots, themeHeader) ?? [])
            } else {
                setSlots([])
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }, [ userLoaded, themeHeader ])

    useEffect(() => {
        // detect if we need to open modal, can also preSelectionValues from Booking tunnel
        if (location.state?.openModal && centers.length > 0) {
            openModal()
            navigate(location.pathname, { state: { openModal: false } })
        }
    }, [ slots, centers ])

    useEffect(() => {
        if (displayLimitMessage && !hasReachedMaxSlots) {
            setDisplayLimitMessage(false)
        }
    }, [ slots, hasReachedMaxSlots ])

    const handleEdit = (slot) => {
        openModal(slot)
    }

    const handleDelete = (slot) => {
        ModalHandler.show(ConfirmPopin, {
            actions: {
                confirm: () => {
                    setIsLoading(true)
                    ModalHandler.hide(ConfirmPopin)
                    deleteFavoriteSlot(slot.id).finally(() => {
                        refreshUser()
                    })
                },
            },
            description: "Es-tu sûr de vouloir supprimer ce créneau favori ?",
            title: "Supprimer le créneau favori",
        }, { keepPreviousProps: true })
    }

    const refreshUser = () => {
        setIsLoading(true)
        getUser(true).finally(() => {
            setIsLoading(false)
        })
    }

    const handleAddClick = () => {
        if (hasReachedMaxSlots) {
            setDisplayLimitMessage(true)
        } else {
            openModal()
        }
    }

    useEffect(() => {
        ModalHandler.update(SlotModal, {
            bookables: centers,
            onSlotCreated: refreshUser,
            preSelectionValues: location?.state?.preSelectionValues ?? null,
            user: userLoaded,
        })
    }, [ centers ])

    const openModal = (slot = undefined) => {
        ModalHandler.show(SlotModal, {
            bookables: centers,
            onSlotCreated: refreshUser,
            preSelectionValues: location?.state?.preSelectionValues ?? null,
            slot: slot,
            user: userLoaded,
        })
    }

    const headerButton = (
        <ButtonCta
            icon={mediaPath([ "/assets/icons/favoriteSlot/add-favorite-slot.svg", "/assets/icons/favoriteSlot/add-favorite-slot-black.svg" ])}
            hoverChangeIcon={"/assets/icons/favoriteSlot/add-favorite-slot.svg"}
            text={"Ajouter un créneau"}
            onClick={handleAddClick}
            isCta2Alt
            className={displayLimitMessage ? "cursor-not-allowed" : ""}
        />
    )

    return (
        <div className={"creneauxFavorisPage"}>
            <PageHeader
                title={"Mes créneaux favoris"}
                customCls={"favorite-slots-header"}
                goBack={false}
                button={headerButton}
            />
            <PageHeader
                customCls={"favorite-slots-header-mobile"}
                button={headerButton}
            />
            <motion.div className={"c-row"} layout key={"container"}>
                {isLoading ? (
                    <Preloader fixed/>
                ) : (
                    <motion.div layout className={"c-col c-col--10 c-col--sm--12 no-padding "}>
                        {!isLoading && !hasFavoriteSlots && (
                            <p className={"no-slot-message"}>
                                <img alt={"empty"} src={mediaPath(
                                    [ "/assets/icons/favoriteSlot/no-favorite-slot.svg", "/assets/icons/favoriteSlot/padel/no-favorite-slot.svg" ],
                                )} className="alertIcon"/>
                                Ajoute tes créneaux favoris pour être averti dès qu’{tu("booking.favoriteSlotField")} se libère !<br />
                                Tu pourras ensuite réserver ta partie en 1 clic.
                            </p>
                        )}
                        <AnimatePresence>
                            {displayLimitMessage && (
                                <motion.div
                                    key={"alert-message"}
                                    className={"limit-message"}
                                    animate={{ opacity: 1 }}
                                    initial={{ opacity: 0 }}
                                    exit={{ opacity: 0 }}
                                    layout
                                >
                                    <motion.img layout alt="Notif" src={mediaPath([ "/assets/icons/notifOrange.svg", "/assets/images/padel/icons/icons-notifications.svg" ])} className="alertIcon"/>
                                    <motion.p>
                                        Tu as atteint le nombre maximum de créneaux favoris.
                                        Modifie ou supprime un créneau favori existant pour créer une autre alerte.
                                    </motion.p>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        <motion.div
                            key={"list"}
                            className={"favorite-slots-list"}
                            variants={containerAnimation}
                            initial="hidden"
                            layout
                            animate="show"
                        >
                            {Array.isArray(slots) && slots?.map((slot) => (
                                <FavoriteSlotItem
                                    key={slot.id}
                                    variants={itemAnimation}
                                    slot={slot}
                                    itemKey={"slot-" + slot.id}
                                    onEdit={() => handleEdit(slot)}
                                    onDelete={() => handleDelete(slot)}
                                />
                            ))}
                        </motion.div>
                    </motion.div>
                )}
            </motion.div>
        </div>
    )
}

const containerAnimation = {
    hidden: { opacity: 0, y:20 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.15,
        },
        y: 0,
    },
}

const itemAnimation = {
    hidden: {
        opacity: 0, y: 20,
    },
    show: {
        opacity: 1, y: 0,
    },
}
