import { _apiCall } from "../../../backend/axios"
import { returnAnswerData } from "../../../backend/helpers"
import playersNumber from "../../../components/reservation/LocationBlock/playersNumber"

export function checkAvailabilities(data, theme)  {
    const dataCopy = { ...data }
    if (!dataCopy.durations && dataCopy.categories === "[9]") {
        dataCopy.durations = "[45, 90, 120]"
    }
    return _apiCall(
        "read/reservation/availabilities/search",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
            activity: theme,
        },
        dataCopy,
    ).then(returnAnswerData)
}

export function preBook({
    centerId,
    resourceType,
    start,
    duration,
    maxAttendees,
    // alertQueryId,
    discountConfigId,
    preferredSlotId,
    theme,
}) {
    const maxAtt = maxAttendees ?? playersNumber.find(number => number.types.includes(parseInt(resourceType)))?.players

    return _apiCall(
        "create/pre-reservation",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
            activity: theme,
        },
        {
            centerId: centerId,
            discountConfigId: discountConfigId,
            duration: duration,
            maxAttendees: maxAtt,
            preferredSlotId: preferredSlotId,
            resourceType: resourceType,
            start: start,
        },
    ).then(
        returnAnswerData,
    )
}

export function bookWithoutPaying(preReservationId) {
    return _apiCall(
        "create/reservation/withoutpaying",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        {
            preReservationId: preReservationId,
        },
    ).then(returnAnswerData)
}

export function extendPreBookValidity(preReservationId) {
    return _apiCall(
        `modify/pre-reservation/${preReservationId}/extend-validity`,
        "PUT",
    ).then(returnAnswerData)
}

export function modifyPreBookMaxAttendees(preReservationId, maxAttendees) {
    return _apiCall(
        `modify/pre-reservation/${preReservationId}/max-attendees/${maxAttendees}`,
        "PUT",
        {
            "Content-Type" : "multipart/form-data",
        },
    ).then(returnAnswerData)
}

export function getPreBook(id) {
    return _apiCall(
        `read/pre-reservation/${id}`,
        "GET",
    ).then(returnAnswerData)
}

export function addPrebookDiscountCode(id, code) {
    return _apiCall(
        `modify/pre-reservation/${id}/discount-code?code=${code}`,
        "PUT",
    ).then(returnAnswerData)
}

export function removePrebookDiscountCode(id, code) {
    return _apiCall(
        `delete/pre-reservation/${id}/discount-code?code=${code}`,
        "DELETE",
    ).then(returnAnswerData)
}
