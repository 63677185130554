import truncateToTwoDecimals from "../../../../services/truncateToTwoDecimals"

const getPartsSelect = (location, players, setPart, specialDiscountCalculation, part) => {
    const discount = location?.discount?.discount
    const priceToPay = truncateToTwoDecimals(location?.price / players)
    const discountPartsCalculation = discount ?  Math.ceil(discount / priceToPay) : null
    const minParts = specialDiscountCalculation ? (discountPartsCalculation > players ? players : discountPartsCalculation) : 1
    function generateArrayBetweenNumbers(start, end) {
        const result = []
        for (let i = start; i <= end; i++) {
            result.push([ i, i + (i === 1 ? " part" : " parts") ])
        }
        return result
    }
    setPart?.(part && parseInt(part) <= players ? part <= minParts ? minParts : part : players)
    return generateArrayBetweenNumbers(minParts, players)
}

getPartsSelect.defaultProps = {
    specialDiscountCalculation: undefined,
}
export default getPartsSelect
