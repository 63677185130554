import { clearAllBodyScrollLocks } from "body-scroll-lock-upgrade"
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from "react"

const MODAL_ID = Symbol.for("modal_id")
const MODAL_HOC_TYPE = Symbol.for("modal_hoc_type")

export const MODAL_REGISTRY = {}

let id = 0
export const getUid = () => `_modal_${id++}`

export function isValidModalHOC(object) {
    const is = object && object.__typeof_modal__ === MODAL_HOC_TYPE

    if (!is) {
        throw new Error("Please use Modal.create(Component)! Otherwise, it cannot be destroyed correctly")
    }

    return is
}

const ModalContext = React.createContext([])
const ModalIdContext = React.createContext(null)

let dispatch = () => {
    throw new Error("No dispatch method detected, did you embed your app with Modal.Provider?")
}

function reducer(state, action) {
    const { id } = action.payload
    const newState = [ ...state ]
    const index = newState.findIndex((v) => v.id === id)

    switch (action.type) {
    case "modal/show": {
        if (index > -1) {
            newState[index] = {
                ...newState[index],
                ...action.payload,
                visible: true,
            }
        } else {
            newState.push({
                ...newState[index],
                ...action.payload,
                visible: true,
            })
        }

        return newState
    }

    case "modal/update": {
        newState[index] = {
            ...newState[index],
            ...action.payload,
        }
        return newState
    }

    case "modal/hide": {
        newState[index] = {
            ...newState[index],
            ...action.payload,
            visible: false,
        }

        return newState
    }

    case "modal/hide_others": {
        return state.map(modal => {
            if (modal.id !== action.payload.exceptId) {
                return { ...modal, visible: false }
            }
            return modal
        })
    }

    case "modal/remove": {
        newState.splice(index, 1)

        return newState
    }
    default:
        return state
    }
}

function showModal(id, props, promise, config) {
    return {
        payload: {
            config,
            id,
            promise,
            props,
        },
        type: "modal/show",
    }
}

function updateModal(id, props) {
    return {
        payload: {
            id,
            props,
        },
        type: "modal/update",
    }
}

function hideModal(id) {
    return {
        payload: {
            id,
        },
        type: "modal/hide",
    }
}

function hideOtherModals(exceptId) {
    return {
        payload: {
            exceptId,
        },
        type: "modal/hide_others",
    }
}

function removeModal(id) {
    return {
        payload: {
            id,
        },
        type: "modal/remove",
    }
}

const getModalId = (Modal) => {
    if (!Modal) {return}

    if (typeof Modal === "string") {return Modal}

    if (!Modal[MODAL_ID]) {Modal[MODAL_ID] = getUid()}

    return Modal[MODAL_ID]
}

function findModal(Modal) {
    if (typeof Modal === "string" && MODAL_REGISTRY[Modal]) {
        return MODAL_REGISTRY[Modal].Component
    }

    const find = Object.values(MODAL_REGISTRY).find((item) => item.Component === Modal)

    return find ? find.Component : void 0
}

const memoizedModals = new Map()

function create(Comp) {
    if (!Comp) {new Error("Please provide a valid react component.")}
    // eslint-disable-next-line react/prop-types
    const ModalHOCWrapper = ({ id: modalId }) => {
        const { id, props, promise, config, visible, ...innerProps } = useModal(modalId)
        const shouldRemove = useRef(false)

        useEffect(() => {
            shouldRemove.current = config?.removeOnHide && !visible
        }, [ visible, config?.removeOnHide ])

        useEffect(() => {
            return () => {
                if (config?.removeOnHide) {
                    if (shouldRemove.current) {
                        remove(modalId)
                    }
                }
            }
        }, [ modalId ])

        return (
            <ModalIdContext.Provider value={id}>
                <Comp {...props} modalId={id} {...promise} {...innerProps} visible={visible}/>
            </ModalIdContext.Provider>
        )
    }

    ModalHOCWrapper.__typeof_modal__ = MODAL_HOC_TYPE

    if (!memoizedModals.has(Comp)) {
        memoizedModals.set(Comp, ModalHOCWrapper)
    }

    return memoizedModals.get(Comp)
}

function register(id, Modal, props) {
    if (!MODAL_REGISTRY[id]) {
        MODAL_REGISTRY[id] = { Component: Modal, id, props }
    }
}

function show(Modal, newProps = {}, config = {}) {
    config.removeOnHide = config.removeOnHide ?? true
    config.resolveOnHide = config.resolveOnHide ?? true
    config.keepPreviousProps = config.keepPreviousProps ?? false
    config.keepOtherModalsOpen = config.keepOtherModalsOpen ?? false

    const _Modal = (isValidModalHOC(Modal) ? Modal : create(Modal))
    const id = getModalId(_Modal)
    const find = findModal(_Modal) ?? findModal(id)

    if (!config.keepOtherModalsOpen) {
        dispatch(hideOtherModals(id))
    }

    if (!find) {
        register(id, _Modal, newProps)
    }

    let theResolve
    let theReject

    const promise = new Promise((resolve, reject) => {
        theResolve = resolve
        theReject = reject
    })

    const modalPromise = {
        reject: theReject,
        resolve: theResolve,
    }

    // Merge existing props with new props if keepPreviousProps is true
    const existingProps = MODAL_REGISTRY[id] ? MODAL_REGISTRY[id].props : {}
    const finalProps = config.keepPreviousProps ? { ...existingProps, ...newProps } : newProps

    dispatch(showModal(id, finalProps, modalPromise, config))
    return promise
}

function update(Modal, props = {}) {
    if (!isValidModalHOC(Modal)) {
        new Error("If you want to update Comp, Please use Modal.create and pass in Modal.update(/* Comp */)")
    }
    const id = getModalId(Modal)
    if (!id) {throw new Error("No id found in Modal.update.")}
    dispatch(updateModal(id, props))
}

function hide(Modal) {
    const id = getModalId(Modal)

    if (id) {
        dispatch(hideModal(id))
    }
}

function remove(Modal) {
    const id = getModalId(Modal)
    if (id) {
        dispatch(removeModal(id))
        delete MODAL_REGISTRY[id]
    }
}

export function useModal(id) {
    const modals = useContext(ModalContext)
    const contextModalId = useContext(ModalIdContext)

    if (!id) {id = contextModalId}
    if (!id) {throw new Error("No modal id found in useModal.")}

    const modalInfo = modals.find((t) => t.id === id)
    if (!modalInfo) {throw new Error("No modalInfo found in useModal.")}

    const { promise, config } = modalInfo
    const modalId = id

    const hideCallback = useCallback(
        (result) => {
            hide(modalId)
            if (config?.removeOnHide) {
                remove(modalId)
            }
            config?.resolveOnHide && promise?.resolve(result)
        },
        [ modalId, promise, config?.removeOnHide, config?.resolveOnHide ],
    )

    const showCallback = useCallback(
        () => {
            show(modalId)
        },
        [ modalId ],
    )

    const removeCallback = useCallback(() => {
        remove(modalId)
    }, [ modalId ])

    return {
        hide: hideCallback,
        remove: removeCallback,
        show: showCallback,
        ...promise,
        ...modalInfo,
    }
}

const ModalPlaceholder = () => {
    const modals = useContext(ModalContext)

    const visibleModals = modals.filter((item) => item.id && MODAL_REGISTRY[item.id])

    if (!visibleModals.length || !visibleModals?.find((item) => item.visible)) {
        clearAllBodyScrollLocks()
    }

    const toRender = visibleModals.map((item) => {
        return {
            Component: MODAL_REGISTRY[item.id].Component,
            id: item.id,
        }
    })

    return (
        <>
            {toRender.map((item, index) => (
                <item.Component key={index} id={item.id} />
            ))}
        </>
    )
}

// eslint-disable-next-line react/prop-types
const Provider = ({ children }) => {
    const arr = useReducer(reducer, [])
    const modals = arr[0]
    const fnRef = useRef()
    fnRef.current = useMemo(() => {
        return function innerDispatch(action) {
            arr[1](action)
        }
    }, [ arr ])

    dispatch = fnRef.current

    return (
        <ModalContext.Provider value={modals}>
            {children}
            <ModalPlaceholder />
        </ModalContext.Provider>
    )
}

const useReactiveModal = (ModalComponent, initialProps = {}) => {
    const { create, show, update } = ModalHandler
    const Modal = React.useMemo(() => create(ModalComponent), [ ModalComponent ])

    const showModal = React.useCallback((props = {}) => {
        return show(Modal, { ...initialProps, ...props })
    }, [ Modal, initialProps ])

    const updateModal = React.useCallback((props = {}) => {
        update(Modal, props)
    }, [ Modal ])

    return { show: showModal, update: updateModal }
}

const ModalHandler = {
    ModalContext,
    Provider,
    create,
    hide,
    remove,
    show,
    update,
    useModal,
    useReactiveModal,
}

export default ModalHandler
