import { any, array, bool, func, object, oneOfType, string } from "prop-types"
import { forwardRef, useImperativeHandle, useState } from "react"

import Button from "../../../components/Buttons/button/button"
import CheckBlock from "../../../components/Forms/CheckBlock/CheckBlock"
import Modal from "../../../components/Modal/Modal"
import useMediaPath from "../../../hooks/useMediaPath"

const SupersubsFilterPopin = forwardRef(({
    haveOnlyOneCenter,
    centers,
    selectedCenter,
    selectCenterFilter,
    checkFiltersChange,
    setSelectedCenter,
    types,
    selectedType,
    selectTypesFilter,
    setSelectedType,
    durations,
    selectedDuration,
    selectDurationsFilter,
    setSelectedDuration,
    hoursScroll,
    setSelectedHour,
    hours,
    selectedHour,
    sameAsOldFilters,
    applyChangeCallback,
    timeFilter,
    resetFromOldFilters,
}, ref) => {
    const mediaPath = useMediaPath()
    const [ internalModalState, setInternalModalState ] = useState(false)

    useImperativeHandle(ref, () => ({
        hide: () => {
            setInternalModalState(false)
        },
        show: () => {
            setInternalModalState(true)
        },
    }))

    return (
        <Modal
            isOpen={internalModalState}
            onClose={() => setInternalModalState(false)}
            beforeOpen={resetFromOldFilters}
            className='superSubFilters searchFilterPopin'
        >
            <header>Tes options</header>
            <section>
                {centers.length > 0 && !haveOnlyOneCenter &&
                        (<>
                            <h2>Centre</h2>
                            <div className={centers.length <= 3  ? "centersWrapper" : "centersWrapper grid"}>
                                {centers.map(
                                    (center, key) => (
                                        <CheckBlock
                                            checked={selectedCenter && selectedCenter.includes(center)}
                                            // checked={selectedCenter && selectedCenter.findIndex(item => item[0] === key) !== -1}
                                            callback={() => {
                                                checkFiltersChange("center",
                                                    selectCenterFilter(center,
                                                        selectedCenter ? selectedCenter : [],
                                                        setSelectedCenter,
                                                    ))
                                            }}
                                            value={key}
                                            key={key + center}
                                            unselect={true}
                                        >
                                            {center}
                                        </CheckBlock>
                                    ),
                                )}
                            </div>
                        </>)
                }
                {types.length > 0 &&
                        (
                            <>
                                <h2>Type de terrain</h2>
                                <div className="typesWrapper">
                                    {types.map(
                                        (type, key) => (
                                            <CheckBlock
                                                checked={selectedType && selectedType.includes(type)}
                                                callback={() => {
                                                    checkFiltersChange("type", selectTypesFilter(type, selectedType ? selectedType : [], setSelectedType))
                                                }}
                                                value={key}
                                                key={key + type}
                                                unselect={true}
                                            >
                                                {type}
                                            </CheckBlock>
                                        ),
                                    )}
                                </div>
                            </>
                        )}

                <h2>Durée</h2>
                <div className="durationsWrapper">
                    {durations.map(
                        duration =>  (
                            <CheckBlock
                                checked={selectedDuration && selectedDuration.findIndex(item => item === duration) !== -1}
                                callback={() => checkFiltersChange("duration", selectDurationsFilter(duration, selectedDuration, setSelectedDuration))}
                                value={duration}
                                key={"filterDuration" + duration}
                                unselect={true}
                            >
                                {duration}
                            </CheckBlock>
                        ),
                    )}
                </div>
                <h2>Horaire à partir de</h2>
                <div className="arrowsWrapper">
                    <img
                        src={mediaPath([ "/assets/icons/arrow-left-orange.svg", "/assets/icons/arrow-left-black.svg" ])}
                        className="previousHours"
                        onClick={
                            () => {
                                let hsScrollLeft = hoursScroll.current.scrollLeft
                                hoursScroll.current.scrollLeft = hsScrollLeft === 140 ?
                                    hsScrollLeft - 140
                                    :
                                    hsScrollLeft - 75
                                    
                            }
                        }
                    />
                    <div className="opacity">
                        <div className="hoursWrapper" ref={hoursScroll}>

                            <CheckBlock
                                callback={
                                    () => {
                                        checkFiltersChange("hour")
                                        setSelectedHour([])
                                    }
                                }
                                checked={selectedHour.length === 0}
                                value={"noHourFilter"}
                            >
                                <img
                                    src={selectedHour === "noHourFilter" ? "/assets/icons/forbidden-white.svg" : "/assets/icons/forbidden-grey.svg"}/>
                            </CheckBlock>

                            {hours.map(
                                (hour) => {
                                    const isNotDisabledTime = (
                                        timeFilter
                                            && timeFilter.start <= hour.value
                                            && timeFilter.end >= hour.value
                                    )
                                    const noTimeFilter = !timeFilter
                                    // const isNotPassedHour = !(todayDate > new Date(todayPopin.split("T")[0] + hour.value));
                                    if (
                                        isNotDisabledTime || noTimeFilter
                                    // (isNotDisabledTime || noTimeFilter)
                                    // && isNotPassedHour
                                    ) {
                                        return (
                                            <CheckBlock
                                                callback={
                                                    () => {
                                                        checkFiltersChange("hour")
                                                        setSelectedHour(hour)
                                                    }
                                                }
                                                checked={selectedHour && selectedHour.value === hour.value}
                                                value={hour.value}
                                                key={hour.value}
                                            >
                                                {hour.display}
                                            </CheckBlock>
                                        )
                                    }
                                },
                            )}
                        </div>
                    </div>
                    <img
                        src={mediaPath([ "/assets/icons/arrow-right-orange.svg", "/assets/icons/arrow-right-black.svg" ])}
                        className="nextHours"
                        onClick={
                            () => {
                                let hsScrollLeft = hoursScroll.current.scrollLeft
                                hoursScroll.current.scrollLeft = hsScrollLeft + 70
                            }
                        }
                    />
                </div>
                <Button
                    disabled={sameAsOldFilters}
                    text="Appliquer"
                    color="orange"
                    onClick={
                        () => applyChangeCallback({
                            center: selectedCenter,
                            duration: selectedDuration,
                            hour: selectedHour,
                            type: selectedType,
                        })
                    }
                />
            </section>
        </Modal>
    )
},
)

SupersubsFilterPopin.displayName = "SupersubsFilterPopin"

SupersubsFilterPopin.propTypes = {
    applyChangeCallback: func,
    centers: array,
    checkFiltersChange: func,
    durations: array,
    haveOnlyOneCenter: bool,
    hours: array,
    hoursScroll: object,
    resetFromOldFilters:func,
    sameAsOldFilters: bool,
    selectCenterFilter: func,
    selectDurationsFilter: func,
    selectTypesFilter: func,
    selectedCenter: array,
    selectedDuration: array,
    selectedHour: oneOfType([ string, object ]),
    selectedType: array,
    setSelectedCenter: func,
    setSelectedDuration: func,
    setSelectedHour: func,
    setSelectedType: func,
    timeFilter: any,
    todayDate: object,
    todayPopin: string,
    types: array,
}

export default SupersubsFilterPopin
