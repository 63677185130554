import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import OverlayMenuContent from "./overlay_menu_content"
import { selectHamburgerMenu } from "../../../store/actions/scaffold/index"

import "./overlay_menu.scss"

function OverlayMenu() {
    const menuOpen = useSelector(state => state.menuSelect.open)
    const dispatch = useDispatch()

    function open() {
        const app = document.getElementById("root")
        if (menuOpen) {
            if (app) {
                app.classList.add("appMenuMobileOpen")
            }
            // Prevent body from scrolling when the menu is open
            document.body.style.overflow = "hidden"
            return true
        }
        if (app) {
            app.classList.remove("appMenuMobileOpen")
        }
        // Re-enable scrolling when the menu is closed
        document.body.style.overflow = ""
        return false
    }

    useEffect(() => {
        const listenerClickOutside = (event) => {
            const container = document.querySelector(".theme")
            let targetEl = event.target

            /* istanbul ignore next */
            if (container === targetEl) {dispatch(selectHamburgerMenu(false))}
        }

        if (menuOpen) {
            document.addEventListener("click", listenerClickOutside)
        } else {
            document.removeEventListener("click", listenerClickOutside)
        }
        return () => document.removeEventListener("click", listenerClickOutside)
    }, [ menuOpen ])

    return (
        <div>
            <div id="overlay-menu" className={open() ? " " : " hidden"} >
                <OverlayMenuContent />
            </div>
        </div>
    )
}

export default OverlayMenu
