import PropTypes from "prop-types"

import ModalHandler from "../../../providers/Modal/ModalHandler"
import Modal from "../../Modal/Modal"

import "./CbAliasError.scss"

const CbAliasError = ModalHandler.create(({ visible, hide }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className='cbAliasError'
        >
            <header>Problème carte enregistrée</header>
            <section>
                Suite à une erreur, la carte bancaire enregistrée sur ton compte a été supprimée.
            </section>
        </Modal>
    )
})

CbAliasError.displayName = "CbAliasError"

CbAliasError.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default CbAliasError
