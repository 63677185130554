export default function disconnectUser(
    customUrl,
) {
    localStorage.removeItem("auth-token")
    localStorage.removeItem("auth-userid")

    // const returnUrl = customUrl ? customUrl : import.meta.env.VITE_URL_BASE;
    if (typeof customUrl === "string") {
        window.location.href = customUrl
    } else {
        window.location.href = "/login"
    }
}
