export const getDiscountCode = (bookables) => {
    const discountMap = new Map()
    function alphabeticSort(a, b) {
        return a.label.localeCompare(b.label)
    }

    bookables.forEach(bookable => {
        bookable.discounts?.forEach(discount => {
            if (discount.id && !discountMap.has(discount.id)) {
                discountMap.set(discount.id, discount)
            }
        })
    })

    return Array.from(discountMap.values()).sort(alphabeticSort)
}

export const deleteDoubleDiscountCode = (discounts) => {
    const discountCodes = []
    discounts.forEach(discount => {
        if (!discountCodes.find(disc => disc.label === discount.label)) {
            discountCodes.push(discount)
        }
    })
    return discountCodes
}

export const  getTypeName = (id, name) => {
    if (id === 8 || id === 10 || id === 14) {return id === 8 || id === 14 ? "Intérieur (Filmé)" : "Extérieur (Filmé)"}
    else if (id === 0 || id === 12) {return "Intérieur"}
    else if (id === 1 || id === 15) {return "Extérieur"}
    else if (id === 13) {return "7 contre 7"}
    else if (id === 2) {return "3 contre 3"}
    else {return name}
}
