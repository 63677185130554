// Purpose: Holds all the framer motion animation presets for the app
export class AnimationPresets {
    static  fadeEase = {
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        initial: { opacity: 0 },
        transition: { duration: 0.2, ease: "easeInOut" },
    }

    static fadeUpEase = {
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 20 },
        initial: { opacity: 0, y: 20 },
        transition: { duration: 0.2, ease: "easeInOut" },
    }

    static fadeDownEase = {
        animate: { height: "auto", marginTop: 16, opacity: 1, y: 0 },
        exit: { height: 0, marginTop: 0, opacity: 0, y: -20 },
        initial: { height: 0, marginTop: 0, opacity: 0, y: -20 },
        transition: { duration: .25, ease: "easeInOut" },
    }

    static fadeInHeightEase = {
        animate: { height: "auto", opacity: 1 },
        exit: { height: 0, opacity: 0 },
        initial: { height: 0, opacity: 0 },
        transition: { duration: 0.2, ease: "easeInOut" },
    }

    static gridRevealBuilder(multiplier = 1) {
        return {
            hidden: {
                opacity: 0,
                scale: 0.8,
            },
            visible: i => ({
                opacity: 1,
                scale: 1,
                transition: {
                    delay: i * multiplier * 0.12,
                },
            }),
        }
    }
}

