import APP_CONFIG from "../business/config"

export default function mustChangeTeamsNames(loc) {
    const { team1, team2, team1CustomName, team2CustomName } = loc
    const team1Players = team1?.map(player => player?.firstname).sort()
    const team2Players = team2?.map(player => player?.firstname).sort()
    const notCustomizedTeam1Name = (team1CustomName && (
        team1CustomName === APP_CONFIG.padelDefaultTeamsNames.team1
        || (team1Players?.[0] && team1Players?.[1] && team1CustomName === `${team1Players[0]}/${team1Players[1]}`)
    )) || !team1CustomName
    const notCustomizedTeam2Name = (team2CustomName && (
        team2CustomName === APP_CONFIG.padelDefaultTeamsNames.team2
        || (team2Players?.[0] && team1Players?.[1] && team2CustomName === `${team2Players[0]}/${team2Players[1]}`)
    )) || !team2CustomName
    return { notCustomizedTeam1Name, notCustomizedTeam2Name }
}
