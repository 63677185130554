/* eslint-disable no-irregular-whitespace */
import classNames from "classnames"
import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { modifyFavoriteUniverse } from "./api"
import ContactMe from "./components/ContactMe/ContactMe"
import MyProfil from "./components/MyProfil/MyProfil"
import MySportInfos from "./components/MySportInfos/MySportInfos"
import { myInformationStepperTitle, uploadFile } from "./functions/functions"
import AuthStep from "../../components/authentification/authStep/authStep"
import AuthStepper from "../../components/authentification/authStepper/authStepper"
import { sendConfirmEmail } from "../../components/authentification/emailConfirmation/api"
import EmailConfirmation from "../../components/authentification/emailConfirmation/emailConfirmation"
import ButtonCta from "../../components/Buttons/button/button-cta"
import Toggle from "../../components/Buttons/toggle/Toggle"
import FileInput from "../../components/Forms/FileInput/FileInput"
import GatherEmail from "../../components/Forms/gatherEmail/gatherEmail"
import PageHeader from "../../components/layout/pageHeader/PageHeader"
import Preloader from "../../components/loaders/preloader/preloader"
import { useTheme } from "../../components/ThemeContext/ThemeContext"
import useMediaPath from "../../hooks/useMediaPath"
import useResetScroll from "../../hooks/useResetScroll"
import useThemeHeader from "../../hooks/useThemeHeader"
import { useAssets } from "../../providers/AssetsProvider.jsx"
import UserAgentHandler from "../../services/UserAgentHandler"
import { goNextAuthStep } from "../../store/auth/actions/actions"
import PhoneConfirm from "../authentication/phoneConfirm/phoneConfirm"

import "./MyInformation.scss"

function MyInformation() {
    useResetScroll()
    const mediaPath = useMediaPath()
    const theme = useTheme()
    const themeHeader = useThemeHeader()
    const userLoaded = useSelector(state => state.userLoaded)

    const currentAuthStepId = useSelector(state => state.auth.currentAuthStepId)

    const [ displayPhone, setDisplayPhone ] = useState(false)
    const [ displayEmail, setDisplayEmail ] = useState(false)
    const [ favoriteUnivers, setFavoriteUnivers ] = useState(userLoaded?.favoriteUniverse)
    const [ universeSaved, setUniverseSaved ] = useState(false)
    const { m } = useAssets()

    const inputUpload = useRef(null)

    const toggleDisplayPhone = () => setDisplayPhone(!displayPhone)

    /* EMAIL EDITION */
    const [ isSending, setIsSending ] = useState(false)
    const [ editedEmailAdress, setEditedEmailAdress ] = useState(userLoaded.emailAddress)
    const [ retryComplete, setRetryComplete ] = useState(false)
    const [ isSuccess, setIsSuccess ] = useState(false)
    const [ successOrErrorMsg, setSuccessOrErrorMsg ] = useState(false)
    const [ gatherMailSubmit, setGatherMailSubmit ] = useState(false)
    const [ changeMailMsg, setChangeMailMsg ] = useState(null)

    /* ACTIONS */
    const dispatch = useDispatch()

    const imageSize = 320

    return (
        <div className={`p-myInformation ${(UserAgentHandler.isAndroid() || UserAgentHandler.isIOS()) && !UserAgentHandler.isFirefox() ? "padding-bottom" : "" }`}>
            <PageHeader title="Profil" />
            <div className="c-row">
                <div className="c-col c-col--3 c-col--m--12 avatar-wrapper">
                    <div className="user-avatar">
                        <img src={userLoaded?.pictureUrl ?? "/assets/images/empty-avatar.svg"} className='avatar'/>
                        <FileInput
                            id="avatarUpload"
                            label="Modifier"
                            acceptType="image"
                            onFileUploadPromiseCallback={uploadFile}
                            imageCropWidth={window.innerWidth < 375 ? 200 : 300}
                            imageCropHeight={window.innerWidth < 375 ? 200 : 300}
                            outputWidth={imageSize}
                            outputHeight={imageSize}
                            hiddenInput={true}
                            subLabel={`Ton Image doit avoir une dimension de ${imageSize}px`}
                            maxSize={5}
                            ref={inputUpload}
                        >
                            <img className="cameraIcon" src={m("icons.profil.camera")} alt="appareil photo" />
                        </FileInput>
                        <div className="chooseFavoriteUnivers">
                            <div className="title">Univers préféré</div>
                            <div className="toggle-wrapper">
                                <div className="label">Soccer</div>
                                <Toggle
                                    enabled={userLoaded?.favoriteUniverse === 1}
                                    callback={checked => checked ? setFavoriteUnivers(1) : setFavoriteUnivers(0)}
                                />
                                <div className="label">Padel</div>
                            </div>
                            <ButtonCta
                                disabled={userLoaded?.favoriteUniverse === favoriteUnivers || universeSaved}
                                text="enregistrer"
                                onClick={
                                    () => {
                                        setUniverseSaved(true)
                                        modifyFavoriteUniverse(userLoaded, favoriteUnivers).then(res => {
                                            if (res) {setTimeout(() => setUniverseSaved(false), 3000)}
                                        })
                                    }
                                }
                            />
                            <div className={classNames("saved", { visible: universeSaved })}>Univers sauvegardé</div>
                        </div>
                    </div>
                    
                </div>
                <div className="c-col c-col--8 c-col--m--12">
                    <section className="p-myInformation__infos">
                        <MyProfil />
                        {theme === "theme-padel" && <MySportInfos />}
                        <ContactMe toggleDisplayPhone={toggleDisplayPhone} setDisplayEmail={setDisplayEmail} />
                    </section>
                </div>
            </div>

            { displayPhone &&
                (<div className="overlay-level3 no-pdg flex-column">
                    <PhoneConfirm
                        prevAction={() => toggleDisplayPhone()}
                        initialPhoneNumber={userLoaded.phoneNumber}
                        step1Title="Renseigner un téléphone"
                        step2Title="Confirmer ton téléphone"
                        forceChangePhone={true}
                        userId={userLoaded.id}
                    />
                </div>)
            }

            { displayEmail &&
                (<div className="overlay-level3 no-pdg flex-column">
                    <AuthStepper
                        title={myInformationStepperTitle(currentAuthStepId)}
                        prevAction={
                            () => {
                                setDisplayEmail(false)
                                setEditedEmailAdress(userLoaded.emailAddress)
                            }
                        }
                        rootClasses="women-bkg"
                        help={<p>Tu rencontres un problème pour te connecter ou créer un compte ? Contacte-nous au 0 806 23 00 24</p>}
                    >
                        <AuthStep cls="forgottenPassword">
                            {
                                isSending &&
                                <Preloader fixed={true}/>
                            }
                            <div className="introText">Renseigne ton adresse e-mail ci-dessous..</div>
                            <GatherEmail
                                initialValue={editedEmailAdress}
                                needToChange={true}
                                onSubmit={(val) => {
                                    setEditedEmailAdress(val)
                                    setIsSending(true)
                                    sendConfirmEmail(userLoaded.id, val, false, themeHeader).then((resEmail) => {
                                        setIsSending(false)
                                        if (resEmail.data && resEmail.data.data && resEmail.data.data.Message) {
                                            setGatherMailSubmit(true)
                                            setIsSuccess(false)
                                            setChangeMailMsg(resEmail.data.data.Message)
                                        } else if (resEmail.data && resEmail.data.data) {
                                            setGatherMailSubmit(false)
                                            dispatch(goNextAuthStep())
                                        }
                                    })
                                }}
                            />

                            {
                                gatherMailSubmit &&
                                (<div className="errorSuccessLabelMyinfo">
                                    {
                                        isSuccess ?
                                            <img className="iconError" src="/assets/icons/notifDarkGreen.svg"/>
                                            :
                                            (<img className="iconError"
                                                src={mediaPath([ "/assets/icons/errorNotif.svg", "/assets/images/padel/icons/errorNotif.svg" ])}/>)
                                    }
                                    <div className={isSuccess ? "isSuccess" : ""}>
                                        {changeMailMsg}
                                    </div>
                                </div>)
                            }
                        </AuthStep>

                        <AuthStep>
                            {
                                isSending &&
                                    <Preloader fixed={true} />
                            }
                            <EmailConfirmation retryValue={editedEmailAdress ? editedEmailAdress : ""}
                                retryAction={() => {
                                    setIsSending(true)
                                    sendConfirmEmail(userLoaded.id, editedEmailAdress, false, themeHeader).then((resEmail) => {
                                        setRetryComplete(true)
                                        setIsSending(false)
                                        if (resEmail.data && resEmail.data.data && resEmail.data.data.Message) {
                                            setIsSuccess(false)
                                            setSuccessOrErrorMsg(resEmail.data.data.Message)
                                        } else if (resEmail.data && resEmail.data.data) {
                                            setIsSuccess(true)
                                            setSuccessOrErrorMsg("Un nouvel email a été envoyé")
                                        } else {
                                            setIsSuccess(false)
                                        }
                                    })
                                }}
                                onChangeAction={(val) => {
                                    setEditedEmailAdress(val)
                                }}
                                retryActionComplete={retryComplete}
                                isSuccess={isSuccess}
                                successOrErrorMsg={successOrErrorMsg}
                            />
                        </AuthStep>

                    </AuthStepper>
                </div>)
            }
        </div>
    )
}

export default MyInformation
