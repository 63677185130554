import classNames from "classnames"
import { bool, func } from "prop-types"

import Modal from "../../../Modal/Modal"
import { useTheme } from "../../../ThemeContext/ThemeContext"

import "./BrowserGeolocErrorPopin.scss"

const BrowserGeolocErrorPopin = ({ isOpen, show }) => {
    const theme = useTheme()
    return (
        <Modal
            withPortal
            portalIdentifier="#content"
            isOpen={isOpen}
            onClose={() => show(false)}
            className={classNames("browserGeolocErrorPopin", { [theme]: theme })}
        >
            <header>Autorisation</header>
            <div className="desc">
                Autorise la géolocalisation pour utiliser cette fonctionnalité
            </div>
        </Modal>
    )
}

BrowserGeolocErrorPopin.propTypes = {
    isOpen: bool,
    show: func,
}

export default BrowserGeolocErrorPopin
