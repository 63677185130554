import { hourOptions } from "../../../../../../components/HoursList/HoursList"
import locationHours from "../../../../../../model/data/reservation/locationHours"
import { formArgs } from "../function"

export const deserializationStrategies = () => {
    return {
        [formArgs.HOUR]: hourFilterDeserialization,
        [formArgs.TYPE_IDS]: typeIdsDeserialization,
        [formArgs.CENTER_ID]: parseInt,
        [formArgs.ACTIVITY_ID]: parseInt,
        [formArgs.SLOT_ID]: parseInt,
    }
}

const hourFilterDeserialization = (value) => {
    return value && locationHours?.find(
        option => option.display === hourOptions?.find(
            option => option.value === parseInt(value ?? ""),
        ).display,
    ) || "noHourFilter"
}

const typeIdsDeserialization = (value) => {
    return value?.split(",").map(id => parseInt(id)) ?? null
}
