const centerFilterSort = (a, b) => {
    if (a.display === b.display) {
        return 0
    } else if (a.display > b.display) {
        return 1
    } else {
        return -1
    }
}

export default centerFilterSort
