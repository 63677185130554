import { arrayOf, node, number, oneOfType, string } from "prop-types"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import getStepPositionClass from "./functions/getStepPositionClass"
import { goToStep } from "../../store/stepsManagement/actions"

import "./StepsContainer.scss"

export default function StepsContainer({ children, stepsToShow, min, max }) {
    const dispatch = useDispatch()

    const currentStep = useSelector(state => state.stepManagement.currentStep)
    const stepCount = useSelector(state => state.stepManagement.stepCount)
    const parentGoToStep =  (number) => dispatch(goToStep(number))

    const updateStepProps = (steps) => {
        let updatedSteps = []
        let minBorder = currentStep - (stepsToShow % 2 === 0 ? Math.ceil((stepsToShow - 1) / 2) : (stepsToShow - 1) / 2)
        let maxBorder = currentStep + (stepsToShow % 2 === 0 ? Math.trunc((stepsToShow - 1) / 2) : (stepsToShow - 1) / 2)
        if (minBorder < min) {
            minBorder = min
            maxBorder = min + (stepsToShow - 1)
        } else if (maxBorder > max) {
            maxBorder = max
            minBorder = max - (stepsToShow - 1)
        }

        steps.forEach(
            (element, index) => {
                index = index + min
                if (index >= minBorder && index <= maxBorder) {
                    updatedSteps.push(
                        React.cloneElement(
                            element,
                            { 
                                cls: ( 
                                    index === currentStep ?
                                        "active" 
                                        :
                                        "compact"
                                ) + " " + getStepPositionClass(index, stepCount),
                                key: index + 1, 
                                numero: index + 1,
                                onHeaderClick: (index) => {
                                    parentGoToStep(index)
                                },
                            },
                        ),
                    )
                }
            },
        )
        return updatedSteps
    }
    
    return (
        <section className="stepsContainer">
            {updateStepProps(children)}
        </section>
    )
}

StepsContainer.propTypes = {
    children: oneOfType([ string, node, arrayOf(node) ]),
    max: number,
    min: number,
    stepsToShow: oneOfType([ number, string ]),
}
