import classNames from "classnames"
import { array } from "prop-types"
import { useDispatch } from "react-redux"
import { NavLink, matchPath, useLocation } from "react-router-dom"

import { selectHamburgerMenu, selectUserProfilMenu } from "../../../../store/actions/scaffold"
import CheckBlock from "../../../Forms/CheckBlock/CheckBlock"
import Notifications from "../../../Icons/Notifications/Notifications"

import "./profileMenuLvl2.scss"

function ProfileMenuLvl2({
    childrenList,
}) {
    const location = useLocation()

    const dispatch = useDispatch()

    return (
        <div className="list-part-profil radioList" key="arrLinks">
            {childrenList?.map((infos) => {
                const content = (
                    <CheckBlock
                        checked={infos.appRoute && matchPath(location.pathname, infos.appRoute)?.pathname === infos.appRoute}
                        name="profileMenuItem"
                        cls={infos.customCls}
                    >
                        <div className="checkblock-profil">
                            {infos.hasNotification && <Notifications />}
                            {infos.icon && <img className="icon" src={infos.icon} />}
                            <div className="label">{infos.label}</div>
                        </div>
                    </CheckBlock>
                )

                if (infos.isVisible) {
                    return (
                        infos.action ? (
                            <div
                                className={classNames({ [infos.parentClass]: infos?.parentClass })}
                                onClick={infos.action}
                                key={"profile-menu-" + infos.label}
                            >
                                {content}
                            </div>
                        ) : (
                            <NavLink
                                to={infos.appRoute}
                                key={"profile-menu-" + infos.label}
                                onClick={() => {
                                    dispatch(selectUserProfilMenu(false))
                                    dispatch(selectHamburgerMenu(false))
                                }}
                            >
                                {content}
                            </NavLink>
                        )
                    )
                }
            })}
        </div>
    )

}

ProfileMenuLvl2.propTypes = {
    childrenList: array,
}

export default ProfileMenuLvl2
