/* # Connecteurs endpoints API League */

import { _apiCall } from "../../../backend/axios"

export function confirmEmail(token) {
    return  _apiCall(
        "check/confirm-email",
        "PUT",
        {
            "Content-Type" : "application/json",
        },
        {
            token: token,
        },
    ).then(
        (res) => {
            return res
        },
    ).catch((err) => {
        return err
    })
}

export function sendConfirmEmail(id, value, changeCoordinatesToken, theme) {
    let data = {
        value: value,
    }

    if (changeCoordinatesToken) {
        data.changeCoordinatesToken = changeCoordinatesToken
    }
    return  _apiCall(
        "check/" + id + "/send-confirm-email",
        "PUT",
        {
            "Content-Type" : "application/json",
            activity: theme,
        },
        data,
    ).then(
        (res) => res,
    ).catch(
        (err) => {
            return err
        },
    )
}
