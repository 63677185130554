import classNames from "classnames"
import { array, bool, func, object, oneOfType } from "prop-types"

import CheckBlock from "../../../../../components/Forms/CheckBlock/CheckBlock"
import { TooltipLight } from "../../../../../components/tooltip/tooltipLight/tooltipLight.jsx"
import useThemeFeatures from "../../../../../hooks/useThemeFeatures"

export default function DiscountFilters({ selectedDiscount, setSelectedDiscount, availableDiscounts }) {
    const themeFeatures = useThemeFeatures()
    
    return (
        <div className="discountsWrapper">
            {availableDiscounts?.map(
                code => (
                    <CheckBlock
                        callback={
                            () => {
                                if (selectedDiscount && selectedDiscount.id === code.id) {
                                    setSelectedDiscount(null)
                                } else {
                                    setSelectedDiscount(code)
                                }
                            }
                        }
                        checked={selectedDiscount && selectedDiscount.id === code.id}
                        value={code.id}
                        key={code.id + code.label}
                        cls="discountCode"
                    >
                        {code.terms && (
                            <div className="infoBubble">
                                <TooltipLight
                                    position={"top"}
                                    id={"infoBubble-supp-discount-" + code.id}
                                    text={code.terms}
                                    centered
                                    dark
                                >
                                    <div id={"buttonInfoDiscount"}>
                                        <div className="infoBubble__icon">
                                            {themeFeatures([ "i", <img key="padelDiscountInfo"
                                                src="/assets/images/padel/icons/icons-info.svg"/> ])}
                                        </div>
                                    </div>

                                </TooltipLight>
                            </div>
                        )}
                        <span className={classNames("desc", { normalBreak: code.label === "Tarif - 26 ans" })}>
                            {code.label === "Tarif - 26 ans" ?
                                (
                                    <span>Tarif<br />
                                        <span className="whitespace-nowrap">- 26 ans</span>
                                    </span>
                                ) : code.label
                            }
                        </span>
                    </CheckBlock>
                ),
            )}
        </div>
    )
}

DiscountFilters.propTypes = {
    availableDiscounts: array,
    selectedDiscount: oneOfType([ bool, object ]),
    setSelectedDiscount: func,
}
