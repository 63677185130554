import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export default function useNotLogFooter() {
    const location = useLocation()
    const [ isNotLogFooter, setIsNotLogFooter ] = useState(checkPubRoute())

    function checkPubRoute() {
        return (
            location?.pathname === "/login"
            || location?.pathname === "/padel/login"
            || location?.pathname === "/signup"
            || location?.pathname === "/padel/signup"
            || location?.pathname === "/forgotPassword"
            || location?.pathname === "/padel/forgotPassword"
            || location?.pathname === "/reload/passwordReset"
        )
    }

    useEffect(
        () => {
            setIsNotLogFooter(checkPubRoute())
        }, [ location ],
    )

    return isNotLogFooter
}
