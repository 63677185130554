import useUrbanLanguage from "../../../../hooks/urbanLanguage/useUrbanLanguage"

import "./EmptySubscriptionPadel.scss"

export default function EmptySubscription() {
    const { tu } = useUrbanLanguage()
    return (
        <div className="noSubscription">
            <div className="noSubscriptionWrapper">
                <div className="title"><span>Abonnement</span></div>
                <div className="subtitle">OUPS !</div>
                <div className="descriptions">
                    <div className="desc">Tu n'as pas encore d'abonnement chez {tu("name")} !</div>
                    <div className="desc">Voici ce que tu manques :</div>
                    <ul>
                        <li>
                            <span className="field">Du confort :</span>
                            <span>ta piste réservée chaque semaine au créneau de ton choix</span>
                        </li>
                        <li>
                            <span className="field">De la liberté :</span>
                            <span>intègre des coupures quand tu ne peux pas jouer</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
