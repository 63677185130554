import { _apiCall } from "../../../backend/axios"

export function getMySupersubsMatchs(
    id,
    pagesize,
    offset = 0,
    status,
    periodStart,
    periodEnd,
    orderByAsc,
) {
    return _apiCall(
        `read/users/${id}/supersubs/responses?pagesize=${pagesize}&offset=${offset}&statuses=${status}&periodStart=${periodStart}&periodEnd=${periodEnd}&orderByAsc=${orderByAsc}`,
        "GET",
    )
}
