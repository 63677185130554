import { useEffect } from "react"

import { resetNativeHistoryToCurrentLocation } from "../../../utilities/helpers"
import TeamSteps from "../steps/TeamSteps"

function UrbanCup() {
    useEffect(() => {
        resetNativeHistoryToCurrentLocation(window.location.href)
    }, [])

    return <TeamSteps tunnelType={"CUP"}/>
}

export default UrbanCup
