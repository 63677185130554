import { bool, func, object } from "prop-types"
import { useState } from "react"

import useMediaPath from "../../../hooks/useMediaPath"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import ButtonCta from "../../Buttons/button/button-cta"
import { TextInput } from "../../Inputs/TextInput/TextInput.jsx"
import Modal from "../../Modal/Modal"

const AskEmail = ModalHandler.create(({ callback, visible, hide }) => {
    const [ targetEmail, setTargetEmail ] = useState("")
    const [ allowMailSend, setAllowMailSend ] = useState(false)
    const mediaPath = useMediaPath()
    function onUserValidation() {
        callback(targetEmail)
        hide()
    }

    function validateEmail(email) 
    {
        const re = /\S+@\S+\.\S+/
        return re.test(email)
    }

    const handleEmailChange = (value) => {
        setTargetEmail(value)
        setAllowMailSend(validateEmail(value))
    }

    return (
        <Modal
            isOpen={visible}
            onClose={() => {
                setTargetEmail("")
                hide()
            }}
            className={"little"}
        >
            <header>E-mail</header>
            <section className="invitationSection">
                <div className="emailVisual">
                    <img src={ mediaPath([ "/assets/icons/emailIcon.svg", "/assets/images/padel/icons/emailIcon.svg" ])} className="arobase" />
                </div>
                <label>Entre une adresse e-mail :</label>
                <section className={"invitationSection"} style={{ width: "100%" }}>
                    <TextInput
                        label="Adresse e-mail"
                        value={targetEmail}
                        onChange={handleEmailChange}
                        required
                        maxWidth={400}
                        fullWidth
                    />
                </section>
                <ButtonCta
                    isAlt={true}
                    disabled={!allowMailSend}
                    onClick={onUserValidation}
                    text="Envoyer"
                />
            </section>
        </Modal>
    )
})

AskEmail.propTypes = {
    callback: func,
    isOpen: bool,
    parentPopIn: object,
    setIsOpen: func,
    showParentCallback: func,
}

AskEmail.displayName = "AskEmail"

export default AskEmail
