import PropTypes from "prop-types"

import { communicationPackageFilters } from "../config.js"

import "./VisualsFilter.scss"

export const VisualsFilters = ({
    onFilterChange,
    value,
}) => {
    return (
        <div className={"cp-filters"}>
            {Object.values(communicationPackageFilters).map((filter) => (
                <button
                    className={`cp-filter ${value === filter.value ? "active" : ""}`}
                    key={filter.value}
                    onClick={() => onFilterChange(filter.value)}
                >
                    {filter.label}
                </button>
            ))}
        </div>
    )
}

VisualsFilters.propTypes = {
    onFilterChange: PropTypes.func,
    value: PropTypes.string,
}
