import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

import usePlural from "../../../hooks/usePlural.js"
import { useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"
import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import ButtonCta from "../../Buttons/button/button-cta"
import ProgressCircle from "../ProgressCircle/ProgressCircle"

import "./RankingSummary.scss"

export default function RankingSummary({ data }) {
    const { selectedLeagueTeam } = useLeagueStore()
    const navigate = useNavigate()

    return (
        <div className="ranking-summary">
            <div className="decoration" />
            {data.rank > 0 && (
                <>
                    {!data?.firstGamePlayed ? (
                        <>
                            <h2 className="ranking">Classement non défini</h2>
                            <div className="desc">La saison n'a pas encore commencé</div>
                        </>
                    ) : (
                        <>
                            <h2 className="ranking">{data.rank}{usePlural(data.rank, "ère place", "ème place")}</h2>
                            <div className="desc">au classement général</div>
                        </>
                    )}
                </>
            )}
            <div className="stats">
                <ProgressCircle
                    color="main"
                    fill={data.wins / data.played}
                    label={{
                        number: data.wins,
                        text: usePlural(data.wins, "victoire", "victoires"),
                    }}
                />
                <ProgressCircle
                    color="secondary"
                    fill={data.draws / data.played}
                    label={{
                        number: data.draws,
                        text: usePlural(data.draws, "nul", "nuls"),
                    }}
                />
                <ProgressCircle
                    color="tertiary"
                    fill={data.losses / data.played}
                    label={{
                        number: data.losses,
                        text: usePlural(data.losses, "défaite", "défaites"),
                    }}
                />
            </div>
            <ButtonCta text="Voir le classement" onClick={() => navigate(LeaguePath.toLeagueInfosRanking(selectedLeagueTeam?.id))} />
        </div>
    )
}

const DataType = PropTypes.shape({
    draws: PropTypes.number,
    firstGamePlayed: PropTypes.bool,
    gameSummary: PropTypes.object,
    losses: PropTypes.number,
    played: PropTypes.number,
    rank: PropTypes.number,
    wins: PropTypes.number,
})

RankingSummary.propTypes = {
    data: DataType,
}

