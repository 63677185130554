import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types"
import { components } from "react-select"

export const CustomMenu = (props) => {
    const { children } = props

    const menuVariants = {
        closed: {
            opacity: 0,
            y: -20,
        },
        open: {
            opacity: 1,
            y: 0,
        },
    }

    return (
        <AnimatePresence>
            <motion.div
                key={"menu"}
                variants={menuVariants}
                initial="closed"
                animate="open"
                transition={{ damping: 20, stiffness: 300, type: "spring" }}
                exit="closed"
            >
                <components.Menu
                    {...props}
                    className="select-input-menu-wrapper"
                >
                    {children}
                </components.Menu>
            </motion.div>
        </AnimatePresence>

    )
}

CustomMenu.propTypes = {
    children: PropTypes.node,
}
