import dayjs from "dayjs"

import { _apiCall } from "../../../backend/axios"
import { returnAnswerData } from "../../../backend/helpers.js"

export function getSuperSubRequests({
    centerId = null,
    date,
    date2 = date,
    pagesize = 50,
    offset = 0,
    orderByAsc = true,
    isToday = false,
    areaId = null,
}) {
    let periodStart, periodEnd
    const format = "YYYY-MM-DDTHH:mm:ss"
    let dateDebut = dayjs(date).format("YYYY-MM-DD")
    let dateFin = dayjs(date2).format("YYYY-MM-DD")

    if (isToday) {
        periodStart = dayjs().add(10, "minutes").format(format)
    } else {
        periodStart = dayjs(dateDebut).startOf("day").format(format)
    }
    periodEnd = dayjs(dateFin).endOf("day").format(format)

    return _apiCall(
        `read/supersubs/requests?periodStart=${periodStart}&periodEnd=${periodEnd}&centerId=${centerId}&offset=${offset}&pagesize=${pagesize}&orderByAsc=${orderByAsc}&areaId=${areaId}`,
        "GET",
    )
}

export function generateSubPaymentForm({
    requestId,
    returnUrl,
    supersubApplyMessage,
    withAlias,
    saveCard,
}) {
    return  _apiCall(
        "read/supersubs/" + requestId + "/responses",
        "POST",
        {
            "Content-Type": "application/json",
        },
        {
            returnUrl: returnUrl,
            saveCard,
            supersubApplyMessage: supersubApplyMessage,
            withAlias,
        },
    ).then(returnAnswerData)
}
