import { arrayOf, bool, func, node, oneOfType, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"

import APP_CONFIG from "../../../business/config"
import { validateEmail } from "../../../services/validation"
import ButtonCta from "../../Buttons/button/button-cta"
import { TextInput } from "../../Inputs/TextInput/TextInput.jsx"

function GatherEmail({
    initialValue,
    onSubmit,
    errorMessage,
    canDisplayError,
    needToChange,
}) {
    const [ isValidMail, setIsValidMail ] = useState(false)
    const [ newEmail, setNewEmail ] = useState(initialValue ? initialValue : "")

    const [ clickOnNext, setClickOnNext ] = useState(false)

    const recaptchaMailRef = useRef(null)

    /* ACTIONS */
    useEffect(
        () => {
            if (initialValue) {
                setIsValidMail(validateEmail(initialValue))
            }
        }, [ initialValue ],
    )

    async function emailAction() {
        setClickOnNext(true)
        const captchaToken = await recaptchaMailRef.current.executeAsync()
        recaptchaMailRef.current.reset()
        if (onSubmit) {
            onSubmit(newEmail, captchaToken)
        }
    }
    
    return (
        <React.Fragment>
            <TextInput
                label="Adresse e-mail"
                placeholder="Confirmer votre adresse e-mail"
                required
                type="email"
                inputMode="email"
                onChange={
                    (val) => {
                        setIsValidMail(validateEmail(val))
                        setNewEmail(val)
                    }
                }
                onEnter={emailAction}
                value={newEmail}
                error={clickOnNext && !isValidMail && canDisplayError || (canDisplayError && !!errorMessage) ? errorMessage : null}
            />

            <ButtonCta
                text="Suivant"
                isAlt={true}
                disabled={!isValidMail || (needToChange && (initialValue === newEmail))}
                onClick={emailAction}
            />
            <ReCAPTCHA
                ref={recaptchaMailRef}
                sitekey={APP_CONFIG.recapchaSiteKey}
                size="invisible"
            />
        </React.Fragment>
    )
}

GatherEmail.propTypes = {
    canDisplayError: bool,
    errorMessage: oneOfType([ string, node, arrayOf(node) ]),
    initialValue: string,
    needToChange: bool,
    onSubmit: func,
}

export default GatherEmail
