import "./HorizontalScrollBar.scss"

export function HorizontalScrollbar({ scrollSize }) {
    return (
        <div className="horizontal-scrollbar-track">
            <div
                className="horizontal-scrollbar-thumb"
                style={{ left: `${scrollSize.thumbLeft}%`, width: `${scrollSize.thumbWidth}%` }}
            ></div>
        </div>
    )
}

HorizontalScrollbar.propTypes = {
    scrollSize: {
        thumbLeft: 0,
        thumbWidth: 0,
    },
}
