import { useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import { getUser } from "../../../../../globalAPI/api"
import useUrbanLanguage from "../../../../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../../../../hooks/useMediaPath"
import usePath from "../../../../../routes/services/usePath"
import { formArgs, handleSlotModal } from "../services/function"
import { useBookingStore } from "../services/store"

import "./NoListingContent.scss"

export const NoListingContent = () => {
    const navigate = useNavigate()
    const userLoaded = useSelector(state => state.userLoaded) ?? []
    const { watch } = useFormContext()

    const path = usePath()
    const {
        bookableDisplayLength,
        isLoading,
        error,
        selectedSlot,
        bookableListingLength,
        setIsLoading,
        bookingInfosData,
    } = useBookingStore()

    const mediaPath = useMediaPath()
    const { tu } = useUrbanLanguage()

    const [ selectedDate, hourFilter, typeFilter, centerId, selectedActivityId, timeFilter ] = watch([
        formArgs.DATE,
        formArgs.HOUR,
        formArgs.TYPE,
        formArgs.CENTER_ID,
        formArgs.ACTIVITY_ID,
        formArgs.TIME_FILTER,
    ])

    const handleClick = () => {
        handleSlotModal({
            activityId: selectedActivityId,
            bookingInfosData: bookingInfosData,
            centerId: centerId,
            fromAlert: true,
            hourFilter: hourFilter,
            refreshUser: refreshUser,
            selectedDate: selectedDate,
            selectedSlot: selectedSlot,
            timeFilter: timeFilter,
            typeFilter: typeFilter,
            userLoaded: userLoaded,
        })
    }

    const refreshUser = () => {
        setIsLoading(true)
        getUser(true).finally(() => {
            setIsLoading(false)
            navigate(path("/profil/creneauxFavoris"))
        })
    }

    const shouldRenderContent = centerId && selectedDate && selectedActivityId && (
        (bookableListingLength === 0 && !isLoading && !error) ||
        (!isLoading && error) ||
        (!isLoading && !error && bookableDisplayLength === 0 && bookableListingLength !== 0)
    )

    if (!shouldRenderContent) {
        return null
    }

    return (
        <>
            {centerId && selectedDate && selectedActivityId && (
                <div  className={"noListingContainer"}>
                    {bookableListingLength === 0 && !isLoading && !error &&  (
                        <div className="noMatch">
                            <img src={mediaPath([ "/assets/images/terrain-barre.svg", "/assets/images/padel/icons/icons-terrain-barre.svg" ])} alt=""/>
                            <p>{tu("booking.noListingField")} n'est disponible.</p>
                        </div>
                    )}
                    { !isLoading && error && (
                        <div className="error">
                            <img alt="" src={mediaPath([ "/assets/images/terrain-barre.svg", "/assets/images/padel/icons/icons-terrain-barre.svg" ])} className="noMatch" />
                            <p>
                                {error === "expiredPrereservation" ?
                                    "Désolés, ta préreservation a expirée."
                                    :
                                    error
                                }
                            </p>
                        </div>
                    )}
                    {!isLoading && !error && bookableDisplayLength === 0 && bookableListingLength !== 0 && (
                        <div className="noMatch">
                            <p>{tu("booking.filter.noResult")}</p>
                            {(!selectedSlot || (selectedSlot && !selectedSlot?.notificationSource?.length > 0)) && (
                                <ButtonCta
                                    hoverChangeIcon={mediaPath([ "/assets/icons/favoriteSlot/bell-check.svg", "/assets/icons/favoriteSlot/padel/bell-check-green.svg" ])}
                                    isAlt
                                    text={"M'alerter"}
                                    icon={mediaPath([ "/assets/icons/favoriteSlot/bell-check-orange.svg", "/assets/icons/favoriteSlot/padel/bell-check.svg" ])}
                                    onClick={() => handleClick()}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
