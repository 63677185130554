import PropTypes from "prop-types"

import { TeamHeading } from "../../parts/TeamHeading/TeamHeading.jsx"
import { Title } from "../../parts/Title/Title.jsx"
import { VisualPlayerPicture } from "../../parts/VisualPlayerPicture/VisualPlayerPicture.jsx"
import { BaseLayout } from "../Base.jsx"

import "./TeamRoster.scss"

export const TeamRoster = (props) => {
    return (
        <BaseLayout decorator="left" withGradient>
            <TeamHeading data={props?.data} big ctx={props.ctx} />
            <VisualPlayerPicture
                picture={props?.data?.leagueTeam?.pictureUrl || "/assets/images/business/communicationPack/mock/team.png"}
                width={1107}
                zIndex={1}
                coordinates={{
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top:0,
                }}
            />
            <div className={"teamRoster"}>
                <Title title="SUIVEZ LEUR PARCOURS SUR" size={[ 45 ]} cls={"primary"}/>
                <img src="/assets/images/business/communicationPack/linkedIn-w.svg"/>
            </div>
        </BaseLayout>
    )
}

TeamRoster.propTypes = {
    ctx: PropTypes.object,
    data: PropTypes.object,
}
