import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { bool, func, object, oneOfType } from "prop-types"
import { useNavigate } from "react-router-dom"

import usePlural from "../../../hooks/usePlural"
import { navigateToPlayerPage } from "../../../pages/league/service/service.js"
import { useAssets } from "../../../providers/AssetsProvider.jsx"
import ButtonCta from "../../Buttons/button/button-cta"
import { useTheme } from "../../ThemeContext/ThemeContext.jsx"
import { TeamPicture } from "../TeamPicture/TeamPicture.jsx"

import "./PlayerStatusManagement.scss"

export default function PlayerStatusManagement({ onClick, player, selected, noBottom, changePlayerStatus, allowEdit,isCurrentVote, mvpVotesDisplay }) {
    const navigate = useNavigate()
    const { m } = useAssets()
    const theme = useTheme()
    const picture = theme === "theme-business" ? player?.businessLeaguePictureUrl || player?.pictureUrl : player?.pictureUrl

    const playerHasStatus = (status) => {
        return player.status === status
    }
    
    const statusButtons = [
        { statusId: 1, text: "présent" },
        { statusId: 0, text: "en attente" },
        { statusId: 2, text: "absent" },
    ]

    const starScaleAnimation = {
        animate: { scale: 1.35 },
        exit: { scale: 1 },
        initial: { scale: 1 },
        transition: { duration: 0.35, ease: "easeInOut", repeat: Infinity, repeatType: "reverse" },
    }

    return (
        <div
            className="playerStatusManagement"
            data-testid="playerStatusManagement"
            onClick={() => onClick?.(selected ? null : player)}
        >
            <div className="topPart">
                {(player?.currentNbVotes || player?.currentNbVotes === 0) && mvpVotesDisplay && (
                    <div className="mvpVotes">
                        <span className="votes">{player?.currentNbVotes}</span>
                        <span className="desc">{usePlural(player?.currentNbVotes, "VOTE", "VOTES")}</span>
                    </div>
                )}
                {noBottom && (
                    <motion.img
                        alt="star"
                        className="selectedStar"
                        src={selected ? m("icons.league.star") : m("icons.league.starStroke")}
                        // src={selected ? "/assets/icons/star--orange.svg" : "/assets/icons/starStroke.svg"}
                        {...((selected && !isCurrentVote) && starScaleAnimation)}
                    />
                )}
                <div
                    className="member"
                    data-testid="goToPlayerPage"
                    onClick={noBottom ? undefined : e => {
                        e?.stopPropagation()
                        navigateToPlayerPage(navigate, player?.championshipPlayerId, null, player?.championshipTeamId)
                    }}
                >
                    <TeamPicture image={picture} size={40} placeHolder={"/assets/images/user-placeholder-b.svg"} />
                    <div className="name">{(player?.firstname ?? "") + (player?.lastname ? " " +  player.lastname.charAt(0) + "." : "")}</div>
                </div>
                {onClick && !noBottom && allowEdit &&  (
                    <div className={classNames("three-dots", { active: selected })}>
                        <div className="dot"></div>
                    </div>
                )}
            </div>
            <AnimatePresence mode="wait" presenceAffectsLayout>
                { selected  && !noBottom && allowEdit && (
                    <motion.div layout className="bottomPart" {...bottomAnimation}>
                        {statusButtons.map(({ statusId, text }) => (
                            <ButtonCta
                                key={statusId}
                                className="presence"
                                text={text}
                                onClick={e => {
                                    e.stopPropagation()
                                    if (playerHasStatus(statusId)) {
                                        return
                                    }
                                    changePlayerStatus({ playerId: player?.id, status: statusId })
                                }}
                                isCta2Alt={!playerHasStatus(statusId)}
                            />
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

const bottomAnimation = {
    animate: { height: "auto", marginTop: 16, opacity: 1, y: 0 },
    exit: { height: 0, marginTop: 0, opacity: 0, y: -20 },
    initial: { height: 0, marginTop: 0, opacity: 0, y: -20 },
    transition: { duration: .25, ease: "easeInOut" },
}

PlayerStatusManagement.propTypes = {
    allowEdit: bool,
    changePlayerStatus: func,
    isCurrentVote: bool,
    mvpVotesDisplay: bool,
    noBottom: bool,
    onClick: oneOfType([ bool, func ]),
    player: object,
    selected: bool,
}
