import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ButtonCta from "../../../../components/Buttons/button/button-cta"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext"
import usePath from "../../../../routes/services/usePath"

import "./HomeProfil.scss"

export default function HomeProfil() {
    const userLoaded = useSelector(state => state.userLoaded)
    const navigate = useNavigate()
    const path = usePath()
    const theme = useTheme()

    const [ hover, setHover ] = useState(false)

    function goToProfile() {
        navigate(path("/profil/coordonnees"))
    }

    function addFriend() {
        navigate(path("/profil/coequipiers/?add=true"))
    }

    return (
        <div className="home-profil">
            <img className="profile-picture" src={userLoaded?.pictureUrl ?? "/assets/images/user-placeholder-gray.png"}  />
            <div className="profile-actions">
                <ButtonCta
                    text="mon profil"
                    onClick={goToProfile}
                    isAlt
                />
                <div className="addFriend"
                    onClick={addFriend}
                >
                    {theme === "theme-soccer" && <img className="add-friend-picto" src="/assets/icons/pictoAddFriend.svg" />}
                    <span className="addSpan">Ajouter&nbsp;un ami
                        {theme === "theme-soccer" && <span className="arrowRightSpan"></span>}
                        {theme === "theme-padel" && (
                            <img
                                src={hover ? "/assets/images/padel/icons/icon-add-black.svg" : "/assets/images/padel/icons/icon-add-green.svg"}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            />
                        )}
                    </span>
                </div>
            </div>
        </div>
    )
}
