import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"

import useGetTeamRegistration from "./useGetTeamRegistration"
import useThemeHeader from "../../../hooks/useThemeHeader"
import { setFormEntry } from "../../../store/forms/actions"
import { goToStep } from "../../../store/stepsManagement/actions"

function useRestoreTeamTunnel(tunnelType, params, subId, initialStep, advanceStep) {
    const dispatch = useDispatch()
    const themeHeader = useThemeHeader()
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ , setSearchParams ] = useSearchParams()
    let stepIndex = 0

    const getTeamRegistration = useGetTeamRegistration(tunnelType)

    useEffect(
        () => {
            setTimeout(
                () => {
                    dispatch(
                        goToStep(
                            initialStep ?
                                initialStep
                                :
                                stepIndex,
                        ),
                    )
                }, 200,
            )
            if (params?.id || subId) {
                const isPreloader = document.getElementsByClassName("fixed-preloader") && document.getElementsByClassName("fixed-preloader").length > 0
                if (!isPreloader) {
                    setAjaxLoading(true)
                }
                getTeamRegistration(params?.id || subId, themeHeader).then(
                    async (res) => {
                        const registrationFromUrl = res?.data?.data
                        if (registrationFromUrl?.statusValue !== -1 && registrationFromUrl?.statusValue !== -2 && registrationFromUrl?.statusValue !== 3) {
                            await dispatch(
                                setFormEntry(
                                    "TournamentRegistration",
                                    registrationFromUrl,
                                ),
                            )
                            if (advanceStep) {
                                advanceStep(registrationFromUrl)
                            }
                        } else {
                            setSearchParams({})
                        }
                        
                        setAjaxLoading(false)
                    },
                )
            }
        },[],
    )

    return ajaxLoading
}

export default useRestoreTeamTunnel
