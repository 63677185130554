import html2pdf from "html2pdf.js"
import { useRef } from "react"

import { convertAllImagesToBase64 } from "../../../utilities/helpers.js"

export const pdfGenerate = (element, name) => {
    const clonedElement = element.cloneNode(true)

    clonedElement.style.display = "block"
    clonedElement.classList.add("html2pdf__item")

    const options = {
        dpi: 250,
        letterRendering: true,
        scale: 1.8,
        scrollY: 0,
    }

    if (/^http:\/\/localhost:\d+$/.test(import.meta.env.VITE_URL_BASE)) {
        options.proxy = "/imageProxy"
    } else {
        options.useCORS = true
        options.allowTaint = true
        options.proxy = "/proxy.php"
        options.onclone =  (cloned) => convertAllImagesToBase64("/proxy.php", cloned)
    }

    return new Promise((resolve, reject) => {
        preloadElementImages(element).then(() => {
            html2pdf()
                .set({
                    filename: name ?? "document.jpeg",
                    html2canvas: options,
                    image: { quality: 0.85, type: "jpeg" },
                    jsPDF: { format: [ 1920,1080 ], orientation: "landscape", unit: "px" },
                    margin: 0,
                    pagebreak: { mode: [ "avoid-all", "css", "legacy" ] },
                    useCORS: true,
                })
                .from(clonedElement)
                .toImg()
                .outputImg("datauristring")
                .then(r => {
                    const link = document.createElement("a")
                    link.href = r
                    link.download = name
                    link.click()
                    resolve()
                })
                .catch(reject)
        })
    })
}

const preloadElementImages = async element => {
    const preloadImage = src => new Promise(resolve => {
        const image = new Image()
        image.onload = resolve
        image.onerror = resolve
        image.src = src
    })

    await Promise.all(Array.from(element.querySelectorAll("img")).map(x => preloadImage(x.src)))
}

export const usePdfExporter = () => {
    const pdfRef = useRef(null)

    const handlePdf = async name => {
        if (pdfRef.current) {
            await pdfGenerate(pdfRef.current, name)
        }
    }

    return [ pdfRef, handlePdf ]
}

