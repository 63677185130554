/* eslint-disable no-irregular-whitespace */
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { createSupersubRequest, modifySupersubRequest } from "./api"
import ButtonCta from "../../../components/Buttons/button/button-cta"
import CheckBtn from "../../../components/Forms/CheckBtn"
import TextArea from "../../../components/Forms/textArea/TextArea"
import { SelectInput } from "../../../components/Inputs/Select/SelectInput.jsx"
import LocationInfoBar from "../../../components/LocationInfoBar/LocationInfoBar"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import Step from "../../../components/stepsManagement/Step"
import StepsManager from "../../../components/stepsManagement/StepManager"
import { completeSuperSubRequest } from "../../../components/SuperSub/SelectSuperSub/api"
import LevelStars from "../../../components/SuperSub/SuperSubRequest/LevelStars"
import Preloader from "../../../entries/main/../../components/loaders/preloader/preloader"
import { getLocation } from "../../../globalAPI/api"
import supersubLevels from "../../../model/enums/superSubLevels"
import { LocationPaths } from "../../../routes/ApplicationPaths/locationPaths.js"
import usePath from "../../../routes/services/usePath"
import { clearAllForms } from "../../../store/forms/actions"
import { useLocationStore } from "../../reservation/store/store"

import "./SearchSuperSub.scss"

function SearchSuperSub() {
    const params = useParams()
    const navigate = useNavigate()
    const locationId = params.locationId
    const path = usePath()
    const { currentLocation, setCurrentLocation } = useLocationStore()

    const [ reservation, setReservation ] = useState({})
    const [ askedPlayers, setAskedPlayers ] = useState(undefined)
    const [ confirmedPlayers, setConfirmedPlayers ] = useState(0)
    const [ amountDue, setAmountDue ] = useState(0)
    const [ searchedLevel, setSearchedLevel ] = useState(null)
    const [ isFree, setIsFree ] = useState(false)
    const [ description, setDescription ] = useState("")
    const [ isModification, setIsModification ] = useState(false)
    const [ requestId, setRequestId ] = useState(-1)
    const [ supersubPrice, setSupersubPrice ] = useState(0)

    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const step = useRef(null)

    const [ nbSubsOptions, setNbSubsOptions ] = useState([])

    const dispatch = useDispatch()

    useEffect(
        () => {
            if (locationId) {
                setAjaxLoading(true)
                getLocation(locationId).then(
                    async (res) => {
                        setSupersubPrice(res.supersubPlayerPrice)
                        setReservation(res)
                        res.supersubRequests.forEach(
                            (sReq) => {
                                const isEditableRequest = sReq.status === 0 &&
                                sReq.confirmedPlayers < sReq.askedPlayers
                                if (
                                    isEditableRequest
                                ) {
                                    setRequestId(sReq.id)
                                    setIsModification(true)
                                    setAskedPlayers(sReq.askedPlayers)
                                    setConfirmedPlayers(sReq.confirmedPlayers)
                                    setDescription(sReq.description)
                                    setIsFree(sReq.isFree)
                                    setSearchedLevel(sReq.level)
                                }
                            },
                        )
                        setAjaxLoading(false)
                    },
                )
            }
        }, [],
    )

    useEffect(
        () => {
            let newSubOptions = []
            if (isModification) {
                for (
                    var i = reservation && reservation.supersubRequests[reservation.supersubRequests.length - 1].confirmedPlayers;
                    i < (4 - reservation.supersubConfirmedPlayers + confirmedPlayers);
                    i++
                ) {
                    newSubOptions.push({
                        label: i,
                        value: i,
                    })
                }
            } else {
                for (
                    var j = 1;
                    j < (4 - reservation.supersubConfirmedPlayers);
                    j++
                ) {
                    newSubOptions.push({
                        label: j,
                        value: j,
                    })
                }
            }
            setNbSubsOptions(newSubOptions)
            setAmountDue(reservation?.amountDue)
            if (reservation?.amountDue === 0) {setIsFree(true)}
        }, [ reservation, confirmedPlayers ],
    )

    const help = <PhoneCallHelp tunnelType="SUPERSUB" />

    function changeLevel(e) {
        setSearchedLevel(e.target.value)
    }

    function changeIsFree(e) {
        setIsFree(e.target.checked)
    }

    function stopLoadingAndDisplayConfirmation() {
        setAjaxLoading(false)
        const commandProps = {
            confirmedSubscription: reservation,
            isCanceled: true,
            message: "supersubCreationConfirm",
            subtitle: `Nous te confirmons${isModification ? " la modification" : " l'enregistrement"} de ta recherche de SuperSub.`,
            success: true,
            title: "Recherche " + (isModification ? "modifiée" : "publiée"),
            tunnelType: "SUPERSUB",
        }
        navigate(path("/confirmcommand"), { state: commandProps })
    }

    function handleReqRes(res) {
        const data = res.data.data
        if (data.askedPlayers === data.confirmedPlayers) {
            completeSuperSubRequest(data.id).then(stopLoadingAndDisplayConfirmation)
        } else {
            stopLoadingAndDisplayConfirmation()
        }
        setCurrentLocation({ ...currentLocation, ...data.reservation, supersubRequests: [ ...currentLocation.supersubRequests, data ] })
    }

    function createRequest() {
        setAjaxLoading(true)
        if (!isModification) {
            createSupersubRequest(
                locationId,
                askedPlayers,
                searchedLevel,
                description,
                isFree,
            ).then(handleReqRes)
        } else {
            modifySupersubRequest(
                requestId,
                askedPlayers,
                searchedLevel,
                description,
                isFree,
            ).then(handleReqRes)
        }
    }

    return (
        <StepsManager hideProgressPar={true}>
            <Step
                title={
                    isModification ?
                        "Modifier ma recherche de supersub"
                        :
                        "Rechercher un supersub"
                }
                help={ help }
                helpId="supersubHelp"
                className="supersub"
                onReturn={
                    () => {
                        dispatch(clearAllForms())
                        navigate(path(LocationPaths.toReservationPlayers(locationId)))
                    }
                }

                returnText="Précedent"
                ref={step}
                otherId="last"
            >
                {ajaxLoading &&
                    (<Preloader
                        fixed={true}
                    />)
                }
                <div className="c-row justify-center">
                    <div className="c-col c-col--10">
                        <LocationInfoBar
                            location={reservation}
                        />
                    </div>
                    <div className="c-col c-col--8 c-col--sm--10 formHeader">
                        <div className="formHeader__title">
                            MA RECHERCHE
                        </div>
                    </div>
                    <div className="c-col c-col--8 c-col--sm--10">
                        <div className="firstPart">
                            <SelectInput
                                options={nbSubsOptions}
                                id='askedPlayers'
                                label={"Nombre de supersubs"}
                                value={askedPlayers}
                                onChange={(e) => setAskedPlayers(e.target.value)}
                            />

                            <section className="o-searchedLevel">
                                <label className="titleLabel">
                                    Niveau recherché :<span className='important'>*</span>
                                </label>

                                <div className="o-searchedLevel__inputs">
                                    {Object.keys(supersubLevels).map(
                                        (key, index) => {
                                            const txtValue = supersubLevels[key]

                                            return (
                                                <CheckBtn
                                                    id={txtValue}
                                                    name="level"
                                                    onChange={changeLevel}
                                                    round={true}
                                                    checked={
                                                        parseInt(searchedLevel) === parseInt(key)
                                                    }
                                                    value={key}
                                                    label={
                                                        (<>
                                                            <div className="stars">
                                                                <LevelStars
                                                                    level={key}
                                                                />
                                                            </div>
                                                            {txtValue}
                                                        </>)
                                                    }
                                                    key={"ckbt-" + index}
                                                />
                                            )
                                        },
                                    )}
                                </div>
                            </section>
                        </div>

                        <CheckBtn
                            id="isFree"
                            type="checkbox"
                            value="isFree"
                            onChange={
                                amountDue === 0 ?
                                    undefined
                                    :
                                    changeIsFree
                            }
                            checked={amountDue === 0 ? true : isFree}
                            disabled={ isModification || amountDue === 0}
                            label={
                                (<>
                                    Offrir la place au SuperSub ({
                                        amountDue === 0 ?
                                            "Déjà payée"
                                            :
                                            (
                                                amountDue <= supersubPrice ?
                                                    amountDue
                                                    :
                                                    supersubPrice
                                            ) + "€"
                                    })
                                    {amountDue !== 0 &&
                                        (<small>
                                            Le paiement se fera au moment de valider ton SuperSub
                                        </small>)
                                    }
                                </>)
                            }
                        />

                        <TextArea
                            isEmpty={description?.length === 0}
                            isRequired={true}
                            labelText="Message"
                            maxLength="500"
                            onChange={setDescription}
                            value={description}
                            placeholder={"Match entre amis, niveau intermédiaire, style de jeu : tiki-taka... ou presque :) \nRejoins la team, et on t'offre la tournée après le match."}
                        />
                        <ButtonCta
                            text="Valider"
                            isAlt={true}
                            disabled={
                                description === "" ||
                                askedPlayers === "" ||
                                askedPlayers === undefined ||
                                searchedLevel === null
                            }
                            onClick={createRequest}
                        />
                    </div>
                </div>
            </Step>
        </StepsManager>
    )
}

SearchSuperSub.propTypes = {
    amountDue: PropTypes.number,
    locationId: PropTypes.number,
    supersubPlayerPrice: PropTypes.number,
}

export default SearchSuperSub
