import classNames from "classnames"
import { array, arrayOf, bool, func, node, oneOfType, string } from "prop-types"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { useSelector } from "react-redux"

import useCookies from "../../../hooks/useCookies"
import ProfilCardWrapper from "../../../pages/profil/components/ProfilCardWrapper/ProfilCardWrapper"
import alphabeticalPlayerSort from "../../../services/Sorting/alphabeticalPlayerSort"
import UserAgentHandler from "../../../services/UserAgentHandler"
import ButtonCta from "../../Buttons/button/button-cta"
import PageHeader from "../../layout/pageHeader/PageHeader"
import PlayerLine from "../../reservation/PlayerLine/PlayerLine"

import "./selectUserFriends.scss"

const SelectUserFriends = forwardRef(
    (
        {
            initialSelectedFriends = [],
            emptyText,
            callback,
            closePopin,
            noHeader,
            customHeader,
            onlyOne,
            resetAfterCallback,
            setAllFriendsInLocation,
            customAddLabel,
            emptyInListing,
        },
        ref,
    ) => {

        const [ selectedFriendsId, setSelectedFriendsId ] = useState(initialSelectedFriends)

        const { isAppV2 } = useCookies()

        function toggleSelectedFriend(friendId) {
            /* istanbul ignore if */
            if (onlyOne && /* istanbul ignore next */!selectedFriendsId?.includes(friendId)) {return setSelectedFriendsId([ friendId ])}
            let newSelectedFriends = [ ...selectedFriendsId ]
            if (newSelectedFriends.includes(friendId)) {
                newSelectedFriends = newSelectedFriends.filter(x => x !== friendId)
            } else {
                newSelectedFriends.push(friendId)
            }
            setSelectedFriendsId(newSelectedFriends)
        }

        const userFriends = useSelector(state => state.userFriends)
        const filteredTeammates = userFriends ?
            userFriends.filter(
                user => (initialSelectedFriends && initialSelectedFriends.includes(user.ozmoSystemId) === false),
            ).sort(alphabeticalPlayerSort)
            :
            []

        const emptyList = filteredTeammates.length === 0

        useEffect(
            () => {
                if (userFriends) {
                    // On retire les invités qui ne font pas parti des amis
                    setSelectedFriendsId(
                        selectedFriendsId.filter(
                            friend => userFriends.includes(friend.ozmoSystemId),
                        ),
                    )
                }
            }, [ userFriends ],
        )

        useEffect(
            () => {
                if (setAllFriendsInLocation) {
                    setAllFriendsInLocation(filteredTeammates?.length === selectedFriendsId?.length)
                }
            }, [ filteredTeammates, selectedFriendsId ],
        )

        useImperativeHandle(
            ref,
            () => ({
                empty() {
                    setSelectedFriendsId([])
                },

                getSelection() {
                    return selectedFriendsId
                },
                selectAll() {
                    setSelectedFriendsId(filteredTeammates?.length ? filteredTeammates.map(friend => friend.ozmoSystemId) : /* istanbul ignore next */[])
                },
            }),
        )

        function onClick() {
            /* istanbul ignore else */
            if (callback) {
                /* istanbul ignore next */
                callback(onlyOne ? userFriends.find(friend => friend.ozmoSystemId === selectedFriendsId[0]) : selectedFriendsId)
                /* istanbul ignore else */
                if (resetAfterCallback) {
                    setSelectedFriendsId([])
                }
            }
        }

        return (
            <div className="locationFriendsInvite">
                {!noHeader && !customHeader && (
                    <PageHeader
                        title={"Joueurs"}
                        goBack={false}
                        customCls={"static friendsTitle"}
                    />
                )}
                { emptyList && !emptyInListing ? (
                    <div className="noFriends">
                        {customHeader}
                        <p>{ emptyText }</p>
                        { closePopin && (
                            <ButtonCta
                                isAlt={true}
                                text="Retour"
                                onClick={/* istanbul ignore next */() => {
                                    if (ref?.current) {
                                        closePopin()
                                    }
                                }}
                                className="inviteFriends"
                            />
                        )}
                    </div>
                ) : (
                    <>
                        <div className="friendsList">
                            <div className="friendsWrapper">
                                {customHeader}
                                {!emptyList && filteredTeammates.map(
                                    (mate) => (
                                        <PlayerLine
                                            key={mate.ozmoSystemId + mate.username}
                                            invite={mate}
                                            isProfil
                                            isSelected={selectedFriendsId.includes(mate.ozmoSystemId)}
                                            selectPlayer={toggleSelectedFriend}
                                            isLocationInvit={true}
                                            customCls={selectedFriendsId.includes(mate.ozmoSystemId) ? "selected" : ""}
                                        />
                                    ),
                                )}
                            </div>
                        </div>
                        {emptyList && (
                            <ProfilCardWrapper customCls={"friendListMessage"}>
                                <div className="emtyInlist">{ emptyText }</div>
                                { closePopin && (
                                    <ButtonCta
                                        isAlt
                                        text="Retour"
                                        onClick={/* istanbul ignore next */() => {
                                            if (ref?.current) {
                                                closePopin()
                                            }
                                        }}
                                        className="inviteFriends"
                                    />
                                )}
                            </ProfilCardWrapper>
                        )}
                        {!emptyList && emptyInListing && (
                            <div className={classNames("button-holder", {
                                hasMobileMenu: (UserAgentHandler.isIOS() || UserAgentHandler.isAndroid()) && !isAppV2,
                            })}>
                                <ButtonCta 
                                    text={customAddLabel ?? "Ajouter"}
                                    disabled = {selectedFriendsId.length === 0}
                                    onClick = {onClick}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    },
)

SelectUserFriends.propTypes = {
    callback: func,
    closePopin: oneOfType([ func, bool ]),
    customAddLabel: oneOfType([ bool, string ]),
    customHeader: oneOfType([ bool, node ]),
    emptyInListing: bool,
    emptyText: oneOfType([ string, node, arrayOf(node) ]),
    initialSelectedFriends: array,
    noHeader: bool,
    onlyOne: bool,
    resetAfterCallback: bool,
    setAllFriendsInLocation: oneOfType([ bool, func ]),
}

SelectUserFriends.displayName = "selectUserFriends"

export default SelectUserFriends
