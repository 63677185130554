const getPlayersSelect = location => {
    const tenPlayers = [ 0, 1, 8, 10 ].includes(location.resourceType)
    const fourteenPlayers = location.resourceType === 13
    function generateArrayBetweenNumbers(start, end) {
        const result = []
        for (let i = start; i <= end; i++) {
            result.push([ i, i ])
        }
        return result
    }
    if (tenPlayers) {
        return generateArrayBetweenNumbers(8, 12)
    } else if (fourteenPlayers) {
        return generateArrayBetweenNumbers(12, 16)
    } else {
        return []
    }
}

export default getPlayersSelect
