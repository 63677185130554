import { AnimatePresence } from "framer-motion"
import PropTypes from "prop-types"
import { components } from "react-select"

export const CustomValueContainer = props => {
    const { children } = props

    return (
        <components.ValueContainer
            {...props}
            innerProps={{
                ...props.innerProps,
                className: "select-input-value-container",
            }}
            layout
        >
            {props?.isMulti ? (
                <AnimatePresence mode={"popLayout"}>
                    {children}
                </AnimatePresence>
            ) : (
                children
            )}
        </components.ValueContainer>
    )
}

CustomValueContainer.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    isMulti: PropTypes.bool,
}
