import PropTypes from "prop-types"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { SlotStep1 } from "./Form/SlotStep1"
import { SlotStep2 } from "./Form/SlotStep2"
import { SlotStep3 } from "./Form/SlotStep3"
import ButtonCta from "../../../../components/Buttons/button/button-cta"
import { hourOptions } from "../../../../components/HoursList/HoursList"
import Preloader from "../../../../components/loaders/preloader/preloader"
import Modal from "../../../../components/Modal/Modal"
import { useIsWebview } from "../../../../components/ThemeContext/ThemeContext.jsx"
import useDeepCompareEffect from "../../../../hooks/useDeepCompareEffect.js"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import ModalHandler from "../../../../providers/Modal/ModalHandler"
import { createFavoriteSlot, editFavoriteSlot } from "../api"
import { getValues } from "../functions"

import "./SlotModal.scss"

const globalActivity = 3

export const SlotModal = ModalHandler.create(({
    onSlotCreated ,
    bookables = [],
    user = undefined,
    slot = null,
    visible, 
    hide,
    preSelectionValues = null,
}) => {
    const [ slotError, setSlotError ] = useState(null)
    const activity = useThemeHeader()
    const isWebView = useIsWebview()

    const {
        formState: { isSubmitting, isDirty, isValid },
        handleSubmit,
        ...form
    } = useForm({
        defaultValues: getFormDefaultValues(slot),
        mode: "onSubmit",
    })

    const watchForm = form.watch()

    useDeepCompareEffect(() => {
        if (Object.keys(watchForm).length > 0) {
            setSlotError(null)
        }
    }, [ watchForm ])

    const onSubmit = (data) => {
        if (slot) {
            return updateSlot(slot.id, {
                ...data,
                status: slot.status,
            })
        } else {
            return createSlot(data)
        }
    }

    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className={"slot-modal full-screen"}
        >
            <FormProvider {...form}>
                <div className={"slot-modal-container"}>
                    <div className={"slot-modal-heading"}>
                        <h1>{slot ? "Modifier un créneau favori" : "Ajouter un créneau favori"}</h1>
                    </div>
                    <div className={"slot-modal-content"}>
                        <form>
                            <SlotStep1 slot={slot} fromBooking={(!preSelectionValues?.hour) ? false : !!preSelectionValues}/>
                            <SlotStep2 bookables={bookables} user={user}/>
                            <SlotStep3/>
                        </form>
                    </div>
                    <div className={"slot-modal-action"}>
                        {slotError && (
                            <div className={"slot-modal-error"}>
                                {slotError}
                            </div>
                        )}
                        {!isSubmitting ? (
                            <ButtonCta text={slot ? "Modifier le créneau" : "Ajoute ton créneau"} onClick={handleSubmit(onSubmit)} disabled={
                                preSelectionValues ? (
                                    !isValid
                                ) : (
                                    !isValid || !isDirty
                                )
                            }/>
                        ) : (
                            <Preloader small/>
                        )}
                    </div>
                </div>
            </FormProvider>
        </Modal>
    )

    function getFormDefaultValues(slot) {
        if (slot) {
            return {
                center: slot.centerId,
                day: slot.day.toString(),
                hour: hourOptions.find(option => option.value === slot.start),
                notifications: [
                    slot.notificationSource.includes(0),
                    slot.notificationSource.includes(1),
                    slot.notificationSource.includes(2),
                ],
                type: slot.resourceTypes,
            }
        } else {
            return {
                center: preSelectionValues?.center ?? getDefaultCenterId(),
                day: preSelectionValues?.day ?? "1",
                hour: preSelectionValues?.hour ?? hourOptions[9],
                notifications: [],
                type: preSelectionValues?.type ?? null,
            }
        }
    }

    function getDefaultCenterId() {
        const isCenterAvailable = [ globalActivity, activity ].includes(
            bookables.find(bookable => bookable[0] === user?.centerId)?.commercialActivities,
        )

        if (user?.centerId && isCenterAvailable) {
            return user.centerId.toString()
        }
        return bookables.find(bookable => [ globalActivity, activity ].includes(bookable.commercialActivities))?.[0].toString()
    }

    function createSlot(data) {
        return createFavoriteSlot(getValues(data, user)).then((res) => {
            handleResponse(res)
        })
    }

    function updateSlot(id, data) {
        if (slot.notificationSource.includes(0) && !isWebView) {
            data.notifications[0] = true
        }

        return editFavoriteSlot(id, getValues(data, user)).then((res) => {
            handleResponse(res)
        })
    }

    function handleResponse(res) {
        if (res.data?.data?.id) {
            hide()
            onSlotCreated?.()
        } else if (res?.response?.data?.error || res?.data?.error) {
            setSlotError(res?.response?.data?.error ?? res?.data?.error)
        } else if (res.data?.data?.["Message"]) {
            setSlotError(res.data?.data?.["Message"])
        } else {
            setSlotError("Une erreur est survenue, veuillez réessayer plus tard.")
        }
    }
})

SlotModal.propTypes = {
    bookables: PropTypes.array,
    onSlotCreated: PropTypes.func,
    preSelectionValues: PropTypes.object,
    slot: PropTypes.object,
    user: PropTypes.object,
}
