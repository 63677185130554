import classNames from "classnames"
import { bool, func, string } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"

import KrForm from "./components/KrForm.jsx"
import Preloader from "../../../components/loaders/preloader/preloader.jsx"
import PhoneCallHelp from "../../../components/popin/help/phoneCall.jsx"
import Step from "../../../components/stepsManagement/Step.jsx"
import UserAgentHandler from "../../../services/UserAgentHandler.js"

import "./LyraEmbedPayment.scss"

export default function LyraEmbedPayment({ tunnelType, title, getPayment, noReturn }) {
    const params = useParams()
    const [ searchParams ] = useSearchParams()
    const location = useLocation()

    const [ payment, setPayment ] = useState(null)
    const [ loading, setLoading ] = useState(true)

    const step = useRef(null)

    const navigate = useNavigate()

    const prev = () => {
        if (location?.state?.prevLocation) {
            navigate(location.state.prevLocation?.pathname + location.state.prevLocation?.search, {
                replace: true,
                state: {
                    ...location.state.prevLocation.state,
                },
            })
        } else {
            navigate(-1)
        }
    }

    useEffect(() => {
        if (!location.state?.lyraEmbedParams) {
            prev()
        } else {
            setLoading(true)

            if (!getPayment) {
                setLoading(false)
                setPayment({})
            }

            getPayment?.(params, searchParams)
                .then(res => {
                    setPayment(res)
                    setLoading(false)
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setLoading(false)
                    prev()
                })
        }
    }, [ params ])

    return (
        <div className="stepManager">
            <div className="progressBar">
                <div className="progressBar__inner" style={{ width: "100%" }}></div>
            </div>

            {loading && <Preloader fixed/>}

            {payment && (
                <Step
                    title={title}
                    help={<PhoneCallHelp tunnelType={tunnelType}/>}
                    helpId={"OrderSummaryHelp"}
                    ref={step}
                    cls={classNames("orderSummary noMarginBottom arrows-on-sides lyraPayment", {
                        "padding-bottom": UserAgentHandler.isAndroid() || UserAgentHandler.isIOS(),
                        [tunnelType?.toLowerCase()]: tunnelType,
                    })}
                    returnText={"Précédent"}
                    onReturn={prev}
                    noReturn={noReturn}
                    otherId='last'
                >
                    <div
                        className={
                            "formsHolder c-row c-mrg--btm--4 c-mrg--btm--sm--1 orderSummary layoutSmall"
                            + tunnelType
                        }
                    >
                        <div className="c-col c-col--8 c-col--xm--12 no-padding">
                            {location.state?.lyraEmbedParams && <KrForm {...location.state.lyraEmbedParams} />}
                        </div>
                    </div>
                </Step>
            )}

            {!payment && !loading && (
                <div className={"no-order-transaction"}>
                    <p>Nous n'avons pas pu retrouver cette transaction. Merci de réessayer.</p>
                </div>
            )}
        </div>
    )
}

LyraEmbedPayment.propTypes = {
    getPayment: func,
    noReturn: bool,
    title: string,
    tunnelType: string,
}
