function getListingUrl(tunnelType, themeFeatures) {
    switch (tunnelType) {
    case "SCHOOL":
        return themeFeatures([ "/kids/ecoleDeFoot", "/padel/cours" ])
    case "BIRTHDAY":
        return "/kids/anniversaire"
    case "TRAINING":
        return themeFeatures([ "/kids/stagesDeFoot/listing", "/padel/stages" ])
    }
}

export default getListingUrl
