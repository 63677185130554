import dayjs from "dayjs"
import { node } from "prop-types"
import { createContext, useContext, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

const SchoolTrainingFiltersContext = createContext()
const SchoolTrainingTunnelContext = createContext()
const SchoolTrainingCurrentContext = createContext()

export function useSchoolTrainingFilters() {
    return useContext(SchoolTrainingFiltersContext)
}

export function useSchoolTrainingTunnel() {
    return useContext(SchoolTrainingTunnelContext)
}

export function useSchoolTrainingCurrent() {
    return useContext(SchoolTrainingCurrentContext)
}

export function SchoolTrainingSubscriptionContextProvider({ children }) {
    const [ searchParams ] = useSearchParams()
    const [ tunnelType, setTunnelType ] = useState(undefined)
    const [ filters, setFilters ] = useState({
        availableCenter: [],
        availableLevel: [],
        availableType: [],
        displayedSLots: null,
        filterCenter: null,
        filterLevel: null,
        filterType: null,
    })

    const user = useSelector(state => state.userLoaded)

    const [ currentSelection, setCurrentSelection ] = useState({
        adultForm: {
            birthdate: user?.birthdate ? new Date(user.birthdate) : "",
            level: user?.playerProfile?.level ?? "",
            ranking: user?.playerProfile?.ranking ?? "",
            title: user?.title ?? "",
        },
        areas: undefined,
        availableSlots: null,
        centers: undefined,
        fromStep4: false,
        isAdult: searchParams?.get("adulte") !== "non",
        isGlobalLoading: false,
        kidForm: {
            birthDate: "",
            firstname: "",
            gender: "",
            lastname: "",
        },
        kids: undefined,
        periodStart: dayjs().format("YYYY-MM-DD"),
        prestation: undefined,
        schoolSubscriptions: [],
        selectedAreaCenters: undefined,
        selectedAreaId: searchParams?.get("areaId") || undefined,
        selectedKid: undefined,
        selectedSlot: undefined,
        skipPrestaSup: false,
        stageSubscriptions: [],
        types: [],
    })

    return (
        <SchoolTrainingFiltersContext.Provider
            value={(useMemo(() => ({
                setValue: setFilters,
                value: filters,
            }), [ filters ]))}
        >
            <SchoolTrainingCurrentContext.Provider
                value={(useMemo(() => ({
                    setValue: setCurrentSelection,
                    value: currentSelection,
                }), [ currentSelection ]))}
            >
                <SchoolTrainingTunnelContext.Provider
                    value={(useMemo(() => ({
                        setValue: setTunnelType,
                        value: tunnelType,
                    }), [ tunnelType ]))}
                >
                    {children}
                </SchoolTrainingTunnelContext.Provider>
            </SchoolTrainingCurrentContext.Provider>
        </SchoolTrainingFiltersContext.Provider>
    )
}

SchoolTrainingSubscriptionContextProvider.propTypes = {
    children: node,
}
