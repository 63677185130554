/* eslint-disable no-irregular-whitespace */
import StepComponent from "./StepComponent"

function SuperSubCaptainInfo() {
    return (
        <>
            <div className="c-row justify-center">
                <div className="c-col c-col--10">
                    <p>
                        Ton match est dans moins de 5 jours et il te manque 1 ou plusieurs joueurs pour le compléter ?<br/>
                        <br/>
                        Utilise notre <a href="#">Service SuperSub</a> pour rechercher jusqu'à 3 joueurs auprès de la communauté MyUrban.
                    </p>
                </div>
            </div>
            <div>
                <StepComponent
                    numero={1}
                    icon={"football-field.svg"}
                    image={"Capitaine-1.png"}
                    title={"Créer une recherche de SuperSub"}
                    text={(
                        <>
                            En tant qu'organisateur, rends-toi dans <br/>
                            Réservations &gt; Locations &gt; A Venir &gt; Feuille de match &gt; Onglet Joueurs<br/>
                            <br/>
                            Si ta recherche change, tu peux revenir ici pour la modifier.
                        </>
                    )}
                />
                <StepComponent
                    numero={2}
                    icon={"gift.svg"}
                    image={"Capitaine-2.png"}
                    title={"Offrir la place au SuperSub ?"}
                    text={(
                        <>
                            Si tu choisis d'offrir la place au SuperSub, tu effectueras le paiement lors de la validation de sa candidature.<br/>
                            <br/>
                            Un imprévu, la réservation a été annulée ? Pas de problème, tu ne seras pas débité.
                        </>
                    )}
                />
                <StepComponent
                    numero={3}
                    icon={"sub.svg"}
                    image={"Capitaine-3.png"}
                    title={"Accéder aux candidatures"}
                    text={(
                        <>
                            Ta recherche est lancée.<br/>
                            <br/>
                            Clique sur le bouton SuperSub au niveau de ta réservation pour accéder aux candidatures.
                        </>
                    )}
                />
                <StepComponent
                    numero={4}
                    icon={"check.svg"}
                    image={"Capitaine-4.png"}
                    title={"Valider une candidature"}
                    text={
                        (<>
                            Confirme ton SuperSub et contacte-le si besoin.<br/>
                            <br/>
                            <strong>
                                Attention : Une candidature validée ne peut plus être annulée.
                            </strong>
                        </>)
                    }
                />
                <StepComponent
                    numero={5}
                    icon="star.svg"
                    image={"Capitaine-5.png"}
                    title={"Noter le Supersub"}
                    text={
                        "Une fois le match joué, retourne sur ta réservation et note ton expérience avec le SuperSub, il en fera de même."
                    }
                />
            </div>
        </>
    )
}

export default SuperSubCaptainInfo
