export const genderValues = [
    {
        label: "Femme",
        value: 2,
    },
    {
        label: "Homme",
        value: 1,
    },
    {
        label: "Autre",
        value: 9,
    },
]
