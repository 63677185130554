import { arrayOf, node, number, oneOfType, string } from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import "./authStep.scss"

function AuthStep({ stepNumber, children, cls }) {
    const currentAuthStepId = useSelector(state => state.auth.currentAuthStepId)
    
    return (
        <React.Fragment>
            {
                (currentAuthStepId && (currentAuthStepId === stepNumber)) && (
                    <div className={cls ? "authStep " + cls : "authStep"}>
                        {children}
                    </div>
                )
            }
        </React.Fragment>
      
    )

}

AuthStep.propTypes = {
    children: oneOfType([ string, node, arrayOf(node) ]),
    cls: string,
    stepNumber: number,
}

export default AuthStep
