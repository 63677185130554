import dayjs from "dayjs"
import { object } from "prop-types"

import useMediaPath from "../../../../hooks/useMediaPath"
import { getTypeName } from "../../../reservation/tunnelReservation/function"

import "./SubscriptionCard.scss"
import "./SubscriptionCardPadel.scss"
import "./SubscriptionCardSoccer.scss"

export default function SubscriptionCard({ abonnement }) {
    const mediaPath = useMediaPath()

    return (
        <div className="subscriptionCard">
            <div className="subInfos">
                <ul>
                    <li className="subInfo1">
                        <img src={mediaPath([ "/assets/icons/center-orange.svg", "/assets/images/padel/icons/icon-center.svg" ])} />
                        <span>{abonnement?.centerName}</span>
                    </li>

                    <li className="subInfo2">
                        <img src={mediaPath([ "/assets/icons/terrain-orange.svg", "/assets/images/padel/icons/icon-terrain.svg" ])} />
                        <span>{getTypeName(abonnement?.resourceType, abonnement?.resourceTypeDisplay)}</span>
                    </li>

                    <li className={"subInfo3"}>
                        <img src={mediaPath([ "/assets/icons/abo.svg", "/assets/images/padel/icons/icon-abo-alt.svg" ])} />
                        <span>
                            {`${abonnement?.nbReservations} ${abonnement?.nbReservations > 1 ? "réservations" : "réservation"}`}
                        </span>
                    </li>
                </ul>
                { abonnement.lastReservationDate !== null && (
                    <div className="lastBooking">
                        <span className="label">Dernière réservation :</span>
                        <ul>
                            <li className="subInfo4">
                                <img src={mediaPath([ "/assets/icons/calendar-orange.svg", "/assets/images/padel/icons/icon-calendar-green.svg" ])} />
                                <span>{dayjs(new Date(abonnement.lastReservationDate)).format("DD/MM/YYYY")}</span>
                            </li>

                            <li className="subInfo5">
                                <img src={mediaPath([ "/assets/icons/heure-orange.svg", "/assets/images/padel/icons/icon-heure.svg" ])} />
                                <span>{abonnement.hour}</span>
                            </li>

                            <li className="subInfo6">
                                <img src={mediaPath([ "/assets/icons/chrono-orange.svg", "/assets/images/padel/icons/icon-oclock.svg" ])} />
                                <span>{abonnement.rentalDuration.replace("45 min", "0h45").replace(/\s|(?!h)[a-z]+( )?/g, "")}</span>
                            </li>
                        </ul>
                    </div>
                )}
                {abonnement.lastReservationDate === null && <div className="noLastBooking">Aucune réservation.</div>}
            </div>
            <div className="status">{abonnement.statusDisplay}</div>
        </div>
    )
}

SubscriptionCard.propTypes = {
    abonnement: object,
}
