import { array, bool, func, object } from "prop-types"
import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"

import CheckList from "../../../../components/Forms/CheckList/CheckList"
import Preloader from "../../../../components/loaders/preloader/preloader"
import ReservationSlotInfoCard from "../../../../components/reservation/slotInfoCard/ReservationSlotInfoCard"
import SuperSubRequestCheckboxContent from "../../../../components/SuperSub/SuperSubRequest/SuperSubRequestCheckboxContent"

export default function DisplayFilteredMatch({ filteredMatch, fetchData, hasMoreData, ajaxLoading, onMatchClick, selectedMatch, userLoaded, isMobile, displayFormAndScrollTo }) {
    if (filteredMatch.length > 0) {

        return (
            <>
                <div className="c-col c-col--5 c-col--m--10 c-col--sm--11 flex justify-center no-padding foundMatchDiv">
                    <section className="o-section--foundMatch">
                        <InfiniteScroll
                            dataLength={filteredMatch.length}
                            next={fetchData}
                            hasMore={hasMoreData}
                            loader={ajaxLoading && <Preloader/>}
                            endMessage={
                                (filteredMatch.length >= 50 && (
                                    <p style={{ textAlign: "center" }}>
                                        <b>Toutes les demandes de supersubs<br/>disponibles pour ce jour ont été chargées !</b>
                                    </p>
                                ))
                            }
                        >
                            <div className="radioListe--small--half">
                                {filteredMatch.map(
                                    (match, idx) => {
                                        const currentMatchAlreadyAnswered = match.contactSupersubResponseState === 0 ||
                                            match.contactSupersubResponseState === 1 ||
                                            match.contactSupersubResponseState === -1 ||
                                            match.contactInvitationState === 1
                                        return (
                                            <React.Fragment key={"subMatch-" + match.id}>
                                                <CheckList
                                                    value={match.id}
                                                    cls="supersub"
                                                    callback={onMatchClick}
                                                    checked={
                                                        !currentMatchAlreadyAnswered
                                                        && (selectedMatch && selectedMatch.id === match.id)
                                                    }

                                                    disabled={
                                                        currentMatchAlreadyAnswered ||
                                                        match.organizer.userId === userLoaded.id
                                                    }
                                                >
                                                    <SuperSubRequestCheckboxContent
                                                        match={match}
                                                    />
                                                </CheckList>
                                                {(
                                                    isMobile 
                                                ) &&
                                                    (<>

                                                        <div
                                                            className={
                                                                "c-col c-col--3 c-col--sm--12 tooltip-description left"
                                                            }
                                                            id={"description-card-" + match.id}
                                                            key={"description-card-" + match.id + idx}
                                                        >
                                                            <ReservationSlotInfoCard
                                                                reservationSlot={match}
                                                                tunnelType="SUPERSUB"
                                                                slotName="demande"
                                                                slotNameIsFemale={true}
                                                                pleaseSelectText="une demande de supersub"

                                                                btnAction={displayFormAndScrollTo}
                                                            />
                                                        </div>
                                                    </>)
                                                }
                                            </React.Fragment>
                                        )
                                    },
                                )}
                            </div>

                        </InfiniteScroll>
                    </section>
                </div>
                {!isMobile && (
                    <div
                        className="c-col c-col--3 c-col--sm--12 matchDescCardDesktop"
                        id={"description-card-1"}
                        key={"description-card-1"}
                    >
                        <ReservationSlotInfoCard
                            reservationSlot={selectedMatch}
                            tunnelType="SUPERSUB"
                            slotName="demande"
                            slotNameIsFemale={true}
                            btnAction={() => {
                                displayFormAndScrollTo()
                            }}
                            pleaseSelectText="une demande de supersub"
                        />
                    </div>
                )}
            </>
        )
    }
}

DisplayFilteredMatch.propTypes = {
    ajaxLoading: bool,
    displayFormAndScrollTo: func,
    fetchData: func,
    filteredMatch: array,
    hasMoreData: bool,
    isMobile: bool,
    onMatchClick: func,
    selectedMatch: object,
    userLoaded: object,
}
