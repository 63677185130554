import { arrayOf , bool, node, oneOfType, string } from "prop-types"

function CategoryCheckHolder({ title, text, bordered, children }) {
    return (
        <div className="formsHolder c-row justify-center align-start layoutSmall">
            <div className="c-col c-col--10 c-col--sm--12">
                <div className="h3">{title}</div>
                {text &&
                    (<div className="text c-mrg--btm--2 c-mrg--btm--sm--1">
                        {text}
                    </div>)
                }
                <div className={"radioList--small c-mrg--btm--1" + 
                    (bordered ? 
                        " bordered"  
                        : 
                        ""
                    )
                }>
                    {children} 
                </div>
            </div>
        </div>
    )
}

CategoryCheckHolder.propTypes = {
    bordered: bool,
    children: oneOfType([ string, node, arrayOf(node) ]),
    text: string,
    title: string,
}

export default CategoryCheckHolder
