import { _apiCall } from "../../../backend/axios"

export function rateSupersubReponse(id, rating, ratingComment, absent) {
    const data = {
        rating: rating,
    }

    if (ratingComment) {
        data.ratingComment = ratingComment
    }

    if (absent) {
        data.absent = true
    }

    return  _apiCall(
        "modify/supersubs/responses/" + id + "/rate",
        "PUT",
        {
            "Content-Type" : "application/json",
        },
        data,
    ).then(
        (res) => {
            return res?.data?.data ? res.data.data : null
        },
    ).catch((err) => {
        return err
    })
}

export function getSuperSubResponse(responseId) {
    let baseUrl = "/read/supersubs/response/" + responseId
    return  _apiCall(
        baseUrl,
        "GET",
    ).then(
        (res) => {
            return res?.data?.data ? res.data.data : null
        },
    ).catch((err) => {
        return err
    })
}
