import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock-upgrade"
import { array, bool, func, object, string } from "prop-types"
import { createRef } from "react"

import ModalHandler from "../../../providers/Modal/ModalHandler"
import Modal from "../../Modal/Modal"
import SelectUserFriends from "../../user/selectUserFriends/selectUserFriends"

const AskTeammates =
    ModalHandler.create(({ alreadySelectedMatesIds, callback, visible, hide, className = null }) => {

        const UserFriends = createRef(null)

        function callbackAndClose() {
            if (UserFriends?.current) {
                callback(UserFriends.current.getSelection())
            }
            hide()
        }

        const handleBodyScroll = (visible) => {
            const el = document.querySelectorAll(".modal .modal-content")[0]
            if (visible) {
                disableBodyScroll(el)
            } else {
                enableBodyScroll(el)
            }
        }

        return (
            <Modal
                isOpen={visible}
                onClose={() => {
                    handleBodyScroll(false)
                    hide()
                }}
                className={className}
                beforeClose={
                    () => {
                        handleBodyScroll(false)

                        if (UserFriends?.current) {
                            UserFriends.current.empty()
                        }
                    }
                }
                afterOpen={() => {
                    handleBodyScroll(true)
                }}
            >
                <header>Ajout coéquipiers</header>
                <section className="invitationSection noHPadMobile">
                    <div className="centerPicker c-row justify-center align-start layoutSmall">
                        <div className="c-col c-col--10 c-col--sm--12 flex justify-center align-center wrap ">
                            <SelectUserFriends
                                emptyInListing
                                initialSelectedFriends={alreadySelectedMatesIds}
                                emptyText="Tu n'as pas encore de coéquipiers ou ils sont tous dans cet effectif"
                                callback={callbackAndClose}
                                ref={UserFriends}
                            />
                        </div>
                    </div>
                </section>
            </Modal>
        )
    })

AskTeammates.propTypes = {
    alreadySelectedMatesIds: array,
    callback: func,
    className   : string,
    isOpen: bool,
    parentPopIn: object,
    setIsOpen: func,
    showParentCallback: func,
}
AskTeammates.displayName = "AskTeammates"

export default AskTeammates
