import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import AvailableCredit from "./components/AvailableCredit/AvailableCredit"
import CbCard from "./components/CbCard/CbCard"
import ProfilCardWrapper from "./components/ProfilCardWrapper/ProfilCardWrapper"
import UsCards from "./components/UsCards/UsCards"
import UsCardsPreview from "./components/UsCards/UsCardsPreview"
import ButtonCta from "../../components/Buttons/button/button-cta"
import Preloader from "../../components/loaders/preloader/preloader"
import Modal from "../../components/Modal/Modal"
import _ContentComponent from "../../entries/main/contents/_ContentComponent"
import {
    deleteCreditCard,
    generatePayzenAliasRegisterReqs,
    getAvailableCredits,
    getCreditCard,
    getUsCards,
} from "../../globalAPI/api"
import useUrbanLanguage from "../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../hooks/useMediaPath"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"
import usePath from "../../routes/services/usePath.js"

import "./moyensDePaiementContent.scss"

function MoyensDePaiementContent() {
    const { onglet } = useParams()
    const [ areCardsLoading, setAreCardsLoading ] = useState(true)
    const [ cbCard, setCbCard ] = useState({})
    const [ usCards, setUsCards ] = useState([])
    const [ availableCredits, setAvailableCredits ] = useState([])
    const [ cbAliasError, setCbAliasError ] = useState(false)

    const { tu } = useUrbanLanguage()

    function getCardDetails() {
        setAreCardsLoading(true)

        Promise.all([ getCreditCard(), getUsCards(), getAvailableCredits() ]).then((results) => {
            const [ creditCardResult, usCardsResult, availableCreditsResult ] = results

            // Process Credit Card Result
            const creditCardData = creditCardResult?.data?.data
            if (creditCardData?.tokenDetails) {
                setCbCard(creditCardData)
            } else if (creditCardData?.errorCode) {
                deleteCreditCard()
                setCbAliasError(true)
            } else {
                setCbCard({})
            }

            // Process US Cards Result
            const usCardsData = usCardsResult.data.data
            let fullList = []
            if (usCardsData) {
                if (usCardsData["Actif"]) {
                    fullList.push(...usCardsData["Actif"])
                }
                if (usCardsData["Expiré"]) {
                    fullList.push(...usCardsData["Expiré"])
                }
            }
            setUsCards(fullList)

            // Process Available Credits Result
            setAvailableCredits(availableCreditsResult)

            setAreCardsLoading(false)
        }).catch(() => {
            setAreCardsLoading(false)
        })
    }

    useEffect(
        () => {
            getCardDetails()
        },[],
    )

    function renderCartesUS() {
        return usCards.map((elem, i) => (
            <UsCards
                elem={elem}
                setUsCard={(card) => {
                    ModalHandler.show(UsCardPopIn, { usCard: card })
                }}
                key={i + "-usCard"}
            />
        ))
    }

    function formatCreditCardNumber() {
        if (cbCard && cbCard.tokenDetails.pan) {
            let displayValue = ""
            let explodedParts = cbCard.tokenDetails.pan.replace(/X/g,"*").match(/.{1,4}/g)
            explodedParts.forEach((elem) => {
                displayValue += elem + " "
            })
            return displayValue
        }
        return ""
    }

    function renderUsCartesPreview() {
        return <UsCardsPreview />
    }

    function preRenderDesktopContent() {
        return preRenderContent()
    }

    useEffect(
        () => {
            if (onglet) {
                if (onglet === "ajouter" || onglet === "editer") {
                    ModalHandler.show(CbCardPopIn, {
                        cbCard: cbCard,
                        deleteCard: () => {
                            deleteCreditCard()
                            setCbCard({})
                        },
                    })
                }
            }
        }, [],
    )

    function preRenderContent() {

        return (
            <div key={Date.now()} className="paymentMeans c-row">
                {areCardsLoading ?
                    <Preloader fixed/>
                    : (
                        <>
                            {availableCredits && availableCredits.total > 0 && availableCredits.items.length > 0 && (
                                <AvailableCredit total={availableCredits.total} items={availableCredits.items}/>
                            )}
                            <CbCard
                                cbCard={cbCard}
                                cbAliasError={cbAliasError}
                                setCbCardPopinOpen={() => {
                                    ModalHandler.show(CbCardPopIn, {
                                        cbCard: cbCard,
                                        deleteCard: () => {
                                            deleteCreditCard().then(() => {
                                                ModalHandler.hide(CbCardPopIn)
                                            })
                                            setCbCard({})
                                        },
                                    })
                                }}
                                formatCreditCardNumber={formatCreditCardNumber}
                            />
                            <div className="cards us-cards">
                                <div className="title">Cartes {tu("name")}</div>
                                <ProfilCardWrapper>
                                    {usCards.length === 0 ?
                                        renderUsCartesPreview()
                                        :
                                        renderCartesUS()
                                    }
                                </ProfilCardWrapper>
                            </div>
                        </>)
                }
            </div>
        )
    }
    return (
        <_ContentComponent
            name="Moyens de paiement"
            preRenderContent={preRenderContent}
            preRenderDesktopContent={preRenderDesktopContent}
            title="Moyens de paiement"
            level1Label="MON PROFIL"
        ></_ContentComponent>
    )
}

const UsCardPopIn = ModalHandler.create(({ usCard, visible, hide }) => {
    const { tu } = useUrbanLanguage()
    const mediaPath = useMediaPath()

    return (
        <Modal
            className="usCard"
            isOpen={visible }
            onClose={hide}
        >
            <header>CARTE {tu("name")}</header>
            <div className="wrapper">
                <img src={mediaPath([ "/assets/images/Carte-US-O.png", "/assets/images/padel/CarteUP.png" ])}  />

                {usCard && (<div>
                    <div className="row">
                        <span>Numéro : </span>
                        <span className="weight-bold indent-left marge-bottom">{usCard.code}</span>
                    </div>
                    <div className="row">
                        <span>Souscrite le : </span>
                        <span className="weight-bold indent-left marge-bottom">{usCard.startDate}</span>
                    </div>
                    <div className="row">
                        <span>Expire le : </span>
                        <span className="weight-bold indent-left marge-bottom">{usCard.endDate}</span>
                    </div>
                    <div className="row">
                        <span>Valeur initiale : </span>
                        <span className="weight-bold indent-left marge-bottom">{(usCard.baseAmount + usCard.extraAmount).toFixed(2).toString().replace(".00", "")}€ (dont {usCard.extraAmount.toFixed(2).toString().replace(".00", "")}€ offerts)</span>
                    </div>
                    <div className="row total">
                        <span>Montant restant : </span>
                        <span className="weight-bold indent-left marge-bottom orange">{usCard.leftAmount.toFixed(2).toString().replace(".00", "")}€</span>
                    </div>
                </div>)}
            </div>
        </Modal>
    )
})

export const CbCardPopIn = ModalHandler.create(({ cbCard, visible, hide, deleteCard }) => {
    const navigate = useNavigate()
    const path = usePath()
    const location = useLocation()

    const processPayzenAliasRegistration = () => {
        generatePayzenAliasRegisterReqs()
            .then((result) => {
                sendPayzenForm(
                    path("/profil/payment/registerCard"),
                    result,
                    import.meta.env.VITE_URL_BASE + "/?action=payzenPayment&type=REGISTERCB",
                )
            })
    }

    function sendPayzenForm(lyraPaymentUrl, data, returnUrl) {
        if (data) {
            if (data.ipn) {
                returnUrl += "&ipn=" + encodeURIComponent("/api/" + data.ipn.split("/US-API/myurban/")[1])
            }

            navigate(lyraPaymentUrl, { state: { lyraEmbedParams: { ...data, returnUrl }, prevLocation: location } })
            hide()
        }
    }

    return (
        <Modal
            hideOnOverlayClicked
            className="cbCard"
            isOpen={visible}
            onClose={hide}
        >
            <header>CARTE BANCAIRE</header>

            <div className="wrapper">
                <div>
                    Afin d'enregistrer ta carte bancaire, tu vas être redirigé vers notre formulaire de paiement pour
                    prendre une empreinte.
                </div>

                <ButtonCta
                    isAlt={true}
                    type="submit"
                    onClick={processPayzenAliasRegistration}
                    text="Continuer"
                />
                {Object.keys(cbCard).length > 0 && (
                    <div className="delete">
                        <span
                            id="trigger-delete-account"
                            className="orange"
                            onClick={deleteCard}
                        >
                           Supprimer ma carte
                        </span>
                    </div>
                )}
            </div>
        </Modal>
    )
})

export default MoyensDePaiementContent
