import { bool, number, oneOfType, string } from "prop-types"

import useMediaPath from "../../../hooks/useMediaPath"
import ActivationTrigger from "../../Buttons/Triggers/ActivationTrigger"
import Tooltip from "../../tooltip/Tooltip"

export default function NotFullyPaid({ id, isOwner }) {
    const mediaPath = useMediaPath()

    return (
        <ActivationTrigger
            id={"notFullyPaid-" + id}
            cls="infoBubble notFullyPaid"
            target={"#infoBubble-notFullyPaid-" + id}
        >
            <img
                className="notif"
                src={mediaPath([ "/assets/icons/info-small-orange.svg", "/assets/images/padel/icons/icons-info.svg" ])}
            />
            <Tooltip id={"infoBubble-notFullyPaid-" + id}>
                <p>
                    {isOwner && (
                        <>
                            Les autres joueurs n'ont pas encore réglé leur part.<br />
                            En cas d'impayé après le match, tu seras débité du reste à payer.
                        </>
                    )}
                    {!isOwner && (
                        <>
                            Gagne du temps et règle ta part en ligne.<br />
                            En cas d'annulation, tu ne seras pas débité.
                        </>
                    )}
                </p>
            </Tooltip>
        </ActivationTrigger>
    )
}

NotFullyPaid.propTypes = {
    id: oneOfType([ number, string ]),
    isOwner: bool,
}
