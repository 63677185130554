import { string } from "prop-types"

function IconPadel({ backgroundColor, textColor }) {
    return (
        <svg className="notif" width="15" height="14" viewBox="0 0 15 14">
            <path fill={backgroundColor} d="M3.5.2h8.4c1.7 0 3 1.3 3 3v.1l-.3 7.7c-.1 1.6-1.4 2.9-3 2.9H3.2c-1.7 0-3-1.3-3-3v-.1L.5 3C.5 1.4 1.9.2 3.5.2z" fillRule="evenodd" clipRule="evenodd" />
            <path d="M6.8 3.1h1.5L8 7.7H7l-.2-4.6zm0 6.9V8.5h1.3V10H6.8z" fill={textColor} />
        </svg>
    )
}

IconPadel.propTypes = {
    backgroundColor: string,
    textColor: string,
}

export default IconPadel
