import { components } from "react-select"

export const CustomMenuPortal = props => {
    return (
        <components.MenuPortal
            {...props}
            className={"select-input-portal"}
        />
    )
}
