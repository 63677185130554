import { useNavigate } from "react-router-dom"

import usePath from "../../routes/services/usePath"

function useShowBanScreen() {
    const navigate = useNavigate()
    const path = usePath()

    return () => { 
        navigate(path("/confirmcommand"), {
            state: {
                customContentIdentifier : "home",
                customLink: "/",
                message: "banSupersub",
                subtitle: "Ton accès au service SuperSub a été interrompu.",
                success: true,
                title: "Désolé !",
                tunnelType: "SUPERSUB",
            },
        })
    }
}

export default useShowBanScreen
