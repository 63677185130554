import { useEffect, useState } from "react"

import { getUser, listUserFriendsAndInvitations } from "../globalAPI/api"
import { useTracker } from "../providers/TrackerProvider"

const useAuth = () => {
    const [ isAuth, setIsAuth ] = useState(null)
    const tracker = useTracker()

    const checkIsAuth = () => {
        getUser().then(
            (user) => {
                if (user && user.status === 401 || user.status === 500) {
                    setIsAuth(false)
                } else {
                    listUserFriendsAndInvitations()
                    setIsAuth(true)
                    tracker.setUserID(user.data.data.emailAddress)
                }
            }).catch(() => setIsAuth(false))
    }

    useEffect(() => {
        checkIsAuth()
    },[])

    return isAuth
}

export default useAuth
