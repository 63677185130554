import { AD_TEAM_SHOP, useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"

import "./TeamStoreAd.scss"

export const TeamStoreAd = () => {
    const { ads, setAds } = useLeagueStore()
    const ad = ads?.find(ad => ad.title === AD_TEAM_SHOP)

    if (!ad) {
        return null
    }

    return (
        <section className="team-store-ad">
            {ad?.alt && (
                <header>
                    <h2>{ad?.alt}</h2>
                </header>
            )}

            {ad?.image && (
                <a href={ad.url} target={ad.newTab ? "_blank" : "_self"} rel="noreferrer">
                    <picture>
                        <img src={ad.image} alt={ad?.alt ?? "pub"} onError={() => {
                            setAds(ads?.filter(a => a.title !== AD_TEAM_SHOP))
                        }}/>
                    </picture>
                </a>
            )}
        </section>
    )
}
