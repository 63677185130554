import PropTypes from "prop-types"
import { components } from "react-select"

export const CustomMultiValueLabel = props => {
    const { children } = props

    return (
        <components.MultiValueLabel
            {...props}
        >
            {children}
        </components.MultiValueLabel>
    )
}

CustomMultiValueLabel.propTypes = {
    children: PropTypes.node,
}
