export default function getFieldSrc(type) {
    const soccerType = [ 0, 1, 8, 10, 13 ]
    const padelType = [ 12, 14,15 ]
    const soccerFieldSrc = "/assets/images/soccerfield.png"
    const padelFieldSrc = "/assets/images/padel/padelField.png"

    if (soccerType.includes(type)) {
        return soccerFieldSrc
    } else if (padelType.includes(type)) {
        return padelFieldSrc
    } else {
        return soccerFieldSrc
    }
}
