
export default function LevelStars({ level, isStrokeStars }) {
    const stars = []
    for (var y = 0; y < parseInt(level) + 1; y++) {
        stars.push(
            <img src={isStrokeStars ? "/assets/icons/starStroke.svg"  : "/assets/icons/star--orange.svg"} key={"img-" + y}/>,
        )
    }
    return stars
}
