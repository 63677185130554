import { useSchoolTrainingCurrent, useSchoolTrainingTunnel } from "../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import EmergencyContact from "../../../kids/ecoleDeFoot/steps/EmergencyContact"

export default function SchoolTrainingEmergencyContact() {
    const { value: tunnelType } = useSchoolTrainingTunnel()
    const { value: { isAdult, skipPrestaSup } } = useSchoolTrainingCurrent()

    const stepNumber = isAdult ? undefined : ((skipPrestaSup ? 4 : 5) + "/")

    return <EmergencyContact numero={stepNumber} tunnelType={tunnelType} isAdult={isAdult} skipPrestaSup={skipPrestaSup} updateSearchParams />
}
