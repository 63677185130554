import { useEffect } from "react"

const useResetScroll = () => {
    useEffect(
        () => {
            document.querySelector("#content")?.scrollTo(0,0)
            document.querySelector("html")?.scrollTo(0, 0)
            window.scrollTo(0, 0)
        }, [],
    )
}

export default useResetScroll
