import { array, arrayOf, node, object, oneOfType, string } from "prop-types"

import "./empty_content.scss"

export default function EmptyContent({
    underContent,
    title,
    pictoSrc,
    customDescription,
    picto,
    descriptions,
}) {
    
    function renderDescriptions() {
        let descriptionRows = []
        if (customDescription) {
            return customDescription
        }
        descriptions.forEach((description, i) => {
            descriptionRows.push(
                <div key={i}>{description}</div>,
            )
        })
        return descriptionRows
    }

    return (
        <>
            <div className="empty-content">
                <div className={"picto" + (picto === "rectangle" ? " rectangle" : "")}><img src={pictoSrc} /></div>
                <div>
                    <div className="title">{title}</div>
                    <div className="descriptions">{renderDescriptions()}</div>
                </div>
            </div>
            { underContent && underContent}
        </>
    )
    
}

EmptyContent.propTypes = {
    customDescription: oneOfType([ string, object ]),
    descriptions: array,
    picto: string,
    pictoSrc: string,
    title: oneOfType([ string, node, arrayOf(node) ]),
    underContent: oneOfType([ string, node, arrayOf(node) ]),
}
