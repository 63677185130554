import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

const initialState = {}

export const useBookingStore = create(
    immer((set) => ({
        ...initialState,
        availableDiscounts: [],
        bookableDisplayLength: 0,
        bookableListingLength: 0,
        bookingInfosData: null,
        clearStore: () => set(initialState),
        error: null,
        fromOrderSummary: false,
        isLoading: false,
        onlyOneRemaining: false,
        savedFilters: null,
        selectedSlot: null,
        setAvailableDiscounts: (data) => set({ availableDiscounts: data }),
        setBookableDisplayLength: (data) => set({ bookableDisplayLength: data }),
        setBookableListingLength: (data) => set({ bookableListingLength: data }),
        setBookingInfosData: (data) => set({ bookingInfosData: data }),
        setError: (error) => set({ error: error }),
        setFromOrderSummary: (data) => set({ fromOrderSummary: data }),
        setIsLoading: (isLoading) => set({ isLoading: isLoading }),
        setOnlyOneRemaining: (data) => set({ onlyOneRemaining: data }),
        setPreReservation: (data) => set((state) => ({
            summaryData: {
                ...state.summaryData,
                preReservation: data,
            },
        })),
        setSavedFilters: (data) => set({ savedFilters: data }),
        setSelectedSlot: (data) => set({ selectedSlot: data }),
        setShouldTriggerSlotNotification: (data) => set({ shouldTriggerSlotNotification: data }),
        setSummaryData: (data) => set({ summaryData: data }),
        shouldTriggerSlotNotification: null,
        summaryData: null,
    })),
)
