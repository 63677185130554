import React from "react"
import Countdown, { zeroPad } from "react-countdown"

import ButtonCta from "../../../../../components/Buttons/button/button-cta"
import ShareLink from "../../../../../components/ShareLink/ShareLink"
import DateDisplay from "../../../../../components/Time/DateDisplay"
import { LocationPaths } from "../../../../../routes/ApplicationPaths/locationPaths.js"
import { getLocationIdFromPreBookingId } from "../../../../../store/actions/scaffold/api"

export function supersubCreationConfirm() {
    return (
        <>
            Ton match est maintenant visible auprès de tous nos SuperSubs.<br/>
            Surveilles tes notifications, des joueurs vont te contacter pour prendre part à la rencontre.<br/>
            <br/>
            Si tu as finalement trouvé un joueur, penses à annuler ta recherche de SuperSub dans le menu «&nbsp;Réservations&nbsp;».<br/>
            <br/>
            Bon Match !
        </>
    )
}

export function organizerAcceptSupersub() {
    return (
        <React.Fragment>
            Ton SuperSub a reçu un mail de confirmation. <br/>
            N’hésites pas à prendre contact avec lui pour lui donner les dernières consignes d’avant match !
            <br/>
            <br/>
            Bon Match !
        </React.Fragment>
    )
}

export function organizerRefuseSupersub(selectedResponse) {
    return (
        <React.Fragment>
            Tu as refusé la demande de&nbsp;{selectedResponse?.contact ? selectedResponse.contact.firstname + " " + selectedResponse.contact.lastname.charAt(0) + "." : ""}<br/>
            S'il te manque encore un joueur pour sauver ton match, n’hésites pas à relancer une demande de SuperSub.
            <br/>
            <br/>
            Bon Match !
        </React.Fragment>
    )
}

export function closeSupersubRequest() {
    return (
        <>
            Ton match est maintenant invisible auprès de tous nos SuperSubs.<br />
            Tu peux dès à présent prendre contact avec ton ou tes SuperSubs(s).<br />
            <br />
            Bon Match !
        </>
    )
}

export function bookingConfirm(tu) {
    return (
        <>
            <p className="bookingConfirm">
                Un email de confirmation t'a été envoyé.<br />
                <strong>{tu("familiarityTrustThanks")}</strong>
            </p>
        </>
    )
}

export function bookingConfirmCompo() {
    return (
        <>
            <p className="bookingConfirm">
                Ta réservation est confirmée.<br/>
                Tu peux désormais ajouter tes joueurs et<br />
                les inciter à payer leur part en ligne.
            </p>
        </>
    )
}

export function cancelPayment() {
    return (
        <>
            <p className="bookingConfirm">
                Ta réservation a été annulée suite à une trop longue inactivité.<br />
                Nous t'invitons à renouveler ta réservation.
            </p>
        </>
    )
}

export function bookingPaymentConfirm() {
    return (
        <React.Fragment>
            <span className="">
                Ton paiement a bien été enregistré.
            </span>
        </React.Fragment>
    )
}

export function bookingSuccess(queryParameters, navigate, path, tu) {
    return (
        <>
            <p className="bookingConfirm">
                Un email de confirmation t'a été envoyé.<br />
                <strong>{tu("familiarityTrustThanks")}</strong>
            </p>
            <p className="buttonsWrapper">
                <ButtonCta
                    isAlt={true}
                    text="INVITER MES JOUEURS"
                    onClick={
                        () => {
                            getLocationIdFromPreBookingId(
                                queryParameters["vads_ext_info_prereservationid"],
                            ).then(
                                res => {
                                    const locationId = res.reservationId
                                    navigate(path(LocationPaths.toReservationPlayers(locationId)), {
                                        state: { openInvitationPopin: true },
                                    })
                                },
                            )
                        }
                    }
                />
                <ButtonCta
                    isCta2Alt
                    onClick={() => {
                        navigate(path("/reservations/locations"))
                    }}
                    text="RETOUR À MES RÉSERVATIONS"
                />
            </p>
        </>
    )
}

export function playerSupersubConfirm() {
    return (
        <React.Fragment>
            Surveilles tes e-mails, l’organisateur va maintenant devoir valider ta demande.  <br/>
            Si tu n'es plus disponible, tu peux annuler ta demande tant que l’organisateur ne l’a pas validée. <br/>
            Si tu as effectué un paiement, tu ne seras pas débité en cas d’annulation.
            <br/>
            <br/>
            Bon Match !
        </React.Fragment>
    )
}

export function freeSupersubConfirm() {
    return (
        <React.Fragment>
            Ton SuperSub a reçu un mail de confirmation. <br/>
            N’hésite pas à prendre contact avec lui pour lui donner les dernières consignes d’avant match !
            <br/>
            <br/>
            Bon Match !
        </React.Fragment>
    )
}

export function apiError() {
    return (
        <React.Fragment>
            Excuse-nous pour ce désagrément occasionnel.   <br/> <br/>
            Nous te conseillons de te rediriger vers une autre page ou de nous contacter au  <strong>0 806 23 00 24.</strong>
        </React.Fragment>
    )
}

export function preregisterConfirm(tunnelType, limitDate, firstSubscription) {
    let message = <p>Vous avez jusqu’au <DateDisplay stringDate={limitDate}/> pour finaliser l’inscription et effectuer votre paiement en ligne ou en centre. <br/> Au-delà de cette date, la pré-inscription sera définitivement annulée.</p>
    const lastUniverse = localStorage.getItem("lastUniverse")
    const youhave = lastUniverse === "theme-padel" ? "Tu as" : "Vous avez"
    const your = lastUniverse === "theme-padel" ? "ton" : "votre"
    switch (tunnelType) {
    case "SCHOOL":
        message = <p>{youhave} jusqu’au <DateDisplay stringDate={limitDate}/> pour finaliser l’inscription et effectuer {your} paiement en ligne ou en centre. <br/> Au-delà de cette date, la pré-inscription sera définitivement annulée.</p>
        break
    case "TRAINING":
        message = firstSubscription?.kid ? 
            <p>{youhave} jusqu’au <DateDisplay stringDate={limitDate}/> pour finaliser l’inscription de <span style={{ textTransform: "uppercase" }}>{firstSubscription.kid.firstName}</span> et effectuer {your} paiement en ligne ou en centre. <br/> <br/> Au-delà de cette date, la pré-inscription sera définitivement annulée.</p>
            :
            <p>{youhave} jusqu’au <DateDisplay stringDate={limitDate}/> pour finaliser l’inscription et effectuer {your} paiement en ligne ou en centre. <br/> <br/> Au-delà de cette date, la pré-inscription sera définitivement annulée.</p>
            
        break
    case "LEAGUE":
        message = <p>Tu as jusqu’au <DateDisplay stringDate={limitDate}/> pour finaliser<br/>l’inscription de ton équipe <span style={{ textTransform: "uppercase" }}>{firstSubscription.championshipTeam.name}</span> et effectuer ton paiement<br/>en ligne ou en centre. <br/> Au-delà de cette date, la pré-inscription sera définitivement annulée.</p>
        break
    case "CUP":
        message = <p>Tu as jusqu’au {limitDate} pour finaliser l’inscription de ton équipe {firstSubscription.cupTeam.teamName} et effectuer ton paiement en ligne ou dans ton centre. <br/> Au-delà de cette date, la pré-inscription sera définitivement annulée.</p>
        break
    case "BIRTHDAY":
        message = (<p>Vous avez jusqu’au <strong> <DateDisplay stringDate={limitDate}/> </strong> pour finaliser la réservation de l'anniversaire et effectuer votre paiement en ligne ou en centre. <br/> Au-delà de cette date, la pré-inscription sera définitivement annulée.</p>)
        break
    default:
        break
    }
    return message
}

export function bookingWithoutPaying(res, navigate, path) {
    return (
        <>
            <p>
                Tu as jusqu’au{" "}
                <strong>
                    <DateDisplay
                        stringDate={
                            res.start ?
                                res.start
                                :
                                ""
                        }
                    />
                </strong>
                {" "}pour finaliser ta réservation. <br />
                Au-delà de cette date, la réservation sera définitivement annulée.
            </p>
            <p className="buttonsWrapper">
                <ButtonCta
                    isAlt={true}
                    text="INVITER MES JOUEURS"
                    onClick={
                        () => {
                            const locationId = res.id
                            navigate(path(LocationPaths.toReservationPlayers(locationId)), {
                                state: { openInvitationPopin: true },
                            })
                        }
                    }
                />
                <ButtonCta
                    isCta2Alt={true}
                    onClick={() => {
                        navigate(path("/reservations/locations"))
                    }}
                    text="RETOUR À MES RÉSERVATIONS"
                />
            </p>
        </>
    )
}

export function banSupersub() {
    return (
        <React.Fragment>
            Tu n’as plus accès au service SuperSub car tu ne t'es pas présenté au dernier match.  <br /> <br />
            N’hésite pas à nous contacter pour davantage d'informations au  <strong>0 806 23 00 24.</strong>
        </React.Fragment>
    )
}

export function acceptBookingInvitation(location, navigate, path, shareLink, theme) {
    const now = new Date()
    const start = new Date(location.start)

    return (
        <React.Fragment>
            <p className="bookingConfirm">
                Ta participation au match est confirmée.<br />
                {location.amountDue > 0 && (
                    <>
                        Gagne du temps et paye ta place en ligne dès maintenant.<br />
                        En cas d'annulation, tu ne seras pas débité.
                    </>
                )}
                {start > now && location.acceptPrePayment && location.amountDue > 0 && (
                    <>
                        <br />
                        Temps restant pour payer en ligne : <Countdown
                            date={start}
                            zeroPadTime
                            renderer={({ days, hours, minutes, seconds }) => <span>{days > 0 && `${days + " jour"}${days > 1 ? "s et " : " et "}`}{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>}
                        />
                    </>
                )}
                <br /><br />
            </p>

            <p className="buttonsWrapper">
                <ShareLink label="partager le lien du match" shareLink={shareLink} icon="" isAlt={theme === "theme-soccer"} isCta2={theme === "theme-padel"} />
                {location.amountDue > 0  && location.acceptPrePayment && (
                    <ButtonCta
                        onClick={
                            () => {
                                navigate(path(LocationPaths.toReservationPayments(location.id)), {
                                    state: { openPaiementPopin: true },
                                })
                            }
                        }
                        text="PAYER MA PART"
                    />
                )}
            </p>
        </React.Fragment>
    )
}
