import { editSchoolSubscription } from "../../kids/ecoleDeFoot/api"
import { editStageSubscription } from "../../kids/stageDeFoot/api"

export default function schoolTrainingWaitingList(userLoaded, selectedKid, tunnelType, setIsLoading, navigate, dailySlot, isAdult) {
    setIsLoading(true)
    let data = {
        child: selectedKid?.id,
        childBirthDate: selectedKid?.birthDate.split("-").reverse().join("/"),
        childFirstname: selectedKid?.firstName,
        childGender: selectedKid?.gender,
        childLastname: selectedKid?.lastName,
        childOutfitSize: selectedKid?.equipment,
        contact1Email: userLoaded?.emailAddress,
        contact1Firstname: userLoaded?.firstname,
        contact1Lastname: userLoaded?.lastname,
        contact1Phone: userLoaded?.phoneNumber ? userLoaded.phoneNumber.replace("+33", "0") : "",
        dailySlot: dailySlot?.value ? dailySlot.value : dailySlot,
        isAdult,
        waitingForAvailability: true,
    }

    let handleReturn = (response) => {
        setIsLoading(false)

        let confirmedSub
        switch (tunnelType) {
        case "SCHOOL":
            confirmedSub = response.data
            break
        case "TRAINING":
            confirmedSub = response.data.data
            break
        }
        const lastUniverse = localStorage.getItem("lastUniverse")
        const you = lastUniverse === "theme-padel" ? "te" : "vous"
        const your = lastUniverse === "theme-padel" ? "ton" : "votre"
        navigate(
            "/padel/confirmcommand", {
                state: {
                    confirmedSubscription: confirmedSub,
                    message: `Un email ${you} sera envoyé en cas de places disponibles.`,
                    subtitle: `Nous ${you} confirmons l’inscription de ${your} enfant à la liste d’attente.`,
                    success: true,
                    title: "Inscription à la liste d’attente",
                    tunnelType: tunnelType,
                },
            },
        )

    }
    switch (tunnelType) {
    case "SCHOOL":
        editSchoolSubscription(data).then(handleReturn)
        break
    case "TRAINING":
        data.stageSession = dailySlot.id,
        data.childPosition = selectedKid?.position
        editStageSubscription(data).then(handleReturn)
        break
    }
}
