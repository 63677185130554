import { _apiCall } from "../../../backend/axios.js"

export function checkGymlibCodeValidity(code, resourceId, playersNumber, type = "preReservation") {
    const data = {
        code: code,
        playersNumber: playersNumber,
        type: type,
    }

    if (!playersNumber) {
        delete data.playersNumber
    }

    return _apiCall(
        `modify/pre-reservation/${resourceId}/gymlibCode`,
        "POST",
        null,
        data,
    ).then((res) => {
        return res
    })
}
