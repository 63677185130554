import Modal from "../../components/Modal/Modal.jsx"
import ModalHandler from "../../providers/Modal/ModalHandler.jsx"

export const FavoriteUniversePopIn = ModalHandler.create(({ visible, hide, action }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            className="universeChoice"
        >
            <div className="modal-container">
                <div className="modal-header">
                    <span>Sélectionne ton sport préféré :</span>
                </div>

                <div
                    className="modal-item padel-item"
                    onClick={()=>action("theme-padel")}
                >
                    <span>Padel</span>
                </div>

                <div
                    className="modal-item soccer-item"
                    onClick={()=>action("theme-soccer")}
                >
                    <span>Foot</span>
                </div>
            </div>
        </Modal>
    )
})
