export const SET_SCHOOL_SUBSCRIPTION = "ADD_SCHOOL_SUBSCRIPTION"
export const REMOVE_SCHOOL_SUBSCRIPTION = "REMOVE_SCHOOL_SUBSCRIPTION"

export const SET_SECOND_SCHOOL_SUBSCRIPTION = "SET_SECOND_SCHOOL_SUBSCRIPTION"
export const REMOVE_SECOND_SCHOOL_SUBSCRIPTION = "REMOVE_SECOND_SCHOOL_SUBSCRIPTION"

export const ADD_SCHOOL_OPTION = "ADD_SCHOOL_OPTION"
export const REMOVE_SCHOOL_OPTION = "REMOVE_SCHOOL_OPTION"
export const CLEAR_SCHOOL_OPTIONS = "CLEAR_SCHOOL_OPTIONS"

export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART"
export const REMOVE_PRODUCT_FROM_CART = "REMOVE_PRODUCT_FROM_CART"

export const ADD_DISCOUNT_CODE = "ADD_DISCOUNT_CODE"
export const REMOVE_DISCOUNT_CODE = "REMOVE_DISCOUNT_CODE"

export function addSchoolOptionInStore(category, id, amount) {
    return {
        payload: {
            amount: amount,
            category: category,
            id: id,
        },
        type: ADD_SCHOOL_OPTION,
    }
}

export function clearSchoolOptions() {
    return {
        type: CLEAR_SCHOOL_OPTIONS,
    }
}

export function removeSchoolOption(category, id) {
    return {
        payload: {
            category: category,
            id: id,
        },
        type: REMOVE_SCHOOL_OPTION,
    }
}

export function setSecondSchoolSubscription(id, amount) {
    return {
        payload: {
            amount,
            id: id,
        },
        type: SET_SECOND_SCHOOL_SUBSCRIPTION,
    }
}

export function setSchoolSubscription(id, amount) {
    return {
        payload: {
            amount,
            id: id,
        },
        type: SET_SCHOOL_SUBSCRIPTION,
    }
}

export function removeSchoolSubscription() {
    return {
        type: REMOVE_SCHOOL_SUBSCRIPTION,
    }
}

export function removeSecondSchoolSubscription() {
    return {
        type: REMOVE_SECOND_SCHOOL_SUBSCRIPTION,
    }
}

export function addProductToCart(id, size, quantity, price) {
    return {
        payload: {
            id: id,
            price: price,
            quantity: quantity,
            size: size,
        },
        type: ADD_PRODUCT_TO_CART,
    }
}

export function removeProductFromCart(id, size) {
    return {
        payload: {
            id: id,
            size: size,
        },
        type: REMOVE_PRODUCT_FROM_CART,
    }
}

export function addDiscountCode(name) {
    return {
        payload: {
            name: name,
        },
        type: ADD_DISCOUNT_CODE,
    }
}

export function removeDiscountCode() {
    return {
        type: REMOVE_DISCOUNT_CODE,
    }
}
