import * as actions from "./actions"

const initialState = {
    currentStep: 0,
    previousStep: 0,
    stepCount: 0,
}

/**
 * Reducer that manages step-related actions and updates the store's state accordingly
 * @param {*} state The current state of the store to be modified
 * @param {*} action The received action
 */
function stepManagement(state = initialState, action) {
    switch (action.type) {
    case actions.RESET_STEP_COUNT:
        return { ...state, stepCount: 0 }

    case actions.PREVIOUS_STEP:
        // eslint-disable-next-line no-case-declarations
        const newCurrentStepForPrevious = Math.max(0, state.currentStep - 1)
        return {
            ...state,
            currentStep: newCurrentStepForPrevious,
            previousStep: state.currentStep,
        }

    case actions.NEXT_STEP:
        // eslint-disable-next-line no-case-declarations
        const newCurrentStepForNext = Math.min(state.currentStep + 1, state.stepCount - 1)
        return {
            ...state,
            currentStep: newCurrentStepForNext,
            previousStep: state.currentStep,
        }

    case actions.ADD_STEP:
        return { ...state, stepCount: state.stepCount + 1 }

    case actions.GO_TO_STEP: {
        const payload = parseInt(action.payload, 10)
        if (isNaN(payload)) {
            return state // Return the current state without changes if payload is not a valid number
        }
        const newCurrentStepForGoTo = payload < 0 ? 0 : (payload >= state.stepCount ? state.stepCount - 1 : payload)
        return {
            ...state,
            currentStep: newCurrentStepForGoTo,
            previousStep: state.currentStep,
        }
    }

    case actions.SKIP_STEP:
        // eslint-disable-next-line no-case-declarations
        const newCurrentStepForSkip = state.previousStep < state.currentStep ? state.currentStep + 1 : Math.max(0, state.currentStep - 1)
        return {
            ...state,
            currentStep: newCurrentStepForSkip,
            previousStep: state.currentStep,
        }

    default:
        return state
    }
}

export default stepManagement
