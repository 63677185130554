import {
    addPlayerToLeagueTeam,
} from "../../league/api/api.js"
import {
    addOnePlayerToCupTeam,
} from "../urbanCup/api"

export default function useAddPlayerToTeam(tunnelType) {
    switch (tunnelType) {
    case "CUP":
        return addOnePlayerToCupTeam

    case "LEAGUE":
        return addPlayerToLeagueTeam

    default:
        // eslint-disable-next-line no-console
        console.error("useAddPlayerToTeam - Not implemented tunneltype : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        return () => {}
    }
}
