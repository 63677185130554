import PropTypes from "prop-types"
import { useMemo } from "react"

import { TeamPicture } from "../../../../../components/league/TeamPicture/TeamPicture.jsx"
import { TeamHeading } from "../../parts/TeamHeading/TeamHeading.jsx"
import { Title } from "../../parts/Title/Title.jsx"
import { BaseLayout } from "../Base.jsx"

import "./TeamPlayers.scss"

export const TeamPlayers = (props) => {
    const { data, pagination, ctx } = props
    const { currentPage, limit } = pagination ?? {}

    const paginatedPlayers = useMemo(() => {
        const startIndex = (currentPage - 1) * limit
        const endIndex = startIndex + limit
        return data?.leagueTeam?.players.slice(startIndex, endIndex)
    }, [ data, currentPage, limit ])

    return (
        <BaseLayout cls={"teamPlayers"}>
            <TeamHeading data={data} ctx={ctx} />
            <Title size={[ 30 ]} title={"effectif de l'équipe"} cls={"uppercase"} margin={[ 6,0,104,0 ]}/>
            <div className="playersGrid">
                {paginatedPlayers?.map(player => (
                    <div key={player?.id} className={"playerCard"}>
                        <TeamPicture
                            size={128}
                            image={player?.businessLeaguePictureUrl ?? player?.pictureUrl}
                            placeHolder={"/assets/images/user-placeholder-b.svg"}
                        />
                        <div className={"content"}>
                            <p className="tw-semibold">{player?.firstname}<br/><strong className={"tw-bold uppercase"}>{player?.lastname}</strong></p>
                            <span className={"tw-regular"}>{player?.jobTitle || "N/C"}</span>
                        </div>
                    </div>
                ))}
            </div>
        </BaseLayout>
    )
}

TeamPlayers.propTypes = {
    ctx: PropTypes.object,
    data: PropTypes.object,
    pagination: PropTypes.object,
}

