import { object } from "prop-types"
import React from "react"

import Preloader from "../../../components/loaders/preloader/preloader"
import { cancelConfirmationOverlay, toggleLevel3Overlay } from "../../../store/actions/content"

class _OverlayContentComponent extends React.Component
{

    constructor(props) {
        super(props)
        this.state = {
            apiData: null,
            // selectedFilter: props.targetContent
        }
    }

    _renderAtomDateDisplay(atomDate, withHourMinutes) {
        return (<span>
            {atomDate.substr(8,2) + "/" + atomDate.substr(5,2) + "/" + atomDate.substr(0,4)}
            {withHourMinutes === true ? " à " + atomDate.substr(11,2) + "h" + atomDate.substr(14,2) : ""}
        </span>)
    }
    checkIfMobile() {
        return !!(
            (
                window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth
            ) <= 640
        )
    }
    getApiData()
    {
        return this.state.apiData
    }

    getTitle() {
        return null
    }

    _renderErrorMessage() {
        let message = ""
        if (this.state.errorMessage) {
            if (typeof this.state.errorMessage === "object") {
                message = []
                Object.keys(this.state.errorMessage).forEach((key) => {
                    message.push(<div>{this.state.errorMessage[key]}</div>)
                })
            } else {
                message = <div>{this.state.errorMessage}</div>
            }
        }
        return message
    }

    _renderPreloader(fixed) {
        return <Preloader fixed={fixed} alt/>
    }

    _renderRadio(onChange, name, value, label, checked, className) {
        className = className ? className : ""
        return (<div className={className}>
            <label>
                <input
                    name={name}
                    type="radio"
                    checked={checked}
                    value={value}
                    data-label={label}
                    onChange={onChange}
                />
                <span>{label}</span>
            </label>
        </div>)
    }

    _renderContainerHeaderTitle() {
        if (this.getTitle() !== null) {
            if (this.checkIfMobile()) {
                return (<div className="header">
                    <div className="overlay-main-title">{this.getTitle()}</div>
                </div>)
            } else {

                return <div className="underlined-title">{this.getTitle()}</div>
            }
        }
        return ""
    }

    render() {
        return (
            <div className="overlayContent" data-identifier={this.getName().toLowerCase()}>
                <div className="overlay-level3-container">
                    {this._renderContainerHeaderTitle()}
                    {this.checkIfMobile() ?
                        this.preRenderContent()
                        :
                        this.preRenderDesktopContent()
                    }
                </div>
            </div>
        )
    }

    static stateToProps(state) {

        return {
            appConfig: state.appConfig,
            // selectedFilter: state.mainContentLevel3 !== null ? state.mainContentLevel3 : '_-_'
        }
    }

    // PARENT ACTIONS TO MERGE WITH ...
    static dispatchToProps() {
        return {
            cancelConfirmationOverlay: cancelConfirmationOverlay,
            toggleLevel3Overlay: toggleLevel3Overlay,
            // selectLevel3Filter: selectLevel3Filter
            // mainScroll: mainScroll
        }
    }
}

_OverlayContentComponent.propTypes = {
    appConfig: object,
}

export default _OverlayContentComponent
