import dayjs from "dayjs"
import { func, number, object, string } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import ButtonCta from "../../../components/Buttons/button/button-cta"
import CheckBlock from "../../../components/Forms/CheckBlock/CheckBlock"
import Preloader from "../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import Step from "../../../components/stepsManagement/Step"
import CommonHandler from "../../../entries/main/business/CommonHandler"
import { addUserKid, editUserKid, getUserKids } from "../../../globalAPI/api"
import useQueryParametersObject from "../../../hooks/routing/useQueryParametersObject"
import useResetScroll from "../../../hooks/useResetScroll"
import genderValues from "../../../model/enums/genderValues"
import { setFormEntry } from "../../../store/forms/actions"
import { goToStep } from "../../../store/stepsManagement/actions"
import ChildForm from "../ecoleDeFoot/components/ChildForm/ChildForm"
import CreneauxDispo from "../ecoleDeFoot/steps/CreneauxDispo"

import "./MesEnfants.scss"

/**
 * Etape du formulaire EcoleDeFoot permettant de choisir son Ecole
 * @param title Titre de l'étape
 */
function MesEnfants(props) {
    useResetScroll()
    const {
        tunnelType,
        numero,
    } = props

    const [ displayForm, setDisplayForm ] = useState(false)
    const [ updatingKid, setUpdatingKid ] = useState(false)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)

    const step = useRef(null)
    const childform = useRef(null)
    const kidsHolder = useRef(null)
    const forms = useSelector(state => state.forms)
    const currentStep = useSelector(state => state.stepManagement.currentStep)
    
    const searchParamsObject = useQueryParametersObject()
    const [ searchParams, setSearchParams ] = useSearchParams()
    const kidIdFromQuery = searchParams.get("kidId")
    const equipmentIdFromQuery = searchParams.get("equipmentId")
    
    const dispatch = useDispatch()
    
    const formData = forms[MesEnfants.formDataName] !== undefined ?
        forms[MesEnfants.formDataName]
        : {
            kids: {},
            kidsOptions: undefined,
        }
    
    const formDataKid = formData

    const { kids } = formData

    const formKid = forms[MesEnfants.formName] !== undefined ?
        forms[MesEnfants.formName]
        : {
            selectedKid: {},
        }

    let {
        selectedKidId,
        selectedKid,
        equipmentId,
    } = formKid

    const help = <PhoneCallHelp tunnelType={tunnelType}/>

    /**
     * Lorsque le composant est chargé, appelle l'API pour récupérer les enfants de l'utilisateur et les affiche
     */
    useEffect(
        () => {
            if (selectedKid) {
                if (Object.values(selectedKid).length > 0) {
                    setDisplayForm(true)
                    step.current.setRecap(
                        <div>
                            {selectedKid.firstName}
                            <br/>
                            {selectedKid.birthDate.includes("/") ? selectedKid.birthDate : dayjs(selectedKid.birthDate).format("DD/MM/YYYY")}
                        </div>,
                    )
                }
            }

            const { kidsOptions } = formData

            if (kidsOptions === undefined) {
                getUserKids().then(
                    (response) => {
                        const kids = {}
                        response.forEach(
                            kid => {
                                kids[kid.id] = kid
                                kids[kid.id].equipment = undefined
                                kids[kid.id].position = undefined
                            },
                        )

                        if (response.length === 0) {
                            setDisplayForm(true)
                        }

                        dispatch(
                            setFormEntry(
                                MesEnfants.formDataName, {
                                    kids: kids,
                                },
                            ),
                        )
                    },
                )
            }

            checkValidity()
        }, [],
    )

    /**
     * Step Check
     */
    useEffect(
        () => {
            if (currentStep > numero - 1 && childform.current) {
                const childInfo = childform.current.getInfo()
                if (selectedKid === undefined) {
                    selectedKid = childInfo
                    selectedKid.birthDate = selectedKid.date.split("-").reverse().join("/")
                } else if (Object.keys(selectedKid).length > 0) {
                    let selectedKidDate = selectedKid.birthDate.split("/");
                    // eslint-disable-next-line no-self-assign
                    [ selectedKidDate[0], selectedKidDate[1], selectedKidDate[2] ] = [ selectedKidDate[2], selectedKidDate[1], selectedKidDate[0] ]
                    selectedKidDate = selectedKidDate.join("-")

                    if (
                        childform.current.validate() === false
                        && !kidIdFromQuery
                    ) {
                        dispatch(
                            goToStep(numero - 1),
                        )
                    } else if (
                        selectedKid && (
                            selectedKid.id !== childInfo.id ||
                            selectedKid.gender !== childInfo.gender ||
                            selectedKid.firstName !== childInfo.firstName ||
                            selectedKid.lastName !== childInfo.lastName ||
                            selectedKid.equipment !== childInfo.equipment ||
                            selectedKidDate !== childInfo.date
                        ) && updatingKid === false
                        && !kidIdFromQuery
                    ) {
                        dispatch(
                            goToStep(numero - 1),
                        )

                        setUpdatingKid(true)
                        updateChildrenInfo().then(
                            () => {
                                setUpdatingKid(false)
                                dispatch(
                                    goToStep(numero),
                                )
                            },
                        )
                    }
                }
            }

            if (currentStep === numero - 1) {
                updateChildList()
            }
        }, [ currentStep ],
    )

    useEffect(
        () => {
            if (
                (selectedKid && !selectedKid.id)
                && (kidIdFromQuery || selectedKidId)
                && formDataKid.kids
            ) {
                let child = Object.assign({}, formDataKid.kids[kidIdFromQuery || selectedKidId])
                if (child && child.birthDate) {
                    if (equipmentId || equipmentIdFromQuery) {
                        child.equipment = equipmentId || equipmentIdFromQuery + ""
                        setSearchParams({
                            ...searchParamsObject,
                            equipmentId: equipmentId || equipmentIdFromQuery,
                        })
                    }

                    child.birthDate = child.birthDate.split("T")[0].split("-").reverse().join("/")
                    let kidBirth = child.birthDate ?? undefined
                    setNewChild(child, kidBirth)
                    setDisplayForm(true)
                }
            }
            checkValidity()
        }, [ formDataKid.kids, selectedKidId ],
    )

    // Auto Scroll to form when revealed
    useEffect(
        () => {
            if (displayForm) {
                scrollToChildrenForm()
            }
        }, [ displayForm ],
    )

    /**
     * Vérifie la validité de l'étape
     */
    function checkValidity() {
        if (childform.current) {
            if (childform?.current?.validate() && step?.current?.validate) {
                step.current.validate()
            } else if (step?.current?.unvalidate) {
                step.current.unvalidate()
            }
        }
    }

    /**
     * Populate the store and the query with the new child
     */
    function setNewChild(newChild, kidBirth, noDelete) {
        const oldSelectedKid = forms[MesEnfants.formName] !== undefined ? forms[MesEnfants.formName].selectedKid : {}
        let data = {
            ...forms[MesEnfants.formName],
        }
        data.oldSelectedKid = oldSelectedKid
        data.selectedKid = newChild

        function deleteChild() {
            data = {}
            const oldQueryParams = { ...searchParamsObject }
            delete oldQueryParams.kidId
            setSearchParams({})
            setDisplayForm(false)
        }

        if (newChild) {
            if (newChild.id) {
                const newQuery = {
                    ...searchParamsObject,
                    kidId: newChild.id,
                }
                if (
                    newChild.equipment
                    || (!newChild.equipment && equipmentIdFromQuery)
                ) {
                    newQuery.equipmentId = newChild.equipment || equipmentIdFromQuery
                }
                setSearchParams(newQuery)
            }

            if (kidBirth) {
                data.kidBirthYear = kidBirth
            }

            if (oldSelectedKid === newChild && !noDelete) {
                step.current.unvalidate()
                deleteChild()
            } else {
                if (newChild.firstName && newChild.birthDate) {
                    step.current.setRecap(
                        <div>
                            {newChild.firstName}
                            <br/>
                            {newChild.birthDate.includes("/") ? newChild.birthDate : dayjs(newChild.birthDate).format("DD/MM/YYYY")}
                        </div>,
                    )
                }
                setDisplayForm(true)
            }

        } else if (!newChild) {
            deleteChild()
        }

        dispatch(
            setFormEntry(
                MesEnfants.formName,
                data,
            ),
        )
    }

    /**
     * Récupère les informations de l'enfant qui à été cliqué et affiche le formulaire d'inscription avec ses infos
     * @param {*} event
     */
    function buildChildForm(event) {
        event.preventDefault()
        event.stopPropagation()

        resetCreneauForm()

        const newSelectedKidId = event.currentTarget.id
        let newSelectedKid = formData.kids[newSelectedKidId]

        newSelectedKid.birthDate = newSelectedKid && newSelectedKid.birthDate ?
            newSelectedKid.birthDate.split("T")[0].split("-").reverse().join("/")
            :
            ""

        let kidBirth = newSelectedKid.birthDate ?? undefined
        setNewChild(newSelectedKid, kidBirth)

        checkValidity()
    }

    /* handle scroll auto to children form */
    function scrollToChildrenForm() {
        let scrollElement = document.getElementById("child-form")
        const rectChildForm = scrollElement.getBoundingClientRect()

        // mobile
        if (window.innerWidth <= 640) {
            const y = rectChildForm.top + window.scrollY - 40
            const _document = document.scrollingElement || document.documentElement
            CommonHandler.scrollTo(_document, y, 400)
        } else {
            const y = rectChildForm.bottom + window.scrollY + rectChildForm.height
            let baseElement = document.getElementById("step-article-2")
            CommonHandler.scrollTo(baseElement, y, 400)
        }
    }

    /**
     * Vide les informations de l'enfant séléctrionné
     *
     * Affiche un formulaire d'inscription vide
     */
    function renderBlankForm() {
        if (childform.current) {
            childform.current.clear()
        }

        step?.current?.unvalidate()

        dispatch(
            setFormEntry(
                MesEnfants.formName, {
                    selectedKid: {
                        equipement: "",
                        gender: "",
                        genderValue: "",
                        id: null,
                        position: "",
                    },
                },
            ),
        )

        setDisplayForm(true)
    }

    /**
     * Récupére la liste d'enfants depuis l'API
     */
    function updateChildList() {
        if (childform.current) {
            const childInfo = childform.current.getInfo()
            setAjaxLoading(true)
            getUserKids().then(
                (response) => {
                    const kids = {}
                    let selectedKid
                    response.forEach(
                        kid => {
                            kids[kid.id] = kid
                            kids[kid.id].equipment = childInfo.equipment
                            kids[kid.id].position = childInfo.position
                            if (kid.firstName === childInfo.firstName &&
                                kid.lastName === childInfo.lastName) {
                                selectedKid = kid
                            }
                        },
                    )

                    if (selectedKid !== undefined) {
                        selectedKid.birthDate = selectedKid.birthDate.split("T")[0].split("-").reverse().join("/")
                        let kidBirth = selectedKid.birthDate ?? undefined
                        setNewChild(selectedKid, kidBirth)
                    }

                    dispatch(
                        setFormEntry(
                            MesEnfants.formDataName, {
                                kids: kids,
                            },
                        ),
                    )
                    setAjaxLoading(false)
                },
            ).catch(() => {
                setAjaxLoading(false)
            })
        }
    }

    /**
     * Ajoute ou met à jour un enfant dans l'API
     * @returns Une promise qui se resolve quand la tâche est finie.
     */
    function updateChildrenInfo() {
        return new Promise(
            (resolve) => {
                if (childform.current) {
                    setAjaxLoading(true)
                    const childInfo = childform.current.getInfo()
                    if (childInfo.date !== undefined) {
                        childInfo.birthDate = childInfo.date
                    }
                    if (!childInfo.id) {
                        addUserKid({
                            birthDate: dayjs(childInfo.birthDate).format("YYYY-MM-DDTHH:mm:ss"),
                            firstName: childInfo.firstName,
                            genderValue: genderValues[childInfo.gender],
                            lastName: childInfo.lastName,
                        }).then(
                            (resAdd) => {
                                let child = childInfo
                                child.id = resAdd.id
                                child.birthDate = child.birthDate.split("T")[0].split("-").reverse().join("/")
                                let kidBirth = child.birthDate ?? undefined
                                setNewChild(
                                    child,
                                    kidBirth,
                                    true,
                                )

                                setAjaxLoading(false)
                                resolve()
                            },
                        )
                    } else {
                        const data = {
                            birthDate: dayjs(childInfo.birthDate).format("YYYY-MM-DDTHH:mm:ss"),
                            firstName: childInfo.firstName,
                            genderValue: genderValues[childInfo.gender],
                            lastName: childInfo.lastName,
                        }

                        const kidFromStore = kids[childInfo.id]

                        if (
                            kidFromStore
                            && (
                                kidFromStore.firstName !== data.firstName
                                || kidFromStore.lastName !== data.lastName
                                || kidFromStore.genderValue !== data.genderValue
                                || kidFromStore.birthDate !== data.birthDate
                            )
                        ) {
                            editUserKid(
                                childInfo.id,
                                data,
                            ).then(
                                (resEdit) => {
                                    let child = resEdit
                                    child.birthDate = child.birthDate.split("T")[0].split("-").reverse().join("/")
                                    let kidBirth = child.birthDate ?? undefined

                                    child.equipment = childform.current.getInfo().equipment
                                    setNewChild(child, kidBirth)
                                    setAjaxLoading(false)
                                    resolve()
                                },
                            )
                        } else {
                            setAjaxLoading(false)
                            resolve()
                        }
                    }
                }
            },
        ).then(resetCreneauForm)
    }

    const resetCreneauForm = () => {
        dispatch(
            setFormEntry(
                CreneauxDispo.formName, {},
            ),
        )
    }

    const cfValidate = step.current ? () => {
        let kidFromChildForm = {}
        if (childform.current) {
            kidFromChildForm = childform.current.getInfo()
            kidFromChildForm.birthDate = kidFromChildForm.date
            let kidBirth = kidFromChildForm.birthDate ?? undefined
            setNewChild(kidFromChildForm, kidBirth)
        }
        if (step.current?.validate) {
            step.current.validate()
        }
    } : () => {}

    const cfUnvalidate = step?.current?.unvalidate ? () => {
        if (step?.current?.unvalidate) {step.current.unvalidate()}
    } : () => {}

    return (
        <Step
            {...props}
            title="Mes enfants"
            help={ help }
            helpId={"mesEnfantsHelp"}
            onValidation={updateChildrenInfo}
            promise={true}
            className="noMarginBottom"
            ref={step}
        >
            {ajaxLoading &&
                <Preloader fixed={true}/>
            }

            {(tunnelType === "SCHOOL") && (
                <div className="formInfo">
                    <strong>Important !</strong>
                    <p>2 enfants = 2 Inscriptions distinctes</p>
                </div>
            )}

            { Object.values(kids).length > 0 && (
                <div className="formsHolder c-row justify-center align-start layoutSmall">
                    <div className="c-col c-col--9 c-col--sm--12">
                        <div ref={kidsHolder} className="radioList--small">
                            { kids && (
                                Object.values(kids).map(
                                    (kid) => (
                                        <CheckBlock
                                            callback={buildChildForm}
                                            checked={selectedKid && kid.id === selectedKid.id}
                                            value={kid.id}
                                            name="kids"
                                            key={kid.id}
                                        >
                                            <div className="radioList__item__subTitle">
                                                {kid.firstName}
                                                <strong>{kid.lastName.toUpperCase()}</strong>
                                            </div>

                                            <div className="radioList__item__info">
                                                Né{kid.gender === "Fille" ? "e" : ""} le {kid.birthDate}
                                            </div>
                                        </CheckBlock>
                                    ),
                                )
                            )}

                            {(Object.values(kids).length > 0) && (
                                <div className="radioList__item action">
                                    <ButtonCta
                                        noSkew
                                        noShadow
                                        heightFull
                                        className="buttonCta--iconMore"
                                        text="Ajouter un nouvel enfant"
                                        onClick = {renderBlankForm}
                                    />
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            )}

            {displayForm && (
                <div className="formsHolder c-row justify-center align-start layoutSmall">
                    <div className="c-col c-col--8 c-col--sm--12 no-padding" id="child-form">
                        <ChildForm
                            {...selectedKid}
                            onValidation={cfValidate}
                            onUnvalidation={cfUnvalidate}
                            tunnelType={tunnelType}
                            ref={childform}
                            kidId={selectedKid?.id ? selectedKid.id : null}
                            validateStep={step?.current?.validate}
                            key={selectedKid?.id}
                        />
                    </div>
                </div>
            )}
        </Step>
    )
}

MesEnfants.formName = "choixEnfant"
MesEnfants.formDataName = "enfantsData"

MesEnfants.propTypes = {
    currentStep: number,
    forms: object,
    numero: number,
    setFormInStore: func,
    tunnelType: string,
}
export default MesEnfants
