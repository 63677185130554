/* # Connecteurs endpoints API League */

import { _apiCall } from "../../../backend/axios"

export function confirmPhone(id, code) {
    return  _apiCall(
        "check/" + id + "/confirm-phone",
        "PUT",
        {
            "Content-Type" : "application/json",
        },
        {
            code: code,
        },
    ).then(
        (res) => res,
    ).catch((err) => {
        return err
    })
}

export function sendMailConfirm(id) {
    return  _apiCall(
        "check/" + id + "/send-confirm-email",
        "PUT",
        {
            "Content-Type" : "application/json",
        },
    ).then(
        (res) => {
            return res
        },
    ).catch((err) => {
        return err
    })
}

export function changePhone(id, tel) {
    return  _apiCall(
        "replace/" + id + "/change-phone",
        "PUT",
        {
            "Content-Type" : "application/json",
        },
        {
            phoneNumber: tel,
        },
    ).then(
        (res) => {
            return res
        },
    ).catch((err) => {
        return err
    })
}

export function confirmChangePhone(id, code) {
    return  _apiCall(
        "check/" + id + "/confirm-change-phone",
        "PUT",
        {
            "Content-Type" : "application/json",
        },
        {
            code: code,
        },
    ).then(
        (res) => {
            return res
        },
    ).catch((err) => {
        return err
    })
}
