import { useSchoolTrainingCurrent, useSchoolTrainingFilters, useSchoolTrainingTunnel } from "../../../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import useUrbanLanguage from "../../../../../../hooks/urbanLanguage/useUrbanLanguage"

import "./NoSchoolTraining.scss"

export default function NoSchoolTraining() {
    const { value: tunnelType } = useSchoolTrainingTunnel()
    const { value: { filterLevel, filterType, filterCenter } } = useSchoolTrainingFilters()
    const { value: { selectedAreaId, areas } } = useSchoolTrainingCurrent()

    const haveFilter = !!(filterLevel || (filterType !== undefined && filterType !== null) || filterCenter)
    
    const { tu } = useUrbanLanguage()
    const wordPressHref = tunnelType === "SCHOOL" ?
        "https://www.urbanpadel.fr/academie"
        :
        "https://www.urbanpadel.fr"

    return (
        <div className="noSchoolTraining">
            <span className="noResultWarning">
                {!haveFilter && tunnelType && (` Aucun ${tunnelType === "SCHOOL" ? "cours" : "stage"} n’est actuellement organisé dans la ville de ${areas?.find(area => area.id === selectedAreaId)?.name}.`)}
                {haveFilter && tunnelType && `Aucun ${tunnelType === "SCHOOL" ? "cours" : "stage"} correspondant à tes critères n’est actuellement organisé.`}
                {!haveFilter && !tunnelType && (`Aucune prestation n’est actuellement organisée dans la ville de ${areas?.find(area => area.id === selectedAreaId)?.name}.`)}
                {haveFilter && !tunnelType && "Aucune prestation correspondant à tes critères n’est actuellement organisé."}
            </span> 
            <span className="noResultMoreInfo">
                Plus d’informations sur les prochains {tunnelType === "SCHOOL" ? "cours" : "stages"} sur notre site&nbsp;
                <a 
                    href={wordPressHref}
                    target="_blank" rel="noreferrer"
                >
                    {tu("wordpress")}
                </a>
            </span>
        </div>
    )
}
