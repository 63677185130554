import dayjs from "dayjs"
import isToday from "dayjs/plugin/isToday"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getSuperSubRequests } from "./api"
import DisplayFilteredMatch from "./DisplayData/DisplayFilteredMatch"
import DisplaySelectedData from "./DisplayData/DisplaySelectedData"
import NoMatchOnCurrentDay from "./NoMatchOnCurrentDay"
import SupersubsFilterPopin from "./SupersubsFilterPopin"
import ButtonCta from "../../../components/Buttons/button/button-cta"
import CheckList from "../../../components/Forms/CheckList/CheckList"
import Form from "../../../components/Forms/Form/Form"
import TextArea from "../../../components/Forms/textArea/TextArea"
import { SelectInput } from "../../../components/Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../../components/Inputs/TextInput/TextInput.jsx"
import PageHeader from "../../../components/layout/pageHeader/PageHeader"
import Preloader from "../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../components/popin/help/phoneCall"
import DayPicker from "../../../components/Time/DayPicker/DayPicker"
import Help from "../../../components/tooltip/Help/Help"
import { getUsCentersArea } from "../../../globalAPI/api"
import { createSupersubMatchResponse } from "../../../globalAPI/supersub/api"
import useShowBanScreen from "../../../hooks/supersub/showBanScreen"
import useIsMobile from "../../../hooks/useIsMobile"
import useThemeHeader from "../../../hooks/useThemeHeader"
import locationHours from "../../../model/data/reservation/locationHours"
import usePath from "../../../routes/services/usePath"
import formatTwoDigits from "../../../services/formatTwoDigits"
import centerFilterSort from "../../../services/Sorting/centerFilterSort"
import durationFilterSort from "../../../services/Sorting/durationFilterSort"
import fieldTypeSort from "../../../services/Sorting/fieldTypeSort"
import { setFormEntry } from "../../../store/forms/actions"
import PreForm from "../../kids/components/preForm/PreForm"

import "./FindAMatch.scss"

dayjs.extend(isToday)

export default function FindAMatch() {
    const path = usePath()
    const showBanScreen = useShowBanScreen()
    const PAGE_SIZE = 50
    const today = new Date()
    const findAMatchData = useSelector(state => state.forms[FindAMatch.formName])
    const isMenuClick = new URLSearchParams(location.search).get("isMenuClick")
    const notMenuClickHaveMatchInStore = !isMenuClick && findAMatchData
    // useIsMobile
    const isMobile = useIsMobile()
    // useSelector
    const userLoaded = useSelector(state => state.userLoaded)
    // useRef
    const select = useRef(null)
    const dayPicker = useRef(null)
    const filterPopin = useRef(null)
    const form = useRef(null)
    // useState
    const [ offset, setOffset ] = useState(0)
    const [ foundMatch, setFoundMatch ] = useState([])
    const [ allRequests, setAllRequests ] = useState(null)
    const [ hasMoreData, setHasMoreData ] = useState(true)
    const [ applyMessage, setApplyMessage ] = useState("")
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ selectedAreaId, setSelectedAreaId ] = useState()
    const [ displayBottomForm, setDisplayBottomForm ] = useState(false)
    const [ initializeTooltip, setInitializeTooltip ] = useState(false)
    const [ dayChoosen, setDayChoosen ] = useState()
    const [ selectedDate, setSelectedDate ] = useState(dayjs(today).format("MM-DD-YYYY"))
    const [ selectedMatch, setSelectedMatch ] = useState((notMenuClickHaveMatchInStore && findAMatchData.selectedMatch) ? findAMatchData.selectedMatch : null)
    // useSTATE for useEffect
    const [ errorCount, setErrorCount ] = useState(0)
    const [ matchError, setMatchError ] = useState(false)
    const [ initializeAppData, setInitializeAppData ] = useState(false)
    const [ startDeletingFilter, setStartDeletingFilter ] = useState(false)
    /* ACTIONS */
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const themeHeader = useThemeHeader()
    // POPPIN useState
    const [ types, setTypes ] = useState([])
    const [ aAreas, setAAreas ] = useState([])
    const [ centers, setCenters ] = useState([])
    const [ typeFilter, setTypeFilter ] = useState([])
    const [ oldFilters, setOldFilters ] = useState(null)
    const [ centerFilter, setCenterFilter ] = useState([])
    const [ filteredMatch, setFilteredMatch ] = useState([])
    const [ durationFilter, setDurationFilter ] = useState([])
    const [ filterChanged, setFilterChanged ] = useState(false)
    const [ hourFilter, setHourFilter ] = useState([])
    const [ sameAsOldFilters, setSameAsOldFilters ] = useState(true)
    const [ filterIsActivate, setFilterIsActivate ] = useState(false)
    const [ haveOnlyOneCenter, setHaveOnlyOneCenter ] = useState(false)
    const [ selectedDuration, setSelectedDuration ] = useState(oldFilters?.duration ? [ ...oldFilters.duration ] : [])
    const [ selectedType, setSelectedType ] = useState(oldFilters?.type ? [ ...oldFilters.type ] : [])
    const [ selectedCenter, setSelectedCenter ] = useState(oldFilters?.center ? [ ...oldFilters.center ] : [])
    const [ selectedHour, setSelectedHour ] = useState(oldFilters ? oldFilters.hour : [])
    // POPIN CONST
    const durations = [ "1 heure", "1 heure 30", "2 heures" ]
    const hours = locationHours
    // POPIN useRef
    const hoursScroll = useRef(null)

    // CONST
    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
    }

    const applyFilters = (filters) => {
        // Permet de reset les filtres et aussi les filtres sélectionné dans la popin
        if (filters.duration) {
            const tempDuration = []
            filters.duration.forEach(duration => {
                if (duration === "1 heure" || duration === "1 heure 30" || duration === "2 heures") {
                    tempDuration.push(duration)
                }
            })
            setDurationFilter(tempDuration)
            setSelectedDuration(tempDuration)
        }
        if (filters.type) {
            setTypeFilter(types?.filter(el => filters.type.includes(el)))
            setSelectedType(types?.filter(el => filters.type.includes(el)))
        }
        if (filters.center) {
            setCenterFilter(centers?.filter(el => filters.center.includes(el)))
            setSelectedCenter(centers?.filter(el => filters.center.includes(el)))
        }

        setHourFilter(filters.hour && filters.hour.length > 0 || Object.keys(filters.hour).length > 0 ? Object.values(filters.hour) : [])
        setSelectedHour(filters.hour && filters.hour.length > 0 ? filters.hour : [])

        setFormInStore(
            FindAMatch.formName, {
                filters: {
                    center: filters.center,
                    duration: filters.duration,
                    hour: filters.hour,
                    type: filters.type,
                },
            },
        )
        setFilterChanged(!filterChanged)
        // setFilteredMatch([]);
        // Si activé = reset des filtres chaque jours
    }

    const selectCenterFilter = (center, oldCenters, setFunction, remove) => {
        const tempCenters = [ ...oldCenters ]
        const filterIsAlreadyTaken = tempCenters.findIndex(
            item => item === center,
        ) !== -1
        if (filterIsAlreadyTaken) {
            const indexOfType = tempCenters.findIndex(typ => typ === center)
            tempCenters.splice(indexOfType, 1)
        } else {
            tempCenters.push(center)
        }
        if (remove) {
            applyFilters({
                center: [ ...tempCenters ],
                // type: [...typeFilter],
                // duration: [...durationFilter],
                hour: hourFilter,
            })
        }
        if (typeof setFunction === "function") {
            setFunction([ ...tempCenters.sort(centerFilterSort) ])
        }
        return [ ...tempCenters.sort(centerFilterSort) ]
    }

    const selectTypesFilter = (type, oldTypes, setFunction, remove) => {
        const tempTypes = [ ...oldTypes ]

        const filterIsAlreadyTaken = tempTypes.findIndex(
            item => item === type,
        ) !== -1

        if (filterIsAlreadyTaken) {
            const indexOfType = tempTypes.findIndex(typ => typ === type)
            tempTypes.splice(indexOfType, 1)
        } else {
            tempTypes.push(type)
        }
        if (remove) {
            applyFilters({
                // duration: durationFilter,
                hour: hourFilter,
                
                type: [ ...tempTypes ],
                // center: [...centerFilter]
            })
        }
        if (typeof setFunction === "function") {
            setFunction([ ...tempTypes.sort(fieldTypeSort) ])
        }
        return [ ...tempTypes.sort(fieldTypeSort) ]
    }

    const selectDurationsFilter = (duration, oldDuration, setFunction, remove) => {
        const tempDurations = [ ...oldDuration ]
        const filterIsAlreadyTaken = tempDurations.findIndex(
            item => item === duration,
        ) !== -1
        if (filterIsAlreadyTaken) {
            const indexOfDuration = tempDurations.findIndex(dur => dur === duration)
            tempDurations.splice(indexOfDuration, 1)
        } else {
            tempDurations.push(duration)
        }
        if (remove) {
            applyFilters({
                // type: [...typeFilter],
                // center: [...centerFilter],
                duration: [ ...tempDurations ],
                hour: hourFilter,
            })
        }

        setFunction([ ...tempDurations.sort(durationFilterSort) ])
        return [ ...tempDurations.sort(durationFilterSort) ]
    }

    // CONST for SuperSubsFilterPopin
    const checkFiltersChange = (type, value) => {
        const center = type === "center" ? [ ...value ] :
            selectedCenter ? [ ...selectedCenter ] : []
        const fieldType = type === "type" ? [ ...value ] :
            selectedType ? [ ...selectedType ] : []
        const duration = type === "duration" ? [ ...value ] :
            selectedDuration ? [ ...selectedDuration ] : []
        const hour = type === "hour" ? value : selectedHour

        if (oldFilters.hour !== hour || (oldFilters.hour.length > 0 && hour.length > 0 && oldFilters.hour !== hour)) {
            return setSameAsOldFilters(false)
        } else {
            setSameAsOldFilters(true)
        }

        if (oldFilters.center.length > 0 && oldFilters.center.length === center.length) {
            for (let i = 0; i < center.length; i++) {
                if (center[i] !== oldFilters.center[i]) {
                    return setSameAsOldFilters(false)
                } else if (center[i] === oldFilters.center[i]) {
                    setSameAsOldFilters(true)
                }
            }
        } else if (center.length !== oldFilters.center.length) {
            return setSameAsOldFilters(false)
        } else {
            setSameAsOldFilters(true)
        }

        if (oldFilters.type.length > 0 && oldFilters.type.length === fieldType.length) {
            for (let i = 0; i < fieldType.length; i++) {
                if (fieldType[i] !== oldFilters.type[i]) {
                    return setSameAsOldFilters(false)
                } else if (fieldType[i] === oldFilters.type[i]) {
                    setSameAsOldFilters(true)
                }
            }
        } else if (fieldType.length !== oldFilters.type.length) {
            return setSameAsOldFilters(false)
        } else {
            setSameAsOldFilters(true)
        }

        if (oldFilters.duration.length > 0 && oldFilters.duration.length === duration.length) {
            for (let i = 0; i < duration.length; i++) {
                if (duration[i] !== oldFilters.duration[i]) {
                    return setSameAsOldFilters(false)
                } else if (duration[i] === oldFilters.duration[i]) {
                    setSameAsOldFilters(true)
                }
            }
        } else if (duration.length !== oldFilters.duration.length) {
            return setSameAsOldFilters(false)
        } else {
            setSameAsOldFilters(true)
        }

    }

    // FUNCTION
    function changeSelectedArea() {
        const newSelectedAreaId = select.current.value
        setSelectedAreaId(newSelectedAreaId)
        setSelectedDate(dayjs(today).format("MM-DD-YYYY"))
        setFoundMatch([])
        setSelectedMatch(null)
        setDisplayBottomForm(false)

        setFormInStore(FindAMatch.formName, {
            ...findAMatchData,
            selectedAreaId: newSelectedAreaId,
            selectedDate: today,
            selectedMatch: null,
        })

    }

    function getDayLocations(allRequests, dayChoosen) {
        const date = dayChoosen.split("-")
        const reformatDate = `${date[2]}-${date[0]}-${date[1]}`
        let matchsOfDay = []
        allRequests.forEach(req => {
            if (req?.[reformatDate]) {
                matchsOfDay.push(req[reformatDate].results)
            }
        })
        matchsOfDay = matchsOfDay[0]
        setFoundMatch(matchsOfDay ?? [])

    }

    function onDayClick(day) {
        const dateFormat = dayjs(day).format("YYYY-MM-DD")
        const matchOfDay = allRequests?.find(el => el && el[dateFormat]?.date === dateFormat)
        if (matchOfDay && matchOfDay[dateFormat].count > 0) {
            setFilteredMatch([])
            setSelectedDate(dayjs(day).format("MM-DD-YYYY"))
            if (selectedAreaId) {
                setSelectedDate(dayjs(day).format("MM-DD-YYYY"))
                setDayChoosen(dayjs(day).format("MM-DD-YYYY"))
                getDayLocations(allRequests, dayjs(day).format("MM-DD-YYYY"))
                setSelectedMatch(null)
                setDisplayBottomForm(false)
                setHasMoreData(true)

                setFormInStore(FindAMatch.formName, {
                    ...findAMatchData,
                    selectedDate: day,
                    selectedMatch: null,
                })
            }
        }
    }

    function goToPayment() {
        if (selectedMatch.isFree || selectedMatch.reservation.amountDue === 0) {
            setAjaxLoading(true)
            createSupersubMatchResponse(
                selectedMatch.id,
                {
                    description: applyMessage,
                },
                themeHeader,
            ).then(
                (res) => {
                    if (res) {
                        navigate(path("/confirmcommand"), {
                            state: {
                                confirmedSubscription: selectedMatch,
                                customContentIdentifier: "findMatch",
                                customLink: "/supersub/findMatch?isMenuClick=true",
                                message: "playerSupersubConfirm",
                                subtitle: "Ta demande pour rejoindre le match a bien été envoyée.",
                                success: true,
                                title: "Tu y es presque&nbsp;!",
                                tunnelType: "SUPERSUB",
                            },
                        })
                    } else {
                        navigate(
                            "/confirmcommand", {
                                state: {
                                    message: "Nous sommes désolés, un problème est survenu",
                                    subtitle: "Réessaies",
                                    success: true,
                                    title: "Erreur !",
                                    tunnelType: "SUPERSUB",
                                },
                            },
                        )
                    }
                    setAjaxLoading(false)
                },
            )
        } else {
            setSelectedMatch(null)
            navigate(path("/ordersummary"), {
                state : {
                    customCls: "active findAMatch",
                    supersubApplyMessage: applyMessage,
                    supersubRequest: selectedMatch,
                    title: "Récapitulatif",
                    tunnelType: "SUPERSUB",
                },
            })
        }
    }

    function requestDateIndex(d = null) {
        let dateToUse = new Date()
        if (d !== null && typeof d !== "string") {
            dateToUse = d
        }
        if (!allRequests) {
            return -1
        }
        const formattedDate = dateToUse.getFullYear() + "-" + formatTwoDigits(dateToUse.getMonth() + 1) + "-" + formatTwoDigits(dateToUse.getDate())
        return Object.keys(allRequests).indexOf(formattedDate)
    }

    function fetchData() {
        let newOffset = offset + PAGE_SIZE
        let matchsOfDay = []
        let reformatDate = ""
        if (dayChoosen) {
            const date = dayChoosen.split("-")
            reformatDate = `${date[2]}-${date[0]}-${date[1]}`
        }
        allRequests.forEach(req => {
            if (reformatDate && req && req[reformatDate]) {
                matchsOfDay.push(req[reformatDate])
            }
        })
        if (allRequests !== "undefined" && !!matchsOfDay.length) {
            if (
                newOffset <= matchsOfDay[0].count
            ) {
                setOffset(newOffset)
            } else {
                setHasMoreData(false)
            }
        }
    }

    function deleteMyFilter() {
        if (filterIsActivate && centerFilter.length > 0 && filteredMatch.length === 0 && startDeletingFilter) {
            centerFilter.forEach(center => {
                if (!centers?.includes(center)) {
                    selectCenterFilter(center, centerFilter, setCenterFilter, true)
                    setStartDeletingFilter(false)
                }
            })
            setStartDeletingFilter(true)
        }

        if (filterIsActivate && typeFilter.length > 0 && filteredMatch.length === 0 && startDeletingFilter) {
            typeFilter.forEach(type => {
                if (!types.includes(type)) {
                    selectTypesFilter(type, typeFilter, setTypeFilter, true)
                    setStartDeletingFilter(false)
                }
            })
            setStartDeletingFilter(true)
        }
    }

    function onMatchClick(ev) {
        const oldSelectedMatch = selectedMatch

        const newSelectedMatch = foundMatch?.find(
            x => parseInt(x.id) === parseInt(ev.currentTarget.id),
        )

        // Toggle tooltip
        const timeslotInfo = document.querySelector("#description-card-" + ev.currentTarget.id)
        if (timeslotInfo) {
            timeslotInfo.classList.toggle("active")
            if (oldSelectedMatch && oldSelectedMatch.id !== newSelectedMatch?.id) {
                const oldPopIn = document.getElementById("description-card-" + oldSelectedMatch.id)
                if (oldPopIn) {
                    oldPopIn.classList.remove("active")
                    timeslotInfo.scrollIntoView({ behavior: "smooth" })
                }
            } else {timeslotInfo.scrollIntoView({ behavior: "smooth" })}
        }

        setSelectedMatch(
            oldSelectedMatch &&
            oldSelectedMatch.id === newSelectedMatch.id ?
                null
                :
                newSelectedMatch,
        )

        if (oldSelectedMatch &&
            oldSelectedMatch.id === newSelectedMatch.id) {
            setDisplayBottomForm(false)
        }

        setFormInStore(FindAMatch.formName, {
            ...findAMatchData,
            selectedMatch: (
                oldSelectedMatch
                && oldSelectedMatch.id === newSelectedMatch.id
            ) ?
                null
                :
                newSelectedMatch,
        })
    }

    function scrollToForm() {
        if (form?.current?.scrollIntoView) {
            form?.current?.scrollIntoView()
        }
    }

    function displayFormAndScrollTo() {
        setDisplayBottomForm(true)
        scrollToForm()
    }

    // FUNCTION For SupersubsFilterPopin2
    function resetFromOldFilters() {

        if (selectedHour) {
            let tempHour = Object.values(selectedHour)
            setSelectedHour(tempHour === hourFilter || selectedHour.length > 0 ? selectedHour : { "display": oldFilters?.hour[1], "value":oldFilters?.hour[0] })
        }
        setSelectedDuration(selectedDuration?.filter(duration => durationFilter.includes(duration)) ?? oldFilters?.duration)
        setSelectedType(selectedType?.filter(type => typeFilter.includes(type)) ?? oldFilters?.type)
        setSelectedCenter(selectedCenter?.filter(center => centerFilter.includes(center)) ?? oldFilters?.center)
    }

    // ASYNC FUNCTION For SupersubsFilterPopin
    async function refreshFilters() {
        let tempCenters = []
        let tempTypes = []
        if (foundMatch) {
            await foundMatch.forEach(
                match => {
                    if (!tempCenters.includes(match.reservation.centerName) || !tempTypes.includes(match.reservation.resourceTypeDisplay)) {
                        tempCenters.push(match.reservation.centerName)
                        tempTypes.push(match.reservation.resourceTypeDisplay)
                    }
                })
        }

        tempCenters = [ ...new Set(tempCenters) ]
        setCenters(tempCenters)
        tempTypes = tempTypes.map(item => item === "Filmé indoor" ? "Intérieur (Filmé)" : item)
        tempTypes = [ ...new Set(tempTypes) ]
        setTypes(tempTypes)
    }

    const appendDaysWith = (day) => {
        const reformatDate = dayjs(day).format("YYYY-MM-DD")
        let matchsOfDayLength = ""
        if (allRequests) {
            allRequests.forEach(req => {
                if (req?.[reformatDate]) {
                    matchsOfDayLength = req[reformatDate].count
                }
            })
        }
        return (
            <span>
                ({matchsOfDayLength ? matchsOfDayLength : 0})
            </span>
        )
    }

    useEffect(() => {
        aAreas?.forEach(area => {
            area?.[2].forEach(center => {
                if (center === userLoaded.centerId) {
                    setSelectedAreaId(area[0])
                }
            })
        })
    }, [ aAreas ])

    const selectDataOrNoMatch = centerFilter.length === 0 && typeFilter.length === 0 && hourFilter.length === 0 && durationFilter.length === 0 && foundMatch?.length > 0 ? (
        <DisplaySelectedData
            selectedDate={selectedDate}
            foundMatch={foundMatch}
            fetchData={fetchData}
            hasMoreData={hasMoreData}
            onMatchClick={onMatchClick}
            selectedMatch={selectedMatch}
            userLoaded={userLoaded}
            isMobile={isMobile}
            displayFormAndScrollTo={displayFormAndScrollTo}
            ajaxLoading={ajaxLoading}
        />
    ) :
        <NoMatchOnCurrentDay/>

    const noSelectionOrNoMatch = selectedAreaId === "" ? (
        <div className="c-col c-col--8 c-col--m--10 c-col--sm--12 flex justify-center">
            <p className="h2">Sélectionne une région</p>
        </div>
    ) :
        !ajaxLoading && <NoMatchOnCurrentDay/>

    useEffect(() => {
        // Permet de supprimer les filtres(centers et types) dont aucun résultat ne correspond aux criteres
        if (centerFilter.length > 0) {
            let idToDelete = []
            let i = 0
            centerFilter.forEach(center => {
                if (!centers.includes(center[1])) {
                    idToDelete.push(i)
                }
                i++
            })
            idToDelete.forEach(id => {
                centerFilter.splice(id, 1)
            })
        }

        if (typeFilter.length > 0) {
            let idToDelete = []
            let i = 0
            typeFilter.forEach(type => {
                if (!centers.includes(type)) {
                    idToDelete.push(i)
                }
                i++
            })
            idToDelete.forEach(id => {
                typeFilter.splice(id, 1)
            })
        }
    }, [ centers, types ])

    useEffect(() => {
        if (userLoaded) {
            // Permet de récup les régions et sélectionner la région par défaut en fonction du centre de l'utilisateur
            getUsCentersArea(userLoaded.centerId).then(r => {
                let tempArray = []
                r.data.map(area => {
                    delete area.country
                    if (area.name !== "Centre fermé") {tempArray.push(Object.values(area))}
                })
                setAAreas(tempArray)
            })

            if (userLoaded.supersubBanned) {
                showBanScreen()
            }
        }
    }, [ userLoaded ])

    useEffect(() => {
        refreshFilters()
    }, [ foundMatch ])

    useEffect(() => {
        resetFromOldFilters()
    }, [ oldFilters ])

    useEffect(() => {
        // nouvellement mis dans un useEffect, donc a voir si pas de soucis
        if (findAMatchData?.selectedMatch) {
            const timeslotInfo = document.querySelector("#description-card-" + findAMatchData.selectedMatch.id)
            if (!initializeTooltip && timeslotInfo && !timeslotInfo.classList.contains("active")) {
                setInitializeTooltip(true)
                timeslotInfo.classList.toggle("active")
            }
        }
    }, [ findAMatchData ])

    useEffect(() => {
        scrollToForm()
    }, [ displayBottomForm ])

    useEffect(() => {
        return () => {
            setFormInStore(FindAMatch.formName, {})
        }
    }, [])

    useEffect(() => {
        // Lorsque l'on change de centre, on récupère le nombre de demandes pour chaque jour
        if (selectedAreaId && dayPicker.current) {
            let promises = []
            dayPicker.current.getDays().forEach(
                (day) => {
                    promises.push(
                        getSuperSubRequests(
                            {
                                areaId: selectedAreaId,
                                date: day,
                                isToday: dayjs(day).isToday(),
                            },
                        ),
                    )
                },
            )

            Promise.all(promises).then(
                (results) => {
                    setAllRequests(results.map(res => !Array.isArray(res.data.data) ? res.data.data : null))
                },
            ).catch(
                () => {
                    if (errorCount > 5) {
                        setMatchError(0)
                        navigate(
                            "/confirmcommand", {
                                state: {
                                    customContentIdentifier: "home",
                                    customLink: "/",
                                    message: "",
                                    subtitle: "Une erreur s’est produite, la page n’est pas disponible.",
                                    success: true,
                                    title: "Oups&nbsp;!",
                                    tunnelType: "SUPERSUB",
                                },
                            },
                        )
                    } else {
                        setMatchError(!matchError)
                        setErrorCount(errorCount + 1)
                    }
                },
            )
        }

        if (selectedAreaId) {
            let myArea = aAreas?.find(element => element[0] === parseInt(selectedAreaId))
            if (myArea && myArea[2].length <= 1) {
                setHaveOnlyOneCenter(true)
            } else {
                setHaveOnlyOneCenter(false)
            }
        }
    }, [ selectedAreaId ])

    useEffect(() => {
        deleteMyFilter()
    }, [ selectedDate ])

    useEffect(() => {
        if (findAMatchData?.selectedDate) {
            if (foundMatch && allRequests && !initializeAppData) {
                setInitializeAppData(true)
            }
        }
        if (
            allRequests && Object
                .values(allRequests)[requestDateIndex()] && Object
                .values(allRequests)[requestDateIndex()].results.length > 0 && !selectedDate
        ) {
            setSelectedDate(dayjs(today).format("MM-DD-YYYY"))
        }
    }, [ foundMatch, findAMatchData, allRequests ])

    useEffect(() => {
        if (durationFilter.length === 0 && typeFilter.length === 0 && centerFilter.length === 0 && hourFilter.length === 0) {
            setFilterIsActivate(false)
            setFilteredMatch([])
        } else if (durationFilter.length > 0 || typeFilter.length > 0 || centerFilter.length > 0 || hourFilter.length > 0) {
            setFilterIsActivate(true)
        }
    }, [ typeFilter, durationFilter, hourFilter, centerFilter, selectedDate ])

    useEffect(() => {
        const tempType = typeFilter
        const tempCenter = centerFilter
        setOldFilters({
            center: tempCenter ? tempCenter.filter(el => centers.includes(el)) : [],
            duration: [ ...durationFilter ],
            hour: hourFilter,
            type: tempType ? tempType.filter(el => types.includes(el)) : [],
        })
    }, [ selectedDate ])

    useEffect(() => {
        if (allRequests?.length) {
            setDayChoosen(dayjs(new Date()).format("MM-DD-YYYY"))
        }
    }, [ allRequests ])

    useEffect(() => {
        if (dayChoosen) {
            getDayLocations(allRequests, dayChoosen)
        }
    }, [ dayChoosen, allRequests ])

    useEffect(() => {
        let tempMatch = foundMatch
        if (centerFilter?.length) {
            tempMatch = tempMatch.filter(match => centerFilter.includes(match.reservation.centerName))
        }

        if (typeFilter?.length) {
            tempMatch = tempMatch.filter(match => typeFilter.includes(match.reservation.resourceTypeDisplay === "Filmé indoor" ? "Intérieur (Filmé)" : match.reservation.resourceTypeDisplay))
        }

        if (durationFilter?.length) {
            tempMatch = tempMatch.filter(match => durationFilter.includes(match.reservation.duration))
        }

        if (hourFilter[0] !== undefined && hourFilter[1] !== undefined && hourFilter.length > 0) {
            const hourToTest = hourFilter[0]?.substring(1, 3)
            tempMatch = tempMatch.filter(match => {
                if (hourToTest <= match.reservation.start.substring(11, 13)) {
                    return match
                }
            })
        }
        tempMatch = new Set(tempMatch)
        tempMatch = Array.from(tempMatch)
        setFilteredMatch(tempMatch)
    }, [ filterChanged, foundMatch ])

    return (
        <div className="step findAMatch">
            {ajaxLoading &&
                (<Preloader
                    fixed={true}
                />)
            }
            <PageHeader
                title="Trouver un match"
                button={
                    (<Help
                        id="findAMatchHelp"
                    >
                        <PhoneCallHelp tunnelType="SUPERSUB"/>
                    </Help>)
                }
            />
            <PreForm
                fullsize={false}
                sticky
                supersubsRow={true}
            >
                <div className={"supersubHeadingFilters"}>
                    <SelectInput
                        ref={select}
                        id="centerPicker"
                        value={selectedAreaId}
                        label={"Région"}
                        options={aAreas.map(area => ({ label: area[1], value: area[0] }))}
                        onChange={changeSelectedArea}
                        placeholderOption="Sélectionne une région"
                        required
                    />
                    <ButtonCta
                        isCta2Alt
                        onClick={() => filterPopin?.current?.show()}
                        text="+ plus d'options"
                        className="supersubsOptions"
                    />
                </div>
            </PreForm>
            <div className="activeFilters justify-center">
                {centerFilter && centerFilter.length > 0 &&
                    centerFilter.map(
                        center => (
                            <CheckList
                                checked={true}
                                unselect={true}
                                action={
                                    () => {
                                        selectCenterFilter(center, centerFilter, setCenterFilter, true)
                                    }
                                }
                                key={"activefilter-" + center}
                            >
                                {center}
                            </CheckList>
                        ),
                    )
                }
                {typeFilter && typeFilter.length > 0 &&
                    typeFilter.map(
                        type => (
                            <CheckList
                                checked={true}
                                unselect={true}
                                action={
                                    () => {
                                        selectTypesFilter(type, typeFilter, setTypeFilter, true)
                                    }
                                }
                                key={"activefilter-" + type}
                            >
                                {type}
                            </CheckList>
                        ),
                    )
                }
                {durationFilter.length > 0 &&
                    durationFilter.map(
                        duration => (
                            <CheckList
                                checked={true}
                                key={"activefilter-" + duration}
                                unselect={true}
                                action={
                                    () => {
                                        selectDurationsFilter(duration, durationFilter, setDurationFilter, true)
                                    }
                                }
                            >
                                {duration}
                            </CheckList>
                        ),
                    )
                }
                {hourFilter[0] !== undefined && hourFilter[1] !== undefined && hourFilter.length > 0 && (
                    <CheckList
                        unselect={true}
                        checked={true}
                        action={
                            () => {
                                applyFilters({
                                    // center: [...centerFilter],
                                    // type: [...typeFilter],
                                    // duration: [...durationFilter],
                                    hour: [],
                                })
                            }
                        }
                    >
                        Après {hourFilter[0]}
                    </CheckList>
                )}
            </div>
            <div className="c-row justify-center botspace">
                <div className="c-col c-col--8 c-col--m--10 c-col--sm--11 flex justify-center">
                    <DayPicker
                        isDayDisabled={(day) => requestDateIndex(day) === -1}
                        onDayClick={onDayClick}
                        foundMatch={foundMatch}
                        appendDaysWith={appendDaysWith}
                        selectedDate={new Date()}
                        dayChoosen={dayChoosen}
                        isSupersub
                        ref={dayPicker}
                    />
                </div>
                {(selectedAreaId && allRequests && Object.entries(allRequests).length > 0) ? (
                    <>
                        {filteredMatch.length > 0 && filterIsActivate ? (
                            <DisplayFilteredMatch
                                filteredMatch={filteredMatch}
                                fetchData={fetchData}
                                hasMoreData={hasMoreData}
                                ajaxLoading={ajaxLoading}
                                onMatchClick={onMatchClick}
                                selectedMatch={selectedMatch}
                                userLoaded={userLoaded}
                                isMobile={isMobile}
                                displayFormAndScrollTo={displayFormAndScrollTo}
                            />
                        ) : 
                            selectDataOrNoMatch
                        }
                    </>
                ) :
                    noSelectionOrNoMatch
                }
            </div>
            {selectedMatch && (
                <div id="subform" className="c-row justify-center" ref={form}>
                    <div className="c-col c-col--12">
                        <hr/>
                    </div>
                    <div className="c-col c-col--8 c-col--m--10 c-col--sm--11 flex justify-center">
                        <Form
                            title="POSTULER POUR CE MATCH"
                            desc="Remplis les informations concernant ta demande :"
                        >
                            <TextInput
                                label="Téléphone"
                                type="tel"
                                value={userLoaded.phoneNumber}
                                disabled
                                required
                                fullWidth
                            />
                            <em>
                                En finalisant, tu acceptes que ton numéro soit communiqué à l'organisateur. Merci de vérifier la validité de ton numéro sinon le modifier dans ton profil.
                            </em>
                            <TextArea
                                isEmpty={applyMessage.length === 0}
                                labelText="Message"
                                maxLength="500"
                                onChange={setApplyMessage}
                                value={applyMessage}
                                placeholder={"Bonjour, amateur du beau jeu, je suis disponible pour donner du plaisir sur le rectangle vert."}
                                isRequired={true}
                            />
                        </Form>
                    </div>
                    <div className="c-col c-col--8 c-col--m--10 c-col--sm--11 flex justify-center">
                        <ButtonCta
                            text= {selectedMatch.isFree ? "Postuler" : "Payer"}
                            onClick= {goToPayment}
                            disabled= {applyMessage === ""}
                            isAlt= {true}
                        />
                    </div>
                </div>
            )}
            <SupersubsFilterPopin
                oldFilters={{ ...oldFilters }}
                centerFilter={centerFilter}
                typeFilter={typeFilter}
                types={types}
                hours={hours}
                centers={centers}
                durations={durations}
                setSelectedType={setSelectedType}
                setSelectedHour={setSelectedHour}
                setSelectedCenter={setSelectedCenter}
                setSelectedDuration={setSelectedDuration}
                selectedType={selectedType}
                selectedCenter={selectedCenter}
                selectedDuration={selectedDuration}
                selectCenterFilter={selectCenterFilter}
                selectTypesFilter={selectTypesFilter}
                selectDurationsFilter={selectDurationsFilter}
                checkFiltersChange={checkFiltersChange}
                hoursScroll={hoursScroll}
                selectedHour={selectedHour}
                resetFromOldFilters={resetFromOldFilters}
                sameAsOldFilters={sameAsOldFilters}
                haveOnlyOneCenter={haveOnlyOneCenter}
                timeFilter={{ end: "T23:59:00+01:00", name: "allDay", start: "T09:59:00+01:00" }}
                applyChangeCallback={
                    (filterOptions) => {
                        applyFilters(filterOptions)
                        filterPopin.current.hide()
                    }
                }
                ref={filterPopin}
            />
        </div>
    )
}

FindAMatch.formName = "findAMatch"
