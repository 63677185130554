import { bool, number, oneOfType, string } from "prop-types"

function CurrencyDisplay({ price, formatZero, customCls }) {
    return (
        price !== undefined ?
            !formatZero && Math.floor(parseFloat(price)?.toFixed(2)) === price ? 
                <span className={customCls ?? ""}>{price}€</span>
                :
                <span className={customCls ?? ""}>{parseFloat(price)?.toFixed(2)}€</span>
            :
            ""
    )
}

CurrencyDisplay.propTypes = {
    customCls: oneOfType([ bool, string ]),
    formatZero: bool,
    price: oneOfType([ number, string ]),
}

export default CurrencyDisplay
