import { dequal as deepEqual } from "dequal"
import * as React from "react"

function checkDeps(deps) {
    if (!deps || !deps.length) {
        throw new Error(
            "useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.",
        )
    }
    if (deps.every(isPrimitive)) {
        throw new Error(
            "useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.",
        )
    }
}

function isPrimitive(val) {
    // eslint-disable-next-line eqeqeq
    return val == null || /^[sbn]/.test(typeof val)
}

/**
 * Memoizes a value deeply.
 * @param value The value to be memoized (usually a dependency list).
 * @returns A memoized version of the value as long as it remains deeply equal.
 */
export function useDeepCompareMemoize(value) {
    const ref = React.useRef(value)
    const signalRef = React.useRef(0)

    if (!deepEqual(value, ref.current)) {
        ref.current = value
        signalRef.current += 1
    }

    return React.useMemo(() => ref.current, [ signalRef.current ])
}

function useDeepCompareEffect(callback, dependencies) {
    if (process.env.NODE_ENV !== "production") {
        checkDeps(dependencies)
    }
    return React.useEffect(callback, useDeepCompareMemoize(dependencies))
}

export default useDeepCompareEffect
