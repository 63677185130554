import dayjs from "dayjs"

import APP_CONFIG from "../../../../../business/config.js"
import Modal from "../../../../../components/Modal/Modal.jsx"
import { getMedicalCertificateStatus } from "../../../../../components/reservation/medicalCertificateText.js"
import useThemeFeatures from "../../../../../hooks/useThemeFeatures.js"
import childOutfitSizes from "../../../../../model/enums/childOutfitSizes.js"
import ModalHandler from "../../../../../providers/Modal/ModalHandler.jsx"
import { popUrl } from "../../../../../services/routingManagment.js"

export const SchoolListingElementPopIn = ModalHandler.create(({ visible, hide, schoolRegistration }) => {
    const themeFeature = useThemeFeatures()
    const isSessionSchoolType = APP_CONFIG.schoolTypes.session.includes(schoolRegistration.school.typeId)

    const renderCatalogArticles = () => {
        return schoolRegistration.catalogArticles.map((catalogArticle, index) => (
            <div className="catalog-article" key={"catalogArticle-" + index}>
                <img src={catalogArticle.product.pictureUrl} alt="Catalog Article" />
                <div>
                    Qté : <span>{catalogArticle.quantity}</span>
                </div>
            </div>
        ))
    }

    const popin = (
        <>
            <header> Détails inscription </header>
            <article>
                <section className="details">
                    <div>
                        <div className="header">Ecole de Foot</div>
                        <strong>{schoolRegistration.school.type}</strong>
                        <div>{schoolRegistration.school.centerName}</div>
                        <div>
                            <strong>Date : </strong>
                            <span>{schoolRegistration.school.startDate}</span>
                        </div>
                        <div>
                            <strong>Créneau : </strong>
                            <span>{schoolRegistration.startHour}  -  {schoolRegistration.endHour}</span>
                        </div>
                        {schoolRegistration?.kid && (
                            <div>
                                <strong>Enfant : </strong>
                                {schoolRegistration.kid.firstName + " " + schoolRegistration.kid.lastName}
                                <span> - </span>
                                <span>{schoolRegistration.kid.birthDate}</span>
                            </div>
                        )}
                        {schoolRegistration.outfitSize && (
                            <div>
                                <strong>Taille équipement : </strong>
                                {schoolRegistration.outfitSizeValue ?
                                    childOutfitSizes[
                                        schoolRegistration.outfitSizeValue
                                    ].short
                                    :
                                    ""
                                }
                            </div>
                        )}
                        {schoolRegistration?.kid && (<div>
                            <strong>Certificat médical : </strong>
                            {getMedicalCertificateStatus(schoolRegistration)}
                        </div>)}
                    </div>

                </section>

                <section className="section-contact-urgence">
                    <div className="header">Contact en cas d'urgence</div>
                    <div>
                        <div className="contact-strong">CONTACT PRINCIPAL</div>
                        <div>{schoolRegistration.urgencyContact1.firstName} {schoolRegistration.urgencyContact1.lastName}</div>
                        <div>{schoolRegistration.urgencyContact1.phone}</div>
                        <div>{schoolRegistration.urgencyContact1.email}</div>
                    </div>
                    {schoolRegistration?.urgencyContact2?.firstName && (
                        <div className="">
                            <div className="contact-strong">CONTACT SECONDAIRE</div>
                            <div>{schoolRegistration.urgencyContact2.firstName} {schoolRegistration.urgencyContact2.lastName}</div>
                            <div>{schoolRegistration.urgencyContact2.phone}</div>
                            <div>{schoolRegistration.urgencyContact2.email}</div>
                        </div>
                    )}

                </section>

                {schoolRegistration?.catalogArticles?.length > 0 && (
                    <section>
                        <div className="header">OPTION ÉQUIPEMENTS</div>
                        <div className="catalogArticlesDiv">
                            {renderCatalogArticles()}
                        </div>
                    </section>
                )}

                <section className="schoolComment">
                    <div className="header">Commentaires</div>
                    {
                        schoolRegistration.informations && schoolRegistration.informations.length > 0 &&
                        (<div className="comment">
                            {schoolRegistration.informations}
                        </div>)
                    }
                    {
                        (!schoolRegistration.informations ||
                            schoolRegistration.informations && schoolRegistration.informations.length === 0) &&
                        (<div>
                            Aucun commentaire pour cette inscription
                        </div>)
                    }

                </section>
            </article>
        </>
    )

    const padelPopin = (
        <>
            <header> Détails inscription </header>
            <article>
                <section className="details">
                    <div>
                        <strong>{schoolRegistration.school.name}
                        </strong>
                        <div> {schoolRegistration.school.centerName}</div>
                        <div>{schoolRegistration.school.type}</div>
                        <div>
                            <span>Créneau : </span>
                            <span>{schoolRegistration.startHour}  -  {schoolRegistration.endHour}</span>
                        </div>{!isSessionSchoolType && (
                            <div>
                            Date : <span>Du {dayjs(schoolRegistration.school.start).format("DD/MM/YY")} au {dayjs(schoolRegistration.school.end).format("DD/MM/YY")}</span>
                            </div>)}
                        {schoolRegistration?.kid && (
                            <div>
                                <span>Enfant : </span>
                                {schoolRegistration.kid.firstName + " " + schoolRegistration.kid.lastName}
                                <span> - </span>
                                <span>{schoolRegistration.kid.birthDate}</span>
                            </div>
                        )}
                        {!isSessionSchoolType && schoolRegistration?.kid && (<div>
                            <span>Certificat médical : </span>
                            {getMedicalCertificateStatus(schoolRegistration)}
                        </div>)}
                    </div>
                    {schoolRegistration.optionProducts?.length > 0 && (
                        <div>
                            <strong>Mes options</strong>
                            <div>
                                {schoolRegistration.optionProducts.map((option, index) => (
                                    <div key={"option-" + index}>
                                        {option.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </section>
                {schoolRegistration?.kid && (
                    <section className="section-contact-urgence">
                        <div className="header">Contact en cas d'urgence</div>
                        <div>
                            <div className="title">CONTACT PRINCIPAL</div>
                            <div>{schoolRegistration.urgencyContact1.firstName} {schoolRegistration.urgencyContact1.lastName}</div>
                            <div>{schoolRegistration.urgencyContact1.phone}</div>
                            <div>{schoolRegistration.urgencyContact1.email}</div>
                        </div>
                        {schoolRegistration?.urgencyContact2?.firstName && (
                            <div className="">
                                <div className="title">CONTACT SECONDAIRE</div>
                                <div>{schoolRegistration.urgencyContact2.firstName} {schoolRegistration.urgencyContact2.lastName}</div>
                                <div>{schoolRegistration.urgencyContact2.phone}</div>
                                <div>{schoolRegistration.urgencyContact2.email}</div>
                            </div>
                        )}
                    </section>
                )}

                {
                    schoolRegistration.catalogArticles && schoolRegistration.catalogArticles.length > 0 && (
                        <section className="myEquipment">
                            <div className="header">Mes équipements</div>
                            <div className="catalogArticlesDiv">
                                {renderCatalogArticles()}
                            </div>
                        </section>
                    )
                }
                {!isSessionSchoolType && schoolRegistration.kid && (<section className="comment">
                    <div className="header">Commentaires</div>
                    {schoolRegistration.informations && schoolRegistration.informations.length > 0 && (
                        <div className="comment">
                            {schoolRegistration.informations}
                        </div>
                    )}
                    {(!schoolRegistration.informations || schoolRegistration.informations && schoolRegistration.informations.length === 0) && (
                        <div>
                                Aucun commentaire pour cette inscription
                        </div>
                    )}

                </section>
                )}</article>
        </>
    )

    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            beforeClose={popUrl}
            key={schoolRegistration.id}
            className={"schoolTrainingListingPopIn"}
        >
            {themeFeature([ popin, padelPopin ])}
        </Modal>
    )
})
