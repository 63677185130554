import { arrayOf, bool, func, node, oneOfType, string } from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import useThemeFeatures from "../../../hooks/useThemeFeatures"
import PasswordForgotten from "../../../pages/authentication/passwordForgotten/passwordForgotten"
import { setFormEntry } from "../../../store/forms/actions"
import { TextInput } from "../../Inputs/TextInput/TextInput.jsx"

import "./passwordComplexity.scss"

function PasswordComplexity({ introText, onComplexityChange, label, placeholder, onPasswordChange, onFocus, onBlur }) {

    const passwordForgottenData = useSelector(state => state.forms[PasswordForgotten.formName])
    const themeFeatures = useThemeFeatures()
    const [ pictoDisplay, setPictoDisplay ] = useState(null)
    const [ complexity, setComplexity ] = useState("")

    const [ hasLower, setHasLower ] = useState(false)
    const [ hasUpper, setHasUpper ] = useState(false)
    const [ hasDigit, setHasDigit ] = useState(false)
    const [ hasSpecial, setHasSpecial ] = useState(false)
    const [ hasLength, setHasLength ] = useState(false)

    const complexityArray = {
        1: "Faible",
        2: "Moyen",
        3: "Fort",
        4: "Sécurisé",
    }

    /* ACTIONS */
    const dispatch = useDispatch()
    const [ , updateState ] = useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }

    useEffect(
        () => {
            if (passwordForgottenData && passwordForgottenData.password) {
                checkStrength(passwordForgottenData.password)
            }
        }, [ passwordForgottenData ],
    )

    useEffect(
        () => {
            if (onComplexityChange) {
                onComplexityChange(complexity)
            }
        }, [ complexity ],
    )

    function checkStrength(password) {
        let meterBar = document.querySelector("#meter #meter-bar")
        let strength = 0

        const hasLowerCase = password.match(/(.*[a-z].*)/)
        const hasUpperCase = password.match(/(.*[A-Z].*)/)
        const hasDigits = password.match(/(.*\d.*)/)
        const hasSpecialChars = password.match(/(.*\W.*)/)
        const isLongEnough = password.length >= 8

        setHasLower(!!hasLowerCase)
        setHasUpper(!!hasUpperCase)
        setHasDigit(!!hasDigits)
        setHasSpecial(!!hasSpecialChars)
        setHasLength(isLongEnough)

        if (hasLowerCase) {strength += 1}
        if (hasUpperCase) {strength += 1}
        if (hasDigits) {strength += 1}
        if (hasSpecialChars) {strength += 1}

        if (password.length === 0) {
            meterBar.style = "width:0%;"
            setPictoDisplay("")
            setComplexity("")
        } else {
            if (strength <= 2) {
                meterBar.style = themeFeatures([ "background:#ff0000; width:25%; color: #ff0000", "background:#F5233C; width:25%; color: #F5233C", "background:#ff0000; width:25%; color: #ff0000" ])
                setPictoDisplay("errorNotif.svg")
                setComplexity(1)
            } else if (password.length < 8) {
                meterBar.style = themeFeatures([ "background:#ee7f00; width:50%; color: #ee7f00", "background:#FFB43E; width:50%; color: #FFB43E", "background:#ee7f00; width:50%; color: #ee7f00" ])
                setPictoDisplay("notifOrange.svg")
                setComplexity(2)
            } else {
                if (strength === 3) {
                    meterBar.style = "background: #98E396; width:75%; color: #98E396"
                    setPictoDisplay(themeFeatures([ "notifLightGreen.svg", "checkLightGreen.svg", "notifLightGreen.svg" ]))
                    setComplexity(3)
                } else if (strength === 4) {
                    meterBar.style = "background:#05B500; width:100%; color: #05B500"
                    setPictoDisplay(themeFeatures([ "notifDarkGreen.svg", "checkDarkGreen.svg", "notifDarkGreen.svg" ]))
                    setComplexity(4)
                }
            }
        }
    }

    return (
        <React.Fragment>
            <div className="passwordComplexity">
                {introText &&
                    <div className="introText">{introText}</div>
                }
                <form onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault()
                    }
                }}>
                    <TextInput
                        label={label}
                        placeholder={placeholder}
                        required={true}
                        type={"password"}
                        name={"password"}
                        value={passwordForgottenData ? passwordForgottenData.password : ""}
                        onChange={(val) => {
                            checkStrength(val)
                            if (onPasswordChange) {
                                onPasswordChange(val)
                            }
                            setFormInStore(
                                PasswordForgotten.formName, {
                                    ...passwordForgottenData,
                                    password: val,
                                },
                            )
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />

                    <div className="blocReinitPassword">
                        <div className="colSecu">
                            <div className="labelSecu">Sécurité&nbsp;:</div>

                            <div className="meter" id="meter">
                                <div className="meter-bar" id="meter-bar"></div>
                            </div>

                            <div className="complexityDiv">
                                {pictoDisplay &&
                                    <img src={themeFeatures([ "/assets/icons/", "/assets/images/padel/icons/", "/assets/icons/" ]) + pictoDisplay} />
                                }
                                {(complexity && complexity !== 0) &&
                                    (<div className={"complexityText complexity-" + complexity}>
                                        {complexityArray[complexity]}
                                    </div>
                                    )}
                            </div>

                        </div>
                        <div className="colText">
                            <div>Choisis un mot de passe sécurisé pour valider ton compte, celui-ci doit contenir au minimum :
                                <ul>
                                    <li className={hasLength ? "met" : ""}>8 caractères</li>
                                    <li className={hasLower ? "met" : ""}>Une minuscule</li>
                                    <li className={hasUpper ? "met" : ""}>Une majuscule</li>
                                    <li className={hasDigit ? "met" : ""}>Un chiffre</li>
                                    <li className={hasSpecial ? "met" : ""}>Un caractère spécial</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

PasswordComplexity.propTypes = {
    introText: string,
    isMaterialInput: bool,
    label: oneOfType([ string, node, arrayOf(node) ]),
    onBlur: oneOfType([ bool, func ]),
    onComplexityChange: func,
    onFocus: oneOfType([ bool, func ]),
    onPasswordChange: func,
    placeholder: string,
}

export default PasswordComplexity
