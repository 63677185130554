import { 
    addBirthdayDiscountCode,
} from "../../pages/kids/birthdayReservation/api"
import {
    addSchoolDiscountCode,
} from "../../pages/kids/ecoleDeFoot/api"
import {
    addStageDiscountCode,
} from "../../pages/kids/stageDeFoot/api"
import {
    addLeagueDiscountCode,
} from "../../pages/league/api/api.js"
import { addPrebookDiscountCode } from "../../pages/reservation/tunnelReservation/api"
import {
    addCupPromoCode,
} from "../../pages/teams/urbanCup/api"

function useAddDiscountCode(tunnelType) {
    switch (tunnelType) {
    case "BIRTHDAY":
        return addBirthdayDiscountCode

    case "SCHOOL":
        return addSchoolDiscountCode
        
    case "CUP":
        return addCupPromoCode
        
    case "TRAINING":
        return addStageDiscountCode
        
    case "LEAGUE":
        return addLeagueDiscountCode

    case "SUPERSUB":
        return () => {}

    case "BOOKING":
        // A VENIR DANS UNE V2
        return addPrebookDiscountCode
            
    default:
        // eslint-disable-next-line no-console
        console.error("useAddDiscountCode - Not implemented tunnelType : %o", tunnelType)
        // eslint-disable-next-line no-debugger
        debugger
        return () => {}
    }
}

export default useAddDiscountCode
