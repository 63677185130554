import { _apiCall } from "../../../backend/axios"

export function getLocationIdFromPreBookingId(preBookingId) {
    return  _apiCall(
        "read/reservation/prereservation",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {
            "preReservationId": preBookingId,
        },
    ).then(
        (res) => {
            return res && res.data.data && res.data.data ?
                res.data.data
                :
                null
            
        },
    ).catch((err) => {
        return err
    })
}
